/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Grid, TextField, Button, Container, Divider } from "@mui/material";
import { useFormik } from "formik";
import * as yup from "yup";
import LocationService from "../../../services/locationService";
import UnitService from "../../../services/unitService";
import { useAppDispatch } from "../../../store/store";
import { uiActions } from "../../../store/ui-slice";
import { UnitInfo } from "../../../models/location";

interface AddEditUnitInformationProps {
  setUnitShowModalPopup: React.Dispatch<React.SetStateAction<boolean>>;
  customerNo: string;
  locationNo: string;
  unitId: string;
  setUnitRefreshList: React.Dispatch<React.SetStateAction<boolean>>;
}

function AddEditUnitInformation(props: AddEditUnitInformationProps) {
  const dispatch = useAppDispatch();
  const [unitInfo, setUnitInformation] = useState<UnitInfo>(
    LocationService.GetDefaultUnitInfo()
  );
  const [isLoaded, setIsLoaded] = useState<boolean>(false);

  const validationSchema = yup.object({
    vunT_UNIT_NO: yup
      .string()
      .min(1, "Please enter a valid Unit No.")
      .max(50, "Please enter a valid Unit No.")
      .required("Please enter Unit No."),
  });

  const { putData: updateUnitInfoData } =
    UnitService.UpdateUnitInformationByUnitID(
      props.customerNo,
      props.locationNo,
      unitInfo
    );
  const { postData: addUnitInfoData } = UnitService.CreateUnitInformation(
    props.customerNo,
    props.locationNo,
    unitInfo
  );

  useEffect(() => {
    if (props.unitId !== "" && props.unitId !== null) {
      loadUnitInformationByUnitId(props.unitId);
    }
  }, [props.unitId]);

  const { fetchData: fetchUnitInformation } = LocationService.GetUnitByID(
    props.customerNo,
    props.locationNo,
    props.unitId
  );

  const loadUnitInformationByUnitId = (uId: string) => {
    if (uId !== "" && uId !== null) {
      dispatch(uiActions.startProgressBar());
      fetchUnitInformation().then((res) => {
        formik.setValues(res.responseData[0]);
      });
    }
  };

  useEffect(() => {
    if (isLoaded) {
      if (
        props.customerNo !== "" &&
        props.customerNo !== null &&
        props.unitId !== ""
      ) {
        dispatch(uiActions.startProgressBar());
        updateUnitInfoData().then((res) => {
          if (res.isSuccess) {
            dispatch(
              uiActions.displayNotification({
                message: "The unit information updated successfully!",
                severity: "success",
              })
            );
            props.setUnitShowModalPopup(false);
            props.setUnitRefreshList(true);
          }
        });
      } else {
        dispatch(uiActions.startProgressBar());
        addUnitInfoData().then((res) => {
          if (res.isSuccess === true) {
            if (res.responseData.success === "Yes") {
              dispatch(
                uiActions.displayNotification({
                  message: "The unit information is added successfully.!",
                  severity: "success",
                })
              );
              props.setUnitShowModalPopup(false);
              props.setUnitRefreshList(true);
            } else if (res.responseData.success === "No") {
              dispatch(
                uiActions.logErrorNotification({
                  message: "There is already a unit with this unit number.",
                  severity: "error",
                })
              );
            }
          }
        });
      }
    } else {
      setIsLoaded(true);
    }
  }, [unitInfo]);

  const formik = useFormik({
    initialValues: LocationService.GetDefaultUnitInfo(),
    validationSchema: validationSchema,
    onReset: (Event) => {
      formik.values = LocationService.GetDefaultUnitInfo();
    },

    onSubmit: (values: UnitInfo) => {
      setUnitInformation(values);
    },
  });

  return (
    <React.Fragment>
      <Container>
        <form onSubmit={formik.handleSubmit} id="form1" className="form">
          <Grid container spacing={3} sx={{ paddingTop: 1 }}>
            <Grid item xs={6} sm={6} md={6}>
              <TextField
                name="vunT_UNIT_NO"
                label="Unit Number *"
                value={formik.values.vunT_UNIT_NO.trim()}
                fullWidth
                onChange={formik.handleChange}
                inputProps={{ maxLength: 3 }}
                disabled={
                  props.unitId !== "" && props.unitId !== null ? true : false
                }
                error={
                  formik.touched.vunT_UNIT_NO &&
                  Boolean(formik.errors.vunT_UNIT_NO)
                }
                helperText={
                  formik.touched.vunT_UNIT_NO && formik.errors.vunT_UNIT_NO
                }
              />
            </Grid>

            <Grid item xs={6} sm={6} md={6}>
              <TextField
                name="vunT_UNIT_GATEWAY"
                label="Unit Gateway"
                value={formik.values.vunT_UNIT_GATEWAY}
                fullWidth
                onChange={formik.handleChange}
                inputProps={{ maxLength: 15 }}
                error={
                  formik.touched.vunT_UNIT_GATEWAY &&
                  Boolean(formik.errors.vunT_UNIT_GATEWAY)
                }
                helperText={
                  formik.touched.vunT_UNIT_GATEWAY &&
                  formik.errors.vunT_UNIT_GATEWAY
                }
              />
            </Grid>
            <Grid item xs={6} sm={6} md={6}>
              <TextField
                name="vunT_UNIT_IP"
                label="Unit IP Address"
                value={formik.values.vunT_UNIT_IP}
                fullWidth
                onChange={formik.handleChange}
                inputProps={{ maxLength: 15 }}
                error={
                  formik.touched.vunT_UNIT_IP &&
                  Boolean(formik.errors.vunT_UNIT_IP)
                }
                helperText={
                  formik.touched.vunT_UNIT_IP && formik.errors.vunT_UNIT_IP
                }
              />
            </Grid>
            <Grid item xs={6} sm={6} md={6}>
              <TextField
                name="vunT_UNIT_SMTP"
                label="Unit SMTP"
                value={formik.values.vunT_UNIT_SMTP}
                fullWidth
                onChange={formik.handleChange}
                inputProps={{ maxLength: 50 }}
                error={
                  formik.touched.vunT_UNIT_SMTP &&
                  Boolean(formik.errors.vunT_UNIT_SMTP)
                }
                helperText={
                  formik.touched.vunT_UNIT_SMTP && formik.errors.vunT_UNIT_SMTP
                }
              />
            </Grid>
            <Grid item xs={6} sm={6} md={6}>
              <TextField
                name="vunT_UNIT_SUBNET"
                label="Unit Subnet"
                fullWidth
                value={
                  formik.values.vunT_UNIT_SUBNET !== ""
                    ? formik.values.vunT_UNIT_SUBNET
                    : ""
                }
                onChange={formik.handleChange}
                inputProps={{ maxLength: 15 }}
                error={
                  formik.touched.vunT_UNIT_SUBNET &&
                  Boolean(formik.errors.vunT_UNIT_SUBNET)
                }
                helperText={
                  formik.touched.vunT_UNIT_SUBNET &&
                  formik.errors.vunT_UNIT_SUBNET
                }
              />
            </Grid>
            <Grid item xs={6} sm={6} md={6}></Grid>
            <Grid item xs={6} sm={6} md={6}>
              <TextField
                name="vunT_UNIT_IMEI"
                label="Wireless Number"
                fullWidth
                value={formik.values.vunT_UNIT_IMEI}
                onChange={formik.handleChange}
                inputProps={{ maxLength: 10 }}
                error={
                  formik.touched.vunT_UNIT_IMEI &&
                  Boolean(formik.errors.vunT_UNIT_IMEI)
                }
                helperText={
                  formik.touched.vunT_UNIT_IMEI && formik.errors.vunT_UNIT_IMEI
                }
              />
            </Grid>
            <Grid item xs={6} sm={6} md={6}>
              <TextField
                name="vunT_UNIT_APN"
                label="APN"
                fullWidth
                value={formik.values.vunT_UNIT_APN}
                onChange={formik.handleChange}
                inputProps={{ maxLength: 50 }}
                error={
                  formik.touched.vunT_UNIT_APN &&
                  Boolean(formik.errors.vunT_UNIT_APN)
                }
                helperText={
                  formik.touched.vunT_UNIT_APN && formik.errors.vunT_UNIT_APN
                }
              />
            </Grid>
            <Grid item xs={6} sm={6} md={6}>
              <TextField
                name="vunT_UNIT_USERNAME"
                label="Username"
                fullWidth
                value={formik.values.vunT_UNIT_USERNAME}
                inputProps={{ maxLength: 25 }}
                onChange={formik.handleChange}
                error={
                  formik.touched.vunT_UNIT_USERNAME &&
                  Boolean(formik.errors.vunT_UNIT_USERNAME)
                }
                helperText={
                  formik.touched.vunT_UNIT_USERNAME &&
                  formik.errors.vunT_UNIT_USERNAME
                }
              />
            </Grid>
            <Grid item xs={6} sm={6} md={6}>
              <TextField
                name="vunT_UNIT_PASSWORD"
                label="Password"
                fullWidth
                value={formik.values.vunT_UNIT_PASSWORD}
                type={"password"}
                onChange={formik.handleChange}
                inputProps={{ maxLength: 25 }}
                error={
                  formik.touched.vunT_UNIT_PASSWORD &&
                  Boolean(formik.errors.vunT_UNIT_PASSWORD)
                }
                helperText={
                  formik.touched.vunT_UNIT_PASSWORD &&
                  formik.errors.vunT_UNIT_PASSWORD
                }
              />
            </Grid>
            <Grid item xs={6} sm={6} md={6}>
              <TextField
                name="vunT_UNIT_SERIAL_NUMBER"
                label="Serial Number"
                fullWidth
                value={formik.values.vunT_UNIT_SERIAL_NUMBER}
                onChange={formik.handleChange}
                inputProps={{ maxLength: 30 }}
                error={
                  formik.touched.vunT_UNIT_SERIAL_NUMBER &&
                  Boolean(formik.errors.vunT_UNIT_SERIAL_NUMBER)
                }
                helperText={
                  formik.touched.vunT_UNIT_SERIAL_NUMBER &&
                  formik.errors.vunT_UNIT_SERIAL_NUMBER
                }
              />
            </Grid>
            <Grid item xs={6} sm={6} md={6}>
              <TextField
                name="vunT_UNIT_MODEL_NUMBER"
                label="Model Number"
                fullWidth
                value={formik.values.vunT_UNIT_MODEL_NUMBER}
                onChange={formik.handleChange}
                inputProps={{ maxLength: 30 }}
                error={
                  formik.touched.vunT_UNIT_MODEL_NUMBER &&
                  Boolean(formik.errors.vunT_UNIT_MODEL_NUMBER)
                }
                helperText={
                  formik.touched.vunT_UNIT_MODEL_NUMBER &&
                  formik.errors.vunT_UNIT_MODEL_NUMBER
                }
              />
            </Grid>
          </Grid>
        </form>
      </Container>
      <Divider sx={{ mt: 4, mb: 2 }}></Divider>
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        sx={{
          justifyContent: "flex-end",
          alignItems: "flex-end",
          display: "flex",
        }}
      >
        {props.unitId === "" && (
          <Button onClick={formik.handleReset} sx={{ mr: 1 }}>
            Reset
          </Button>
        )}
        <Button
          form="form1"
          type="submit"
          onSubmit={(e) => formik.handleSubmit}
        >
          {props.unitId !== "" ? "Update" : "Submit"}
        </Button>
      </Grid>
    </React.Fragment>
  );
}

export default AddEditUnitInformation;
