/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import PageHeader from "../../shared/PageHeader";
import {
  Button,
  Card,
  CardContent,
  Grid,
  MenuItem,
  TableBody,
  TextField,
  Tooltip,
} from "@mui/material";
import { IFilterBandState } from "../../../interfaces/storeInterfaces";
import { useSelector } from "react-redux";
import { filterBandSelector, useAppDispatch } from "../../../store/store";
import ShipmentDataService from "../../../services/shipmentService";
import { IntransitCars, ProductCode } from "../../../models/shipment";
import {
  AdditionalTableHeaderCell,
  TableHeaderCell,
} from "../../../models/common";
import useTable from "../../../hooks/useTable";
import { StyledTableCell, StyledTableRow } from "../../controls/tablecontrols";
import { FormatNumber } from "../../shared/CommonFunctions";
import moment from "moment";
import React from "react";
import PrintIcon from "@mui/icons-material/Print";
import DownloadIcon from "@mui/icons-material/Download";
import GenerateCSV from "../../shared/GenerateCSV";
import { filterBandActions } from "../../../store/filterband-slice";
import { Link as RouterLink } from "react-router-dom";
import ProductInventoriesTable from "./ProductInventories";
import ModalPopup from "../../shared/ModalPopup";
import IntransitProductSummaryPrint from "./IntransitProductSummaryPrint";

interface Props {}

function IntransitProductSummary(props: Props) {
  const dispatch = useAppDispatch();
  const filterBandState: IFilterBandState = useSelector(filterBandSelector);
  const [productList, setProductList] = useState<ProductCode[]>([]);
  const [intransitCars, setIntransitCars] = useState<IntransitCars[]>([]);
  const [intransitCarsFormatted, setIntransitCarsFormatted] = useState<
    IntransitCars[]
  >([]);
  const [intransitCarsCount, setIntransitCarsCount] = useState<string>("");
  const [intransitProductPrintModalPopup, setIntransitProductPrintModalPopup] =
    useState<boolean>(false);

  const { fetchData: fetchProductData } =
    ShipmentDataService.RetrieveProductsByLocation(
      filterBandState.selectedCustomerNo,
      filterBandState.selectedLocationNo
    );

  const { fetchData: fetchInTransitCarsByCustomer } =
    ShipmentDataService.RetrieveAllCarsByCustomer(
      filterBandState.selectedCustomerNo
    );

  const { fetchData: fetchInTransitCarsByLocation } =
    ShipmentDataService.RetrieveAllCarsByLocation(
      filterBandState.selectedLocationNo
    );

  const { fetchData: fetchInTransitCarsByCustomerProduct } =
    ShipmentDataService.RetrieveCarsByCustomer(
      filterBandState.selectedCustomerNo,
      filterBandState.selectedProductID
    );

  const { fetchData: fetchInTransitCarsByLocationProduct } =
    ShipmentDataService.RetrieveCarsByLocation(
      filterBandState.selectedLocationNo,
      filterBandState.selectedProductID
    );

  useEffect(() => {
    if (
      filterBandState.selectedCustomerNo !== "" &&
      filterBandState.selectedCustomerNo !== undefined
    ) {
      loadProduct();
      loadTransitCarsData(filterBandState.selectedProductID);
    }
  }, []);

  const loadProduct = () => {
    fetchProductData().then((res) => {
      setProductList(res.responseData);
    });
  };

  useEffect(() => {
    loadTransitCarsData(filterBandState.selectedProductID);
  }, [
    filterBandState.selectedCustomerNo,
    filterBandState.selectedLocationNo,
    filterBandState.selectedProductID,
  ]);

  useEffect(() => {
    if (filterBandState.selectedProductID !== "") {
      productChange(filterBandState.selectedProductID);
    }
  }, [filterBandState.selectedProductID]);

  const handleProductChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const productCode = event.target.value;
    productChange(productCode);
  };

  const productChange = (productCode: string) => {
    dispatch(
      filterBandActions.setSelectedProduct({
        productId: productCode,
      })
    );
  };

  useEffect(() => {
    loadTransitCarsData(filterBandState.selectedProductID);
  }, [filterBandState.selectedProductID]);

  const loadTransitCarsData = (productCode: string) => {
    setIntransitCars([]);
    setIntransitCarsFormatted([]);
    setIntransitCarsCount("");
    if (
      filterBandState.selectedCustomerNo !== "" &&
      filterBandState.selectedCustomerNo !== undefined
    ) {
      if (filterBandState.selectedLocationNo) {
        if (productCode === "ALL" || productCode === "") {
          fetchInTransitCarsByLocation().then((res) => {
            setIntransitCars(res.responseData);
            setIntransitCarsCount(res.responseData.length);
          });
        } else {
          fetchInTransitCarsByLocationProduct().then((res) => {
            setIntransitCars(res.responseData);
            setIntransitCarsCount(res.responseData.length);
          });
        }
      } else {
        if (productCode === "ALL" || productCode === "") {
          fetchInTransitCarsByCustomer().then((res) => {
            setIntransitCars(res.responseData);
            setIntransitCarsCount(res.responseData.length);
          });
        } else {
          fetchInTransitCarsByCustomerProduct().then((res) => {
            setIntransitCars(res.responseData);
            setIntransitCarsCount(res.responseData.length);
          });
        }
      }
    }
  };

  const tableHeaderCellDetails: TableHeaderCell[] = [
    { id: "rS_CAR_NUMBER", label: "Car No.", align: "center" },
    { id: "formatted_rS_SHIPPED_DATE", label: "Ship Date", align: "center" },
    { id: "rS_LOAD_EMPTY_STATUS", label: "LE", align: "center" },
    { id: "origin", label: "Origin", align: "center" },
    { id: "destination", label: "Destination", align: "center" },
    { id: "rS_CURRENT_CLM_CARRIER", label: "RR", align: "center" },
    { id: "rS_CURRENT_CLM_CODE", label: "S", align: "center" },
    { id: "clm", label: "CLM Location", align: "center" },
    { id: "formatted_CURRENT_CLM_DATE", label: "CLM Date", align: "center" },
    { id: "rS_SOLDTO_CUSTOMER_NUMBER", label: "Cust No", align: "center" },
    { id: "idlE_DAYS", label: "Idle Days", align: "center" },
    { id: "formatted_NET_WEIGHT", label: "Net Weight(MT)", align: "center" },
    { id: "vP_PRODUCT_ABBR", label: "Product", align: "center" },
    { id: "rS_PERSONNEL_CODE", label: "PR Code", align: "center" },
  ];

  const additionalHeaderDetails: AdditionalTableHeaderCell[] = [
    {
      id: "reorder",
      label: `In-Transit/In-Town Product - ${intransitCarsCount} cars`,
      colSpan: 14,
      align: "center",
    },
  ];

  const { Tbl, GetRowsAfterPagingAndSorting } = useTable<IntransitCars>(
    intransitCarsFormatted,
    tableHeaderCellDetails,
    true,
    10,
    additionalHeaderDetails
  );

  useEffect(() => {
    if (intransitCars.length > 0) {
      let data: IntransitCars[] = formatData(intransitCars);
      setIntransitCarsFormatted(data);
    }
  }, [intransitCars]);

  const formatData = (data: IntransitCars[]) => {
    let calculatedData: IntransitCars[] = [];
    data.forEach((element) => {
      element.formatted_rS_SHIPPED_DATE = moment(
        element.rS_SHIPPED_DATE
      ).format("MM/DD/YYYY");
      element.formatted_CURRENT_CLM_DATE =
        element.rS_CURRENT_CLM_DATE !== null
          ? moment(element.rS_CURRENT_CLM_DATE).format("MMM DD YYYY h:mm A")
          : element.rS_CURRENT_CLM_DATE;
      if (Number(FormatNumber(element.rS_NET_WEIGHT)) < 120) {
        element.formatted_NET_WEIGHT = FormatNumber(element.rS_NET_WEIGHT);
      } else {
        if (element.inbounD_RAIL === true) {
          element.formatted_NET_WEIGHT = FormatNumber(
            element.rS_NET_WEIGHT / 2000
          );
        } else {
          element.formatted_NET_WEIGHT = FormatNumber(
            element.rS_NET_WEIGHT / 2204
          );
        }
      }
      element.checkbox_turn = false;
      if (
        element.rtcS_COA !== "" &&
        element.rtcS_COA !== undefined &&
        element.rtcS_COA !== null
      ) {
        if (element.rtcS_COA.trim().toUpperCase() === "Y") {
          element.checkbox_COA = true;
        } else {
          element.checkbox_COA = false;
        }
      } else {
        element.checkbox_COA = false;
      }
      calculatedData.push(element);
    });
    return calculatedData;
  };

  //---------------------props
  const ITEM_HEIGHT = 46;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5,
      },
    },
  };

  const printTableHeaderCellDetails: TableHeaderCell[] = [
    { id: "rS_CAR_NUMBER", label: "Car No", align: "center" },
    { id: "formatted_rS_SHIPPED_DATE", label: "Ship Date", align: "center" },
    { id: "rS_LOAD_EMPTY_STATUS", label: "LE", align: "center" },
    { id: "origin", label: "Origin", align: "center" },
    { id: "destination", label: "Destination", align: "center" },
    { id: "rS_CURRENT_CLM_CARRIER", label: "RR", align: "center" },
    { id: "rS_CURRENT_CLM_CODE", label: "S", align: "center" },
    { id: "clm", label: "CLM Location", align: "center" },
    {
      id: "formatted_CURRENT_CLM_DATE",
      label: "CLM Destination",
      align: "center",
    },
    {
      id: "rS_SOLDTO_CUSTOMER_NUMBER",
      label: "Customer Number",
      align: "center",
    },
    { id: "idlE_DAYS", label: "Idle Days", align: "center" },
    { id: "formatted_NET_WEIGHT", label: "Net Weight", align: "center" },
    { id: "vP_PRODUCT_ABBR", label: "Product", align: "center" },
    { id: "rS_PERSONNEL_CODE", label: "PR Code", align: "center" },
  ];

  const onModalClose = (e: React.MouseEvent<HTMLButtonElement>) => {
    Promise.resolve().then(() => {
      setIntransitProductPrintModalPopup(false);
    });
  };

  function onPrintClick() {
    setIntransitProductPrintModalPopup(true);
  }

  const onExcelClick = () => {
    GenerateCSVfile(intransitCarsFormatted || []);
  };

  const GenerateCSVfile = (data: IntransitCars[]) => {
    const allData: any = [];
    const Header = {
      "Car No": "",
      "Ship Date": "",
      LE: "",
      Origin: "",
      Destination: "",
      RR: "",
      S: "",
      "CLM Location": "",
      "CLM Destination": "",
      "Customer Number": "",
      "Idle Days": "",
      "Net Weight": "",
      Product: "",
      "PR Code": "",
    };
    allData.push(Header);

    for (let i = 0; i < data.length; i++) {
      let intransitShipmentData = {
        "Car No": data[i].rS_CAR_NUMBER,
        "Ship Date": data[i].formatted_rS_SHIPPED_DATE,
        LE: data[i].rS_LOAD_EMPTY_STATUS,
        Origin: data[i].origin,
        Destination: data[i].destination,
        RR: "",
        S: "",
        "CLM Location": data[i].clm,
        "CLM Destination": moment(data[i].formatted_CURRENT_CLM_DATE).format(
          "MMM DD YYYY h:mm A"
        ),
        "Customer Number": "",
        "Idle Days": data[i].idlE_DAYS,
        "Net Weight": data[i].formatted_NET_WEIGHT,
        Product: data[i].rS_PRODUCT_CODE,
        "PR Code": data[i].rS_PERSONNEL_CODE,
      };
      allData.push(intransitShipmentData);
    }
    GenerateCSV(
      "OISInTransitSchedule",
      allData,
      ",,,,VMI In-Transit Schedule\n\n"
    );
  };

  return (
    <React.Fragment>
      <PageHeader
        title="In-Transit Product Summary"
        subtitle="Select the Location Name to view the location summary. Product Inventories shows the current inventory for all the locations summation by product."
      ></PageHeader>

      {
        <Card
          sx={{ width: "100hv", borderRadius: 3, margin: 2, mt: 0 }}
          elevation={6}
        >
          <CardContent sx={{ pb: 0 }}>
            <Grid container columnSpacing={{ xs: 1, sm: 1, md: 4, lg: 4 }}>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Grid container>
                  <Grid item xs={2} sm={2} md={4} lg={4}></Grid>
                  <Grid item xs={8} sm={8} md={4} lg={4}>
                    <TextField
                      id="Product"
                      name="Product"
                      select
                      label="Product"
                      onChange={handleProductChange}
                      fullWidth
                      inputProps={{ MenuProps }}
                      value={
                        filterBandState.selectedProductID !== ""
                          ? filterBandState.selectedProductID
                          : "ALL"
                      }
                    >
                      <MenuItem value="ALL">Please Select a Product</MenuItem>
                      {productList.map((row, index) => {
                        return (
                          row.vpL_RAIL_USER === true && (
                            <MenuItem
                              key={index + row.vP_PRODUCT_ID}
                              value={row.vP_PRODUCT_CODE}
                            >
                              {row.vP_PRODUCT_DESC}
                            </MenuItem>
                          )
                        );
                      })}
                    </TextField>
                  </Grid>
                  <Grid item xs={2} sm={2} md={4} lg={4}></Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <ProductInventoriesTable />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Card
                  sx={{ width: "100hv", borderRadius: 3, margin: 1 }}
                  elevation={6}
                >
                  <Grid
                    container
                    columnSpacing={{ xs: 1, sm: 1, md: 4, lg: 4 }}
                    sx={{ mt: 1 }}
                  >
                    <Grid item xs={4} sm={4} md={4} lg={4}></Grid>
                    <Grid
                      item
                      xs={4}
                      sm={4}
                      md={4}
                      lg={4}
                      sx={{ textAlign: "center" }}
                    >
                      <RouterLink
                        to={"/railcarHistory"}
                        style={{ color: "Green", fontWeight: "bold" }}
                      >
                        View Railcar History
                      </RouterLink>
                    </Grid>
                    <Grid
                      item
                      xs={4}
                      sm={4}
                      md={4}
                      lg={4}
                      sx={{ textAlign: "center" }}
                    >
                      <Grid>
                        <Button
                          onClick={onPrintClick}
                          startIcon={<PrintIcon />}
                          sx={{ mr: 2 }}
                        >
                          Print
                        </Button>
                        <Button
                          onClick={onExcelClick}
                          startIcon={<DownloadIcon />}
                        >
                          Export
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                  <CardContent>
                    <Tbl>
                      <TableBody>
                        {GetRowsAfterPagingAndSorting().map((row, index) => (
                          <StyledTableRow key={index}>
                            <StyledTableCell
                              scope="row"
                              sx={{ textAlign: "center" }}
                            >
                              {row.rS_CAR_NUMBER !== undefined &&
                              row.rS_CAR_NUMBER !== null
                                ? row.rS_CAR_NUMBER.trim()
                                : row.rS_CAR_NUMBER}
                            </StyledTableCell>
                            <StyledTableCell
                              scope="row"
                              sx={{ textAlign: "center" }}
                            >
                              {row.formatted_rS_SHIPPED_DATE}
                            </StyledTableCell>
                            <StyledTableCell
                              scope="row"
                              sx={{ textAlign: "center" }}
                            >
                              {row.rS_LOAD_EMPTY_STATUS}
                            </StyledTableCell>
                            <StyledTableCell
                              scope="row"
                              sx={{ textAlign: "center" }}
                            >
                              {row.origin !== undefined && row.origin !== null
                                ? row.origin.trim()
                                : row.origin}
                            </StyledTableCell>
                            <StyledTableCell
                              scope="row"
                              sx={{ textAlign: "center" }}
                            >
                              {row.destination !== undefined &&
                              row.destination !== null
                                ? row.destination.trim()
                                : row.destination}
                            </StyledTableCell>
                            <StyledTableCell
                              scope="row"
                              sx={{ textAlign: "center" }}
                            >
                              {row.rS_CURRENT_CLM_CARRIER}
                            </StyledTableCell>
                            <StyledTableCell
                              scope="row"
                              sx={{ textAlign: "center" }}
                            >
                              {row.rS_CURRENT_CLM_CODE}
                            </StyledTableCell>
                            <StyledTableCell
                              scope="row"
                              sx={{ textAlign: "center" }}
                            >
                              {row.clm !== undefined && row.clm !== null
                                ? row.clm.trim()
                                : row.clm}
                            </StyledTableCell>
                            <StyledTableCell
                              scope="row"
                              sx={{ textAlign: "center" }}
                            >
                              {row.formatted_CURRENT_CLM_DATE}
                            </StyledTableCell>
                            <StyledTableCell
                              scope="row"
                              sx={{ textAlign: "center" }}
                            >
                              {row.rS_SOLDTO_CUSTOMER_NUMBER}
                            </StyledTableCell>
                            <StyledTableCell
                              scope="row"
                              sx={{ textAlign: "center" }}
                            >
                              {row.idlE_DAYS !== undefined &&
                              row.idlE_DAYS !== null
                                ? String(row.idlE_DAYS).trim()
                                : row.idlE_DAYS}
                            </StyledTableCell>
                            <StyledTableCell
                              scope="row"
                              sx={{ textAlign: "center" }}
                            >
                              {row.formatted_NET_WEIGHT}
                            </StyledTableCell>
                            <StyledTableCell
                              scope="row"
                              sx={{ textAlign: "center" }}
                            >
                              <Tooltip title={row.rS_PRODUCT_DESCRIPTION}>
                                <div>{row.rS_PRODUCT_CODE}</div>
                              </Tooltip>
                            </StyledTableCell>
                            <StyledTableCell
                              scope="row"
                              sx={{ textAlign: "center" }}
                            >
                              {row.rS_PERSONNEL_CODE}
                            </StyledTableCell>
                          </StyledTableRow>
                        ))}
                      </TableBody>
                    </Tbl>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      }
      <ModalPopup
        open={intransitProductPrintModalPopup}
        title={"In-Transit Schedule"}
        onPopupClose={onModalClose}
        width="1500px"
      >
        <IntransitProductSummaryPrint
          intransitCars={intransitCarsFormatted}
          tableHeader={printTableHeaderCellDetails}
        />
      </ModalPopup>
    </React.Fragment>
  );
}

export default IntransitProductSummary;
