/* eslint-disable import/no-anonymous-default-export */
import { Alarm, Tank, TankByLocation, TankInformation, ManualTankReading, TankInfo, ShowChartModel,MaxTankNumber, AddTank } from "../models/tank";
import useAxiosGet from "../hooks/useAxiosGet";
import useAxiosPost from "../hooks/useAxiosPost";
import useAxiosPut from "../hooks/useAxiosPut";
import { UnitInfo } from "../models/location";
import { ShowHistoryModel } from "../models/tank";
import useAxiosDelete from "../hooks/useAxiosDelete";

const isLocal = () => (process.env.REACT_APP_ENVIR === 'LOCAL');
const userId = () => sessionStorage.getItem("userMailID");

const GetDefaultTankInformation = () : TankInformation =>
{
   const defaultTankInformation = {
     //Tank Inventory Information
     vT_TANK_NO: 0,
     vT_TANK_NAME:'',
     vL_LOCATION_NAME: '',
     vP_PRODUCT_DESC: '',
     lastLevelUpdate: '',
     currentLevel: '',
     beginLevel: '',
 
     //Tank Specification Information
     vpT_PRODUCT_TYPE: '',
     vpL_UOM: '',
     inV_LBS: 0,
     specific_Gravity: 0,
     vT_TANK_HEIGHT: 0,
     vT_TANK_ORIENTATION: '',
     caP_LBS: 0,
     caP_GAL: 0,
     caP_CUFT: 0,
     vT_TANK_DIAMETER: 0,
     vT_TANK_LENGTH: 0,
     //Tank Sensor Information
     vT_PROBE_MODEL: '',
     vT_PROBE_MFG: '',
     vT_PROBE_4MA_SET: 0,
     vT_PROBE_20MA_SET: 0,
     vT_PROBE_BLANKING: 0,
     vT_PROBE_PSI_MAX: 0,
     vT_PROBE_IND_SPAN_LOW: 0,
     vT_PROBE_IND_SPAN_HIGH: 0,
     vT_PROBE_CAL_DATE: '',
     vT_PROBE_INST_DATE: '',
     stR_InvLevels:'',
     stR_TankLevels:'',
     stR_LevelInches:''
  } as TankInformation;

  return defaultTankInformation;
}

const GetDefaultTankInfo = (): any => {
  const defaultTankInfo = {
    vT_TANK_ID: "",
    vT_TANK_NAME: "",
    vT_TANK_NO: "",
    vT_LOCATION_ID: "",
    vT_UNIT_ID: "",
    vT_PRODUCT_ID: "",
    vT_TANK_HEIGHT: "",
    vT_TANK_DIAMETER: "",
    vT_TANK_TEMP_F: "",
    vT_TANK_CAPACITY_GAL: "",
    vT_TANK_CAPACITY_CUB_FT: "",
    vT_TANK_PSI_MAX: "",
    vT_TANK_ACTIVE: "",
    vT_TANK_AUTO_CALC: "",
    vT_PROBE_COMM_TYPE: "",
    vT_PROBE_TYPE: "",
    vT_PROBE_MODEL: "",
    vT_PROBE_MFG: "",
    vT_PROBE_4MA_SET: "",
    vT_PROBE_20MA_SET: "",
    vT_PROBE_IND_SPAN_LOW: "",
    vT_PROBE_IND_SPAN_HIGH: "",
    vT_PROBE_CAL_DATE: "",
    vT_PROBE_INST_DATE: "",
    vT_PROBE_BLANKING: "",
    vT_PROBE_PSI_MAX: "",
    vT_SEND_WRN_EMAIL: "",
    vT_TANK_ORIENTATION: "",
    vT_TANK_LENGTH: "",
    vT_TANK_SCALE: "",
    vT_TANK_MA_MAX: "",
    vT_SOURCE_TYPE: "",
    vT_SOURCE_TAG: "",
    vT_SOURCE_NODE: "",
    vT_TEMP_READER: "",
    vT_TEMP_RANGE_MIN: "",
    vT_TEMP_RANGE_MAX: "",
    sourcE_TAG: "",
    vpL_PRODUCT_ID: "",
    vpL_LOCATION_ID: "",
    vpL_AVG_DAILY_USAGE: "",
    vpL_REPORT_TIME: "",
    vpL_REPORT_TIME_WEEKLY: "",
    vpL_ORDER_POINT_PERCENT: "",
    vpL_ORDER_POINT_DAYS: "",
    vpL_ORDER_REORDER_QTY: "",
    vpL_ORDER_MAX_SHIP_QTY: "",
    vpL_ORDER_MIN_SHIP_PCT: "",
    vpL_ORDER_COPC: "",
    vpL_ORDER_MSTR_CUST_NO: "",
    vpL_ORDER_MSTR_SHIP_CODE: "",
    vpL_ORDER_CONTAINER_CODE: "",
    vpL_ORDER_LEAD_DAYS: "",
    vpL_ORDER_TRAVEL_DAYS: "",
    vpL_ORDER_ESTIMATION: "",
    vpL_ORDER_GENERATION: "",
    vpL_AVG_DAILY_USAGE_OVERRIDE: "",
    vpL_WKND_FLG: "",
    vpL_RAIL_USER: "",
    vpl_acpt_overflow: "",
    vpl_notes: "",
    vpL_PROD_SCHEDULE_USER: "",
    vpL_UOM: "",
    vpL_NUMBER_OF_CARS: "",
    vpL_IBM_SYNC: "",
    vpL_ARRIVAL_DT_FLG: "",
    vpL_PR_CODE: "",
    vpL_MOBILE_PUBLIC: "",
    vpL_MOBILE_PRIVATE: "",
    vpL_ARRIVAL_DATE_ADJUSTMENT: "",
    vpL_HIGH_INVENTORY_ARRIVAL_WARNING: "",
    vpL_ADU_RANGE_WARNING: "",
    vpL_ADU_RANGE_MIN: "",
    vpL_ADU_RANGE_MAX: "",
    vpL_WEEK_AVG_DISPLAY: "",
    vpL_CALCULATED_WEEK_AVG: "",
    vpL_OPERATING_DAYS: "",
    vP_PRODUCT_ID: "",
    vP_PRODUCT_TYPE_ID: "",
    vP_PRODUCT_CODE: "",
    vP_PRODUCT_DESC: "",
    vP_PRODUCT_GROUP: "",
    vP_UNIT_OF_MEASURE: "",
    vP_SPECIFIC_GRAVITY: "",
    vP_PRODUCT_ABBR: "",
    vL_LOCATION_NAME: "",
    radioPSIValue: "",
  } as any;
  return defaultTankInfo;
};


const GetDefaultAlarmInformation = () : Alarm =>
{
   const defaultAlarmInformation = {
    result:''
  } as Alarm;

  return defaultAlarmInformation;
}

const UpdateManualDetails = () : ManualTankReading =>
{
   const manualDetails = {
    tankID: 0,
    fReading: '',
    strUnitType: '', 
  } as ManualTankReading;

  return manualDetails;
}

const GetTanks = (customerNo:string, locationNo:string) => {
  return useAxiosGet<Tank[]>(isLocal()?`/Tank/GetTanks/users/${userId()}/customers/${customerNo}/locations/${locationNo}/tanks`:`/users/${userId()}/customers/${customerNo}/locations/${locationNo}/tanks`, [], "Tank list");
};

const RetrieveTanksBylocation = (customerNo:string, locationNo:string) => {
  return useAxiosGet<TankByLocation[]>(isLocal()?`/Tank/RetrieveTanksBylocation/users/${userId()}/customers/${customerNo}/locations/${locationNo}/tanksbylocations`:`/users/${userId()}/customers/${customerNo}/locations/${locationNo}/tanksbylocations`, [], "Tank list");
};

const GetAlarmInfo = (customerNo:string,locationNo:string,tankID:string)=> {
  return useAxiosGet<Alarm>(isLocal()?`/Tank/GetAlarmInfo/users/${userId()}/customers/${customerNo}/locations/${locationNo}/tanks/${tankID}`:`/users/${userId()}/customers/${customerNo}/locations/${locationNo}/tanks/${tankID}/alarm`, GetDefaultAlarmInformation() , "Alarm Information");
};

const GetTankByNumber = (customerNo:string,locationNo:string,tankID:string)=> {
  return useAxiosGet<TankInformation>(isLocal()?`/Tank/GetTankByNumber/users/${userId()}/customers/${customerNo}/locations/${locationNo}/tanks/${tankID}/tankdetails`:`/users/${userId()}/customers/${customerNo}/locations/${locationNo}/tanks/${tankID}/tankdetails`, GetDefaultTankInformation() , "Tank Information");
};

const CreateManualTankReading = (data: ManualTankReading) => {
  return useAxiosPost(isLocal() ? 
  `/Tank/CreateManualTankReading/users/${userId()}/tankreadings`: 
  `/users/${userId()}/tankreadings`, data, "Manual Tank - Update Tank Details");
};

const RetrieveTankInfo = (customerNo:string,locationNo:string,tankID:number)=> {
  return useAxiosGet<TankInfo>(isLocal()?`/Tank/RetrieveTankInfo/tanks/${tankID}/tankdetails`:`/tanks/${tankID}/tankdetails`, GetDefaultTankInfo() , "Tank Information");
};

const UpdateTank = (customerNo:string,locationNo:string,data:AddTank) => {
  return useAxiosPut(isLocal() ?`/Tank/UpdateTank/users/${userId()}/customers/${customerNo}/locations/${locationNo}/tankdetails`:`/users/${userId()}/customers/${customerNo}/locations/${locationNo}/tankdetails`, data, "Tank Maintainance - Update Tank");
};

const CreateTank = (customerNo:string,locationNo:string,data:AddTank) => {
  return useAxiosPost(isLocal() ?`/Tank/CreateTank/users/${userId()}/customers/${customerNo}/locations/${locationNo}/tankdetails`:`/users/${userId()}/customers/${customerNo}/locations/${locationNo}/tankdetails`, data, "Tank Maintainance - Add Tank");
};

const RetrieveUnitByLocation = (customerNo:string,locationNo:string)=> {
  return useAxiosGet<UnitInfo[]>(isLocal()?`/Tank/RetrieveUnitByLocation/locations/${locationNo}/units`:`/locations/${locationNo}/units`, [] , "Unit Information");
};

const RetrieveShowHistoryData = (tankID:number,dtStartDate:string,dtEndDate:string)=> {
  return useAxiosGet<ShowHistoryModel[]>(isLocal()?`/Tank/RetrieveShowHistoryData/tanks/${tankID}/startdate/${dtStartDate}/enddate/${dtEndDate}/tankhistories`: `/tanks/${tankID}/startdate/${dtStartDate}/enddate/${dtEndDate}/tankhistories`, [] , "Show History Data");
}

const RetrieveTankChartData = (tankID:number,dtStartDate:string,dtEndDate:string)=> {
  return useAxiosGet<ShowChartModel[]>(isLocal()?`/Tank/RetrieveTankChartData/tanks/${tankID}/startdate/${dtStartDate}/enddate/${dtEndDate}/tankchartdetails`: `/tanks/${tankID}/startdate/${dtStartDate}/enddate/${dtEndDate}/tankchartdetails`, [] , "Show Chart Data");
}

const RetrieveMaxTankNumber=(customerNo:string,locationNo:string)=>{
  return useAxiosGet<MaxTankNumber[]>(isLocal()?`/Tank/RetrieveMaxTankNumber/users/${userId()}/customers/${customerNo}/locations/${locationNo}/maxtanknumbers`:`/users/${userId()}/customers/${customerNo}/locations/${locationNo}/maxtanknumbers`,[],"Max Tank Number")
}

const DeleteTank = (customerNo:string,locationNo:string,tankID:number) => {
  return useAxiosDelete(isLocal() ?`/Tank/DeleteTank/tanks/${tankID}`:`/tanks/${tankID}`, tankID, "Tank Maintainance - Delete Tank");
};

export default { 
  GetDefaultAlarmInformation,
  GetDefaultTankInfo,
  GetDefaultTankInformation,
  UpdateManualDetails,
  GetTanks,
  RetrieveTanksBylocation,
  GetAlarmInfo, 
  GetTankByNumber,
  CreateManualTankReading,
  RetrieveTankInfo,
  UpdateTank,
  CreateTank,
  RetrieveUnitByLocation,
  RetrieveShowHistoryData,
  RetrieveTankChartData,
  RetrieveMaxTankNumber,
  DeleteTank
};
