/* eslint-disable import/no-anonymous-default-export */
import useAxiosGet from "../hooks/useAxiosGet";
import useAxiosPost from "../hooks/useAxiosPost";
import useAxiosDelete from "../hooks/useAxiosDelete";
import { LevelUsers, Notice, NoticeType, NoticeUsers, UpdateSecurity, UserLevelNotice, UserNoticeInfo, UserRole, Users,UserCreate } from "../models/userNotice";
import useAxiosPut from "../hooks/useAxiosPut";


const isLocal = () => (process.env.REACT_APP_ENVIR === 'LOCAL');
//const userId = () => sessionStorage.getItem("userMailID");

const GetDefaultUser = (): UserCreate => {
    const defaultUser = {
        vU_FIRST_NAME: "",
        vU_LAST_NAME: "",
        vU_EMAIL: "",
        vU_PHONE: "",
    } as UserCreate;
    return defaultUser;
};

const GetDefaultUserLevelNoticeInfo = (): UserLevelNotice => {
    const defaultUserLevelNoticeInfo = {
        vunL_USER_ID: 0,
        vunL_NOTICE_ID: 0,
        vunL_LOCATION_ID: 0,
        vunL_THRESHOLD: 0,
        vunL_EQUALITY_SYMBOL: "",
        vunL_LAST_UPDATE: "",
        vunL_DAILY: "",
        vN_NOTICE_ID: 0,
        vN_NOTICE_DESC: "",
        vN_NOTICE_TYPE: "",
    } as UserLevelNotice;

    return defaultUserLevelNoticeInfo;
};

const GetDefaultUserNoticeInfo = (): UserNoticeInfo => {
    const defaultUserNoticeInfo = {
        vuN_USER_ID: 0,
        vuN_NOTICE_ID: 0,
        vuN_LAST_NOTICE: "",
        vuN_IDLE_DAYS: 0,
        vuN_THRESHOLD_AMOUNT: 0,
        vuN_HOUR_NOTICE: 0,
        vN_NOTICE_ID: 0,
        vN_NOTICE_DESC: "",
        vN_NOTICE_TYPE: ""
    } as UserNoticeInfo;

    return defaultUserNoticeInfo;
};


const GetDefaultUserList = (): Users => {
    const defaultUserInfo = {
        userName: "",
        eadmUserID: 0,
        userID: 0,
        email: "",
        noticeDesc: "",
        noticeID: 0,
        idleDays: 0,
    } as Users;

    return defaultUserInfo;
};

const GetDefaultLevelUserList = (): LevelUsers => {
    const defaultLevelUserInfo = {
        userName: "",
        eadmUserID: 0,
        userID: 0,
        email: "",
        locationID: 0,
        locationNo: "",
        locationName: "",
        noticeDesc: "",
        noticeID: 0,
        threshold: 0,
        equalitySymbol: "",
        daily: ""
    } as LevelUsers;

    return defaultLevelUserInfo;
};

const GetDefaultNoticeRequest = (): Notice => {
    const defaultNoticeRequest = {
        userID: "",
        noticeID: 0,
        locationNo: "",
        level: "",
        symbol: "",
        idleDays: "",
        hourNotice: "",
        thresholdAmount: "",
        lastNotice: "",
        prevLocationID: 0,
        customerNo: "",
        levelDailyOption: "",
    } as Notice;

    return defaultNoticeRequest;
};

const GetDefaultUpdateSecurity = (): UpdateSecurity => {
  const defaultUpdateSecurity = {
    userID: 0,
    customerIDs: "",
    locationIDs: "",
    productIDs: "",
    isAdmin: false,
    isTurnCar: false,
  } as UpdateSecurity;

  return defaultUpdateSecurity;
};

const RetrieveNoticeTypes = () => {
    return useAxiosGet<NoticeType[]>(isLocal() ? `/UserNotice/RetrieveNoticeTypes/noticetypes` : `/noticetypes`, [], "Retrieve Notice Types");
};

const RetrieveUsersLevelNoticeInfo = (userID: string, noticeID: string, locationID: string) => {
    return useAxiosGet<UserLevelNotice>(isLocal() ? `/UserNotice/RetrieveUsersLevelNoticeInfo/users/${userID}/notice/${noticeID}/locations/${locationID}/informations` : `/users/${userID}/notice/${noticeID}/locations/${locationID}/informations`, GetDefaultUserLevelNoticeInfo(), "Retrieve Users Level Notice Info");
};

const RetrieveUsers = () => {
    return useAxiosGet<NoticeUsers[]>(isLocal() ? `/UserNotice/RetrieveUsers/users` : `/users`, [], "Retrieve Users");
};

const RetrieveUserList = (customerNo: string) => {
    return useAxiosGet<Users>(isLocal() ? `/UserNotice/RetrieveUserList/customers/${customerNo}/userdetails` : `/customers/${customerNo}/userdetails`, GetDefaultUserList(), "Retrieve User List");
};

const RetrieveLevelUserList = (customerNo: string, locationID?: string) => {
    let optionsParamsURL = '';
    if (locationID != null && locationID !== '' && locationID !== undefined) {
        optionsParamsURL += `locationID=${locationID}&`;
    }
    return useAxiosGet<LevelUsers>(isLocal() ? `/UserNotice/RetrieveLevelUserList/customers/${customerNo}/levelusers?${optionsParamsURL}` : `/customers/${customerNo}/levelusers?${optionsParamsURL}`, GetDefaultLevelUserList(), "Retrieve User level List");
};

const RetrieveUsersNoticeInfo = (userID: string, noticeID: string) => {
    return useAxiosGet<UserNoticeInfo>(isLocal() ? `/UserNotice/RetrieveUsersNoticeInfo/users/${userID}/notice/${noticeID}/noticeusers` : `/users/${userID}/notice/${noticeID}/noticeusers`, GetDefaultUserNoticeInfo(), "Retrieve Users Notice Info");
};

const DeleteLevelNotice = (uId: string, locationID: string, noticeID: string) => {
    return useAxiosDelete(isLocal() ? `/UserNotice/DeleteLevelNotice/users/${uId}/locations/${locationID}/notice/${noticeID}` : `/users/${uId}/locations/${locationID}/notice/${noticeID}`, "Delete Level Notice");
};

const DeleteNotice = (uId: string, noticeID: string) => {
    return useAxiosDelete(isLocal() ? `/UserNotice/DeleteNotice/users/${uId}/notice/${noticeID}` : `/users/${uId}/notice/${noticeID}`, "Delete Notice");
};

const CreateNotice = (data: Notice) => {
    return useAxiosPost(isLocal() ? `/UserNotice/CreateNotice/notice` : `/notice`, data, "Create Notice");
};

const RetrieveUserDetailsByNameOrEmail = (emailID: string, firstName: string, lastName: string) => {
    let optionalParameters="";
    if (emailID !== "" && emailID !== null && emailID !== undefined) {
      optionalParameters = `emailID=${emailID}&`;
    }
    if (firstName !== "" && firstName !== null && firstName !== undefined) {
      optionalParameters += `firstName=${firstName}&`;
    }
    if (lastName !== "" && lastName !== null && lastName !== undefined) {
      optionalParameters += `lastName=${lastName}`;
    }
    return useAxiosGet<NoticeUsers[]>(isLocal() ? `/UserNotice/RetrieveUserDetailsByNameOrEmail/detailusers?${optionalParameters}` : 
    `/detailusers?${optionalParameters}`, [], "Retrieve User By Name or Email");
};
const RetrieveUsersPerCustForNoticeType = (noticeType: string) => {
    return useAxiosGet(isLocal() ? `/UserNotice/RetrieveUsersPerCustForNoticeType/noticeType/${noticeType}/userbynoticetypes` : `/noticeType/${noticeType}/userbynoticetypes`, [], "Retrieve Users Per Customer for notice type");
}

const RetrieveUsersWithMultCustSecurity = () => {
    return useAxiosGet(isLocal() ? `/UserNotice/RetrieveUsersWithMultCustSecurity/userwithmulticustomers` : `/userwithmulticustomers`, [], "Retrieve Users With Multiple Customer Security")
}

const RetrieveCheckUserSetUp = (emailID: string) => {
    return useAxiosGet<UserRole[]>(isLocal() ? `/UserNotice/RetrieveCheckUserSetUp/users/${emailID}/setupusers` : `/users/${emailID}/setupusers`, [], "Retrieve User Role");
};

const UpdateUserSecurity = (data:UpdateSecurity) => {
    return useAxiosPut(isLocal() ?`/UserNotice/UpdateUserSecurity/updateuserdetails`:`/updateuserdetails`, data, "Update User Security");
  };

const CreateUser = (data: UserCreate) => {
    return useAxiosPost(isLocal() ? `/UserNotice/CreateUser/addusers` : `/addusers`, data, "Create User");
};

const DeleteUserSettings = (userID: number) => {
    return useAxiosDelete(isLocal() ?`/UserNotice/DeleteUserSettings/users/${userID}`:`/users/${userID}`, userID, "Delete User");
  };

export default {
    RetrieveUsersNoticeInfo,
    RetrieveUsersLevelNoticeInfo,
    RetrieveUsers,
    RetrieveNoticeTypes,
    RetrieveUserList,
    RetrieveLevelUserList,
    CreateNotice,
    DeleteLevelNotice,
    DeleteNotice,
    RetrieveUserDetailsByNameOrEmail,
    RetrieveUsersPerCustForNoticeType,
    RetrieveUsersWithMultCustSecurity,
    GetDefaultNoticeRequest,
    RetrieveCheckUserSetUp,
    UpdateUserSecurity,
    GetDefaultUpdateSecurity,
    GetDefaultUser,
    CreateUser,
    DeleteUserSettings
};
