
interface Props {
    msg: any;
    colorMsg:any;
  }
const SystemMessage = (props: React.PropsWithChildren<Props>) => {
    return (
        <div>
            <span style={{color:props.colorMsg}}>{props.msg}</span>
        </div>
    );
  };
  
  export default SystemMessage;
  