/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { Card, CardContent, Grid, TableBody } from "@mui/material";
import CustomCardHeader from "../../shared/CustomCardHeader";
import { IFilterBandState } from "../../../interfaces/storeInterfaces";
import { useSelector } from "react-redux";
import { filterBandSelector, useAppDispatch } from "../../../store/store";
import CustomerDataService from "../../../services/customerService";
import ProductDataService from "../../../services/productService";
import LocationDataService from "../../../services/locationService";
import {
  ProductInventories,
  ProductInventory,
  ProductShipment,
  RailcarInventory,
} from "../../../models/product";
import { TableHeaderCell } from "../../../models/common";
import useTable from "../../../hooks/useTable";
import { StyledTableCell, StyledTableRow } from "../../controls/tablecontrols";
import { FormatInvResult } from "../../shared/CommonFunctions";
import React from "react";
import { filterBandActions } from "../../../store/filterband-slice";

function ProductInventoriesTable() {
  const dispatch = useAppDispatch();
  const filterBandState: IFilterBandState = useSelector(filterBandSelector);
  const [currentInventories, setCurrentInventories] = useState<
    ProductInventories[]
  >([]);
  const [sortedCurrentInventories, setSortedCurrentInventories] = useState<
    ProductShipment[]
  >([]);
  const [inTransitInventories, setInTransitInventories] = useState<
    ProductInventory[]
  >([]);
  const [sortedInTransitInventories, setSortedInTransitInventories] = useState<
    ProductShipment[]
  >([]);
  const [railcarInventories, setRailcarInventories] = useState<
    RailcarInventory[]
  >([]);
  const [sortedRailcarInventories, setSortedRailcarInventories] = useState<
    RailcarInventory[]
  >([]);

  const [sortedTotalInventories, setSortedTotalInventories] = useState<
    ProductShipment[]
  >([]);

  const { fetchData: fetchCurrentInventoriesData } =
    CustomerDataService.GetProductInventories(
      filterBandState.selectedCustomerNo
    );

  const { fetchData: fetchLocationCurrentInventoriesData } =
    LocationDataService.GetProductInventoryByDate(
      filterBandState.selectedCustomerNo,
      filterBandState.selectedLocationNo
    );

  const { fetchData: fetchInTransitInventoriesData } =
    CustomerDataService.GetSumIntransitInv(
      filterBandState.selectedCustomerNo,
      filterBandState.selectedLocationNo
    );

  const { fetchData: fetchInTransitInventoriesDataByLocation } =
    CustomerDataService.RetrieveSumIntransitInvByLocation(
      filterBandState.selectedCustomerNo,
      filterBandState.selectedLocationNo
    );

  const { fetchData: fetchRailcarInventory } =
    ProductDataService.RetrieveRailCarInventories(
      filterBandState.selectedCustomerNo,
      filterBandState.selectedLocationNo
    );

  useEffect(() => {}, []);

  const headerCellsCurrentInventory: TableHeaderCell[] = [
    { id: "product_desc", label: "Product Description" },
    { id: "product_code", label: "Product Code" },
    { id: "inventory", label: "Current Inventory" },
  ];

  const headerCellsInTransitInventory: TableHeaderCell[] = [
    { id: "product_desc", label: "In-Transit Product" },
    { id: "product_code", label: "Product Code" },
    { id: "inventory", label: "In-Transit Inventory" },
  ];

  const headerCellsRailcarInventory: TableHeaderCell[] = [
    { id: "product_desc", label: "On-site (RL) Product" },
    { id: "product_code", label: "Product Code" },
    { id: "railcar_Inv", label: "On-site (RL) Inventories" },
  ];

  const {
    Tbl: TblCurrent,
    GetRowsAfterPagingAndSorting: GetRowsCurrentProductInventory,
  } = useTable<ProductShipment>(
    sortedCurrentInventories,
    headerCellsCurrentInventory,
    false
  );

  const {
    Tbl: TblInTransit,
    GetRowsAfterPagingAndSorting: GetRowsInTransitProductInventory,
  } = useTable<ProductShipment>(
    sortedInTransitInventories,
    headerCellsInTransitInventory,
    false
  );

  const {
    Tbl: TblRailcar,
    GetRowsAfterPagingAndSorting: GetRowsRailcarInventory,
  } = useTable<RailcarInventory>(
    sortedRailcarInventories,
    headerCellsRailcarInventory,
    false
  );

  const clearInventories = () => {
    setCurrentInventories([]);
    setInTransitInventories([]);
    setRailcarInventories([]);
  };

  useEffect(() => {
    if (filterBandState.selectedLocationNo) {
      fetchLocationCurrentInventoriesData().then((res) => {
        setCurrentInventories(res.responseData);
        filterBandState.selectedProductID !== ""
          ? sortCurrent(
              res.responseData,
              "Location",
              filterBandState.selectedProductID
            )
          : sortCurrent(res.responseData, "Location");
        if (res.isSuccess) {
          fetchInTransitInventoriesDataByLocation().then((res) => {
            setInTransitInventories(res.responseData);
            filterBandState.selectedProductID !== ""
              ? sortIntransit(
                  res.responseData,
                  filterBandState.selectedProductID
                )
              : sortIntransit(res.responseData);
          });
          fetchRailcarInventory().then((res) => {
            setRailcarInventories(res.responseData);
            filterBandState.selectedProductID !== ""
              ? sortRailcar(res.responseData, filterBandState.selectedProductID)
              : sortRailcar(res.responseData);
          });
        } else {
          setInTransitInventories([]);
        }
      });
    } else if (filterBandState.selectedCustomerNo) {
      fetchCurrentInventoriesData().then((res) => {
        setCurrentInventories(res.responseData);
        filterBandState.selectedProductID !== ""
          ? sortCurrent(
              res.responseData,
              "Customer",
              filterBandState.selectedProductID
            )
          : sortCurrent(res.responseData, "Customer");
        if (res.isSuccess) {
          fetchInTransitInventoriesData().then((res) => {
            setInTransitInventories(res.responseData);
            filterBandState.selectedProductID !== ""
              ? sortIntransit(
                  res.responseData,
                  filterBandState.selectedProductID
                )
              : sortIntransit(res.responseData);
          });
          fetchRailcarInventory().then((res) => {
            setRailcarInventories(res.responseData);
            filterBandState.selectedProductID !== ""
              ? sortRailcar(res.responseData, filterBandState.selectedProductID)
              : sortRailcar(res.responseData);
          });
        } else {
          setInTransitInventories([]);
        }
      });
    } else {
      clearInventories();
    }
  }, [
    filterBandState.selectedCustomerNo,
    filterBandState.selectedLocationNo,
    filterBandState.selectedProductID,
  ]);

  const sortCurrent = (data: any, dataType: string, productCode?: string) => {
    let arrayCurrent = [];
    for (let i = 0; i < data.length; i++) {
      let product: ProductShipment = {
        inventory:
          dataType === "Customer"
            ? data[i].current_Inventories
            : data[i].current_Inv,
        rail_user:
          dataType === "Customer" ? data[i].rail_user : data[i].rail_User,
        uom: data[i].uom,
        product_id:
          dataType === "Customer" ? data[i].product_id : data[i].product_ID,
        product_code:
          dataType === "Customer" ? data[i].product_code : data[i].product_Code,
        product_desc:
          dataType === "Customer" ? data[i].product_desc : data[i].product_Desc,
      };
      if (
        productCode !== undefined &&
        productCode !== null &&
        productCode !== "" &&
        productCode !== "ALL"
      ) {
        if (product.product_code === productCode) {
          arrayCurrent.push(product);
        }
      } else {
        arrayCurrent.push(product);
      }
    }
    setSortedCurrentInventories(arrayCurrent);
  };

  const sortIntransit = (data: any, productCode?: string) => {
    setSortedInTransitInventories([]);
    let arrayInTransit = [];
    for (let i = 0; i < data.length; i++) {
      let product: ProductShipment = {
        inventory: String(data[i].intransit_inv),
        rail_user: data[i].rail_user,
        uom: data[i].uom,
        product_id: data[i].product_id,
        product_code: data[i].product_code,
        product_desc: data[i].product_desc,
      };
      if (
        productCode !== undefined &&
        productCode !== null &&
        productCode !== "" &&
        productCode !== "ALL"
      ) {
        if (product.product_code === productCode) {
          arrayInTransit.push(product);
        }
      } else {
        arrayInTransit.push(product);
      }
    }
    setSortedInTransitInventories(arrayInTransit);
  };

  const sortRailcar = (data: any, productCode?: string) => {
    setSortedRailcarInventories([]);
    let arrayRailcar = [];
    for (let i = 0; i < data.length; i++) {
      if (
        productCode !== undefined &&
        productCode !== null &&
        productCode !== "" &&
        productCode !== "ALL"
      ) {
        if (data[i].product_code === productCode) {
          arrayRailcar.push(data[i]);
        }
      } else {
        arrayRailcar.push(data[i]);
      }
    }
    setSortedRailcarInventories(arrayRailcar);
  };

  useEffect(() => {
    if (filterBandState.selectedProductID !== "") {
      productChange(filterBandState.selectedProductID);
    }
  }, [filterBandState.selectedProductID]);

  const productChange = (productCode: string) => {
    dispatch(
      filterBandActions.setSelectedProduct({
        productId: productCode,
      })
    );
    sortIntransit([]);
    if (productCode !== "ALL") {
      if (filterBandState.selectedLocationNo) {
        sortCurrent(currentInventories, "Location", productCode);
      } else {
        sortCurrent(currentInventories, "Customer", productCode);
      }
      sortIntransit(inTransitInventories, productCode);
      sortRailcar(railcarInventories, productCode);
    } else {
      if (filterBandState.selectedLocationNo) {
        sortCurrent(currentInventories, "Location");
      } else {
        sortCurrent(currentInventories, "Customer");
      }
      sortIntransit(inTransitInventories);
      sortRailcar(railcarInventories);
    }
  };

  useEffect(() => {
    if (sortedRailcarInventories.length > 0) {
      let arrayTotal: ProductShipment[] = [];
      for (let i = 0; i < sortedCurrentInventories.length; i++) {
        let totalInventory = 0;
        let productCode = sortedCurrentInventories[i].product_code;
        if (
          sortedCurrentInventories[i].inventory !== null &&
          sortedCurrentInventories[i].inventory !== ""
        ) {
          totalInventory += Number(sortedCurrentInventories[i].inventory);
        }
        for (let j = 0; j < sortedInTransitInventories.length; j++) {
          if (sortedInTransitInventories[j].product_code === productCode) {
            if (
              sortedInTransitInventories[j].inventory !== null &&
              sortedInTransitInventories[j].inventory !== ""
            ) {
              totalInventory += Number(sortedInTransitInventories[j].inventory);
              break;
            }
          }
        }
        for (let k = 0; k < sortedRailcarInventories.length; k++) {
          if (sortedRailcarInventories[k].product_code === productCode) {
            if (
              sortedRailcarInventories[k].railcar_Inv !== null &&
              sortedRailcarInventories[k].railcar_Inv !== undefined
            ) {
              totalInventory += Number(sortedRailcarInventories[k].railcar_Inv);
              break;
            }
          }
        }
        let objTotal = {
          product_code: sortedCurrentInventories[i].product_code,
          product_desc: sortedCurrentInventories[i].product_desc,
          inventory: String(totalInventory),
          rail_user: sortedCurrentInventories[i].rail_user,
          uom: sortedCurrentInventories[i].uom,
          product_id: sortedCurrentInventories[i].product_id,
        };
        arrayTotal.push(objTotal);
      }
      setSortedTotalInventories(arrayTotal);
    }
  }, [
    sortedCurrentInventories,
    sortedInTransitInventories,
    sortedRailcarInventories,
  ]);

  const headerCellsTotalInventory: TableHeaderCell[] = [
    { id: "product_desc", label: "Product" },
    { id: "product_code", label: "Product Code" },
    { id: "inventory", label: "Total Inventories" },
  ];

  const {
    Tbl: TblTotal,
    GetRowsAfterPagingAndSorting: GetRowsTotalProductInventory,
  } = useTable<ProductShipment>(
    sortedTotalInventories,
    headerCellsTotalInventory,
    false
  );

  return (
    <React.Fragment>
      <Card sx={{ width: "100hv", borderRadius: 3, margin: 1 }} elevation={6}>
        <CustomCardHeader title="Product Inventories"></CustomCardHeader>
        <CardContent>
          <Grid container columnSpacing={{ xs: 1, sm: 1, md: 4, lg: 4 }}>
            <Grid
              item
              xs={12}
              sm={12}
              md={sortedRailcarInventories.length > 0 ? 6 : 6}
              lg={sortedRailcarInventories.length > 0 ? 6 : 6}
            >
              <TblCurrent>
                {sortedCurrentInventories.length > 0 ? (
                  <TableBody>
                    {GetRowsCurrentProductInventory().map(
                      (row, index) =>
                        row.rail_user === true && (
                          <StyledTableRow
                            key={"Current" + index + row.product_code}
                          >
                            <StyledTableCell>
                              {row.product_desc}
                            </StyledTableCell>
                            <StyledTableCell>
                              {row.product_code}
                            </StyledTableCell>
                            <StyledTableCell>
                              {FormatInvResult(
                                parseFloat(row.inventory),
                                row.uom
                              )}
                            </StyledTableCell>
                          </StyledTableRow>
                        )
                    )}
                  </TableBody>
                ) : (
                  <TableBody></TableBody>
                )}
              </TblCurrent>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={sortedRailcarInventories.length > 0 ? 6 : 6}
              lg={sortedRailcarInventories.length > 0 ? 6 : 6}
            >
              <TblInTransit>
                {sortedInTransitInventories.length > 0 ? (
                  <TableBody>
                    {GetRowsInTransitProductInventory().map(
                      (row, index) =>
                        row.rail_user === true && (
                          <StyledTableRow
                            key={"Intrasnit" + index + row.product_code}
                          >
                            <StyledTableCell>
                              {row.product_desc}
                            </StyledTableCell>
                            <StyledTableCell>
                              {row.product_code}
                            </StyledTableCell>
                            <StyledTableCell>
                              {FormatInvResult(
                                parseFloat(row.inventory),
                                row.uom
                              )}
                            </StyledTableCell>
                          </StyledTableRow>
                        )
                    )}
                  </TableBody>
                ) : (
                  <TableBody></TableBody>
                )}
              </TblInTransit>
            </Grid>
            {sortedRailcarInventories.length > 0 && (
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <TblRailcar>
                  <TableBody>
                    {GetRowsRailcarInventory().map(
                      (row, index) =>
                        row.rail_user === true && (
                          <StyledTableRow
                            key={"Railcar" + index + row.product_code}
                          >
                            <StyledTableCell>
                              {row.product_desc}
                            </StyledTableCell>
                            <StyledTableCell>
                              {row.product_code}
                            </StyledTableCell>
                            <StyledTableCell>
                              {FormatInvResult(row.railcar_Inv, row.uom)}
                            </StyledTableCell>
                          </StyledTableRow>
                        )
                    )}
                  </TableBody>
                </TblRailcar>
              </Grid>
            )}
            {sortedRailcarInventories.length > 0 && (
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <TblTotal>
                  <TableBody>
                    {GetRowsTotalProductInventory().map(
                      (row, index) =>
                        row.rail_user === true && (
                          <StyledTableRow
                            key={"Total" + index + row.product_code}
                          >
                            <StyledTableCell>
                              {row.product_desc}
                            </StyledTableCell>
                            <StyledTableCell>
                              {row.product_code}
                            </StyledTableCell>
                            <StyledTableCell>
                              {FormatInvResult(Number(row.inventory), row.uom)}
                            </StyledTableCell>
                          </StyledTableRow>
                        )
                    )}
                  </TableBody>
                </TblTotal>
              </Grid>
            )}
          </Grid>
          {/* <Grid container columnSpacing={{ xs: 1, sm: 1, md: 4, lg: 4 }}>
            {renderTable()}
          </Grid> */}
        </CardContent>
      </Card>
    </React.Fragment>
  );
}

export default ProductInventoriesTable;
