import useAxiosGet from "../hooks/useAxiosGet";
import useAxiosPost from "../hooks/useAxiosPost";
import useAxiosPut from "../hooks/useAxiosPut";

import { OrderSchedule, VerifyOrderSplit, OpenOrdersModel, ArrivalAdjustment, OrderNotesPayload, ArrivalAdjustmentDetails, RailcarOrders, RailcarHistory } from "../models/order";

const isLocal = () => (process.env.REACT_APP_ENVIR === 'LOCAL');
const userId = () => sessionStorage.getItem("userMailID");

const GetDefaultOrderSchedule = (): OrderSchedule => {
  const defaultCustomer = {
    producT_ID: 0,
    locatioN_ID: 0,
    loC_SHIPTO: 0,
    loC_NAME: '',
    proD_CODE: 0,
    proD_DESC: '',
    reordeR_QTY: 0,
    reordeR_PNT: '',
    avG_DAILY_USAGE: '',
    usagE_OVERRIDE: '',
    esT_ORDER_DATE: '',
    esT_ARRIVAL_DATE: '',
    maX_SHIP_QTY: 0,
    uom: '',
    ordeR_STATE: '',
    ordeR_NO: 0,
    ordeR_STATUS: '',
    ordeR_NOTES: '',
    inventory: '',
    capacity: '',
    actuaL_INV: '',
    iN_TRANSIT_INV: '',
    wknD_FLG: '',
    copc: 0,
    raiL_USER: false,
    ordeR_DATE: '',
    arrivaL_DATE: '',
    ordeR_ID: 0,
    specific_Gravity: '',
    week_Avg_Display: 0,
    vpL_CALCULATED_WEEK_AVG: '',
    vpL_OPERATING_DAYS: 0
  } as OrderSchedule;

  return defaultCustomer;
}

const getDefaultOpenOrder = (): OpenOrdersModel => {
  const defaultOpenOrder = {
    loC_SHIPTO: null,
    loC_NAME: null,
    proD_CODE: null,
    proD_DESC: null,
    reordeR_QTY: 0,
    reordeR_PNT: 0,
    ordeR_DATE: null,
    arrivaL_DATE: null,
    maX_SHIP_QTY: 0,
    uom: null,
    ordeR_STATE: null,
    ordeR_NO: null,
    ordeR_STATUS: null,
    inventory: 0,
    capacity: 0,
    actuaL_INV: 0,
    iN_TRANSIT_INV: 0,
    wknD_FLG: null,
    copc: null,
    specific_Gravity: 0
  } as any;
  return defaultOpenOrder;
}


const GetDefaultArrivalAdjustmentDetails = (): ArrivalAdjustmentDetails => {
  const defaultArrivalAdjustmentDetails = {
    vO_ORDER_ID: 0,
    vO_LOCATION_ID: 0,
    vO_ENTRY_DATE: '',
    vO_TANK_ID: 0,
    vO_PRODUCT_ID: 0,
    vC_CUSTOMER_NAME: '',
    vL_LOCATION_NAME: '',
    vT_TANK_NAME: '',
    vP_PRODUCT_DESC: '',
    vS_SHIPMENT_AMOUNT: 0,
    voaA_TANK_INVENTORY: '',
    voaA_TANK_INVENTORY_TIMESTAMP: '',
    voaA_ORIGINAL_ARRIVAL_DATE: '',
    voaA_ADJUSTED_ARRIVAL_DATE: '',
    voaA_ADJUSTED_DATETIME_STAMP: ''
  } as ArrivalAdjustmentDetails;

  return defaultArrivalAdjustmentDetails;
}

const GetdefaultArrivalAdjustment = (): ArrivalAdjustment => {
  const defaultArrivalAdjustment = {
    voaA_ORDER_ID: 0,
    voaA_ADJUSTED_ARRIVAL_DATE: '',
  } as ArrivalAdjustment;

  return defaultArrivalAdjustment;
}

const GetDefaultOrderNotesPayload = () : OrderNotesPayload => {
   const orderNotesReading =  {
    orderID: null,
    vO_NOTES: "",
  } as OrderNotesPayload;

  return orderNotesReading;
}

const GetDefaultRailcarOrders = (): RailcarOrders => {
  const defaultRailcarOrderDetails = {
      vS_CAR_NO: "",
      vS_SHIPPED_DATE: "",
      vS_SHIPMENT_AMOUNT: 0,
      vO_NET_WEIGHT:0,
      vO_RAILCAR_RECEIVED_DATE: "",
      vO_ADM_ORDER_NO: "",
      vO_INVOICE_NO: "",
      vO_RAILCAR_SHIPPED_DATE: "",
      vO_RAILCAR_LOAD_DATE: "",
      vO_SPLIT_ORDER: true,
      vP_PRODUCT_DESC:"",

  } as RailcarOrders;
  return defaultRailcarOrderDetails;
}




const RetrieveOrderSchedule = (customerNo: string, sortKey: string, locationNo: string) => {
  let optionsParamsURL = '';
    if (sortKey != null && sortKey !== '' && sortKey !== undefined) {
        optionsParamsURL += `sortKey=${sortKey}&`;
    }
    if (locationNo != null && locationNo !== '' && locationNo !== undefined) {
        optionsParamsURL += `locationNo=${locationNo}&`;
    }
  return useAxiosGet<OrderSchedule>(isLocal() ? `/Order/RetrieveOrderSchedule/users/${userId()}/customers/${customerNo}/orderschedules?${optionsParamsURL}`
    : `/users/${userId()}/customers/${customerNo}/orderschedules?${optionsParamsURL}`, GetDefaultOrderSchedule(), "Order Schedule Details");
};

const RetrieveVerifyOrderSplit = (threshold: number, percent: number, quantity: number) => {
  return useAxiosGet<VerifyOrderSplit[]>(isLocal() ? `/Order/RetrieveVerifyOrderSplit/threshold/${threshold}/percent/${percent}/quantity/${quantity}/verifyordersplits`
    : `/threshold/${threshold}/percent/${percent}/quantity/${quantity}/verifyordersplits`, [], "Verify Order Split");
};

const RetrieveOpenOrders = (customerNo: string, locationNo: string, sortKey: string) => {

  let optionalParameter = '';
  if (sortKey !== undefined && sortKey !== '' && sortKey !== null) {
    optionalParameter += '?sortKey=' + sortKey + '&';
  }
  if (locationNo !== undefined && locationNo !== '' && locationNo !== null) {
    optionalParameter += 'locationNo=' + locationNo;
  }

  return useAxiosGet<OpenOrdersModel[]>(isLocal() ? `/Order/RetrieveOpenOrders/users/${userId()}/customers/${customerNo}/openorders${optionalParameter}`
    : `/users/${userId()}/customers/${customerNo}/openorders${optionalParameter}`,
    [],
    "Open Orders");
};

const RetrieveOrderArrivalAdjustmentDictionary = (orderIds: any) => {
  return useAxiosPost(isLocal() ? `/Order/RetrieveOrderArrivalAdjustmentDictionary/orderarrivaladjustments`
    : `/orderarrivaladjustments`, orderIds, "Order Arrival Adjustment Details");
};

const RetrieveOrderNotes = (locationID: number, orderID: number, productID: number) => {
  return useAxiosGet<OrderNotesPayload>(isLocal() ? `/Order/RetrieveOrderNotes/locationID/${locationID}/productID/${productID}/orderID/${orderID}/ordernotes`
    : `/locationID/${locationID}/productID/${productID}/orderID/${orderID}/ordernotes`, GetDefaultOrderNotesPayload(), "Order Notes Details");
};

const UpdateOrderNotes = (data: OrderNotesPayload) => {
  return useAxiosPut(isLocal() ? `/Order/UpdateOrderNotes/ordernotes`
    : `/ordernotes`, data, "Order Notes - Update Order Notes");
};

const RetrieveAdjustmentDetails = (orderId: number) => {
  return useAxiosGet<ArrivalAdjustmentDetails>(isLocal() ? `/Order/RetrieveAdjustmentDetails/orderID/${orderId}/adjustmentdetails`
    : `/orderID/${orderId}/adjustmentdetails`, GetDefaultArrivalAdjustmentDetails(), "Order Schedule Details");
};


const RetrieveOrderAgainstRailCar = (carNumber: string, shippedDates: string, locationNo: string) => {
  return useAxiosGet<RailcarOrders>(isLocal() ? `/Order/RetrieveOrderAgainstRailCar/cars/${carNumber}/shippedDates/${shippedDates}/locations/${locationNo}/orderagainstrailcars`
      : `/cars/${carNumber}/shippedDates/${shippedDates}/locations/${locationNo}/orderagainstrailcars`, GetDefaultRailcarOrders(), "Railcar Inventory");
};


const RetrieveOrderAgainstRailCarHistory = (locationNo: string,fromdates:string,todates:string) => {
  return useAxiosGet<RailcarOrders>(isLocal() ? `/Order/RetrieveOrderAgainstRailCarHistory/locations/${locationNo}/fromdates/${fromdates}/todates/${todates}/orderagainstrailcarhistories`
      : `/locations/${locationNo}/fromdates/${fromdates}/todates/${todates}/orderagainstrailcarhistories`, GetDefaultRailcarOrders(), "Railcar Inventory History");
};

const RetrieveRailCarHistory = (locationNo: string, productCode: string, fromDate:string, toDate:string) => {
  return useAxiosGet<RailcarHistory[]>(isLocal() ? `/Order/RetrieveRailCarHistory/locations/${locationNo}/products/${productCode}/fromdates/${fromDate}/todates/${toDate}/railcarhistories`
      : `/locations/${locationNo}/products/${productCode}/fromdates/${fromDate}/todates/${toDate}/railcarhistories`, [], "Railcar History");
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  GetDefaultOrderSchedule,
  RetrieveOrderSchedule,
  RetrieveVerifyOrderSplit,
  getDefaultOpenOrder,
  RetrieveOpenOrders,
  GetdefaultArrivalAdjustment,
  RetrieveOrderArrivalAdjustmentDictionary,
  RetrieveOrderNotes,
  UpdateOrderNotes,
  GetDefaultArrivalAdjustmentDetails,
  RetrieveAdjustmentDetails,
  GetDefaultOrderNotesPayload,
  RetrieveOrderAgainstRailCar,
  RetrieveOrderAgainstRailCarHistory,
  RetrieveRailCarHistory
};