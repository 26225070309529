import NoticeRequest from "../components/app/User/NoticeRequest";
import SystemMessage from "./SystemMessage";

function NoticeRequestPage() {
  const role = sessionStorage.getItem("roles");
  return role?.toString().toUpperCase() === "ADMIN" ? (
    <NoticeRequest></NoticeRequest>
  ) : (
    <SystemMessage msg="Access Denied." colorMsg="Red"></SystemMessage>
  );
}
export default NoticeRequestPage;
