/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import {
    Grid,
    TextField,
    Button,
    Container,
    Divider,
    Checkbox,
    Select,
    MenuItem,
    InputLabel,
    FormControl,
} from "@mui/material";

import { useFormik } from "formik";
import * as yup from "yup";

import { IFilterBandState } from "../../../interfaces/storeInterfaces";
import { filterBandSelector, useAppDispatch } from "../../../store/store";

import ShipmentDataService from "../../../services/shipmentService";
import { useSelector } from "react-redux";
import { LocationShipment, ProductCode, UpdateShipment } from "../../../models/shipment";
import dayjs from "dayjs";
import { uiActions } from "../../../store/ui-slice";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { DatePicker } from "@mui/x-date-pickers";

interface LocationShipmentMaintenanceProps {
    setShowModalPopup: React.Dispatch<React.SetStateAction<boolean>>;
    orderId: string;
    shipTo: string;
    setRefreshList: React.Dispatch<React.SetStateAction<boolean>>;
}

function LocationShipmentMaintenance(props: LocationShipmentMaintenanceProps) {
    //const [loadoutChecked,setLoadoutchecked] = useState(false)
    const [checkedLoadout, setcheckedLoadout] = useState(false);
    const dispatch = useAppDispatch();
    const filterBandState: IFilterBandState = useSelector(filterBandSelector);
    const [productList, setProductList] = useState<ProductCode[]>([]);
    const [orderId, setOrderId] = useState('');
    const { fetchData: fetchLocationShipmentData } = ShipmentDataService.RetrieveLocationShipmentSummary(filterBandState.selectedCustomerNo, filterBandState.selectedLocationNo, props.orderId);
    const { fetchData: fetchProductData } = ShipmentDataService.RetrieveProductsByLocation(filterBandState.selectedCustomerNo, filterBandState.selectedLocationNo);
    const [shipmentInfo, setShipmentInformation] = useState<UpdateShipment>(ShipmentDataService.GetDefaultUpdateLocationShipmentDetails());
    const { putData: updateLocationShipmentData } = ShipmentDataService.UpdateLocationShipment(shipmentInfo);

    useEffect(() => {
        loadLocationShipmentSummaryById(props.orderId);
        setOrderId(props.orderId);
    }, [props.orderId]);

    useEffect(() => {
        if (orderId !== "" && orderId !== null && orderId !== undefined) {
            dispatch(uiActions.startProgressBar());
            updateLocationShipmentData().then((res) => {
                if (res.isSuccess) {
                    dispatch(
                        uiActions.displayNotification({
                            message: "The shipment information has been saved successfully.",
                            severity: "success",
                        })
                    );
                    props.setShowModalPopup(false);
                }
            });
        }
    }, [shipmentInfo]);

    const loadLocationShipmentSummaryById = (orderId: string) => {
        if (orderId !== "" && orderId !== undefined && orderId !== null) {
            dispatch(uiActions.startProgressBar());
            fetchProductData().then((resProduct) => {
                setProductList(resProduct.responseData);
            });
            fetchLocationShipmentData().then((res) => {
                if (res.responseData[0].weight < 0) {
                    res.responseData[0].weight = res.responseData[0].weight.toString().replace('-', '');
                    setcheckedLoadout(true);
                } else {
                    setcheckedLoadout(false);
                }
                formik.setValues(res.responseData[0]);
                if (res.responseData[0].comments === null || res.responseData[0].comments === undefined) { formik.setFieldValue("comments", "") }
                else { formik.setFieldValue("comments", res.responseData[0].comments) }
            });
        }
    };

    // Form Validation
    const validationSchema = yup.object({
        deliveryDate: yup.string().nullable().required("Required"),
        weight: yup
            .number()
            .typeError("Truck Weight should be a number")
            .required("Truck Weight is required"),
        productID: yup.string().required("Product is required")
    });

    //form configuration
    const formik = useFormik({
        initialValues: ShipmentDataService.GetDefaultLocationShipmentDetails(),
        validationSchema: validationSchema,

        onSubmit: (values: LocationShipment) => {
            if (orderId !== "") {
                if (checkedLoadout) {
                    if (values.weight.toString().indexOf('-') > -1) {
                        // Nothing will change
                    } else {
                        values.weight = '-' + values.weight;
                    }
                } else if (values.weight < '0') {
                    values.weight = values.weight.toString().replace('-', '');
                }
                setShipmentInformation(values);
                props.setShowModalPopup(false);
                props.setRefreshList(true);
            }
        }
    });

    const dateChanger = (newValue: dayjs.Dayjs | null) => {
        if (newValue !== null && dayjs(newValue).isValid()) {
            formik.setFieldValue("deliveryDate", dayjs(newValue).format('MM/DD/YYYY'));
        } else if (newValue === null) {
            formik.setFieldValue("deliveryDate", null)
        } else {
            formik.setFieldValue("deliveryDate", newValue)
        }
    }

    const handleCheckBoxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const isChecked = event.target.checked;
        setcheckedLoadout(isChecked)
    }

    return (
        <React.Fragment>
            <Container>
                <form
                    onSubmit={formik.handleSubmit}
                    id="frmLocationShipmentMaintenance"
                    className="form"
                >
                    <Grid container spacing={3} sx={{ paddingTop: 1 }}>
                        <Grid item xs={9} sm={9} md={9}>
                            <TextField
                                name="orderNo"
                                label="Order #"
                                value={formik.values.orderNo.trim()}
                                fullWidth
                                onChange={formik.handleChange}
                                error={
                                    formik.touched.orderNo &&
                                    Boolean(formik.errors.orderNo)
                                }
                                helperText={
                                    formik.touched.orderNo &&
                                    formik.errors.orderNo
                                }
                                inputProps={{ maxLength: 6 }}
                            />
                        </Grid>
                        <Grid item xs={9} sm={9} md={9}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                    label="Delivery Date"
                                    value={formik.values.deliveryDate !== null ? dayjs(formik.values.deliveryDate) : null}
                                    onChange={(newValue) => { dateChanger(newValue) }}
                                    slotProps={{
                                        textField: {
                                            variant: "standard",
                                            error: formik.touched.deliveryDate && Boolean(formik.errors.deliveryDate),
                                            helperText: formik.touched.deliveryDate && formik.errors.deliveryDate
                                        }
                                    }}
                                />
                            </LocalizationProvider>
                        </Grid>
                        <Grid item xs={9} sm={9} md={9}>
                            <TextField
                                name="weight"
                                label="Truck Weight (lbs) *"
                                value={formik.values.weight}
                                fullWidth
                                onChange={formik.handleChange}
                                error={
                                    formik.touched.weight &&
                                    Boolean(formik.errors.weight)
                                }
                                helperText={
                                    formik.touched.weight &&
                                    formik.errors.weight
                                }
                                inputProps={{ maxLength: 10 }}
                            />
                        </Grid>
                        <Grid item xs={9} sm={9} md={9}  >
                            <span>Loadout</span>
                            <Checkbox
                                name="isChecked"
                                checked={checkedLoadout}
                                onChange={handleCheckBoxChange}
                            />
                        </Grid>
                        <Grid item xs={9} sm={9} md={9}>
                            <FormControl variant="standard" fullWidth>
                                <InputLabel id="lblProduct">Product</InputLabel>
                                <Select
                                    id="product"
                                    name="productID"
                                    value={formik.values.productID}
                                    onChange={formik.handleChange}
                                >
                                    {productList.map((row) => {
                                        return (
                                            <MenuItem key={row.vP_PRODUCT_ID} value={row.vP_PRODUCT_ID}>
                                                {row.vP_PRODUCT_CODE}
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={9} sm={9} md={9}>
                            <TextField
                                name="comments"
                                label="Comments"
                                value={formik.values.comments}
                                fullWidth
                                onChange={formik.handleChange}
                                error={
                                    formik.touched.comments &&
                                    Boolean(formik.errors.comments)
                                }
                                helperText={
                                    formik.touched.comments &&
                                    formik.errors.comments
                                }
                            />
                        </Grid>

                    </Grid>
                </form>
            </Container>
            <Divider sx={{ mt: 4, mb: 2 }}></Divider>
            <Grid
                item
                xs={12}
                sm={12}
                md={12}
                sx={{
                    justifyContent: "flex-end",
                    alignItems: "flex-end",
                    display: "flex",
                }}
            >
                <Button
                    form="frmLocationShipmentMaintenance"
                    type="submit"
                    onSubmit={(e) => formik.handleSubmit}
                >
                    Save
                </Button>
            </Grid>
        </React.Fragment>
    )
}

export default LocationShipmentMaintenance;