import { Box } from "@mui/material";
import React from "react";
import { Route, Routes } from "react-router-dom";
import { useSelector } from "react-redux";
import { IUIState } from "../../interfaces/storeInterfaces";
import NotFoundPage from "../../pages/NotFoundPage";
import { SiteContentBox } from "../controls/commoncontrols";
import FilterBand from "../shared/FilterBand";
import Footer from "./Footer";
import Header from "./Header";
import LeftNav from "./LeftNav";
import { uiSelector } from "../../store/store";
import ProgressBar from "../shared/ProgressBar";
import { RouteInfoArray } from "../../utils/data/routedata";
import NotificationHandler from "../shared/NotificationHandler";
import BackToTop from "../shared/BackToTop";
import SessionCheck from "./SessionCheck";

interface Props {
  logoutAction: any;
  userName: any;
}

function Layout(props: React.PropsWithChildren<Props>) {
  const uiState: IUIState = useSelector(uiSelector);

  const validTime: number = SessionCheck();

  setTimeout(() => {
    window.sessionStorage.clear();
    props.logoutAction();
  }, validTime);

  return (
    <React.Fragment>
      <div>
        <Header logoutAction={props.logoutAction} userName={props.userName} />
        <FilterBand></FilterBand>
        <Box display="flex" minHeight="100vh" sx={{ paddingBottom: 7 }}>
          <LeftNav></LeftNav>
          <SiteContentBox>
            <Routes>
              {RouteInfoArray.map((item) => {
                return (
                  <Route
                    key={item.path}
                    path={item.path}
                    element={item.element}
                  />
                );
              })}
              <Route path="*" element={<NotFoundPage />} />
            </Routes>
            <BackToTop />
          </SiteContentBox>
        </Box>
        <Footer></Footer>
      </div>
      {uiState.showProgressBar && <ProgressBar />}
      {uiState.showNotification && (
        <NotificationHandler
          error={uiState.error}
          message={uiState.message}
          severity={uiState.severity}
          showNotification={uiState.showNotification}
        />
      )}
    </React.Fragment>
  );
}

export default Layout;
