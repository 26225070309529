/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import { 
  Grid, 
  TextField, 
  Button, 
  Container, 
  Divider, 
  FormControlLabel, 
  RadioGroup, 
  Radio 
} from "@mui/material";
import Stack from '@mui/material/Stack';

// Store/Redux
import { useSelector } from "react-redux";
import { uiActions } from "../../../store/ui-slice";
import { IFilterBandState } from "../../../interfaces/storeInterfaces";
import { filterBandSelector, useAppDispatch } from "../../../store/store";

// Common Controls
import { LabelItem } from "../../controls/commoncontrols";

// Model
import { TankInformation, ManualTankReading } from "../../../models/tank";

// Services
import TankDataService from "../../../services/tankService";
import { FormatNumber } from "../../shared/CommonFunctions";

interface ManualTankDetailsProps {
  manualTankNo: number;
  manualTankID: number;
  setShowTankModalPopup: React.Dispatch<React.SetStateAction<boolean>>;
  setRefreshList: React.Dispatch<React.SetStateAction<boolean>>;
}

function ManualTankDetails(props: ManualTankDetailsProps) {
  const dispatch = useAppDispatch();
  const filterBandState: IFilterBandState = useSelector(filterBandSelector);
  const [manualTankNo, setManualTankNo] = useState(0);
  const {fetchData: fetchManualTankData} = TankDataService.GetTankByNumber(filterBandState.selectedCustomerNo, filterBandState.selectedLocationNo, props.manualTankNo.toString());
  const [manualTankReading, setManualTankReading] = useState<ManualTankReading>(TankDataService.UpdateManualDetails());
  const {postData: updateTankData } = TankDataService.CreateManualTankReading(manualTankReading);

  useEffect(() => {
    loadManualTankByID(props.manualTankNo);
    setManualTankNo(props.manualTankNo);
  }, [props.manualTankNo]);

  useEffect(() => {
    if (manualTankNo !== 0) {
      dispatch(uiActions.startProgressBar());
      updateTankData().then((res) => {
        if (res.isSuccess) {
          dispatch(
            uiActions.displayNotification({
              message: "Tank updated successfully!",
              severity: "success",
            })
          );
          props.setShowTankModalPopup(false);
          props.setRefreshList(true);
        }
      });
    }
  }, [manualTankReading]);

  // Form Configuration
  const validationSchema = yup.object({
    vT_Reading: yup
    .string()
    .matches(/^[.0-9]*$/, 'Reading value should be a number')
    .required("Please enter a Reading value")
  });

  const formik = useFormik({
    initialValues: TankDataService.GetDefaultTankInformation(),
    validationSchema: validationSchema,
  
    onSubmit: (values: TankInformation) => {
      if (values.vpL_UOM === 'PERCENT') {
        let reading = parseInt(values.vT_Reading);
        if (reading > 100) {
          dispatch(
            uiActions.displayNotification({
              message: "Percentage can not be exceed 100%",
              severity: "error",
            })
          );
          return;
        }
      } else if (values.vpL_UOM === 'INCHES') {
        let vtTankHeight = (values.vT_TANK_HEIGHT*12).toString();
        let reading = values.vT_Reading;
        if (reading > vtTankHeight) {
          dispatch(
            uiActions.displayNotification({
              message: "Height can not be exceed " + (values.vT_TANK_HEIGHT*12) + " inches",
              severity: "error",
            })
          );
          return;
        }
      } else if (values.vpL_UOM === 'LBS') {
        let caP_LBS = parseFloat(values.caP_LBS.toFixed(2)).toString();
        let reading = values.vT_Reading;
        if (reading > caP_LBS) {
          dispatch(
            uiActions.displayNotification({
              message: "Pounds can not be exceed " + caP_LBS + " Lbs",
              severity: "error",
            })
          );
          return;
        }
      } else if (values.vpL_UOM === 'MT') {
        let mt = parseFloat((formik.values.caP_LBS / 2204).toFixed(2)).toString();
        let reading = values.vT_Reading;
        if (reading > mt) {
          dispatch(
            uiActions.displayNotification({
              message: "Metric Tons can not be exceed " + mt + " MT",
              severity: "error",
            })
          );
          return;
        }
      }

      let manualTankReading = {
        tankID: props.manualTankID,
        fReading: values.vT_Reading,
        strUnitType: values.vpL_UOM
      };
      setManualTankReading(manualTankReading);
    }
  });

  // Change Event
  const onUnitChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValues("vpL_UOM", (event.target as HTMLInputElement).value);
  };

  // Methods
  const loadManualTankByID = async(manualTankNo: number) => {
    if (manualTankNo !== 0) {
      fetchManualTankData().then((res) => {
        if (res.responseData !== '' && res.responseData !== undefined && res.responseData !== null) {
          res.responseData['vT_Reading'] = '';
          res.responseData.vpL_UOM = 'PERCENT';
          formik.setValues(res.responseData);
        }
      });
    }
  };

  const setInputValues = useCallback(
    (key, value) =>
      formik.setValues({
        ...formik.values,
        [key]: value,
      }),
    [formik]
  );

  return (
  <React.Fragment>
      <Container>
        <form onSubmit={formik.handleSubmit} id="frmManualTankDetails" className="form">
          <Grid container spacing={3} sx={{ paddingTop: 1 }}>
            <Grid item xs={6} sm={6} md={6}>
              <LabelItem>Tank Name : {formik.values.vT_TANK_NAME}</LabelItem>
            </Grid>
            {
              <Grid item xs={6} sm={6} md={6}>
                <LabelItem>Tank Number : {formik.values.vT_TANK_NO}</LabelItem>
              </Grid>
            }
          </Grid>
          <Grid container spacing={3} sx={{ paddingTop: 1 }}>
            <Grid item xs={9} sm={9} md={9}>
              <TextField
                name="vT_Reading"
                label="Reading"
                value={formik.values.vT_Reading}
                fullWidth
                onChange={formik.handleChange}
                error={
                  formik.touched.vT_Reading && Boolean(formik.errors.vT_Reading)
                }
                helperText={
                  formik.touched.vT_Reading && formik.errors.vT_Reading
                }
              />
            </Grid>
          </Grid>
          <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} sx={{ paddingTop: 4 }}>
            <Grid item xs={6}>
              <Stack spacing={1.3}>
                <LabelItem>&nbsp;</LabelItem>
                <LabelItem style={{alignSelf:'end'}}>Values cannot exceed: </LabelItem>
                <span style={{color:"red", alignSelf:'end'}}>{formik.values.vT_TANK_HEIGHT * 12}</span>
                <span style={{color:"red", alignSelf:'end'}}>{FormatNumber(formik.values.caP_LBS, 2)}</span>
                <span style={{color:"red", alignSelf:'end'}}>{FormatNumber(formik.values.caP_LBS / 2204, 2)}</span>
              </Stack>
            </Grid>
            <Grid item xs={6}>
              <LabelItem>Units : </LabelItem>
              <RadioGroup
                aria-labelledby="vpL_UOM"
                name="vpL_UOM"
                value={formik.values.vpL_UOM}
                onChange={onUnitChange}
              >
                <FormControlLabel value="PERCENT" control={<Radio size="small" />} label="Percent" />
                <FormControlLabel value="INCHES" control={<Radio size="small" />} label="Inches" />
                <FormControlLabel value="LBS" control={<Radio size="small" />} label="Pounds" />
                <FormControlLabel value="MT" control={<Radio size="small" />} label="Metric Tons" />
              </RadioGroup>
            </Grid>
          </Grid>
        </form>
      </Container>
      <Divider sx={{ mt: 4, mb: 2 }}></Divider>
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        sx={{
          justifyContent: "flex-end",
          alignItems: "flex-end",
          display: "flex",
        }}
      >
        <Button
          form="frmManualTankDetails"
          type="submit"
          onSubmit={(e) => formik.handleSubmit}
        >
          Update
        </Button>
      </Grid>
    </React.Fragment>
  );
}

export default ManualTankDetails;