import React, { useEffect, useState } from "react";
import TableBody from "@mui/material/TableBody";
import { StyledTableCell, StyledTableRow } from "../../controls/tablecontrols";
import {
  Grid,
  TextField,
  Button,
  Container,
  Card,
  CardContent,
  MenuItem,
} from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { filterBandSelector, useAppDispatch } from "../../../store/store";
import { filterBandActions } from "../../../store/filterband-slice";
import { uiActions } from "../../../store/ui-slice";
import CustomCardHeader from "../../shared/CustomCardHeader";
import ShipmentService from "../../../services/shipmentService";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs, { Dayjs } from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import PageHeader from "../../shared/PageHeader";
import AddSharpIcon from "@mui/icons-material/AddSharp";
import { TableHeaderCell } from "../../../models/common";
import useTable from "../../../hooks/useTable";
import { ProductCode, Shipment } from "../../../models/shipment";
import { IFilterBandState } from "../../../interfaces/storeInterfaces";
import { useSelector } from "react-redux";
import { FormatInvResult } from "../../shared/CommonFunctions";
import moment from "moment";

function ShipmentSummary() {
  const dispatch = useAppDispatch();
  const filterBandState: IFilterBandState = useSelector(filterBandSelector);
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const [startDate, setStartDate] = useState<Dayjs>(dayjs(new Date()));
  const [productId, setProductId] = useState<string>("");
  const [invalidDate, setInvalidDate] = useState(false);
  const [shipmentList, setShipmentSummary] = useState<Shipment[]>([]);
  const { fetchData: fetchShowShipmentSummaryData } =
    ShipmentService.RetrieveCustomerShipmentSummary(
      filterBandState.selectedCustomerNo,
      filterBandState.selectedLocationNo,
      productId,
      startDate.format("MM-DD-YYYY")
    );
  const { fetchData: fetchProductCodesData } =
    ShipmentService.RetrieveProductsByLocation(
      filterBandState.selectedCustomerNo,
      filterBandState.selectedLocationNo
    );
  const tableHeaderCellDetails: TableHeaderCell[] = [
    { id: "vT_LocationName", label: "Location Name" },
    { id: "vT_Site", label: "Site #" },
    { id: "vT_ShipTo", label: "ShipTo #" },
    { id: "OrderNo", label: "Order No." },
    { id: "DeliveryDate", label: "Delivery Date" },
    { id: "Weight", label: "Weight" },
    { id: "Comments", label: "Comments" },
  ];

  const { Tbl, GetRowsAfterPagingAndSorting } = useTable<Shipment>(
    shipmentList,
    tableHeaderCellDetails,
    true,
    10
  );
  const [productCodes, setProductCodeData] = useState<ProductCode[]>([]);

  useEffect(() => {
    if (!isLoaded) {
      if (
        filterBandState.selectedCustomerNo !== undefined &&
        filterBandState.selectedCustomerNo !== ""
      ) {
        loadProduct();
        dispatch(uiActions.startProgressBar());
        fetchShowShipmentSummaryData().then((res) => {
          if (res.isSuccess === true) {
            setShipmentSummary(res.responseData);
            setIsLoaded(true);
          }
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterBandState.selectedCustomerNo, filterBandState.selectedLocationNo]);

  let menuItem =
    productCodes.length > 0 ? (
      <MenuItem value={"A"}>ALL</MenuItem>
    ) : (
      <MenuItem value={"A"}>None</MenuItem>
    );

  const loadProduct = async () => {
    dispatch(uiActions.startProgressBar());
    await fetchProductCodesData().then((res) => {
      setProductCodeData(res.responseData);
    });
  };
  const productChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let productID = event.target.value;
    if (productID === "A" || productID === "N") {
      productID = "";
    }
    setProductId(productID);
  };

  function checkDateValidation() {
    if (invalidDate) {
      dispatch(
        uiActions.displayNotification({
          message: "Please enter a valid Date",
          severity: "error",
        })
      );
      return false;
    } else {
      return true;
    }
  }

  const handleShowSummary = () => {
    if (checkDateValidation()) {
      dispatch(uiActions.startProgressBar());
      fetchShowShipmentSummaryData().then((res) => {
        if (res.isSuccess === true) {
          setShipmentSummary(res.responseData);
        }
      });
    }
  };

  const handleGoToLocationShipment = (locationNum: number) => {
    if (locationNum !== null && locationNum !== undefined) {
      dispatch(
        filterBandActions.setSelectedLocation({
          locationNo: locationNum,
        })
      );
    }
  };

  function renderLocation() {
    if (shipmentList.length > 0) {
      return (
        <>
          <StyledTableRow>
            <StyledTableCell
              scope="row"
              colSpan={7}
              style={{ textAlign: "center" }}
            >
              <RouterLink
                style={{ color: "Green", fontWeight: "bold" }}
                onClick={(e) =>
                  handleGoToLocationShipment(shipmentList[0].locationNo)
                }
                to={`/locationShipmentSummary`}
              >
                {shipmentList[0].locationName}
              </RouterLink>
            </StyledTableCell>
          </StyledTableRow>
        </>
      );
    }
  }

  return (
    <div>
      <PageHeader
        title="Shipment Summary"
        subtitle="To view shipment summary, select the week ending date, product and click on the Show Summary button.
To view location specific summary select the location from the location dropdown box at the top."
      ></PageHeader>
      <Card
        sx={{ width: "100hv", borderRadius: 3, margin: 2, mt: 0 }}
        elevation={6}
      >
        <CardContent sx={{ pb: 0 }}>
          <React.Fragment>
            <Container>
              <form>
                <Grid container spacing={3} sx={{ paddingTop: 1 }}>
                  <Grid item xs={3} sm={3} md={3} lg={3}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        label="Select Week Ending Date"
                        value={startDate}
                        onChange={(newValue) => {
                          if (newValue !== null && newValue.isValid()) {
                            setStartDate(newValue);
                            setInvalidDate(false);
                          } else {
                            setInvalidDate(true);
                          }
                        }}
                        selectedSections={undefined}
                        onSelectedSectionsChange={undefined}
                      />
                    </LocalizationProvider>
                  </Grid>
                  <Grid item xs={3} sm={3} md={3} lg={3}>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <TextField
                        id="Product ID"
                        name="vp_PRODUCT_ID"
                        select
                        label="Product Code"
                        //value={productId}
                        onChange={productChange}
                        defaultValue={"A"}
                        fullWidth
                      >
                        {menuItem}
                        {productCodes.map((row, index) => {
                          return (
                            <MenuItem
                              key={row.vP_PRODUCT_ID + "$" + index}
                              value={String(row.vP_PRODUCT_ID)}
                            >
                              {row.vP_PRODUCT_DESC +
                                "(" +
                                row.vP_PRODUCT_CODE +
                                ") "}
                            </MenuItem>
                          );
                        })}
                      </TextField>
                    </Grid>
                  </Grid>
                  <Grid item xs={2} sm={2} md={2} lg={2}>
                    <Button size="small" onClick={handleShowSummary}>
                      Show Summary
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </Container>
          </React.Fragment>
        </CardContent>
      </Card>
      {
        <Card
          sx={{ width: "100hv", borderRadius: 3, margin: 2, mt: 0 }}
          elevation={6}
        >
          <CustomCardHeader
            title="Shipment Details"
            action={
              <RouterLink to={"/shipmentEntry"}>
                <Button startIcon={<AddSharpIcon />}>Add Shipment</Button>
              </RouterLink>
            }
          ></CustomCardHeader>
          <CardContent sx={{ pb: 0 }}>
            <Tbl>
              <TableBody>
                {renderLocation()}

                {GetRowsAfterPagingAndSorting().map((row, index) => (
                  <>
                    {row.locationNo !== shipmentList[0].locationNo ? (
                      <StyledTableRow>
                        <StyledTableCell
                          scope="row"
                          colSpan={7}
                          style={{ textAlign: "center" }}
                        >
                          <RouterLink
                            style={{ color: "Green", fontWeight: "bold" }}
                            onClick={(e) =>
                              handleGoToLocationShipment(row.locationNo)
                            }
                            to={`/locationShipmentSummary`}
                          >
                            {row.locationName}
                          </RouterLink>
                        </StyledTableCell>
                      </StyledTableRow>
                    ) : (
                      ""
                    )}

                    <StyledTableRow key={index}>
                      <StyledTableCell scope="row">{""}</StyledTableCell>
                      <StyledTableCell scope="row">
                        {row.siteNo}
                      </StyledTableCell>
                      <StyledTableCell scope="row">
                        {row.locationNo}
                      </StyledTableCell>
                      <StyledTableCell scope="row">
                        {row.admOrderNo}
                      </StyledTableCell>
                      <StyledTableCell scope="row">
                        {row.deliveryDate === null
                          ? ""
                          : moment(row.deliveryDate).format("MM/DD/YYYY")}
                      </StyledTableCell>
                      {row.uom === "MT" ? (
                        <StyledTableCell scope="row">
                          {row.truckWeight === null
                            ? 0
                            : FormatInvResult(
                                Number(Number(row.truckWeight) / 2204),
                                row.uom,
                                0
                              )}
                        </StyledTableCell>
                      ) : row.uom === "BU" ? (
                        <StyledTableCell scope="row">
                          {row.truckWeight === null
                            ? 0
                            : row.truckWeight / row.specific_Gravity === null
                            ? 0
                            : FormatInvResult(
                                Number(Number(row.specific_Gravity)),
                                row.uom,
                                0
                              )}
                        </StyledTableCell>
                      ) : (
                        <StyledTableCell scope="row">
                          {row.truckWeight === null
                            ? 0
                            : FormatInvResult(
                                Number(Number(row.truckWeight)),
                                row.uom,
                                0
                              )}
                        </StyledTableCell>
                      )}
                      <StyledTableCell scope="row">
                        {row.comments}
                      </StyledTableCell>
                    </StyledTableRow>
                  </>
                ))}
              </TableBody>
            </Tbl>
          </CardContent>
        </Card>
      }
    </div>
  );
}

export default ShipmentSummary;
