import { createTheme } from '@mui/material/styles';
import { CommonFontFamily, BgThemeColorADMBlue, BgThemeColorPrimaryLight, BgThemeColorSecondaryMain, CommonThemeFontSize, CommonThemeAutoCompleteFontSize } from '../constant/constant';





// A custom theme for this app
const theme = createTheme({
  palette: {
    primary: {
      main: BgThemeColorADMBlue,
      light: BgThemeColorPrimaryLight,
    },
    secondary: {
      main: BgThemeColorSecondaryMain,
    },
    error: {
      main: '#ff0000',
      light: '#ef5350',
    },
  },
  typography: {
    fontFamily: CommonFontFamily,
    fontSize: 14,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 500,
    h1: {
      fontFamily: CommonFontFamily,
      fontWeight: 700,
      fontSize: "2.0rem",
      color: 'black',
      lineHeight: 1.5
    },
    h2: {
      fontFamily: CommonFontFamily,//'Segoe UI, Roboto, Helvetica Neue, Arial, sans-serif',
      fontSize: "1.5rem",
      color: '#2c275d',
      fontWeight: 700,
      lineHeight: 1.7
    },
    h3: {
      fontFamily: CommonFontFamily,
      fontWeight: 500,
      fontSize: "1.5rem",
      color: 'black',
      lineHeight: 1.5
    },    
    h4: {
      fontWeight: 550,
      fontFamily: CommonFontFamily,
      fontSize: "1.1rem",
      color: '#3A3541'//"#03060B"
    },
    h5: {
      fontWeight: 500,
      fontFamily: CommonFontFamily,
      color: '#363636',
      fontSize: "1.3rem",
    },
    h6: {
      fontWeight: 500,
      fontFamily: CommonFontFamily,
      color: '#363636',
      fontSize: "1.3rem",
    },
    subtitle1: {
      fontSize: "1.25rem",
      fontWeight: 300,
      color: '#FFFFF',
      fontFamily: CommonFontFamily,
    },
    subtitle2: {
      fontSize: 14,
      color: '#666666',
      fontFamily: CommonFontFamily,
    },
    body1: {
      fontSize: "1.0rem",
      color: 'black',
      fontWeight: 300
    },
  },
  components: {    
    // Name of the component
    MuiTextField:{
      defaultProps:{
        size:'small',
        variant:'standard' 
      },
    },
    MuiInputBase:{
      styleOverrides: {
        // Name of the slot
        root: {
          fontSize: 14
        }
      },
    },
    MuiInputLabel:{
      styleOverrides: {
        // Name of the slot
        root: {
          fontSize: 16,
          color: BgThemeColorADMBlue,
        }
      },
    },
    MuiButton: {
      defaultProps:{
        size:'medium',
        variant:'contained'
      },
      styleOverrides: {
        // Name of the slot
        root: {
          // Some CSS
          fontSize: '0.85rem',
          textTransform:'capitalize'
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        // Name of the slot
        root: {
          // Some CSS
          fontSize: CommonThemeFontSize
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        // Name of the slot
        root: {
          '& label': {
            fontSize: '1em',
            fontFamily: CommonFontFamily,
          }
        },
        input: {
          fontSize: CommonThemeAutoCompleteFontSize,
        },
        listbox: {
          fontSize: CommonThemeAutoCompleteFontSize,
        },
        noOptions: {
          fontSize: CommonThemeFontSize,
        }
      }
    },
    MuiListItemText: {
      styleOverrides: {
        // Name of the slot
        primary: {
          fontSize: CommonThemeFontSize,
        }
      }
    },
    MuiCardContent: {
      styleOverrides: {
        // Name of the slot
        root: {
          '&:last-child': {
            paddingBottom: 10
          }
        }
      }
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          fontWeight: 600,
          backgroundColor: BgThemeColorADMBlue,
          color: 'white'
          // '& h6': {
          //   color: 'red'
          // }
        }
      }
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          backgroundColor: '#E7EBF0'
        }
      }
    },
  },
  mixins: {
    toolbar: {
      minHeight: 50,
    },
  }
});

export default theme;
