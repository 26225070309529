/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import useTable from "../../../hooks/useTable";
import { TableHeaderCell } from "../../../models/common";
import TableBody from '@mui/material/TableBody';
import { Card, CardContent } from "@mui/material";
import ShipmentDataService from "../../../services/shipmentService";
import { IntransitCars } from "../../../models/shipment";
import PageHeader from "../../shared/PageHeader";
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import CustomCardHeader from "../../shared/CustomCardHeader";
import { useAppDispatch } from "../../../store/store";
import { uiActions } from "../../../store/ui-slice";

const LoadOut = () => {
    const dispatch = useAppDispatch();
    const LocationNo = "Z00776";
    const [carsByLocation, setCarsByLocation] = React.useState<IntransitCars[]>([])
    const { fetchData: fetchAllCarsByLocation } = ShipmentDataService.RetrieveAllCarsByLocation(LocationNo)

    React.useEffect(() => {
        dispatch(uiActions.startProgressBar());
        fetchAllCarsByLocation().then((res) => {
            setCarsByLocation(res.responseData)
        })
    }, [])

    const tableHeaderCellDetails: TableHeaderCell[] = [
        { id: "rS_CAR_NUMBER", label: "Car No." },
        { id: "vP_PRODUCT_ABBR", label: "Product" },
        { id: "rtcS_COA", label: "COA" },
    ];

    const { Tbl, GetRowsAfterPagingAndSorting } = useTable<IntransitCars>(
        carsByLocation,
        tableHeaderCellDetails,
        false,
        10,
        [{
            disablePadding: false,
            disableSorting: false,
            id: "heading",
            label: `${(carsByLocation.length).toString()} cars`,
            align: "center",
            colSpan: 3
        }]

    );

    let colorArr = [
        "#ffffff", "#eff8d3", "#c9e7fa", "#ddea9a", "#fff5ce", "#feed07", "#c8a958"
    ]
    let color: number = -1;
    const getRowColorChange = (row: IntransitCars, index: number) => {
        if (row.vP_PRODUCT_ABBR !== carsByLocation[index - 1]?.vP_PRODUCT_ABBR) {
            color++;
        }
        return { backgroundColor: colorArr[color] }
    }

    return (
        <>
            <PageHeader title="Z00776 - ADM BIOPRODUCTS " subtitle="DECATUR, IL"></PageHeader>
            <Card
                sx={{ width: "100hv", borderRadius: 3, margin: 2, mt: 0 }}
                elevation={6}
            >
                <CustomCardHeader
                    title="Loadout Screen"
                ></CustomCardHeader>
                <CardContent sx={{ pb: 0 }}>
                    <Tbl >
                        <TableBody >
                            {GetRowsAfterPagingAndSorting().map((row: IntransitCars, index: number) => (
                                <TableRow key={index} sx={getRowColorChange(row, index)} >
                                    <TableCell component="th" scope="row" id="rS_CAR_NUMBER">
                                        {row.rS_CAR_NUMBER}
                                    </TableCell>
                                    <TableCell component="th" scope="row" id="vP_PRODUCT_ABBR">
                                        {row.vP_PRODUCT_ABBR}
                                    </TableCell>
                                    <TableCell component="th" scope="row" id="rtcS_COA">
                                        {row.rtcS_COA}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Tbl>
                </CardContent>
            </Card>
        </>
    )
}
export default LoadOut;

