
import React from "react";
import { Dialog, DialogContent, DialogProps, DialogTitle, IconButton, styled } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';


export interface ModalPopupProps extends DialogProps {
    open: boolean,
    title: string,
    titleElement?: JSX.Element,
    onPopupClose: React.MouseEventHandler,
    width?:string, //width in pixel
    height?:string //width in pixel
}

export interface DialogTitleProps {
    id: string;
    children?: React.ReactNode;
    onClose: React.MouseEventHandler
}

// function ShowHideCloseButton(isCloseButtonVisible: boolean | undefined): boolean{
//     if(isCloseButtonVisible === undefined || isCloseButtonVisible === true)
//         return true;
//     else 
//         return false;
// }

function ModalPopup(props: React.PropsWithChildren<ModalPopupProps>) {

    const BootstrapDialog = styled(Dialog)(({ theme }) => ({
        '& .MuiDialogContent-root': {
            padding: theme.spacing(2),
        },
        '& .MuiDialogActions-root': {
            padding: theme.spacing(1),
        },
        [theme.breakpoints.down('sm')]: {
            
          },
          [theme.breakpoints.between('sm', 'md')]: {
            
          },
          [theme.breakpoints.up('md')]: {
            '& .MuiDialog-container': {
                '& .MuiPaper-root': {
                  width: "100%",
                  maxWidth: props.width === undefined ? "sm":props.width,  // Set your width here
                  maxHeight: props.height === undefined ? "sm":props.height,  // Set your width here
                },
              },
          },
    }));

    const BootstrapDialogTitle = (props: DialogTitleProps) => {
        const { children, onClose, ...other } = props;

        return (
            <DialogTitle sx={{ m: 0, pl: 2, pr: 2, pt: 1, pb: 1 }} {...other}>
                {children}
                {onClose ? (
                    <IconButton
                        aria-label="close"
                        onClick={onClose}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 6,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon sx={{fontSize:30}}/>
                    </IconButton>
                ) : null}
            </DialogTitle>
        );
    }

    return (
        <BootstrapDialog open={props.open} onClose={props.onPopupClose} 
            scroll={'paper'}> 
            <BootstrapDialogTitle id="customized-dialog-title" onClose={props.onPopupClose}>
                {props.title} {props.titleElement}
            </BootstrapDialogTitle>
            <DialogContent dividers>
                {props.children}
            </DialogContent>
        </BootstrapDialog >
    )
}

export default ModalPopup;