import { Button, Divider, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";

interface ErrorPopupProps {
    setShowModalPopup: React.Dispatch<React.SetStateAction<boolean>>;
    orderNos: string;
    setRefreshList: React.Dispatch<React.SetStateAction<boolean>>;
}

function ErrorPopup(props: ErrorPopupProps) {
    const [errorMsg, setErrorMsg] = useState<string>("");

    useEffect(() => {
        setErrorMsg(props.orderNos);
    }, [props.orderNos]);

    const closePopup = () => {
        props.setShowModalPopup(false);
        props.setRefreshList(true);
    };

    return (
        <React.Fragment>
            <Grid item xs={12} sm={12} md={12}>
                <span>
                    The following order numbers could not be saved since they are already present. 
                </span>
                <br/>
                <Grid container direction="column" alignItems="center" color="red">
                    {errorMsg}
                </Grid>
            </Grid>
            <Divider sx={{ mt: 4, mb: 2 }}></Divider>
            <Grid
                item
                xs={12}
                sm={12}
                md={12}
                sx={{
                    justifyContent: "flex-end",
                    alignItems: "flex-end",
                    display: "flex",
                }}
            >
                <Button
                    type="submit"
                    onClick={closePopup}
                >
                    Go Back
                </Button>
            </Grid>
        </React.Fragment>
    );
}

export default ErrorPopup;