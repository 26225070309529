import React from "react";
import ProductMaintenance from "../components/app/Product/ProductMaintenance";
import SystemMessage from "./SystemMessage";

function ProductPage(){
  const role=sessionStorage.getItem("roles");
  return(
    role?.toString().toUpperCase()==="ADMIN" ?
  <ProductMaintenance></ProductMaintenance> : <SystemMessage msg="Access Denied." colorMsg="Red"></SystemMessage>
  );
}

export default ProductPage