import { MenuItemInfo, RouteInfo } from '../../models/routeinfo';

//imports for icons
import PeopleAltSharpIcon from '@mui/icons-material/PeopleAltSharp';
import ScheduleIcon from '@mui/icons-material/Schedule';
import SummarizeIcon from '@mui/icons-material/Summarize';
import AssignmentIcon from '@mui/icons-material/Assignment';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import ListIcon from '@mui/icons-material/List';
import HomeIcon from '@mui/icons-material/Home';
import AddLocationAltIcon from '@mui/icons-material/AddLocationAlt';
import AssessmentIcon from '@mui/icons-material/Assessment';
import OutboxIcon from '@mui/icons-material/Outbox';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import InventoryIcon from '@mui/icons-material/Inventory';
import ReceiptIcon from '@mui/icons-material/Receipt';
import TrainIcon from '@mui/icons-material/Train';
import MiscellaneousServicesIcon from '@mui/icons-material/MiscellaneousServices';

//imports for pages
import CustomerSummaryPage from '../../pages/CustomerSummaryPage';
import LocationInformationPage from '../../pages/LocationInformationPage';
import CustomerMaintenancePage from '../../pages/CustomerMaintenancePage';
import TankDetailsPage from '../../pages/TankDetailsPage';
import LocationMaintenancePage from '../../pages/LocationMaintenancePage';
import LoadSummaryPage from '../../pages/LoadSummaryPage';
import TankMaintenancePage from '../../pages/TankMaintenancePage';
import OrderSchedulePage from '../../pages/OrderSchedulePage';
import OpenOrdersPage from '../../pages/OpenOrdersPage';
import ShipmentSummaryPage from '../../pages/ShipmentSummaryPage';
import LocationShipmentSummaryPage from '../../pages/LocationShipmentSummaryPage';
import ShipmentEntryPage from '../../pages/ShipmentEntryPage';
import IntrasnitShipmentMaintenancePage from '../../pages/IntrasnitShipmentMaintenancePage';
import LoadoutPage from '../../pages/LoadoutPage';
import IntransitTurnedCarsPage from '../../pages/IntransitTurnedCarsPage';
import RailcarInventoryPage from '../../pages/RailcarInventoryPage';
import UsageReportPage from '../../pages/UsageReportPage';
import ProductPage from '../../pages/ProductPage';
import IntransitProductSummaryPage from '../../pages/IntransitProductSummaryPage';
import IntransitProductInventoryPage from '../../pages/IntransitProductInventoryPage';
import RailcarHistoryPage from '../../pages/RailcarHistoryPage';
import NoticeRequestPage from '../../pages/NoticeRequestPage';
import UserAdminPage from '../../pages/UserAdminPage';
import SecurityReportPage from '../../pages/SecurityReportPage';

export const InboundDrawerMenuItemArray: MenuItemInfo[] = [
    { name: "Summary", link: "/", activeIndex: 0, icon: <HomeIcon color="primary" />, expand: false, isAdmin: false, isTurnCars: false, isRailCar: false, isRailcarTerminal: false },
    { name: "Customer", link: "/customers", activeIndex: 1, icon: <PeopleAltSharpIcon color="primary" />, expand: false, isAdmin: true, isTurnCars: false, isRailCar: false, isRailcarTerminal: false },
    { name: "Product", link: "/product", activeIndex: 2, icon: <InventoryIcon color="primary"/>, expand: false, isAdmin: true, isTurnCars: false, isRailCar: false, isRailcarTerminal: false},
    { name: "Intransit Product", link: "/intransitProductSummary", activeIndex: 3, icon: <LocalShippingIcon color="primary"/>, expand: false, isAdmin: false, isTurnCars: false, isRailCar: false, isRailcarTerminal: false},
    { name: "Usage Report", link: "/usageReport", activeIndex: 4, icon: <AssessmentIcon color="primary"/>, expand: false, isAdmin: false, isTurnCars: false, isRailCar: false, isRailcarTerminal: false},
    { name: "Intransit Shipment (Loadout)", link: "/intransitShipmentMaintenance", activeIndex: 5, icon: <MiscellaneousServicesIcon color="primary" />, expand: false, isAdmin: false, isTurnCars: true, isRailCar: false, isRailcarTerminal: false },
    { name: "Loadout", link: "/loadout", activeIndex: 6, icon: <ListIcon color="primary" />, expand: false, isAdmin: true, isTurnCars: false, isRailCar: false, isRailcarTerminal: false },
    { name: "Notice Request", link: "/noticeRequest", activeIndex: 7, icon: <OutboxIcon color="primary"/>, expand: false, isAdmin: true, isTurnCars: false, isRailCar: false, isRailcarTerminal: false},
    { name: "User Admin", link: "/userAdmin", activeIndex: 8, icon: <AdminPanelSettingsIcon color="primary"/>, expand: false, isAdmin: true, isTurnCars: false, isRailCar: false, isRailcarTerminal: false},
    { name: "Railcar Inventory", link: "/railcarInventory", activeIndex: 9, icon: <TrainIcon color="primary"/>, expand: false, isAdmin: true, isTurnCars: false, isRailCar: false, isRailcarTerminal: true},
];

export const DrawerMenuItemArray: MenuItemInfo[] = [
    { name: "Summary", link: "/", activeIndex: 0, icon: <HomeIcon color="primary" />, expand: false, isAdmin: false, isTurnCars: false, isRailCar: false, isRailcarTerminal: false },
    { name: "Usage Report", link: "/usageReport", activeIndex: 1, icon: <AssessmentIcon color="primary"/>, expand: false, isAdmin: false, isTurnCars: false, isRailCar: false, isRailcarTerminal: false},
    { name: "Shipment Summary", link: "/shipmentSummary", activeIndex: 2, icon: <SummarizeIcon color="primary" />, expand: false, isAdmin: false, isTurnCars: false, isRailCar: false, isRailcarTerminal: false },
    { name: "Open Orders", link: "/openOrders", activeIndex: 3, icon: <AssignmentIcon color="primary" />, expand: false, isAdmin: false, isTurnCars: false, isRailCar: false, isRailcarTerminal: false },
    { name: "Intransit Inventory", link: "/intransitProductInventory", activeIndex: 4, icon: <ReceiptIcon color="primary"/>, expand: false, isAdmin: false, isTurnCars: false, isRailCar: true, isRailcarTerminal: false},
    { name: "Intransit Product", link: "/intransitProductSummary", activeIndex: 5, icon: <LocalShippingIcon color="primary"/>, expand: false, isAdmin: false, isTurnCars: false, isRailCar: true, isRailcarTerminal: false},
    { name: "User Admin", link: "/userAdmin", activeIndex: 6, icon: <AdminPanelSettingsIcon color="primary"/>, expand: false, isAdmin: true, isTurnCars: false, isRailCar: false, isRailcarTerminal: false},
    { name: "Shipment Entry", link: "/shipmentEntry", activeIndex: 7, icon: <AddLocationAltIcon color="primary" />, expand: false, isAdmin: true, isTurnCars: false, isRailCar: false, isRailcarTerminal: false },
    { name: "Customer", link: "/customers", activeIndex: 8, icon: <PeopleAltSharpIcon color="primary" />, expand: false, isAdmin: true, isTurnCars: false, isRailCar: false, isRailcarTerminal: false },
    { name: "Product", link: "/product", activeIndex: 9, icon: <InventoryIcon color="primary"/>, expand: false, isAdmin: true, isTurnCars: false, isRailCar: false, isRailcarTerminal: false},
    { name: "Notice Request", link: "/noticeRequest", activeIndex: 10, icon: <OutboxIcon color="primary"/>, expand: false, isAdmin: true, isTurnCars: false, isRailCar: false, isRailcarTerminal: false},
    { name: "Order Schedule", link: "/orderSchedule", activeIndex: 11, icon: <ScheduleIcon color="primary" />, expand: false, isAdmin: true, isTurnCars: false, isRailCar: false, isRailcarTerminal: false },
    { name: "Railcar Inventory", link: "/railcarInventory", activeIndex: 12, icon: <TrainIcon color="primary"/>, expand: false, isAdmin: true, isTurnCars: false, isRailCar: false, isRailcarTerminal: true},    
    // Production Schedule
];


export const RouteInfoArray: RouteInfo[] = [
    // -----Basic Dropdown Routing Start-----
    { path: '/', element: <LoadSummaryPage /> },
    { path: '/customer', element: <CustomerSummaryPage /> },
    { path: '/location', element: <LocationInformationPage /> },
    { path: '/tank', element: <TankDetailsPage /> },
    // -----Basic Dropdown Routing End-----
    { path: '/customers', element: <CustomerMaintenancePage /> },
    { path: '/locations', element: <LocationMaintenancePage /> },
    { path: '/tanks', element: <TankMaintenancePage /> },
    { path: '/orderSchedule', element: <OrderSchedulePage /> },
    { path: '/openOrders', element: <OpenOrdersPage /> },
    { path: '/shipmentSummary', element: <ShipmentSummaryPage /> },
    { path: '/locationShipmentSummary', element: <LocationShipmentSummaryPage /> },
    { path: '/intransitTurnedCars', element: <IntransitTurnedCarsPage /> },
    { path: '/shipmentEntry', element: <ShipmentEntryPage /> },
    { path: '/intransitShipmentMaintenance', element: <IntrasnitShipmentMaintenancePage /> },
    { path: '/loadout', element: <LoadoutPage /> },
    { path: '/usageReport', element: <UsageReportPage/> },
    { path: '/product', element: <ProductPage/> },
    { path: '/intransitProductSummary', element: <IntransitProductSummaryPage/> },
    { path: '/railcarInventory', element: <RailcarInventoryPage /> },
    { path: '/noticeRequest', element: <NoticeRequestPage /> },
    { path: '/intransitProductInventory', element: <IntransitProductInventoryPage /> },
    { path: '/railcarHistory', element: <RailcarHistoryPage /> },
    { path: '/userAdmin', element: <UserAdminPage /> },
    { path: '/securityReport',element: <SecurityReportPage/>}
];