/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import TableBody from "@mui/material/TableBody";
import { StyledTableCell, StyledTableRow } from "../../controls/tablecontrols";
import {
  Grid,
  Button,
  Container,
  Card,
  CardContent,
  Table,
} from "@mui/material";
import { useAppDispatch } from "../../../store/store";
import { uiActions } from "../../../store/ui-slice";
import CustomCardHeader from "../../shared/CustomCardHeader";
import TableHead from "@mui/material/TableHead";
import TankDataService from "../../../services/tankService";
import { ShowChartModel, ShowHistoryModel } from "../../../models/tank";
import Chart from "react-apexcharts";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs, { Dayjs } from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

interface ShowChartProps {
  startDate: Dayjs;
  endDate: Dayjs;
  setStartDate: React.Dispatch<React.SetStateAction<Dayjs>>;
  setEndDate: React.Dispatch<React.SetStateAction<Dayjs>>;
  setShowChartModalPopup: React.Dispatch<React.SetStateAction<boolean>>;
  setShowHistoryModelPopup: React.Dispatch<React.SetStateAction<boolean>>;
  tankId: number;
  locationName: string;
}

function ShowChart(props: ShowChartProps) {
  const dispatch = useAppDispatch();

  const [startDate, setStartDate] = useState<Dayjs>(props.startDate);
  const [endDate, setEndDate] = useState<Dayjs>(props.endDate);
  const [invalidDate, setInvalidDate] = useState(false);
  const { fetchData: retrieveShowHistoryData } =
    TankDataService.RetrieveShowHistoryData(
      props.tankId,
      startDate.format("MM-DD-YYYY"),
      endDate.format("MM-DD-YYYY")
    );
  const { fetchData: retrieveTankChartData } =
    TankDataService.RetrieveTankChartData(
      props.tankId,
      startDate.format("MM-DD-YYYY"),
      endDate.format("MM-DD-YYYY")
    );
  const [histData, setHistoryData] = useState<ShowHistoryModel[]>([]);
  const [chartData, setChartData] = useState<ShowChartModel[]>([]);

  useEffect(() => {
    if (props.tankId) {
      retrieveShowHistoryData().then((res) => {
        setHistoryData(res.responseData);
      });
      retrieveTankChartData().then((ress) => {
        setChartData(ress.responseData);
      });
    } else {
      setHistoryData([]);
    }
  }, [props.tankId]);

  var intBegining = "";
  var intEnding = "";
  let rwclr = "#E7EBF0";
  function renderTable() {
    if (histData.length > 0) {
      histData.forEach((row) => {
        if (intBegining === "") {
          intBegining =
            row.b_Inv_Percent +
            "% /" +
            (row.b_Inv_Pounds == null ? 0 : row.b_Inv_Pounds).toFixed(0) +
            " lbs";
        }
        intEnding =
          row.e_Inv_Percent +
          "% /" +
          (row.e_Inv_Pounds == null ? 0 : row.e_Inv_Pounds).toFixed(0) +
          " lbs";
      });
      return (
        <Table>
          <TableHead>
            <StyledTableRow>
              <StyledTableCell scope="row">{"Date Range"}</StyledTableCell>
              <StyledTableCell scope="row">{"Beginning Level"}</StyledTableCell>
              <StyledTableCell scope="row">{"Ending Level"}</StyledTableCell>
            </StyledTableRow>
          </TableHead>
          <TableBody>
            <StyledTableRow style={{ backgroundColor: rwclr }}>
              <StyledTableCell scope="row">
                {dayjs(startDate).format("MM/DD/YYYY") +
                  " - " +
                  dayjs(endDate).format("MM/DD/YYYY")}
              </StyledTableCell>
              <StyledTableCell scope="row">{intBegining}</StyledTableCell>
              <StyledTableCell scope="row">{intEnding}</StyledTableCell>
            </StyledTableRow>
          </TableBody>
        </Table>
      );
    } else {
      return (
        <Grid item xs={12} sm={12} md={12} lg={12}>
          No records found
        </Grid>
      );
    }
  }

  const ApexChart = () => {
    var MlngTankCapacityLBs = 0;
    var MstrTankLevels: number[] = [];
    var MstrTankLevelDates: string[] = [];

    chartData.forEach((row) => {
      if (row.tank_levels === null) {
        MlngTankCapacityLBs = row.tank_capacity_lbs;
      } else {
        MstrTankLevels.push(Number(row.tank_levels.slice(0, -1)));
        MstrTankLevelDates.push(row.tank_level_dates.slice(0, -1));
      }
    });

    const options = {
      xaxis: {
        categories: MstrTankLevelDates,
      },

      yaxis: [
        {
          axisTicks: {
            show: true,
          },
          axisBorder: {
            show: true,
            color: "#008FFB",
          },
          labels: {
            style: {
              colors: "#008FFB",
            },
          },
          title: {
            text: "Percent",
            style: {
              color: "#008FFB",
            },
          },
          tooltip: {
            enabled: true,
          },
          min: 0,
          max: 100,
        },

        {
          seriesName: "Pounds",
          opposite: true,
          axisTicks: {
            show: true,
          },
          axisBorder: {
            show: true,
            color: "#FEB019",
          },
          labels: {
            style: {
              colors: "#FEB019",
            },
          },
          title: {
            text: "Pounds",
            style: {
              color: "#FEB019",
            },
          },
          max: MlngTankCapacityLBs,
          min: 0,
        },
      ],

      chart: {
        toolbar: {
          export: {
            csv: {
              filename: "Tank Reading",
            },
            svg: {
              filename: "Tank Reading",
            },
            png: {
              filename: "Tank Reading",
            },
          },
        },
      },
    };

    const series = [
      {
        name: "Tank level",
        data: MstrTankLevels,
        dataLabels: {
          enabled: true,
        },
      },
    ];

    return <Chart options={options} series={series} type="area" />;
  };

  function checkDateValidation(startDate: dayjs.Dayjs, endDate: dayjs.Dayjs) {
    if (invalidDate) {
      dispatch(
        uiActions.displayNotification({
          message: "Please enter a valid Begin/Through Date",
          severity: "error",
        })
      );
      return;
    }
    if (
      Date.parse(startDate.toISOString()) <= Date.parse(endDate.toISOString())
    ) {
      return true;
    } else {
      dispatch(
        uiActions.displayNotification({
          message: "Through Date should be after the Begin Date.",
          severity: "error",
        })
      );
      return;
    }
  }

  const handleShowSummary = () => {
    if (checkDateValidation(startDate, endDate)) {
      dispatch(uiActions.startProgressBar());
      retrieveShowHistoryData().then((res) => {
        setHistoryData(res.responseData);
      });
      retrieveTankChartData().then((ress) => {
        setChartData(ress.responseData);
      });
    }
  };

  function openShowHistory() {
    props.setStartDate(startDate);
    props.setEndDate(endDate);
    props.setShowHistoryModelPopup(true);
    props.setShowChartModalPopup(false);
  }

  return (
    <React.Fragment>
      <Container>
        {/* <form> */}
        <Grid container spacing={3} sx={{ paddingTop: 1 }}>
          <Grid item xs={6} sm={6} md={6}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="Search Begin Date"
                value={startDate}
                onChange={(newValue) => {
                  if (newValue !== null && newValue.isValid()) {
                    setStartDate(newValue);
                    setInvalidDate(false);
                  } else {
                    setInvalidDate(true);
                  }
                }}
                selectedSections={undefined}
                onSelectedSectionsChange={undefined}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={6} sm={6} md={6}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                defaultValue={dayjs(new Date())}
                label="Search Through Date"
                value={endDate}
                onChange={(newValue) => {
                  if (newValue !== null && newValue.isValid()) {
                    setEndDate(newValue);
                    setInvalidDate(false);
                  } else {
                    setInvalidDate(true);
                  }
                }}
                selectedSections={undefined}
                onSelectedSectionsChange={undefined}
              />
            </LocalizationProvider>
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          sx={{
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
          }}
        >
          <Button
            type="button"
            sx={{ mr: 1, mt: 1 }}
            onClick={handleShowSummary}
          >
            {"Show Summary"}
          </Button>
        </Grid>

        <Grid container sx={{ paddingTop: 1 }}>
          {
            <Card
              sx={{ width: "100hv", borderRadius: 3, margin: 2, mt: 0 }}
              elevation={6}
            >
              <CustomCardHeader
                title={props.locationName}
                action={
                  <Button variant="text" size="small" onClick={openShowHistory}>
                    {"Show History"}
                  </Button>
                }
              ></CustomCardHeader>
              <CardContent sx={{ pb: 0 }}>{renderTable()}</CardContent>
            </Card>
          }
          <Card
            sx={{ width: "100hv", borderRadius: 3, margin: 2, mt: 0 }}
            elevation={6}
          >
            <CustomCardHeader title="Tank Level"></CustomCardHeader>
            <CardContent sx={{ pb: 0 }}>
              <ApexChart />
            </CardContent>
          </Card>
        </Grid>
      </Container>
    </React.Fragment>
  );
}

export default ShowChart;
