import {
  Button,
  Card,
  CardContent,
  Container,
  Grid,
  Table,
  TableBody,
  TableHead,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs, { Dayjs } from "dayjs";
import React, { useEffect, useState } from "react";
import { StyledTableCell, StyledTableRow } from "../../controls/tablecontrols";
import CustomCardHeader from "../../shared/CustomCardHeader";
import PageHeader from "../../shared/PageHeader";
import orderService from "../../../services/orderService";
import { filterBandSelector, useAppDispatch } from "../../../store/store";
import { useSelector } from "react-redux";
import { IFilterBandState } from "../../../interfaces/storeInterfaces";
import { uiActions } from "../../../store/ui-slice";
import { RailcarOrders } from "../../../models/order";
import { FormatNumber } from "../../shared/CommonFunctions";
import moment from "moment";

interface RailcarInventoryHistoryProps {}

function RailcarInventoryHistory(props: RailcarInventoryHistoryProps) {
  const dispatch = useAppDispatch();
  const filterBandState: IFilterBandState = useSelector(filterBandSelector);
  const [startDate, setStartDate] = useState<Dayjs>(
    dayjs(new Date(new Date().setDate(new Date().getDate() - 60)))
  );
  const [endDate, setEndDate] = useState<Dayjs>(dayjs(new Date()));
  const [invalidFDate, setInvalidFDate] = useState(false);
  const [invalidTDate, setInvalidTDate] = useState(false);
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const [railcarInventoryHistory, setRailcarInventoryHistory] = useState<
    RailcarOrders[]
  >([]);
  const { fetchData: fetchRailcarHistoryData } =
    orderService.RetrieveOrderAgainstRailCarHistory(
      filterBandState.selectedLocationNo,
      startDate.format("MM-DD-YYYY"),
      endDate.format("MM-DD-YYYY")
    );

  useEffect(() => {
    if (!isLoaded) {
      fetchRailcarHistoryData().then((res) => {
        if (res.isSuccess === true) {
          setRailcarInventoryHistory(res.responseData);
          setIsLoaded(true);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleShowHistory = () => {
    if (checkDateValidation(startDate, endDate)) {
      dispatch(uiActions.startProgressBar());
      fetchRailcarHistoryData().then((res) => {
        setRailcarInventoryHistory(res.responseData);
      });
    }
  };

  function checkDateValidation(startDate: dayjs.Dayjs, endDate: dayjs.Dayjs) {
    if (invalidFDate) {
      dispatch(
        uiActions.displayNotification({
          message: "Please enter a valid From Date",
          severity: "error",
        })
      );
      return;
    }
    if (invalidTDate) {
      dispatch(
        uiActions.displayNotification({
          message: "Please enter a valid To Date",
          severity: "error",
        })
      );
      return;
    }
    if (
      Date.parse(startDate.toISOString()) <= Date.parse(endDate.toISOString())
    ) {
      return true;
    } else {
      dispatch(
        uiActions.displayNotification({
          message: "To Date should be after the From Date.",
          severity: "error",
        })
      );
      return;
    }
  }

  function railcarInventoryHistoryTable() {
    let strCheckCarNumber = "";
    let strCheckReceivedDate = "";

    if (railcarInventoryHistory.length > 0) {
      // eslint-disable-next-line array-callback-return
      return railcarInventoryHistory.map((row) => {
        if (
          strCheckCarNumber !== row.vS_CAR_NO ||
          strCheckReceivedDate !== row.vO_RAILCAR_RECEIVED_DATE
        ) {
          return (
            <>
              <Card
                sx={{ width: "100hv", borderRadius: 3, margin: 2, mt: 0 }}
                elevation={6}
              ></Card>
              <CardContent sx={{ pb: 0 }}>
                <Table>
                  <TableHead>
                    <StyledTableRow>
                      <StyledTableCell>
                        <div>{"Car Number :"}</div>
                        <div> {row.vS_CAR_NO}</div>
                      </StyledTableCell>
                      <StyledTableCell>
                        <div>{"Product Desc :"}</div>
                        <div>{row.vP_PRODUCT_DESC}</div>
                      </StyledTableCell>
                      <StyledTableCell>
                        <div>{"Received Loaded Weight :"}</div>
                        <div>{FormatNumber(row.vO_NET_WEIGHT, 0)}</div>
                      </StyledTableCell>
                    </StyledTableRow>
                  </TableHead>
                  <TableBody>
                    <StyledTableRow>
                      <StyledTableCell style={{ fontWeight: "bold" }}>
                        <div>{"Load Date :"}</div>
                        <div>
                          {moment(row.vO_RAILCAR_LOAD_DATE).format(
                            "MM/DD/YYYY"
                          )}
                        </div>
                      </StyledTableCell>
                      <StyledTableCell style={{ fontWeight: "bold" }}>
                        <div>{"Shipped Date :"}</div>
                        <div>
                          {moment(row.vO_RAILCAR_SHIPPED_DATE).format(
                            "MM/DD/YYYY"
                          )}
                        </div>
                      </StyledTableCell>
                      <StyledTableCell style={{ fontWeight: "bold" }}>
                        <div> {"Received Date :"}</div>
                        <div>
                          {moment(row.vO_RAILCAR_RECEIVED_DATE).format(
                            "MM/DD/YYYY hh:mm:ss A"
                          )}
                        </div>
                      </StyledTableCell>
                    </StyledTableRow>
                  </TableBody>
                </Table>
                <RenderOrders
                  carNumber={row.vS_CAR_NO}
                  receivedDate={row.vO_RAILCAR_RECEIVED_DATE}
                  orders={railcarInventoryHistory}
                  loadedWeight={row.vO_NET_WEIGHT}
                />
                <div style={{ display: "none" }}>
                  {(strCheckCarNumber = row.vS_CAR_NO)}
                  {(strCheckReceivedDate = row.vO_RAILCAR_RECEIVED_DATE)}
                </div>
              </CardContent>
            </>
          );
        }
      });
    } else {
      return (
        <>
          <Table>
            <TableBody>
              <StyledTableRow>
                <StyledTableCell colSpan={4}>
                  {
                    "There are no data available for the date range you selected.  "
                  }
                  {startDate.format("MM/DD/YYYY") +
                    " - " +
                    endDate.format("MM/DD/YYYY")}
                </StyledTableCell>
              </StyledTableRow>
            </TableBody>
          </Table>
        </>
      );
    }
  }

  return (
    <>
      <PageHeader
        title=""
        subtitle="This report displays all railcars that were received within the date range below.
		Select a date range to refine the report.  The earliest date must be within the last 90 days."
      ></PageHeader>
      <React.Fragment>
        <Container>
          <Grid container sx={{ paddingTop: 1 }}>
            <Card
              sx={{ width: "100hv", borderRadius: 3, margin: 2, mt: 0 }}
              elevation={6}
            >
              <CardContent sx={{ pb: 0 }}>
                <Grid container sx={{ paddingTop: 1 }} columnSpacing={{ xs: 1, sm: 1, md: 4, lg: 4 }}>
                  <Grid
                    item
                    xs={6}
                    sm={6}
                    md={6}
                    lg={6}
                    sx={{ textAlign: "right" }}
                  >
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        label="From"
                        value={startDate}
                        onChange={(newValue) => {
                          if (newValue !== null && newValue.isValid()) {
                            setStartDate(newValue);
                            setInvalidFDate(false);
                          } else {
                            setInvalidFDate(true);
                          }
                        }}
                        selectedSections={undefined}
                        onSelectedSectionsChange={undefined}
                      />
                    </LocalizationProvider>
                  </Grid>
                  <Grid item xs={6} sm={6} md={6} lg={6}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        label="To"
                        value={endDate}
                        onChange={(newValue) => {
                          if (newValue !== null && newValue.isValid()) {
                            setEndDate(newValue);
                            setInvalidTDate(false);
                          } else {
                            setInvalidTDate(true);
                          }
                        }}
                        selectedSections={undefined}
                        onSelectedSectionsChange={undefined}
                      />
                    </LocalizationProvider>
                  </Grid>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  sx={{
                    justifyContent: "center",
                    alignItems: "center",
                    display: "flex",
                  }}
                >
                  <Button
                    type="button"
                    sx={{ mr: 1, mt: 1 }}
                    onClick={handleShowHistory}
                  >
                    {"Show History"}
                  </Button>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
          <Grid container sx={{ paddingTop: 1 }}>
            {
              <Card
                sx={{ width: "100hv", borderRadius: 3, margin: 2, mt: 0 }}
                elevation={6}
              >
                <CustomCardHeader
                  title={"Inventory History"}
                ></CustomCardHeader>
                <CardContent sx={{ pb: 0 }}>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    {railcarInventoryHistoryTable()}
                  </Grid>
                </CardContent>
              </Card>
            }
          </Grid>
        </Container>
      </React.Fragment>
    </>
  );
}

interface renderOrderProps {
  carNumber: string;
  receivedDate: string;
  orders: RailcarOrders[];
  loadedWeight: number;
}

function RenderOrders(props: renderOrderProps) {
  let orderByCar = props.orders.filter(function (el) {
    return el.vS_CAR_NO === props.carNumber;
  });
  let nTotalOrderWeight = 0;

  if (props.orders.length > 0) {
    return (
      <>
        <Table>
          <TableBody>
            <StyledTableRow
              style={{ backgroundColor: "rgba(224, 224, 224, 1)" }}
            >
              <StyledTableCell style={{ fontWeight: "bold" }}>
                {"Order Date"}
              </StyledTableCell>
              <StyledTableCell style={{ fontWeight: "bold" }}>
                {"Order Number"}
              </StyledTableCell>
              <StyledTableCell style={{ fontWeight: "bold" }}>
                {"Invoice Number"}
              </StyledTableCell>
              <StyledTableCell style={{ fontWeight: "bold" }}>
                {"Order Weight"}
              </StyledTableCell>
            </StyledTableRow>

            {orderByCar.map((row, index) => (
              <>
                <StyledTableRow>
                  <StyledTableCell>
                    {moment(row.vS_SHIPPED_DATE).format("MM/DD/YYYY")}
                  </StyledTableCell>
                  <StyledTableCell>{row.vO_ADM_ORDER_NO}</StyledTableCell>
                  <StyledTableCell>{row.vO_INVOICE_NO}</StyledTableCell>
                  {row.vO_SPLIT_ORDER ? (
                    <StyledTableCell>
                      <sup>{"split"}</sup>
                      {row.vS_SHIPMENT_AMOUNT}
                    </StyledTableCell>
                  ) : (
                    <StyledTableCell>
                      {Number(row.vS_SHIPMENT_AMOUNT)}
                    </StyledTableCell>
                  )}
                </StyledTableRow>
                <span style={{ display: "none" }}>
                  {
                    (nTotalOrderWeight =
                      nTotalOrderWeight + Number(row.vS_SHIPMENT_AMOUNT))
                  }
                </span>
              </>
            ))}

            <StyledTableRow>
              <StyledTableCell
                colSpan={4}
                style={{ fontWeight: "bold" }}
                align={"right"}
              >
                {"Total Ordered Weight:"}
              </StyledTableCell>
            </StyledTableRow>
            <StyledTableRow>
              <StyledTableCell
                colSpan={4}
                style={{ fontWeight: "bold" }}
                align={"right"}
              >
                {FormatNumber(nTotalOrderWeight, 0)}
              </StyledTableCell>
            </StyledTableRow>
            <StyledTableRow>
              <StyledTableCell
                colSpan={4}
                style={{ fontWeight: "bold" }}
                align={"right"}
              >
                {"Estimated Weight Remaining:"}
              </StyledTableCell>
            </StyledTableRow>
            <StyledTableRow>
              {Number(props.loadedWeight) - Number(nTotalOrderWeight) < 0 ? (
                <StyledTableCell
                  colSpan={4}
                  style={{ fontWeight: "bold", color: "red" }}
                  align={"right"}
                >
                  {FormatNumber(
                    Number(props.loadedWeight) - Number(nTotalOrderWeight),
                    0
                  )}
                </StyledTableCell>
              ) : (
                <StyledTableCell
                  colSpan={4}
                  style={{ fontWeight: "bold" }}
                  align={"right"}
                >
                  {FormatNumber(
                    Number(props.loadedWeight) - Number(nTotalOrderWeight),
                    0
                  )}
                </StyledTableCell>
              )}
            </StyledTableRow>
          </TableBody>
        </Table>
      </>
    );
  } else {
    return <></>;
  }
}
export default RailcarInventoryHistory;
