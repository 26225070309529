/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Button, Card, CardContent, Grid, IconButton } from "@mui/material";
import AddSharpIcon from "@mui/icons-material/AddSharp";
import DeleteSharpIcon from "@mui/icons-material/DeleteSharp";
import ModeEditSharpIcon from "@mui/icons-material/ModeEditSharp";

import CustomCardHeader from "../../shared/CustomCardHeader";
import PageHeader from "../../shared/PageHeader";

import { TableHeaderCell } from "../../../models/common";
import TableBody from "@mui/material/TableBody";
import useTable from "../../../hooks/useTable";

import { IFilterBandState } from "../../../interfaces/storeInterfaces";
import { useSelector } from "react-redux";
import { filterBandSelector, useAppDispatch } from "../../../store/store";

import ShipmentDataService from "../../../services/shipmentService";
import { LocationShipment } from "../../../models/shipment";
import { StyledTableCell, StyledTableRow } from "../../controls/tablecontrols";
import dayjs from "dayjs";
import ModalPopup from "../../shared/ModalPopup";
import ConfirmationMessage from "../../shared/ConfirmationMessage";
import LocationShipmentMaintenance from "./LocationShipmentMaintenance";
import { uiActions } from "../../../store/ui-slice";
import { Link } from "react-router-dom";
import { FormatNumber } from "../../shared/CommonFunctions";


function LocationShipmentSummary() {
    const dispatch = useAppDispatch();
    const [shipmentList, setShipmentList] = useState<LocationShipment[]>([]);
    const filterBandState: IFilterBandState = useSelector(filterBandSelector);
    const [showModalPopup, setShowModalPopup] = useState<boolean>(false);
    const [openConfirmation, setOpenConfirmation] = React.useState<boolean>(false);
    const [deleteOrderNo, setDeleteOrderNo] = useState<string>("");
    const [isConfirmDelete, setIsConfirmDelete] = useState<boolean>(false);
    const [orderId, setOrderId] = useState('');
    const [shipTo, setShipTo] = useState('');
    const [isRefreshList, setRefreshList] = React.useState<boolean>(false);

    const { fetchData: fetchLocationShipmentData } = ShipmentDataService.RetrieveLocationShipmentSummary(filterBandState.selectedCustomerNo, filterBandState.selectedLocationNo, '');
    const { deleteData: deleteLocation } = ShipmentDataService.DeleteLocationShipment(deleteOrderNo);

    useEffect(() => {
        if (filterBandState.selectedCustomerNo) {
            dispatch(uiActions.startProgressBar());
            fetchLocationShipmentData().then((res) => {
                setShipmentList(res.responseData);
            });
        }
    }, [filterBandState.selectedCustomerNo, filterBandState.selectedLocationNo]);

    useEffect(() => {
        if (isRefreshList) {
            dispatch(uiActions.startProgressBar());
            fetchLocationShipmentData().then((res) => {
                setShipmentList(res.responseData);
                setRefreshList(false);
            });
        }
    }, [isRefreshList]);


    const tableHeaderCellDetails: TableHeaderCell[] = [
        { id: "siteNo", label: "Site #" },
        { id: "locationName", label: "Location Name" },
        { id: "shipTo", label: "Ship To" },
        { id: "orderNo", label: "Order No." },
        { id: "deliveryDate", label: "Delivery Date" },
        { id: "weight", label: "Truck Weight (lbs)" },
        { id: "productCode", label: "Product" },
        { id: "comments", label: "Comments" },
        { id: "edit", label: "Action", disableSorting: true },
    ];

    const { Tbl, GetRowsAfterPagingAndSorting } = useTable<LocationShipment>(
        shipmentList,
        tableHeaderCellDetails,
        true,
        10
    );

    const handleEdit = (orderId: string, shipTo: string) => {
        setOrderId(orderId);
        setShipTo(shipTo);
        setShowModalPopup(true);
    };

    const handleOpenDeleteConfirmation = (orderId: string) => {
        Promise.resolve().then(() => {
            setOpenConfirmation(true);
            setDeleteOrderNo(orderId);
        });
    };

    const handleConfirmDelete = (e: React.MouseEvent<HTMLButtonElement>) => {
        Promise.resolve().then(() => {
            setOpenConfirmation(false);
            setIsConfirmDelete(true);
        });
    };

    useEffect(() => {
        if (isConfirmDelete && deleteOrderNo !== "") {
            dispatch(uiActions.startProgressBar());
            deleteLocation().then((res) => {
                if (res.isSuccess) {
                    dispatch(
                        uiActions.displayNotification({
                            message: "Shipment Location deleted successfully!",
                            severity: "success",
                        })
                    );
                    setRefreshList(true);
                }
            });

            Promise.resolve().then(() => {
                setIsConfirmDelete(false);
                setDeleteOrderNo("");
            });
        }
    }, [isConfirmDelete]);
    
    const handleCancelDelete = (e: React.MouseEvent<HTMLButtonElement>) => {
        Promise.resolve().then(() => {
            setOpenConfirmation(false);
            setDeleteOrderNo("");
        });
    };

    const onModalClose = (e: React.MouseEvent<HTMLButtonElement>) => {
        Promise.resolve().then(() => {
            setShowModalPopup(false);
        });
    };

    return (
        <div>
            <PageHeader title="Location Shipment Summary"></PageHeader>
            {
                <Card sx={{ width: "100hv", borderRadius: 3, margin: 2, mt: 0 }} elevation={6}>
                    <CustomCardHeader
                        action={(
                            <Grid container columnSpacing={{ xs: 1, sm: 1, md: 4, lg: 4 }}>
                                <Grid item>
                                    <Link to={"/shipmentEntry"}>
                                        <Button startIcon={<AddSharpIcon />}>Add Shipment</Button>
                                    </Link>
                                </Grid>
                            </Grid>
                        )}
                    ></CustomCardHeader>
                    <CardContent sx={{ pb: 0 }}>
                        <Tbl>
                            <TableBody>
                                {GetRowsAfterPagingAndSorting().map((row, index) => (
                                    <StyledTableRow key={index}>
                                        <StyledTableCell scope="row">
                                            {row.siteNo}
                                        </StyledTableCell>
                                        <StyledTableCell scope="row">
                                            {row.locationName}
                                        </StyledTableCell>
                                        <StyledTableCell scope="row">
                                            {row.shipTo}
                                        </StyledTableCell>
                                        <StyledTableCell scope="row">
                                            {row.orderNo}
                                        </StyledTableCell>
                                        <StyledTableCell scope="row">
                                            {dayjs(row.deliveryDate).format("MM/DD/YYYY")}
                                        </StyledTableCell>
                                        <StyledTableCell scope="row">
                                            {FormatNumber(Number(row.weight), 0)}
                                        </StyledTableCell>
                                        <StyledTableCell scope="row">
                                            {row.productCode}
                                        </StyledTableCell>
                                        <StyledTableCell scope="row">
                                            {row.comments}
                                        </StyledTableCell>
                                        <StyledTableCell scope="row">
                                            <IconButton
                                                aria-label="Edit"
                                                size="small"
                                                sx={{ paddingLeft: 1, paddingRight: 1 }}
                                                onClick={(e) => handleEdit(row.orderID.toString(), row.shipTo.toString())}
                                            >
                                                <ModeEditSharpIcon fontSize="small" />
                                            </IconButton>
                                            <IconButton
                                                aria-label="Delete"
                                                size="small"
                                                sx={{ paddingLeft: 1, paddingRight: 1 }}
                                                onClick={(e) =>
                                                    handleOpenDeleteConfirmation(
                                                        row.orderID.toString()
                                                    )
                                                }
                                            >
                                                <DeleteSharpIcon fontSize="small" />
                                            </IconButton>
                                        </StyledTableCell>
                                    </StyledTableRow>
                                ))}
                            </TableBody>
                        </Tbl>
                    </CardContent>
                </Card>

            }
            <ModalPopup
                open={showModalPopup}
                title={"Edit Shipment Information"}
                onPopupClose={onModalClose}
                width='500px'
            >
                <LocationShipmentMaintenance
                    setShowModalPopup={setShowModalPopup}
                    orderId={orderId}
                    shipTo={shipTo}
                    setRefreshList={setRefreshList} />
            </ModalPopup>
            <ConfirmationMessage
                open={openConfirmation}
                message={
                    "Are you sure you want to delete the Shipment? This process cannot be undone."
                }
                onYesClick={handleConfirmDelete}
                onNoClick={handleCancelDelete}
            ></ConfirmationMessage>
        </div>
    )
}

export default LocationShipmentSummary;
