/* eslint-disable react-hooks/exhaustive-deps */
import { Card } from "@mui/material";
import { Alarm, TankInformation } from "../../../models/tank";
import CustomCardHeader from "../../shared/CustomCardHeader";
import moment from "moment";
import { LabelItem, StyledCardContent } from "../../controls/commoncontrols";

interface Props {
  alarm: Alarm;
  tankInfo: TankInformation;
}

function AlarmInformation({ alarm, tankInfo }: Props) {
  let masterColour;
  let alarmResult = alarm.result.trim().toUpperCase();
  let alarmResultContent;
  if (alarmResult.toUpperCase() === "NO DATA") {
    masterColour = "orange";
    alarmResultContent = (
      <LabelItem style={{ color: masterColour }}>
        This is a new tank and the initial tank level has not been set.
      </LabelItem>
    );
  } else if (alarmResult.toUpperCase() === "NO") {
    masterColour = "green";
    alarmResultContent = (
      <LabelItem style={{ color: masterColour }}>
        The tank level was updated within the last hour.
      </LabelItem>
    );
  } else {
    let dateTimeNow;
    let dateTimeLastUpdate;
    let timeSinceUpdateInMinutes = 0;
    let duration;
    dateTimeNow = moment(new Date()); //todays date
    dateTimeLastUpdate = moment(new Date(alarm.result)); // last updated date
    duration = moment.duration(dateTimeNow.diff(dateTimeLastUpdate));
    timeSinceUpdateInMinutes = duration.asMinutes();

    if (timeSinceUpdateInMinutes >= 240) {
      masterColour = "red";
      alarmResultContent = (
        <LabelItem style={{ color: masterColour }}>
          The last tank level update is over 4 hours: Last Reading @{" "}
          {moment(new Date(alarm.result)).format("MM/DD/YYYY hh:mm:ss A")}
        </LabelItem>
      );
    } else if (timeSinceUpdateInMinutes < 240) {
      masterColour = "#ffff00";
      alarmResultContent = (
        <LabelItem style={{ color: masterColour }}>
          The last tank level update was {timeSinceUpdateInMinutes / 60} hr and{" "}
          {timeSinceUpdateInMinutes % 60} min ago: Last Reading @{" "}
          {moment(new Date(alarm.result)).format("MM/DD/YYYY hh:mm:ss A")}
        </LabelItem>
      );
    } else {
      alarmResultContent = <LabelItem>No record found</LabelItem>;
    }
  }
  return (
      <Card
        sx={{ width: "100hv", borderRadius: 3, margin: 2, mt: 0 }}
        elevation={6}
      >
        <CustomCardHeader title="Alarm Information"></CustomCardHeader>
        <StyledCardContent>{alarmResultContent}</StyledCardContent>
      </Card>
  );
}

export default AlarmInformation;
