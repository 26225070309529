import {Divider, Grid, Typography } from "@mui/material";

interface PageHeaderProps {
    title: string,
    subtitle?: string
}
function PageHeader(props: React.PropsWithChildren<PageHeaderProps>) {
    return (
        <Grid sx={{ pb: 3 }}>
            <Typography variant="h2">
               {props.title}
            </Typography>
            
            {props.subtitle && <Typography variant="subtitle2">
                {props.subtitle}
            </Typography>}
            <Divider sx={{ pt: 1 }}></Divider>
        </Grid>
    )
}
export default PageHeader;