/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import {
  Button,
  Card,
  CardContent,
  Grid,
  Table,
  TableBody,
} from "@mui/material";
import {
  DetailItem,
  LabelItem,
  StyledCardContent,
} from "../../controls/commoncontrols";
import CustomCardHeader from "../../shared/CustomCardHeader";
import { StyledTableCell } from "../../controls/tablecontrols";
import { StyledTableRow } from "../../controls/tablecontrols";
import LocationService from "../../../services/locationService";
import { LocationDetails } from "../../../models/location";
import {
  FormatContactNumber,
  FormatZipCode,
} from "../../shared/CommonFunctions";
import { TableHeaderCell } from "../../../models/common";
import useTable from "../../../hooks/useTable";
import { Link as RouterLink } from "react-router-dom";
import { useAppDispatch } from "../../../store/store";
import { filterBandActions } from "../../../store/filterband-slice";
import { TankByLocation } from "../../../models/tank";
import { IFilterBandState } from "../../../interfaces/storeInterfaces";
import { useSelector } from "react-redux";
import { filterBandSelector } from "../../../store/store";
import { uiActions } from "../../../store/ui-slice";
import { useNavigate } from "react-router-dom";

interface LocationInformationProps {
  customerNo: string;
  locationNo: string;
}

function LocationInformation({
  customerNo,
  locationNo,
}: LocationInformationProps) {
  const navigate = useNavigate();
  const role = sessionStorage.getItem("roles");
  const filterBandState: IFilterBandState = useSelector(filterBandSelector);

  const dispatch = useAppDispatch();

  const defaultLocationInfo: LocationDetails =
    LocationService.GetDefaultLocationInfo();

  const [locationInfo, setLocationInfo] =
    useState<LocationDetails>(defaultLocationInfo);

  const [tankLocation, setTanks] = useState<TankByLocation[]>([]);

  const { fetchData: fetchLocationInfo } = LocationService.GetLocationByLocNumber(
    customerNo,
    locationNo
  );

  const tableHeaderCellDetails: TableHeaderCell[] = [
    { id: "vT_TANK_NAME", label: "Tanks" },
  ];

  useEffect(() => {
    if (locationNo) {
      fetchLocationInfo().then((res) => {
        setLocationInfo(res.responseData);
      });
    } else {
      setLocationInfo(defaultLocationInfo);
    }
  }, [locationNo]);

  useEffect(() => {
    if (locationNo) {
      setTanks(filterBandState.tanks);
    } else {
      setTanks([]);
    }
  }, [filterBandState.tanks]);

  const { Tbl, GetRowsAfterPagingAndSorting } = useTable<TankByLocation>(
    tankLocation,
    tableHeaderCellDetails,
    true
  );

  const handleTankSelect = (tankNo: number, tankName: string) => {
    if (tankNo != null && tankNo !== undefined) {
      dispatch(
        filterBandActions.setSelectedTank({
          tankNo: tankNo,
        })
      );
    }
  };

  const onEditClick = () => {
    if (locationNo !== "" && locationNo !== undefined && locationNo !== null) {
      navigate("/locations", {
        state: { editlocationNo: locationNo, editModal: true },
      });
    } else {
      dispatch(
        uiActions.displayNotification({
          message: "Location not selected",
          severity: "error",
        })
      );
    }
  };

  const openTankList = () => {
    if (locationNo !== "" && locationNo !== undefined && locationNo !== null) {
      navigate("/tanks");
    } else {
      dispatch(
        uiActions.displayNotification({
          message: "Location not selected",
          severity: "error",
        })
      );
    }
  }

  const onAddTankClick = () => {
    if (locationNo !== "" && locationNo !== undefined && locationNo !== null) {
      navigate("/tanks",{state:{ editTankID:'', editModal:true }});
    } else {
      dispatch(
        uiActions.displayNotification({
          message: "Location not selected",
          severity: "error",
        })
      );
    }
  }

  return (
    <Card
      sx={{ width: "100hv", borderRadius: 3, margin: 2, mt: 0 }}
      elevation={6}
    >
      <CustomCardHeader
        title="Location Information"
        action={
          role?.toString().toUpperCase() === "ADMIN" ? (
            <>
              <Button
                variant="text"
                sx={{ fontSize: "0.90rem" }}
                onClick={onEditClick}
              >
                <u>Edit Location</u>
              </Button>
              <Button
                variant="text"
                sx={{ fontSize: "0.90rem" }}
                onClick={onAddTankClick}
              >
                <u>Add Tank</u>
              </Button>
              <Button
                variant="text"
                sx={{ fontSize: "0.90rem" }}
                onClick={openTankList}
              >
                <u>Delete Tank</u>
              </Button>
            </>
          ) : (
            ""
          )
        }
      ></CustomCardHeader>
      <CardContent sx={{ pb: 0 }}>
        <Grid container columnSpacing={{ xs: 1, sm: 1, md: 4, lg: 4 }}>
          <Grid item xs={12} sm={12} md={5} lg={5}>
            <CardContent sx={{ pb: 0 }}>
              <Tbl>
                <TableBody>
                  {GetRowsAfterPagingAndSorting().map((row, index) => (
                    <StyledTableRow key={row.vT_TANK_ID}>
                      <StyledTableCell
                        scope="row"
                        style={{ fontWeight: "bold" }}
                      >
                        <RouterLink
                          onClick={(e) =>
                            handleTankSelect(row.vT_TANK_NO, row.vT_TANK_NAME)
                          }
                          to={`/tank`}
                          style={
                            row.vT_TANK_ACTIVE === "Y"
                              ? { color: "Green" }
                              : { color: "" }
                          }
                        >
                          {row.vT_TANK_NAME}
                        </RouterLink>
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Tbl>
            </CardContent>
          </Grid>

          <Grid item xs={12} sm={12} md={7} lg={7}>
            <Card
              sx={{ width: "100hv", borderRadius: 3, margin: 2, mt: 2 }}
              elevation={6}
            >
              <StyledCardContent>
                <Table>
                  <TableBody>
                    <StyledTableRow>
                      <StyledTableCell>
                        <LabelItem>Address:</LabelItem>
                      </StyledTableCell>
                      <StyledTableCell>
                        <DetailItem>
                          {locationInfo.vL_ADDRESS_1}{" "}
                          {locationInfo.vL_ADDRESS_2}
                        </DetailItem>
                      </StyledTableCell>
                      <StyledTableCell>
                        <LabelItem>City:</LabelItem>
                      </StyledTableCell>
                      <StyledTableCell>
                        <DetailItem>{locationInfo.vL_CITY}</DetailItem>
                      </StyledTableCell>
                    </StyledTableRow>
                    <StyledTableRow>
                      <StyledTableCell>
                        <LabelItem>State / Zip:</LabelItem>
                      </StyledTableCell>
                      <StyledTableCell>
                        <DetailItem>
                          {locationInfo.vL_STATE}{" "}
                          {FormatZipCode(locationInfo.vL_ZIP)}
                        </DetailItem>
                      </StyledTableCell>
                      <StyledTableCell>
                        <LabelItem>Contact Name:</LabelItem>
                      </StyledTableCell>
                      <StyledTableCell>
                        <DetailItem>{locationInfo.vL_CONTACT_NAME}</DetailItem>
                      </StyledTableCell>
                    </StyledTableRow>
                    <StyledTableRow>
                      <StyledTableCell>
                        <LabelItem>Phone:</LabelItem>
                      </StyledTableCell>
                      <StyledTableCell>
                        <DetailItem>
                          {FormatContactNumber(locationInfo.vL_PHONE)}
                        </DetailItem>
                      </StyledTableCell>
                      <StyledTableCell>
                        <LabelItem>Fax:</LabelItem>
                      </StyledTableCell>
                      <StyledTableCell>
                        <DetailItem>{locationInfo.vL_FAX}</DetailItem>
                      </StyledTableCell>
                    </StyledTableRow>
                    <StyledTableRow>
                      <StyledTableCell>
                        <LabelItem>Site#:</LabelItem>
                      </StyledTableCell>
                      <StyledTableCell>
                        <DetailItem>{locationInfo.vL_SITE_NUMBER}</DetailItem>
                      </StyledTableCell>
                      <StyledTableCell>
                        <LabelItem>ShipTo#:</LabelItem>
                      </StyledTableCell>
                      <StyledTableCell>
                        <DetailItem>
                          {locationInfo.vL_CUSTOMER_SHIPTO}
                        </DetailItem>
                      </StyledTableCell>
                    </StyledTableRow>
                    <StyledTableRow>
                      <StyledTableCell>
                        <LabelItem>E-Mail:</LabelItem>
                      </StyledTableCell>
                      <StyledTableCell colSpan={3}>
                        <DetailItem>
                          {locationInfo.vL_CONTACT_EMAIL}
                          {locationInfo.vL_CONTACT_EMAIL2 != null
                            ? ", " + locationInfo.vL_CONTACT_EMAIL2
                            : ""}
                          {locationInfo.vL_CONTACT_EMAIL3 != null
                            ? ", " + locationInfo.vL_CONTACT_EMAIL3
                            : ""}
                        </DetailItem>
                      </StyledTableCell>
                    </StyledTableRow>
                  </TableBody>
                </Table>
              </StyledCardContent>
            </Card>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

export default LocationInformation;
