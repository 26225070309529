import { CheckError } from "../components/shared/CommonFunctions";
import { axios } from "../config/axios";
import { APIResponse } from "../models/api";
import { useAppDispatch } from "../store/store";
import { uiActions } from "../store/ui-slice";


export default function useAxiosPost<T>(url: string, requestData:T, title: string="") {
    const dispatch = useAppDispatch();

    const postData = async  (): Promise<APIResponse> => {
        let apiResObj:APIResponse={responseData: null, isSuccess:false,errorString:''};

      await axios.post(url, requestData,{headers:{'Authorization': `Bearer ${sessionStorage.getItem("jwtToken")}`}}).then((response) => {
            //console.log(url);
            dispatch(uiActions.incrementCurrentProgress());
            apiResObj = {responseData: response.data, isSuccess:true,errorString:''};
        }).catch((e) => {  
            let isSpecialChars: boolean = false;
            dispatch(uiActions.stopProgressBar());
            if (e.code === "ERR_NETWORK") {
                isSpecialChars = CheckError(JSON.parse(e.config.data));
            }
            if (isSpecialChars === true) {
              dispatch(
                uiActions.logErrorNotification({
                  message: `Provided data may contain characters ( \` , ' , " , - ) which are blocked by policy. Kindly try again by removing those characters.`,
                  error: JSON.stringify(e),
                })
              );
            } else {
              dispatch(
                uiActions.logErrorNotification({
                  message:
                    title === ""
                      ? "Error occurred"
                      : "Error occurred in " + title,
                  error: JSON.stringify(e),
                })
              );
            }
            apiResObj = {
              responseData: null,
              isSuccess: false,
              errorString: JSON.stringify(e),
            };                                           
        });

        return apiResObj;
    }


    return {
        postData
    }
}