import React from "react";
import Loadout from "../components/app/Shipment/Loadout";
import SystemMessage from "./SystemMessage";

function LoadoutPage(){
  const role=sessionStorage.getItem("roles");
  return(
    role?.toString().toUpperCase()==="ADMIN" ?
  <Loadout></Loadout> : <SystemMessage msg="Access Denied." colorMsg="Red"></SystemMessage>
  );
}

export default LoadoutPage;