import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TablePagination from "@mui/material/TablePagination";
import Paper from "@mui/material/Paper";
import { AdditionalTableHeaderCell, Order, TableHeaderCell } from "../models/common";
import {
  StyledPaper,
  StyledTableCell,
  StyledTableRow,
} from "../components/controls/tablecontrols";
import MainTableHead from "../components/shared/MainTableHead";

export default function useTable<T>(
  rows: T[],
  headerCells: TableHeaderCell[],
  isPagingEnabled: boolean = true,
  defaultRowCountPerPage: number = 5,
  additionalHeader?:AdditionalTableHeaderCell[],
) {
  const [order, setOrder] = React.useState<Order>("asc");
  const [orderBy, setOrderBy] = React.useState<string>("");
  const [selected, setSelected] = React.useState<readonly string[]>([]);
  const [page, setPage] = React.useState(0);

  const [rowsPerPage, setRowsPerPage] = React.useState(
    defaultRowCountPerPage > 0 ? defaultRowCountPerPage : 5
  );

  useEffect(() => {
    setPage(0);
  }, [rows]);

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: string
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      // const newSelecteds = rows.map((n) => n.name);    /*Commented for time being */
      // setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  // const handleClick = (
  //   event: React.MouseEvent<unknown>,
  //   name: string | number
  // ) => {
  //   const selectedIndex = selected.indexOf(name.toString());
  //   let newSelected: readonly string[] = [];

  //   if (selectedIndex === -1) {
  //     newSelected = newSelected.concat(selected, name.toString());
  //   } else if (selectedIndex === 0) {
  //     newSelected = newSelected.concat(selected.slice(1));
  //   } else if (selectedIndex === selected.length - 1) {
  //     newSelected = newSelected.concat(selected.slice(0, -1));
  //   } else if (selectedIndex > 0) {
  //     newSelected = newSelected.concat(
  //       selected.slice(0, selectedIndex),
  //       selected.slice(selectedIndex + 1)
  //     );
  //   }

  //   setSelected(newSelected);
  // };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const isSelected = (name: string | number) =>
    selected.indexOf(name.toString()) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  interface Props {}
  function Tbl(props: React.PropsWithChildren<Props>) {
    return (
      <Box>
        <Paper sx={{ mb: 2 }}>
          {/* <EnhancedTableToolbar numSelected={selected.length} /> */}
          <TableContainer component={StyledPaper}>
            <Table aria-labelledby="tableTitle" size={"small"}>
              <MainTableHead
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={rows.length}
                headCells={headerCells}
                additionalHeader={additionalHeader}
              />
              {props.children}
              {rows.length === 0 && (
                <TableBody>
                  <StyledTableRow>
                    <StyledTableCell
                      align="center"
                      colSpan={headerCells.length}
                    >
                      No records found
                    </StyledTableCell>
                  </StyledTableRow>
                </TableBody>
              )}
            </Table>
          </TableContainer>
          {isPagingEnabled && (
            <TablePagination
              rowsPerPageOptions={[5, 10, 25,{ value: rows.length, label: 'All' }]}
              component="div"
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          )}
        </Paper>
      </Box>
    );
  }

  function descendingComparator<T>(a: T, b: T, orderBy: string) {
    if (b[orderBy as keyof T] < a[orderBy as keyof T]) {
      return -1;
    }
    if (b[orderBy as keyof T] > a[orderBy as keyof T]) {
      return 1;
    }
    return 0;
  }

  function getComparator(
    order: Order,
    orderBy: string
  ): (
    a: T, //{ [key in Key]: number | string },
    b: T //{ [key in Key]: number | string },
  ) => number {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  // This method is created for cross-browser compatibility, if you don't
  // need to support IE11, you can use Array.prototype.sort() directly
  function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
    const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  const GetRowsAfterPagingAndSorting = () => {
    if (isPagingEnabled) {
      return stableSort(rows, getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
      );
    } else {
      return stableSort(rows, getComparator(order, orderBy));
    }
  };

  const GetCurrentPage = () => {
    return page;
  };

  const GetRowsPerPage = () => {
    if (isPagingEnabled) {
      return rowsPerPage;
    } else {
      return rows.length;
    }
  };

  return {
    Tbl,
    GetRowsAfterPagingAndSorting,
    GetCurrentPage,
    GetRowsPerPage,
  };
}
