import React, { useEffect } from "react";
import PageHeader from "../../shared/PageHeader";
import { Grid, TextField, Card, CardContent, MenuItem } from "@mui/material";
import { StyledTableCell, StyledTableRow } from "../../controls/tablecontrols";
import TableBody from "@mui/material/TableBody";
import CustomCardHeader from "../../shared/CustomCardHeader";
import useTable from "../../../hooks/useTable";
import { ReportTypeModel, UsersWithMultCustSecurityModel } from "../../../models/userNotice";
import UserNoticeService from "../../../services/userNoticeService";
import { useAppDispatch } from "../../../store/store";
import { uiActions } from "../../../store/ui-slice";

const SecurityReport = () => {

    const dispatch = useAppDispatch();
    const [selectedValue, setSelectedValue] = React.useState<string>('0');
    const [upperTable, setUpperTable] = React.useState<UsersWithMultCustSecurityModel[]>([])
    const [isloaded, setIsloaded] = React.useState<boolean>(false);
    const [lowerTable, setLowerData] = React.useState<ReportTypeModel[]>([]);
    const { fetchData: fetchCustForNoticeType } = UserNoticeService.RetrieveUsersPerCustForNoticeType
        (selectedValue);
    const { fetchData: fetchUsersWithMultCustSecurity } = UserNoticeService.RetrieveUsersWithMultCustSecurity();

    const css = `
    .css-1nfpu46-MuiTypography-root{
        color: red;
    }
    `
    useEffect(() => {
        if (isloaded) {
            dispatch(uiActions.startProgressBar());
            fetchCustForNoticeType().then((res) => {
                if (res.responseData.length > 0) {
                    setLowerData(res.responseData);
                    setIsloaded(false);
                }
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isloaded])

    useEffect(() => {
        dispatch(uiActions.startProgressBar());
        fetchUsersWithMultCustSecurity().then((res) => {
            if (res.responseData.length > 0) {
                setUpperTable(res.responseData);
            }
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const tableHeaderCellDetails: any = [
        { id: "lasT_NAME", label: "User Name" },
        { id: "vU_USER_ID", label: "User ID" },
        { id: "cusT_NAME", label: "Customer" },
        { id: "securitY_LEVEL", label: "Access Level" },
    ];

    const { Tbl, GetRowsAfterPagingAndSorting } = useTable<any>(
        upperTable,
        tableHeaderCellDetails,
        true,
        10,
    );

    const handleDropDownChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        setSelectedValue(value);
        setIsloaded(true);
    }

    return (
        <>
            <PageHeader title="Security Reports" subtitle=""></PageHeader>
            <Card
                sx={{ width: "100hv", borderRadius: 3, margin: 2, mt: 0 }}
                elevation={6}
            >
                <style>
                    {css}
                </style>
                <CustomCardHeader
                    title="Alert: Non-A Number eADM Users With Access To Multiple Customers"
                    sx={{ color: "red" }}
                ></CustomCardHeader>
                <CardContent sx={{ pb: 0 }}>
                    <Tbl>
                        <TableBody>
                            {GetRowsAfterPagingAndSorting().map((row: any, index) => {
                                return (<>
                                    <StyledTableRow key={index}>
                                        <StyledTableCell scope="row" >
                                            {row.lasT_NAME}, {row.firsT_NAME}
                                        </StyledTableCell>
                                        <StyledTableCell scope="row">
                                            {row.emailID}
                                        </StyledTableCell>
                                        <StyledTableCell scope="row">
                                            {row.cusT_NAME} ({row.cusT_NO})
                                        </StyledTableCell>
                                        <StyledTableCell scope="row">
                                            {row.securitY_LEVEL}
                                        </StyledTableCell>
                                    </StyledTableRow>
                                </>)
                            })}
                        </TableBody>
                    </Tbl>
                </CardContent>
            </Card>
            <Card
                sx={{ width: "100hv", borderRadius: 3, margin: 2, mt: 0 }}
                elevation={6}
            >
                <CardContent sx={{ pb: 0 }}>
                    <Grid container columnSpacing={{ xs: 1, sm: 1, md: 4, lg: 4 }}>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <Grid container>
                                <Grid item xs={2} sm={2} md={4} lg={4}></Grid>
                                <Grid item xs={8} sm={8} md={4} lg={4} sx={{textAlign:"center"}}>
                                    <TextField
                                        id="Report_Type"
                                        name="Report_Type"
                                        select
                                        label="Select Summary Report Type"
                                        defaultValue="0"
                                        sx={{ minWidth: 200 }}
                                        onChange={handleDropDownChange}
                                        value={selectedValue}
                                    >
                                        <MenuItem value="0">Report Type</MenuItem>
                                        <MenuItem value="D">Daily</MenuItem>
                                        <MenuItem value="W">Weekly</MenuItem>
                                    </TextField>
                                </Grid>
                                <Grid item xs={2} sm={2} md={4} lg={4}></Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <CardContent sx={{ pb: 0 }}>
                                {(selectedValue === "0" ? false : true) && <SummaryReport lowerTable={lowerTable} />}

                            </CardContent>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </>
    )
}

//Component for lower table
const SummaryReport = (props: any) => {

    const tableHeaderCellDetails2: any = [
        { id: "vu_last_name", label: "User Name" },
        { id: "vc_customer_name", label: "Customer" },
    ];

    const { Tbl, GetRowsAfterPagingAndSorting } = useTable<ReportTypeModel[]>(
        props.lowerTable,
        tableHeaderCellDetails2,
        true,
        10,
    );

    return (
        <>
            <Tbl>
                <TableBody>
                    {GetRowsAfterPagingAndSorting().map((row: any, index) => {
                        return (<>
                            <StyledTableRow key={index}>
                                <StyledTableCell scope="row" id="vu_last_name">
                                    {row.vu_last_name},{row.vu_first_name}
                                </StyledTableCell>
                                <StyledTableCell scope="row" id="vc_customer_name">
                                    {row.vc_customer_name} ({row.vc_customer_no})
                                </StyledTableCell>
                            </StyledTableRow>
                        </>)
                    })}
                </TableBody>
            </Tbl>
        </>
    )
}
export default SecurityReport;