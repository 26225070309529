/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Button, Card, CardContent, TableBody } from "@mui/material";
import CustomCardHeader from "../../shared/CustomCardHeader";
import PrintIcon from "@mui/icons-material/Print";
import { StyledTableCell, StyledTableRow } from "../../controls/tablecontrols";
import useTable from "../../../hooks/useTable";
import {
  AdditionalTableHeaderCell,
  TableHeaderCell,
} from "../../../models/common";
import DownloadIcon from "@mui/icons-material/Download";
import orderService from "../../../services/orderService";
import { IFilterBandState } from "../../../interfaces/storeInterfaces";
import { useSelector } from "react-redux";
import { filterBandSelector } from "../../../store/store";
import { FormatInvResult } from "../../shared/CommonFunctions";
import dayjs from "dayjs";
import { Link as RouterLink } from "react-router-dom";
import Tooltip from "@mui/material/Tooltip";
import PageHeader from "../../shared/PageHeader";
import { OpenOrdersModel } from "../../../models/order";
import ModalPopup from "../../shared/ModalPopup";
import OpenOrdersPrint from "./OpenOrdersPrint";
import GenerateCSV from "../../shared/GenerateCSV";

function OpenOrders() {
  const filterBandState: IFilterBandState = useSelector(filterBandSelector);

  const [openOrders, setOpenOrders] = useState<OpenOrdersModel[]>([]);
  const [showOpenOrdersModalPopup, setShowOpenOrdersModalPopup] =
    useState<boolean>(false);

  const { fetchData: fetchOpenOrders } = orderService.RetrieveOpenOrders(
    filterBandState.selectedCustomerNo,
    filterBandState.selectedLocationNo,
    "LNA"
  );

  const tableHeaderCellDetails: TableHeaderCell[] = [
    { id: "loC_NAME", label: "Name", align: "center" },
    { id: "loC_SHIPTO", label: "No", align: "center" },
    { id: "proD_CODE", label: "Prod", align: "center" },
    { id: "capacity", label: "Capacity", align: "center" },
    { id: "actuaL_INV", label: "Inventory", align: "center" },
    { id: "reordeR_PNT", label: "Point", align: "center" },
    { id: "reordeR_QTY", label: "Quantity", align: "center" },
    { id: "ordeR_DATE", label: "Order", align: "center" },
    { id: "arrivaL_DATE", label: "Arrival", align: "center" },
    { id: "ordeR_NO", label: "Number", align: "center" },
    { id: "orderState", label: "Status", align: "center" },
  ];

  const printHeaderCellDetails: TableHeaderCell[] = [
    { id: "loC_NAME", label: "Name", align: "center" },
    { id: "loC_SHIPTO", label: "No", align: "center" },
    { id: "proD_CODE", label: "Prod", align: "center" },
    { id: "capacity", label: "Capacity", align: "center" },
    { id: "actuaL_INV", label: "Inventory", align: "center" },
    { id: "reordeR_PNT", label: "Point", align: "center" },
    { id: "reordeR_QTY", label: "Quantity", align: "center" },
    { id: "ordeR_DATE", label: "Order", align: "center" },
    { id: "arrivaL_DATE", label: "Arrival", align: "center" },
    { id: "ordeR_NO", label: "Order No", align: "center" },
    { id: "orderState", label: "Status", align: "center" },
  ];

  const additionalHeaderDetails: AdditionalTableHeaderCell[] = [
    {
      id: "productLoaction",
      label: "Product-Location",
      colSpan: 5,
      align: "center",
    },
    { id: "reorder", label: "Reorder", colSpan: 2, align: "center" },
    { id: "actualDates", label: "Actual Dates", colSpan: 2, align: "center" },
    { id: "order", label: "Order", colSpan: 2, align: "center" },
  ];

  const { Tbl, GetRowsAfterPagingAndSorting } = useTable<OpenOrdersModel>(
    openOrders,
    tableHeaderCellDetails,
    true,
    10,
    additionalHeaderDetails
  );

  useEffect(() => {
    if (
      filterBandState.selectedCustomerNo !== "" &&
      filterBandState.selectedCustomerNo !== undefined
    )
      dataBinding();
  }, [filterBandState.selectedCustomerNo, filterBandState.selectedLocationNo]);

  function dataBinding() {
    fetchOpenOrders().then((res) => {
      let data = res.responseData;
      for (let i = 0; i < data.length; i++) {
        if (data[i].uom === "MT") {
          data[i].capacity = (data[i].capacity / 2204).toFixed(1);
          data[i].actuaL_INV = (data[i].actuaL_INV / 2204).toFixed(1);
          data[i].reordeR_PNT = (data[i].reordeR_PNT / 2204).toFixed(1);
          data[i].reordeR_QTY = (data[i].reordeR_QTY / 2204).toFixed(1);
        } else if (data[i].uom === "BU") {
          data[i].capacity = (
            data[i].capacity / data[i].specific_Gravity
          ).toFixed(1);
          data[i].actuaL_INV = (
            data[i].actuaL_INV / data[i].specific_Gravity
          ).toFixed(1);
          data[i].reordeR_PNT = (
            data[i].reordeR_PNT / data[i].specific_Gravity
          ).toFixed(1);
          data[i].reordeR_QTY = (
            data[i].reordeR_QTY / data[i].specific_Gravity
          ).toFixed(1);
        } else {
          data[i].capacity = data[i].capacity.toFixed(0);
          data[i].actuaL_INV = data[i].actuaL_INV.toFixed(0);
          data[i].reordeR_PNT = data[i].reordeR_PNT.toFixed(0);
          data[i].reordeR_QTY = data[i].reordeR_QTY.toFixed(0);
        }

        if (data[i].inventory < data[i].reordeR_PNT) {
          data[i].colorName = "red";
        } else {
          data[i].colorName = "blue";
        }

        switch (data[i].ordeR_STATE) {
          case "USER_INTERFACE":
            data[i].orderState = "M";
            data[i].orderStateDesc = "Order entered manually via OIS";
            break;
          case "OIS_GENERATION":
            data[i].orderState = "OIS";
            data[i].orderStateDesc = "Order generated on OIS";
            break;
          case "OP_GENERATION":
            data[i].orderState = "OP";
            data[i].orderStateDesc = FetchOrderStatus(data[i].ordeR_STATUS);
            break;
          case "SHIPMENT_UPLOAD":
            data[i].orderState = "S";
            data[i].orderStateDesc = "Order shipped";
            break;
          case "NULL":
            data[i].orderState = "0";
            data[i].orderStateDesc =
              "Zero orders exist for this product-location";
            break;
          default:
            data[i].orderState = "NA";
            data[i].orderStateDesc = "Not Available (Pre-Phase II OIS)";
            break;
        }

        //formatted value
        data[i].formattedCapacity = FormatInvResult(
          Number(data[i].capacity),
          data[i].uom,
          0
        );
        data[i].formattedActuaL_INV = FormatInvResult(
          Number(data[i].actuaL_INV),
          data[i].uom,
          0
        );
        data[i].formattedReordeR_PNT = FormatInvResult(
          Number(data[i].reordeR_PNT),
          data[i].uom,
          0
        );
        data[i].formattedReordeR_QTY = FormatInvResult(
          Number(data[i].reordeR_QTY),
          data[i].uom,
          0
        );
        data[i].ordeR_DATE = dayjs(data[i].ordeR_DATE).format(
          "MM/DD/YYYY hh:mm:ss A"
        );
        data[i].arrivaL_DATE = dayjs(data[i].arrivaL_DATE).format("MM/DD/YYYY");
      }
      setOpenOrders(data);
    });
  }

  function FetchOrderStatus(oState: string) {
    let orderStatus = "";

    if (oState.length !== 0) {
      switch (oState) {
        case "0":
          orderStatus = "- order Deleted";
          break;
        case "1":
          orderStatus = " - Order Entered";
          break;
        case "2":
          orderStatus = "- order Printed";
          break;
        case "3":
          orderStatus = "- order Shipped";
          break;
        case "4":
          orderStatus = "- order Cancelled";
          break;
        case "5":
          orderStatus = "- order Reissued";
          break;
        case "6":
          orderStatus = " - Adjusted Rule 102";
          break;
        case "7":
          orderStatus = " - Deleted Rule 102";
          break;
        default:
          orderStatus = "Tentative Order";
          break;
      }
    } else {
      orderStatus = "NA";
    }

    return orderStatus;
  }

  function onPrintClick() {
    setShowOpenOrdersModalPopup(true);
  }

  const onModalClose = (e: React.MouseEvent<HTMLButtonElement>) => {
    Promise.resolve().then(() => {
      setShowOpenOrdersModalPopup(false);
    });
  };

  function onExcelClick() {
    const data = CSVDataSort();
    GenerateCSV("OISOpenRecentOrders", data, ",,,,VMI Open Orders\n\n");
  }

  const CSVDataSort = () => {
    const allData: any = [];
    const Header = {
      Name: "",
      No: "",
      Prod: "",
      Capacity: "",
      Inventory: "",
      Point: "",
      Quantity: "",
      Order: "",
      Arrival: "",
      "Order No": "",
      Status: "",
    };
    allData.push(Header);

    for (let i = 0; i < openOrders.length; i++) {
      let openOrderData = {
        Name: openOrders[i].loC_NAME,
        No: openOrders[i].loC_SHIPTO,
        Prod: openOrders[i].proD_CODE,
        Capacity: openOrders[i].capacity,
        Inventory: openOrders[i].actuaL_INV,
        Point: openOrders[i].reordeR_PNT,
        Quantity: openOrders[i].reordeR_QTY,
        Order: dayjs(openOrders[i].ordeR_DATE).format("MM/DD/YYYY"),
        Arrival: openOrders[i].arrivaL_DATE,
        "Order No": openOrders[i].ordeR_NO,
        Status: openOrders[i].orderState,
      };
      allData.push(openOrderData);
    }
    return allData;
  };

  return (
    <React.Fragment>
      <PageHeader
        title="Open Orders"
        subtitle=" Select a customer from the drop-down list above to show pending and/or unshipped orders. Select a location from the drop-down list above to show orders specific to that location."
      ></PageHeader>
      <Card
        sx={{
          width: "100hv",
          borderRadius: 3,
          margin: 2,
          mt: 0,
        }}
        elevation={6}
      >
        <CustomCardHeader
          title=""
          action={
            <div>
              <Button
                onClick={onPrintClick}
                startIcon={<PrintIcon />}
                sx={{ mr: 2 }}
              >
                Print
              </Button>
              <Button onClick={onExcelClick} startIcon={<DownloadIcon />}>
                Export
              </Button>
            </div>
          }
        ></CustomCardHeader>
        <CardContent sx={{ pb: 0 }}>
          <Tbl>
            <TableBody>
              {GetRowsAfterPagingAndSorting().map((row, index) => (
                <StyledTableRow key={index}>
                  <StyledTableCell scope="row">
                    <RouterLink
                      onClick={() => {
                        return null;
                      }}
                      to={"/locationShipmentSummary"}
                      style={{
                        color: row.colorName,
                      }}
                    >
                      {row.loC_NAME}
                    </RouterLink>
                  </StyledTableCell>
                  <StyledTableCell scope="row">
                    {row.loC_SHIPTO}
                  </StyledTableCell>
                  <StyledTableCell scope="row">
                    <Tooltip title={row.proD_DESC}>
                      <div>{row.proD_CODE}</div>
                    </Tooltip>
                  </StyledTableCell>
                  <StyledTableCell scope="row">
                    {row.formattedCapacity}
                  </StyledTableCell>
                  <StyledTableCell scope="row">
                    {row.formattedActuaL_INV}
                  </StyledTableCell>
                  <StyledTableCell scope="row">
                    {row.formattedReordeR_PNT}
                  </StyledTableCell>
                  <StyledTableCell scope="row">
                    {row.formattedReordeR_QTY}
                  </StyledTableCell>
                  <StyledTableCell scope="row">
                    {row.ordeR_DATE}
                  </StyledTableCell>
                  <StyledTableCell scope="row">
                    {row.arrivaL_DATE}
                  </StyledTableCell>
                  <StyledTableCell scope="row">{row.ordeR_NO}</StyledTableCell>
                  <StyledTableCell scope="row">
                    <Tooltip title={row.orderStateDesc}>
                      <div>{row.orderState}</div>
                    </Tooltip>
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Tbl>
        </CardContent>
      </Card>
      <ModalPopup
        open={showOpenOrdersModalPopup}
        title={"Open Orders"}
        onPopupClose={onModalClose}
        width="1500px"
      >
        <OpenOrdersPrint
          openOrder={openOrders}
          tableHeader={printHeaderCellDetails}
        />
      </ModalPopup>
    </React.Fragment>
  );
}
export default OpenOrders;
