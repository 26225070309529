/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Card, CardContent, IconButton } from "@mui/material";
import React, { useEffect, useState } from "react";
import TableBody from "@mui/material/TableBody";
import ModeEditSharpIcon from "@mui/icons-material/ModeEditSharp";
import DeleteSharpIcon from "@mui/icons-material/DeleteSharp";
import AddSharpIcon from "@mui/icons-material/AddSharp";
import { StyledTableCell, StyledTableRow } from "../../controls/tablecontrols";
import { TableHeaderCell } from "../../../models/common";
import { Customer } from "../../../models/customer";
import useTable from "../../../hooks/useTable";
import CustomerService from "../../../services/customerService";
import { uiActions } from "../../../store/ui-slice";
import ModalPopup from "../../shared/ModalPopup";
import AddEditCustomer from "../Customer/AddEditCustomer";
import { filterBandSelector, useAppDispatch } from "../../../store/store";
import PageHeader from "../../shared/PageHeader";
import CustomCardHeader from "../../shared/CustomCardHeader";
import ConfirmationMessage from "../../shared/ConfirmationMessage";
import { IFilterBandState } from "../../../interfaces/storeInterfaces";
import { useSelector } from "react-redux";
import { filterBandActions } from "../../../store/filterband-slice";
import { useLocation } from "react-router-dom";

function CustomerList() {
  const filterBandState: IFilterBandState = useSelector(filterBandSelector);
  const [custList, setCustomerList] = useState<Customer[]>([]);
  const [customerNo, setCustomerNo] = useState<string>("");
  const [deleteCustomerNo, setDeleteCustomerNo] = useState<string>("");
  const [isConfirmDelete, setIsConfirmDelete] = useState<boolean>(false);
  const [openConfirmation, setOpenConfirmation] =
    React.useState<boolean>(false);
  const [isRefreshList, setRefreshList] = React.useState<boolean>(false);
  const [showModalPopup, setShowModalPopup] = useState<boolean>(false);
  const [customerName, setDeleteCustomerName] = useState<string>("");
  const dispatch = useAppDispatch();
  const customer = useLocation();
  

  
  const tableHeaderCellDetails: TableHeaderCell[] = [
    { id: "customerName", label: "Customer Name" },
    { id: "customerNo", label: "Customer No" },
    { id: "edit", label: "Edit", disableSorting: true },
    { id: "delete", label: "Delete", disableSorting: true },
  ];

  const { Tbl, GetRowsAfterPagingAndSorting } = useTable<Customer>(
    custList,
    tableHeaderCellDetails,
    true,
    10
  );
  const { fetchData: fetchCustomersData } = CustomerService.GetCustomers();
  const { deleteData: deleteCustomer } =
    CustomerService.DeleteCustomerByNumber(deleteCustomerNo);

  useEffect(() => {
    getCustomers();
  }, [isRefreshList, filterBandState.customers]);

  useEffect(() => {
    if (customer.state !== null && customer.state !== undefined) {
      if (
        customer.state.editModal === true &&
        customer.state.editCustomerNo !== ""
      ) {
        setCustomerNo(customer.state.editCustomerNo);
        setShowModalPopup(true);
        window.history.replaceState({}, document.title);
      }
    }
  }, [customer]);

  const getCustomers = () => {
    if (isRefreshList) {
      fetchCustomersData().then((res) => {
        dispatch(filterBandActions.setCustomerList(res.responseData));
        if (res.responseData.length > 0) {
          dispatch(
            filterBandActions.setSelectedCustomer({
              customerNo: res.responseData[0].customerNo,
            })
          );
        }
        setRefreshList(false);
      });
    }
    setCustomerList(filterBandState.customers);
  };

  useEffect(() => {
    if (isConfirmDelete && deleteCustomerNo !== "") {
      dispatch(uiActions.startProgressBar());
      deleteCustomer().then((res) => {
        if (res.isSuccess) {
          dispatch(
            uiActions.displayNotification({
              message: "Customer information deleted successfully!",
              severity: "success",
            })
          );
          filterBandState.selectedCustomerNo === deleteCustomerNo
            ? dispatch(filterBandActions.removeSelectedCustomer())
            : dispatch(filterBandActions.removeSelectedLocation());
          setRefreshList(true);
        }
      });

      Promise.resolve().then(() => {
        setIsConfirmDelete(false);
        setDeleteCustomerNo("");
        setDeleteCustomerName("");
      });
    }
  }, [isConfirmDelete]);

  const onAddNewClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    Promise.resolve().then(() => {
      setCustomerNo("");
      setShowModalPopup(true);
    });
  };

  const onModalClose = (e: React.MouseEvent<HTMLButtonElement>) => {
    Promise.resolve().then(() => {
      setCustomerNo("");
      setShowModalPopup(false);
    });
  };

  const handleEdit = (customerNo: string) => {
    Promise.resolve().then(() => {
      setCustomerNo(customerNo);
      setShowModalPopup(true);
    });
  };

  const handleOpenDeleteConfirmation = (
    customerNo: string,
    customerName: string
  ) => {
    Promise.resolve().then(() => {
      setOpenConfirmation(true);
      setDeleteCustomerNo(customerNo);
      setDeleteCustomerName(customerName);
    });
  };

  const handleConfirmDelete = (e: React.MouseEvent<HTMLButtonElement>) => {
    Promise.resolve().then(() => {
      setOpenConfirmation(false);
      setIsConfirmDelete(true);
    });
  };

  const handleCancelDelete = (e: React.MouseEvent<HTMLButtonElement>) => {
    Promise.resolve().then(() => {
      setOpenConfirmation(false);
      setDeleteCustomerNo("");
      setDeleteCustomerName("");
    });
  };

  return (
    <div>
      <PageHeader
        title="Customer Maintenance"
        subtitle="Manage customers"
      ></PageHeader>

      {
        <Card
          sx={{ width: "100hv", borderRadius: 3, margin: 2, mt: 0 }}
          elevation={6}
        >
          <CustomCardHeader
            title="Customer List"
            action={
              <Button onClick={onAddNewClick} startIcon={<AddSharpIcon />}>
                Add New
              </Button>
            }
          ></CustomCardHeader>
          <CardContent sx={{ pb: 0 }}>
            <Tbl>
              <TableBody>
                {GetRowsAfterPagingAndSorting().map((row, index) => (
                  <StyledTableRow key={index}>
                    <StyledTableCell scope="row">
                      {row.customerName}
                    </StyledTableCell>
                    <StyledTableCell scope="row">
                      {row.customerNo}
                    </StyledTableCell>
                    <StyledTableCell scope="row">
                      {/* <Tooltip title="Edit"> */}
                      <IconButton
                        aria-label="Edit"
                        size="small"
                        sx={{ paddingLeft: 1, paddingRight: 1 }}
                        onClick={(e) => handleEdit(row.customerNo)}
                      >
                        <ModeEditSharpIcon fontSize="small" />
                      </IconButton>
                      {/* </Tooltip> */}
                    </StyledTableCell>
                    <StyledTableCell scope="row">
                      {/* <Tooltip title="Delete"> */}
                      <IconButton
                        aria-label="Delete"
                        size="small"
                        sx={{ paddingLeft: 1, paddingRight: 1 }}
                        onClick={(e) =>
                          handleOpenDeleteConfirmation(
                            row.customerNo,
                            row.customerName
                          )
                        }
                      >
                        <DeleteSharpIcon fontSize="small" />
                      </IconButton>
                      {/* </Tooltip> */}
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Tbl>
          </CardContent>
        </Card>
      }
      <ModalPopup
        open={showModalPopup}
        title={customerNo !== "" ? "Edit Customer" : "Add Customer"}
        onPopupClose={onModalClose}
      >
        <AddEditCustomer
          setShowModalPopup={setShowModalPopup}
          customerNo={customerNo}
          setRefreshList={setRefreshList}
        />
      </ModalPopup>
      <ConfirmationMessage
        open={openConfirmation}
        message={
          "Do you really want to delete the Customer- " +
          '"' +
          customerName +
          '"' +
          " ? This process cannot be undone."
        }
        onYesClick={handleConfirmDelete}
        onNoClick={handleCancelDelete}
      ></ConfirmationMessage>
    </div>
  );
}

export default CustomerList;
