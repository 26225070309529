import ReactDOM from "react-dom";

interface OverlayProps {

}
function Overlay(props: React.PropsWithChildren<OverlayProps>) {
    return (
        ReactDOM.createPortal(
            props.children,
            document.getElementById('portal')!
        ))
}

export default Overlay;