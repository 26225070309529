/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Grid, TextField, Button, Container, Divider } from "@mui/material";
import { useFormik } from "formik";
import * as yup from "yup";
import UserNoticeService from "../../../services/userNoticeService";
import EmailService, { Email } from "../../../services/emailService";
import { uiActions } from "../../../store/ui-slice";
import { useAppDispatch } from "../../../store/store";
import { UserCreate } from "../../../models/userNotice";

interface CreateUserProps {
  setShowModalPopup: React.Dispatch<React.SetStateAction<boolean>>;
  setRefreshList: React.Dispatch<React.SetStateAction<boolean>>;
}

function CreateUser(props: CreateUserProps) {
  const dispatch = useAppDispatch();
  const [userInfo, setUserInfo] = useState<UserCreate>(
    UserNoticeService.GetDefaultUser()
  );
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const [isConfirmed, setIsConfirmed] = useState<boolean>(false);
  const [emailData, setEmailData] = useState<Email>(
    EmailService.GetDefaultEmailData()
  );

  const { postData: sendConfirmation } =
    EmailService.ConfirmationEmail(emailData);

  const { postData: createCustomer } = UserNoticeService.CreateUser(userInfo);

  useEffect(() => {
    if (isConfirmed) {
      sendConfirmation().then((res) => {
      });
      setIsConfirmed(false);
    }
  }, [isConfirmed]);

  useEffect(() => {
    if (isLoaded) {
      dispatch(uiActions.startProgressBar());
      createCustomer().then((res) => {
        if (res.isSuccess === true) {
          if (res.responseData.success === "Yes") {
            dispatch(
              uiActions.displayNotification({
                message: "The user is added successfully.!",
                severity: "success",
              })
            );
            let emailData: Email = {
              email_id: userInfo.vU_EMAIL,
              family_name: userInfo.vU_LAST_NAME,
              given_name: userInfo.vU_FIRST_NAME,
              subject: "Account Creation Completed",
              title: "",
              userType: "",
            };
            setEmailData(emailData);
            setIsConfirmed(true);
            props.setShowModalPopup(false);
            props.setRefreshList(true);
          } else if (res.responseData.success === "No") {
            dispatch(
              uiActions.logErrorNotification({
                message:
                  "There is already a user exist with same information .",
                severity: "error",
              })
            );
          }
        }
      });
    } else {
      setIsLoaded(true);
    }
  }, [userInfo]);

  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
  const validationSchema = yup.object({
    vU_FIRST_NAME: yup.string().required("Please enter First Name"),
    vU_LAST_NAME: yup.string().required("Please enter Last Name"),
    vU_EMAIL: yup
      .string()
      .email("Invalid Email")
      .required("Please enter Email")
      .max(70, "Email Id should be less than 70 characters"),
    vU_PHONE: yup
      .string()
      .matches(phoneRegExp, "Invalid Phone Number")
      .min(10, "Invalid Phone Number")
      .max(10, "Invalid Phone Number"),
  });

  const formik = useFormik({
    initialValues: UserNoticeService.GetDefaultUser(),
    validationSchema: validationSchema,
    onReset: (Event) => {
      formik.values = UserNoticeService.GetDefaultUser();
    },
    onSubmit: (values: UserCreate) => {
      setUserInfo(values);
    },
  });

  return (
    <React.Fragment>
      <Container>
        <form onSubmit={formik.handleSubmit} id="form1" className="form">
          <Grid container spacing={3} sx={{ paddingTop: 1 }}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <TextField
                id="vU_FIRST_NAME"
                name="vU_FIRST_NAME"
                value={formik.values.vU_FIRST_NAME}
                label="First Name"
                fullWidth
                onChange={formik.handleChange}
                error={
                  formik.touched.vU_FIRST_NAME &&
                  Boolean(formik.errors.vU_FIRST_NAME)
                }
                helperText={
                  formik.touched.vU_FIRST_NAME && formik.errors.vU_FIRST_NAME
                }
                inputProps={{ maxLength: 50 }}
              ></TextField>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <TextField
                id="vU_LAST_NAME"
                name="vU_LAST_NAME"
                value={formik.values.vU_LAST_NAME}
                label="Last Name"
                fullWidth
                onChange={formik.handleChange}
                error={
                  formik.touched.vU_LAST_NAME &&
                  Boolean(formik.errors.vU_LAST_NAME)
                }
                helperText={
                  formik.touched.vU_LAST_NAME && formik.errors.vU_LAST_NAME
                }
                inputProps={{ maxLength: 50 }}
              ></TextField>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <TextField
                id="vU_EMAIL"
                name="vU_EMAIL"
                value={formik.values.vU_EMAIL}
                label="Email"
                fullWidth
                onChange={formik.handleChange}
                error={
                  formik.touched.vU_EMAIL && Boolean(formik.errors.vU_EMAIL)
                }
                helperText={formik.touched.vU_EMAIL && formik.errors.vU_EMAIL}
                inputProps={{ maxLength: 50 }}
              ></TextField>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <TextField
                id="vU_PHONE"
                name="vU_PHONE"
                value={formik.values.vU_PHONE}
                label="Phone"
                fullWidth
                onChange={formik.handleChange}
                error={
                  formik.touched.vU_PHONE && Boolean(formik.errors.vU_PHONE)
                }
                helperText={formik.touched.vU_PHONE && formik.errors.vU_PHONE}
                inputProps={{ maxLength: 20 }}
              ></TextField>
            </Grid>
          </Grid>
        </form>
      </Container>
      <Divider sx={{ mt: 4, mb: 2 }}></Divider>
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        sx={{
          justifyContent: "flex-end",
          alignItems: "flex-end",
          display: "flex",
        }}
      >
        <Button onClick={formik.handleReset} sx={{ mr: 1 }}>
          Reset
        </Button>
        <Button
          form="form1"
          type="submit"
          onSubmit={(e) => formik.handleSubmit}
        >
          {"Submit"}
        </Button>
      </Grid>
    </React.Fragment>
  );
}

export default CreateUser;
