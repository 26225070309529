/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Grid, Table } from "@mui/material";
import TableBody from "@mui/material/TableBody";
import { Card, CardContent } from "@mui/material";
import { StyledTableCell, StyledTableRow } from "../../controls/tablecontrols";
import { Alarm, TankInformation } from "../../../models/tank";
import CustomCardHeader from "../../shared/CustomCardHeader";
import moment from "moment";
import admTankImg from "../../../assets/admTank.png";
import admTankHorImg from "../../../assets/admTankHorizontal.png";
import {
  FormatInvResult,
  FormatNumber,
  FormatUnit,
} from "../../shared/CommonFunctions";
import { IFilterBandState } from "../../../interfaces/storeInterfaces";
import { useDispatch, useSelector } from "react-redux";
import { filterBandSelector } from "../../../store/store";
import { useNavigate } from "react-router-dom";
import { uiActions } from "../../../store/ui-slice";

interface Props {
  alarm: Alarm;
  tankInfo: TankInformation;
}

function InventoryInformation({ alarm, tankInfo }: Props) {
  const role = sessionStorage.getItem("roles");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const filterBandState: IFilterBandState = useSelector(filterBandSelector);

  const noRec = (
    <CardContent>
      <Table>
        <TableBody>
          <StyledTableRow>
            <StyledTableCell>No records found</StyledTableCell>
          </StyledTableRow>
        </TableBody>
      </Table>
    </CardContent>
  );

  const onEditClick = (tankNumber: number) => {
    const tankID = filterBandState.tanks.filter(
      (row) => row.vT_TANK_NO === tankNumber
    )[0].vT_TANK_ID;
    if (
      String(tankInfo.vT_TANK_NO) !== "" &&
      tankInfo.vT_TANK_NO !== undefined &&
      tankInfo.vT_TANK_NO !== null
    ) {
      navigate("/tanks", {
        state: { editTankID: tankID, editModal: true },
      });
    } else {
      dispatch(
        uiActions.displayNotification({
          message: "Tank not selected",
          severity: "error",
        })
      );
    }
  };

  let masterColour;
  let alarmResult = alarm.result.trim().toUpperCase();
  if (alarmResult.toUpperCase() === "NO DATA") {
    masterColour = "orange";
  } else if (alarmResult.toUpperCase() === "NO") {
    masterColour = "green";
  } else {
    let dateTimeNow;
    let dateTimeLastUpdate;
    let timeSinceUpdateInMinutes = 0;
    let duration;
    dateTimeNow = moment(new Date()); //todays date
    dateTimeLastUpdate = moment(new Date(alarm.result)); // last updated date
    duration = moment.duration(dateTimeNow.diff(dateTimeLastUpdate));
    timeSinceUpdateInMinutes = duration.asMinutes();

    if (timeSinceUpdateInMinutes >= 240) {
      masterColour = "red";
    } else if (timeSinceUpdateInMinutes < 240) {
      masterColour = "#ffff00";
    }
  }

  let tankType;
  let tankLength;

  let tankOverlay = (
    <div style={{ textAlign: "center" }}>
      {tankInfo.vpT_PRODUCT_TYPE.toUpperCase() === "LIQUID" ? (
        <>
          <span> {FormatNumber(tankInfo.inV_GAL) + " Gal"}</span>
          <br />
        </>
      ) : (
        ""
      )}

      {tankInfo.vpT_PRODUCT_TYPE.toUpperCase() !== "GAS" ? (
        <>
          <span>
            {" "}
            {FormatInvResult(
              parseFloat(tankInfo.stR_InvLevels),
              tankInfo.vpL_UOM
            )}
          </span>
          <br />
        </>
      ) : (
        ""
      )}
      <span>{tankInfo.stR_TankLevels + "% Full"}</span>
      <br />
      <span>
        {FormatNumber(parseFloat(tankInfo.stR_LevelInches)) + " Inches"}
      </span>
    </div>
  );

  let tankCurrLvl: any;
  if (parseInt(tankInfo.currentLevel) > 100) {
    tankCurrLvl = 100;
  } else if (parseInt(tankInfo.currentLevel) < 0) {
    tankCurrLvl = 0;
  } else {
    tankCurrLvl = tankInfo.currentLevel;
  }

  let tankOrientation = tankInfo.vT_TANK_ORIENTATION.trim().toUpperCase();

  if (tankOrientation === "V") {
    tankType = (
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 1, md: 1 }}>
        <Grid item xs={12} sm={12} md={12} lg={5}>
          <div
            className="tk"
            style={{
              position: "relative",
              textAlign: "center",
              color: "white",
            }}
          >
            <div>
              <img src={admTankImg} alt="Tank" />
              <div
                className="tankV"
                style={{ position: "absolute", bottom: "27px", left: "43%" }}
              >
                <div
                  style={{
                    height: "133px ",
                    backgroundColor: "#61799F",
                    border: "1px solid black",
                    width: "20px",
                  }}
                >
                  <div
                    style={{
                      height: tankCurrLvl + "%",
                      backgroundColor: masterColour,
                      border: "0",
                      width: "18px",
                      bottom: "1px",
                      position: "absolute",
                    }}
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={7}>
          <Card
            sx={{
              width: "100hv",
              borderRadius: 3,
              margin: 2,
              mt: 0,
              backgroundColor: "lightblue",
            }}
            elevation={6}
          >
            <CardContent>{tankOverlay}</CardContent>
          </Card>
        </Grid>
      </Grid>
    );
  } else if (tankOrientation === "H") {
    tankLength = (
      <>
        <StyledTableCell style={{ fontWeight: "bold" }}>
          Length (ft):
        </StyledTableCell>
        <StyledTableCell>{tankInfo.vT_TANK_LENGTH}</StyledTableCell>
      </>
    );
    tankType = (
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 1, md: 1 }}>
        <Grid item xs={12} sm={12} md={12} lg={7}>
          <div
            style={{
              position: "relative",
              textAlign: "center",
              color: "white",
            }}
          >
            <div>
              <img src={admTankHorImg} alt="Tank" />
              <div
                className="tankV tankH"
                style={{ position: "absolute", bottom: "33px", left: "48%" }}
              >
                <div
                  style={{
                    height: "75px ",
                    backgroundColor: "#61799F",
                    border: "1px solid black",
                    width: "20px",
                  }}
                >
                  <div
                    style={{
                      height: tankCurrLvl + "%",
                      backgroundColor: masterColour,
                      border: "0",
                      width: "18px",
                      bottom: "1px",
                      position: "absolute",
                    }}
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={5}>
          <Card
            sx={{
              width: "100hv",
              borderRadius: 3,
              margin: 2,
              mt: 0,
              backgroundColor: "lightblue",
            }}
            elevation={6}
          >
            <CardContent>{tankOverlay}</CardContent>
          </Card>
        </Grid>
      </Grid>
    );
  }

  return (
    <Card
      sx={{ width: "100hv", borderRadius: 3, margin: 2, mt: 0 }}
      elevation={6}
    >
      <CustomCardHeader
        title="Tank Specifications"
        action={
          role?.toString().toUpperCase() === "ADMIN" ? (
            <>
              <Button
                variant="text"
                sx={{ fontSize: "0.90rem" }}
                onClick={() => onEditClick(tankInfo.vT_TANK_NO)}
              >
                <u>Edit</u>
              </Button>
            </>
          ) : (
            ""
          )
        }
      ></CustomCardHeader>
      {tankInfo.vT_TANK_NAME !== null && tankInfo.vT_TANK_NAME !== "" ? (
        <CardContent>
          <Table>
            <TableBody>
              <StyledTableRow>
                <StyledTableCell style={{ fontWeight: "bold" }}>
                  Capacity({FormatUnit(tankInfo.vpL_UOM)})
                </StyledTableCell>
                <StyledTableCell>
                  {FormatNumber(tankInfo.caP_LBS)}
                </StyledTableCell>
              </StyledTableRow>
              <StyledTableRow>
                <StyledTableCell style={{ fontWeight: "bold" }}>
                  Capacity(Gal)
                </StyledTableCell>
                <StyledTableCell>
                  {tankInfo.vpT_PRODUCT_TYPE.toUpperCase() === "LIQUID"
                    ? FormatInvResult(tankInfo.caP_GAL, "")
                    : "Not Applicable"}
                </StyledTableCell>
              </StyledTableRow>
              <StyledTableRow>
                <StyledTableCell style={{ fontWeight: "bold" }}>
                  Capacity(ft³)
                </StyledTableCell>
                <StyledTableCell>
                  {FormatNumber(tankInfo.caP_CUFT)}
                </StyledTableCell>
              </StyledTableRow>
              <StyledTableRow>
                <StyledTableCell style={{ fontWeight: "bold" }}>
                  Diameter(ft)
                </StyledTableCell>
                <StyledTableCell>
                  {FormatNumber(tankInfo.vT_TANK_DIAMETER)}
                </StyledTableCell>
              </StyledTableRow>
              <StyledTableRow>
                <StyledTableCell style={{ fontWeight: "bold" }}>
                  Height(ft)
                </StyledTableCell>
                <StyledTableCell>
                  {FormatNumber(tankInfo.vT_TANK_HEIGHT)}
                </StyledTableCell>
              </StyledTableRow>
              <StyledTableRow>{tankLength}</StyledTableRow>
            </TableBody>
          </Table>
        </CardContent>
      ) : (
        noRec
      )}
      <CardContent>{tankType}</CardContent>
    </Card>
  );
}

export default InventoryInformation;
