/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import TableBody from "@mui/material/TableBody";
import { StyledTableCell, StyledTableRow } from "../../controls/tablecontrols";
import {
  Grid,
  TextField,
  Button,
  Card,
  CardContent,
  MenuItem,
  Link,
} from "@mui/material";

import { filterBandSelector, useAppDispatch } from "../../../store/store";
import { uiActions } from "../../../store/ui-slice";
import CustomCardHeader from "../../shared/CustomCardHeader";
import ShipmentService from "../../../services/shipmentService";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import PageHeader from "../../shared/PageHeader";
import { TableHeaderCell } from "../../../models/common";
import useTable from "../../../hooks/useTable";
import {
  COAFile,
  InputTunrnedCars,
  InTransitTurnedCars,
  ProductCode,
} from "../../../models/shipment";
import { IFilterBandState } from "../../../interfaces/storeInterfaces";
import { useSelector } from "react-redux";
import { useFormik } from "formik";
import * as yup from "yup";
import shipmentService from "../../../services/shipmentService";
import moment from "moment";
import { base64ToArrayBuffer } from "../../shared/CommonFunctions";
import { filterBandActions } from "../../../store/filterband-slice";

function IntransitTurnedCars() {
  const dispatch = useAppDispatch();
  const filterBandState: IFilterBandState = useSelector(filterBandSelector);
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const [productCodes, setProductCodeData] = useState<ProductCode[]>([]);
  const [inputIntTCars, setInputIntTCars] = useState<InputTunrnedCars>(
    shipmentService.GetDefaultInputTurnedCars()
  );
  const [turnedCarList, setTurnedCarList] = useState<InTransitTurnedCars[]>([]);
  const [carNumber, setCarNumber] = useState<string>("");
  const [turnedCarDate, setTurnedCarDate] = useState<string>("");
  const { fetchData: fetchRetrieveShipmentTurnedCars } =
    ShipmentService.RetrieveShipmentTurnedCars(
      inputIntTCars.fromDate === "" || inputIntTCars.fromDate == null
        ? "1900-01-01T00:00:00.000Z"
        : inputIntTCars.fromDate,
      inputIntTCars.toDate === "" || inputIntTCars.toDate == null
        ? "2050-12-31T00:00:00.000Z"
        : inputIntTCars.toDate,
      filterBandState.selectedProductID === "ALL"
        ? ""
        : filterBandState.selectedProductID
    );

  const { fetchData: fetchRetrieveShipmentTurnedCarsDates } =
    ShipmentService.RetrieveShipmentTurnedCarsDates(
      inputIntTCars.fromDate === "" || inputIntTCars.fromDate == null
        ? "1900-01-01T00:00:00.000Z"
        : inputIntTCars.fromDate,
      inputIntTCars.toDate === "" || inputIntTCars.toDate == null
        ? "2050-12-31T00:00:00.000Z"
        : inputIntTCars.toDate,
      filterBandState.selectedProductID === "ALL"
        ? ""
        : filterBandState.selectedProductID
    );

  const { fetchData: fetchProductCodesData } =
    ShipmentService.RetrieveProductsByLocation(
      filterBandState.selectedCustomerNo,
      filterBandState.selectedLocationNo
    );
    

  const { fetchData: fetchRetrieveCOA } = ShipmentService.RetrieveCOA(
    carNumber,
    dayjs(turnedCarDate).format("MM-DD-YYYY")
  );

  const tableHeaderCellDetails: TableHeaderCell[] = [
    { id: "vtC_CAR_NUMBER", label: "Car Number" },
    { id: "vtC_PRODUCT_CODE", label: "Product Code" },
    { id: "vtC_TURNED_USER_ID", label: "Turned User ID" },
    { id: "vtC_TURNED_DATETIME", label: "Turned Datetime" },
    { id: "vtC_COA_USER_ID", label: "COA File", disableSorting: true },
    { id: "vtC_COA_DATETIME", label: "COA Datetime" },
    { id: "vtC_REC_NUM", label: "Rec Num" },
    { id: "vtC_PRODUCT_SUPPLIER", label: "Product Supplier" },
    { id: "vtC_PO_LOC", label: "PO Loc" },
    { id: "vtC_PO_DEPT", label: "PO Dept" },
    { id: "vtC_PO_NUMBER", label: "PO Number" },
  ];

  const {
    Tbl: TblITCars,
    GetRowsAfterPagingAndSorting: GetRowsIntransitTurnedCars,
  } = useTable<InTransitTurnedCars>(
    turnedCarList,
    tableHeaderCellDetails,
    true,
    10
  );

  useEffect(() => {
    if (!isLoaded) {
      if (
        filterBandState.selectedCustomerNo !== "" &&
        filterBandState.selectedCustomerNo !== undefined
      ) {
        loadProduct();
        LoadTurnedCarsDetails();
      }
    }
  }, [filterBandState.selectedCustomerNo, filterBandState.selectedLocationNo]);

  useEffect(() => {
    if (carNumber !== "") {
      dispatch(uiActions.startProgressBar());
      fetchRetrieveCOA().then((res) => {
        if (res.isSuccess) {
          ShowCOAFile(res.responseData);
        }
      });
    }
  }, [carNumber]);

  useEffect(() => {
    if (isLoaded) {
      if (
        filterBandState.selectedCustomerNo !== "" &&
        filterBandState.selectedCustomerNo !== undefined
      ) {
        if (
          filterBandState.selectedProductID === "" ||
          filterBandState.selectedProductID === "ALL" ||
          filterBandState.selectedProductID == null
        ) {
          if (
            inputIntTCars.fromDate === "01-01-1990" ||
            inputIntTCars.fromDate === "" ||
            inputIntTCars.fromDate == null
          ) {
            dispatch(uiActions.startProgressBar());
            fetchRetrieveShipmentTurnedCars().then((res) => {
              if (res.isSuccess) {
                setTurnedCarList(res.responseData);
              }
            });
          } else {
            dispatch(uiActions.startProgressBar());
            fetchRetrieveShipmentTurnedCarsDates().then((res) => {
              if (res.isSuccess) {
                setTurnedCarList(res.responseData);
              }
            });
          }
        } else {
          if (
            inputIntTCars.fromDate === "01-01-1990" ||
            inputIntTCars.fromDate === "" ||
            inputIntTCars.fromDate == null
          ) {
            dispatch(uiActions.startProgressBar());
            fetchRetrieveShipmentTurnedCars().then((res) => {
              if (res.isSuccess) {
                setTurnedCarList(res.responseData);
              }
            });
          } else {
            dispatch(uiActions.startProgressBar());
            fetchRetrieveShipmentTurnedCarsDates().then((res) => {
              if (res.isSuccess) {
                setTurnedCarList(res.responseData);
              }
            });
          }
        }
      }
    } else {
      setIsLoaded(true);
    }
  }, [
    inputIntTCars,
    filterBandState.selectedCustomerNo,
    filterBandState.selectedProductID,
  ]);

  function ShowCOAFile(response: COAFile) {
    if (response.coA_FILE !== "") {
      let contentType = "";
      let fileName = "";
      switch (response.filE_TYPE.toUpperCase()) {
        case "PDF":
          contentType = "application/pdf";
          fileName = "COA.pdf";
          break;
        case "DOC":
          contentType = "application/msword";
          fileName = "COA.doc";
          break;
        case "XLS":
          contentType = "application/nvd-excel";
          fileName = "COA.xls";
          break;
        case "TIF":
          contentType = "application/tif";
          fileName = "COA.tif";
          break;
        case "JPG":
          contentType = "application/image";
          fileName = "COA.jpg";
          break;
        case "BMP":
          contentType = "application/image";
          fileName = "COA.bmp";
          break;
        default:
          contentType = "application/image";
          fileName = "";
      }

      var arrrayBuffer = base64ToArrayBuffer(response.coA_FILE); //data is the base64 encoded string
      var blob = new Blob([arrrayBuffer], { type: contentType });
      var link = window.URL.createObjectURL(blob);
      window.open(link, fileName, "");
      return "";
    }
  }

  const LoadTurnedCarsDetails = () => {
    dispatch(uiActions.startProgressBar());
    fetchRetrieveShipmentTurnedCars().then((res) => {
      setTurnedCarList(res.responseData);
    });
  };

  const loadProduct = async () => {
    dispatch(uiActions.startProgressBar());
    await fetchProductCodesData().then((res) => {
      setProductCodeData(res.responseData);
    });
  };
  const productChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let productID = event.target.value;
    dispatch(
      filterBandActions.setSelectedProduct({
        productId: productID,
      })
    );
    if (productID === "ALL") {
      productID = "";
    }
    formik.setFieldValue("productId", productID);
  };

  const validationSchema = yup.object({
    fromDate: yup
      .date()
      .nullable(true)
      // .required("From date must be entered.")
      .typeError("Please enter a valid From Date."),

    toDate: yup
      .date()
      .nullable(true)
      .typeError("Please enter a valid To Date.")
      .min(yup.ref("fromDate"), "To Date should be after the From Date."),
  });

  const handleCOAFile = (carNumber: string, turnedCarDate: string) => {
    setCarNumber(carNumber);
    setTurnedCarDate(turnedCarDate);
  };

  function renderCOAFile(file: string, carNumber: string, turnedDate: string) {
    if (turnedCarList.length > 0) {
      if (file != null) {
        if (
          file.toUpperCase() === "DOC" ||
          file.toUpperCase() === "PDF" ||
          file.toUpperCase() === "XLS" ||
          file.toUpperCase() === "TIF" ||
          file.toUpperCase() === "BMP" ||
          file.toUpperCase() === "JPG"
        )
          return (
            <Link
              component="button"
              style={{ color: "Green", fontWeight: "bold" }}
              onClick={() => handleCOAFile(carNumber, turnedDate)}
            >
              View
            </Link>
          );
      } else {
        return "";
      }
    }
  }

  const formik = useFormik({
    initialValues: shipmentService.GetDefaultInputTurnedCars(),
    validationSchema: validationSchema,
    onSubmit: (values: InputTunrnedCars) => {
      setInputIntTCars(values);
    },
  });

  const fDateChanger = (newValue: dayjs.Dayjs | null) => {
    if (newValue !== null && dayjs(newValue).isValid()) {
      formik.setFieldValue("fromDate", dayjs(newValue).format("MM-DD-YYYY"));
    } else if (newValue === null) {
      formik.setFieldValue("fromDate", null);
    } else {
      formik.setFieldValue("fromDate", newValue);
    }
  };

  const clearDate = (event: string) => {
    let inputValues = {
      fromDate: "",
      toDate: "",
      productId: "ALL",
    };
    setInputIntTCars(inputValues);
  };

  const tDateChanger = (newValue: dayjs.Dayjs | null) => {
    if (newValue !== null && dayjs(newValue).isValid()) {
      formik.setFieldValue("toDate", dayjs(newValue).format("MM-DD-YYYY"));
    } else if (newValue === null) {
      formik.setFieldValue("toDate", null);
    } else {
      formik.setFieldValue("toDate", newValue);
    }
  };

  return (
    <React.Fragment>
      <PageHeader
        title="In-Transit Product Summary"
        subtitle="Click on the Location Name to view the location summary.
             Product Inventories shows the current inventory for all the locations summation by product."
      ></PageHeader>
      <Card
        sx={{ width: "100hv", borderRadius: 3, margin: 2, mt: 0 }}
        elevation={6}
      >
        <CardContent sx={{ pb: 0 }}>
          <form onSubmit={formik.handleSubmit} id="form1" className="form">
            <Grid container columnSpacing={{ xs: 1, sm: 1, md: 4, lg: 4 }}>
              <Grid item xs={3} sm={4} md={3} lg={3}>
                <TextField
                  id="Product ID"
                  name="vp_PRODUCT_ID"
                  select
                  label="Product"
                  value={
                    filterBandState.selectedProductID !== ""
                      ? filterBandState.selectedProductID
                      : "ALL"
                  }
                  onChange={productChange}
                  fullWidth
                >
                  <MenuItem value={"ALL"}>Please Select a Product</MenuItem>
                  {productCodes.map((row, index) => {
                    if (row.vpL_RAIL_USER === true) {
                      return (
                        <MenuItem
                          key={row.vP_PRODUCT_ID + "$" + index}
                          value={String(row.vP_PRODUCT_CODE)}
                        >
                          {row.vP_PRODUCT_DESC}
                        </MenuItem>
                      );
                    }
                  })}
                </TextField>
              </Grid>
              <Grid item xs={3} sm={3} md={2} lg={2}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="From"
                    value={
                      formik.values.fromDate !== null &&
                        formik.values.fromDate !== undefined &&
                        formik.values.fromDate !== ""
                        ? dayjs(formik.values.fromDate)
                        : null
                    }
                    onChange={(newValue) => {
                      fDateChanger(newValue);
                    }}
                    slotProps={{
                      textField: {
                        onKeyDown: (event) => clearDate(""),
                        variant: "standard",
                        error:
                          formik.touched.fromDate &&
                          Boolean(formik.errors.fromDate),
                        helperText:
                          formik.touched.fromDate && formik.errors.fromDate,
                      },
                    }}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={3} sm={3} md={2} lg={2}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="To"
                    value={
                      formik.values.toDate !== null &&
                        formik.values.toDate !== undefined &&
                        formik.values.toDate !== ""
                        ? dayjs(formik.values.toDate)
                        : null
                    }
                    onChange={(newValue) => {
                      tDateChanger(newValue);
                    }}
                    slotProps={{
                      textField: {
                        onKeyDown: (event) => clearDate(""),
                        variant: "standard",
                        error:
                          formik.touched.toDate &&
                          Boolean(formik.errors.toDate),
                        helperText:
                          formik.touched.toDate && formik.errors.toDate,
                      },
                    }}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={3} sm={2} md={3} lg={3}>
                <Button
                  form="form1"
                  type="submit"
                  sx={{ mr: 1 }}
                  onSubmit={(e) => formik.handleSubmit}
                >
                  Search Cars
                </Button>
              </Grid>
            </Grid>
          </form>
        </CardContent>
      </Card>
      {
        <Card
          sx={{ width: "100hv", borderRadius: 3, margin: 2, mt: 0 }}
          elevation={6}
        >
          <CustomCardHeader title="Car Details"></CustomCardHeader>
          <CardContent sx={{ pb: 0 }}>
            <TblITCars>
              <TableBody>
                {GetRowsIntransitTurnedCars().map((row, index) => (
                  <StyledTableRow key={index}>
                    <StyledTableCell>{row.vtC_CAR_NUMBER}</StyledTableCell>
                    <StyledTableCell>{row.vtC_PRODUCT_CODE}</StyledTableCell>
                    <StyledTableCell>{row.vtC_TURNED_USER_ID}</StyledTableCell>
                    <StyledTableCell>
                      {moment(row.vtC_TURNED_DATETIME).format(
                        "MM/DD/YYYY HH:mm:ss A"
                      )}
                    </StyledTableCell>
                    <StyledTableCell>
                      {renderCOAFile(
                        row.rC_FILE_TYPE,
                        row.vtC_CAR_NUMBER,
                        row.vtC_TURNED_DATETIME
                      )}
                    </StyledTableCell>
                    <StyledTableCell>{row.vtC_COA_DATETIME}</StyledTableCell>
                    <StyledTableCell>{row.vtC_REC_NUM}</StyledTableCell>
                    <StyledTableCell>
                      {row.vtC_PRODUCT_SUPPLIER}
                    </StyledTableCell>
                    <StyledTableCell>{row.vtC_PO_LOC}</StyledTableCell>
                    <StyledTableCell>{row.vtC_PO_DEPT}</StyledTableCell>
                    <StyledTableCell>{row.vtC_PO_NUMBER}</StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </TblITCars>
          </CardContent>
        </Card>
      }
    </React.Fragment>
  );
}

export default IntransitTurnedCars;
