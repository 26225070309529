/* eslint-disable import/no-anonymous-default-export */
import useAxiosGet from "../hooks/useAxiosGet";
import { Customer } from "../models/customer";
import { LocationDetails } from "../models/location";
import CustomerDataService from "./customerService";
import LocationDataService from "./locationService";

const isLocal = () => process.env.REACT_APP_ENVIR === "LOCAL";

const GetTransportCustomer = (transportcustomer: string) => {
  return useAxiosGet<Customer>(
    isLocal()
      ? `/DB2LookUp/GetTransportCustomer/transportcustomer/${transportcustomer}`
      : `/transportcustomer/${transportcustomer}`,
    CustomerDataService.GetDefaultCustomer(),
    "Loading Customer Information"
  );
};

const RetrieveTransportLocation = (locationID: string) => {
  return useAxiosGet<LocationDetails>(
    isLocal()
      ? `/DB2LookUp/RetrieveTransportLocation/transportlocation/${locationID}/transportlocations`
      : `/transportlocation/${locationID}/transportlocations`,
    LocationDataService.GetDefaultLocationInfo(),
    "Location Information"
  );
};

export default {
  GetTransportCustomer,
  RetrieveTransportLocation,
};
