import { download, generateCsv, mkConfig } from "export-to-csv";

function GenerateCSV(
  fileName: string,
  data: any = [],
  title?: string,
  quoteCharacter?: string
) {
  const csvConfig = mkConfig({
    useKeysAsHeaders: true,
    filename: fileName,
    showColumnHeaders: true,
    showTitle: title !== "" && title !== undefined ? true : false,
    title: title,
    quoteStrings: true,
    quoteCharacter:
      quoteCharacter !== undefined && quoteCharacter !== null
        ? quoteCharacter
        : "",
  });

  const csv = generateCsv(csvConfig)(data);
  download(csvConfig)(csv);
}

export default GenerateCSV;
