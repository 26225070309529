import React, { useCallback } from "react";
import { AppBar, Box, Button, Card } from "@mui/material";
import { Toolbar } from "@mui/material";
import Typography from "@mui/material/Typography";
import logo from "../assets/ADMLogo.png";
import { SiteContentBox } from "../components/controls/commoncontrols";
import Footer from "../components/layout/Footer";
import ADMLine from "../components/layout/ADMLine";
import { authProvider } from "../config/authConfig";
interface Props {
  msg: any;
  type: any;
  buttonVisible?: boolean;
}

function ContactAdministrator(props: React.PropsWithChildren<Props>) {
  function clearStorage() {
    window.sessionStorage.clear();
    window.localStorage.clear();
  }

  const onLoginClick = useCallback(() => {
    clearStorage();
    authProvider.logout();
  }, []);

  return (
    <div>
      <AppBar>
        <Toolbar>
          <img src={logo} alt="ADM logo"></img>
          <Typography
            variant="subtitle1"
            sx={{
              flexGrow: 1,
              fontSize: { xs: "1rem", sm: "1rem", md: "1.25rem", lg: "1.5rem" },
            }}
            textAlign="center"
          >
            Online Inventory Services
          </Typography>
        </Toolbar>
        <ADMLine></ADMLine>
      </AppBar>
      <Box display="flex">
        <SiteContentBox sx={{ mt: "80px !important" }}>
          <Card
            style={{
              textAlign: "center",
              paddingBottom: "20px",
              padding: "50px",
            }}
            sx={{ width: "100hv", borderRadius: 3, margin: 2 }}
            elevation={6}
          >
            <h2>{props.msg}</h2>
            {props.buttonVisible !== false && (
              <Button onClick={onLoginClick}>Click to login</Button>
            )}
          </Card>
        </SiteContentBox>
      </Box>
      <Footer></Footer>
      {props.type === "clear" ? clearStorage() : ""}
    </div>
  );
}

export default ContactAdministrator;
