import { styled, TableRow } from "@mui/material";
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { BgColorTableHeader, CommonFontFamily, FontColorDetailItem } from "../../utils/constant/constant";
import Paper from '@mui/material/Paper';

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: BgColorTableHeader,
    color: theme.palette.common.white,
    fontSize: '0.95rem',
    fontWeight: '520',
    fontFamily: CommonFontFamily,
    padding:6,    
    paddingLeft:8
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: '0.85rem',
    color: FontColorDetailItem,
    fontFamily:CommonFontFamily,          
    padding:6,
    paddingLeft:8
  },
}));


export const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(even)': {
    backgroundColor: '#f2f8fb',   
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));


export const StyledPaper = styled(Paper)(({ theme }) => ({
  elevation:1,
  border: "1px solid #2c275d",
}));




