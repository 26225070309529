/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Container, Grid } from "@mui/material";

import { ArrivalAdjustmentDetails } from "../../../models/order";
import OrderDataService from "../../../services/orderService";
import dayjs from "dayjs";

interface ArrivalDateAdjustmentProps {
    orderId: number,
    setArrivalDateAdjustShowModalPopup: React.Dispatch<React.SetStateAction<boolean>>;
}

function ArrivalDateAdjustment(props: ArrivalDateAdjustmentProps) {
    const [DateAdjustmentDetails, setDateAdjustmentDetails] = useState<ArrivalAdjustmentDetails>();
    const [isDataLoaded, setIsDataLoaded] = useState<boolean>(true);
    const { fetchData: fetchDateAdjustmentData } = OrderDataService.RetrieveAdjustmentDetails(props.orderId);

    const columnStyle = {
        weight: {
            fontWeight: "bold"
        },
    };

    useEffect(() => {
        if (isDataLoaded) {
            fetchDateAdjustmentData().then((res) => {
                if (res.responseData.length > 0) {
                    let entryDateInMiliseconds = new Date(res.responseData[0].vO_ENTRY_DATE).getMilliseconds();
                    if (entryDateInMiliseconds > 500) {
                        let entryDate = new Date(res.responseData[0].vO_ENTRY_DATE);
                        res.responseData[0].vO_ENTRY_DATE = entryDate.setSeconds(entryDate.getSeconds() + 1);
                    }
                    setDateAdjustmentDetails(res.responseData[0]);
                    setIsDataLoaded(false);
                }
            });
        }
    }, [props.orderId]);


    return (
        <React.Fragment>
            <Container>
                <Grid container spacing={3} sx={{ paddingTop: 1 }}>
                    <Grid item xs={6} sm={6} md={6}>
                        <span style={columnStyle.weight}>Customer Name: </span>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6}>
                        <span>{DateAdjustmentDetails?.vC_CUSTOMER_NAME}</span>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6}>
                        <span style={columnStyle.weight}>Location Name: </span>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6}>
                        <span>{DateAdjustmentDetails?.vL_LOCATION_NAME}</span>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6}>
                        <span style={columnStyle.weight}>Tank Name (ID): </span>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6}>
                        <span>{DateAdjustmentDetails?.vT_TANK_NAME + ' (' + DateAdjustmentDetails?.vO_TANK_ID + ')'}</span>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6}>
                        <span style={columnStyle.weight}>Product: </span>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6}>
                        <span>{DateAdjustmentDetails?.vP_PRODUCT_DESC} </span>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6}>
                        <span style={columnStyle.weight}>Order Date: </span>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6}>
                        <span>{dayjs(DateAdjustmentDetails?.vO_ENTRY_DATE).format("MM/DD/YYYY hh:mm:ss A")}</span>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6}>
                        <span style={columnStyle.weight}>Tank Inventory: </span>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6}>
                        <span>{DateAdjustmentDetails?.voaA_TANK_INVENTORY}</span>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6}>
                        <span style={columnStyle.weight}>Shipment Amount: </span>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6}>
                        <span>{DateAdjustmentDetails?.vS_SHIPMENT_AMOUNT}</span>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6}>
                        <span style={columnStyle.weight}>Original Arrival Date: </span>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6}>
                        <span>{DateAdjustmentDetails?.voaA_ORIGINAL_ARRIVAL_DATE}</span>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6}>
                        <span style={columnStyle.weight}>Adjusted Arrival Date: </span>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6}>
                        <span>{dayjs(DateAdjustmentDetails?.voaA_ADJUSTED_ARRIVAL_DATE).format("MM/DD/YYYY")}</span>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                        &nbsp;
                    </Grid>
                </Grid>
            </Container>
        </React.Fragment>
    )
}

export default ArrivalDateAdjustment;

