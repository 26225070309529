/* eslint-disable react-hooks/exhaustive-deps */
import { Fragment, useEffect } from "react";
import { useSelector } from "react-redux";
import {
  AppBar,
  Toolbar,
  Grid,
  SelectChangeEvent,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  IconButton,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import { BgColorFilterBand } from "../../utils/constant/constant";
import { filterBandActions } from "../../store/filterband-slice";
import { IFilterBandState } from "../../interfaces/storeInterfaces";
import { useAppDispatch, filterBandSelector } from "../../store/store";
import ADMLine from "../layout/ADMLine";
import { useNavigate } from "react-router-dom";
import CustomerService from "../../services/customerService";
import TankService from "../../services/tankService";
import CommonService from "../../services/commonService";
import ShipmentDataService from "../../services/shipmentService";
import { uiActions } from "../../store/ui-slice";

function FilterBand() {
  const filterBandState: IFilterBandState = useSelector(filterBandSelector);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { fetchData: fetchCustomerList } = CustomerService.GetCustomers();

  const { fetchData: fetchLocationList } = CustomerService.GetCustomerLocations(
    filterBandState.selectedCustomerNo
  );
  const { fetchData: fetchTankList } = TankService.RetrieveTanksBylocation(
    filterBandState.selectedCustomerNo,
    filterBandState.selectedLocationNo
  );

  const { fetchData: fetchInboundOrOutbound } =
    CommonService.DetermineInboundOrOutbound(
      filterBandState.selectedCustomerNo
    );

  const { fetchData: fetchTurnCarsUserData } =
    ShipmentDataService.RetrieveTurnedCarUser();

  const { fetchData: fetchRailUser } =
    CommonService.DetermineIfCustomerIsRailUser(
      filterBandState.selectedCustomerNo
    );

  const { fetchData: fetchRailcarTerminal } =
    CommonService.DetermineRailcarTerminal(
      filterBandState.selectedCustomerNo,
      filterBandState.selectedLocationNo
    );

  useEffect(() => {
    loadCustomerList();
  }, []);

  useEffect(() => {
    if (filterBandState.selectedCustomerNo) {
      loadLocationList();
      setCustomerFlags();
    } else {
      dispatch(filterBandActions.removeSelectedCustomer());
      dispatch(filterBandActions.removeCustomerFlags());
    }
  }, [filterBandState.selectedCustomerNo]);

  useEffect(() => {
    if (filterBandState.selectedLocationNo) {
      loadTankList();
      setLocationFlags();
    } else {
      dispatch(filterBandActions.removeSelectedLocation());
      dispatch(filterBandActions.removeRailcarTerminalFlag());
    }
  }, [filterBandState.selectedLocationNo]);

  // Loads Customer Dropdown list
  const loadCustomerList = () => {
    dispatch(uiActions.startProgressBar());
    fetchCustomerList().then((res) => {
      dispatch(filterBandActions.setCustomerList(res.responseData));
      if (res.responseData.length > 0) {
        dispatch(
          filterBandActions.setSelectedCustomer({
            customerNo: res.responseData[0].customerNo,
          })
        );
      }
    });
  };

  const loadLocationList = () => {
    fetchLocationList().then((res) => {
      dispatch(filterBandActions.setLocationList(res.responseData));
    });
  };

  const loadTankList = () => {
    fetchTankList().then((res) => {
      dispatch(filterBandActions.setTankList(res.responseData));
    });
  };

  const handleCustomerChange = (event: SelectChangeEvent) => {
    dispatch(filterBandActions.removeSelectedLocation());
    dispatch(filterBandActions.removeSelectedProduct());
    dispatch(
      filterBandActions.setSelectedCustomer({
        customerNo: event.target.value,
      })
    );
    navigate("/");
  };

  const handleLocationChange = (event: SelectChangeEvent) => {
    dispatch(filterBandActions.removeSelectedTank());
    dispatch(
      filterBandActions.setSelectedLocation({
        locationNo: event.target.value,
      })
    );
    navigate("/location");
  };

  const handleTankChange = (event: SelectChangeEvent) => {
    dispatch(
      filterBandActions.setSelectedTank({
        tankNo: event.target.value,
      })
    );
    navigate("/tank");
  };

  function clearLocation() {
    dispatch(filterBandActions.removeSelectedLocation());
    navigate("/");
  }

  function clearTank() {
    dispatch(filterBandActions.removeSelectedTank());
    navigate("/location");
  }

  //--------------------

  const setCustomerFlags = () => {
    fetchInboundOrOutbound().then((res) => {
      if (res.responseData.length > 0) {
        if (res.responseData[0].vL_INBOUND_RAIL === true) {
          dispatch(
            filterBandActions.setInboundUserFlag({
              inboundUser: true,
            })
          );
        }
        else {
          dispatch(
            filterBandActions.setInboundUserFlag({
              inboundUser: false,
            })
          );
        }
      }
    });

    fetchTurnCarsUserData().then((res) => {
      dispatch(
        filterBandActions.setTurnCarsUserFlag({
          turnCarsUser: res.responseData.bit === 1 ? true : false,
        })
      );
    });

    fetchRailUser().then((res) => {
      if (res.responseData.length > 0) {
        if (res.responseData[0].vpL_RAIL_USER === true) {
          dispatch(
            filterBandActions.setRailcarUserFlag({
              railcarUser: true,
            })
          );
        }
        else {
          dispatch(
            filterBandActions.setRailcarUserFlag({
              railcarUser: false,
            })
          );
        }
      }
    });
  };

  const setLocationFlags = () => {
    fetchRailcarTerminal().then((res) => {
      if (res.responseData.length > 0) {
        dispatch(
          filterBandActions.setRailcarTerminalFlag({
            railcarTerminal: true,
          })
        );
      }
      else {
        dispatch(
          filterBandActions.setRailcarTerminalFlag({
            railcarTerminal: false,
          })
        );
      }
    });
  };

  //--------------------

  const ITEM_HEIGHT = 46;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5,
      },
    },
  };

  return (
    <Fragment>
      <AppBar sx={{ mt: 6, backgroundColor: BgColorFilterBand }}>
        <ADMLine></ADMLine>
        <Toolbar
          sx={{
            backgroundColor: BgColorFilterBand,
            alignContent: "centre",
            textAlign: "center",
            display: "flex",
            justifyContent: "center",
            padding: 1,
          }}
        >
          <Grid
            container
            columnSpacing={{ xs: 2, sm: 2, md: 3, lg: 3 }}
            alignContent="center"
            paddingLeft={{ xs: 2, sm: 2, md: 10, lg: 10 }}
            paddingRight={{ xs: 2, sm: 2, md: 10, lg: 10 }}
          >
            <Grid item xs={6} sm={6} md={4} lg={4}>
              <FormControl variant="standard" fullWidth>
                <InputLabel id="lblCustomer">Customer</InputLabel>
                <Select
                  id="Customer"
                  value={filterBandState.selectedCustomerNo}
                  onChange={handleCustomerChange}
                  MenuProps={MenuProps}
                >
                  {filterBandState.customers.map((row) => {
                    return (
                      <MenuItem key={row.customerID} value={row.customerNo}>
                        {row.customerName}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6} sm={6} md={4} lg={4}>
              <FormControl variant="standard" fullWidth>
                <InputLabel id="lblLocation">Location</InputLabel>
                <Select
                  id="Location"
                  value={filterBandState.selectedLocationNo}
                  onChange={handleLocationChange}
                  MenuProps={MenuProps}
                  endAdornment={
                    filterBandState.selectedLocationNo !== "" ? (
                      <IconButton
                        onClick={() => {
                          clearLocation();
                        }}
                        style={{ padding: "1px", marginRight: "20px" }}
                      >
                        <ClearIcon style={{ width: "0.8em" }} />
                      </IconButton>
                    ) : (
                      ""
                    )
                  }
                >
                  {filterBandState.locations.map((row) => {
                    return (
                      <MenuItem key={row.locationID} value={row.locationNo}>
                        {row.locationNo.trim() +
                          " - " +
                          row.locationName.trim() +
                          " - " +
                          row.city.trim() +
                          ", " +
                          row.state.trim()}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6} sm={6} md={4} lg={4}>
              <FormControl variant="standard" fullWidth>
                <InputLabel id="lbltank">Tank/Unit</InputLabel>
                <Select
                  id="Tank"
                  value={filterBandState.selectedTankNo}
                  onChange={handleTankChange}
                  MenuProps={MenuProps}
                  endAdornment={
                    filterBandState.selectedTankNo !== "" ? (
                      <IconButton
                        onClick={() => {
                          clearTank();
                        }}
                        style={{ padding: "1px", marginRight: "20px" }}
                      >
                        <ClearIcon style={{ width: "0.8em" }} />
                      </IconButton>
                    ) : (
                      ""
                    )
                  }
                >
                  {filterBandState.tanks.map((row) => {
                    return (
                      <MenuItem key={row.vT_TANK_ID} value={row.vT_TANK_NO}>
                        {row.vT_TANK_NAME}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </Fragment>
  );
}

export default FilterBand;
