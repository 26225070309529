import CustomerList from "../components/app/Customer/CustomerList";
import SystemMessage from "./SystemMessage";

function CustomerMaintenancePage(){
  const role=sessionStorage.getItem("roles");
  return(
    role?.toString().toUpperCase()==="ADMIN" ?
  <CustomerList></CustomerList> : <SystemMessage msg="Access Denied." colorMsg="Red"></SystemMessage>
  );
}

export default CustomerMaintenancePage