/* eslint-disable react-hooks/exhaustive-deps */
import { Grid } from "@mui/material";
import { IFilterBandState } from "../../../interfaces/storeInterfaces";
import { filterBandSelector } from "../../../store/store";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../../store/store";
import PageHeader from "../../shared/PageHeader";
import { uiActions } from "../../../store/ui-slice";
import { useEffect, useState } from "react";
import TankDataService from "../../../services/tankService";
import { Alarm, TankInformation } from "../../../models/tank";
import AlarmInformation from "./AlarmInformation";
import InventoryInformation from "./InventoryInformation";
import SensorInformation from "./SensorInformation";
import TankSpecification from "./TankSpecification";

function TankDetails() {
  const filterBandState: IFilterBandState = useSelector(filterBandSelector);
  const dispatch = useAppDispatch();
  const defaultAlarmInformation: Alarm =
    TankDataService.GetDefaultAlarmInformation();
  const [alarm, setAlarmInfo] = useState<Alarm>(defaultAlarmInformation);
  const defaultTankInformation: TankInformation =
    TankDataService.GetDefaultTankInformation();
  const [tankInfo, setTankInfo] = useState<TankInformation>(
    defaultTankInformation
  );
  const [isRefreshList, setRefreshList] = useState<boolean>(false);

  useEffect(() => {
    if (filterBandState.selectedTankNo)
      dispatch(uiActions.startProgressBarWithTotalStepCount(2));
  }, [filterBandState.selectedTankNo]);

  const { fetchData: fetchTankInformationData } =
    TankDataService.GetTankByNumber(
      filterBandState.selectedCustomerNo,
      filterBandState.selectedLocationNo,
      filterBandState.selectedTankNo
    );

  const { fetchData: fetchAlarmInformationData } =
    TankDataService.GetAlarmInfo(
      filterBandState.selectedCustomerNo,
      filterBandState.selectedLocationNo,
      filterBandState.selectedTankNo
    );

  useEffect(() => {
    if (filterBandState.selectedLocationNo) {
      fetchAlarmInformationData().then((res) => {
        setAlarmInfo(res.responseData);
      });
    } else {
      setAlarmInfo(defaultAlarmInformation);
    }
  }, [
    filterBandState.selectedCustomerNo,
    filterBandState.selectedLocationNo,
    filterBandState.selectedTankNo,
    isRefreshList,
  ]);

  useEffect(() => {
    if (filterBandState.selectedTankNo) {
      fetchTankInformationData().then((res) => {
        setTankInfo(res.responseData);
        setRefreshList(false);
      });
    } else {
      setTankInfo(defaultTankInformation);
      setRefreshList(false);
    }
  }, [filterBandState.selectedTankNo, isRefreshList]);

  return (
    <div>
      <PageHeader title="Tank Details"></PageHeader>
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 1, md: 1 }}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <AlarmInformation
            alarm={alarm}
            tankInfo={tankInfo}
          ></AlarmInformation>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <InventoryInformation
            alarm={alarm}
            tankInfo={tankInfo}
            setRefreshList={setRefreshList}
          ></InventoryInformation>
          <SensorInformation tankInfo={tankInfo}></SensorInformation>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <TankSpecification
            alarm={alarm}
            tankInfo={tankInfo}
          ></TankSpecification>
        </Grid>
      </Grid>
    </div>
  );
}
export default TankDetails;
