/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import PageHeader from "../../shared/PageHeader";
import {
  Button,
  Card,
  CardContent,
  Grid,
  MenuItem,
  TableBody,
  TextField,
} from "@mui/material";
import { IFilterBandState } from "../../../interfaces/storeInterfaces";
import { useSelector } from "react-redux";
import { filterBandSelector, useAppDispatch } from "../../../store/store";
import ShipmentDataService from "../../../services/shipmentService";
import OrderDataService from "../../../services/orderService";
import { ProductCode } from "../../../models/shipment";
import {
  AdditionalTableHeaderCell,
  TableHeaderCell,
} from "../../../models/common";
import useTable from "../../../hooks/useTable";
import { StyledTableCell, StyledTableRow } from "../../controls/tablecontrols";
import React from "react";
import GenerateCSV from "../../shared/GenerateCSV";
import { filterBandActions } from "../../../store/filterband-slice";
import { RailcarHistory as RailcarHistoryModel } from "../../../models/order";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs, { Dayjs } from "dayjs";
import moment from "moment";
import { FormatNumber } from "../../shared/CommonFunctions";
import PrintIcon from "@mui/icons-material/Print";
import DownloadIcon from "@mui/icons-material/Download";
import CustomCardHeader from "../../shared/CustomCardHeader";
import { uiActions } from "../../../store/ui-slice";
import ModalPopup from "../../shared/ModalPopup";
import RailcarHistoryPrint from "./RailcarHistoryPrint";

interface Props {}

function RailcarHistory(props: Props) {
  const dispatch = useAppDispatch();
  const filterBandState: IFilterBandState = useSelector(filterBandSelector);
  const [productList, setProductList] = useState<ProductCode[]>([]);
  const [railcarHistoryData, setRailcarHistoryData] = useState<
    RailcarHistoryModel[]
  >([]);
  const [railcarHistoryDataFormatted, setRailcarHistoryDataFormatted] =
    useState<RailcarHistoryModel[]>([]);
  const [fromDate, setFromDate] = useState<Dayjs>(dayjs().subtract(90, "day"));
  const [toDate, setToDate] = useState<Dayjs>(dayjs());
  const [invalidDate, setInvalidDate] = useState(false);
  const [railcarHistoryPrintModalPopup, setRailcarHistoryPrintModalPopup] =
    useState<boolean>(false);

  const { fetchData: fetchProductData } =
    ShipmentDataService.RetrieveProductsByLocation(
      filterBandState.selectedCustomerNo,
      filterBandState.selectedLocationNo
    );

  const { fetchData: fetchRailcarData } =
    OrderDataService.RetrieveRailCarHistory(
      filterBandState.selectedLocationNo,
      filterBandState.selectedProductID,
      fromDate.format("YYYY-MM-DD"),
      toDate.format("YYYY-MM-DD")
    );

  useEffect(() => {
    if (
      filterBandState.selectedCustomerNo !== "" &&
      filterBandState.selectedCustomerNo !== undefined
    ) {
      loadProduct();
    }
  }, [filterBandState.selectedCustomerNo]);

  const loadProduct = () => {
    fetchProductData().then((res) => {
      setProductList(res.responseData);
    });
  };

  const loadRailcarHistory = () => {
    fetchRailcarData().then((res) => {
      setRailcarHistoryData(res.responseData);
    });
  };

  useEffect(() => {
    if (railcarHistoryData.length > 0) {
      formatData(railcarHistoryData);
    } else {
      setRailcarHistoryDataFormatted([]);
    }
  }, [railcarHistoryData]);

  const formatData = (data: RailcarHistoryModel[]) => {
    let calculatedData: RailcarHistoryModel[] = [];
    data.forEach((element) => {
      element.formatted_rH_SHIPPED_DATE = moment(
        element.rH_SHIPPED_DATE
      ).format("MM/DD/YYYY");
      element.formatted_rH_RECEIVED_DATE = moment(
        element.rH_RECEIVED_DATE
      ).format("MM/DD/YYYY hh:mm:ss A");
      element.formatted_rH_NET_WEIGHT = Number(
        FormatNumber(element.rH_NET_WEIGHT / 2204, 2)
      );
      calculatedData.push(element);
    });
    setRailcarHistoryDataFormatted(calculatedData);
  };

  useEffect(() => {
    if (filterBandState.selectedProductID !== "") {
      productChange(filterBandState.selectedProductID);
    }
  }, [filterBandState.selectedProductID]);

  const handleProductChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const productCode = event.target.value;
    productChange(productCode);
  };

  const productChange = (productCode: string) => {
    dispatch(
      filterBandActions.setSelectedProduct({
        productId: productCode,
      })
    );
  };

  const tableHeaderCellDetails: TableHeaderCell[] = [
    { id: "rH_CAR_NUMBER", label: "Car No.", align: "center" },
    { id: "origin", label: "Origin", align: "center" },
    { id: "formatted_rH_SHIPPED_DATE", label: "Ship Date", align: "center" },
    {
      id: "formatted_rH_RECEIVED_DATE",
      label: "Received Date",
      align: "center",
    },
    { id: "formatted_rH_NET_WEIGHT", label: "Net Weight(MT)", align: "center" },
    { id: "rH_PRODUCT_CODE", label: "Product", align: "center" },
    { id: "rH_PERSONNEL_CODE", label: "PR Code", align: "center" },
  ];

  const additionalHeaderDetails: AdditionalTableHeaderCell[] = [
    {
      id: "reorder",
      label: `Rail Car History`,
      colSpan: 7,
      align: "center",
    },
  ];

  const { Tbl, GetRowsAfterPagingAndSorting } = useTable<RailcarHistoryModel>(
    railcarHistoryDataFormatted,
    tableHeaderCellDetails,
    true,
    10,
    additionalHeaderDetails
  );

  //---------------------props
  const ITEM_HEIGHT = 46;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5,
      },
    },
  };

  const printTableHeaderCellDetails: TableHeaderCell[] = [
    { id: "rH_CAR_NUMBER", label: "Car No", align: "center" },
    { id: "origin", label: "Origin", align: "center" },
    { id: "formatted_rH_SHIPPED_DATE", label: "Ship Date", align: "center" },
    {
      id: "formatted_rH_RECEIVED_DATE",
      label: "Received Date",
      align: "center",
    },
    { id: "formatted_rH_NET_WEIGHT", label: "Net Weight(MT)", align: "center" },
    { id: "rH_PRODUCT_CODE", label: "Product", align: "center" },
    { id: "rH_PERSONNEL_CODE", label: "PR Code", align: "center" },
  ];

  const onModalClose = (e: React.MouseEvent<HTMLButtonElement>) => {
    Promise.resolve().then(() => {
      setRailcarHistoryPrintModalPopup(false);
    });
  };

  function onPrintClick() {
    setRailcarHistoryPrintModalPopup(true);
  }

  const onExcelClick = () => {
    GenerateCSVfile(railcarHistoryDataFormatted);
  };

  const GenerateCSVfile = (data: RailcarHistoryModel[]) => {
    const allData: any = [];
    const Header = {
      "Car No": "",
      Origin: "",
      "Ship Date": "",
      "Received Date": "",
      "Net Weight (MT)": "",
      Product: "",
      "PR Code": "",
    };
    allData.push(Header);

    for (let i = 0; i < data.length; i++) {
      let intransitShipmentData = {
        "Car No": data[i].rH_CAR_NUMBER,
        Origin: data[i].origin.replaceAll(",", "-"),
        "Ship Date": data[i].formatted_rH_SHIPPED_DATE,
        "Received Date": data[i].formatted_rH_RECEIVED_DATE,
        "Net Weight (MT)": data[i].formatted_rH_NET_WEIGHT,
        Product: data[i].rH_PRODUCT_CODE,
        "PR Code": data[i].rH_PERSONNEL_CODE,
      };
      allData.push(intransitShipmentData);
    }
    GenerateCSV("OISRailcarHistory", allData, ",,,Railcar History\n\n");
  };

  const handleSubmit = () => {
    checkRequiredFields();
  };

  function checkRequiredFields() {
    if (
      filterBandState.selectedLocationNo === "" ||
      filterBandState.selectedProductID === "" ||
      filterBandState.selectedProductID === "ALL"
    ) {
      dispatch(
        uiActions.displayNotification({
          message: "Select Location and Product to view railcar history",
          severity: "error",
        })
      );
      return;
    }
    if (invalidDate) {
      dispatch(
        uiActions.displayNotification({
          message: "Please enter a valid Date",
          severity: "error",
        })
      );
      return;
    }

    if (
      fromDate > toDate ||
      Number(
        (
          (dayjs(toDate).toDate().getTime() -
            dayjs(fromDate).toDate().getTime()) /
          (1000 * 3600 * 24)
        ).toFixed(0)
      ) > 90
    ) {
      dispatch(
        uiActions.displayNotification({
          message: "Your date range is invalid or greater than 90 days.",
          severity: "error",
        })
      );
      return;
    }
    loadRailcarHistory();
  }

  return (
    <React.Fragment>
      <PageHeader
        title="Location Railcar History"
        subtitle="Select a product to generate the report. Below are the most recent railcar deliveries to this location for the last 90 days. Select a date range to refine the report.  The received date must be within the last 90 days."
      ></PageHeader>
      <Card
        sx={{ width: "100hv", borderRadius: 3, margin: 2, mt: 0 }}
        elevation={6}
      >
        <CardContent sx={{ pb: 0 }}>
          <Grid container columnSpacing={{ xs: 1, sm: 1, md: 4, lg: 4 }}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Grid container columnSpacing={{ xs: 1, sm: 1, md: 4, lg: 4 }}>
                <Grid item xs={12} sm={4} md={3} lg={3}>
                  <TextField
                    id="Product"
                    name="Product"
                    select
                    label="Product"
                    onChange={handleProductChange}
                    fullWidth
                    inputProps={{ MenuProps }}
                    value={
                      filterBandState.selectedProductID !== ""
                        ? filterBandState.selectedProductID
                        : "ALL"
                    }
                  >
                    <MenuItem value="ALL">Please Select a Product</MenuItem>
                    {productList.map((row, index) => {
                      return (
                        row.vpL_RAIL_USER === true && (
                          <MenuItem
                            key={index + row.vP_PRODUCT_ID}
                            value={row.vP_PRODUCT_CODE}
                          >
                            {row.vP_PRODUCT_DESC}
                          </MenuItem>
                        )
                      );
                    })}
                  </TextField>
                </Grid>
                <Grid item xs={5} sm={3} md={2} lg={2}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      label="From"
                      value={fromDate}
                      onChange={(newValue) => {
                        if (newValue !== null && newValue.isValid()) {
                          setFromDate(newValue);
                          setInvalidDate(false);
                        } else {
                          setInvalidDate(true);
                        }
                      }}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={5} sm={3} md={2} lg={2}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      label="To"
                      value={toDate}
                      onChange={(newValue) => {
                        if (newValue !== null && newValue.isValid()) {
                          setToDate(newValue);
                          setInvalidDate(false);
                        } else {
                          setInvalidDate(true);
                        }
                      }}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={2} sm={2} md={2} lg={2}>
                  <Button
                    size="small"
                    onClick={handleSubmit}
                    style={{ marginTop: "10px" }}
                  >
                    Submit
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <Card sx={{ width: "100hv", borderRadius: 3, margin: 1 }} elevation={6}>
        <CardContent>
          <CustomCardHeader
            title=""
            action={
              <div>
                <Button
                  onClick={onPrintClick}
                  startIcon={<PrintIcon />}
                  sx={{ mr: 2 }}
                >
                  Print
                </Button>
                <Button onClick={onExcelClick} startIcon={<DownloadIcon />}>
                  Export
                </Button>
              </div>
            }
          ></CustomCardHeader>
          <Tbl>
            <TableBody>
              {GetRowsAfterPagingAndSorting().map((row, index) => (
                <StyledTableRow key={index}>
                  <StyledTableCell scope="row" sx={{ textAlign: "center" }}>
                    {row.rH_CAR_NUMBER !== undefined &&
                    row.rH_CAR_NUMBER !== null
                      ? row.rH_CAR_NUMBER.trim()
                      : row.rH_CAR_NUMBER}
                  </StyledTableCell>
                  <StyledTableCell scope="row" sx={{ textAlign: "center" }}>
                    {row.origin !== undefined && row.origin !== null
                      ? row.origin.trim()
                      : row.origin}
                  </StyledTableCell>
                  <StyledTableCell scope="row" sx={{ textAlign: "center" }}>
                    {row.formatted_rH_SHIPPED_DATE !== undefined &&
                    row.formatted_rH_SHIPPED_DATE !== null
                      ? row.formatted_rH_SHIPPED_DATE.trim()
                      : row.formatted_rH_SHIPPED_DATE}
                  </StyledTableCell>
                  <StyledTableCell scope="row" sx={{ textAlign: "center" }}>
                    {row.formatted_rH_RECEIVED_DATE !== undefined &&
                    row.formatted_rH_RECEIVED_DATE !== null
                      ? row.formatted_rH_RECEIVED_DATE.trim()
                      : row.formatted_rH_RECEIVED_DATE}
                  </StyledTableCell>
                  <StyledTableCell scope="row" sx={{ textAlign: "center" }}>
                    {row.formatted_rH_NET_WEIGHT}
                  </StyledTableCell>
                  <StyledTableCell scope="row" sx={{ textAlign: "center" }}>
                    {row.rH_PRODUCT_CODE !== undefined &&
                    row.rH_PRODUCT_CODE !== null
                      ? row.rH_PRODUCT_CODE.trim()
                      : row.rH_PRODUCT_CODE}
                  </StyledTableCell>
                  <StyledTableCell scope="row" sx={{ textAlign: "center" }}>
                    {row.rH_PERSONNEL_CODE !== undefined &&
                    row.rH_PERSONNEL_CODE !== null
                      ? row.rH_PERSONNEL_CODE.trim()
                      : row.rH_PERSONNEL_CODE}
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Tbl>
        </CardContent>
      </Card>
      <ModalPopup
        open={railcarHistoryPrintModalPopup}
        title={"Railcar History"}
        onPopupClose={onModalClose}
        width="1500px"
      >
        <RailcarHistoryPrint
          railcarHistory={railcarHistoryDataFormatted}
          tableHeader={printTableHeaderCellDetails}
        ></RailcarHistoryPrint>
      </ModalPopup>
    </React.Fragment>
  );
}

export default RailcarHistory;
