import * as React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import {
  Link as RouterLink
  ,LinkProps as RouterLinkProps
  //,Route
  //,MemoryRouter
  //,useLocation
  //,Link
} from "react-router-dom"
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { Collapse} from '@mui/material';

interface ListItemLinkProps {
  icon?: React.ReactElement;
  primary: string;
  to: string;
  nestedLinks?:{ name: string; link: string; activeIndex: number; icon:  React.ReactElement; expand: boolean; }[];
  nestedEl?: React.ReactElement;
  expand: boolean;
  clickHandler?: (e:  React.MouseEvent<HTMLAnchorElement>) => void;
  children?:never[]
}



function ListItemLink(props: ListItemLinkProps) {
  const { icon, primary, to, nestedLinks,nestedEl, expand,clickHandler } = props;

  const renderLink = React.useMemo(
    () =>
      React.forwardRef<HTMLAnchorElement, Omit<RouterLinkProps, 'to'>>(function Link(
        itemProps,
        ref,
      ) {
        return <RouterLink to={to !=="" ? to:"#"} ref={ref} {...itemProps} role={undefined} />
      }),
    [to],
  );


  return (
    <li>
      <ListItem button component={renderLink} onClick={clickHandler}>
        {icon ? <ListItemIcon sx={{minWidth:40}}>{icon}</ListItemIcon> : null}    
        <ListItemText primary={primary} />
        {expand && nestedLinks ? <ExpandLess /> : null}
        {!expand && nestedLinks ? <ExpandMore /> : null}
      </ListItem>
      {nestedLinks &&
          <Collapse in={expand} timeout="auto" unmountOnExit sx={{ pl: 3 }}>
             <List component="div" disablePadding>
               {nestedEl}
             </List>
          </Collapse>
        }
    </li >
  );
}


// function ListItemLinkRouter(props: { children?: React.ReactNode }) {
//     const { children } = props;
  
//     return (
//       <MemoryRouter initialEntries={['/drafts']} initialIndex={0}>
//         {children}
//       </MemoryRouter>
//     );
//   }
  

export default ListItemLink;