import TankList from "../components/app/Tank/TankList";
import SystemMessage from "./SystemMessage";

function TankMaintenancePage(){
  const role=sessionStorage.getItem("roles");
  return(
    role?.toString().toUpperCase()==="ADMIN" ?
  <TankList></TankList> : <SystemMessage msg="Access Denied." colorMsg="Red"></SystemMessage>
  );
}

export default TankMaintenancePage