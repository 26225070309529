import React from "react";
import { useEffect, useState } from "react";
import { Button } from "@mui/material";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";

function BackToTop() {
  const [backButton, setBackButton] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 300) {
        setBackButton(true);
      } else {
        setBackButton(false);
      }
    });
  }, []);

  const scrollUp = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <div>
      {backButton && (
        <Button
          onClick={scrollUp}
          style={{
            position: "fixed",
            bottom: "50px",
            right: "50px",
            backgroundColor: "gray",
          }}
        >
          <ArrowUpwardIcon />
        </Button>
      )}
    </div>
  );
}

export default BackToTop;
