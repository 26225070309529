/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import useTable from "../../../hooks/useTable";
import { useSelector } from "react-redux";

import { Button, Card, CardContent, IconButton } from "@mui/material";
import ModeEditSharpIcon from "@mui/icons-material/ModeEditSharp";
import TableBody from "@mui/material/TableBody";
import AddSharpIcon from "@mui/icons-material/AddSharp";
import DeleteSharpIcon from "@mui/icons-material/DeleteSharp";

import { LocationInfo } from "../../../models/location";
import { TableHeaderCell } from "../../../models/common";

import { StyledTableCell, StyledTableRow } from "../../controls/tablecontrols";

import PageHeader from "../../shared/PageHeader";
import CustomCardHeader from "../../shared/CustomCardHeader";
import ModalPopup from "../../shared/ModalPopup";
import ConfirmationMessage from "../../shared/ConfirmationMessage";

import { IFilterBandState } from "../../../interfaces/storeInterfaces";
import { filterBandSelector, useAppDispatch } from "../../../store/store";
import { filterBandActions } from "../../../store/filterband-slice";
import { uiActions } from "../../../store/ui-slice";
import { useLocation } from "react-router-dom";

import LocationMaintenance from "./LocationMaintenance";
import LocationDataService from "../../../services/locationService";
import CustomerDataService from "../../../services/customerService";
import AddEditTank from "../Tank/AddEditTank";
import AddEditUnitInformation from "./AddEditUnitInformation";

function LocationList() {
  const filterBandState: IFilterBandState = useSelector(filterBandSelector);
  const [locationList, setLocationList] = useState<LocationInfo[]>([]);
  const [locationNo, setLocationNo] = useState("");
  const [showModalPopup, setShowModalPopup] = useState<boolean>(false);
  const [openConfirmation, setOpenConfirmation] =
    React.useState<boolean>(false);
  const [deleteLocationNo, setDeleteLocationNo] = useState<string>("");
  const [isConfirmDelete, setIsConfirmDelete] = useState<boolean>(false);
  const [locationName, setDeleteLocationName] = useState<string>("");
  const [isRefreshList, setRefreshList] = React.useState<boolean>(false);
  const [tankID, setTankID] = useState<number>(0);
  const [showTankModalPopup, setTankShowModalPopup] = useState<boolean>(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [tankRefreshList, setTankRefreshList] = useState<boolean>(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [productName, setProductName] = useState<string>("");
  const dispatch = useAppDispatch();
  const location = useLocation();

  const [showUnitModalPopup, setUnitShowModalPopup] = useState<boolean>(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isUnitRefreshList, setUnitRefreshList] = useState<boolean>(false);

  const tableHeaderCellDetails: TableHeaderCell[] = [
    { id: "siteNumber", label: "Site #" },
    { id: "locationName", label: "Location Name" },
    { id: "edit", label: "Edit", disableSorting: true },
    { id: "delete", label: "Delete", disableSorting: true },
    { id: "addTank", label: "Add Tank", disableSorting: true },
    { id: "addUnit", label: "Add Unit", disableSorting: true },
  ];

  const { Tbl, GetRowsAfterPagingAndSorting } = useTable<LocationInfo>(
    locationList,
    tableHeaderCellDetails,
    true,
    10
  );

  const { fetchData: fetchLocationData } =
  CustomerDataService.GetCustomerLocations(
      filterBandState.selectedCustomerNo
    );
  const { deleteData: deleteLocation } = LocationDataService.DeleteLocation(
    filterBandState.selectedCustomerNo,
    deleteLocationNo
  );

  useEffect(() => {
    getLocations();
  }, [isRefreshList, filterBandState.locations]);

  useEffect(() => {
    if (isConfirmDelete && deleteLocationNo !== "") {
      dispatch(uiActions.startProgressBar());
      deleteLocation().then((res) => {
        if (res.isSuccess) {
          dispatch(
            uiActions.displayNotification({
              message: "Location deleted successfully!",
              severity: "success",
            })
          );
          if (filterBandState.selectedLocationNo === deleteLocationNo) {
            dispatch(filterBandActions.removeSelectedLocation());
          }
          setRefreshList(true);
        }
      });

      Promise.resolve().then(() => {
        setIsConfirmDelete(false);
        setDeleteLocationNo("");
        setDeleteLocationName("");
      });
    }
  }, [isConfirmDelete]);

  useEffect(() => {
    if (location.state !== null && location.state !== undefined) {
      if (
        location.state.editModal === true &&
        location.state.editlocationNo !== ""
      ) {
        setLocationNo(location.state.editlocationNo);
        setShowModalPopup(true);
      } else {
        setLocationNo("");
        setShowModalPopup(true);
      }
      window.history.replaceState({}, document.title);
    }
  }, [location]);

  const getLocations = () => {
    if (isRefreshList) {
      fetchLocationData().then((res) => {
        dispatch(filterBandActions.setLocationList(res.responseData));
        setRefreshList(false);
      });
    }
    setLocationList(filterBandState.locations);
  };

  const onAddNewClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    Promise.resolve().then(() => {
      setLocationNo("");
      setShowModalPopup(true);
    });
  };

  const handleEdit = (locationNo: string) => {
    Promise.resolve().then(() => {
      setLocationNo(locationNo);
      setShowModalPopup(true);
    });
  };

  const handleOpenDeleteConfirmation = (
    locationNo: string,
    locationName: string
  ) => {
    Promise.resolve().then(() => {
      setOpenConfirmation(true);
      setDeleteLocationNo(locationNo);
      setDeleteLocationName(locationName);
    });
  };

  const handleConfirmDelete = (e: React.MouseEvent<HTMLButtonElement>) => {
    Promise.resolve().then(() => {
      setOpenConfirmation(false);
      setIsConfirmDelete(true);
    });
  };

  const handleCancelDelete = (e: React.MouseEvent<HTMLButtonElement>) => {
    Promise.resolve().then(() => {
      setOpenConfirmation(false);
      setDeleteLocationNo("");
    });
  };

  const onModalClose = (e: React.MouseEvent<HTMLButtonElement>) => {
    Promise.resolve().then(() => {
      setLocationNo("");
      setShowModalPopup(false);
    });
  };

  const onTankModalClose = (e: React.MouseEvent<HTMLButtonElement>) => {
    Promise.resolve().then(() => {
      setTankID(0);
      setLocationNo("");
      setTankShowModalPopup(false);
    });
  };

  const onAddTankClick = (locationNo: string) => {
    if (locationNo !== "" && locationNo !== undefined && locationNo !== null) {
      setLocationNo(locationNo);
      setTankID(0);
      setTankShowModalPopup(true);
      setTankRefreshList(false);
    } else {
      dispatch(
        uiActions.displayNotification({
          message: "Location not selected",
          severity: "error",
        })
      );
    }
  };

  const onUnitModalClose = (e: React.MouseEvent<HTMLButtonElement>) => {
    Promise.resolve().then(() => {
      setUnitShowModalPopup(false);
      setLocationNo("");
    });
  };

  const onAddUnitClick = (locationNo: string) => {
    if (locationNo !== "" && locationNo !== undefined && locationNo !== null) {
      setLocationNo(locationNo);
      setUnitShowModalPopup(true);
    } else {
      dispatch(
        uiActions.displayNotification({
          message: "Location not selected",
          severity: "error",
        })
      );
    }
  };

  return (
    <div>
      <PageHeader
        title="Location Maintenance"
        subtitle="To edit an existing location information, click on the edit link from the list below."
      ></PageHeader>
      {
        <Card
          sx={{ width: "100hv", borderRadius: 3, margin: 2, mt: 0 }}
          elevation={6}
        >
          <CustomCardHeader
            title="Location List"
            action={
              filterBandState.selectedCustomerNo !== "" && (
                <Button onClick={onAddNewClick} startIcon={<AddSharpIcon />}>
                  Add New
                </Button>
              )
            }
          ></CustomCardHeader>
          <CardContent sx={{ pb: 0 }}>
            <Tbl>
              <TableBody>
                {GetRowsAfterPagingAndSorting().map((row, index) => (
                  <StyledTableRow key={index}>
                    <StyledTableCell scope="row">
                      {row.siteNumber}
                    </StyledTableCell>
                    <StyledTableCell scope="row">
                      {row.locationName}
                    </StyledTableCell>
                    <StyledTableCell scope="row">
                      <IconButton
                        aria-label="Edit"
                        size="small"
                        sx={{ paddingLeft: 1, paddingRight: 1 }}
                        onClick={(e) => handleEdit(row.locationNo)}
                      >
                        <ModeEditSharpIcon fontSize="small" />
                      </IconButton>
                    </StyledTableCell>
                    <StyledTableCell scope="row">
                      <IconButton
                        aria-label="Delete"
                        size="small"
                        sx={{ paddingLeft: 1, paddingRight: 1 }}
                        onClick={(e) =>
                          handleOpenDeleteConfirmation(
                            row.locationNo,
                            row.locationName.trim()
                          )
                        }
                      >
                        <DeleteSharpIcon fontSize="small" />
                      </IconButton>
                      {/* </Tooltip> */}
                    </StyledTableCell>
                    <StyledTableCell scope="row">
                      <Button
                        variant="text"
                        sx={{ fontSize: "0.90rem" }}
                        onClick={(e) => onAddTankClick(row.locationNo)}
                      >
                        Add Tank
                      </Button>
                    </StyledTableCell>
                    <StyledTableCell scope="row">
                      <Button
                        variant="text"
                        sx={{ fontSize: "0.90rem" }}
                        onClick={(e) => onAddUnitClick(row.locationNo)}
                      >
                        Add Unit
                      </Button>
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Tbl>
          </CardContent>
        </Card>
      }
      <ModalPopup
        open={showModalPopup}
        title={locationNo !== "" ? "Edit Location" : "Add Location"}
        onPopupClose={onModalClose}
        width="800px"
      >
        <LocationMaintenance
          setShowModalPopup={setShowModalPopup}
          locationNo={locationNo}
          setRefreshList={setRefreshList}
        />
      </ModalPopup>
      <ConfirmationMessage
        open={openConfirmation}
        message={
          "Do you really want to delete the Location- " +
          '"' +
          locationName +
          '"' +
          " ? This process cannot be undone."
        }
        onYesClick={handleConfirmDelete}
        onNoClick={handleCancelDelete}
      ></ConfirmationMessage>
      <ModalPopup
        open={showTankModalPopup}
        title={"Tank Specifications"}
        onPopupClose={onTankModalClose}
        width="1200px"
      >
        <AddEditTank
          setShowModalPopup={setTankShowModalPopup}
          tankID={tankID}
          locationNo={locationNo}
          setRefreshList={setTankRefreshList}
          setProductName={setProductName}
        />
      </ModalPopup>
      <ModalPopup
        open={showUnitModalPopup}
        title={"Add New Unit"}
        onPopupClose={onUnitModalClose}
      >
        <AddEditUnitInformation
          setUnitShowModalPopup={setUnitShowModalPopup}
          customerNo={filterBandState.selectedCustomerNo}
          locationNo={locationNo}
          unitId={""}
          setUnitRefreshList={setUnitRefreshList}
        />
      </ModalPopup>
    </div>
  );
}

export default LocationList;
