/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Table } from "@mui/material";
import TableBody from "@mui/material/TableBody";
import { Card, CardContent } from "@mui/material";
import { StyledTableCell, StyledTableRow } from "../../controls/tablecontrols";
import { TankInformation } from "../../../models/tank";
import CustomCardHeader from "../../shared/CustomCardHeader";
import React from "react";
import moment from "moment";
import { FormatNumber } from "../../shared/CommonFunctions";
import { IFilterBandState } from "../../../interfaces/storeInterfaces";
import { useDispatch, useSelector } from "react-redux";
import { filterBandSelector } from "../../../store/store";
import { useNavigate } from "react-router-dom";
import { uiActions } from "../../../store/ui-slice";

interface Props {
  tankInfo: TankInformation;
}

function InventoryInformation({ tankInfo }: Props) {
  const role = sessionStorage.getItem("roles");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const filterBandState: IFilterBandState = useSelector(filterBandSelector);

  const noRec = (
    <CardContent>
      <Table>
        <TableBody>
          <StyledTableRow>
            <StyledTableCell>No records found</StyledTableCell>
          </StyledTableRow>
        </TableBody>
      </Table>
    </CardContent>
  );

  const onEditClick = (tankNumber: number) => {
    const tankID = filterBandState.tanks.filter(
      (row) => row.vT_TANK_NO === tankNumber
    )[0].vT_TANK_ID;
    if (
      String(tankInfo.vT_TANK_NO) !== "" &&
      tankInfo.vT_TANK_NO !== undefined &&
      tankInfo.vT_TANK_NO !== null
    ) {
      navigate("/tanks", {
        state: { editTankID: tankID, editModal: true },
      });
    } else {
      dispatch(
        uiActions.displayNotification({
          message: "Tank not selected",
          severity: "error",
        })
      );
    }
  };

  return (
    <React.Fragment>
      {
        <Card
          sx={{ width: "100hv", borderRadius: 3, margin: 2, mt: 0 }}
          elevation={6}
        >
          <CustomCardHeader
            title="Sensor Information"
            action={
              role?.toString().toUpperCase() === "ADMIN" ? (
                <Button
                  variant="text"
                  sx={{ fontSize: "0.90rem" }}
                  onClick={(e) => onEditClick(tankInfo.vT_TANK_NO)}
                >
                  <u>Edit</u>
                </Button>
              ) : (
                ""
              )
            }
          ></CustomCardHeader>
          {tankInfo.vT_TANK_NAME != null && tankInfo.vT_TANK_NAME !== "" ? (
            <CardContent>
              <Table>
                <TableBody>
                  <StyledTableRow>
                    <StyledTableCell style={{ fontWeight: "bold" }}>
                      Probe Manufacture:
                    </StyledTableCell>
                    <StyledTableCell>{tankInfo.vT_PROBE_MFG}</StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow>
                    <StyledTableCell style={{ fontWeight: "bold" }}>
                      Probe Model:
                    </StyledTableCell>
                    <StyledTableCell>{tankInfo.vT_PROBE_MODEL}</StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow>
                    <StyledTableCell style={{ fontWeight: "bold" }}>
                      4ma Setting(ft):
                    </StyledTableCell>
                    <StyledTableCell>
                      {FormatNumber(tankInfo.vT_PROBE_4MA_SET, 3)}
                    </StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow>
                    <StyledTableCell style={{ fontWeight: "bold" }}>
                      20ma Setting(ft):
                    </StyledTableCell>
                    <StyledTableCell>
                      {FormatNumber(tankInfo.vT_PROBE_20MA_SET, 3)}
                    </StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow>
                    <StyledTableCell style={{ fontWeight: "bold" }}>
                      Blanking:
                    </StyledTableCell>
                    <StyledTableCell>
                      {FormatNumber(tankInfo.vT_PROBE_BLANKING, 3)}
                    </StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow>
                    <StyledTableCell style={{ fontWeight: "bold" }}>
                      Indicator Span Low(ft):
                    </StyledTableCell>
                    <StyledTableCell>
                      {FormatNumber(tankInfo.vT_PROBE_IND_SPAN_LOW / 12, 3) +
                        "  " +
                        "(" +
                        (FormatNumber(tankInfo.vT_PROBE_IND_SPAN_LOW, 1) +
                          " In" +
                          ")")}
                    </StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow>
                    <StyledTableCell style={{ fontWeight: "bold" }}>
                      Indicator Span High(ft):
                    </StyledTableCell>
                    <StyledTableCell>
                      {FormatNumber(tankInfo.vT_PROBE_IND_SPAN_HIGH / 12, 3) +
                        "  " +
                        "(" +
                        (FormatNumber(tankInfo.vT_PROBE_IND_SPAN_HIGH, 1) +
                          " In" +
                          ")")}
                    </StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow>
                    <StyledTableCell style={{ fontWeight: "bold" }}>
                      Calibration Date:
                    </StyledTableCell>
                    <StyledTableCell>
                      {tankInfo.vT_PROBE_CAL_DATE !== null &&
                      tankInfo.vT_PROBE_CAL_DATE !== ""
                        ? moment(tankInfo.vT_PROBE_CAL_DATE).format(
                            "MM/DD/YYYY"
                          )
                        : ""}
                    </StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow>
                    <StyledTableCell style={{ fontWeight: "bold" }}>
                      Install Date:
                    </StyledTableCell>
                    <StyledTableCell>
                      {tankInfo.vT_PROBE_INST_DATE !== null &&
                      tankInfo.vT_PROBE_INST_DATE !== ""
                        ? moment(tankInfo.vT_PROBE_INST_DATE).format(
                            "MM/DD/YYYY"
                          )
                        : ""}
                    </StyledTableCell>
                  </StyledTableRow>
                </TableBody>
              </Table>
            </CardContent>
          ) : (
            noRec
          )}
        </Card>
      }
    </React.Fragment>
  );
}

export default InventoryInformation;
