import UserAdmin from "../components/app/User/UserAdmin";
import SystemMessage from "./SystemMessage";

function UserAdminPage() {
  const role = sessionStorage.getItem("roles");
  return role?.toString().toUpperCase() === "ADMIN" ? (
    <UserAdmin></UserAdmin>
  ) : (
    <SystemMessage msg="Access Denied." colorMsg="Red"></SystemMessage>
  );
}

export default UserAdminPage;
