export const environment = {
  apiBaseUrl: process.env.REACT_APP_OIS_API_ENDPOINT_BASE_URL || "",
  apiVersion: process.env.REACT_APP_OIS_API_VERSION || "",

  REACT_APP_B2C_CLIENT_ID: process.env.REACT_APP_B2C_CLIENT_ID || "",
  REACT_APP_B2C_REDIRECT_URI: process.env.REACT_APP_B2C_REDIRECT_URI || "",
  REACT_APP_OIS_AUTH_AUTHORITY: process.env.REACT_APP_OIS_AUTH_AUTHORITY || "",
  REACT_APP_OIS_RESET_AUTHORITY:
    process.env.REACT_APP_OIS_RESET_AUTHORITY || "",
  REACT_APP_B2C_SCOPE: process.env.REACT_APP_B2C_SCOPE || "",
  REACT_APP_APIM_SUB_KEY: process.env.REACT_APP_APIM_SUB_KEY || "",
};
