import { CardHeader, CardHeaderProps, Divider } from "@mui/material";
import React from "react";

function CustomCardHeader(props: CardHeaderProps) {
  return (<React.Fragment>
              <CardHeader sx={{ pb: 0, pr:3, mb: 1 , ...(props.action && {pt: 1}), ...(!props.action && {pt: 2}) }} titleTypographyProps={{ variant: 'h4' }} {...props}>
                  {props.children}
              </CardHeader>
              <Divider></Divider>
          </React.Fragment>
  );
}


export default CustomCardHeader;