/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import PageHeader from "../../shared/PageHeader";
import {
  Button,
  Card,
  CardContent,
  Checkbox,
  Grid,
  Link,
  MenuItem,
  TableBody,
  TextField,
  Tooltip,
} from "@mui/material";
import CustomCardHeader from "../../shared/CustomCardHeader";
import { IFilterBandState } from "../../../interfaces/storeInterfaces";
import { useSelector } from "react-redux";
import { filterBandSelector, useAppDispatch } from "../../../store/store";
import CustomerDataService from "../../../services/customerService";
import LocationDataService from "../../../services/locationService";
import ShipmentDataService from "../../../services/shipmentService";
import {
  IntransitCars,
  ProductCode,
  TurnedCars,
} from "../../../models/shipment";
import {
  ProductInventories,
  ProductInventory,
  ProductShipment,
} from "../../../models/product";
import {
  AdditionalTableHeaderCell,
  TableHeaderCell,
} from "../../../models/common";
import useTable from "../../../hooks/useTable";
import { StyledTableCell, StyledTableRow } from "../../controls/tablecontrols";
import {
  FormatInvResult,
  FormatNumber,
  base64ToArrayBuffer,
} from "../../shared/CommonFunctions";
import moment from "moment";
import React from "react";
import PrintIcon from "@mui/icons-material/Print";
import DownloadIcon from "@mui/icons-material/Download";
import GenerateCSV from "../../shared/GenerateCSV";
import ModalPopup from "../../shared/ModalPopup";
import IntransitSchedulePrint from "./IntransitSchedulePrint";
import { filterBandActions } from "../../../store/filterband-slice";
import { Link as RouterLink } from "react-router-dom";

interface IntrasnitShipmentMaintenanceProps {}

function IntrasnitShipmentMaintenance(
  props: IntrasnitShipmentMaintenanceProps
) {
  const dispatch = useAppDispatch();
  const filterBandState: IFilterBandState = useSelector(filterBandSelector);
  const [productList, setProductList] = useState<ProductCode[]>([]);
  const [turnCarsUserData, setTurnCarsUserData] = useState<boolean>(false);
  const [currentInventories, setCurrentInventories] = useState<
    ProductInventories[]
  >([]);
  const [sortedCurrentInventories, setSortedCurrentInventories] = useState<
    ProductShipment[]
  >([]);
  const [inTransitInventories, setInTransitInventories] = useState<
    ProductInventory[]
  >([]);
  const [sortedInTransitInventories, setSortedInTransitInventories] = useState<
    ProductShipment[]
  >([]);
  const [intransitCars, setIntransitCars] = useState<IntransitCars[]>([]);
  const [intransitCarsFormatted, setIntransitCarsFormatted] = useState<
    IntransitCars[]
  >([]);
  const [intransitCarsCount, setIntransitCarsCount] = useState<string>("");
  const [carNumber, setCarNumber] = useState<string>("");
  const [fileKind, setFileKind] = useState<string>("");
  const [fileDate, setFileDate] = useState<string>("");
  const [turnedCars, setTurnedCars] = useState<TurnedCars>({
    carIDs: "",
    userID: "",
  });
  const [updateMarkCOA, setUpdateMarkCOA] = useState<TurnedCars>({
    carIDs: "",
    userID: "",
  });
  const [updateMarkNoCOA, setUpdateMarkNoCOA] = useState<TurnedCars>({
    carIDs: "",
    userID: "",
  });

  const userId = () => sessionStorage.getItem("userMailID") || "";
  const [printData, setPrintData] = useState<IntransitCars[]>([]);
  const [showIntransitScheduleModalPopup, setIntransitScheduleModalPopup] =
    useState<boolean>(false);

  const { fetchData: fetchProductData } =
    ShipmentDataService.RetrieveProductsByLocation(
      filterBandState.selectedCustomerNo,
      filterBandState.selectedLocationNo
    );

  const { fetchData: fetchCurrentInventoriesData } =
    CustomerDataService.GetProductInventories(
      filterBandState.selectedCustomerNo
    );

  const { fetchData: fetchLocationCurrentInventoriesData } =
    LocationDataService.GetProductInventoryByDate(
      filterBandState.selectedCustomerNo,
      filterBandState.selectedLocationNo
    );

  const { fetchData: fetchInTransitInventoriesData } =
    CustomerDataService.GetSumIntransitInv(
      filterBandState.selectedCustomerNo,
      filterBandState.selectedLocationNo
    );

  const { fetchData: fetchInTransitInventoriesDataByLocation } =
    CustomerDataService.RetrieveSumIntransitInvByLocation(
      filterBandState.selectedCustomerNo,
      filterBandState.selectedLocationNo
    );

  const { fetchData: fetchInTransitCarsByCustomer } =
    ShipmentDataService.RetrieveAllCarsByCustomer(
      filterBandState.selectedCustomerNo
    );

  const { fetchData: fetchInTransitCarsByLocation } =
    ShipmentDataService.RetrieveAllCarsByLocation(
      filterBandState.selectedLocationNo
    );

  const { fetchData: fetchInTransitCarsByCustomerProduct } =
    ShipmentDataService.RetrieveCarsByCustomer(
      filterBandState.selectedCustomerNo,
      filterBandState.selectedProductID
    );

  const { fetchData: fetchInTransitCarsByLocationProduct } =
    ShipmentDataService.RetrieveCarsByLocation(
      filterBandState.selectedLocationNo,
      filterBandState.selectedProductID
    );

  const { fetchData: fetchInTransitCarsByLocationTrimmed } =
    ShipmentDataService.RetrieveAllOutBCarsByLocation(
      filterBandState.selectedLocationNo
    );

  const { fetchData: fetchInTransitCarsByLocationProductTrimmed } =
    ShipmentDataService.RetrieveOutBCarsByLocation(
      filterBandState.selectedLocationNo,
      filterBandState.selectedProductID
    );

  const { fetchData: fetchTurnCarsUserData } =
    ShipmentDataService.RetrieveTurnedCarUser();

  const { fetchData: fetchCOADocument } =
    ShipmentDataService.RetrieveCOA(carNumber);

  const { fetchData: fetchRailcarDocument } =
    ShipmentDataService.RetrieveRailcarDoc(carNumber, fileKind, fileDate);

  const { putData: updateTurnedCars } =
    ShipmentDataService.UpdateTurnCars(turnedCars);

  const { putData: updateMarkedCars } =
    ShipmentDataService.UpdateMarkCarCOA(updateMarkCOA);

  const { putData: updateNotMarkedCars } =
    ShipmentDataService.UpdateMarkCarNoCOA(updateMarkNoCOA);

  useEffect(() => {
    if (
      filterBandState.selectedCustomerNo !== "" &&
      filterBandState.selectedCustomerNo !== undefined
    ) {
      loadProduct();
      turnCarsUser();
      loadTransitCarsData(filterBandState.selectedProductID);
    }
  }, [filterBandState.selectedCustomerNo]);

  const loadProduct = () => {
    fetchProductData().then((res) => {
      setProductList(res.responseData);
    });
  };

  const turnCarsUser = () => {
    fetchTurnCarsUserData().then((res) => {
      setTurnCarsUserData(res.responseData.bit === 1 ? true : false);
    });
  };

  const headerCellsCurrentInventory: TableHeaderCell[] = [
    { id: "product_desc", label: "Product Description" },
    { id: "product_code", label: "Product Code" },
    { id: "current_Inventories", label: "Current Inventory" },
  ];

  const headerCellsInTransitInventory: TableHeaderCell[] = [
    { id: "product_desc", label: "In-Transit Product" },
    { id: "product_code", label: "Product Code" },
    { id: "intransit_inv", label: "In-Transit Inventory" },
  ];

  const {
    Tbl: TblCurrent,
    GetRowsAfterPagingAndSorting: GetRowsCurrentProductInventory,
  } = useTable<ProductShipment>(
    sortedCurrentInventories,
    headerCellsCurrentInventory,
    false
  );

  const {
    Tbl: TblInTransit,
    GetRowsAfterPagingAndSorting: GetRowsInTransitProductInventory,
  } = useTable<ProductShipment>(
    sortedInTransitInventories,
    headerCellsInTransitInventory,
    false
  );

  const clearInventories = () => {
    setCurrentInventories([]);
    setInTransitInventories([]);
  };

  useEffect(() => {
    loadTransitCarsData(filterBandState.selectedProductID);
    if (filterBandState.selectedLocationNo) {
      fetchLocationCurrentInventoriesData().then((res) => {
        setCurrentInventories(res.responseData);
        filterBandState.selectedProductID !== ""
          ? sortCurrent(
              res.responseData,
              "Location",
              filterBandState.selectedProductID
            )
          : sortCurrent(res.responseData, "Location");
        if (res.isSuccess) {
          fetchInTransitInventoriesDataByLocation().then((res) => {
            setInTransitInventories(res.responseData);
            filterBandState.selectedProductID !== ""
              ? sortIntransit(
                  res.responseData,
                  filterBandState.selectedProductID
                )
              : sortIntransit(res.responseData);
          });
        } else {
          setInTransitInventories([]);
        }
      });
    } else if (filterBandState.selectedCustomerNo) {
      fetchCurrentInventoriesData().then((res) => {
        setCurrentInventories(res.responseData);
        filterBandState.selectedProductID !== ""
          ? sortCurrent(
              res.responseData,
              "Customer",
              filterBandState.selectedProductID
            )
          : sortCurrent(res.responseData, "Customer");
        if (res.isSuccess) {
          fetchInTransitInventoriesData().then((res) => {
            setInTransitInventories(res.responseData);
            filterBandState.selectedProductID !== ""
              ? sortIntransit(
                  res.responseData,
                  filterBandState.selectedProductID
                )
              : sortIntransit(res.responseData);
          });
        } else {
          setInTransitInventories([]);
        }
      });
    } else {
      clearInventories();
    }
  }, [
    filterBandState.selectedCustomerNo,
    filterBandState.selectedLocationNo,
    filterBandState.selectedProductID,
  ]);

  const sortCurrent = (data: any, dataType: string, productCode?: string) => {
    let arrayCurrent = [];
    for (let i = 0; i < data.length; i++) {
      let product: ProductShipment = {
        inventory:
          dataType === "Customer"
            ? data[i].current_Inventories
            : data[i].current_Inv,
        rail_user:
          dataType === "Customer" ? data[i].rail_user : data[i].rail_User,
        uom: data[i].uom,
        product_id:
          dataType === "Customer" ? data[i].product_id : data[i].product_ID,
        product_code:
          dataType === "Customer" ? data[i].product_code : data[i].product_Code,
        product_desc:
          dataType === "Customer" ? data[i].product_desc : data[i].product_Desc,
      };
      if (
        productCode !== undefined &&
        productCode !== null &&
        productCode !== "" &&
        productCode !== "ALL"
      ) {
        if (product.product_code === productCode) {
          arrayCurrent.push(product);
        }
      } else {
        arrayCurrent.push(product);
      }
    }
    setSortedCurrentInventories(arrayCurrent);
  };

  const sortIntransit = (data: any, productCode?: string) => {
    setSortedInTransitInventories([]);
    let arrayInTransit = [];
    for (let i = 0; i < data.length; i++) {
      let product: ProductShipment = {
        inventory: String(data[i].intransit_inv),
        rail_user: data[i].rail_user,
        uom: data[i].uom,
        product_id: data[i].product_id,
        product_code: data[i].product_code,
        product_desc: data[i].product_desc,
      };
      if (
        productCode !== undefined &&
        productCode !== null &&
        productCode !== "" &&
        productCode !== "ALL"
      ) {
        if (product.product_code === productCode) {
          arrayInTransit.push(product);
        }
      } else {
        arrayInTransit.push(product);
      }
    }
    setSortedInTransitInventories(arrayInTransit);
  };

  useEffect(() => {
    if (filterBandState.selectedProductID !== "") {
      productChange(filterBandState.selectedProductID);
    }
  }, [filterBandState.selectedProductID]);

  const handleProductChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const productCode = event.target.value;
    productChange(productCode);
  };

  const productChange = (productCode: string) => {
    dispatch(
      filterBandActions.setSelectedProduct({
        productId: productCode,
      })
    );
    sortIntransit([]);
    if (productCode !== "ALL") {
      if (filterBandState.selectedLocationNo) {
        sortCurrent(currentInventories, "Location", productCode);
      } else {
        sortCurrent(currentInventories, "Customer", productCode);
      }
      sortIntransit(inTransitInventories, productCode);
    } else {
      if (filterBandState.selectedLocationNo) {
        sortCurrent(currentInventories, "Location");
      } else {
        sortCurrent(currentInventories, "Customer");
      }
      sortIntransit(inTransitInventories);
    }
  };

  useEffect(() => {
    loadTransitCarsData(filterBandState.selectedProductID);
  }, [filterBandState.selectedProductID]);

  const loadTransitCarsData = (productCode: string) => {
    setIntransitCars([]);
    setIntransitCarsFormatted([]);
    setPrintData([]);
    setIntransitCarsCount("");
    if (
      filterBandState.selectedCustomerNo !== "" &&
      filterBandState.selectedCustomerNo !== undefined
    ) {
      if (filterBandState.selectedLocationNo) {
        if (productCode === "ALL" || productCode === "") {
          fetchInTransitCarsByLocation().then((res) => {
            setIntransitCars(res.responseData);
            setIntransitCarsCount(res.responseData.length);
          });
        } else {
          fetchInTransitCarsByLocationProduct().then((res) => {
            setIntransitCars(res.responseData);
            setIntransitCarsCount(res.responseData.length);
          });
        }
      } else {
        if (productCode === "ALL" || productCode === "") {
          fetchInTransitCarsByCustomer().then((res) => {
            setIntransitCars(res.responseData);
            setIntransitCarsCount(res.responseData.length);
          });
        } else {
          fetchInTransitCarsByCustomerProduct().then((res) => {
            setIntransitCars(res.responseData);
            setIntransitCarsCount(res.responseData.length);
          });
        }
      }
    }
  };

  const tableHeaderCellDetails: TableHeaderCell[] = [
    { id: "turn", label: "Turn", align: "center", disableSorting: true },
    { id: "rS_CAR_NUMBER", label: "Car No.", align: "center" },
    { id: "formatted_rS_SHIPPED_DATE", label: "Ship Date", align: "center" },
    { id: "origin", label: "Origin", align: "center" },
    { id: "destination", label: "Destination", align: "center" },
    { id: "clm", label: "CLM Location", align: "center" },
    { id: "formatted_CURRENT_CLM_DATE", label: "CLM Date", align: "center" },
    { id: "idlE_DAYS", label: "Idle Days", align: "center" },
    { id: "formatted_NET_WEIGHT", label: "Net Weight(MT)", align: "center" },
    { id: "vP_PRODUCT_ABBR", label: "Product", align: "center" },
    { id: "coa", label: "COA", align: "center", disableSorting: true },
    {
      id: "otherDocs",
      label: "Others Docs",
      align: "center",
      disableSorting: true,
    },
  ];

  const additionalHeaderDetails: AdditionalTableHeaderCell[] = [
    {
      id: "reorder",
      label: `In-Transit/In-Town Product - ${intransitCarsCount} cars`,
      colSpan: 12,
      align: "center",
    },
  ];

  const { Tbl, GetRowsAfterPagingAndSorting } = useTable<IntransitCars>(
    intransitCarsFormatted,
    tableHeaderCellDetails,
    true,
    10,
    additionalHeaderDetails
  );

  useEffect(() => {
    if (intransitCars.length > 0) {
      let data: IntransitCars[] = formatData(intransitCars);
      setIntransitCarsFormatted(data);
    }
  }, [intransitCars]);

  const formatData = (data: IntransitCars[]) => {
    let calculatedData: IntransitCars[] = [];
    data.forEach((element) => {
      element.formatted_rS_SHIPPED_DATE = moment(
        element.rS_SHIPPED_DATE
      ).format("MM/DD/YYYY");
      element.formatted_CURRENT_CLM_DATE =
        element.formatted_CURRENT_CLM_DATE !== null
          ? moment(element.rS_CURRENT_CLM_DATE).format("MM/DD/YYYY hh:mm:ss A")
          : element.formatted_CURRENT_CLM_DATE;
      if (Number(FormatNumber(element.rS_NET_WEIGHT)) < 120) {
        element.formatted_NET_WEIGHT = FormatNumber(element.rS_NET_WEIGHT);
      } else {
        if (element.inbounD_RAIL === true) {
          element.formatted_NET_WEIGHT = FormatNumber(
            element.rS_NET_WEIGHT / 2000
          );
        } else {
          element.formatted_NET_WEIGHT = FormatNumber(
            element.rS_NET_WEIGHT / 2204
          );
        }
      }
      element.checkbox_turn = false;
      if (
        element.rtcS_COA !== "" &&
        element.rtcS_COA !== undefined &&
        element.rtcS_COA !== null
      ) {
        if (element.rtcS_COA.trim().toUpperCase() === "Y") {
          element.checkbox_COA = true;
        } else {
          element.checkbox_COA = false;
        }
      } else {
        element.checkbox_COA = false;
      }
      calculatedData.push(element);
    });
    return calculatedData;
  };

  const onTurnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let calculatedData: IntransitCars[] = intransitCarsFormatted;
    for (let i = 0; i < calculatedData.length; i++) {
      if (calculatedData[i].rS_CAR_NUMBER === event.target.value) {
        if (calculatedData[i].checkbox_turn === false) {
          calculatedData[i].checkbox_turn = true;
        } else {
          calculatedData[i].checkbox_turn = false;
        }
        break;
      }
    }
    setIntransitCarsFormatted(calculatedData);
  };

  const onCOAChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let calculatedData: IntransitCars[] = intransitCarsFormatted;
    for (let i = 0; i < calculatedData.length; i++) {
      if (calculatedData[i].rS_CAR_NUMBER === event.target.value) {
        if (calculatedData[i].checkbox_COA === false) {
          calculatedData[i].checkbox_COA = true;
        } else {
          calculatedData[i].checkbox_COA = false;
        }
        break;
      }
    }
    setIntransitCarsFormatted(calculatedData);
  };

  //---------------------props
  const ITEM_HEIGHT = 46;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5,
      },
    },
  };

  function renderViewCOAButton(
    COA: string,
    fileType: string,
    carNumber: string
  ) {
    if (String(COA).trim() === "Y") {
      if (
        String(fileType).toUpperCase() === "DOC" ||
        String(fileType).toUpperCase() === "PDF" ||
        String(fileType).toUpperCase() === "XLS" ||
        String(fileType).toUpperCase() === "TIF" ||
        String(fileType).toUpperCase() === "BMP" ||
        String(fileType).toUpperCase() === "JPG"
      ) {
        return (
          <Link
            component="button"
            style={{ color: "Green", fontWeight: "bold" }}
            onClick={() => handleFile(carNumber, "COA")}
          >
            View
          </Link>
        );
      }
    }
  }

  function renderFileKindButton(
    fileKind: string,
    carNumber: string,
    date: string
  ) {
    return (
      <Link
        component="button"
        style={{ color: "Green", fontWeight: "bold" }}
        onClick={() => handleFile(carNumber, fileKind.trim(), date)}
      >
        {fileKind}
      </Link>
    );
  }

  const handleFile = (carNumber: string, kind: string, carDate?: string) => {
    setCarNumber(carNumber);
    setFileKind(kind);
    setFileDate(carDate || "");
  };

  useEffect(() => {
    if (fileKind !== "" && fileKind !== undefined && fileKind !== null) {
      if (fileKind === "COA") {
        fetchCOADocument().then((res) => {
          ShowCOAFile(res.responseData);
        });
      } else {
        fetchRailcarDocument().then((res) => {
          ShowCOAFile(res.responseData[0]);
        });
      }
    }
  }, [carNumber, fileKind, fileDate]);

  function ShowCOAFile(response: any) {
    if (response.coA_FILE !== "" || response.datA_FILE !== "") {
      let contentType = "";
      let fileName = "";
      switch (response.filE_TYPE.toUpperCase()) {
        case "PDF":
          contentType = "application/pdf";
          fileName = fileKind + ".pdf";
          break;
        case "DOC":
          contentType = "application/msword";
          fileName = fileKind + ".doc";
          break;
        case "XLS":
          contentType = "application/nvd-excel";
          fileName = fileKind + ".xls";
          break;
        case "TIF":
          contentType = "application/tif";
          fileName = fileKind + ".tif";
          break;
        case "JPG":
          contentType = "application/image";
          fileName = fileKind + ".jpg";
          break;
        case "BMP":
          contentType = "application/image";
          fileName = fileKind + ".bmp";
          break;
        default:
          contentType = "application/image";
          fileName = "";
      }

      var arrrayBuffer =
        fileKind === "COA"
          ? base64ToArrayBuffer(response.coA_FILE)
          : base64ToArrayBuffer(response.datA_FILE); //data is the base64 encoded string
      var blob = new Blob([arrrayBuffer], { type: contentType });
      var link = window.URL.createObjectURL(blob);
      window.open(link, fileName, "");
      return "";
    }
  }

  function onUnloadClick() {
    let tableData: IntransitCars[] = intransitCarsFormatted;
    let stringCarIDs = "";
    for (let i = 0; i < tableData.length; i++) {
      if (tableData[i].checkbox_turn === true) {
        stringCarIDs += tableData[i].rS_CAR_NUMBER.trim() + ",";
      }
    }
    let updateData: TurnedCars = { carIDs: stringCarIDs, userID: userId() };
    setTurnedCars(updateData);
  }

  function onUpdateCOAClick() {
    let tableData: IntransitCars[] = intransitCarsFormatted;
    let markCarIDs = "",
      noMarkCarIDs = "";

    //----Marked
    for (let i = 0; i < tableData.length; i++) {
      if (tableData[i].checkbox_COA === true) {
        markCarIDs += tableData[i].rS_CAR_NUMBER.trim() + ",";
      }
    }
    let markData: TurnedCars = { carIDs: markCarIDs, userID: userId() };
    setUpdateMarkCOA(markData);

    //----No Marked
    for (let i = 0; i < tableData.length; i++) {
      if (
        tableData[i].rtcS_COA.trim().toUpperCase() === "Y" &&
        tableData[i].checkbox_COA === false
      ) {
        noMarkCarIDs += tableData[i].rS_CAR_NUMBER.trim() + ",";
      }
    }
    let noMarkData: TurnedCars = { carIDs: noMarkCarIDs, userID: userId() };
    setUpdateMarkNoCOA(noMarkData);
  }

  useEffect(() => {
    if (updateMarkCOA.carIDs !== "") {
      updateMarkedCars().then((res) => {
        if (res.isSuccess) {
          loadTransitCarsData(filterBandState.selectedProductID);
        }
      });
    }
  }, [updateMarkCOA]);

  useEffect(() => {
    if (updateMarkNoCOA.carIDs !== "") {
      updateNotMarkedCars().then((res) => {
        if (res.isSuccess) {
          loadTransitCarsData(filterBandState.selectedProductID);
        }
      });
    }
  }, [updateMarkNoCOA]);

  useEffect(() => {
    if (turnedCars.carIDs !== "") {
      updateTurnedCars().then((res) => {
        if (res.isSuccess) {
          loadTransitCarsData(filterBandState.selectedProductID);
        }
      });
    }
  }, [turnedCars]);

  const printTableHeaderCellDetails: TableHeaderCell[] = [
    { id: "rS_CAR_NUMBER", label: "Car No.", align: "center" },
    { id: "formatted_rS_SHIPPED_DATE", label: "Ship Date", align: "center" },
    { id: "origin", label: "Origin", align: "center" },
    { id: "destination", label: "Destination", align: "center" },
    { id: "clm", label: "CLM Location", align: "center" },
    { id: "formatted_CURRENT_CLM_DATE", label: "CLM Date", align: "center" },
    { id: "idlE_DAYS", label: "Idle Days", align: "center" },
    { id: "formatted_NET_WEIGHT", label: "Net Weight(MT)", align: "center" },
    { id: "vP_PRODUCT_ABBR", label: "Product", align: "center" },
    { id: "coa", label: "COA", align: "center", disableSorting: true },
  ];

  function onPrintClick() {
    let data = selectData();
    setPrintData(data || []);
    setIntransitScheduleModalPopup(true);
  }

  const onModalClose = (e: React.MouseEvent<HTMLButtonElement>) => {
    Promise.resolve().then(() => {
      setIntransitScheduleModalPopup(false);
    });
  };

  const onExcelClick = () => {
    let data = selectData();
    GenerateCSVfile(data || []);
  };

  const selectData = () => {
    let locationNo = filterBandState.selectedLocationNo;
    if (locationNo !== "" && locationNo !== null && locationNo !== undefined) {
      let trimmedLocationNo = locationNo.toString().substring(0, 3);
      if (trimmedLocationNo === "000") {
        let response: IntransitCars[] = [];
        if (
          filterBandState.selectedProductID === "ALL" ||
          filterBandState.selectedProductID === ""
        ) {
          fetchInTransitCarsByLocationTrimmed().then((res) => {
            response = formatData(res.responseData);
            return response;
          });
        } else {
          fetchInTransitCarsByLocationProductTrimmed().then((res) => {
            response = formatData(res.responseData);
            return response;
          });
        }
      } else {
        return intransitCarsFormatted;
      }
    } else {
      return intransitCarsFormatted;
    }
  };

  const GenerateCSVfile = (data: IntransitCars[]) => {
    const allData: any = [];
    const Header = {
      "Car No": "",
      "Ship Date": "",
      Origin: "",
      Destination: "",
      "CLM Location": "",
      "CLM Destination": "",
      "Idle Days": "",
      "Net Weight": "",
      Product: "",
      COA: "",
    };
    allData.push(Header);

    for (let i = 0; i < data.length; i++) {
      let intransitShipmentData = {
        "Car No": data[i].rS_CAR_NUMBER,
        "Ship Date": data[i].formatted_rS_SHIPPED_DATE,
        Origin: data[i].origin,
        Destination: data[i].destination,
        "CLM Location": data[i].clm,
        "CLM Destination": moment(data[i].formatted_CURRENT_CLM_DATE).format(
          "MMM DD YYYY h:mm A"
        ),
        "Idle Days": data[i].idlE_DAYS,
        "Net Weight": data[i].formatted_NET_WEIGHT,
        Product: data[i].vP_PRODUCT_ABBR,
        COA: data[i].rtcS_COA,
      };
      allData.push(intransitShipmentData);
    }
    GenerateCSV(
      "OISInTransitSchedule",
      allData,
      ",,,,VMI In-Transit Schedule\n\n"
    );
  };

  return (
    <React.Fragment>
      <PageHeader
        title="Intransit Shipment Summary (Loadout Maintenance)"
        subtitle="Select the Location Name to view the location summary. Product Inventories shows the current inventory for all the locations summation by product."
      ></PageHeader>

      {
        <Card
          sx={{ width: "100hv", borderRadius: 3, margin: 2, mt: 0 }}
          elevation={6}
        >
          <CardContent sx={{ pb: 0 }}>
            <Grid container columnSpacing={{ xs: 1, sm: 1, md: 4, lg: 4 }}>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Grid container>
                  <Grid item xs={2} sm={2} md={4} lg={4}></Grid>
                  <Grid item xs={8} sm={8} md={4} lg={4}>
                    <TextField
                      id="Product"
                      name="Product"
                      select
                      label="Product"
                      onChange={handleProductChange}
                      fullWidth
                      inputProps={{ MenuProps }}
                      value={
                        filterBandState.selectedProductID !== ""
                          ? filterBandState.selectedProductID
                          : "ALL"
                      }
                    >
                      <MenuItem value="ALL">Please Select a Product</MenuItem>
                      {productList.map((row, index) => {
                        return (
                          row.vpL_RAIL_USER === true && (
                            <MenuItem
                              key={index + row.vP_PRODUCT_ID}
                              value={row.vP_PRODUCT_CODE}
                            >
                              {row.vP_PRODUCT_DESC}
                            </MenuItem>
                          )
                        );
                      })}
                    </TextField>
                  </Grid>
                  <Grid item xs={2} sm={2} md={4} lg={4}></Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Card
                  sx={{ width: "100hv", borderRadius: 3, margin: 1 }}
                  elevation={6}
                >
                  <CustomCardHeader title="Product Inventories"></CustomCardHeader>
                  <CardContent>
                    <Grid
                      container
                      columnSpacing={{ xs: 1, sm: 1, md: 4, lg: 4 }}
                    >
                      <Grid item xs={12} sm={12} md={6} lg={6}>
                        <TblCurrent>
                          {sortedCurrentInventories.length > 0 ? (
                            <TableBody>
                              {GetRowsCurrentProductInventory().map(
                                (row, index) =>
                                  row.rail_user === true && (
                                    <StyledTableRow
                                      key={"Current" + index + row.product_code}
                                    >
                                      <StyledTableCell>
                                        {row.product_desc}
                                      </StyledTableCell>
                                      <StyledTableCell>
                                        {row.product_code}
                                      </StyledTableCell>
                                      <StyledTableCell>
                                        {FormatInvResult(
                                          parseFloat(row.inventory),
                                          row.uom
                                        )}
                                      </StyledTableCell>
                                    </StyledTableRow>
                                  )
                              )}
                            </TableBody>
                          ) : (
                            <TableBody></TableBody>
                          )}
                        </TblCurrent>
                      </Grid>
                      <Grid item xs={12} sm={12} md={6} lg={6}>
                        <TblInTransit>
                          {sortedInTransitInventories.length > 0 ? (
                            <TableBody>
                              {GetRowsInTransitProductInventory().map(
                                (row, index) =>
                                  row.rail_user === true && (
                                    <StyledTableRow
                                      key={
                                        "Intrasnit" + index + row.product_code
                                      }
                                    >
                                      <StyledTableCell>
                                        {row.product_desc}
                                      </StyledTableCell>
                                      <StyledTableCell>
                                        {row.product_code}
                                      </StyledTableCell>
                                      <StyledTableCell>
                                        {FormatInvResult(
                                          parseFloat(row.inventory),
                                          row.uom
                                        )}
                                      </StyledTableCell>
                                    </StyledTableRow>
                                  )
                              )}
                            </TableBody>
                          ) : (
                            <TableBody></TableBody>
                          )}
                        </TblInTransit>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Card
                  sx={{ width: "100hv", borderRadius: 3, margin: 1 }}
                  elevation={6}
                >
                  <Grid
                    container
                    columnSpacing={{ xs: 1, sm: 1, md: 4, lg: 4 }}
                    sx={{ mt: 1 }}
                  >
                    <Grid item xs={4} sm={4} md={4} lg={4}></Grid>
                    <Grid
                      item
                      xs={4}
                      sm={4}
                      md={4}
                      lg={4}
                      sx={{ textAlign: "center" }}
                    >
                      <RouterLink
                        to={"/intransitTurnedCars"}
                        style={{ color: "Green", fontWeight: "bold" }}
                      >
                        Turned Car History
                      </RouterLink>
                    </Grid>
                    <Grid
                      item
                      xs={4}
                      sm={4}
                      md={4}
                      lg={4}
                      sx={{ textAlign: "center" }}
                    >
                      <Grid>
                        <Button
                          onClick={onPrintClick}
                          startIcon={<PrintIcon />}
                          sx={{ mr: 2 }}
                        >
                          Print
                        </Button>
                        <Button
                          onClick={onExcelClick}
                          startIcon={<DownloadIcon />}
                        >
                          Export
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                  <CardContent>
                    <Tbl>
                      <TableBody>
                        {GetRowsAfterPagingAndSorting().map((row, index) => (
                          <StyledTableRow key={index}>
                            <StyledTableCell
                              scope="row"
                              sx={{ textAlign: "center" }}
                            >
                              {turnCarsUserData && (
                                <Checkbox
                                  size="small"
                                  value={row.rS_CAR_NUMBER}
                                  defaultChecked={row.checkbox_turn}
                                  onChange={onTurnChange}
                                />
                              )}
                            </StyledTableCell>
                            <StyledTableCell
                              scope="row"
                              sx={{ textAlign: "center" }}
                            >
                              {row.rS_CAR_NUMBER !== undefined &&
                              row.rS_CAR_NUMBER !== null
                                ? row.rS_CAR_NUMBER.trim()
                                : row.rS_CAR_NUMBER}
                            </StyledTableCell>
                            <StyledTableCell
                              scope="row"
                              sx={{ textAlign: "center" }}
                            >
                              {row.formatted_rS_SHIPPED_DATE}
                            </StyledTableCell>
                            <StyledTableCell
                              scope="row"
                              sx={{ textAlign: "center" }}
                            >
                              {row.origin !== undefined && row.origin !== null
                                ? row.origin.trim()
                                : row.origin}
                            </StyledTableCell>
                            <StyledTableCell
                              scope="row"
                              sx={{ textAlign: "center" }}
                            >
                              {row.destination !== undefined &&
                              row.destination !== null
                                ? row.destination.trim()
                                : row.destination}
                            </StyledTableCell>
                            <StyledTableCell
                              scope="row"
                              sx={{ textAlign: "center" }}
                            >
                              {row.clm !== undefined && row.clm !== null
                                ? row.clm.trim()
                                : row.clm}
                            </StyledTableCell>
                            <StyledTableCell
                              scope="row"
                              sx={{ textAlign: "center" }}
                            >
                              {row.formatted_CURRENT_CLM_DATE}
                            </StyledTableCell>
                            <StyledTableCell
                              scope="row"
                              sx={{ textAlign: "center" }}
                            >
                              {row.idlE_DAYS !== undefined &&
                              row.idlE_DAYS !== null
                                ? String(row.idlE_DAYS).trim()
                                : row.idlE_DAYS}
                            </StyledTableCell>
                            <StyledTableCell
                              scope="row"
                              sx={{ textAlign: "center" }}
                            >
                              {row.formatted_NET_WEIGHT}
                            </StyledTableCell>
                            <StyledTableCell
                              scope="row"
                              sx={{ textAlign: "center" }}
                            >
                              <Tooltip title={row.rS_PRODUCT_DESCRIPTION}>
                                <div>
                                  {row.vP_PRODUCT_ABBR !== undefined &&
                                  row.vP_PRODUCT_ABBR !== null
                                    ? row.vP_PRODUCT_ABBR.trim()
                                    : row.vP_PRODUCT_ABBR}
                                </div>
                              </Tooltip>
                            </StyledTableCell>
                            <StyledTableCell
                              scope="row"
                              sx={{ textAlign: "center" }}
                            >
                              <Checkbox
                                size="small"
                                value={row.rS_CAR_NUMBER}
                                defaultChecked={row.checkbox_COA}
                                onChange={onCOAChange}
                              />
                              {renderViewCOAButton(
                                row.rtcS_COA,
                                row.rtcS_FILE_TYPE,
                                row.rS_CAR_NUMBER
                              )}
                            </StyledTableCell>
                            <StyledTableCell
                              scope="row"
                              sx={{ textAlign: "center" }}
                            >
                              {renderFileKindButton(
                                row.vrD_FILE_KIND,
                                row.rS_CAR_NUMBER,
                                moment(row.maX_DATE).format("MM-DD-YYYY")
                              )}
                            </StyledTableCell>
                          </StyledTableRow>
                        ))}
                      </TableBody>
                    </Tbl>
                    <Grid
                      container
                      columnSpacing={{ xs: 1, sm: 1, md: 4, lg: 4 }}
                    >
                      {turnCarsUserData ? (
                        <Grid
                          item
                          xs={12}
                          sm={6}
                          md={6}
                          lg={6}
                          sx={{ textAlign: "right" }}
                        >
                          <Button onClick={onUnloadClick}>
                            Mark Rail Car Unloaded
                          </Button>
                        </Grid>
                      ) : (
                        <Grid
                          item
                          xs={12}
                          sm={6}
                          md={6}
                          lg={6}
                          sx={{ textAlign: "right" }}
                        ></Grid>
                      )}
                      <Grid item xs={12} sm={6} md={6} lg={6}>
                        <Button onClick={onUpdateCOAClick}>Update COA</Button>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      }
      <ModalPopup
        open={showIntransitScheduleModalPopup}
        title={"Loadout Maintenance"}
        onPopupClose={onModalClose}
        width="1500px"
      >
        <IntransitSchedulePrint
          intransitCars={printData}
          tableHeader={printTableHeaderCellDetails}
        />
      </ModalPopup>
    </React.Fragment>
  );
}

export default IntrasnitShipmentMaintenance;
