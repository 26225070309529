import React, { useEffect, useState } from "react";
import { Grid, TextField, Button, Container, Divider } from "@mui/material";
import { useFormik } from "formik";
import * as yup from "yup";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import { ProductType, Product } from "../../../models/product";
import ProductService from "../../../services/productService";
import { useAppDispatch } from "../../../store/store";
import { uiActions } from "../../../store/ui-slice";

interface AddEditProductProps {
  setShowModalPopup: React.Dispatch<React.SetStateAction<boolean>>;
  productData: any;
  setRefreshList: React.Dispatch<React.SetStateAction<boolean>>;
}

function AddEditProduct(props: AddEditProductProps) {
  const dispatch = useAppDispatch();
  const [dropDownData, setDropDownData] = useState<ProductType[]>([]);
  const [productInfo, setProductInfo] = useState<Product>();
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const { fetchData: fetchProductTypeData } =
    ProductService.RetrieveProductType();
  const { postData: createProduct } = ProductService.CreateProduct(productInfo);
  const { putData: updateProduct } = ProductService.UpdateProduct(productInfo);

  useEffect(() => {
    if (isLoaded) {
      if (props.productData.vP_PRODUCT_TYPE_ID === 0) {
        dispatch(uiActions.startProgressBar());
        createProduct().then((res) => {
          if (res.isSuccess) {
            if (res.responseData.success === "Yes") {
              dispatch(
                uiActions.displayNotification({
                  message: "The product information is saved successfully.",
                  severity: "success",
                })
              );
              props.setShowModalPopup(false);
              props.setRefreshList(true);
            }
            if (res.responseData.success === "No") {
              dispatch(
                uiActions.logErrorNotification({
                  message: "There is already a product with this product code.",
                  severity: "error",
                })
              );
            }
          }
        });
      }
      if (props.productData.vP_PRODUCT_TYPE_ID !== 0) {
        updateProduct().then((res) => {
          if (res.isSuccess) {
            dispatch(
              uiActions.displayNotification({
                message: "The product information is updated successfully.",
                severity: "success",
              })
            );
            props.setShowModalPopup(false);
            props.setRefreshList(true);
          } else {
            if (res.responseData !== null) {
              dispatch(
                uiActions.logErrorNotification({
                  message:
                    "The product information could not be updated successfully.",
                  severity: "error",
                })
              );
              props.setShowModalPopup(false);
              props.setRefreshList(true);
            }
          }
        });
      }
    } else {
      setIsLoaded(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productInfo]);

  // Form Validation
  const validationSchema = yup.object({
    vP_SPECIFIC_GRAVITY: yup
      .number()
      .typeError("Specific Gravity should be a number")
      .required("Specific Gravity is required"),
    vP_PRODUCT_CODE: yup.string().required("Product Code is required"),
    vP_UNIT_OF_MEASURE: yup.string().required("Unit of Measure is required"),
    vP_PRODUCT_TYPE_ID: yup
      .number()
      .required()
      .moreThan(0, "Product Type is required"),
  });

  const formik = useFormik({
    initialValues: ProductService.GetDefaultProduct(),
    validationSchema: validationSchema,
    onReset: (Event) => {
      formik.values = ProductService.GetDefaultProduct();
    },
    onSubmit: (values: any) => {
      setProductInfo(values);
    },
  });

  useEffect(() => {
    formik.setValues(props.productData);
    formik.setFieldValue(
      "vP_PRODUCT_CODE",
      props.productData.vP_PRODUCT_CODE.trim()
    );
    formik.setFieldValue(
      "vP_PRODUCT_DESC",
      props.productData.vP_PRODUCT_DESC.trim()
    );
    formik.setFieldValue(
      "vP_PRODUCT_GROUP",
      props.productData.vP_PRODUCT_GROUP.trim()
    );
    formik.setFieldValue(
      "vP_UNIT_OF_MEASURE",
      props.productData.vP_UNIT_OF_MEASURE.trim()
    );
    fetchProductTypeData().then((res) => {
      setDropDownData(res.responseData);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.productData]);

  return (
    <>
      <Container>
        <form id="form1" onSubmit={formik.handleSubmit} className="form">
          <Grid container spacing={3} sx={{ paddingTop: 1 }}>
            <Grid item xs={9} sm={9} md={9}>
              <TextField
                disabled={
                  props.productData.vP_PRODUCT_TYPE_ID !== 0 ? true : false
                }
                label="Product Code"
                fullWidth
                name="vP_PRODUCT_CODE"
                value={formik.values.vP_PRODUCT_CODE}
                inputProps={{ maxLength: 6 }}
                onChange={formik.handleChange}
                error={
                  formik.touched.vP_PRODUCT_CODE &&
                  Boolean(formik.errors.vP_PRODUCT_CODE)
                }
                helperText={
                  formik.touched.vP_PRODUCT_CODE &&
                  formik.errors.vP_PRODUCT_CODE
                }
              />
            </Grid>
            <Grid item xs={9} sm={9} md={9}>
              <TextField
                label="Product Description"
                fullWidth
                inputProps={{ maxLength: 50 }}
                name="vP_PRODUCT_DESC"
                value={formik.values.vP_PRODUCT_DESC}
                onChange={formik.handleChange}
              />
            </Grid>
            <Grid item xs={9} sm={9} md={9}>
              <FormControl variant="standard" sx={{ minWidth: 120 }}>
                <TextField
                  select
                  id="productType"
                  name="vP_PRODUCT_TYPE_ID"
                  label="Product Type"
                  defaultValue={0}
                  value={
                    formik.values.vP_PRODUCT_TYPE_ID !== 0
                      ? formik.values.vP_PRODUCT_TYPE_ID
                      : 0
                  }
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.vP_PRODUCT_TYPE_ID &&
                    Boolean(formik.errors.vP_PRODUCT_TYPE_ID)
                  }
                  helperText={
                    formik.touched.vP_PRODUCT_TYPE_ID &&
                    formik.errors.vP_PRODUCT_TYPE_ID
                  }
                >
                  {dropDownData.length > 0 && (
                    <MenuItem value={0} key="No_Data">
                      Select Product
                    </MenuItem>
                  )}
                  {dropDownData.map((row) => {
                    return (
                      <MenuItem
                        key={row.vpT_PRODUCT_TYPE_ID}
                        value={row.vpT_PRODUCT_TYPE_ID}
                      >
                        {row.vpT_PRODUCT_TYPE}
                      </MenuItem>
                    );
                  })}
                </TextField>
              </FormControl>
            </Grid>
            <Grid item xs={9} sm={9} md={9}>
              <TextField
                label="Product Group"
                fullWidth
                name="vP_PRODUCT_GROUP"
                value={formik.values.vP_PRODUCT_GROUP}
                onChange={formik.handleChange}
                inputProps={{ maxLength: 4 }}
              />
            </Grid>
            <Grid item xs={9} sm={9} md={9}>
              <TextField
                label="Unit of Measure"
                inputProps={{ maxLength: 10 }}
                fullWidth
                name="vP_UNIT_OF_MEASURE"
                value={formik.values.vP_UNIT_OF_MEASURE}
                onChange={formik.handleChange}
                error={
                  formik.touched.vP_UNIT_OF_MEASURE &&
                  Boolean(formik.errors.vP_UNIT_OF_MEASURE)
                }
                helperText={
                  formik.touched.vP_UNIT_OF_MEASURE &&
                  formik.errors.vP_UNIT_OF_MEASURE
                }
              />
            </Grid>
            <Grid item xs={9} sm={9} md={9}>
              <TextField
                label={
                  formik.values.vP_UNIT_OF_MEASURE.toUpperCase() === "LBS"
                    ? "Specific Gravity (lbs/ft^3)"
                    : "Specific Gravity"
                }
                fullWidth
                inputProps={{ maxLength: 8 }}
                name="vP_SPECIFIC_GRAVITY"
                value={formik.values.vP_SPECIFIC_GRAVITY}
                onChange={formik.handleChange}
                error={
                  formik.touched.vP_SPECIFIC_GRAVITY &&
                  Boolean(formik.errors.vP_SPECIFIC_GRAVITY)
                }
                helperText={
                  formik.touched.vP_SPECIFIC_GRAVITY &&
                  formik.errors.vP_SPECIFIC_GRAVITY
                }
              />
            </Grid>
          </Grid>
        </form>
      </Container>
      <Divider sx={{ mt: 4, mb: 2 }}></Divider>
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        sx={{
          justifyContent: "flex-end",
          alignItems: "flex-end",
          display: "flex",
        }}
      >
        {props.productData.vP_PRODUCT_ID === 0 && (
          <Button sx={{ mr: 1 }} onClick={formik.handleReset}>
            Reset
          </Button>
        )}
        <Button
          form="form1"
          type="submit"
          onSubmit={(e) => formik.handleSubmit}
        >
          {props.productData.vP_PRODUCT_ID !== 0 ? "Update" : "Submit"}
        </Button>
      </Grid>
    </>
  );
}
export default AddEditProduct;
