/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { Divider, IconButton, Toolbar, List } from "@mui/material";
import MuiDrawer from "@mui/material/Drawer";
import { CSSObject, styled, Theme } from "@mui/material/styles";
import ListItemLink from "./ListItemLink";
import { MenuItemInfo } from "../../models/routeinfo";
import { BgColorSiteContent } from "../../utils/constant/constant";

import { filterBandSelector } from "../../store/store";
import { useSelector } from "react-redux";
import { IFilterBandState } from "../../interfaces/storeInterfaces";

interface DrawerListProps {
  menus: MenuItemInfo[];
}

const drawerTopMargin: number = 120;
const drawerTopMarginSmallScreen: number = 165;
const drawerBottomPadding: number = 170;

function DrawerList({ menus }: DrawerListProps) {
  const drawerWidth: number = 200;
  const role = sessionStorage.getItem("roles") || "User";
  const [data, setData] = useState<MenuItemInfo[]>([]);
  const filterBandState: IFilterBandState = useSelector(filterBandSelector);

  useEffect(() => {
    if (menus.length > 0) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      let user = filterBandState.inboundUser;
      let staticData: MenuItemInfo[] = menus;
      for (let i = 0; i < staticData.length; i++) {
        staticData[i].isVisible = true;
        if (
          String(role).toUpperCase() !== "ADMIN" &&
          staticData[i].isAdmin === true
        ) {
          staticData[i].isVisible = false;
          continue;
        }
        if (
          filterBandState.turnCarsUser !== true &&
          staticData[i].isTurnCars === true
        ) {
          staticData[i].isVisible = false;
          continue;
        }
        if (
          filterBandState.railcarUser !== true &&
          staticData[i].isRailCar === true
        ) {
          staticData[i].isVisible = false;
          continue;
        }
        if (
          filterBandState.railcarTerminal !== true &&
          staticData[i].isRailcarTerminal === true
        ) {
          staticData[i].isVisible = false;
          continue;
        }
      }
      let finalData: MenuItemInfo[] = [];
      for (let j = 0; j < staticData.length; j++) {
        if (staticData[j].isVisible === true) {
          finalData.push(staticData[j]);
        }
      }
      setData(finalData);
    }
  }, [
    menus,
    filterBandState.turnCarsUser,
    filterBandState.railcarUser,
    filterBandState.railcarTerminal,
  ]);

  // const drawerTopMargin: number = 200;
  const handleClick = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    key: number
  ) => {
    const updatedData = data.slice();
    const item = updatedData.filter((x) => x.activeIndex === key);

    if (open && item.length > 0) {
      if (item[0].nodes) {
        const index = updatedData.indexOf(item[0]);
        updatedData[index].expand = !updatedData[index].expand;
        setData(updatedData);
      }
    }
  };

  const [open, setOpen] = React.useState(true);
  const toggleDrawer = () => {
    setOpen(!open);
    collapseAll();
  };

  const collapseAll = () => {
    setData(
      data.map((item) =>
        Object.assign({}, item, {
          expand: false,
        })
      )
    );
  };

  const tablist = (
    <React.Fragment>
      {data.map((route) => (
        <ListItemLink
          key={route.activeIndex}
          to={route.link}
          primary={route.name}
          icon={route.icon}
          nestedLinks={route.nodes}
          clickHandler={(event) => handleClick(event, route.activeIndex)}
          nestedEl={
            route.nodes ? (
              <nav>
                {route.nodes.map((child) => (
                  <ListItemLink
                    to={child.link}
                    primary={child.name}
                    icon={child.icon}
                    expand={child.expand}
                    key={child.activeIndex}
                  ></ListItemLink>
                ))}
              </nav>
            ) : (
              <React.Fragment></React.Fragment>
            )
          }
          expand={route.expand}
        />
      ))}
    </React.Fragment>
  );

  const openedMixin = (theme: Theme): CSSObject => ({
    width: drawerWidth,
    [theme.breakpoints.down("sm")]: {
      marginTop: drawerTopMarginSmallScreen,
      paddingBottom: drawerBottomPadding,
    },
    [theme.breakpoints.between("sm", "md")]: {
      marginTop: drawerTopMarginSmallScreen,
      paddingBottom: drawerBottomPadding,
    },
    [theme.breakpoints.up("md")]: {
      marginTop: drawerTopMargin,
      paddingBottom: drawerBottomPadding,
    },
    [theme.breakpoints.up("lg")]: {
      marginTop: drawerTopMargin,
      paddingBottom: drawerBottomPadding,
    },
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: "hidden",
  });

  const closedMixin = (theme: Theme): CSSObject => ({
    [theme.breakpoints.down("sm")]: {
      marginTop: drawerTopMarginSmallScreen,
      paddingBottom: drawerBottomPadding,
    },
    [theme.breakpoints.between("sm", "md")]: {
      marginTop: drawerTopMarginSmallScreen,
      paddingBottom: drawerBottomPadding,
    },
    [theme.breakpoints.up("md")]: {
      marginTop: drawerTopMargin,
      paddingBottom: drawerBottomPadding,
    },
    [theme.breakpoints.up("lg")]: {
      marginTop: drawerTopMargin,
      paddingBottom: drawerBottomPadding,
    },
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up("sm")]: {
      width: `calc(${theme.spacing(7)} + 1px)`,
    },
  });

  const Drawer = styled(MuiDrawer, {
    shouldForwardProp: (prop) => prop !== "open",
  })(({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    backgroundColor: BgColorSiteContent,
    [theme.breakpoints.down("sm")]: {
      marginTop: drawerTopMarginSmallScreen,
    },
    [theme.breakpoints.between("sm", "md")]: {
      marginTop: drawerTopMarginSmallScreen,
    },
    [theme.breakpoints.up("md")]: {
      marginTop: drawerTopMargin,
    },
    [theme.breakpoints.up("lg")]: {
      marginTop: drawerTopMargin,
    },
    whiteSpace: "normal",
    boxSizing: "border-box",
    ...(open && {
      ...openedMixin(theme),
      "& .MuiDrawer-paper": openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      "& .MuiDrawer-paper": closedMixin(theme),
    }),
  }));

  return (
    <Drawer
      variant="permanent"
      open={open}
      sx={{
        drawerPaper: {
          marginTop: "50px",
        },
      }}
    >
      <Toolbar
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
          px: [1],
        }}
      >
        <IconButton onClick={toggleDrawer}>
          {open ? (
            <ChevronLeftIcon color="primary" />
          ) : (
            <ChevronRightIcon color="primary" />
          )}
        </IconButton>
      </Toolbar>
      <Divider />

      <List component="nav">
        {tablist}
        <Divider sx={{ my: 1 }} />
      </List>
    </Drawer>
  );
}
export default DrawerList;
