/* eslint-disable import/no-anonymous-default-export */
import useAxiosPost from "../hooks/useAxiosPost";

export type Email = {
  email_id: string;
  family_name: string;
  given_name: string;
  subject: string;
  title: string;
  userType: string;
};

const GetDefaultEmailData = (): Email => {
  const defaultEmail = {
    email_id: "",
    family_name: "",
    given_name: "",
    subject: "",
    title: "",
    userType: "",
  } as Email;
  return defaultEmail;
};

const ConfirmationEmail = (data: Email) => {
  return useAxiosPost(
    `/users/confirmation`,
    data,
    "Sending Confirmation Mail"
  );
};

export default {
  ConfirmationEmail,
  GetDefaultEmailData,
};
