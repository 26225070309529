import "./App.css";
import { AzureAD } from "react-aad-msal";
import { authProvider } from "./config/authConfig";
import Layout from "./components/layout/Layout";
import jwtDecode from "jwt-decode";
import { useCallback } from "react";
import ContactAdministrator from "../src/pages/ContactAdministrator";

function App() {
  interface MyToken {
    name: string;
    exp: number;
    email: string;
    roles: [];
  }

  function clearStorage() {
    window.sessionStorage.clear();
    window.localStorage.clear();
  }

  const logout = useCallback(() => {
    clearStorage();
    authProvider.logout();
  }, []);

  if (sessionStorage.length > 0) {
    let accessToken,
      tknType = "";

    for (let i = 0; i < sessionStorage.length; i++) {
      let key = sessionStorage.key(i) || "";
      if (key === "msal.login.error") {
        accessToken = sessionStorage.getItem(key);
        tknType = "invalid";
        break;
      }
    }

    if (tknType === "") {
      for (let i = 0; i < sessionStorage.length; i++) {
        let key = sessionStorage.key(i) || "";
        if (
          key.indexOf(
            "msal." + process.env.REACT_APP_B2C_CLIENT_ID + ".idtoken"
          ) !== -1
        ) {
          accessToken = sessionStorage.getItem(key);
          tknType = "valid";
          break;
        }
      }
    }

    //console.log(accessToken);

    const tokenFound: boolean = accessToken ? true : false;

    if (tokenFound) {
      if (tknType === "valid") {
        const decodedToken = jwtDecode<MyToken>(accessToken || "");
        const isValid: boolean = accessToken
          ? decodedToken.exp * 1000 > Date.now()
          : false;

        if (isValid) {
          const role = isValid ? decodedToken.roles : []; //role
          const emailId = isValid ? decodedToken.email : ""; //email
          sessionStorage.setItem("roles", role[0]);
          sessionStorage.setItem("userMailID", emailId);
          sessionStorage.setItem("jwtToken", accessToken || "");

          return (
            <AzureAD provider={authProvider} forceLogin={true}>
              <Layout logoutAction={logout} userName={decodedToken.name} />;
            </AzureAD>
          );
        } else {
          return <AzureAD provider={authProvider} forceLogin={true} />;
        }
      } else if (tknType === "invalid") {
        //need to kept blank for event already handeled in authConfig.ts
        //append all the error code in this scope which was added in authConfig.ts
        if (accessToken?.toString().indexOf("AADB2C90118") !== -1) {
          let type = "";
          for (let i = 0; i < sessionStorage.length; i++) {
            let key = sessionStorage.key(i) || "";
            if (
              key ===
              "msal." + process.env.REACT_APP_B2C_CLIENT_ID + ".idtoken"
            ) {
              type = "ResetComplete";
              break;
            }
          }
          if (type === "ResetComplete") {
            const msg = "Kindly login again to continue";
            return <ContactAdministrator msg={msg} type="clear" />;
          } else {
            const msg = "Redirecting to Password Reset..";
            return <ContactAdministrator msg={msg} type="keep" buttonVisible={false}/>;
          }
        } else if (accessToken?.toString().indexOf("AADB2C99002") !== -1) {
          return <div>Loading..</div>;
        }
        //---------------------------------------------------------------------
        else if (accessToken?.toString().indexOf("B2COIS4091") !== -1) {
          const msg =
            "Role is not assigned. Kindly contact administrator and get roles assigned for yourself.";
          return <ContactAdministrator msg={msg} type="clear" />;
        } else if (accessToken?.toString().indexOf("AADB2C90091") !== -1) {
          const msg = "User cancelled the operation.";
          return <ContactAdministrator msg={msg} type="clear" />;
        } else {
          const msg =
            "Sorry for inconvenience in accesing the application. Kindly try again in sometime or contact Administrator incase same issue is appearing.";
          return <ContactAdministrator msg={msg} type="clear" />;
        }
      } else {
        return <AzureAD provider={authProvider} forceLogin={true} />;
      }
    } else {
      return <AzureAD provider={authProvider} forceLogin={true} />;
    }
  } else {
    return <AzureAD provider={authProvider} forceLogin={true} />;
  }
}

export default App;
