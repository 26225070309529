/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Grid, TextField, Button, Container, Divider } from "@mui/material";
import { useFormik } from "formik";
import * as yup from "yup";
import { Customer } from "../../../models/customer";
import CustomerDataService from "../../../services/customerService";
import DB2DataService from "../../../services/db2LookUpService";
import { useAppDispatch } from "../../../store/store";
import { uiActions } from "../../../store/ui-slice";
import SearchSharpIcon from "@mui/icons-material/SearchSharp";

interface AddEditCustomerProps {
  setShowModalPopup: React.Dispatch<React.SetStateAction<boolean>>;
  customerNo: string;
  setRefreshList: React.Dispatch<React.SetStateAction<boolean>>;
}

function AddEditCustomer(props: AddEditCustomerProps) {
  const dispatch = useAppDispatch();
  const [customerNo, setCustomerNo] = useState("");
  const [lookupID, setLookUpID] = useState<string>("");
  const [customerInfo, setCustomerInformation] = useState<Customer>(
    CustomerDataService.GetDefaultCustomer()
  );
  const [isLoaded, setIsLoaded] = useState<boolean>(false);

  const validationSchema = yup.object({
    customerNo: yup
      .string()
      .required("Required")
      .min(6, "Customer No. should be of 6 characters")
      .max(6, "Customer No. should be of 6 characters"),

    customerName: yup
      .string()
      .required("Required")
      .max(30, "Customer name should be less than 30 characters"),

    vC_CUSTOMER_CONTACT: yup
      .string()
      .required("Required")
      .max(
        30,
        "Customer contact person name should be less than 50 characters"
      ),

    vC_CUSTOMER_EMAIL: yup
      .string()
      .email("Invalid Email")
      .nullable(true)
      .max(50, "Email Id should be less than 50 characters"),

    vC_CUSTOMER_PHONE: yup
      .string()
      .nullable(true)
      .min(10, "Invalid Phone No.")
      .max(10, "Invalid Phone No"),

    vC_COA_REQUEST_GROUP: yup
      .string()
      .nullable(true)
      .max(50, "COA Group shold be less than 50 characters"),
  });

  const { fetchData: fetchCustomerData } =
    CustomerDataService.GetCustomerByNumber(props.customerNo);
  const { fetchData: fetchCustomerLookUpData } =
    DB2DataService.GetTransportCustomer(lookupID);
  const { postData: updateCustomerData } =
    CustomerDataService.UpdateCustomerByNumber(customerInfo);
  const { postData: addCustomerData } =
    CustomerDataService.CreateCustomer(customerInfo);

  useEffect(() => {
    loadCustomerbyID(props.customerNo);
    setCustomerNo(props.customerNo);
  }, [props.customerNo]);

  const loadCustomerbyID = (customerNo: string) => {
    if (customerNo !== "") {
      dispatch(uiActions.startProgressBar());
      fetchCustomerData().then((res) => {
        formik.setValues(res.responseData);
      });
    }
  };

  useEffect(() => {
    LookUpCustomer(lookupID);
    // setLookUpID(lookupID);
  }, [lookupID]);

  const handleLookUp = (e: React.MouseEvent<HTMLButtonElement>) => {
    // formik.handleReset(Event);
    setLookUpID(formik.values.customerNo);
  };

  const LookUpCustomer = (customerNo: string) => {
    if (customerNo !== "") {
      dispatch(uiActions.startProgressBar());
      fetchCustomerLookUpData().then((res) => {
        if (
          res.responseData.customerNo !== null &&
          res.responseData.customerNo !== ""
        ) {
          formik.setValues(res.responseData);
        } else {
          dispatch(
            uiActions.displayNotification({
              message:
                "No customer information is found for the Sold To # you have provided.",
              severity: "error",
            })
          );
        }
      });
    }
  };

  useEffect(() => {
    if (isLoaded) {
      if (customerNo !== "" && customerNo !== null) {
        dispatch(uiActions.startProgressBar());
        updateCustomerData().then((res) => {
          if (res.isSuccess) {
            dispatch(
              uiActions.displayNotification({
                message: "Customer information updated successfully!",
                severity: "success",
              })
            );
            props.setShowModalPopup(false);
            props.setRefreshList(true);
          }
        });
      } else {
        dispatch(uiActions.startProgressBar());
        addCustomerData().then((res) => {
          if (res.isSuccess === true) {
            if (res.responseData.success === "Yes") {
              dispatch(
                uiActions.displayNotification({
                  message: "Customer information added successfully!",
                  severity: "success",
                })
              );
              props.setShowModalPopup(false);
              props.setRefreshList(true);
            } else if (res.responseData.success === "No") {
              dispatch(
                uiActions.logErrorNotification({
                  message:
                    "There is already a customer with this 'Ship to' number.",
                  severity: "error",
                })
              );
            }
          }
        });
      }
    } else {
      setIsLoaded(true);
    }
  }, [customerInfo]);

  const formik = useFormik({
    initialValues: CustomerDataService.GetDefaultCustomer(),
    validationSchema: validationSchema,

    onReset: (Event) => {
      formik.values = CustomerDataService.GetDefaultCustomer();
    },

    onSubmit: (values: Customer) => {
      setCustomerInformation(values);
    },
  });

  return (
    <React.Fragment>
      <Container>
        <form onSubmit={formik.handleSubmit} id="form1" className="form">
          <Grid container spacing={3} sx={{ paddingTop: 1 }}>
            <Grid item xs={9} sm={9} md={9}>
              <TextField
                name="customerNo"
                label="Sold To # *"
                value={formik.values.customerNo}
                fullWidth
                onChange={formik.handleChange}
                disabled={
                  customerNo !== "" &&
                  customerNo !== undefined &&
                  customerNo !== null
                    ? true
                    : false
                }
                error={
                  formik.touched.customerNo && Boolean(formik.errors.customerNo)
                }
                helperText={
                  formik.touched.customerNo && formik.errors.customerNo
                }
                inputProps={{ maxLength: 6 }}
              />
            </Grid>
            {
              <Grid item xs={3} sm={3} md={3}>

{customerNo !== "" ? <></> : <Button
                  startIcon={<SearchSharpIcon></SearchSharpIcon>}
                  onClick={(e) => handleLookUp(e)}
                >
                  Look Up
                </Button>
                
                }


                
                {/* } */}
              </Grid>
            }
            <Grid item xs={9} sm={9} md={9}>
              <TextField
                name="customerName"
                label="Customer Name *"
                value={formik.values.customerName}
                fullWidth
                onChange={formik.handleChange}
                error={
                  formik.touched.customerName &&
                  Boolean(formik.errors.customerName)
                }
                helperText={
                  formik.touched.customerName && formik.errors.customerName
                }
                inputProps={{ maxLength: 30 }}
              />
            </Grid>
            <Grid item xs={9} sm={9} md={9}>
              <TextField
                name="vC_CUSTOMER_CONTACT"
                label="Contact Name *"
                value={formik.values.vC_CUSTOMER_CONTACT}
                fullWidth
                onChange={formik.handleChange}
                error={
                  formik.touched.vC_CUSTOMER_CONTACT &&
                  Boolean(formik.errors.vC_CUSTOMER_CONTACT)
                }
                helperText={
                  formik.touched.vC_CUSTOMER_CONTACT &&
                  formik.errors.vC_CUSTOMER_CONTACT
                }
                inputProps={{ maxLength: 30 }}
              />
            </Grid>
            <Grid item xs={9} sm={9} md={9}>
              <TextField
                name="vC_CUSTOMER_PHONE"
                label="Contact Phone"
                value={formik.values.vC_CUSTOMER_PHONE}
                fullWidth
                onChange={formik.handleChange}
                error={
                  formik.touched.vC_CUSTOMER_PHONE &&
                  Boolean(formik.errors.vC_CUSTOMER_PHONE)
                }
                helperText={
                  formik.touched.vC_CUSTOMER_PHONE &&
                  formik.errors.vC_CUSTOMER_PHONE
                }
                inputProps={{ maxLength: 10 }}
              />
            </Grid>
            <Grid item xs={9} sm={9} md={9}>
              <TextField
                name="vC_CUSTOMER_EMAIL"
                label="Contact Email"
                fullWidth
                value={
                  formik.values.vC_CUSTOMER_EMAIL !== ""
                    ? formik.values.vC_CUSTOMER_EMAIL
                    : ""
                }
                onChange={formik.handleChange}
                error={
                  formik.touched.vC_CUSTOMER_EMAIL &&
                  Boolean(formik.errors.vC_CUSTOMER_EMAIL)
                }
                helperText={
                  formik.touched.vC_CUSTOMER_EMAIL &&
                  formik.errors.vC_CUSTOMER_EMAIL
                }
                inputProps={{ maxLength: 30 }}
              />
            </Grid>
            <Grid item xs={9} sm={9} md={9}>
              <TextField
                name="vC_COA_REQUEST_GROUP"
                label="COA Group"
                fullWidth
                value={formik.values.vC_COA_REQUEST_GROUP}
                onChange={formik.handleChange}
                error={
                  formik.touched.vC_COA_REQUEST_GROUP &&
                  Boolean(formik.errors.vC_COA_REQUEST_GROUP)
                }
                helperText={
                  formik.touched.vC_COA_REQUEST_GROUP &&
                  formik.errors.vC_COA_REQUEST_GROUP
                }
                inputProps={{ maxLength: 50 }}
              />
            </Grid>
          </Grid>
        </form>
      </Container>
      <Divider sx={{ mt: 4, mb: 2 }}></Divider>
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        sx={{
          justifyContent: "flex-end",
          alignItems: "flex-end",
          display: "flex",
        }}
      >
        {customerNo === "" && (
          <Button onClick={formik.handleReset} sx={{ mr: 1 }}>
            Reset
          </Button>
        )}
        <Button
          form="form1"
          type="submit"
          onSubmit={(e) => formik.handleSubmit}
        >
          {customerNo !== "" ? "Update" : "Submit"}
        </Button>
      </Grid>
    </React.Fragment>
  );
}

export default AddEditCustomer;
