import { createSlice } from '@reduxjs/toolkit';


const authSlice = createSlice({
  name: 'auth',
  initialState: {
    emailId:""
   },
  reducers: {
    addUser(state) {
     
    }
  }
});

export const authActions = authSlice.actions;

export default authSlice;