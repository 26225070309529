import IntransitProductInventory from "../components/app/Product/IntransitProductInventory";
import { filterBandSelector } from "../store/store";
import { useSelector } from "react-redux";
import { IFilterBandState } from "../interfaces/storeInterfaces";
import SystemMessage from "./SystemMessage";

function IntransitProductInventoryPage() {
  const filterBandState: IFilterBandState = useSelector(filterBandSelector);
  return filterBandState.railcarUser === true ? (
    <IntransitProductInventory></IntransitProductInventory>
  ) : (
    <SystemMessage msg="Access Denied." colorMsg="Red"></SystemMessage>
  );
}

export default IntransitProductInventoryPage;
