/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import PageHeader from "../../shared/PageHeader";
import { Grid } from "@mui/material";
import ProductInventory from "../../app/Product/ProductInventory";
import { filterBandSelector } from "../../../store/store";
import { IFilterBandState } from "../../../interfaces/storeInterfaces";
import { useAppDispatch } from "../../../store/store";
import { useSelector } from "react-redux";
import { uiActions } from "../../../store/ui-slice";
import UnitInformation from "../../app/Location/UnitInformation";
import LocationInformation from "../../app/Location/LocationInformation";

function LocationSummary() {
  const filterBandState: IFilterBandState = useSelector(filterBandSelector);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (filterBandState.selectedLocationNo)
      dispatch(uiActions.startProgressBarWithTotalStepCount(3));
  }, [filterBandState.selectedLocationNo]);

  return (
    <div>
      <PageHeader
        title="Location Summary"
        subtitle="To view the location summary of another location, select the location name from the location dropdown box above. To view the tank details, click on a tank name. To edit a unit, click on the unit ID."
      ></PageHeader>
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 1, md: 1 }}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <ProductInventory
            customerNo={filterBandState.selectedCustomerNo}
            locationNo={filterBandState.selectedLocationNo}
          ></ProductInventory>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <LocationInformation
            customerNo={filterBandState.selectedCustomerNo}
            locationNo={filterBandState.selectedLocationNo}
          ></LocationInformation>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <UnitInformation
            customerNo={filterBandState.selectedCustomerNo}
            locationNo={filterBandState.selectedLocationNo}
          ></UnitInformation>
        </Grid>
      </Grid>
    </div>
  );
}

export default LocationSummary;
