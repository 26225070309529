
import React from "react";
import { Box, Container, Link, styled, Toolbar, Typography } from "@mui/material";
//backgroundColor: theme.palette.primary.light,

const date=new Date();
const year=date.getFullYear();

export default function Footer() {
  const AppFooter = styled(Toolbar)(({ theme }) => ({   
    width: "100%",
    zIndex: 1302,
    position: "fixed",
    bottom:0,
    display: 'flex',
    alignItems: 'center',
    minHeight: 50,
    '@media (max-width: 499px)': {
      display: 'table',
      width: '100%',
      minHeight: 'auto',
      padding: '1rem 0',
      '& .container': {
        flexDirection: 'column !important',
        '& a': {
          margin: '0 0 16px !important',
        },
      },
    },
  }));

  return (
    <AppFooter>
      <Container maxWidth="lg">
        <Box style={{
          backgroundColor: '#F5F5F5',
          padding: 1,
          position: 'fixed',
          bottom: 0,
          width: '100%',
          height: 35,
          textAlign: 'center',
          left: 0,
          fontSize: 4
        }}>
          <Typography sx={{
            fontSize: 10,
            color: "grey",
            variant: "caption",
            // [theme.breakpoints.down("sm")]: {
            //   fontSize: 8
            // }
          }}>
            ©{year} ADM. All Rights Reserved. Designated trademarks and brands are the property of their respective owners.
            <br />
            Use of this website constitutes acceptance of the&nbsp;
            <Link sx={{ paddingLeft: 1 }}
              href="http://dev.e-adm.com/legl/Default.aspx" target='_blank'
            >
              Terms of Use Agreement
            </Link>
            &nbsp;and the&nbsp;
            <Link sx={{ paddingLeft: 1 }}
              href="http://www.adm.com/en-us/Pages/privacy.aspx" target='_blank'
            >
              Privacy Policy
            </Link>
            .
          </Typography>
        </Box>
      </Container>
    </AppFooter>
  );

}