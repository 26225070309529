/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Grid, TextField, Button, Container, Divider, MenuItem, RadioGroup, FormControlLabel, Radio, FormControl, Checkbox } from "@mui/material";
import { uiActions } from "../../../store/ui-slice";
import UserNoticeService from "../../../services/userNoticeService";
import { Notice, NoticeType, NoticeUsers, UserLevelNotice, UserNoticeInfo } from "../../../models/userNotice";
import { useFormik } from "formik";
import * as yup from "yup";
import dayjs from "dayjs";
import { filterBandSelector, useAppDispatch } from "../../../store/store";
import { IFilterBandState } from "../../../interfaces/storeInterfaces";
import { useSelector } from "react-redux";


interface AddEditNoticeProps {
    setShowModalPopup: React.Dispatch<React.SetStateAction<boolean>>;
    userId: string;
    noticeId: string;
    locationNo: string;
    locationId: string;
    isEditLevelNotice: boolean;
    isEditNotice: boolean;
    setRefreshList: React.Dispatch<React.SetStateAction<boolean>>;
}

function AddEditNoticeRequest(props: AddEditNoticeProps) {
    const filterBandState: IFilterBandState = useSelector(filterBandSelector);
    const dispatch = useAppDispatch();
    const [users, setUsers] = useState<NoticeUsers[]>([]);
    const [noticeTypes, setNoticeType] = useState<NoticeType[]>([]);
    const [dprList, setDrpList] = useState<number[]>([]);
    const [noticeRequest, setNoticeRequest] = useState<Notice>(
        UserNoticeService.GetDefaultNoticeRequest()
    );
    const { fetchData: fetchUsers } = UserNoticeService.RetrieveUsers();
    const { fetchData: fetchNoticeType } = UserNoticeService.RetrieveNoticeTypes();
    const { postData: addNoticeRequest } = UserNoticeService.CreateNotice(noticeRequest);
    const { fetchData: fetchUserNoticeInfo } = UserNoticeService.RetrieveUsersNoticeInfo(props.userId, props.noticeId);
    const { fetchData: fetchUserLevelNoticeInfo } = UserNoticeService.RetrieveUsersLevelNoticeInfo(props.userId, props.noticeId, props.locationId);
    const [isLoaded, setIsLoaded] = useState<boolean>(false);

    useEffect(() => {
        if (props.isEditLevelNotice) {
            if ((props.locationNo !== "") && props.noticeId !== "" && props.userId !== "") {
                dispatch(uiActions.startProgressBar());
                fetchUserLevelNoticeInfo().then((res) => {
                    if (res.isSuccess) {
                        assignLevelUserNoticeInfoValues(res.responseData[0]);
                    }
                });
            }
        }
    }, [props.isEditLevelNotice, props.locationNo, props.noticeId, props.userId]);

    useEffect(() => {
        if (props.isEditNotice) {
            if (props.noticeId !== "" && props.userId !== "") {
                dispatch(uiActions.startProgressBar());
                fetchUserNoticeInfo().then((res) => {
                    if (res.isSuccess) {
                        assignUserNoticeInfoValues(res.responseData[0]);
                    }
                });
            }
        }

    }, [props.isEditNotice, props.noticeId, props.userId]);



    useEffect(() => {
        loadUserDropdown();
        loadNoticeType();
        loadLevelPercentageDropdown();
    }, []);

    useEffect(() => {
        if (isLoaded) {
            dispatch(uiActions.startProgressBar());
            addNoticeRequest().then((res) => {
                if (res.isSuccess === true) {
                    if (res.responseData.success === "Yes") {
                        dispatch(
                            uiActions.displayNotification({
                                message: "The notice information saved successfully.",
                                severity: "success",
                            })
                        );
                        props.setShowModalPopup(false);
                        props.setRefreshList(true);
                    } else if (res.responseData.success === "No") {
                        dispatch(
                            uiActions.logErrorNotification({
                                message:
                                    "The notice information could not be saved successfully. The user may not have security for the selected location.",
                                severity: "error",
                            })
                        );
                    }
                }
            });
        }
        else {
            setIsLoaded(true);
        }
    }, [noticeRequest]);


    const assignUserNoticeInfoValues = (values: UserNoticeInfo) => {
        let apiUserNoticeValues: Notice = {
            userID: String(values.vuN_USER_ID),
            noticeID: values.vN_NOTICE_ID,
            hourNotice:   values.vN_NOTICE_TYPE==="A"? String(values.vuN_HOUR_NOTICE):"",
            idleDays:values.vN_NOTICE_TYPE==="I"? String(values.vuN_IDLE_DAYS):"",
            thresholdAmount:values.vN_NOTICE_TYPE==="U"?  String(values.vuN_THRESHOLD_AMOUNT === null ? "" : values.vuN_THRESHOLD_AMOUNT):"",
            lastNotice: String(values.vuN_LAST_NOTICE),
            prevLocationID: values.vunL_LOCATION_ID,
            locationNo: "",
            symbol: "",
            level: "",
            customerNo: "",
            levelDailyOption: "",
        };

        formik.setValues(apiUserNoticeValues);
    };

    const assignLevelUserNoticeInfoValues = (values: UserLevelNotice) => {
        let apiUserLevelNoticeValues: Notice = {
            userID: String(values.vunL_USER_ID),
            noticeID: values.vunL_NOTICE_ID,
            hourNotice: "0",
            idleDays: "0",
            thresholdAmount: values.vN_NOTICE_DESC.trim().indexOf("Usage") !== -1 ? String(values.vunL_THRESHOLD) : "",
            lastNotice: String(values.vunL_LAST_UPDATE),
            prevLocationID: values.vunL_LOCATION_ID,
            locationNo: "",
            symbol: values.vunL_EQUALITY_SYMBOL==="="? "1":values.vunL_EQUALITY_SYMBOL==="<"?"2":"3",
            level: values.vN_NOTICE_DESC.trim().indexOf("Usage") === -1 ? String(values.vunL_THRESHOLD) : "",
            customerNo: "",
            levelDailyOption: values.vunL_DAILY,
        };
        formik.setValues(apiUserLevelNoticeValues);
    };

    let menuItem = users.length > 0 ? <MenuItem value={"N"}>Select User</MenuItem> : <MenuItem value={"N"}>None</MenuItem>

    const loadUserDropdown = () => {
        dispatch(uiActions.startProgressBar());
        fetchUsers().then((res) => {
            if (res.responseData.length > 0) {
                setUsers(res.responseData)
            }
        });
    };

    const loadNoticeType = () => {
        dispatch(uiActions.startProgressBar());
        fetchNoticeType().then((res) => {
            if (res.responseData.length > 0) {
                setNoticeType(res.responseData)
            }
        });
    };

    const loadLevelPercentageDropdown = () => {
        let list = [];

        for (let i = 5; i <= 100; i += 5) {
            list.push(i);
        }
        setDrpList(list)
    };

    const validationSchema = yup.object({
        userID: yup
            .string()
            .required("Please select a user."),
        idleDays: yup
            .number()
            .typeError(
                "The value supplied for IdleDays must be a number"
            ),
        hourNotice: yup
            .number()
            .typeError(
                "The value supplied for HourNotice must be a number"
            ),
        thresholdAmount: yup
            .number()
            .typeError(
                "The value supplied for ThresholdAmount must be a number"
            ),
    });

    const userChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        let userId = event.target.value;
        if (userId === "N") {
            userId = ""
        }
        formik.setFieldValue("userID", userId)
    };

    const symbolChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        let symbol = event.target.value;
        
        formik.setFieldValue("symbol", symbol)
    };

    const NoticeTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        let noticeTypeId = event.target.value;
        formik.setFieldValue("noticeID", noticeTypeId)
    };

    const levelChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        let level = event.target.value;
        formik.setFieldValue("level", level.toString())
    };

    const handleLimitChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        let limit = event.target.checked;
        if (limit) {
            formik.setFieldValue("levelDailyOption", "Y")
        }
        else {
            formik.setFieldValue("levelDailyOption", "N")
        }

    };

    function CheckNoticeTypeValication(noticeId: number) {
        if (noticeId < 1) {
            dispatch(
                uiActions.displayNotification({
                    message: "Please select at least one notice type",
                    severity: "error",
                })
            );
            return false;
        }
        if (noticeId > 0) {
            let notcType = noticeTypes.filter(function (el) {
                return el.vN_NOTICE_ID === Number(noticeId)
            });

            if (notcType[0].vN_NOTICE_TYPE === "L") {
                if (!filterBandState.selectedLocationNo) {
                    dispatch(
                        uiActions.displayNotification({
                            message: "Please select a location.",
                            severity: "error",
                        })
                    );
                    return false;
                }
                else {
                    return true;
                }

            }
            else {
                return true;
            }
        }
    }

    const formik = useFormik({
        initialValues: UserNoticeService.GetDefaultNoticeRequest(),
        validationSchema: validationSchema,
        onSubmit: (values: Notice) => {
            if (CheckNoticeTypeValication(values.noticeID)) {
                if (values.idleDays === "") {
                    values.idleDays = "0";
                }
                if (values.hourNotice === "") {
                    values.hourNotice = "0";
                }
                if (values.thresholdAmount === "") {
                    values.thresholdAmount = "0";
                }
                if (values.symbol === "") {
                    values.symbol = ">";
                }
                if (values.level === "") {
                    values.level = dprList[0].toString();
                }
                if (values.levelDailyOption === "" || values.levelDailyOption === null || values.levelDailyOption === undefined) {
                    values.levelDailyOption = "N"
                }

                if (values.symbol === "1") {
                    values.symbol = "="
                }
                else if (values.symbol === "2") {
                    values.symbol = "<"
                }
                else if (values.symbol === "3") {
                    values.symbol = ">"
                }

                values.lastNotice = dayjs(new Date()).format("YYYY-MM-DD")
                values.customerNo = filterBandState.selectedCustomerNo;
                values.locationNo = filterBandState.selectedLocationNo === null ? "" : filterBandState.selectedLocationNo;
                setNoticeRequest(values);
            }
        },
    });

    return (
        <React.Fragment>
            <Container>
                <form onSubmit={formik.handleSubmit} id="form1" className="form">
                    <Grid container spacing={3} sx={{ paddingTop: 1 }}>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <TextField
                                id="userID"
                                name="userID"
                                value={formik.values.userID}
                                select
                                label="User"
                                defaultValue={"N"}
                                onChange={userChange}
                                fullWidth
                                error={
                                    formik.touched.userID &&
                                    Boolean(formik.errors.userID)
                                }
                                helperText={
                                    formik.touched.userID &&
                                    formik.errors.userID
                                }
                            >
                                {menuItem}
                                {users.map((row, index) => {
                                    return (
                                        <MenuItem
                                            key={row.vU_USER_ID + "$" + index}
                                            value={String(row.vU_USER_ID)}
                                        >
                                            {row.vU_LAST_NAME + " " +
                                                row.vU_FIRST_NAME
                                            }
                                        </MenuItem>
                                    );
                                })}
                            </TextField>
                        </Grid>
                        <>
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                <RadioGroup row
                                    onChange={NoticeTypeChange}
                                    name="noticeID"
                                    value={formik.values.noticeID}
                                >
                                    {noticeTypes.map((row, index) => {
                                        return (
                                            <>
                                                <Grid item xs={4} sm={4} md={4} lg={4}  style={{paddingTop:"8px"}}>
                                                    <span>{row.vN_NOTICE_DESC}</span>
                                                </Grid>
                                                <Grid item xs={1} sm={1} md={1} lg={1}>
                                                    <FormControl>
                                                        <FormControlLabel
                                                            key={index}
                                                            value={row.vN_NOTICE_ID}
                                                            control={<Radio />}
                                                            label=""
                                                        />
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={6} sm={6} md={6} lg={6}  style={{paddingTop:"8px"}}>
                                                <span>
                                                    {row.vN_NOTICE_TYPE === "D" ? "One Notice Daily"
                                                        : row.vN_NOTICE_TYPE === "W" ? "Sunday through Sunday Spreadsheet"
                                                            : row.vN_NOTICE_TYPE === "L" ?
                                                                (<>
                                                                    <TextField
                                                                        id="symbol"
                                                                        name="symbol"
                                                                        defaultValue={3}
                                                                        value={formik.values.symbol === "" || formik.values.symbol === null || formik.values.symbol === undefined ? 3 : formik.values.symbol}
                                                                        onChange={symbolChange}
                                                                        select
                                                                    >
                                                                        <MenuItem value={"1"}>{"="}</MenuItem>
                                                                        <MenuItem value={"2"}>{"<"}</MenuItem>
                                                                        <MenuItem value={"3"}>{">"}</MenuItem>
                                                                    </TextField>{"  "}
                                                                    <TextField
                                                                        id="Level"
                                                                        name="Level"
                                                                        value={formik.values.level === "" || formik.values.level === null || formik.values.level === undefined ? dprList[0] : formik.values.level}
                                                                        defaultValue={dprList[0]}
                                                                        onChange={levelChange}
                                                                        select
                                                                    >
                                                                        {dprList.map((row, index) => {
                                                                            return (
                                                                                <MenuItem value={row}>{row}{" %"}</MenuItem>
                                                                            );
                                                                        })}

                                                                    </TextField> {"  "}
                                                                     <FormControlLabel control={<Checkbox checked={formik.values.levelDailyOption === "Y" ? true : false} onChange={handleLimitChange} />} label="Limit one email per day" />
                                                                </>)
                                                                : row.vN_NOTICE_TYPE === "I" ?
                                                                    <>
                                                                        {" >="}
                                                                        <TextField
                                                                            id="idleDays"
                                                                            name="idleDays"
                                                                            value={formik.values.idleDays==="0"?"":formik.values.idleDays }
                                                                            inputProps={{ maxLength: 2 }}
                                                                            onChange={formik.handleChange}
                                                                            error={
                                                                                formik.touched.idleDays &&
                                                                                Boolean(formik.errors.idleDays)
                                                                            }
                                                                            helperText={
                                                                                formik.touched.idleDays &&
                                                                                formik.errors.idleDays
                                                                            }
                                                                        >
                                                                        </TextField>
                                                                        {"days"}
                                                                    </>
                                                                    : row.vN_NOTICE_TYPE === "A" ?
                                                                        <>
                                                                            {" ="}
                                                                            <TextField
                                                                                id="hourNotice"
                                                                                name="hourNotice"
                                                                                value={formik.values.hourNotice==="0"?"":formik.values.hourNotice}
                                                                                inputProps={{ maxLength: 2 }}
                                                                                onChange={formik.handleChange}
                                                                                error={
                                                                                    formik.touched.hourNotice &&
                                                                                    Boolean(formik.errors.hourNotice)
                                                                                }
                                                                                helperText={
                                                                                    formik.touched.hourNotice &&
                                                                                    formik.errors.hourNotice
                                                                                }
                                                                            >
                                                                            </TextField>
                                                                            {"hours"}
                                                                        </>

                                                                        : row.vN_NOTICE_TYPE === "U" ?
                                                                            <>
                                                                                {" ="}
                                                                                <TextField
                                                                                    id="thresholdAmount"
                                                                                    name="thresholdAmount"
                                                                                    value={formik.values.thresholdAmount==="0"?"":formik.values.thresholdAmount}
                                                                                    inputProps={{ maxLength: 5 }}
                                                                                    onChange={formik.handleChange}
                                                                                    error={
                                                                                        formik.touched.thresholdAmount &&
                                                                                        Boolean(formik.errors.thresholdAmount)
                                                                                    }
                                                                                    helperText={
                                                                                        formik.touched.thresholdAmount &&
                                                                                        formik.errors.thresholdAmount
                                                                                    }
                                                                                >
                                                                                </TextField>
                                                                            </>

                                                                            : "" 
                                                    }
                                                    </span>
                                                </Grid>
                                            </>
                                        )
                                    })}

                                </RadioGroup>
                            </Grid>
                        </>
                    </Grid>
                </form>
            </Container>
            <Divider sx={{ mt: 4, mb: 2 }}></Divider>
            <Grid
                item
                xs={12}
                sm={12}
                md={12}
                sx={{
                    justifyContent: "flex-end",
                    alignItems: "flex-end",
                    display: "flex",
                }}
            >

                <Button
                    form="form1"
                    type="submit"
                    onSubmit={(e) => formik.handleSubmit}
                >
                    {"Submit"}
                </Button>
            </Grid>
        </React.Fragment>
    );
}

export default AddEditNoticeRequest;
