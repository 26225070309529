import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IFilterBandState } from "../interfaces/storeInterfaces";
import { Customer } from "../models/customer";
import { LocationInfo } from "../models/location";
import { TankByLocation } from "../models/tank";

let defaultCustomerList: Customer[] = [];
let defaultLocationList: LocationInfo[] = [];
let defaultTankList: TankByLocation[] = [];

const initialState = {
  selectedCustomerNo: "",
  selectedCustomerName: "",
  selectedLocationNo: "",
  selectedLocationName: "",
  selectedTankNo: "",
  selectedTankName: "",
  selectedUnitNo: "",
  selectedUnitName: "",
  selectedProductID: "",
  customers: defaultCustomerList,
  locations: defaultLocationList,
  tanks: defaultTankList,
  inboundUser: false,
  turnCarsUser: false,
  railcarUser: false,
  railcarTerminal: false,
} as IFilterBandState;

const filterBandSlice = createSlice({
  name: "filter",
  initialState,
  reducers: {
    setSelectedCustomer(state, action) {
      state.selectedCustomerNo = action.payload.customerNo;
      state.selectedCustomerName = action.payload.customerName;
    },
    setSelectedLocation(state, action) {
      state.selectedLocationNo = action.payload.locationNo;
      state.selectedLocationName = action.payload.locationName;
    },
    setSelectedTank(state, action) {
      state.selectedTankNo = action.payload.tankNo;
      state.selectedTankName = action.payload.tankName;
    },
    setSelectedUnit(state, action) {
      state.selectedUnitNo = action.payload.unitNo;
      state.selectedUnitName = action.payload.unitName;
    },
    setSelectedProduct(state, action) {
      state.selectedProductID = action.payload.productId;
    },
    setCustomerList(state, action: PayloadAction<Customer[]>) {
      state.customers = action.payload;
    },
    setLocationList(state, action: PayloadAction<LocationInfo[]>) {
      state.locations = action.payload;
    },
    setTankList(state, action: PayloadAction<TankByLocation[]>) {
      state.tanks = action.payload;
    },
    setInboundUserFlag(state, action) {
      state.inboundUser = action.payload.inboundUser;
    },
    setTurnCarsUserFlag(state, action) {
      state.turnCarsUser = action.payload.turnCarsUser;
    },
    setRailcarUserFlag(state, action) {
      state.railcarUser = action.payload.railcarUser;
    },
    setRailcarTerminalFlag(state, action) {
      state.railcarTerminal = action.payload.railcarTerminal;
    },
    removeSelectedCustomer(state) {
      state.selectedCustomerNo = "";
      state.selectedCustomerName = "";
      state.selectedLocationNo = "";
      state.selectedLocationName = "";
      state.selectedTankNo = "";
      state.selectedTankName = "";
      state.selectedUnitNo = "";
      state.selectedUnitName = "";
      state.selectedProductID = "";
      state.locations = defaultLocationList;
      state.tanks = defaultTankList;
      state.inboundUser = false;
      state.turnCarsUser = false;
      state.railcarUser = false;
      state.railcarTerminal = false;
    },
    removeSelectedLocation(state) {
      state.selectedLocationNo = "";
      state.selectedLocationName = "";
      state.selectedTankNo = "";
      state.selectedTankName = "";
      state.selectedUnitNo = "";
      state.selectedUnitName = "";
      state.selectedProductID = "";
      state.tanks = defaultTankList;
      state.railcarTerminal = false;
    },
    removeSelectedTank(state) {
      state.selectedTankNo = "";
      state.selectedTankName = "";
      state.selectedUnitNo = "";
      state.selectedUnitName = "";
      state.selectedProductID = "";
    },
    removeSelectedProduct(state) {
      state.selectedProductID = "";
    },
    removeCustomerFlags(state) {
      state.inboundUser = false;
      state.turnCarsUser = false;
      state.railcarUser = false;
    },
    removeRailcarTerminalFlag(state) {
      state.railcarTerminal = false;
    },
  },
});

export const filterBandActions = filterBandSlice.actions;

export default filterBandSlice;
