import { styled } from '@mui/system'
import { Box, Grid,CardContent } from '@mui/material';
import { CommonFontFamily,FontColorLabelItem, FontColorDetailItem, FontSizeLabelItem, FontSizeDetailItem } from '../../utils/constant/constant';


export const LabelItem = styled(Grid)(() => ({    
    textAlign: 'left',
    color: FontColorLabelItem,
    fontSize: FontSizeLabelItem,
    fontWeight: '550',
    fontFamily:CommonFontFamily,
    lineHeight:'1.75'
  }));
  
  export const DetailItem = styled(Grid)(() => ({    
    textAlign: 'left',
    fontSize: FontSizeDetailItem,
    color: FontColorDetailItem,
    fontFamily:CommonFontFamily,  
}));


export const SiteContentBox = styled(Box)(({ theme}) => ({    
    flexGrow: 1, 
    paddingTop:12,
    paddingLeft:12,
    paddingRight:12,
    //backgroundColor: BgColorSiteContent,
        [theme.breakpoints.down('sm')]: {
          marginTop: 164,
        },
        [theme.breakpoints.between('sm', 'md')]: {
          marginTop: 164,
        },
        [theme.breakpoints.up('md')]: {
          marginTop: 116,
        },
        [theme.breakpoints.up('lg')]: {
          marginTop: 116
        }
}));

export const StyledCardContent = styled(CardContent)(({ theme}) => ({    
  paddingTop: 10,
  '&:last-child': {
    paddingBottom: 10
  }
}));

