import {
  Button,
  Card,
  CardContent,
  Grid,
  IconButton,
  TableBody,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import PageHeader from "../../shared/PageHeader";
import { StyledTableRow, StyledTableCell } from "../../controls/tablecontrols";
import ModeEditSharpIcon from "@mui/icons-material/ModeEditSharp";
import {
  AdditionalTableHeaderCell,
  TableHeaderCell,
} from "../../../models/common";
import useTable from "../../../hooks/useTable";
import ModalPopup from "../../shared/ModalPopup";
import AddEditUser from "./AddEditUser";
import UserNoticeDataService from "../../../services/userNoticeService";
import { NoticeUsers } from "../../../models/userNotice";
import { uiActions } from "../../../store/ui-slice";
import { useAppDispatch } from "../../../store/store";
import CreateUser from "./CreateUser";
import { Link } from "react-router-dom";

interface Props {}

function UserAdmin(props: Props) {
  const dispatch = useAppDispatch();
  const [userList, setUserList] = useState<NoticeUsers[]>([]);
  const [emailId, setEmailId] = useState<string>("");
  const [userID, setUserID] = useState<number>(0);
  const [userName, setUserName] = useState<string>("");
  const [showEditModalPopup, setShowEditModalPopup] = useState<boolean>(false);
  const [showAddModalPopup, setShowAddModalPopup] = useState<boolean>(false);
  const [isRefreshList, setRefreshList] = useState<boolean>(false);
  const [emailIDSearch, setemailIDSearch] = useState<string>("");
  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [showTable, setShowTable] = useState<string>("none");

  const tableHeaderCellDetails: TableHeaderCell[] = [
    { id: "vU_FIRST_NAME", label: "First Name" },
    { id: "vU_LAST_NAME", label: "Last Name" },
    { id: "vU_EMAIL", label: "Email" },
    { id: "edit", label: "Edit", disableSorting: true },
  ];

  const additionalHeaderDetails: AdditionalTableHeaderCell[] = [
    {
      id: "listOfUsers",
      label: "List of Users",
      colSpan: 4,
      align: "center",
    },
  ];

  const { Tbl, GetRowsAfterPagingAndSorting } = useTable<NoticeUsers>(
    userList,
    tableHeaderCellDetails,
    true,
    10,
    additionalHeaderDetails
  );

  const { fetchData: fetchUsers } =
    UserNoticeDataService.RetrieveUserDetailsByNameOrEmail(
      emailIDSearch,
      firstName,
      lastName
    );

  const handleEdit = (userID: number, email: string, userName: string) => {
    Promise.resolve().then(() => {
      setUserID(userID);
      setEmailId(email);
      setUserName(userName);
      setShowEditModalPopup(true);
      setRefreshList(false);
    });
  };

  const onEditModalClose = (e: React.MouseEvent<HTMLButtonElement>) => {
    Promise.resolve().then(() => {
      setUserID(0);
      setEmailId("");
      setUserName("");
      setShowEditModalPopup(false);
      setRefreshList(false);
      dispatch(uiActions.stopProgressBar());
    });
  };

  useEffect(() => {
    if (isRefreshList) {
      setFirstName("");
      setLastName("");
      setemailIDSearch("");
      setUserList([]);
      setShowTable("none");
    }
  }, [isRefreshList]);

  const onFirstNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFirstName(event.target.value);
  };

  const onLastNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLastName(event.target.value);
  };

  const onEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setemailIDSearch(event.target.value);
  };

  function onSearchClick() {
    if (firstName !== "" || lastName !== "" || emailIDSearch !== "") {
      setUserList([]);
      fetchUsers().then((res) => {
        setUserList(res.responseData);
        if (res.responseData.length === 0) {
          setShowTable("none");
          dispatch(
            uiActions.logErrorNotification({
              message: "No Data Found",
              severity: "error",
            })
          );
          return;
        }
        setShowTable("block");
      });
    } else {
      setUserList([]);
      setShowTable("none");
      dispatch(
        uiActions.logErrorNotification({
          message: "Provide atleast one search criteria",
          severity: "error",
        })
      );
    }
  }

  const onAddModalClose = (e: React.MouseEvent<HTMLButtonElement>) => {
    Promise.resolve().then(() => {
      setShowAddModalPopup(false);
      setRefreshList(false);
    });
  };

  function onAddUserClick() {
    setShowAddModalPopup(true);
  }

  return (
    <React.Fragment>
      <PageHeader title="User Admin"></PageHeader>
      {
        <Card
          sx={{ width: "100hv", borderRadius: 3, margin: 2, mt: 0 }}
          elevation={6}
        >
          <CardContent sx={{ pb: 0 }}>
            <Grid container columnSpacing={{ xs: 1, sm: 1, md: 4, lg: 4 }}>
              <Grid item xs={12} sm={6} md={4} lg={2}>
                <TextField
                  name="firstName"
                  label="First Name"
                  value={firstName}
                  fullWidth
                  onChange={onFirstNameChange}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={2}>
                <TextField
                  name="lastName"
                  value={lastName}
                  label="Last Name"
                  fullWidth
                  onChange={onLastNameChange}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <TextField
                  name="email"
                  value={emailIDSearch}
                  label="Email ID"
                  fullWidth
                  onChange={onEmailChange}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={8} lg={5} sx={{ pt: "5px" }}>
                <Grid container>
                  <Grid item xs={4} sm={4} md={3} lg={3}>
                    <Button
                      type="submit"
                      sx={{ my: 0.5 }}
                      onClick={onSearchClick}
                    >
                      Search
                    </Button>
                  </Grid>
                  <Grid item xs={4} sm={4} md={4} lg={4}>
                    <Button
                      type="submit"
                      sx={{ my: 0.5 }}
                      onClick={onAddUserClick}
                    >
                      Add New User
                    </Button>
                  </Grid>
                  <Grid item xs={4} sm={4} md={4} lg={4}>
                    <Link to="/securityReport">
                      <Button type="submit" sx={{ my: 0.5 }}>
                        Security Report
                      </Button>
                    </Link>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              sx={{ mt: "15px", display: showTable }}
            >
              <Tbl>
                <TableBody>
                  {GetRowsAfterPagingAndSorting().map((row, index) => (
                    <StyledTableRow key={index}>
                      <StyledTableCell scope="row">
                        {row.vU_FIRST_NAME}
                      </StyledTableCell>
                      <StyledTableCell scope="row">
                        {row.vU_LAST_NAME}
                      </StyledTableCell>
                      <StyledTableCell scope="row">
                        {row.vU_EMAIL}
                      </StyledTableCell>
                      <StyledTableCell scope="row">
                        <IconButton
                          aria-label="Edit"
                          size="small"
                          sx={{ paddingLeft: 1, paddingRight: 1 }}
                          onClick={(e) =>
                            handleEdit(
                              row.vU_USER_ID,
                              row.vU_EMAIL,
                              row.vU_FIRST_NAME + " " + row.vU_LAST_NAME
                            )
                          }
                        >
                          <ModeEditSharpIcon fontSize="small" />
                        </IconButton>
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Tbl>
            </Grid>
          </CardContent>
        </Card>
      }
      <ModalPopup
        open={showEditModalPopup}
        title={"Edit User Security"}
        onPopupClose={onEditModalClose}
        width="1000px"
      >
        <AddEditUser
          email={emailId}
          userName={userName}
          userID={userID}
          setShowModalPopup={setShowEditModalPopup}
          setRefreshList={setRefreshList}
        />
      </ModalPopup>
      <ModalPopup
        open={showAddModalPopup}
        title={"Create New User Account"}
        onPopupClose={onAddModalClose}
        // width="1000px"
      >
        <CreateUser
          setShowModalPopup={setShowAddModalPopup}
          setRefreshList={setRefreshList}
        ></CreateUser>
      </ModalPopup>
    </React.Fragment>
  );
}

export default UserAdmin;
