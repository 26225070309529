import LocationList from "../components/app/Location/LocationList";
import SystemMessage from "./SystemMessage";

function LocationMaintenancePage() {
  const role = sessionStorage.getItem("roles");
  return (
    role?.toString().toUpperCase() === "ADMIN" ?
      <LocationList></LocationList> : <SystemMessage msg="Access Denied." colorMsg="Red"></SystemMessage>
  );
}

export default LocationMaintenancePage