/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useCallback } from "react";
import {
  Grid,
  TextField,
  Button,
  Container,
  Divider,
  CardContent,
  Card,
  Checkbox,
  FormControlLabel,
  MenuItem,
  CardHeader,
  List,
  ListItemIcon,
  ListItem,
  ListItemText,
} from "@mui/material";
import CustomCardHeader from "../../shared/CustomCardHeader";
import Stack from "@mui/material/Stack";
import { useFormik } from "formik";
import * as yup from "yup";

// Services
import LocationDataService from "../../../services/locationService";
import OrderDataService from "../../../services/orderService";
// Models
import {
  EmailContacts,
  ProductLocation,
  SaveContacts,
  UpdateProductLocationData,
} from "../../../models/location";
import { FormatNumber } from "../../shared/CommonFunctions";
import { uiActions } from "../../../store/ui-slice";
import { useAppDispatch } from "../../../store/store";
import ConfirmationMessage from "../../shared/ConfirmationMessage";

interface EditProductLocationProps {
  setshowProductModalPopup: React.Dispatch<React.SetStateAction<boolean>>;
  productID: number;
  locationID: number;
  locationName: string;
}

function not(a: readonly number[], b: readonly number[]) {
  return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a: readonly number[], b: readonly number[]) {
  return a.filter((value) => b.indexOf(value) !== -1);
}

function EditProductLocation(props: EditProductLocationProps) {
  const dispatch = useAppDispatch();
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const [checked, setChecked] = useState<readonly number[]>([]);
  const [unassigned, setUnassigned] = useState<readonly number[]>([]);
  const [assigned, setAssigned] = useState<readonly number[]>([]);
  const [emailContats, setEmailContacts] = useState<EmailContacts[]>([]);
  const [emailUsers, setEmailUsers] = useState<readonly number[]>([]);
  const [saveContacts, setSaveContacts] = useState<SaveContacts>(
    LocationDataService.GetDefaultContactInfo()
  );
  const [productLocationAPI, setProductLocationAPI] =
    useState<UpdateProductLocationData>(
      LocationDataService.GetDefaultProductLocation()
    );
  const [isConfirmProceed, setIsConfirmProceed] = useState<boolean>(false);
  const [openConfirmation, setOpenConfirmation] =
    React.useState<boolean>(false);
  const [confirmMessage, setConfirmMessage] = useState<string>("");

  const unassignedChecked = intersection(checked, unassigned);
  const assignedChecked = intersection(checked, assigned);

  const [initialLoad, setinitialLoad] = useState(false);

  let objOrderSchedule = [
    { id: "0", value: "0" },
    { id: "1", value: "1" },
    { id: "2", value: "2" },
    { id: "3", value: "3" },
    { id: "4", value: "4" },
    { id: "5", value: "5" },
    { id: "6", value: "6" },
    { id: "7", value: "7" },
    { id: "8", value: "8" },
    { id: "9", value: "9" },
  ];
  let objOperatingDays = [
    { id: "4", value: "4" },
    { id: "4.5", value: "4.5" },
    { id: "5", value: "5" },
    { id: "5.5", value: "5.5" },
    { id: "6", value: "6" },
    { id: "6.5", value: "6.5" },
    { id: "7", value: "7" },
  ];

  const validationSchema = yup.object({
    vpL_ORDER_REORDER_QTY: yup
      .number()
      .typeError("The value supplied for Reorder Quantity must be a number")
      .test(
        "testReorder",
        `Reorder Quantity must be greater than average daily usage.`,
        function (vpL_ORDER_REORDER_QTY: number | undefined): boolean {
          return vpL_ORDER_REORDER_QTY
            ? vpL_ORDER_REORDER_QTY > formik.values.vpL_AVG_DAILY_USAGE
            : true;
        }
      )
      .test(
        "testReorderCapacity",
        `The value of Reorder Quantity must be less than the product capacity of the location.`,
        function (): boolean {
          return formik.values.vpL_ORDER_REORDER_QTY >= formik.values.capacity
            ? false
            : true;
        }
      )
      .when("vpL_ORDER_GENERATION", {
        is: true,
        then: yup
          .number()
          .typeError("The value supplied for Reorder Quantity must be a number")
          .required(
            "A value for Reorder Quantity must be supplied when subscribing to Order Generation"
          ),
      }),
    vpL_REPORT_TIME: yup
      .number()
      .typeError("Value must an integer")
      .test(
        "testReportTime",
        `Value must an integer between 0 and 23`,
        function (vpL_REPORT_TIME: number | undefined): boolean {
          return vpL_REPORT_TIME
            ? vpL_REPORT_TIME >= 0 && vpL_REPORT_TIME <= 23
            : true;
        }
      ),
    vpL_REPORT_TIME_WEEKLY: yup
      .number()
      .typeError("Value must an integer")
      .test(
        "testReportTimeWeekly",
        `Value must an integer between 0 and 23`,
        function (vpL_REPORT_TIME_WEEKLY: number | undefined): boolean {
          return vpL_REPORT_TIME_WEEKLY
            ? vpL_REPORT_TIME_WEEKLY >= 0 && vpL_REPORT_TIME_WEEKLY <= 23
            : true;
        }
      ),
    vpL_ORDER_COPC: yup
      .string()
      .nullable(true)
      .when("vpL_ORDER_GENERATION", {
        is: true,
        then: yup
          .string()
          .nullable(true)
          .required("A value for COPC must be supplied"),
      }),
    vpL_ORDER_CONTAINER_CODE: yup
      .string()
      .nullable(true)
      .when("vpL_ORDER_GENERATION", {
        is: true,
        then: yup
          .string()
          .nullable(true)
          .required(
            "A value for Container Code must be supplied when subscribing to Order Generation"
          ),
      }),
    vpL_ORDER_MSTR_CUST_NO: yup
      .string()
      .nullable(true)
      .when("vpL_ORDER_GENERATION", {
        is: true,
        then: yup
          .string()
          .nullable(true)
          .required(
            "A value for Master Customer must be supplied when subscribing to Order Generation"
          ),
      }),
    vpL_ORDER_POINT_DAYS: yup
      .number()
      .typeError("The value supplied for Order Point Days must be a number")
      .nullable(true),
    vpL_ORDER_POINT_PERCENT: yup
      .number()
      .typeError("A numeric value must be supplied for Order Point Percent")
      .required("A numeric value must be supplied for Order Point Percent")
      .test(
        "testorderPointPercent",
        `An Order Point Percent value greater than 0 must be supplied when subscribing to Order Estimation or Generation`,
        function (vpL_ORDER_POINT_PERCENT: number | undefined): boolean {
          if (
            formik.values.vpL_ORDER_ESTIMATION === true ||
            formik.values.vpL_ORDER_GENERATION === true
          ) {
            return String(vpL_ORDER_POINT_PERCENT) === "" ||
              Number(vpL_ORDER_POINT_PERCENT) === 0
              ? false
              : true;
          }
          return true;
        }
      ),

    vpL_ORDER_LEAD_DAYS: yup
      .number()
      .typeError("The value supplied for Lead Days must be a number"),
    vpL_ORDER_TRAVEL_DAYS: yup
      .number()
      .typeError("The value supplied for Travel Days must be a number"),
    vpL_ORDER_MAX_SHIP_QTY: yup
      .number()
      .typeError(
        "The value supplied for Maximum Load Quantity must be a number"
      ),
    vpL_ORDER_MIN_SHIP_PCT: yup
      .number()
      .typeError(
        "The value supplied for Minimum Load Percent must be a number"
      ),
  });

  const formik = useFormik({
    initialValues: LocationDataService.GetDefaultProductLocation(),
    validationSchema: validationSchema,
    enableReinitialize: true,

    onSubmit: (values: ProductLocation) => {
      let Valid = checkCapacity();
      if (Valid) {
        if (values.vpL_ORDER_GENERATION === true) {
          verifyOrderSplit();
        } else {
          assignValues();
        }
      }
    },
  });

  const { fetchData: fetchProductLocationInfo } =
    LocationDataService.RetrieveProductLocationInfo(
      props.productID,
      props.locationID
    );

  const { fetchData: fetchEmailContactsInfo } =
    LocationDataService.RetrieveContactEmails(
      props.productID,
      props.locationID
    );

  const { putData: updateAssignedContacts } =
    LocationDataService.UpdateProductLocationContacts(
      props.productID,
      props.locationID,
      saveContacts
    );

  const { putData: updateProductLocationData } =
    LocationDataService.UpdateProductLocation(
      props.productID,
      props.locationID,
      productLocationAPI
    );

  const { fetchData: fetchOrderSplit } =
    OrderDataService.RetrieveVerifyOrderSplit(
      formik.values.vpL_ORDER_MAX_SHIP_QTY,
      formik.values.vpL_ORDER_MIN_SHIP_PCT,
      formik.values.vpL_ORDER_REORDER_QTY
    );

  const assignValues = () => {
    let formValues = formik.values;
    let apiValues: UpdateProductLocationData = {
      vpL_PRODUCT_ID: checkNull(formValues.vpL_PRODUCT_ID),
      vpL_LOCATION_ID: checkNull(formValues.vpL_LOCATION_ID),
      vpL_AVG_DAILY_USAGE_OVERRIDE: checkNull(
        formValues.vpL_AVG_DAILY_USAGE_OVERRIDE
      ),
      vpL_REPORT_TIME: checkNull(formValues.vpL_REPORT_TIME),
      vpL_REPORT_TIME_WEEKLY: checkNull(formValues.vpL_REPORT_TIME_WEEKLY),
      vpL_ORDER_POINT_PERCENT: checkNull(formValues.vpL_ORDER_POINT_PERCENT),
      vpL_ORDER_POINT_DAYS: checkNull(formValues.vpL_ORDER_POINT_DAYS),
      vpL_ORDER_REORDER_QTY: checkNull(formValues.vpL_ORDER_REORDER_QTY),
      vpL_ORDER_MAX_SHIP_QTY: checkNull(formValues.vpL_ORDER_MAX_SHIP_QTY),
      vpL_ORDER_MIN_SHIP_PCT: checkNull(formValues.vpL_ORDER_MIN_SHIP_PCT),
      vpL_ORDER_COPC: checkNull(formValues.vpL_ORDER_COPC),
      vpL_ORDER_LEAD_DAYS: checkNull(formValues.vpL_ORDER_LEAD_DAYS),
      vpL_ORDER_TRAVEL_DAYS: checkNull(formValues.vpL_ORDER_TRAVEL_DAYS),
      vpL_ORDER_MSTR_CUST_NO: checkNull(formValues.vpL_ORDER_MSTR_CUST_NO),
      vpL_ORDER_MSTR_SHIP_CODE: checkNull(formValues.vpL_ORDER_MSTR_SHIP_CODE),
      vpL_ORDER_CONTAINER_CODE: checkNull(formValues.vpL_ORDER_CONTAINER_CODE),
      vpL_ORDER_ESTIMATION: checkNull(formValues.vpL_ORDER_ESTIMATION),
      vpL_ORDER_GENERATION: checkNull(formValues.vpL_ORDER_GENERATION),
      strOrderContacts: checkNull(""),
      vpL_WKND_FLG: checkNull(formValues.vpL_WKND_FLG),
      vpL_RAIL_USER: checkNull(formValues.vpL_RAIL_USER),
      vpL_ACPT_OVERFLOW: checkNull(formValues.vpl_acpt_overflow),
      vpL_PROD_SCHEDULE_USER: checkNull(formValues.vpL_PROD_SCHEDULE_USER),
      vpL_UOM: checkNull(formValues.vpL_UOM),
      vpL_NUMBER_OF_CARS: checkNull(formValues.vpL_NUMBER_OF_CARS),
      vpL_IBM_SYNC: checkNull(formValues.vpL_IBM_SYNC),
      vpL_MOBILE_PUBLIC: checkNull(formValues.vpL_MOBILE_PUBLIC),
      vpL_MOBILE_PRIVATE: checkNull(formValues.vpL_MOBILE_PRIVATE),
      vpL_ARRIVAL_DT_FLG: checkNull(formValues.vpL_ARRIVAL_DT_FLG),
      vpL_PR_CODE: checkNull(formValues.vpL_PR_CODE),
      vpL_ARRIVAL_DATE_ADJUSTMENT: checkNull(
        formValues.vpL_ARRIVAL_DATE_ADJUSTMENT
      ),
      vpL_HIGH_INVENTORY_ARRIVAL_WARNING: checkNull(
        formValues.vpL_HIGH_INVENTORY_ARRIVAL_WARNING
      ),
      vpL_ADU_RANGE_WARNING: checkNull(formValues.vpL_ADU_RANGE_WARNING),
      vpL_ADU_RANGE_MIN: checkNull(formValues.vpL_ADU_RANGE_MIN),
      vpL_ADU_RANGE_MAX: checkNull(formValues.vpL_ADU_RANGE_MAX),
      vpL_WEEK_AVG_DISPLAY: checkNull(formValues.vpL_WEEK_AVG_DISPLAY),
      vpL_OPERATING_DAYS: checkNull(formValues.vpL_OPERATING_DAYS),
    };
    setProductLocationAPI(apiValues);
  };

  const checkNull = (value: any) => {
    if (value !== null && String(value) !== "") {
      return value;
    } else {
      return null;
    }
  };

  const checkCapacity = (): boolean => {
    const formValues = formik.values;
    let i =
      (parseInt(String(formValues.vpL_ORDER_POINT_DAYS).trim()) +
        parseInt(String(formValues.vpL_ORDER_LEAD_DAYS).trim()) +
        parseInt(String(formValues.vpL_ORDER_TRAVEL_DAYS).trim())) *
      parseInt(String(formValues.vpL_AVG_DAILY_USAGE).trim());
    if (parseFloat(String(i)) >= 0.95 * formValues.capacity) {
      dispatch(
        uiActions.displayNotification({
          message:
            "The sum of Order Point, Lead & Travel Days multiplied by Average Daily Usage will exceed 95% of the Product Capacity at the Location (current total capacity: " +
            formValues.capacity +
            " lbs).",
          severity: "error",
        })
      );
      return false;
    }
    return true;
  };

  const handleConfirm = (e: React.MouseEvent<HTMLButtonElement>) => {
    Promise.resolve().then(() => {
      setOpenConfirmation(false);
      setIsConfirmProceed(true);
    });
  };

  const handleCancel = (e: React.MouseEvent<HTMLButtonElement>) => {
    Promise.resolve().then(() => {
      setOpenConfirmation(false);
      setIsConfirmProceed(false);
    });
  };

  async function verifyOrderSplit() {
    const formValues = formik.values;
    const response = await fetchOrderSplit();
    if (response.isSuccess === true) {
      let sQty, sAmt, sX, msg;
      sQty = response.responseData.vchQTY;
      sAmt = response.responseData.vchAMT;
      sX = response.responseData.vchMSG;
      if (parseInt(sQty) > 1 || sX.trim() !== "") {
        msg = `The existing relationship across the values for `;
        msg =
          msg + `Reorder Quantity (` + formValues.vpL_ORDER_REORDER_QTY + `), `;
        msg =
          msg +
          `Maximum Shipment Quantity (` +
          formValues.vpL_ORDER_MAX_SHIP_QTY +
          `) `;
        msg = msg + `and `;
        msg =
          msg +
          `Minimum Shipment Percent (` +
          formValues.vpL_ORDER_MIN_SHIP_PCT +
          `%) `;
        msg =
          msg +
          `will generate ` +
          sQty.trim() +
          ` order(s) of ` +
          sAmt.trim() +
          ` lbs`;
        if (sX.trim() !== "")
          msg =
            msg +
            ` with a remainder of ` +
            parseInt(sX.trim()) +
            ` lbs that will not justify an additional order`;
        msg = msg + `. Do you wish to continue with these settings?`;
        setConfirmMessage(msg);
        setOpenConfirmation(true);
        return false;
      } else {
        assignValues();
      }
    } else {
      dispatch(
        uiActions.displayNotification({
          message: "An error occurred while verifying split order parameters.",
          severity: "error",
        })
      );
      return false;
    }
  }

  useEffect(() => {
    if (isConfirmProceed === true) {
      assignValues();
      setIsConfirmProceed(false);
    }
  }, [isConfirmProceed]);

  const handleToggle = (value: number) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const numberOfChecked = (items: readonly number[]) =>
    intersection(checked, items).length;

  const handleCheckedRight = () => {
    setAssigned(
      assigned
        .concat(unassignedChecked)
        .sort((a, b) => emailUsers.indexOf(a) - emailUsers.indexOf(b))
    );
    setUnassigned(not(unassigned, unassignedChecked));
    setChecked(not(checked, unassignedChecked));
  };

  const handleCheckedLeft = () => {
    setUnassigned(
      unassigned
        .concat(assignedChecked)
        .sort((a, b) => emailUsers.indexOf(a) - emailUsers.indexOf(b))
    );
    setAssigned(not(assigned, assignedChecked));
    setChecked(not(checked, assignedChecked));
  };

  const customList = (
    title: React.ReactNode,
    items: readonly number[],
    contacts: EmailContacts[]
  ) => (
    <Card>
      <CardHeader
        sx={{ px: 2, py: 1 }}
        title={title}
        subheader={`${numberOfChecked(items)}/${items.length} selected`}
      />
      <Divider />
      <List
        sx={{
          width: { xs: 180, sm: 180, md: 250, lg: 250 },
          height: 230,
          bgcolor: "background.paper",
          overflow: "auto",
        }}
        dense
        component="div"
        role="list"
      >
        {items.map((value: number) => {
          const labelId = `${value}`;
          const contact = contacts.find((row) => row.vu_user_id === value) || {
            vu_first_name: "",
            vu_last_name: "",
          };
          return (
            <ListItem key={value} role="listitem" onClick={handleToggle(value)}>
              <ListItemIcon>
                <Checkbox
                  checked={checked.indexOf(value) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{
                    "aria-labelledby": labelId,
                  }}
                />
              </ListItemIcon>
              <ListItemText
                id={labelId}
                primary={contact.vu_last_name + ", " + contact.vu_first_name}
              />
            </ListItem>
          );
        })}
      </List>
    </Card>
  );

  useEffect(() => {
    if (props.productID && props.locationID) {
      fetchProductLocationInfo().then((res) => {
        formik.setValues(res.responseData);
        setinitialLoad(true);
      });
      fetchEmailContactsInfo().then((res) => {
        sortData(res.responseData);
        setEmailContacts(res.responseData);
      });
    }
  }, [props.productID, props.locationID]);

  useEffect(() => {
    if (initialLoad === true) {
      let formValues = formik.values;
      let calculatedValue;
      if (formValues.vpL_UOM === "MT") {
        calculatedValue = FormatNumber(
          formValues.vpL_AVG_DAILY_USAGE / 2204,
          3
        );
      } else {
        calculatedValue = FormatNumber(formValues.vpL_AVG_DAILY_USAGE, 3);
      }
      formik.setFieldValue("averageDailyUsage", calculatedValue);
      setinitialLoad(false);
    }
  }, [initialLoad]);

  useEffect(() => {
    if (
      saveContacts.vpL_PRODUCT_ID !== 0 &&
      saveContacts.vpL_LOCATION_ID !== null
    ) {
      dispatch(uiActions.startProgressBar());
      updateAssignedContacts().then((res) => {
        if (res.isSuccess) {
          dispatch(
            uiActions.displayNotification({
              message: "Email Contacts updated successfully!",
              severity: "success",
            })
          );
          props.setshowProductModalPopup(false);
        }
      });
    }
  }, [saveContacts]);

  useEffect(() => {
    if (isLoaded) {
      if (
        productLocationAPI.vpL_PRODUCT_ID !== 0 &&
        productLocationAPI.vpL_LOCATION_ID !== null
      ) {
        dispatch(uiActions.startProgressBar());
        updateProductLocationData().then((res) => {
          if (res.isSuccess) {
            dispatch(
              uiActions.displayNotification({
                message: "Product Location updated successfully!",
                severity: "success",
              })
            );
            props.setshowProductModalPopup(false);
          }
        });
      }
    } else {
      setIsLoaded(true);
    }
  }, [productLocationAPI]);

  const sortData = (contacts: EmailContacts[]) => {
    let emailUsers = [],
      assigned = [],
      unassigned = [];
    if (contacts.length > 0) {
      for (let i = 0; i < contacts.length; i++) {
        emailUsers.push(contacts[i].vu_user_id);
        if (contacts[i].iS_CONTACT !== 0) {
          assigned.push(contacts[i].vu_user_id);
        } else {
          unassigned.push(contacts[i].vu_user_id);
        }
      }
    }
    setEmailUsers(emailUsers);
    setAssigned(assigned);
    setUnassigned(unassigned);
  };

  const onSaveClick = () => {
    let assignedString: string = "";
    assigned.forEach((value) => {
      assignedString += value + ",";
    });
    assignedString = assignedString.substring(assignedString.length - 1, 0);
    let obj: SaveContacts = {
      vpL_PRODUCT_ID: props.productID,
      vpL_LOCATION_ID: props.locationID,
      orderContactIDs: assignedString,
    };
    setSaveContacts(obj);
  };

  useEffect(() => {
    if (formik.values.vpL_ORDER_ESTIMATION === true) {
      formik.setFieldValue("vpL_ORDER_GENERATION", false);
    }
  }, [formik.values.vpL_ORDER_ESTIMATION]);

  useEffect(() => {
    if (formik.values.vpL_ORDER_GENERATION === true) {
      formik.setFieldValue("vpL_ORDER_ESTIMATION", false);
    }
  }, [formik.values.vpL_ORDER_GENERATION]);

  const onWkndFlgChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValues("vpL_WKND_FLG", event.target.checked === true ? "Y" : "N");
  };

  const onIBMSyncChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValues("vpL_IBM_SYNC", event.target.checked === true ? "1" : "0");
  };

  const onArrivalDtFlagChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setInputValues(
      "vpL_ARRIVAL_DT_FLG",
      event.target.checked === true ? "1" : "0"
    );
  };

  const setInputValues = useCallback(
    (key, value) =>
      formik.setValues({
        ...formik.values,
        [key]: value,
      }),
    [formik]
  );

  return (
    <React.Fragment>
      <Container>
        <form onSubmit={formik.handleSubmit} id="form1" className="form">
          <Card sx={{ borderRadius: 1 }} elevation={3}>
            <CustomCardHeader title={props.locationName}></CustomCardHeader>
            <CardContent>
              <Grid container columnSpacing={{ xs: 1, sm: 1, md: 4, lg: 4 }}>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6.5}
                  lg={6.5}
                  sx={{
                    borderRight: { md: "1px solid", lg: "1px solid" },
                    borderColor: { md: "lightgray", lg: "lightgray" },
                  }}
                >
                  <CardContent>
                    <Grid
                      container
                      columnSpacing={{ xs: 1, sm: 1, md: 4, lg: 4 }}
                    >
                      <Grid item xs={12} sm={12} md={6} lg={6}>
                        <Stack spacing={1} sx={{ paddingTop: 1 }}>
                          <span>
                            {formik.values.vP_PRODUCT_DESC}-(
                            {formik.values.vP_PRODUCT_CODE})
                          </span>
                        </Stack>
                      </Grid>
                      <Grid item xs={12} sm={12} md={6} lg={6}>
                        <span>Accept Weekend Shipments</span>
                        <Checkbox
                          name="vpL_WKND_FLG"
                          value={formik.values.vpL_WKND_FLG}
                          checked={
                            formik.values.vpL_WKND_FLG === "Y" ? true : false
                          }
                          onChange={onWkndFlgChange}
                          inputProps={{ "aria-label": "controlled" }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={6} lg={6}>
                        <TextField
                          id="uom"
                          name="vpL_UOM"
                          value={formik.values.vpL_UOM}
                          label="UOM"
                          fullWidth
                          select
                          error={
                            formik.touched.vpL_UOM &&
                            Boolean(formik.errors.vpL_UOM)
                          }
                          helperText={
                            formik.touched.vpL_UOM && formik.errors.vpL_UOM
                          }
                          onChange={formik.handleChange}
                        >
                          {formik.values.vpT_PRODUCT_TYPE === "SOLID" && (
                            <MenuItem key={1} value={"Bu"}>
                              Bu
                            </MenuItem>
                          )}
                          <MenuItem key={2} value={"LBS"}>
                            LBS
                          </MenuItem>
                          <MenuItem key={3} value={"MT"}>
                            MT
                          </MenuItem>
                        </TextField>
                      </Grid>
                      <Grid item xs={12} sm={12} md={6} lg={6}>
                        <span>Accept Tank Overflow</span>
                        <Checkbox
                          name="vpl_acpt_overflow"
                          value={formik.values.vpl_acpt_overflow}
                          checked={formik.values.vpl_acpt_overflow}
                          onChange={formik.handleChange}
                          inputProps={{ "aria-label": "controlled" }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={6} lg={6}>
                        <span>Rail User</span>
                        <Checkbox
                          name="vpL_RAIL_USER"
                          value={formik.values.vpL_RAIL_USER}
                          checked={formik.values.vpL_RAIL_USER}
                          onChange={formik.handleChange}
                          inputProps={{ "aria-label": "controlled" }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={6} lg={6}>
                        <span>IBM Sync</span>
                        <Checkbox
                          name="vpL_IBM_SYNC"
                          value={formik.values.vpL_IBM_SYNC}
                          checked={
                            formik.values.vpL_IBM_SYNC === "1" ? true : false
                          }
                          onChange={onIBMSyncChange}
                          inputProps={{ "aria-label": "controlled" }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={6} lg={6}>
                        <span>Prod. Schedule User</span>
                        <Checkbox
                          name="vpL_PROD_SCHEDULE_USER"
                          value={formik.values.vpL_PROD_SCHEDULE_USER}
                          checked={formik.values.vpL_PROD_SCHEDULE_USER}
                          onChange={formik.handleChange}
                          inputProps={{ "aria-label": "controlled" }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={6} lg={6}>
                        <span>Public Mobile</span>
                        <Checkbox
                          name="vpL_MOBILE_PUBLIC"
                          value={formik.values.vpL_MOBILE_PUBLIC}
                          checked={formik.values.vpL_MOBILE_PUBLIC}
                          onChange={formik.handleChange}
                          inputProps={{ "aria-label": "controlled" }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={6} lg={6}>
                        <span>RL-&gt;SE Arrival Update</span>
                        <Checkbox
                          name="vpL_ARRIVAL_DT_FLG"
                          value={formik.values.vpL_ARRIVAL_DT_FLG}
                          checked={
                            formik.values.vpL_ARRIVAL_DT_FLG === "1"
                              ? true
                              : false
                          }
                          onChange={onArrivalDtFlagChange}
                          inputProps={{ "aria-label": "controlled" }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={6} lg={6}>
                        <span>Private Mobile</span>
                        <Checkbox
                          name="vpL_MOBILE_PRIVATE"
                          value={formik.values.vpL_MOBILE_PRIVATE}
                          checked={formik.values.vpL_MOBILE_PRIVATE}
                          onChange={formik.handleChange}
                          inputProps={{ "aria-label": "controlled" }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <span>Allow Arrival Date Verification Adjustment</span>
                        <Checkbox
                          name="vpL_ARRIVAL_DATE_ADJUSTMENT"
                          value={formik.values.vpL_ARRIVAL_DATE_ADJUSTMENT}
                          checked={formik.values.vpL_ARRIVAL_DATE_ADJUSTMENT}
                          onChange={formik.handleChange}
                          inputProps={{ "aria-label": "controlled" }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <span>High Inventory Arrival Warning</span>
                        <Checkbox
                          name="vpL_HIGH_INVENTORY_ARRIVAL_WARNING"
                          value={
                            formik.values.vpL_HIGH_INVENTORY_ARRIVAL_WARNING
                          }
                          checked={
                            formik.values.vpL_HIGH_INVENTORY_ARRIVAL_WARNING
                          }
                          onChange={formik.handleChange}
                          inputProps={{ "aria-label": "controlled" }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={6} lg={6}>
                        <span>ADU Range Warning</span>
                        <Checkbox
                          name="vpL_ADU_RANGE_WARNING"
                          value={formik.values.vpL_ADU_RANGE_WARNING}
                          checked={formik.values.vpL_ADU_RANGE_WARNING}
                          onChange={formik.handleChange}
                          inputProps={{ "aria-label": "controlled" }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={3} lg={3}>
                        <TextField
                          name="vpL_ADU_RANGE_MIN"
                          label="Range Min"
                          value={formik.values.vpL_ADU_RANGE_MIN}
                          fullWidth
                          onChange={formik.handleChange}
                          error={
                            formik.touched.vpL_ADU_RANGE_MIN &&
                            Boolean(formik.errors.vpL_ADU_RANGE_MIN)
                          }
                          helperText={
                            formik.touched.vpL_ADU_RANGE_MIN &&
                            formik.errors.vpL_ADU_RANGE_MIN
                          }
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={3} lg={3}>
                        <TextField
                          name="vpL_ADU_RANGE_MAX"
                          label="Range Max"
                          value={formik.values.vpL_ADU_RANGE_MAX}
                          fullWidth
                          onChange={formik.handleChange}
                          error={
                            formik.touched.vpL_ADU_RANGE_MAX &&
                            Boolean(formik.errors.vpL_ADU_RANGE_MAX)
                          }
                          helperText={
                            formik.touched.vpL_ADU_RANGE_MAX &&
                            formik.errors.vpL_ADU_RANGE_MAX
                          }
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={6} lg={6}>
                        <TextField
                          id="vpL_WEEK_AVG_DISPLAY"
                          name="vpL_WEEK_AVG_DISPLAY"
                          value={formik.values.vpL_WEEK_AVG_DISPLAY}
                          label="Order Schedule Week Average"
                          fullWidth
                          select
                          onChange={formik.handleChange}
                          error={
                            formik.touched.vpL_WEEK_AVG_DISPLAY &&
                            Boolean(formik.errors.vpL_WEEK_AVG_DISPLAY)
                          }
                          helperText={
                            formik.touched.vpL_WEEK_AVG_DISPLAY &&
                            formik.errors.vpL_WEEK_AVG_DISPLAY
                          }
                        >
                          {objOrderSchedule.map((row, index) => {
                            return (
                              <MenuItem key={row.id} value={row.value}>
                                {row.value}
                              </MenuItem>
                            );
                          })}
                        </TextField>
                      </Grid>
                      <Grid item xs={12} sm={12} md={6} lg={6}>
                        <TextField
                          id="vpL_OPERATING_DAYS"
                          name="vpL_OPERATING_DAYS"
                          value={formik.values.vpL_OPERATING_DAYS}
                          label="Operating Days per Week"
                          fullWidth
                          select
                          onChange={formik.handleChange}
                          error={
                            formik.touched.vpL_OPERATING_DAYS &&
                            Boolean(formik.errors.vpL_OPERATING_DAYS)
                          }
                          helperText={
                            formik.touched.vpL_OPERATING_DAYS &&
                            formik.errors.vpL_OPERATING_DAYS
                          }
                        >
                          {objOperatingDays.map((row, index) => {
                            return (
                              <MenuItem key={row.id} value={row.value}>
                                {row.value}
                              </MenuItem>
                            );
                          })}
                        </TextField>
                      </Grid>
                      <Grid item xs={12} sm={12} md={6} lg={6}>
                        <TextField
                          name="vpL_REPORT_TIME"
                          label="Daily Report Time"
                          value={formik.values.vpL_REPORT_TIME}
                          fullWidth
                          onChange={formik.handleChange}
                          error={
                            formik.touched.vpL_REPORT_TIME &&
                            Boolean(formik.errors.vpL_REPORT_TIME)
                          }
                          helperText={
                            formik.touched.vpL_REPORT_TIME &&
                            formik.errors.vpL_REPORT_TIME
                          }
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={6} lg={6}>
                        <TextField
                          name="vpL_REPORT_TIME_WEEKLY"
                          label="Weekly Report Time"
                          value={formik.values.vpL_REPORT_TIME_WEEKLY}
                          fullWidth
                          onChange={formik.handleChange}
                          error={
                            formik.touched.vpL_REPORT_TIME_WEEKLY &&
                            Boolean(formik.errors.vpL_REPORT_TIME_WEEKLY)
                          }
                          helperText={
                            formik.touched.vpL_REPORT_TIME_WEEKLY &&
                            formik.errors.vpL_REPORT_TIME_WEEKLY
                          }
                        />
                      </Grid>
                    </Grid>
                  </CardContent>
                </Grid>
                <Grid item xs={12} sm={12} md={5.5} lg={5.5}>
                  <Grid
                    container
                    columnSpacing={{ xs: 1, sm: 1, md: 4, lg: 4 }}
                  >
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <CardContent>
                        <Grid item>
                          <FormControlLabel
                            value="start"
                            control={
                              <Checkbox
                                id="vpL_ORDER_ESTIMATION"
                                name="vpL_ORDER_ESTIMATION"
                                value={formik.values.vpL_ORDER_ESTIMATION}
                                checked={formik.values.vpL_ORDER_ESTIMATION}
                                onChange={formik.handleChange}
                                inputProps={{ "aria-label": "controlled" }}
                              />
                            }
                            label="Order Estimation"
                            labelPlacement="start"
                          />
                        </Grid>
                        <Grid item>
                          <TextField
                            name="averageDailyUsage"
                            label="Avg Daily Usage"
                            value={formik.values.averageDailyUsage}
                            fullWidth
                            onChange={formik.handleChange}
                            error={
                              formik.touched.averageDailyUsage &&
                              Boolean(formik.errors.averageDailyUsage)
                            }
                            helperText={
                              formik.touched.averageDailyUsage &&
                              formik.errors.averageDailyUsage
                            }
                            inputProps={{ readOnly: true }}
                          />
                        </Grid>
                        <Grid item>
                          <TextField
                            name="vpL_AVG_DAILY_USAGE_OVERRIDE"
                            label="Usage Override"
                            value={formik.values.vpL_AVG_DAILY_USAGE_OVERRIDE}
                            fullWidth
                            onChange={formik.handleChange}
                            // onBlur={onBlur}
                            error={
                              formik.touched.vpL_AVG_DAILY_USAGE_OVERRIDE &&
                              Boolean(
                                formik.errors.vpL_AVG_DAILY_USAGE_OVERRIDE
                              )
                            }
                            helperText={
                              formik.touched.vpL_AVG_DAILY_USAGE_OVERRIDE &&
                              formik.errors.vpL_AVG_DAILY_USAGE_OVERRIDE
                            }
                          />
                        </Grid>
                        <Grid item>
                          <TextField
                            name="vpL_ORDER_REORDER_QTY"
                            label="Reorder Qty"
                            value={formik.values.vpL_ORDER_REORDER_QTY}
                            fullWidth
                            onChange={formik.handleChange}
                            // onBlur={onBlur}
                            error={
                              formik.touched.vpL_ORDER_REORDER_QTY &&
                              Boolean(formik.errors.vpL_ORDER_REORDER_QTY)
                            }
                            helperText={
                              formik.touched.vpL_ORDER_REORDER_QTY &&
                              formik.errors.vpL_ORDER_REORDER_QTY
                            }
                          />
                        </Grid>
                        <Grid item>
                          <TextField
                            name="vpL_ORDER_POINT_PERCENT"
                            label="Order Point Pct"
                            value={formik.values.vpL_ORDER_POINT_PERCENT}
                            fullWidth
                            onChange={formik.handleChange}
                            error={
                              formik.touched.vpL_ORDER_POINT_PERCENT &&
                              Boolean(formik.errors.vpL_ORDER_POINT_PERCENT)
                            }
                            helperText={
                              formik.touched.vpL_ORDER_POINT_PERCENT &&
                              formik.errors.vpL_ORDER_POINT_PERCENT
                            }
                          />
                        </Grid>
                        <Grid item>
                          <TextField
                            name="vpL_ORDER_POINT_DAYS"
                            label="Order Point Days"
                            value={formik.values.vpL_ORDER_POINT_DAYS}
                            fullWidth
                            onChange={formik.handleChange}
                            error={
                              formik.touched.vpL_ORDER_POINT_DAYS &&
                              Boolean(formik.errors.vpL_ORDER_POINT_DAYS)
                            }
                            helperText={
                              formik.touched.vpL_ORDER_POINT_DAYS &&
                              formik.errors.vpL_ORDER_POINT_DAYS
                            }
                          />
                        </Grid>
                        <Grid item>
                          <TextField
                            name="vpL_ORDER_LEAD_DAYS"
                            label="Lead Days"
                            value={formik.values.vpL_ORDER_LEAD_DAYS}
                            fullWidth
                            onChange={formik.handleChange}
                            error={
                              formik.touched.vpL_ORDER_LEAD_DAYS &&
                              Boolean(formik.errors.vpL_ORDER_LEAD_DAYS)
                            }
                            helperText={
                              formik.touched.vpL_ORDER_LEAD_DAYS &&
                              formik.errors.vpL_ORDER_LEAD_DAYS
                            }
                          />
                        </Grid>
                        <Grid item>
                          <TextField
                            name="vpL_ORDER_TRAVEL_DAYS"
                            label="Travel Days"
                            value={formik.values.vpL_ORDER_TRAVEL_DAYS}
                            fullWidth
                            onChange={formik.handleChange}
                            error={
                              formik.touched.vpL_ORDER_TRAVEL_DAYS &&
                              Boolean(formik.errors.vpL_ORDER_TRAVEL_DAYS)
                            }
                            helperText={
                              formik.touched.vpL_ORDER_TRAVEL_DAYS &&
                              formik.errors.vpL_ORDER_TRAVEL_DAYS
                            }
                          />
                        </Grid>
                      </CardContent>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <CardContent>
                        <Grid item>
                          <FormControlLabel
                            value="start"
                            control={
                              <Checkbox
                                id="vpL_ORDER_GENERATION"
                                name="vpL_ORDER_GENERATION"
                                value={formik.values.vpL_ORDER_GENERATION}
                                checked={formik.values.vpL_ORDER_GENERATION}
                                onChange={formik.handleChange}
                                inputProps={{ "aria-label": "controlled" }}
                              />
                            }
                            label="Order Generation"
                            labelPlacement="start"
                          />
                        </Grid>
                        <Grid item>
                          <TextField
                            name="vpL_ORDER_COPC"
                            label="COPC"
                            value={formik.values.vpL_ORDER_COPC}
                            fullWidth
                            onChange={formik.handleChange}
                            error={
                              formik.touched.vpL_ORDER_COPC &&
                              Boolean(formik.errors.vpL_ORDER_COPC)
                            }
                            helperText={
                              formik.touched.vpL_ORDER_COPC &&
                              formik.errors.vpL_ORDER_COPC
                            }
                          />
                        </Grid>
                        <Grid item>
                          <TextField
                            name="vpL_ORDER_MSTR_CUST_NO"
                            label="Mstr Cust No"
                            value={formik.values.vpL_ORDER_MSTR_CUST_NO}
                            fullWidth
                            onChange={formik.handleChange}
                            error={
                              formik.touched.vpL_ORDER_MSTR_CUST_NO &&
                              Boolean(formik.errors.vpL_ORDER_MSTR_CUST_NO)
                            }
                            helperText={
                              formik.touched.vpL_ORDER_MSTR_CUST_NO &&
                              formik.errors.vpL_ORDER_MSTR_CUST_NO
                            }
                          />
                        </Grid>
                        <Grid item>
                          <TextField
                            name="vpL_ORDER_MSTR_SHIP_CODE"
                            label="Mstr Ship Code"
                            value={formik.values.vpL_ORDER_MSTR_SHIP_CODE}
                            fullWidth
                            onChange={formik.handleChange}
                            error={
                              formik.touched.vpL_ORDER_MSTR_SHIP_CODE &&
                              Boolean(formik.errors.vpL_ORDER_MSTR_SHIP_CODE)
                            }
                            helperText={
                              formik.touched.vpL_ORDER_MSTR_SHIP_CODE &&
                              formik.errors.vpL_ORDER_MSTR_SHIP_CODE
                            }
                          />
                        </Grid>
                        <Grid item>
                          <TextField
                            name="vpL_ORDER_CONTAINER_CODE"
                            label="Container Code"
                            value={formik.values.vpL_ORDER_CONTAINER_CODE}
                            fullWidth
                            onChange={formik.handleChange}
                            error={
                              formik.touched.vpL_ORDER_CONTAINER_CODE &&
                              Boolean(formik.errors.vpL_ORDER_CONTAINER_CODE)
                            }
                            helperText={
                              formik.touched.vpL_ORDER_CONTAINER_CODE &&
                              formik.errors.vpL_ORDER_CONTAINER_CODE
                            }
                          />
                        </Grid>
                        <Grid item>
                          <TextField
                            name="vpL_ORDER_MAX_SHIP_QTY"
                            label="Max Load Qty"
                            value={formik.values.vpL_ORDER_MAX_SHIP_QTY}
                            fullWidth
                            onChange={formik.handleChange}
                            error={
                              formik.touched.vpL_ORDER_MAX_SHIP_QTY &&
                              Boolean(formik.errors.vpL_ORDER_MAX_SHIP_QTY)
                            }
                            helperText={
                              formik.touched.vpL_ORDER_MAX_SHIP_QTY &&
                              formik.errors.vpL_ORDER_MAX_SHIP_QTY
                            }
                          />
                        </Grid>
                        <Grid item>
                          <TextField
                            name="vpL_ORDER_MIN_SHIP_PCT"
                            label="Min Load Pct"
                            value={formik.values.vpL_ORDER_MIN_SHIP_PCT}
                            fullWidth
                            onChange={formik.handleChange}
                            error={
                              formik.touched.vpL_ORDER_MIN_SHIP_PCT &&
                              Boolean(formik.errors.vpL_ORDER_MIN_SHIP_PCT)
                            }
                            helperText={
                              formik.touched.vpL_ORDER_MIN_SHIP_PCT &&
                              formik.errors.vpL_ORDER_MIN_SHIP_PCT
                            }
                          />
                        </Grid>
                        <Grid item>
                          <TextField
                            name="vpL_NUMBER_OF_CARS"
                            label="# Cars"
                            value={formik.values.vpL_NUMBER_OF_CARS}
                            fullWidth
                            onChange={formik.handleChange}
                            error={
                              formik.touched.vpL_NUMBER_OF_CARS &&
                              Boolean(formik.errors.vpL_NUMBER_OF_CARS)
                            }
                            helperText={
                              formik.touched.vpL_NUMBER_OF_CARS &&
                              formik.errors.vpL_NUMBER_OF_CARS
                            }
                          />
                        </Grid>
                        <Grid item>
                          <TextField
                            name="vpL_PR_CODE"
                            label="PR Code"
                            value={formik.values.vpL_PR_CODE}
                            fullWidth
                            onChange={formik.handleChange}
                            error={
                              formik.touched.vpL_PR_CODE &&
                              Boolean(formik.errors.vpL_PR_CODE)
                            }
                            helperText={
                              formik.touched.vpL_PR_CODE &&
                              formik.errors.vpL_PR_CODE
                            }
                          />
                        </Grid>
                      </CardContent>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                sx={{
                  justifyContent: "flex-end",
                  alignItems: "flex-end",
                  display: "flex",
                }}
              >
                <Button
                  form="form1"
                  type="submit"
                  onSubmit={(e) => formik.handleSubmit}
                >
                  Save
                </Button>
              </Grid>
            </CardContent>
          </Card>

          <Grid container columnSpacing={{ xs: 1, sm: 1, md: 4, lg: 4 }}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Card sx={{ borderRadius: 1 }} elevation={3}>
                <CustomCardHeader title="Email Contacts"></CustomCardHeader>
                <CardContent>
                  <Grid
                    container
                    spacing={2}
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Grid item>
                      {customList("Unassigned", unassigned, emailContats)}
                    </Grid>
                    <Grid item>
                      <Grid container direction="column" alignItems="center">
                        <Button
                          fullWidth
                          sx={{ my: 0.5 }}
                          size="small"
                          onClick={handleCheckedRight}
                          disabled={unassignedChecked.length === 0}
                          aria-label="move selected assigned"
                        >
                          Add &gt;&gt;
                        </Button>
                        <Button
                          fullWidth
                          sx={{ my: 0.5 }}
                          size="small"
                          onClick={handleCheckedLeft}
                          disabled={assignedChecked.length === 0}
                          aria-label="move selected unassigned"
                        >
                          &lt;&lt; Remove
                        </Button>
                      </Grid>
                    </Grid>
                    <Grid item>
                      {customList("Assigned", assigned, emailContats)}
                    </Grid>
                    <Divider sx={{ mt: 4, mb: 2 }}></Divider>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      sx={{
                        justifyContent: "flex-end",
                        alignItems: "flex-end",
                        display: "flex",
                      }}
                    >
                      <Button form="form2" type="submit" onClick={onSaveClick}>
                        Save Contacts
                      </Button>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
              <ConfirmationMessage
                title="SPLIT ORDER CONFIRMATION"
                open={openConfirmation}
                message={confirmMessage}
                onYesClick={handleConfirm}
                onNoClick={handleCancel}
              ></ConfirmationMessage>
            </Grid>
          </Grid>
        </form>
      </Container>
    </React.Fragment>
  );
}
export default EditProductLocation;
