/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { StyledTableCell, StyledTableRow } from "../../controls/tablecontrols";
import { Grid, Button, Container, Divider, Card, CardContent } from "@mui/material";
import { useAppDispatch } from "../../../store/store";
import { uiActions } from "../../../store/ui-slice";
import CustomCardHeader from "../../shared/CustomCardHeader";
import { TableHeaderCell } from "../../../models/common";
import { ShowHistoryModel } from "../../../models/tank";
import useTable from "../../../hooks/useTable";
import tankService from "../../../services/tankService";
import moment from "moment";
import { FormatUnit } from "../../shared/CommonFunctions";

//for Date-Picker
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { DatePicker } from '@mui/x-date-pickers';
import dayjs, { Dayjs } from 'dayjs';

interface ShowHistoryProps {
  tankId: number;
  locationName: string;
  startDate: Dayjs;
  endDate:  Dayjs;
  setStartDate: React.Dispatch<React.SetStateAction<Dayjs>>;
  setEndDate: React.Dispatch<React.SetStateAction<Dayjs>>;
  setShowHistoryModelPopup: React.Dispatch<React.SetStateAction<boolean>>;
  setShowChartModalPopup: React.Dispatch<React.SetStateAction<boolean>>;
}

function ShowHistory(props: ShowHistoryProps) {
  const dispatch = useAppDispatch();

  const [showHistoryData, SetShowHistoryData] = useState<ShowHistoryModel[]>([]);

  const [startDate, setStartDate] = useState<Dayjs>(dayjs(props.startDate));

  const [endDate, setEndDate] = useState<Dayjs>(dayjs(props.endDate));

  const [invalidDate ,setInvalidDate] = useState(false)

  const { fetchData: fetchShowHistoryData } = tankService.RetrieveShowHistoryData(props.tankId, startDate.format('MM-DD-YYYY'), endDate.format('MM-DD-YYYY'));

  const headerCellsUnitInfo: TableHeaderCell[] = [
    { id: "Date", label: "Date" },
    { id: "Start", label: "Beginning Level" },
    { id: "End", label: "Ending Level" },
  ];

  const {
    Tbl: TblUnit,
    GetRowsAfterPagingAndSorting: GetRowsInfo,
  } = useTable<ShowHistoryModel>(showHistoryData, headerCellsUnitInfo, false);

  useEffect(() => {
    if (props.tankId !== null) {
      fetchShowHistoryData().then((res) => {
        if (res.isSuccess === true) {
          SetShowHistoryData(res.responseData);
        }
      });
    };
  }, [])

  function checkRequiredFields() {

    if (invalidDate) {
      dispatch(
        uiActions.displayNotification({
          message: "Please enter a valid Date",
          severity: "error",
        })
      );
      return;
    }

    if (startDate > endDate) {
      dispatch(
        uiActions.displayNotification({
          message: "Through Date should be after the Begin Date.",
          severity: "error",
        })
      );
      return;
    }

    if ((dayjs(endDate).toDate().getTime() - dayjs(startDate).toDate().getTime()) / (1000 * 3600 * 24) > 30) {
      dispatch(
        uiActions.displayNotification({
          message: "Select a date range not more than 30 days apart.",
          severity: "error",
        })
      );
      return;
    }

    fetchShowHistoryData().then((res) => {
      if (res.isSuccess === true) {
        SetShowHistoryData(res.responseData);
      }
    });
  }

  function openShowChart(){ 
    props.setStartDate(startDate)
    props.setEndDate(endDate)
    props.setShowChartModalPopup(true)
    props.setShowHistoryModelPopup(false)
  }

  return (
    <React.Fragment>
      <Container>

      <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              sx={{minWidth:'270px',margin:'15px'}}
              label="Search Begin Date"
              value={startDate}
              onChange={(newValue) => {
                if (newValue !== null && newValue.isValid()) {
                  setStartDate(newValue)
                  setInvalidDate(false)
                } else {
                  setInvalidDate(true)
                }
              }}
              //disableFuture={true}
            />
            <DatePicker
            sx={{minWidth:'270px',margin:'15px'}}
              label="Search Through Date"
              value={endDate}
              onChange={(newValue) => {
                if (newValue !== null && newValue.isValid()) {
                  setEndDate(newValue)
                  setInvalidDate(false)
                } else {
                  setInvalidDate(true)
                }
              }}
              //disableFuture={true}
            />
        </LocalizationProvider>

        <Grid item sx={{ alignItems: 'center' ,justifyContent:'center',display: 'flex'}}>
          <Button type="submit" size="medium" onClick={checkRequiredFields}>{"Show Summary"}</Button>
        </Grid>

        <Divider sx={{ mt: 4, mb: 2 }}></Divider>
        {
          <Card
            //sx={{ width: "100hv", borderRadius: 3, margin: 2, mt: 0 }}
            sx={{ alignItems: 'center' ,justifyContent:'center', borderRadius: 3}}
            elevation={6}
          >
            <CustomCardHeader title={props.locationName}
            action = {
              <Button variant="text" size="small" onClick={openShowChart}><u>Show Chart</u></Button>}
            ></CustomCardHeader>
            <CardContent style={{ whiteSpace: 'nowrap' }} >
              <TblUnit>

                {GetRowsInfo().map((row, index) => (
                  <StyledTableRow key={index}>
                    <StyledTableCell>{row.inv_Date===null?'':moment(row.inv_Date).format("MM/DD/YYYY hh:mm:ss A")}</StyledTableCell>
                    {(row.e_Inv_UOM === "MT") ?
                      (<StyledTableCell>{row.b_Inv_Percent + "% / " + (row.b_Inv_Pounds === null ? 0 : row.b_Inv_Pounds / 2204).toFixed(1) + " " + FormatUnit(row.e_Inv_UOM || '')}</StyledTableCell>)
                      :
                      (row.e_Inv_UOM === "BT" ? <StyledTableCell>{row.b_Inv_Percent + "% / " + (row.b_Inv_Pounds === null ? 0 : row.b_Inv_Pounds / (row.e_Inv_Specific_Gravity == null ? 0 : row.e_Inv_Specific_Gravity)).toFixed(1) + " " + FormatUnit(row.e_Inv_UOM || '')}</StyledTableCell>
                        : <StyledTableCell>{row.b_Inv_Percent + "% / " + (row.b_Inv_Pounds === null ? 0 : row.b_Inv_Pounds).toFixed(0) + " " + FormatUnit(row.e_Inv_UOM || '')}</StyledTableCell>)
                    }

                    {(row.e_Inv_UOM === "MT") ?
                      (<StyledTableCell>{row.e_Inv_Percent + "% / " + (row.e_Inv_Pounds === null ? 0 : row.e_Inv_Pounds / 2204).toFixed(1) + " " + FormatUnit(row.e_Inv_UOM || '')}</StyledTableCell>)
                      :
                      (row.e_Inv_UOM === "BT" ? <StyledTableCell>{row.e_Inv_Percent + "% / " + (row.e_Inv_Pounds == null ? 0 : row.e_Inv_Pounds / (row.e_Inv_Specific_Gravity == null ? 0 : row.e_Inv_Specific_Gravity)).toFixed(1) + " " + FormatUnit(row.e_Inv_UOM || '')}</StyledTableCell>
                        : <StyledTableCell>{row.e_Inv_Percent + "% / " + (row.e_Inv_Pounds === null ? 0 : row.e_Inv_Pounds).toFixed(0) + " " + FormatUnit(row.e_Inv_UOM || '')}</StyledTableCell>)
                    }

                  </StyledTableRow>
                ))}

              </TblUnit>
            </CardContent>
            {/* <CardContent sx={{ alignItems: 'center' ,justifyContent:'center',display: 'flex'}}>
              <Button type="submit" size="small" onClick={openShowChart}>{"Show Chart"}</Button>
            </CardContent> */}
          </Card>
        }
      </Container>

    </React.Fragment>
  );
}

export default ShowHistory;
