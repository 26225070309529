/* eslint-disable import/no-anonymous-default-export */
import useAxiosGet from "../hooks/useAxiosGet";
import useAxiosPut from "../hooks/useAxiosPut";
import useAxiosDelete from "../hooks/useAxiosDelete";
import useAxiosPost from "../hooks/useAxiosPost";
import {
    IntransitCars,
    LocationShipment,
    ProductCode,
    Shipment,
    UpdateShipment,
    COAFile,
    InputTunrnedCars,
    InTransitTurnedCars,
    ShipmentEntryDetails,
    RailCarDocs,
    UserCarsData,
    TurnedCars,
    RetrieveSummaryReportModal,
    RailcarInventories,
} from "../models/shipment";


const isLocal = () => (process.env.REACT_APP_ENVIR === 'LOCAL');
const userId = () => sessionStorage.getItem("userMailID");

const GetDefaultShipmentSummary = (): Shipment => {
    const defaultShipmentSummary = {
        siteNo: 0,
        locationName: "",
        locationNo: 0,
        admOrderNo: 0,
        deliveryDate: "",
        shippedDate: "",
        truckWeight: 0,
        comments: 0,
        uom: "",
        specific_Gravity: 0,
        shipID: 0,
        vP_PRODUCT_ID: 0,
    } as Shipment;

    return defaultShipmentSummary;
}

const GetDefaultLocationShipmentDetails = (): LocationShipment => {
    const defaultLocationShipmentDetails = {
        siteNo: 0,
        locationName: '',
        shipTo: 0,
        orderNo: "",
        orderID: 0,
        deliveryDate: '',
        weight: '',
        productCode: 0,
        productID: 0,
        comments: '',
        source: '',
        isChecked: false,
    } as LocationShipment;

    return defaultLocationShipmentDetails;
}

const GetDefaultInTransitTurnedCars = (): InTransitTurnedCars => {
    const defaultInTransitTurnedCars = {
        vtC_CAR_NUMBER: "",
        vtC_SOURCE: "",
        vtC_TURNED_USER_ID: "",
        vtC_TURNED_DATETIME: "",
        vtC_COA_USER_ID: "",
        vtC_COA_DATETIME: "",
        vtC_REC_NUM: "",
        vtC_PRODUCT_CODE: "",
        vtC_PRODUCT_SUPPLIER: "",
        vtC_PO_LOC: "",
        vtC_PO_DEPT: "",
        vtC_PO_NUMBER: "",
        vP_PRODUCT_DESC: "",
        rC_FILE_TYPE: ""
    } as InTransitTurnedCars;

    return defaultInTransitTurnedCars;
}


const GetDefaultCOAFiles = (): COAFile => {
    const defaultCOAFiles = {
        coA_FILE: '',
        filE_TYPE: '',
        filE_SIZE: 0
    } as COAFile;

    return defaultCOAFiles;
}

const GetDefaultRailCarDocs = (): RailCarDocs => {
    const defaultRailCarDocs = {
        datA_FILE: '',
        filE_TYPE: '',
        filE_SIZE: 0
    } as RailCarDocs;

    return defaultRailCarDocs;
}
const GetDefaultInputTurnedCars = (): InputTunrnedCars => {
    const defaultInputTurnedCars = {
        fromDate: '',
        toDate: '',
        productId: '',
    } as InputTunrnedCars;

    return defaultInputTurnedCars;
}

const GetDefaultUpdateLocationShipmentDetails = (): UpdateShipment => {
    const defaultUpdateLocationShipmentDetails = {
        orderNo: "",
        orderID: 0,
        deliveryDate: '',
        weight: '',
        productID: 0,
        comments: '',
        locationID: ''
    } as UpdateShipment;

    return defaultUpdateLocationShipmentDetails;
}

const GetDefaultShipmentEntryDetails = (): ShipmentEntryDetails => {
    const defaultUpdateLocationShipmentEntryDetails = {
        orderNo: '',
        deliveryDate: '',
        weight: 0,
        productID: 0,
        comments: '',
        locationID: 0
    } as ShipmentEntryDetails;

    return defaultUpdateLocationShipmentEntryDetails;
}


const GetDefaultRailcarInventory = (): RailcarInventories => {
    const defaultRailcarInventoriesDetails = {
        rS_CAR_NUMBER: "",
        rS_PRODUCT_DESCRIPTION: "",
        rS_NET_WEIGHT: 0,
        rS_ORDER_NUMBER: "",
        rS_RECEIVED_DATE: "",
        rS_PRODUCT_CODE: "",
        rS_SHIPPED_DATE: "",
        rS_LOAD_DATE: "",
        vL_LOCATION_ID: 0,
        vP_PRODUCT_ID: 0,
        vpL_ORDER_COPC: "",

    } as RailcarInventories;
    return defaultRailcarInventoriesDetails;
}

const RetrieveProductsByLocation = (customerNo: string, locationNo: string) => {
    let optionsParamsURL = '';
    if (locationNo != null && locationNo !== '' && locationNo !== undefined) {
        optionsParamsURL += `locationNo=${locationNo}&`;
    }
    return useAxiosGet<ProductCode[]>(isLocal() ? `/Shipment/RetrieveProductsByLocation/users/${userId()}/customers/${customerNo}/locationproducts?${optionsParamsURL}` : `/users/${userId()}/customers/${customerNo}/locationproducts?${optionsParamsURL}`, [], "Product list");
};

const RetrieveCustomerShipmentSummary = (customerNo: string, locationNo: string, productID: string, endDate: string) => {
    let optionsParamsURL = '';
    if (locationNo != null && locationNo !== '' && locationNo !== undefined) {
        optionsParamsURL += `locationNo=${locationNo}&`;
    }
    if (productID != null && productID !== '' && productID !== undefined) {
        optionsParamsURL += `productID=${productID}&`;
    }
    return useAxiosGet<Shipment>(isLocal() ? `/Shipment/RetrieveCustomerShipmentSummary/users/${userId()}/customers/${customerNo}/endDate/${endDate}/customershipments?${optionsParamsURL}` : `users/${userId()}/customers/${customerNo}/endDate/${endDate}/customershipments?${optionsParamsURL}`, GetDefaultShipmentSummary(), "Shipment Summary");
};

const RetrieveLocationShipmentSummary = (customerNo: string, shipto?: string, orderId?: string) => {
    let optionsParamsURL = '';
    if (shipto != null && shipto !== '' && shipto !== undefined) {
        optionsParamsURL += `locationNo=${shipto}&`;
    }
    if (orderId != null && orderId !== '' && orderId !== undefined) {
        optionsParamsURL += `orderID=${orderId}&`;
    }
    return useAxiosGet<UpdateShipment>(isLocal() ? `/Shipment/RetrieveLocationShipmentSummary/customerNo/${customerNo}/locationshipments?${optionsParamsURL}`
        : `/customerNo/${customerNo}/locationshipments?${optionsParamsURL}`, GetDefaultUpdateLocationShipmentDetails(), "Location Shipment Summary");
};

const UpdateLocationShipment = (data: UpdateShipment) => {
    return useAxiosPut(isLocal() ?
        `/Shipment/UpdateLocationShipment/locationshipments` :
        `/locationshipments`,
        data,
        "Location Shipment - Update Shipment Location");
};

const DeleteLocationShipment = (orderId: string) => {
    return useAxiosDelete(isLocal() ?
        `/Shipment/DeleteLocationShipment/orderID/${orderId}` :
        `/orderID/${orderId}`,
        orderId,
        "Location Shipment - Delete Shipment Location");
};

const AddShipmentEntry = (data: ShipmentEntryDetails) => {
    return useAxiosPost(isLocal() ?
        `/Shipment/CreateShipment/shipments` :
        `/shipments`,
        data,
        "Add Shipment Entry");
};

const RetrieveAllCarsByCustomer = (customerNo: string) => {
    return useAxiosGet<IntransitCars[]>(isLocal() ? `/Shipment/RetrieveAllCarsByCustomer/customerNo/${customerNo}/cars`
        : `/customerNo/${customerNo}/cars`, [], "In-Transit Cars");
};

const RetrieveAllCarsByLocation = (locationNo: string) => {
    return useAxiosGet<IntransitCars[]>(isLocal() ? `/Shipment/RetrieveAllCarsByLocation/locationNo/${locationNo}/cars`
        : `/locationNo/${locationNo}/cars`, [], "In-Transit Cars");
};

const RetrieveCarsByCustomer = (customerNo: string, productCode: string) => {
    return useAxiosGet<IntransitCars[]>(isLocal() ? `/Shipment/RetrieveCarsByCustomer/customerNo/${customerNo}/productCode/${productCode}/customercars`
        : `/customerNo/${customerNo}/productCode/${productCode}/customercars`, [], "In-Transit Cars");
};

const RetrieveCarsByLocation = (locationNo: string, productCode: string) => {
    return useAxiosGet<IntransitCars[]>(isLocal() ? `/Shipment/RetrieveCarsByLocation/locationNo/${locationNo}/productCode/${productCode}/locationcars`
        : `/locationNo/${locationNo}/productCode/${productCode}/locationcars`, [], "In-Transit Cars");
};

const RetrieveTurnedCarUser = () => {
    return useAxiosGet<UserCarsData[]>(isLocal() ? `/Shipment/RetrieveTurnedCarUser/emailID/${userId()}/turnedcarusers`
        : `/emailID/${userId()}/turnedcarusers`, [], "Turn Car User Details");
}

const RetrieveShipmentTurnedCars = (fromDate: string, toDate: string, productCode?: string, sortKey?: string) => {
    let optionsParamsURL = '';
    if (productCode != null && productCode !== '' && productCode !== undefined) {
        optionsParamsURL += `productCode=${productCode}&`;
    }
    if (sortKey != null && sortKey !== '' && sortKey !== undefined) {
        optionsParamsURL += `sortKey=${sortKey}&`;
    }
    return useAxiosGet<InTransitTurnedCars[]>(isLocal() ? `/Shipment/RetrieveShipmentTurnedCars/fromDate/${fromDate}/toDate/${toDate}/shipmentturnedcars?${optionsParamsURL}`
        : `/fromDate/${fromDate}/toDate/${toDate}/shipmentturnedcars?${optionsParamsURL}`, [], "Intransit Turned Cars");
};

const RetrieveShipmentTurnedCarsDates = (fromDate: string, toDate: string, productCode?: string, sortKey?: string) => {
    let optionsParamsURL = '';
    if (productCode != null && productCode !== '' && productCode !== undefined) {
        optionsParamsURL += `productCode=${productCode}&`;
    }
    if (sortKey != null && sortKey !== '' && sortKey !== undefined) {
        optionsParamsURL += `sortKey=${sortKey}&`;
    }
    return useAxiosGet<InTransitTurnedCars[]>(isLocal() ? `/Shipment/RetrieveShipmentTurnedCarsDates/fromDate/${fromDate}/toDate/${toDate}/shipmentturnedcardates?${optionsParamsURL}`
        : `/fromDate/${fromDate}/toDate/${toDate}/shipmentturnedcardates?${optionsParamsURL}`, [], "Intransit Turned Cars");
};


const RetrieveCOA = (carID: string, turnDate?: string) => {
    let optionsParamsURL = '';
    if (turnDate != null && turnDate !== '' && turnDate !== undefined) {
        optionsParamsURL += `turnDate=${turnDate}&`;
    }

    return useAxiosGet<COAFile>(isLocal() ? `/Shipment/RetrieveCOA/carID/${carID}/files?${optionsParamsURL}`
        : `/carID/${carID}/files?${optionsParamsURL}`, GetDefaultCOAFiles(), "Retrieve COA");
};


const RetrieveRailcarDoc = (carNo: string, docType: string, documentDate: string) => {

    return useAxiosGet<RailCarDocs>(isLocal() ? `/Shipment/RetrieveRailcarDoc/carNo/${carNo}/docType/${docType}/documentDate/${documentDate}/railcardocs`
        : `/carNo/${carNo}/docType/${docType}/documentDate/${documentDate}/railcardocs`, GetDefaultRailCarDocs(), "Retrieve Railcar Document");
};

const UpdateTurnCars = (data: TurnedCars) => {
    return useAxiosPut(isLocal() ? `/Shipment/UpdateTurnCars/turncars` : `/turncars`, data, "Update Turn Cars");
};

const UpdateMarkCarCOA = (data: TurnedCars) => {
    return useAxiosPut(isLocal() ? `/Shipment/UpdateMarkCarCOA/markcars` : `/markcars`, data, "Update Marked Cars");
};

const UpdateMarkCarNoCOA = (data: TurnedCars) => {
    return useAxiosPut(isLocal() ? `/Shipment/UpdateMarkCarNoCOA/marknocars` : `/marknocars`, data, "Update Not Marked Cars");
};

const RetrieveAllOutBCarsByLocation = (locationNo: string) => {
    return useAxiosGet<IntransitCars[]>(isLocal() ? `/Shipment/RetrieveAllOutBCarsByLocation/locationNo/${locationNo}/bcars` : `/locationNo/${locationNo}/bcars`, [], "In-Transit Cars");
};

const RetrieveOutBCarsByLocation = (locationNo: string, productCode: string) => {
    return useAxiosGet<IntransitCars[]>(isLocal() ? `/Shipment/RetrieveOutBCarsByLocation/locationNo/${locationNo}/productCode/${productCode}/locationbcars` : `/locationNo/${locationNo}/productCode/${productCode}/locationbcars`, [], "In-Transit Cars");
};


const RetrieveRailCarInventory = (locationNo: string, productId?: string) => {
    let optionsParamsURL = '';

    if (productId != null && productId !== '' && productId !== undefined) {
        optionsParamsURL += `productId=${productId}&`;
    }
    return useAxiosGet<RailcarInventories>(isLocal() ? `/Shipment/RetrieveRailCarInventory/locations/${locationNo}/railcarinventories?${optionsParamsURL}`
        : `/locations/${locationNo}/railcarinventories?${optionsParamsURL}`, GetDefaultRailcarInventory(), "Railcar Inventory");
};

  const RetrieveSummaryReport = (customerNo:string,locationNo:string,productID:number,thruDate:string,reportType:string) => {
    let optionsParamsURL = '';
    if (locationNo != null && locationNo !== '' && locationNo !== undefined) {
        optionsParamsURL += `locationNo=${locationNo}&`;
    }
      return useAxiosGet<RetrieveSummaryReportModal[]>(isLocal()? `/Shipment/RetrieveSummaryReport/users/${userId()}/customers/${customerNo}/products/${productID}/thruDates/${thruDate}/types/${reportType}/reports?${optionsParamsURL}` :`/users/${userId()}/customers/${customerNo}/products/${productID}/thruDates/${thruDate}/types/${reportType}/reports?${optionsParamsURL}`,[], "Summary Report");
  };

export default {
    RetrieveProductsByLocation,
    RetrieveCustomerShipmentSummary,
    GetDefaultShipmentSummary,
    RetrieveLocationShipmentSummary,
    GetDefaultLocationShipmentDetails,
    GetDefaultInTransitTurnedCars,
    RetrieveShipmentTurnedCars,
    RetrieveShipmentTurnedCarsDates,
    GetDefaultInputTurnedCars,
    GetDefaultCOAFiles,
    RetrieveCOA,
    RetrieveRailcarDoc,
    UpdateLocationShipment,
    GetDefaultUpdateLocationShipmentDetails,
    DeleteLocationShipment,
    GetDefaultShipmentEntryDetails,
    AddShipmentEntry,
    RetrieveAllCarsByCustomer,
    RetrieveAllCarsByLocation,
    RetrieveCarsByCustomer,
    RetrieveCarsByLocation,
    RetrieveTurnedCarUser,
    UpdateTurnCars,
    UpdateMarkCarCOA,
    UpdateMarkCarNoCOA,
    RetrieveAllOutBCarsByLocation,
    RetrieveOutBCarsByLocation,
    RetrieveSummaryReport,
    RetrieveRailCarInventory,
   
};
