import SecurityReport from "../components/app/User/SecurityReport";
import SystemMessage from "./SystemMessage";

function SecurityReportPage() {
  const role = sessionStorage.getItem("roles");
  return role?.toString().toUpperCase() === "ADMIN" ? (
    <SecurityReport></SecurityReport>
  ) : (
    <SystemMessage msg="Access Denied." colorMsg="Red"></SystemMessage>
  );
}

export default SecurityReportPage;
