import { Box, TableHead, TableSortLabel } from "@mui/material";
import { visuallyHidden } from "@mui/utils";
import {
  AdditionalTableHeaderCell,
  Order,
  TableHeaderCell,
} from "../../models/common";
import { StyledTableCell, StyledTableRow } from "../controls/tablecontrols";

interface MainTableProps {
  numSelected: number;
  onRequestSort: (event: React.MouseEvent<unknown>, property: string) => void;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
  headCells: TableHeaderCell[];
  additionalHeader?: AdditionalTableHeaderCell[];
  isHeaderCheckBox?: false;
}

function MainTableHead(props: MainTableProps) {
  const {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    onSelectAllClick,
    order,
    orderBy,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    numSelected,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler =
    (property: string) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead>
      {props.additionalHeader !== undefined &&
        props.additionalHeader !== null && (
          <StyledTableRow>
            {props.additionalHeader.map((headCell) => (
              <StyledTableCell
                key={headCell.id}
                align={headCell.align?headCell.align:"left"}
                padding={headCell.disablePadding ? "none" : "normal"}
                colSpan={headCell.colSpan}
              >
                {headCell.label}
              </StyledTableCell>
            ))}
          </StyledTableRow>
        )}
      <StyledTableRow>
        {/* <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              'aria-label': 'select all desserts',
            }}
          />
        </TableCell> */}
        {props.headCells.map((headCell) => (
          <StyledTableCell
            key={headCell.id}
            align={headCell.align ? headCell.align : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {headCell.disableSorting === true ? (
              headCell.label
            ) : (
              <TableSortLabel
                sx={{
                  "&.MuiTableSortLabel-root": {
                    color: "white",
                  },
                  "&.MuiTableSortLabel-root:hover": {
                    color: "white",
                  },
                  "&.Mui-active": {
                    color: "white",
                  },
                  "& .MuiTableSortLabel-icon": {
                    color: "white !important",
                  },
                }}
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>
            )}
          </StyledTableCell>
        ))}
      </StyledTableRow>
    </TableHead>
  );
}

export default MainTableHead;
