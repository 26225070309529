/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { TextField, Button, Grid, Divider, Container } from '@mui/material';
import { useFormik } from 'formik'
import OrderDataService from "../../../services/orderService";
import { OrderNotesPayload, DataForOrderNotesModal } from "../../../models/order";
import { uiActions } from "../../../store/ui-slice";
import { useAppDispatch } from "../../../store/store";



interface OrderNotesProps {
  dataFromOrderSchedule: DataForOrderNotesModal,
  setShowOrderNotesModalPopup: React.Dispatch<React.SetStateAction<boolean>>;
}


function OrderNotes(props: OrderNotesProps) {
  const dispatch = useAppDispatch();
  const [isLoaded, setIsloaded] = useState(false);
  const [payload, setPayload] = useState<OrderNotesPayload>(OrderDataService.GetDefaultOrderNotesPayload());
  const { putData: updateOrderNotes } = OrderDataService.UpdateOrderNotes(payload);
  const { fetchData: fetchOrderNotes } = OrderDataService.RetrieveOrderNotes(props.dataFromOrderSchedule.locatioN_ID, props.dataFromOrderSchedule.ordeR_ID, props.dataFromOrderSchedule.producT_ID
  );
  //Calling Get API
  useEffect(() => {
    if (!isLoaded) {
      fetchData()
      setIsloaded(true)
    }
  }, [])

  //Calling Put API
  useEffect(() => {
    if (payload.orderID !== 0 && payload.orderID !== null && payload.orderID !== undefined && isLoaded) {
      dispatch(uiActions.startProgressBar());
      updateOrderNotes().then((res) => {
        if (res.isSuccess === true) {
          dispatch(
            uiActions.displayNotification({
              message: "Order Notes updated successfully!",
              severity: "success",
            })
          );
          props.setShowOrderNotesModalPopup(false)
        }
      })
    }
  }, [payload.orderID]);

  //Get API function
  const fetchData = () => {
    dispatch(uiActions.startProgressBar());
    fetchOrderNotes().then((response) => {
      if (response.isSuccess === true) {
        formik.setValues(response.responseData);
      }
    })
  }

  const formik = useFormik({
    initialValues: OrderDataService.GetDefaultOrderNotesPayload(),
    onSubmit: (values: OrderNotesPayload) => {
      const updatedPayload = {
        orderID: props.dataFromOrderSchedule.ordeR_ID,
        vO_NOTES: formik.values.vO_NOTES.trim(),
      }
      setPayload(updatedPayload);
    }
  })

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <Container>
          <Grid container spacing={3} sx={{ paddingTop: 1 }}>
            <Grid item xs={9} sm={18} md={18}>
              <TextField label="Order Notes" fullWidth variant="outlined" type="text" value={formik.values.vO_NOTES} onChange={formik.handleChange} name="vO_NOTES" onBlur={formik.handleBlur} id="outlined-multiline-static"
                multiline
                rows={6}/>
            </Grid>
          </Grid>
        </Container>
        <Divider sx={{ mt: 3, mb: 2 }}></Divider>
        <Grid item sx={{
          justifyContent: "flex-end",
          alignItems: "flex-end",
          display: "flex",
        }}
        >
          <Button type="submit">
            Update
          </Button>
        </Grid>
      </form>
    </>
  );
}
export default OrderNotes;