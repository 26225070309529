import { configureStore } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux'

import authSlice from './auth-slice';
import filterBandSlice from './filterband-slice';
import uiSlice from './ui-slice';
import adAuthSlice from './adauth-slice';

const store = configureStore({
  reducer: {
    auth: authSlice.reducer, 
    filterBand: filterBandSlice.reducer,
    ui:uiSlice.reducer,
    adAuth: adAuthSlice.reducer,
  },
})

export type RootState = ReturnType<typeof store.getState>
export const authSelector = (state: RootState) => state.auth
export const filterBandSelector = (state: RootState) => state.filterBand
export const uiSelector = (state: RootState) => state.ui
export const adAuthSelector = (state: RootState) => state.adAuth

export default store;
export type AppDispatch = typeof store.dispatch
export const useAppDispatch = () => useDispatch<AppDispatch>()
