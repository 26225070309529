import React from "react";
import { AppBar } from "@mui/material";
import { Toolbar } from "@mui/material";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import logo from "../../assets/ADMLogo.png";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import ListItemIcon from "@mui/material/ListItemIcon";
import Logout from "@mui/icons-material/Logout";
import ProfileNameAvatar from "../controls/avatar";
import { Link as RouterLink } from "react-router-dom";

interface Props {
  logoutAction: any;
  userName: any;
}

function Header(props: React.PropsWithChildren<Props>) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const adUserName: string = props.userName;

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <AppBar>
      <Toolbar>
        <RouterLink to="/">
          <img src={logo} alt="ADM logo"></img>
        </RouterLink>
        <Typography
          variant="subtitle1"
          sx={{
            flexGrow: 1,
            fontSize: { xs: "1rem", sm: "1rem", md: "1.25rem", lg: "1.5rem" },
          }}
          textAlign="center"
        >
          Online Inventory Services
        </Typography>
        <IconButton edge="end" onClick={handleMenu}>
          <ProfileNameAvatar accountName={adUserName} />
        </IconButton>
        <Menu
          id="menu-appbar"
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          keepMounted
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={Boolean(anchorEl)}
          onClose={handleClose}
          sx={{ mt: 4, ml: 14 }}
        >
          <MenuItem
            sx={{ width: 230, whiteSpace: "normal" }}
            className="menuItemText"
            onClick={handleClose}
            divider={true}
          >
            {adUserName}
          </MenuItem>
          <MenuItem onClick={props.logoutAction}>
            <ListItemIcon>
              <Logout fontSize="small" />
            </ListItemIcon>
            Logout
          </MenuItem>
        </Menu>
      </Toolbar>
    </AppBar>
  );
}

export default Header;
