/* eslint-disable react-hooks/exhaustive-deps */
import {
  Button,
  Card,
  CardHeader,
  Checkbox,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { CustomerLocations } from "../../../models/location";
import { CustomerList } from "../../../models/customer";
import { CustomerProducts } from "../../../models/product";
import CustomerDataService from "../../../services/customerService";
import ProductDataService from "../../../services/productService";
import LocationDataService from "../../../services/locationService";
import UserNoticeDataService from "../../../services/userNoticeService";
import { UpdateSecurity } from "../../../models/userNotice";
import userNoticeService from "../../../services/userNoticeService";
import { uiActions } from "../../../store/ui-slice";
import { useAppDispatch } from "../../../store/store";
import ConfirmationMessage from "../../shared/ConfirmationMessage";

interface Props {
  email: string;
  userName: string;
  userID: number;
  setShowModalPopup: React.Dispatch<React.SetStateAction<boolean>>;
  setRefreshList: React.Dispatch<React.SetStateAction<boolean>>;
}

function UserAdmin(props: Props) {
  const dispatch = useAppDispatch();
  const [initialCalculate, setInitialCalculate] = useState<boolean>(false);
  const [isUserAdmin, setUserAdmin] = useState<boolean>(false);
  const [isUserTurnCars, setUserTurnCars] = useState<boolean>(false);
  const [updateData, setUpdateData] = useState<UpdateSecurity>(
    userNoticeService.GetDefaultUpdateSecurity()
  );
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const [userIDtoDelete, setUserIDtoDelete] = useState<number>(0);
  const [openConfirmation, setOpenConfirmation] =
    React.useState<boolean>(false);

  //Customer
  const [allCustomers, setAllCustomers] = useState<CustomerList[]>([]);
  const [checkedCustomers, setCheckedCustomers] = useState<readonly number[]>(
    []
  );
  const [assignedCustomers, setAssignedCustomers] = useState<readonly number[]>(
    []
  );
  const [unassignedCustomers, setUnassignedCustomers] = useState<
    readonly number[]
  >([]);
  const unassignedCheckedCustomers = intersection(
    checkedCustomers,
    unassignedCustomers
  );
  const assignedCheckedCustomers = intersection(
    checkedCustomers,
    assignedCustomers
  );
  const [customerSeq, setCustomerSeq] = useState<readonly number[]>([]); //to maintain sequence

  const handleCustomerCheckedRight = () => {
    setAssignedCustomers(
      assignedCustomers
        .concat(unassignedCheckedCustomers)
        .sort((a, b) => customerSeq.indexOf(a) - customerSeq.indexOf(b))
    );
    setUnassignedCustomers(
      not(unassignedCustomers, unassignedCheckedCustomers)
    );
    setCheckedCustomers(not(checkedCustomers, unassignedCheckedCustomers));
  };

  const handleCustomerCheckedLeft = () => {
    setUnassignedCustomers(
      unassignedCustomers
        .concat(assignedCheckedCustomers)
        .sort((a, b) => customerSeq.indexOf(a) - customerSeq.indexOf(b))
    );
    setAssignedCustomers(not(assignedCustomers, assignedCheckedCustomers));
    setCheckedCustomers(not(checkedCustomers, assignedCheckedCustomers));
  };

  const handleToggleAllCustomers =
    (checked: readonly number[], items: readonly number[]) => () => {
      if (numberOfChecked(checked, items) === items.length) {
        setCheckedCustomers(not(checked, items));
      } else {
        setCheckedCustomers(union(checked, items));
      }
    };

  //Location
  const [allLocations, setAllLocations] = useState<CustomerLocations[]>([]);
  const [checkedLocations, setCheckedLocations] = useState<readonly number[]>(
    []
  );
  const [assignedLocations, setAssignedLocations] = useState<readonly number[]>(
    []
  );
  const [unassignedLocations, setUnassignedLocations] = useState<
    readonly number[]
  >([]);
  const unassignedCheckedLocations = intersection(
    checkedLocations,
    unassignedLocations
  );
  const assignedCheckedLocations = intersection(
    checkedLocations,
    assignedLocations
  );
  const [locationSeq, setLocationSeq] = useState<readonly number[]>([]); //to maintain sequence

  const handleLocationCheckedRight = () => {
    setAssignedLocations(
      assignedLocations
        .concat(unassignedCheckedLocations)
        .sort((a, b) => locationSeq.indexOf(a) - locationSeq.indexOf(b))
    );
    setUnassignedLocations(
      not(unassignedLocations, unassignedCheckedLocations)
    );
    setCheckedLocations(not(checkedLocations, unassignedCheckedLocations));
  };

  const handleLocationCheckedLeft = () => {
    setUnassignedLocations(
      unassignedLocations
        .concat(assignedCheckedLocations)
        .sort((a, b) => locationSeq.indexOf(a) - locationSeq.indexOf(b))
    );
    setAssignedLocations(not(assignedLocations, assignedCheckedLocations));
    setCheckedLocations(not(checkedLocations, assignedCheckedLocations));
  };

  const handleToggleAllLocations =
    (checked: readonly number[], items: readonly number[]) => () => {
      if (numberOfChecked(checked, items) === items.length) {
        setCheckedLocations(not(checked, items));
      } else {
        setCheckedLocations(union(checked, items));
      }
    };

  //Product
  const [allProducts, setAllProducts] = useState<CustomerProducts[]>([]);
  const [checkedProducts, setCheckedProducts] = useState<readonly number[]>([]);
  const [assignedProducts, setAssignedProducts] = useState<readonly number[]>(
    []
  );
  const [unassignedProducts, setUnassignedProducts] = useState<
    readonly number[]
  >([]);
  const unassignedCheckedProducts = intersection(
    checkedProducts,
    unassignedProducts
  );
  const assignedCheckedProducts = intersection(
    checkedProducts,
    assignedProducts
  );
  const [productSeq, setProductSeq] = useState<readonly number[]>([]); //to maintain sequence

  const handleProductCheckedRight = () => {
    setAssignedProducts(
      assignedProducts
        .concat(unassignedCheckedProducts)
        .sort((a, b) => productSeq.indexOf(a) - productSeq.indexOf(b))
    );
    setUnassignedProducts(not(unassignedProducts, unassignedCheckedProducts));
    setCheckedProducts(not(checkedProducts, unassignedCheckedProducts));
  };

  const handleProductCheckedLeft = () => {
    setUnassignedProducts(
      unassignedProducts
        .concat(assignedCheckedProducts)
        .sort((a, b) => productSeq.indexOf(a) - productSeq.indexOf(b))
    );
    setAssignedProducts(not(assignedProducts, assignedCheckedProducts));
    setCheckedProducts(not(checkedProducts, assignedCheckedProducts));
  };

  const handleToggleAllProducts =
    (checked: readonly number[], items: readonly number[]) => () => {
      if (numberOfChecked(checked, items) === items.length) {
        setCheckedProducts(not(checked, items));
      } else {
        setCheckedProducts(union(checked, items));
      }
    };

  //--Common
  function not(a: readonly number[], b: readonly number[]) {
    return a.filter((value) => b.indexOf(value) === -1);
  }

  function intersection(a: readonly number[], b: readonly number[]) {
    return a.filter((value) => b.indexOf(value) !== -1);
  }

  function union(a: readonly number[], b: readonly number[]) {
    return [...a, ...not(b, a)];
  }

  const numberOfChecked = (
    checked: readonly number[],
    items: readonly number[]
  ) => intersection(checked, items).length;

  const handleToggle = (value: number, type: string) => () => {
    let currentIndex, newChecked;
    if (type.toUpperCase() === "CUSTOMER") {
      currentIndex = checkedCustomers.indexOf(value);
      newChecked = [...checkedCustomers];
    } else if (type.toUpperCase() === "LOCATION") {
      currentIndex = checkedLocations.indexOf(value);
      newChecked = [...checkedLocations];
    } else {
      currentIndex = checkedProducts.indexOf(value);
      newChecked = [...checkedProducts];
    }

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    if (type.toUpperCase() === "CUSTOMER") {
      setCheckedCustomers(newChecked);
    } else if (type.toUpperCase() === "LOCATION") {
      setCheckedLocations(newChecked);
    } else {
      setCheckedProducts(newChecked);
    }
  };

  //------API call-----
  const { fetchData: fetchAllCustomers } =
    CustomerDataService.RetrieveAllCustomers();

  const { fetchData: fetchAllLocations } =
    LocationDataService.RetrieveAllLocations();

  const { fetchData: fetchAllProducts } =
    ProductDataService.RetrieveAllProducts();

  const { fetchData: fetchUserCustomers } =
    CustomerDataService.RetrieveUserCustomers(props.email);

  const { fetchData: fetchUserLocations } =
    LocationDataService.RetrieveUserLocations(props.email);

  const { fetchData: fetchUserProducts } =
    ProductDataService.RetrieveUserProducts(props.email);

  const { fetchData: fetchUserRole } =
    UserNoticeDataService.RetrieveCheckUserSetUp(props.email);

  const { putData: updateUserData } =
    UserNoticeDataService.UpdateUserSecurity(updateData);

  const { deleteData: deleteUser } =
    UserNoticeDataService.DeleteUserSettings(userIDtoDelete);

  useEffect(() => {
    if (props.email !== "" && props.email !== undefined) loadData();
  }, [props]);

  useEffect(() => {
    if (initialCalculate) {
      sortCustomers();
      sortLocations();
      sortProducts();
      setInitialCalculate(false);
      dispatch(uiActions.stopProgressBar());
    }
  }, [initialCalculate]);

  useEffect(() => {
    sortLocations();
    sortProducts();
  }, [unassignedCustomers, assignedCustomers]);

  async function loadData() {
    dispatch(uiActions.startProgressBarWithTotalStepCount(8));
    const allCustomers = await fetchAllCustomers();
    setAllCustomers(allCustomers.responseData);
    let seqCustomers = [];
    for (let i = 0; i < allCustomers.responseData.length; i++) {
      seqCustomers.push(allCustomers.responseData[i].vC_CUSTOMER_ID);
    }
    setCustomerSeq(seqCustomers);

    const allLocations = await fetchAllLocations();
    setAllLocations(allLocations.responseData);
    let seqLocations = [];
    for (let i = 0; i < allLocations.responseData.length; i++) {
      seqLocations.push(allLocations.responseData[i].vL_LOCATION_ID);
    }
    setLocationSeq(seqLocations);

    const allProducts = await fetchAllProducts();
    setAllProducts(allProducts.responseData);
    let seqProducts = [];
    for (let i = 0; i < allProducts.responseData.length; i++) {
      seqProducts.push(allProducts.responseData[i].vP_PRODUCT_ID);
    }
    setProductSeq(seqProducts);

    const userCustomers = await fetchUserCustomers();
    let assignedCustomers = [];
    for (let i = 0; i < userCustomers.responseData.length; i++) {
      assignedCustomers.push(userCustomers.responseData[i].customerID);
    }
    setAssignedCustomers(assignedCustomers);

    const userLocations = await fetchUserLocations();
    let assignedLocations = [];
    for (let i = 0; i < userLocations.responseData.length; i++) {
      assignedLocations.push(userLocations.responseData[i].vuL_LOCATION_ID);
    }
    setAssignedLocations(assignedLocations);

    const userProducts = await fetchUserProducts();
    let assignedProducts = [];
    for (let i = 0; i < userProducts.responseData.length; i++) {
      assignedProducts.push(userProducts.responseData[i].vuP_PRODUCT_ID);
    }
    setAssignedProducts(assignedProducts);

    const userRoles = await fetchUserRole();
    if (userRoles.isSuccess === true) {
      let roles = userRoles.responseData[0];
      setUserAdmin(roles.vu_admin);
      setUserTurnCars(roles.vu_turn_car);
    }

    //to trigger sort
    setInitialCalculate(true);
  }

  const sortCustomers = () => {
    //Customers
    let unAssignedSortedCustomers = [],
      assignedSortedCustomers = [];
    for (let i = 0; i < allCustomers.length; i++) {
      let customerID = allCustomers[i].vC_CUSTOMER_ID;
      let isFoundCustomer = false;
      for (let j = 0; j < assignedCustomers.length; j++) {
        isFoundCustomer = false;
        if (assignedCustomers[j] === customerID) {
          isFoundCustomer = true;
          break;
        }
      }
      if (isFoundCustomer === false) {
        unAssignedSortedCustomers.push(allCustomers[i].vC_CUSTOMER_ID);
      } else {
        assignedSortedCustomers.push(allCustomers[i].vC_CUSTOMER_ID);
      }
    }
    setUnassignedCustomers(unAssignedSortedCustomers);
    setAssignedCustomers(assignedSortedCustomers);
  };

  const sortLocations = () => {
    //Locations
    let unAssignedSortedLocations = [],
      assignedSortedLocations = [],
      filteredLocations: CustomerLocations[] = [];
    for (let i = 0; i < allLocations.length; i++) {
      let locationCustomerID = allLocations[i].vL_CUSTOMER_ID;
      for (let j = 0; j < assignedCustomers.length; j++) {
        if (locationCustomerID === assignedCustomers[j]) {
          filteredLocations.push(allLocations[i]);
          break;
        }
      }
    }

    for (let m = 0; m < filteredLocations.length; m++) {
      let locationID = filteredLocations[m].vL_LOCATION_ID;
      let isFoundLocation = false;
      for (let n = 0; n < assignedLocations.length; n++) {
        isFoundLocation = false;
        if (assignedLocations[n] === locationID) {
          isFoundLocation = true;
          break;
        }
      }
      if (isFoundLocation === false) {
        unAssignedSortedLocations.push(filteredLocations[m].vL_LOCATION_ID);
      } else {
        assignedSortedLocations.push(filteredLocations[m].vL_LOCATION_ID);
      }
    }
    setUnassignedLocations(unAssignedSortedLocations);
    setAssignedLocations(assignedSortedLocations);
  };

  const sortProducts = () => {
    //Products
    let unAssignedSortedProducts = [],
      assignedSortedProdcuts = [],
      filteredProducts: CustomerProducts[] = [];
    for (let i = 0; i < allProducts.length; i++) {
      let productCustomerID = allProducts[i].vL_CUSTOMER_ID;
      for (let j = 0; j < assignedCustomers.length; j++) {
        if (productCustomerID === assignedCustomers[j]) {
          filteredProducts.push(allProducts[i]);
          break;
        }
      }
    }

    for (let m = 0; m < filteredProducts.length; m++) {
      let productID = filteredProducts[m].vP_PRODUCT_ID;
      let isFoundProduct = false;
      for (let n = 0; n < assignedProducts.length; n++) {
        isFoundProduct = false;
        if (assignedProducts[n] === productID) {
          isFoundProduct = true;
          break;
        }
      }
      if (isFoundProduct === false) {
        unAssignedSortedProducts.push(filteredProducts[m].vP_PRODUCT_ID);
      } else {
        assignedSortedProdcuts.push(filteredProducts[m].vP_PRODUCT_ID);
      }
    }

    //to remove duplicate products
    let sortedUnAssignedUniqueProducts: number[] = [];
    unAssignedSortedProducts.forEach(function (value) {
      if (sortedUnAssignedUniqueProducts.indexOf(value) === -1) {
        sortedUnAssignedUniqueProducts.push(value);
      }
    });

    let sortedAssignedUniqueProducts: number[] = [];
    assignedSortedProdcuts.forEach(function (value) {
      if (sortedAssignedUniqueProducts.indexOf(value) === -1) {
        sortedAssignedUniqueProducts.push(value);
      }
    });

    setUnassignedProducts(sortedUnAssignedUniqueProducts);
    setAssignedProducts(sortedAssignedUniqueProducts);
  };

  const customerList = (
    title: React.ReactNode,
    checked: readonly number[],
    items: readonly number[],
    data: any[]
  ) => (
    <Card>
      <CardHeader
        sx={{ px: 2, py: 1 }}
        title={title}
        avatar={
          <Checkbox
            onClick={handleToggleAllCustomers(checkedCustomers, items)}
            checked={
              numberOfChecked(checkedCustomers, items) === items.length &&
              items.length !== 0
            }
            indeterminate={
              numberOfChecked(checkedCustomers, items) !== items.length &&
              numberOfChecked(checkedCustomers, items) !== 0
            }
            disabled={items.length === 0}
            inputProps={{
              "aria-label": "all items selected",
            }}
          />
        }
        subheader={`${numberOfChecked(checked, items)}/${
          items.length
        } selected`}
      />
      <Divider />
      <List
        sx={{
          width: { xs: 180, sm: 180, md: 400, lg: 400 },
          height: 200,
          bgcolor: "background.paper",
          overflow: "auto",
        }}
        dense
        component="div"
        role="list"
      >
        {items.map((value: number) => {
          const labelId = `${value}`;
          const customer = data.find((row) => row.vC_CUSTOMER_ID === value) || {
            vC_CUSTOMER_ID: "",
            vC_CUSTOMER_NAME: "",
          };
          return (
            <ListItem
              key={value}
              role="listitem"
              onClick={handleToggle(value, "CUSTOMER")}
              sx={{ overflowX: "hidden" }}
            >
              <ListItemIcon>
                <Checkbox
                  checked={checked.indexOf(value) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{
                    "aria-labelledby": labelId,
                  }}
                />
              </ListItemIcon>
              <ListItemText id={labelId} primary={customer.vC_CUSTOMER_NAME} />
            </ListItem>
          );
        })}
      </List>
    </Card>
  );

  const locationList = (
    title: React.ReactNode,
    checked: readonly number[],
    items: readonly number[],
    data: any[]
  ) => (
    <Card>
      <CardHeader
        sx={{ px: 2, py: 1 }}
        title={title}
        subheader={`${numberOfChecked(checked, items)}/${
          items.length
        } selected`}
        avatar={
          <Checkbox
            onClick={handleToggleAllLocations(checkedLocations, items)}
            checked={
              numberOfChecked(checkedLocations, items) === items.length &&
              items.length !== 0
            }
            indeterminate={
              numberOfChecked(checkedLocations, items) !== items.length &&
              numberOfChecked(checkedLocations, items) !== 0
            }
            disabled={items.length === 0}
            inputProps={{
              "aria-label": "all items selected",
            }}
          />
        }
      />
      <Divider />
      <List
        sx={{
          width: { xs: 180, sm: 180, md: 400, lg: 400 },
          height: 200,
          bgcolor: "background.paper",
          overflow: "auto",
        }}
        dense
        component="div"
        role="list"
      >
        {items.map((value: number) => {
          const labelId = `${value}`;
          const location = data.find((row) => row.vL_LOCATION_ID === value) || {
            vL_LOCATION_ID: "",
            vL_LOCATION_NAME: "",
          };
          return (
            <ListItem
              key={value}
              role="listitem"
              onClick={handleToggle(value, "LOCATION")}
              sx={{ overflowX: "hidden" }}
            >
              <ListItemIcon>
                <Checkbox
                  checked={checked.indexOf(value) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{
                    "aria-labelledby": labelId,
                  }}
                />
              </ListItemIcon>
              <ListItemText id={labelId} primary={location.vL_LOCATION_NAME} />
            </ListItem>
          );
        })}
      </List>
    </Card>
  );

  const productList = (
    title: React.ReactNode,
    checked: readonly number[],
    items: readonly number[],
    data: any[]
  ) => (
    <Card>
      <CardHeader
        sx={{ px: 2, py: 1 }}
        title={title}
        subheader={`${numberOfChecked(checked, items)}/${
          items.length
        } selected`}
        avatar={
          <Checkbox
            onClick={handleToggleAllProducts(checkedProducts, items)}
            checked={
              numberOfChecked(checkedProducts, items) === items.length &&
              items.length !== 0
            }
            indeterminate={
              numberOfChecked(checkedProducts, items) !== items.length &&
              numberOfChecked(checkedProducts, items) !== 0
            }
            disabled={items.length === 0}
            inputProps={{
              "aria-label": "all items selected",
            }}
          />
        }
      />
      <Divider />
      <List
        sx={{
          width: { xs: 180, sm: 180, md: 400, lg: 400 },
          height: 200,
          bgcolor: "background.paper",
          overflow: "auto",
        }}
        dense
        component="div"
        role="list"
      >
        {items.map((value: number) => {
          const labelId = `${value}`;
          const product = data.find((row) => row.vP_PRODUCT_ID === value) || {
            vP_PRODUCT_ID: "",
            vP_PRODUCT_DESC: "",
          };
          return (
            <ListItem
              key={value}
              role="listitem"
              onClick={handleToggle(value, "PRODUCT")}
              sx={{ overflowX: "hidden" }}
            >
              <ListItemIcon>
                <Checkbox
                  checked={checked.indexOf(value) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{
                    "aria-labelledby": labelId,
                  }}
                />
              </ListItemIcon>
              <ListItemText id={labelId} primary={product.vP_PRODUCT_DESC} />
            </ListItem>
          );
        })}
      </List>
    </Card>
  );

  const onAdminChecked = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUserAdmin(event.target.checked === true ? true : false);
  };

  const onTurnCarsChecked = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUserTurnCars(event.target.checked === true ? true : false);
  };

  useEffect(() => {
    if (isLoaded) {
      updateUserData().then((res) => {
        if (res.isSuccess) {
          dispatch(
            uiActions.displayNotification({
              message: "The information is Saved Successfully.",
              severity: "success",
            })
          );
          props.setShowModalPopup(false);
          props.setRefreshList(true);
        }
      });
    } else {
      setIsLoaded(true);
    }
  }, [updateData]);

  const onSaveClick = () => {
    let assignedCustomersList: string = "";
    assignedCustomers.forEach((value) => {
      assignedCustomersList += value + ",";
    });
    assignedCustomersList = assignedCustomersList.substring(
      assignedCustomersList.length - 1,
      0
    );

    let assignedLocationsList: string = "";
    assignedLocations.forEach((value) => {
      assignedLocationsList += value + ",";
    });
    assignedLocationsList = assignedLocationsList.substring(
      assignedLocationsList.length - 1,
      0
    );

    let assignedProductsList: string = "";
    assignedProducts.forEach((value) => {
      assignedProductsList += value + ",";
    });
    assignedProductsList = assignedProductsList.substring(
      assignedProductsList.length - 1,
      0
    );

    let apiValues: UpdateSecurity = {
      userID: props.userID,
      customerIDs: assignedCustomersList,
      locationIDs: assignedLocationsList,
      productIDs: assignedProductsList,
      isAdmin: isUserAdmin,
      isTurnCar: isUserTurnCars,
    };

    setUpdateData(apiValues);
  };

  const handleConfirmDelete = (e: React.MouseEvent<HTMLButtonElement>) => {
    Promise.resolve().then(() => {
      setOpenConfirmation(false);
      setUserIDtoDelete(props.userID);
    });
  };

  const handleCancelDelete = (e: React.MouseEvent<HTMLButtonElement>) => {
    Promise.resolve().then(() => {
      setOpenConfirmation(false);
      setUserIDtoDelete(0);
    });
  };

  useEffect(() => {
    if (userIDtoDelete !== 0) {
      deleteUser().then((res) => {
        if (res.isSuccess) {
          dispatch(
            uiActions.displayNotification({
              message: "The information is Deleted Successfully.",
              severity: "success",
            })
          );
          props.setShowModalPopup(false);
          props.setRefreshList(true);
        }
      });
    }
  }, [userIDtoDelete]);

  const onDeleteClick = () => {
    setOpenConfirmation(true);
  };

  return (
    <div>
      {
        <Grid container columnSpacing={{ xs: 1, sm: 1, md: 4, lg: 4 }}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              sx={{ textAlign: "center" }}
            >
              <span style={{ fontWeight: "bold" }}>
                User : {props.userName}
              </span>
            </Grid>
            <Grid
              container
              columnSpacing={{ xs: 1, sm: 1, md: 4, lg: 4 }}
              sx={{ mb: "10px" }}
            >
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                lg={6}
                sx={{ textAlign: "center" }}
              >
                <span style={{ fontWeight: "bold" }}>Admin</span>
                <Checkbox
                  name="chkAdmin"
                  checked={isUserAdmin}
                  onChange={onAdminChecked}
                  inputProps={{ "aria-label": "controlled" }}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                lg={6}
                sx={{ textAlign: "center" }}
              >
                <span style={{ fontWeight: "bold" }}>Turn Cars</span>
                <Checkbox
                  name="chkTurnCars"
                  checked={isUserTurnCars}
                  onChange={onTurnCarsChecked}
                  inputProps={{ "aria-label": "controlled" }}
                />
              </Grid>
            </Grid>
            {/* Customer */}
            <Grid
              container
              spacing={2}
              justifyContent="center"
              alignItems="center"
            >
              <Grid item>
                {customerList(
                  "Unassigned Customers",
                  checkedCustomers,
                  unassignedCustomers,
                  allCustomers
                )}
              </Grid>
              <Grid item>
                <Grid container direction="column" alignItems="center">
                  <Button
                    fullWidth
                    sx={{ my: 0.5 }}
                    size="small"
                    onClick={handleCustomerCheckedRight}
                    disabled={unassignedCheckedCustomers.length === 0}
                    aria-label="move selected assigned"
                  >
                    Add &gt;&gt;
                  </Button>
                  <Button
                    fullWidth
                    sx={{ my: 0.5 }}
                    size="small"
                    onClick={handleCustomerCheckedLeft}
                    disabled={assignedCheckedCustomers.length === 0}
                    aria-label="move selected unassigned"
                  >
                    &lt;&lt; Remove
                  </Button>
                </Grid>
              </Grid>
              <Grid item>
                {customerList(
                  "Assigned Customers",
                  checkedCustomers,
                  assignedCustomers,
                  allCustomers
                )}
              </Grid>
            </Grid>
            <br></br>
            {/* Location */}
            <Grid
              container
              spacing={2}
              justifyContent="center"
              alignItems="center"
            >
              <Grid item>
                {locationList(
                  "Unassigned Locations",
                  checkedLocations,
                  unassignedLocations,
                  allLocations
                )}
              </Grid>
              <Grid item>
                <Grid container direction="column" alignItems="center">
                  <Button
                    fullWidth
                    sx={{ my: 0.5 }}
                    size="small"
                    onClick={handleLocationCheckedRight}
                    disabled={unassignedCheckedLocations.length === 0}
                    aria-label="move selected assigned"
                  >
                    Add &gt;&gt;
                  </Button>
                  <Button
                    fullWidth
                    sx={{ my: 0.5 }}
                    size="small"
                    onClick={handleLocationCheckedLeft}
                    disabled={assignedCheckedLocations.length === 0}
                    aria-label="move selected unassigned"
                  >
                    &lt;&lt; Remove
                  </Button>
                </Grid>
              </Grid>
              <Grid item>
                {locationList(
                  "Assigned Locations",
                  checkedLocations,
                  assignedLocations,
                  allLocations
                )}
              </Grid>
            </Grid>
            <br></br>
            {/* Product */}
            <Grid
              container
              spacing={2}
              justifyContent="center"
              alignItems="center"
            >
              <Grid item>
                {productList(
                  "Unassigned Products",
                  checkedProducts,
                  unassignedProducts,
                  allProducts
                )}
              </Grid>
              <Grid item>
                <Grid container direction="column" alignItems="center">
                  <Button
                    fullWidth
                    sx={{ my: 0.5 }}
                    size="small"
                    onClick={handleProductCheckedRight}
                    disabled={unassignedCheckedProducts.length === 0}
                    aria-label="move selected assigned"
                  >
                    Add &gt;&gt;
                  </Button>
                  <Button
                    fullWidth
                    sx={{ my: 0.5 }}
                    size="small"
                    onClick={handleProductCheckedLeft}
                    disabled={assignedCheckedProducts.length === 0}
                    aria-label="move selected unassigned"
                  >
                    &lt;&lt; Remove
                  </Button>
                </Grid>
              </Grid>
              <Grid item>
                {productList(
                  "Assigned Products",
                  checkedProducts,
                  assignedProducts,
                  allProducts
                )}
              </Grid>
            </Grid>
            {/* Buttons */}
            <Divider sx={{ mt: 4, mb: 2 }}></Divider>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              sx={{
                justifyContent: "flex-end",
                alignItems: "flex-end",
                display: "flex",
              }}
            >
              <Button type="submit" onClick={onSaveClick}>
                Save
              </Button>&nbsp;&nbsp;
              <Button type="submit" onClick={onDeleteClick}>
                Delete
              </Button>
            </Grid>
          </Grid>
        </Grid>
      }
      <ConfirmationMessage
        open={openConfirmation}
        message={
          "Do you really want to delete the User Security for- " +
          '"' +
          props.userName +
          '"' +
          " ? This process cannot be undone."
        }
        onYesClick={handleConfirmDelete}
        onNoClick={handleCancelDelete}
      ></ConfirmationMessage>
    </div>
  );
}

export default UserAdmin;
