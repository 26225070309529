/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import useTable from "../../../hooks/useTable";
import TableBody from "@mui/material/TableBody";
import { Link as RouterLink } from "react-router-dom";

// Matarila
import {
  Button,
  TableRow,
  TableCell,
  Link,
  Card,
  CardContent,
} from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";
import PrintIcon from "@mui/icons-material/Print";

// State
import { useSelector } from "react-redux";
import { IFilterBandState } from "../../../interfaces/storeInterfaces";
import { filterBandSelector, useAppDispatch } from "../../../store/store";
import { uiActions } from "../../../store/ui-slice";

// Date
import moment from "moment";
import dayjs from "dayjs";

import PageHeader from "../../shared/PageHeader";
import ModalPopup from "../../shared/ModalPopup";
import GenerateCSV from "../../shared/GenerateCSV";
import { OrderSchedule, DataForOrderNotesModal } from "../../../models/order";
import {
  AdditionalTableHeaderCell,
  TableHeaderCell,
} from "../../../models/common";

// Services
import OrderDataService from "../../../services/orderService";

// Functions
import { FormatInvResult, FormatNumber } from "../../shared/CommonFunctions";

// Pages
import ArrivalDateAdjustment from "./ArrivalDateAdjustment";
import OrderNotes from "./OrderNotes";
import OrderSchedulePrint from "./OrderSchedulePrint";
import CustomCardHeader from "../../shared/CustomCardHeader";

function OrderScheduleDetails() {
  const dispatch = useAppDispatch();
  const [isRefreshList, setRefreshList] = React.useState<boolean>(true);
  const filterBandState: IFilterBandState = useSelector(filterBandSelector);
  const [printData, setPrintData] = useState<OrderSchedule[]>([]);
  const [showOrderSchedulePrint, setShowOrderSchedulePrint] = useState(false);
  const [showOrderScheduleModalPopup, setOrderScheduleShowModalPopup] =
    useState<boolean>(false);
  const [
    showArrivalDateAdjustShowModalPopup,
    setArrivalDateAdjustShowModalPopup,
  ] = useState<boolean>(false);
  const [showOrderNotesModalPopup, setShowOrderNotesModalPopup] =
    useState<boolean>(false);
  const [dataForOrderNotes, setDataForOrderNotes] =
    useState<DataForOrderNotesModal>({
      locatioN_ID: 0,
      producT_ID: 0,
      ordeR_ID: 0,
    });

  const [orderScheduleList, setOrderScheduleList] = useState<OrderSchedule[]>(
    []
  );
  const [excelData, setExcelData] = useState<OrderSchedule[]>([]);
  const [orderIdList, setorderIdList] = useState<any>("");
  const [orderId, setOrderId] = useState<number>(0);

  const { fetchData: fetchOrdersData } = OrderDataService.RetrieveOrderSchedule(
    filterBandState.selectedCustomerNo,
    "LNA",
    filterBandState.selectedLocationNo === null
      ? ""
      : filterBandState.selectedLocationNo
  );
  const { postData: fetchOrderArrivalData } =
    OrderDataService.RetrieveOrderArrivalAdjustmentDictionary(orderIdList);

  const tableHeaderCellDetails: TableHeaderCell[] = [
    { id: "loC_NAME", label: "Name" },
    { id: "loC_SHIPTO", label: "No" },
    { id: "proD_CODE", label: "Prod" },
    { id: "capacity", label: "Capacity" },
    { id: "inventory", label: "Inventory" },
    { id: "reordeR_QTY", label: "In-Transit" },
    { id: "reordeR_PNT", label: "Point" },
    { id: "avG_DAILY_USAGE", label: "Avg. Daily Usage" },
    { id: "usagE_OVERRIDE", label: "Usage Override" },
    { id: "esT_ORDER_DATE", label: "Order" },
    { id: "esT_ARRIVAL_DATE", label: "Arrival" },
    { id: "wknD_FLG", label: "Status" },
    { id: "copc", label: "Number" },
    { id: "copc", label: "Notes" },
  ];

  const additionalHeaderDetails: AdditionalTableHeaderCell[] = [
    {
      id: "productLoaction",
      label: "Product-Location",
      colSpan: 5,
      align: "center",
    },
    { id: "reorder", label: "Reorder", colSpan: 2, align: "center" },
    { id: "0", label: "", colSpan: 2, align: "center" },
    { id: "orderDates", label: "Order Dates", colSpan: 2, align: "center" },
    { id: "order", label: "Order", colSpan: 2, align: "center" },
    { id: "1", label: "", colSpan: 1, align: "center" },
  ];

  const { Tbl, GetRowsAfterPagingAndSorting } = useTable<OrderSchedule>(
    orderScheduleList,
    tableHeaderCellDetails,
    false,
    10,
    additionalHeaderDetails
  );

  useEffect(() => {
    if (
      isRefreshList &&
      filterBandState.selectedCustomerNo !== "" &&
      filterBandState.selectedCustomerNo !== undefined
    ) {
      dispatch(uiActions.startProgressBarWithTotalStepCount(2));
      getOrderScheduleDetails();
    }
  }, [isRefreshList, filterBandState.selectedCustomerNo]);

  useEffect(() => {
    if (orderIdList.orderIDs !== null && orderIdList.orderIDs !== undefined) {
      fetchOrderArrivalData().then((resp) => {
        let LstrOrderState = "";
        let LstrOrderStateDesc = "";
        let LintCapacity = "";
        let LintActualInv;
        let LintInTransitInv;
        let LintReOrderPoint;
        let LstrUnitOfMeasure;
        let LintAvgDailyUsage;
        let responseData = [];
        let LstrPrevLoc = 0;
        let LstrPrevLoctionId = 0;
        let LstrPrevProductID = 0;
        let data;
        let strArrivalDateLinkOutput: boolean = false;

        for (let i = 0; i < orderScheduleList.length; i++) {
          let avgDisplay = false;
          if (orderScheduleList[i].ordeR_STATE === "USER_INTERFACE") {
            LstrOrderState = "M";
            LstrOrderStateDesc = "Order entered manually via OIS";
          } else if (orderScheduleList[i].ordeR_STATE === "OIS_GENERATION") {
            LstrOrderState = "OIS";
            LstrOrderStateDesc = "Order generated on OIS";
          } else if (orderScheduleList[i].ordeR_STATE === "OP_GENERATION") {
            LstrOrderState = "OP";
            if (orderScheduleList[i].ordeR_STATUS === "0") {
              LstrOrderStateDesc =
                orderScheduleList[i].ordeR_STATUS + " - Order Deleted";
            } else if (orderScheduleList[i].ordeR_STATUS === "1") {
              LstrOrderStateDesc =
                orderScheduleList[i].ordeR_STATUS + " - Order Entered";
            } else if (orderScheduleList[i].ordeR_STATUS === "2") {
              LstrOrderStateDesc =
                orderScheduleList[i].ordeR_STATUS + " - Order Printed";
            } else if (orderScheduleList[i].ordeR_STATUS === "3") {
              LstrOrderStateDesc =
                orderScheduleList[i].ordeR_STATUS + " - Order Shipped";
            } else if (orderScheduleList[i].ordeR_STATUS === "4") {
              LstrOrderStateDesc =
                orderScheduleList[i].ordeR_STATUS + " - Order Cancelled";
            } else if (orderScheduleList[i].ordeR_STATUS === "5") {
              LstrOrderStateDesc =
                orderScheduleList[i].ordeR_STATUS + " - Order Reissued";
            } else if (orderScheduleList[i].ordeR_STATUS === "6") {
              LstrOrderStateDesc =
                orderScheduleList[i].ordeR_STATUS + " - Adjusted Rule 102";
            } else if (orderScheduleList[i].ordeR_STATUS === "7") {
              LstrOrderStateDesc =
                orderScheduleList[i].ordeR_STATUS + " - Deleted Rule 102";
            } else {
              LstrOrderStateDesc =
                orderScheduleList[i].ordeR_STATUS + "Tentative Order";
            }
          } else if (orderScheduleList[i].ordeR_STATE === "SHIPMENT_UPLOAD") {
            LstrOrderState = "S";
            LstrOrderStateDesc = "Order shipped";
          } else if (orderScheduleList[i].ordeR_STATE === null) {
            LstrOrderState = "0";
            LstrOrderStateDesc = "Zero orders exist for this product-location";
          } else {
            LstrOrderState = "NA";
            LstrOrderStateDesc = "Not Available (Pre-Phase II OIS)";
          }

          if (orderScheduleList[i].uom.trim() === "MT") {
            LstrUnitOfMeasure = "MT";
            LintCapacity = FormatNumber(
              Number(orderScheduleList[i].capacity) / 2204,
              2
            );
            LintActualInv = FormatNumber(
              Number(orderScheduleList[i].actuaL_INV) / 2204,
              2
            );
            LintInTransitInv = FormatNumber(
              Number(orderScheduleList[i].iN_TRANSIT_INV) / 2204,
              2
            );
            LintReOrderPoint = FormatNumber(
              Number(orderScheduleList[i].reordeR_PNT) / 2204,
              2
            );
            LintAvgDailyUsage = FormatNumber(
              Number(orderScheduleList[i].avG_DAILY_USAGE) / 2204,
              2
            );
          } else if (orderScheduleList[i].uom.trim() === "BU") {
            LstrUnitOfMeasure = orderScheduleList[i].uom.trim();
            LintCapacity = FormatNumber(
              Number(orderScheduleList[i].capacity) /
                Number(orderScheduleList[i].specific_Gravity),
              2
            );
            LintActualInv = FormatNumber(
              Number(orderScheduleList[i].actuaL_INV) /
                Number(orderScheduleList[i].specific_Gravity),
              2
            );
            LintInTransitInv = FormatNumber(
              Number(orderScheduleList[i].iN_TRANSIT_INV) /
                Number(orderScheduleList[i].specific_Gravity),
              2
            );
            LintReOrderPoint = FormatNumber(
              Number(orderScheduleList[i].reordeR_PNT) /
                Number(orderScheduleList[i].specific_Gravity),
              2
            );
            LintAvgDailyUsage = FormatNumber(
              Number(orderScheduleList[i].avG_DAILY_USAGE) /
                Number(orderScheduleList[i].specific_Gravity),
              2
            );
          } else {
            LstrUnitOfMeasure = orderScheduleList[i].uom.trim();
            LintCapacity = FormatNumber(
              Number(orderScheduleList[i].capacity),
              0
            );
            LintActualInv = FormatNumber(
              Number(orderScheduleList[i].actuaL_INV),
              0
            );
            LintInTransitInv = FormatNumber(
              Number(orderScheduleList[i].iN_TRANSIT_INV),
              0
            );
            LintReOrderPoint = FormatNumber(
              Number(orderScheduleList[i].reordeR_PNT),
              0
            );
            LintAvgDailyUsage = FormatNumber(
              Number(orderScheduleList[i].avG_DAILY_USAGE),
              0
            );
          }

          if (
            orderScheduleList[i].arrivaL_DATE !== "NA" &&
            orderScheduleList[i].arrivaL_DATE !== "" &&
            orderScheduleList[i].arrivaL_DATE !== null
          ) {
            let dateCompare = "";
            if (resp.responseData.length > 0) {
              let dataExist = resp.responseData.filter(
                (x: any) => x.voaA_ORDER_ID === orderScheduleList[i].ordeR_ID
              );
              if (dataExist.length > 0) {
                dateCompare = dataExist[0].voaA_ADJUSTED_ARRIVAL_DATE;
                if (dateCompare !== orderScheduleList[i].arrivaL_DATE) {
                  let dateTimeNow;
                  let dateTimeLastUpdate;
                  let duration;
                  let timeSinceUpdateInMinutes = 0;
                  dateTimeNow = moment(new Date()); // Today's Date
                  dateTimeLastUpdate = moment(
                    new Date(orderScheduleList[i].arrivaL_DATE)
                  ); // Arrival Date
                  duration = moment.duration(
                    dateTimeNow.diff(dateTimeLastUpdate)
                  );
                  timeSinceUpdateInMinutes = duration.asDays();
                  if (
                    timeSinceUpdateInMinutes === 1 ||
                    timeSinceUpdateInMinutes > 1
                  ) {
                    strArrivalDateLinkOutput = false;
                  } else {
                    strArrivalDateLinkOutput = true;
                  }
                }
              } else {
                strArrivalDateLinkOutput = false;
              }
            } else {
              strArrivalDateLinkOutput = false;
            }
          } else {
            strArrivalDateLinkOutput = false;
          }

          let adjArrivalDate = orderScheduleList[i].esT_ARRIVAL_DATE;
          if (orderScheduleList[i].wknD_FLG === "N") {
            if (
              adjArrivalDate !== null &&
              adjArrivalDate !== undefined &&
              adjArrivalDate !== ""
            ) {
              let weekDay = new Date(adjArrivalDate).toLocaleDateString(
                "en-US",
                { weekday: "long" }
              );
              if (weekDay === "Saturday") {
                let newAdjArrivalDate = new Date(adjArrivalDate);
                newAdjArrivalDate.setDate(newAdjArrivalDate.getDate() + 2);
                orderScheduleList[i].esT_ARRIVAL_DATE =
                  newAdjArrivalDate.toString();
              } else if (weekDay === "Sunday") {
                let newAdjArrivalDate = new Date(adjArrivalDate);
                newAdjArrivalDate.setDate(newAdjArrivalDate.getDate() + 1);
                orderScheduleList[i].esT_ARRIVAL_DATE =
                  newAdjArrivalDate.toString();
              }
            }
          }

          if (
            orderScheduleList[i].producT_ID === LstrPrevProductID &&
            orderScheduleList[i].locatioN_ID === LstrPrevLoctionId
          ) {
            if (i >= 1) {
              responseData[i - 1].parentRow = true;
              if (
                orderScheduleList[i].loC_SHIPTO !== LstrPrevLoc ||
                orderScheduleList[i].producT_ID !== LstrPrevProductID
              ) {
                if (orderScheduleList[i].week_Avg_Display > 0)
                  avgDisplay = true;
              }
              data = {
                parentRow: false,
                childRow: true,
                avgDisplay: avgDisplay,
                isArrivalDateAdjust: strArrivalDateLinkOutput,
                producT_ID: orderScheduleList[i].producT_ID,
                locatioN_ID: orderScheduleList[i].locatioN_ID,
                loC_SHIPTO: orderScheduleList[i].loC_SHIPTO,
                loC_NAME: orderScheduleList[i].loC_NAME,
                proD_CODE: orderScheduleList[i].proD_CODE,
                proD_DESC: orderScheduleList[i].proD_DESC,
                reordeR_QTY: orderScheduleList[i].reordeR_QTY,
                reordeR_PNT: LintReOrderPoint,
                avG_DAILY_USAGE: LintAvgDailyUsage,
                usagE_OVERRIDE: FormatNumber(
                  Number(orderScheduleList[i].usagE_OVERRIDE),
                  0
                ),
                esT_ORDER_DATE: orderScheduleList[i].esT_ORDER_DATE,
                esT_ARRIVAL_DATE: orderScheduleList[i].esT_ARRIVAL_DATE,
                maX_SHIP_QTY: orderScheduleList[i].maX_SHIP_QTY,
                uom: LstrUnitOfMeasure,
                ordeR_STATE: LstrOrderState,
                ordeR_NO: orderScheduleList[i].ordeR_NO,
                ordeR_STATUS: LstrOrderStateDesc,
                ordeR_NOTES: orderScheduleList[i].ordeR_NOTES,
                inventory: LintActualInv,
                capacity: LintCapacity,
                actuaL_INV: orderScheduleList[i].actuaL_INV,
                iN_TRANSIT_INV: LintInTransitInv,
                wknD_FLG: orderScheduleList[i].wknD_FLG,
                copc: orderScheduleList[i].copc,
                raiL_USER: orderScheduleList[i].raiL_USER,
                ordeR_DATE: orderScheduleList[i].ordeR_DATE,
                arrivaL_DATE: orderScheduleList[i].arrivaL_DATE,
                ordeR_ID: orderScheduleList[i].ordeR_ID,
                specific_Gravity: orderScheduleList[i].specific_Gravity,
                week_Avg_Display: orderScheduleList[i].week_Avg_Display,
                vpL_CALCULATED_WEEK_AVG:
                  orderScheduleList[i].vpL_CALCULATED_WEEK_AVG,
                vpL_OPERATING_DAYS: orderScheduleList[i].vpL_OPERATING_DAYS,
              };
              responseData.push(data);
            } else {
              if (
                orderScheduleList[i].loC_SHIPTO !== LstrPrevLoc ||
                orderScheduleList[i].producT_ID !== LstrPrevProductID
              ) {
                if (orderScheduleList[i].week_Avg_Display > 0)
                  avgDisplay = true;
              }
              data = {
                parentRow: false,
                childRow: false,
                avgDisplay: avgDisplay,
                isArrivalDateAdjust: strArrivalDateLinkOutput,
                producT_ID: orderScheduleList[i].producT_ID,
                locatioN_ID: orderScheduleList[i].locatioN_ID,
                loC_SHIPTO: orderScheduleList[i].loC_SHIPTO,
                loC_NAME: orderScheduleList[i].loC_NAME,
                proD_CODE: orderScheduleList[i].proD_CODE,
                proD_DESC: orderScheduleList[i].proD_DESC,
                reordeR_QTY: orderScheduleList[i].reordeR_QTY,
                reordeR_PNT: LintReOrderPoint,
                avG_DAILY_USAGE: LintAvgDailyUsage,
                usagE_OVERRIDE: FormatNumber(
                  Number(orderScheduleList[i].usagE_OVERRIDE),
                  0
                ),
                esT_ORDER_DATE: orderScheduleList[i].esT_ORDER_DATE,
                esT_ARRIVAL_DATE: orderScheduleList[i].esT_ARRIVAL_DATE,
                maX_SHIP_QTY: orderScheduleList[i].maX_SHIP_QTY,
                uom: LstrUnitOfMeasure,
                ordeR_STATE: LstrOrderState,
                ordeR_NO: orderScheduleList[i].ordeR_NO,
                ordeR_STATUS: LstrOrderStateDesc,
                ordeR_NOTES: orderScheduleList[i].ordeR_NOTES,
                inventory: LintActualInv,
                capacity: LintCapacity,
                actuaL_INV: orderScheduleList[i].actuaL_INV,
                iN_TRANSIT_INV: LintInTransitInv,
                wknD_FLG: orderScheduleList[i].wknD_FLG,
                copc: orderScheduleList[i].copc,
                raiL_USER: orderScheduleList[i].raiL_USER,
                ordeR_DATE: orderScheduleList[i].ordeR_DATE,
                arrivaL_DATE: orderScheduleList[i].arrivaL_DATE,
                ordeR_ID: orderScheduleList[i].ordeR_ID,
                specific_Gravity: orderScheduleList[i].specific_Gravity,
                week_Avg_Display: orderScheduleList[i].week_Avg_Display,
                vpL_CALCULATED_WEEK_AVG:
                  orderScheduleList[i].vpL_CALCULATED_WEEK_AVG,
                vpL_OPERATING_DAYS: orderScheduleList[i].vpL_OPERATING_DAYS,
              };
              responseData.push(data);
            }
          } else {
            if (
              orderScheduleList[i].loC_SHIPTO !== LstrPrevLoc ||
              orderScheduleList[i].producT_ID !== LstrPrevProductID
            ) {
              if (orderScheduleList[i].week_Avg_Display > 0) avgDisplay = true;
            }
            data = {
              parentRow: false,
              childRow: false,
              avgDisplay: avgDisplay,
              isArrivalDateAdjust: strArrivalDateLinkOutput,
              producT_ID: orderScheduleList[i].producT_ID,
              locatioN_ID: orderScheduleList[i].locatioN_ID,
              loC_SHIPTO: orderScheduleList[i].loC_SHIPTO,
              loC_NAME: orderScheduleList[i].loC_NAME,
              proD_CODE: orderScheduleList[i].proD_CODE,
              proD_DESC: orderScheduleList[i].proD_DESC,
              reordeR_QTY: orderScheduleList[i].reordeR_QTY,
              reordeR_PNT: LintReOrderPoint,
              avG_DAILY_USAGE: LintAvgDailyUsage,
              usagE_OVERRIDE: FormatNumber(
                Number(orderScheduleList[i].usagE_OVERRIDE),
                0
              ),
              esT_ORDER_DATE: orderScheduleList[i].esT_ORDER_DATE,
              esT_ARRIVAL_DATE: orderScheduleList[i].esT_ARRIVAL_DATE,
              maX_SHIP_QTY: orderScheduleList[i].maX_SHIP_QTY,
              uom: LstrUnitOfMeasure,
              ordeR_STATE: LstrOrderState,
              ordeR_NO: orderScheduleList[i].ordeR_NO,
              ordeR_STATUS: LstrOrderStateDesc,
              ordeR_NOTES: orderScheduleList[i].ordeR_NOTES,
              inventory: LintActualInv,
              capacity: LintCapacity,
              actuaL_INV: orderScheduleList[i].actuaL_INV,
              iN_TRANSIT_INV: LintInTransitInv,
              wknD_FLG: orderScheduleList[i].wknD_FLG,
              copc: orderScheduleList[i].copc,
              raiL_USER: orderScheduleList[i].raiL_USER,
              ordeR_DATE: orderScheduleList[i].ordeR_DATE,
              arrivaL_DATE: orderScheduleList[i].arrivaL_DATE,
              ordeR_ID: orderScheduleList[i].ordeR_ID,
              specific_Gravity: orderScheduleList[i].specific_Gravity,
              week_Avg_Display: orderScheduleList[i].week_Avg_Display,
              vpL_CALCULATED_WEEK_AVG:
                orderScheduleList[i].vpL_CALCULATED_WEEK_AVG,
              vpL_OPERATING_DAYS: orderScheduleList[i].vpL_OPERATING_DAYS,
            };
            responseData.push(data);
          }
          LstrPrevLoc = orderScheduleList[i].loC_SHIPTO;
          LstrPrevLoctionId = orderScheduleList[i].locatioN_ID;
          LstrPrevProductID = orderScheduleList[i].producT_ID;
        }
        setPrintData(responseData);
        //dispatch(filterBandActions.setOrderScheduleList(responseData));
        setExcelData(orderScheduleList);
        setOrderScheduleList(responseData);
        setRefreshList(false);
      });
    }
  }, [orderIdList]);

  const getOrderScheduleDetails = () => {
    if (
      filterBandState.selectedCustomerNo !== null &&
      filterBandState.selectedCustomerNo !== undefined &&
      filterBandState.selectedCustomerNo !== ""
    ) {
      fetchOrdersData().then((res) => {
        let orderList = "";
        for (let i = 0; i < res.responseData.length; i++) {
          if (
            res.responseData[i].ordeR_ID !== "" &&
            res.responseData[i].ordeR_ID !== null
          ) {
            if (orderList === "") {
              orderList = res.responseData[i].ordeR_ID;
            } else {
              orderList = orderList + "," + res.responseData[i].ordeR_ID;
            }
          }
        }
        if (res.responseData.length > 0) {
          setOrderScheduleList(res.responseData);
          if (orderList !== null && orderList !== undefined) {
            setorderIdList({ orderIDs: orderList.toString() });
          } else {
            setorderIdList({ orderIDs: null });
          }
        }
      });
    }
  };

  const onPrintClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    Promise.resolve().then(() => {
      setShowOrderSchedulePrint(true);
      setOrderScheduleShowModalPopup(true);
    });
  };

  const onModalClose = (e: React.MouseEvent<HTMLButtonElement>) => {
    Promise.resolve().then(() => {
      setShowOrderSchedulePrint(false);
      setOrderScheduleShowModalPopup(false);
    });
  };

  const onViewClick = (
    locatioN_ID: number,
    producT_ID: number,
    ordeR_ID: number
  ) => {
    let notesObj = {
      locatioN_ID: locatioN_ID,
      producT_ID: producT_ID,
      ordeR_ID: ordeR_ID,
    };
    setDataForOrderNotes(notesObj);
    if (ordeR_ID !== null) {
      setShowOrderNotesModalPopup(true);
    }
  };

  const onOrderNotesModalClose = (e: React.MouseEvent<HTMLButtonElement>) => {
    Promise.resolve().then(() => {
      setShowOrderNotesModalPopup(false);
    });
  };

  const openArrivalDateAdjust = (orderId: number) => {
    Promise.resolve().then(() => {
      setOrderId(orderId);
      setArrivalDateAdjustShowModalPopup(true);
    });
  };

  const onArrivalDateAdjustModalClose = (
    e: React.MouseEvent<HTMLButtonElement>
  ) => {
    Promise.resolve().then(() => {
      setArrivalDateAdjustShowModalPopup(false);
    });
  };

  function onExcelClick() {
    const data = CSVDataSort();
    GenerateCSV("OISOrderSchedule", data, ",,,,VMI Orders Schedule\n\n");
  }

  const CSVDataSort = () => {
    const allData: any = [];
    const Header = {
      Name: "",
      No: "",
      Prod: "",
      Capacity: "",
      Inventory: "",
      "In-Transit": "",
      Point: "",
      "Avg. Daily Usage": "",
      "Usage Override": "",
      Order: "",
      Arrival: "",
      Status: "",
      Number: "",
      Notes: "",
    };
    allData.push(Header);

    for (let i = 0; i < excelData.length; i++) {
      if (excelData[i].ordeR_STATE === "USER_INTERFACE") {
        excelData[i].ordeR_STATE = "M";
      } else if (excelData[i].ordeR_STATE === "OIS_GENERATION") {
        excelData[i].ordeR_STATE = "OIS";
      } else if (excelData[i].ordeR_STATE === "OP_GENERATION") {
        excelData[i].ordeR_STATE = "OP";
      } else if (excelData[i].ordeR_STATE === "SHIPMENT_UPLOAD") {
        excelData[i].ordeR_STATE = "S";
      } else if (excelData[i].ordeR_STATE === "null") {
        excelData[i].ordeR_STATE = "0";
      } else {
        excelData[i].ordeR_STATE = "NA";
      }
      let openOrderData = {
        Name: excelData[i].loC_NAME,
        No: excelData[i].loC_SHIPTO,
        Prod: excelData[i].proD_CODE,
        Capacity: excelData[i].capacity,
        Inventory: excelData[i].inventory,
        "In-Transit": excelData[i].iN_TRANSIT_INV,
        Point: excelData[i].reordeR_PNT,
        "Avg. Daily Usage": excelData[i].avG_DAILY_USAGE,
        "Usage Override":
          excelData[i].usagE_OVERRIDE === null
            ? ""
            : excelData[i].usagE_OVERRIDE,
        Order: dayjs(orderScheduleList[i].esT_ORDER_DATE).format("MM/DD/YYYY"),
        Arrival: dayjs(orderScheduleList[i].esT_ARRIVAL_DATE).format(
          "MM/DD/YYYY"
        ),
        Status: excelData[i].ordeR_STATE,
        Number: excelData[i].ordeR_NO,
        Notes: excelData[i].ordeR_NOTES,
      };
      allData.push(openOrderData);
    }
    return allData;
  };

  return (
    <React.Fragment>
      <PageHeader
        title="Order Schedule"
        subtitle="Select a customer from the drop-down list above to show pending and/or unshipped orders.
Select a location from the drop-down list above to show orders specific to that location."
      ></PageHeader>
      <Card
        sx={{
          width: "100hv",
          borderRadius: 3,
          margin: 2,
          mt: 0,
        }}
        elevation={6}
      >
        <CustomCardHeader
          title=""
          action={
            <div>
              <Button
                onClick={onPrintClick}
                sx={{ mr: 1 }}
                startIcon={<PrintIcon />}
              >
                Print
              </Button>
              <Button onClick={onExcelClick} startIcon={<DownloadIcon />}>
                Export
              </Button>
            </div>
          }
        ></CustomCardHeader>
        <CardContent sx={{ pb: 0 }}>
          <Tbl>
            <TableBody>
              {GetRowsAfterPagingAndSorting().map((row, index) => (
                <>
                  {(row.parentRow === true && row.childRow === false) ||
                  (row.parentRow === false && row.childRow === false) ? (
                    <TableRow key={index}>
                      <TableCell scope="row">
                        {new Date(new Date(row.esT_ORDER_DATE).toDateString()) <
                        new Date(new Date().toDateString()) ? (
                          <RouterLink
                            to={`/locationShipmentSummary`}
                            style={{
                              color: "red",
                            }}
                          >
                            {row.loC_NAME}
                          </RouterLink>
                        ) : (
                          <RouterLink
                            to={`/locationShipmentSummary`}
                            style={{
                              color: "blue",
                            }}
                          >
                            {row.loC_NAME}
                          </RouterLink>
                        )}
                      </TableCell>
                      <TableCell scope="row">{row.loC_SHIPTO}</TableCell>
                      <TableCell scope="row" title={row.proD_DESC}>
                        {row.proD_CODE}
                      </TableCell>
                      <TableCell scope="row">
                        {row.capacity === ""
                          ? ""
                          : row.capacity + " " + row.uom.toLowerCase()}
                      </TableCell>
                      <TableCell scope="row">
                        {row.inventory === ""
                          ? ""
                          : row.inventory + " " + row.uom.toLowerCase()}
                      </TableCell>
                      <TableCell scope="row">
                        {row.iN_TRANSIT_INV === "-1"
                          ? ""
                          : row.iN_TRANSIT_INV + " " + row.uom.toLowerCase()}
                      </TableCell>
                      <TableCell scope="row">
                        {row.reordeR_PNT === ""
                          ? ""
                          : row.reordeR_PNT + " " + row.uom.toLowerCase()}
                      </TableCell>
                      <TableCell scope="row">
                        {row.avG_DAILY_USAGE === ""
                          ? ""
                          : row.avG_DAILY_USAGE + " " + row.uom.toLowerCase()}
                      </TableCell>
                      <TableCell scope="row">
                        {row.usagE_OVERRIDE === null ||
                        row.usagE_OVERRIDE === undefined
                          ? 0 + " " + row.uom.toLowerCase()
                          : row.usagE_OVERRIDE + " " + row.uom.toLowerCase()}
                      </TableCell>
                      <TableCell scope="row">
                        {row.ordeR_DATE === null
                          ? "NA"
                          : dayjs(row.ordeR_DATE).format("MM/DD/YYYY")}
                      </TableCell>
                      <TableCell scope="row">
                        {row.arrivaL_DATE === null ? (
                          "NA"
                        ) : row.isArrivalDateAdjust === true ? (
                          <Link
                            component="button"
                            variant="body2"
                            sx={{ fontSize: "0.90rem", color: "green" }}
                            onClick={(e) => openArrivalDateAdjust(row.ordeR_ID)}
                          >
                            {dayjs(row.arrivaL_DATE).format("MM/DD/YYYY")}
                          </Link>
                        ) : (new Date().getTime() -
                            new Date(row.arrivaL_DATE).getTime()) /
                            (1000 * 3600 * 24) >
                          0 ? (
                          <span style={{ color: "red" }}>
                            {" "}
                            {dayjs(row.arrivaL_DATE).format("MM/DD/YYYY")}{" "}
                          </span>
                        ) : (
                          <span>
                            {" "}
                            {dayjs(row.arrivaL_DATE).format("MM/DD/YYYY")}{" "}
                          </span>
                        )}
                      </TableCell>
                      <TableCell scope="row" title={row.ordeR_STATUS}>
                        {row.ordeR_STATE === "null" || row.ordeR_STATE === "NA"
                          ? 0
                          : row.ordeR_STATE}
                      </TableCell>
                      <TableCell scope="row">{row.ordeR_NO}</TableCell>
                      <TableCell scope="row">
                        <Button
                          variant="text"
                          sx={{ fontSize: "0.90rem", color: "green" }}
                          onClick={(e) =>
                            onViewClick(
                              row.locatioN_ID,
                              row.producT_ID,
                              row.ordeR_ID
                            )
                          }
                        >
                          View
                        </Button>
                      </TableCell>
                    </TableRow>
                  ) : (
                    <TableRow key={index}>
                      <TableCell colSpan={9} align="right" scope="row">
                        &nbsp;
                      </TableCell>
                      <TableCell>
                        {row.ordeR_DATE === null
                          ? "NA"
                          : dayjs(row.ordeR_DATE).format("MM/DD/YYYY")}
                      </TableCell>
                      <TableCell scope="row">
                        {row.arrivaL_DATE === null ? (
                          "NA"
                        ) : row.isArrivalDateAdjust === true ? (
                          <Link
                            component="button"
                            variant="body2"
                            sx={{
                              fontWeight: "bold",
                              fontSize: "0.90rem",
                              color: "green",
                            }}
                            onClick={(e) => openArrivalDateAdjust(row.ordeR_ID)}
                          >
                            {dayjs(row.arrivaL_DATE).format("MM/DD/YYYY")}
                          </Link>
                        ) : (new Date().getTime() -
                            new Date(row.arrivaL_DATE).getTime()) /
                            (1000 * 3600 * 24) >
                          0 ? (
                          <span style={{ color: "red" }}>
                            {" "}
                            {dayjs(row.arrivaL_DATE).format("MM/DD/YYYY")}{" "}
                          </span>
                        ) : (
                          <span>
                            {" "}
                            {dayjs(row.arrivaL_DATE).format("MM/DD/YYYY")}{" "}
                          </span>
                        )}
                      </TableCell>
                      <TableCell scope="row" title={row.ordeR_STATUS}>
                        {row.ordeR_STATE}
                      </TableCell>
                      <TableCell scope="row">{row.ordeR_NO}</TableCell>
                      <TableCell scope="row">
                        <Button
                          variant="text"
                          sx={{ fontSize: "0.90rem", color: "green" }}
                          onClick={(e) =>
                            onViewClick(
                              row.locatioN_ID,
                              row.producT_ID,
                              row.ordeR_ID
                            )
                          }
                        >
                          View
                        </Button>
                      </TableCell>
                    </TableRow>
                  )}

                  {row.avgDisplay === true ? (
                    <TableRow>
                      <TableCell colSpan={9} align="center" scope="row">
                        <span style={{ fontWeight: "bold" }}>
                          Average Daily Usage for the last{" "}
                          {row.week_Avg_Display} weeks at{" "}
                          {row.vpL_OPERATING_DAYS} operating days per week:
                          {row.uom === "MT"
                            ? " " +
                              FormatInvResult(
                                Number(
                                  Number(row.vpL_CALCULATED_WEEK_AVG) / 2204
                                ),
                                row.uom,
                                2
                              )
                            : row.uom === "BU"
                            ? " " +
                              FormatInvResult(
                                Number(
                                  Number(row.vpL_CALCULATED_WEEK_AVG) /
                                    Number(row.specific_Gravity)
                                ),
                                row.uom,
                                2
                              )
                            : " " +
                              FormatInvResult(
                                Number(row.vpL_CALCULATED_WEEK_AVG),
                                row.uom,
                                0
                              )}
                        </span>
                      </TableCell>
                      <TableCell scope="row">&nbsp;</TableCell>
                      <TableCell scope="row">&nbsp;</TableCell>
                      <TableCell scope="row">&nbsp;</TableCell>
                      <TableCell scope="row">&nbsp;</TableCell>
                      <TableCell scope="row">&nbsp;</TableCell>
                    </TableRow>
                  ) : (
                    ""
                  )}

                  {(row.parentRow === false && row.childRow === true) ||
                  (row.parentRow === false && row.childRow === false) ? (
                    <TableRow>
                      <TableCell colSpan={9} align="right" scope="row">
                        <i>Estimation</i>
                      </TableCell>
                      <TableCell scope="row">
                        {dayjs(row.esT_ORDER_DATE).format("MM/DD/YYYY")}
                      </TableCell>
                      <TableCell scope="row">
                        {dayjs(row.esT_ARRIVAL_DATE).format("MM/DD/YYYY")}
                      </TableCell>
                      <TableCell scope="row">&nbsp;</TableCell>
                      <TableCell scope="row">&nbsp;</TableCell>
                      <TableCell scope="row">&nbsp;</TableCell>
                    </TableRow>
                  ) : (
                    ""
                  )}
                </>
              ))}
            </TableBody>
          </Tbl>
        </CardContent>
      </Card>
      <ModalPopup
        open={showOrderScheduleModalPopup}
        title={"Order Schedule"}
        onPopupClose={onModalClose}
        width="1500px"
      >
        <OrderSchedulePrint
          data={printData}
          setOrderScheduleShowModalPopup={setOrderScheduleShowModalPopup}
          showOrderSchedulePrint={showOrderSchedulePrint}
        />
      </ModalPopup>
      <ModalPopup
        open={showArrivalDateAdjustShowModalPopup}
        title={"Order Arrival Date Adjustment"}
        onPopupClose={onArrivalDateAdjustModalClose}
        width="600px"
      >
        <ArrivalDateAdjustment
          orderId={orderId}
          setArrivalDateAdjustShowModalPopup={
            setArrivalDateAdjustShowModalPopup
          }
        />
      </ModalPopup>
      <ModalPopup
        open={showOrderNotesModalPopup}
        title={"Order Notes"}
        onPopupClose={onOrderNotesModalClose}
        width="700px"
      >
        <OrderNotes
          dataFromOrderSchedule={dataForOrderNotes}
          setShowOrderNotesModalPopup={setShowOrderNotesModalPopup}
        />
      </ModalPopup>
    </React.Fragment>
  );
}

export default OrderScheduleDetails;
