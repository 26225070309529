/* eslint-disable react-hooks/exhaustive-deps */
import TableBody from "@mui/material/TableBody";
import { Button, Card, CardContent } from "@mui/material";
import { LocationInfo } from "../../../models/location";
import { StyledTableCell, StyledTableRow } from "../../controls/tablecontrols";
import { useEffect, useState } from "react";
import useTable from "../../../hooks/useTable";
import { TableHeaderCell } from "../../../models/common";
import { filterBandSelector, useAppDispatch } from "../../../store/store";
import CustomCardHeader from "../../shared/CustomCardHeader";
import { Link as RouterLink } from "react-router-dom";
import { filterBandActions } from "../../../store/filterband-slice";
import { IFilterBandState } from "../../../interfaces/storeInterfaces";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { uiActions } from "../../../store/ui-slice";

interface Props {
  customerNo: string;
}

function CustomerLocationList({ customerNo }: Props) {
  const role = sessionStorage.getItem("roles");
  const dispatch = useAppDispatch();
  const filterBandState: IFilterBandState = useSelector(filterBandSelector);
  const navigate = useNavigate();
  const [customerLocations, setLocations] = useState<LocationInfo[]>([]);

  const tableHeaderCellDetails: TableHeaderCell[] = [
    { id: "siteNumber", label: "Site #" },
    { id: "locationName", label: "Location Name" },
  ];

  useEffect(() => {
    if (customerNo) {
      setLocations(filterBandState.locations);
    } else {
      setLocations([]);
    }
  }, [filterBandState.locations]);

  const { Tbl, GetRowsAfterPagingAndSorting } = useTable<LocationInfo>(
    customerLocations,
    tableHeaderCellDetails,
    true
  );
  
  const handleLocationSelect = (locationNo: string, locationName: string) => {
    if (locationNo !== null && locationNo !== undefined && locationNo !== "") {
      dispatch(
        filterBandActions.setSelectedLocation({
          locationNo: locationNo,
        })
      );
    }
  };

  const onAddClick = () => {
    if (customerNo !== "" && customerNo !== undefined && customerNo !== null) {
      navigate("/locations", {
        state: { editlocationNo: "", editModal: true },
      });
    } else {
      dispatch(
        uiActions.displayNotification({
          message: "Customer not selected",
          severity: "error",
        })
      );
    }
  };

  return (
    <Card
      sx={{ width: "100hv", borderRadius: 3, margin: 2, mt: 0 }}
      elevation={6}
    >
      <CustomCardHeader
        title="Locations"
        action={
          role?.toString().toUpperCase() === "ADMIN" ? (
            <Button
              variant="text"
              sx={{ fontSize: "0.90rem" }}
              onClick={onAddClick}
            >
              <u>Add New Location</u>
            </Button>
          ) : (
            ""
          )
        }
      ></CustomCardHeader>
      <CardContent sx={{ pb: 0 }}>
        <Tbl>
          <TableBody>
            {GetRowsAfterPagingAndSorting().map((row) => (
              <StyledTableRow key={row.locationName}>
                <StyledTableCell>{row.siteNumber}</StyledTableCell>
                <StyledTableCell>
                  <RouterLink
                    onClick={() =>
                      handleLocationSelect(row.locationNo, row.locationName)
                    }
                    to={"/location"}
                  >
                    {row.locationName}
                  </RouterLink>
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Tbl>
      </CardContent>
    </Card>
  );
}
export default CustomerLocationList;
