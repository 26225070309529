/* eslint-disable import/no-anonymous-default-export */
import useAxiosGet from "../hooks/useAxiosGet";
import { Product, RailcarInventory, ProductType, ShipmentCountDetails, ShipmentCountLoadedCarrier, ShipmentCountLoadedNonUs, ShipmentCountNonUs, CustomerProducts, AssignedProducts } from "../models/product";
import useAxiosPost from "../hooks/useAxiosPost";
import useAxiosPut from "../hooks/useAxiosPut";

const isLocal = () => process.env.REACT_APP_ENVIR === "LOCAL";
const userId = () => sessionStorage.getItem("userMailID");
const date = new Date().toJSON().slice(0, 10);

const GetDefaultProduct = (): Product => {
  const defaultProduct = {
    vP_PRODUCT_ID: 0,
    vP_PRODUCT_TYPE_ID: 0,
    vP_PRODUCT_CODE: "",
    vP_PRODUCT_DESC: "",
    vP_PRODUCT_GROUP: "",
    vP_UNIT_OF_MEASURE: "",
    vP_SPECIFIC_GRAVITY: "",
    vP_PRODUCT_ABBR: "",
  } as Product;

  return defaultProduct;
};

const RetrieveProducts = () => {
  return useAxiosGet<Product[]>(
    isLocal() ? `/Product/RetrieveProducts/products` : `/products`,
    [],
    "Product Information"
  );
};

const RetrieveRailCarInventories = (
  customerNo: string,
  locationNo?: string
) => {
  let optionalParameters = "";
  if (locationNo !== "" && locationNo !== null && locationNo !== undefined) {
    optionalParameters = `?locationNo=${locationNo}&date=${date}`;
  }
  return useAxiosGet<RailcarInventory[]>(
    isLocal()
      ? `/Product/RetrieveRailCarInventories/upn/${userId()}/customers/${customerNo}/railcarinventories${optionalParameters}`
      : `/upn/${userId()}/customers/${customerNo}/railcarinventories${optionalParameters}`,
    [],
    "Railcar Inventory"
  );
};

const RetrieveProductType = () => {
  return useAxiosGet<ProductType[]>(
    isLocal() ? `/Product/RetrieveProductType/producttypes` : `producttypes`,
    [],
    "Product Type"
  );
};

const UpdateProduct = (data: any) => {
  return useAxiosPut(
    isLocal() ? `/Product/UpdateProduct/products` : `/products`,
    data,
    "Update Product"
  );
};

const CreateProduct = (data: any) => {
  return useAxiosPost(
    isLocal()
      ? `/Product/CreateProduct/users/${userId()}/products`
      : `/users/${userId()}/products`,
    data,
    "Create Product"
  );
};

const RetrieveShipmentCountNonUs = (locationNo: string) => {
  return useAxiosGet<ShipmentCountNonUs[]>(isLocal() ?
    `/Product/RetrieveShipmentCountNonUs/locations/${locationNo}/soldtolocations` :
    `/locations/${locationNo}/soldtolocations`,
    [],
    "Shipment Count for Non-US");
};

const RetrieveShipmentCountLoadedNonUs = (locationNo: string) => {
  return useAxiosGet<ShipmentCountLoadedNonUs[]>(isLocal() ?
    `/Product/RetrieveShipmentCountLoadedNonUs/locations/${locationNo}/nonusloadedcounts` :
    `/locations/${locationNo}/nonusloadedcounts`,
    [],
    "Shipment Count Loaded for Non-US");
};

const RetrieveShipmentCountLoaded = (locationNo: string, productCode: string) => {
  let optionsParamsURL = '';
  if (productCode != null && productCode !== '' && productCode !== undefined) {
    optionsParamsURL += `productCode=${productCode}`;
  }
  return useAxiosGet<ShipmentCountLoadedNonUs[]>(isLocal() ?
    `/Product/RetrieveShipmentCountLoaded/locations/${locationNo}/loadedcounts?${optionsParamsURL}` :
    `/locations/${locationNo}/loadedcounts?${optionsParamsURL}`,
    [],
    "Shipment Loaded Count");
};

const RetrieveShipmentCountLoadedDecatur = (locationNo: string, productCode: string) => {
  let optionsParamsURL = '';
  if (productCode != null && productCode !== '' && productCode !== undefined) {
    optionsParamsURL += `productCode=${productCode}`;
  }
  return useAxiosGet<ShipmentCountLoadedNonUs[]>(isLocal() ?
    `/Product/RetrieveShipmentCountLoadedDecatur/locations/${locationNo}/decaturloadedcounts?${optionsParamsURL}` :
    `/locations/${locationNo}/decaturloadedcounts?${optionsParamsURL}`,
    [],
    "Shipment Decatur Loaded Count");
};

const RetrieveShipmentCountLoadedCarrier = (locationNo: string, productCode: string) => {
  let optionsParamsURL = '';
  if (productCode != null && productCode !== '' && productCode !== undefined) {
    optionsParamsURL += `productCode=${productCode}`;
  }
  return useAxiosGet<ShipmentCountLoadedCarrier[]>(isLocal() ?
    `/Product/RetrieveShipmentCountLoadedCarrier/locations/${locationNo}/carrierloadedcounts?${optionsParamsURL}` :
    `/locations/${locationNo}/carrierloadedcounts?${optionsParamsURL}`,
    [],
    "Shipment Carrier Loaded Count");
};

const RetrieveShipmentCountLoadedAll = (customerNo: string, productCode: string) => {
  let optionsParamsURL = '';
  if (productCode != null && productCode !== '' && productCode !== undefined) {
    optionsParamsURL += `productCode=${productCode}`;
  }

  return useAxiosGet<ShipmentCountLoadedCarrier[]>(isLocal() ?
    `/Product/RetrieveShipmentCountLoadedAll/customers/${customerNo}/allloadedcounts?${optionsParamsURL}` :
    `/customers/${customerNo}/allloadedcounts?${optionsParamsURL}`,
    [],
    "Shipment Carrier Loaded All");
};

const RetrieveShipmentCountEmpty = (productCode: string) => {
  let optionsParamsURL = '';
  if (productCode != null && productCode !== '' && productCode !== undefined) {
    optionsParamsURL += `productCode=${productCode}`;
  }

  return useAxiosGet<ShipmentCountDetails[]>(isLocal() ?
    `/Product/RetrieveShipmentCountEmpty/emptyshipmentcounts?${optionsParamsURL}` :
    `/emptyshipmentcounts?${optionsParamsURL}`,
    [],
    "Shipment Carrier Loaded All");
};

const RetrieveShipmentCountEmptyDecatur = (productCode: string) => {
  let optionsParamsURL = '';
  if (productCode != null && productCode !== '' && productCode !== undefined) {
    optionsParamsURL += `productCode=${productCode}`;
  }

  return useAxiosGet<ShipmentCountDetails[]>(isLocal() ?
    `/Product/RetrieveShipmentCountEmptyDecatur/emptydecaturshipmentcounts?${optionsParamsURL}` :
    `/emptydecaturshipmentcounts?${optionsParamsURL}`,
    [],
    "Shipment Carrier Loaded All Decatur");
};

const RetrieveShipmentCountEmptyAtShop = () => {
  return useAxiosGet<ShipmentCountDetails[]>(isLocal() ?
    `/Product/RetrieveShipmentCountEmptyAtShop/emptyatshopshipmentcounts` :
    `/emptyatshopshipmentcounts`,
    [],
    "Shipment Count Empty At Stop");
};

const RetrieveShipmentCountLoadedForAll = (locationNo: string, productCode: string) => {
  let optionsParamsURL = '';
  if (productCode != null && productCode !== '' && productCode !== undefined) {
    optionsParamsURL += `productCode=${productCode}`;
  }
  return useAxiosGet<ShipmentCountDetails[]>(isLocal() ?
    `/Product/RetrieveShipmentCountLoadedForAll/customers/${locationNo}/forallloadedcounts?${optionsParamsURL}` :
    `/customers/${locationNo}/forallloadedcounts?${optionsParamsURL}`,
    [],
    "Shipment Count Loaded For All");
};

const RetrieveShipmentCountLoadedForAllCustomer = (customerNo: string, productCode: string) => {
  let optionsParamsURL = '';
  if (productCode != null && productCode !== '' && productCode !== undefined) {
    optionsParamsURL += `productCode=${productCode}`;
  }
  return useAxiosGet<ShipmentCountDetails[]>(isLocal() ?
    `/Product/RetrieveShipmentCountLoadedForAllCustomer/customers/${customerNo}/customerloadedcounts?${optionsParamsURL}` :
    `/customers/${customerNo}/customerloadedcounts?${optionsParamsURL}`,
    [],
    "Shipment Count Loaded For All Customer");
};

const RetrieveShipmentCountLoadedForAllOL = () => {
  return useAxiosGet<ShipmentCountDetails[]>(isLocal() ?
    `/Product/RetrieveShipmentCountLoadedForAllOL/allolloadedcounts` :
    `/allolloadedcounts`,
    [],
    "Shipment Count Loaded For All Customer");
};

const RetrieveAllProducts = () => {
  return useAxiosGet<CustomerProducts[]>(isLocal() ? `/Product/RetrieveAllProducts/allproducts` : `/allproducts`, [], "Customer Products");
};

const RetrieveUserProducts = (userEmailID: string) => {
  return useAxiosGet<AssignedProducts[]>(isLocal() ? `/Product/RetrieveUserProducts/upn/${userEmailID}/userproducts` : `/upn/${userEmailID}/userproducts`, [], "Products Assigned to User");
};

export default {
  RetrieveProducts,
  RetrieveRailCarInventories,
  RetrieveProductType,
  CreateProduct,
  UpdateProduct,
  GetDefaultProduct,
  RetrieveShipmentCountNonUs,
  RetrieveShipmentCountLoadedNonUs,
  RetrieveShipmentCountLoaded,
  RetrieveShipmentCountLoadedDecatur,
  RetrieveShipmentCountLoadedCarrier,
  RetrieveShipmentCountLoadedAll,
  RetrieveShipmentCountEmpty,
  RetrieveShipmentCountEmptyDecatur,
  RetrieveShipmentCountEmptyAtShop,
  RetrieveShipmentCountLoadedForAll,
  RetrieveShipmentCountLoadedForAllCustomer,
  RetrieveShipmentCountLoadedForAllOL,
  RetrieveAllProducts,
  RetrieveUserProducts
};
