import React, { useEffect, useRef, useState } from "react";
import { useReactToPrint } from "react-to-print";
import { Button, Divider, Grid } from "@mui/material";
import { OrderSchedule } from "../../../models/order";
import dayjs from "dayjs";

interface OrderSchedulePrintProps {
  data: OrderSchedule[];
  setOrderScheduleShowModalPopup: React.Dispatch<React.SetStateAction<boolean>>;
  showOrderSchedulePrint: boolean;
}

function OrderSchedulePrint(props: OrderSchedulePrintProps) {
  const componentRef = useRef(null);
  const [orderScheduleList, setOrderScheduleList] = useState<OrderSchedule[]>(
    []
  );


  const tableStyle = {
    div: {
      padding: "10px",
      paddingLeft: "30px",
      paddingRight: "30px",
    },
    table: {
      border: "1px solid black",
      "border-collapse": "collapse",
      width: "100%",
      "text-align": "left",
    },
    tdata: {
      border: "1px solid black",
      "border-collapse": "collapse",
      paddingLeft: "5px",
    },
  };

  useEffect(() => {
    if (props.data.length > 0) {
      setOrderScheduleList(props.data);
    }
  }, [props.data, props.showOrderSchedulePrint]);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  return (
    <div>
      <Button onClick={handlePrint}>Print</Button>
      <Divider sx={{ mt: 4, mb: 2 }}></Divider>
      <div id="printTable" ref={componentRef} style={tableStyle.div}>
        <Grid container spacing={3} sx={{ paddingTop: 1 }}>
          <Grid item xs={12} sm={12} md={12} lg={12} style={{ textAlign: "center", marginBottom: "10px", fontWeight: "bold", fontSize: "18px" }}><span >Order Schedule</span></Grid>
        </Grid>
        <table style={tableStyle.table}>
          <th style={tableStyle.tdata}>Name</th>
          <th style={tableStyle.tdata}>No</th>
          <th style={tableStyle.tdata}>Prod</th>
          <th style={tableStyle.tdata}>Capacity</th>
          <th style={tableStyle.tdata}>Inventory</th>
          <th style={tableStyle.tdata}>In-Transit</th>
          <th style={tableStyle.tdata}>Point</th>
          <th style={tableStyle.tdata}>Avg. Daily Usage</th>
          <th style={tableStyle.tdata}>Usage Override</th>
          <th style={tableStyle.tdata}>Order</th>
          <th style={tableStyle.tdata}>Arrival</th>
          <th style={tableStyle.tdata}>Status</th>
          <th style={tableStyle.tdata}>Number</th>
          <th style={tableStyle.tdata}>Notes</th>
          <tbody>
            {orderScheduleList.map((row, index) => (
              <tr key={index}>
                <td style={tableStyle.tdata}>{row.loC_NAME}</td>
                <td style={tableStyle.tdata}>{row.loC_SHIPTO}</td>
                <td style={tableStyle.tdata}>{row.proD_CODE}</td>
                <td style={tableStyle.tdata}>{row.capacity}</td>
                <td style={tableStyle.tdata}>{row.inventory}</td>
                <td style={tableStyle.tdata}>{row.iN_TRANSIT_INV}</td>
                <td style={tableStyle.tdata}>{row.reordeR_PNT}</td>
                <td style={tableStyle.tdata}>{row.avG_DAILY_USAGE}</td>
                <td style={tableStyle.tdata}>{row.usagE_OVERRIDE}</td>
                <td style={tableStyle.tdata}>
                  {row.ordeR_DATE === null
                    ? "NA"
                    : dayjs(row.ordeR_DATE).format("MM/DD/YYYY")}
                </td>
                <td style={tableStyle.tdata}>
                  {row.arrivaL_DATE === null ? (
                    "NA"
                  ) : row.isArrivalDateAdjust === true ? (
                    <span>
                      {" "}
                      {dayjs(row.arrivaL_DATE).format("MM/DD/YYYY")}{" "}
                    </span>
                  ) : (new Date().getTime() -
                    new Date(row.arrivaL_DATE).getTime()) /
                    (1000 * 3600 * 24) >
                    0 ? (
                    <span style={{ color: "red" }}>
                      {" "}
                      {dayjs(row.arrivaL_DATE).format("MM/DD/YYYY")}{" "}
                    </span>
                  ) : (
                    <span>
                      {" "}
                      {dayjs(row.arrivaL_DATE).format("MM/DD/YYYY")}{" "}
                    </span>
                  )}
                </td>
                <td style={tableStyle.tdata}>{row.ordeR_STATE}</td>
                <td style={tableStyle.tdata}>{row.ordeR_NO}</td>
                <td style={tableStyle.tdata}>&nbsp;</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default OrderSchedulePrint;
