/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Button, Card, Grid } from "@mui/material";
import {
  DetailItem,
  LabelItem,
  StyledCardContent,
} from "../../controls/commoncontrols";
import { Customer } from "../../../models/customer";
import CustomerDataService from "../../../services/customerService";
import { filterBandSelector, useAppDispatch } from "../../../store/store";
import { IFilterBandState } from "../../../interfaces/storeInterfaces";
import CustomCardHeader from "../../shared/CustomCardHeader";
import React from "react";
import { FormatContactNumber } from "../../shared/CommonFunctions";
import CustomerService from "../../../services/customerService";
import { filterBandActions } from "../../../store/filterband-slice";
import { uiActions } from "../../../store/ui-slice";
import { useNavigate } from "react-router-dom";

interface Props {
  customerNo: string;
}

function CustomerInfo({ customerNo }: Props) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const filterBandState: IFilterBandState = useSelector(filterBandSelector);
  const role = sessionStorage.getItem("roles");
  const defaultCustomer: Customer = CustomerDataService.GetDefaultCustomer();
  const [customer, setCustomerInfo] = useState<Customer>(defaultCustomer);
  const [isRefreshList, setRefreshList] = React.useState<boolean>(false);
  const { fetchData: fetchCustomersData } = CustomerService.GetCustomers();

  useEffect(() => {
    getCustomers();
  }, [isRefreshList, filterBandState.selectedCustomerNo]);

  const getCustomers = () => {
    if (isRefreshList) {
      fetchCustomersData().then((res) => {
        dispatch(filterBandActions.setCustomerList(res.responseData));
        setRefreshList(false);
      });
    }
    setCustomerInfo(
      filterBandState.customers.find((row) => row.customerNo === customerNo) ||
        defaultCustomer
    );
  };

  const onEditClick = () => {
    if (customerNo !== "" && customerNo !== undefined && customerNo !== null) {
      navigate("/customers", {
        state: { editCustomerNo: customerNo, editModal: true },
      });
    } else {
      dispatch(
        uiActions.displayNotification({
          message: "Customer not selected",
          severity: "error",
        })
      );
    }
  };

  return (
    <React.Fragment>
      {
        <Card
          sx={{ width: "100hv", borderRadius: 3, margin: 2, mt: 0 }}
          elevation={6}
        >
          <CustomCardHeader
            title="Customer Information"
            action={
              role?.toString().toUpperCase() === "ADMIN" ? (
                <Button
                  variant="text"
                  sx={{ fontSize: "0.90rem" }}
                  onClick={onEditClick}
                >
                  <u>Edit Customer</u>
                </Button>
              ) : (
                ""
              )
            }
          ></CustomCardHeader>
          <StyledCardContent>
            <Grid container columnSpacing={{ xs: 1, sm: 1, md: 4, lg: 4 }}>
              <Grid item xs={12} sm={12} md={3}>
                <LabelItem>Customer Name:</LabelItem>
                <DetailItem>{customer.customerName}</DetailItem>
              </Grid>
              <Grid item xs={12} sm={12} md={3}>
                <LabelItem>Contact Name:</LabelItem>
                <DetailItem>{customer.vC_CUSTOMER_CONTACT}</DetailItem>
              </Grid>
              <Grid item xs={12} sm={12} md={2}>
                <LabelItem>Contact Phone:</LabelItem>
                <DetailItem>
                  {FormatContactNumber(customer.vC_CUSTOMER_PHONE)}
                </DetailItem>
              </Grid>
              <Grid item xs={12} sm={12} md={4}>
                <LabelItem>Contact E-Mail:</LabelItem>
                <DetailItem>{customer.vC_CUSTOMER_EMAIL}</DetailItem>
              </Grid>
            </Grid>
          </StyledCardContent>
        </Card>
      }
    </React.Fragment>
  );
}

export default CustomerInfo;
