/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import {
  Button,
  Card,
  CardContent,
  IconButton,
  TableBody,
} from "@mui/material";
import CustomCardHeader from "../../shared/CustomCardHeader";
import LocationService from "../../../services/locationService";
import UnitService from "../../../services/unitService";
import { UnitInfo } from "../../../models/location";
import { TableHeaderCell } from "../../../models/common";
import useTable from "../../../hooks/useTable";
import { StyledTableRow } from "../../controls/tablecontrols";
import { StyledTableCell } from "../../controls/tablecontrols";
import { FormatContactNumber } from "../../shared/CommonFunctions";
import AddEditUnitInformation from "./AddEditUnitInformation";
import ModalPopup from "../../shared/ModalPopup";
import ModeEditSharpIcon from "@mui/icons-material/ModeEditSharp";
import DeleteSharpIcon from "@mui/icons-material/DeleteSharp";
import ConfirmationMessage from "../../shared/ConfirmationMessage";
import { uiActions } from "../../../store/ui-slice";
import { filterBandSelector, useAppDispatch } from "../../../store/store";
import { IFilterBandState } from "../../../interfaces/storeInterfaces";
import { useSelector } from "react-redux";

interface UnitInformationProps {
  customerNo: string;
  locationNo: string;
  unitNo?: string;
}

function UnitInformation({
  customerNo,
  locationNo,
  unitNo = "",
}: UnitInformationProps) {
  const role = sessionStorage.getItem("roles");
  const filterBandState: IFilterBandState = useSelector(filterBandSelector);
  const dispatch = useAppDispatch();
  const [unitDetails, setUnitInfo] = useState<UnitInfo[]>([]);
  const [unitId, setUnitId] = useState<string>("");
  const [uNo, setUnitNo] = useState<string>("");
  const [isConfirmDelete, setIsConfirmDelete] = useState<boolean>(false);
  const [isUnitRefreshList, setUnitRefreshList] = useState<boolean>(false);
  const [showUnitModalPopup, setUnitShowModalPopup] = useState<boolean>(false);
  const [openConfirmation, setOpenConfirmation] = useState<boolean>(false);
  const { fetchData: fetchUnitInformation } = LocationService.GetUnitByID(
    customerNo,
    locationNo,
    unitNo
  );

  const { deleteData: deleteUnit } = UnitService.DeleteUnit(customerNo, unitId);

  useEffect(() => {
    if (customerNo && locationNo) {
      fetchUnitInformation().then((res) => {
        setUnitInfo(res.responseData);
      });
    } else {
      setUnitInfo([]);
    }
  }, [customerNo, locationNo]);

  useEffect(() => {
    if (isUnitRefreshList) {
      if (customerNo && locationNo) {
        fetchUnitInformation().then((res) => {
          setUnitInfo(res.responseData);
          setUnitRefreshList(false);
        });
      } else {
        setUnitInfo([]);
        setUnitRefreshList(false);
      }
    }
  }, [
    isUnitRefreshList,
    filterBandState.selectedCustomerNo,
    filterBandState.selectedLocationNo,
  ]);

  useEffect(() => {
    if (isConfirmDelete) {
      dispatch(uiActions.startProgressBar());
      deleteUnit().then((res) => {
        if (res.isSuccess) {
          dispatch(
            uiActions.displayNotification({
              message: "Unit deleted successfully!",
              severity: "success",
            })
          );
          setUnitRefreshList(true);
        }
      });

      Promise.resolve().then(() => {
        setIsConfirmDelete(false);
      });
    }
  }, [isConfirmDelete]);

  const Admin = [
    { id: "vunT_UNIT_NO", label: "ID" },
    { id: "vunT_UNIT_IP", label: "IP Address" },
    { id: "vunT_UNIT_SUBNET", label: "Subnet" },
    { id: "vunT_UNIT_SMTP", label: "SMTP Address" },
    { id: "vunT_UNIT_IMEI", label: "Wireless Number" },
    { id: "edit", label: "Edit", disableSorting: true },
    { id: "delete", label: "Delete", disableSorting: true },
  ];

  const NonAdmin = [
    { id: "vunT_UNIT_NO", label: "ID" },
    { id: "vunT_UNIT_IP", label: "IP Address" },
    { id: "vunT_UNIT_SUBNET", label: "Subnet" },
    { id: "vunT_UNIT_SMTP", label: "SMTP Address" },
    { id: "vunT_UNIT_IMEI", label: "Wireless Number" },
  ];

  const headerCellsUnitInfo: TableHeaderCell[] =
    role?.toString().toUpperCase() === "ADMIN" ? Admin : NonAdmin;

  const { Tbl: TblUnit, GetRowsAfterPagingAndSorting: GetRowsUnitInfo } =
    useTable<UnitInfo>(unitDetails, headerCellsUnitInfo, false);

  const onAddNewUnitClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    Promise.resolve().then(() => {
      setUnitId("");
      setUnitShowModalPopup(true);
    });
  };

  const onUnitModalClose = (e: React.MouseEvent<HTMLButtonElement>) => {
    Promise.resolve().then(() => {
      setUnitId("");
      setUnitShowModalPopup(false);
    });
  };
  const handleEditUnit = (
    customerNo: string,
    locationNo: string,
    UnitId: string
  ) => {
    Promise.resolve().then(() => {
      setUnitShowModalPopup(true);
      setUnitId(UnitId);
    });
  };

  const handleOpenDeleteConfirmation = (
    customerNo: string,
    UnitId: string,
    vunT_UNIT_NO: string
  ) => {
    Promise.resolve().then(() => {
      setUnitId(UnitId);
      setUnitNo(vunT_UNIT_NO);
      setOpenConfirmation(true);
    });
  };

  const handleConfirmDelete = (e: React.MouseEvent<HTMLButtonElement>) => {
    Promise.resolve().then(() => {
      setOpenConfirmation(false);
      setIsConfirmDelete(true);
    });
  };

  const handleCancelDelete = (e: React.MouseEvent<HTMLButtonElement>) => {
    Promise.resolve().then(() => {
      setOpenConfirmation(false);
      setUnitId("");
    });
  };

  return (
    <div>
      {
        <Card
          sx={{ width: "100hv", borderRadius: 3, margin: 2, mt: 0 }}
          elevation={6}
        >
          <CustomCardHeader
            title="Unit Information"
            action={
              role?.toString().toUpperCase() === "ADMIN" ? (
                <>
                  <Button
                    variant="text"
                    sx={{ fontSize: "0.90rem" }}
                    onClick={onAddNewUnitClick}
                  >
                    <u>Add Unit</u>
                  </Button>
                </>
              ) : (
                ""
              )
            }
          ></CustomCardHeader>
          <CardContent>
            <TblUnit>
              <TableBody>
                {GetRowsUnitInfo().map((row, index) => (
                  <StyledTableRow key={index}>
                    {role?.toString().toUpperCase() === "ADMIN" ? (
                      <StyledTableCell>{row.vunT_UNIT_NO}</StyledTableCell>
                    ) : (
                      <StyledTableCell>{row.vunT_UNIT_NO}</StyledTableCell>
                    )}
                    <StyledTableCell>{row.vunT_UNIT_IP}</StyledTableCell>
                    <StyledTableCell>{row.vunT_UNIT_SUBNET}</StyledTableCell>
                    <StyledTableCell>{row.vunT_UNIT_SMTP}</StyledTableCell>
                    <StyledTableCell>
                      {FormatContactNumber(row.vunT_UNIT_IMEI)}
                    </StyledTableCell>
                    {role?.toString().toUpperCase() === "ADMIN" ? (
                      <>
                        <StyledTableCell scope="row">
                          <IconButton
                            aria-label="Edit"
                            size="small"
                            sx={{ paddingLeft: 1, paddingRight: 1 }}
                            onClick={(e) =>
                              handleEditUnit(
                                customerNo,
                                locationNo,
                                row.vunT_UNIT_ID
                              )
                            }
                          >
                            <ModeEditSharpIcon fontSize="small" />
                          </IconButton>
                        </StyledTableCell>
                        <StyledTableCell scope="row">
                          <IconButton
                            aria-label="Delete"
                            size="small"
                            sx={{ paddingLeft: 1, paddingRight: 1 }}
                            onClick={(e) =>
                              handleOpenDeleteConfirmation(
                                customerNo,
                                row.vunT_UNIT_ID,
                                row.vunT_UNIT_NO
                              )
                            }
                          >
                            <DeleteSharpIcon fontSize="small" />
                          </IconButton>
                        </StyledTableCell>
                      </>
                    ) : (
                      ""
                    )}
                  </StyledTableRow>
                ))}
              </TableBody>
            </TblUnit>
          </CardContent>
        </Card>
      }
      <ModalPopup
        open={showUnitModalPopup}
        title={unitId === "" ? "Add New Unit" : "Update Unit"}
        onPopupClose={onUnitModalClose}
      >
        <AddEditUnitInformation
          setUnitShowModalPopup={setUnitShowModalPopup}
          customerNo={customerNo}
          locationNo={locationNo}
          unitId={unitId}
          setUnitRefreshList={setUnitRefreshList}
        />
      </ModalPopup>
      <ConfirmationMessage
        open={openConfirmation}
        message={
          `Do you really want to delete "${uNo.trim()}" unit ? This process cannot be undone.`
        }
        onYesClick={handleConfirmDelete}
        onNoClick={handleCancelDelete}
      ></ConfirmationMessage>
    </div>
  );
}

export default UnitInformation;
