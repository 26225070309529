import IntrasnitShipmentMaintenance from "../components/app/Shipment/IntransitShipmentMaintenance";
import SystemMessage from "./SystemMessage";
import { filterBandSelector } from "../store/store";
import { useSelector } from "react-redux";
import { IFilterBandState } from "../interfaces/storeInterfaces";

function IntrasnitShipmentMaintenancePage() {
  const filterBandState: IFilterBandState = useSelector(filterBandSelector);
  return filterBandState.turnCarsUser === true && filterBandState.inboundUser === true ? (
    <IntrasnitShipmentMaintenance></IntrasnitShipmentMaintenance>
  ) : (
    <SystemMessage msg="Access Denied." colorMsg="Red"></SystemMessage>
  );
}

export default IntrasnitShipmentMaintenancePage;
