/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Table } from "@mui/material";
import TableBody from "@mui/material/TableBody";
import { Card, CardContent } from "@mui/material";
import { StyledTableCell, StyledTableRow } from "../../controls/tablecontrols";
import { useState } from "react";
import { Alarm, TankInformation } from "../../../models/tank";
import CustomCardHeader from "../../shared/CustomCardHeader";
import React from "react";
import moment from "moment";
import ModalPopup from "../../shared/ModalPopup";
import ShowHistory from "./ShowHistory";
import ManualTankDetails from "./ManualTankDetails";
import { IFilterBandState } from "../../../interfaces/storeInterfaces";
import { useSelector } from "react-redux";
import { filterBandSelector } from "../../../store/store";
import ShowChart from "./ShowChart";
import dayjs from "dayjs";

interface Props {
  alarm: Alarm;
  tankInfo: TankInformation;
  setRefreshList: React.Dispatch<React.SetStateAction<boolean>>;
}

function InventoryInformation({ alarm, tankInfo, setRefreshList }: Props) {
  const role = sessionStorage.getItem("roles");
  const filterBandState: IFilterBandState = useSelector(filterBandSelector);
  const [manualTankNo, setManualTankNo] = useState<number>(0);
  const [manualTankID, setManualTankID] = useState<number>(0);
  const [showChartModalPopup, setShowChartModalPopup] =
    useState<boolean>(false);
  const [showTankModalPopup, setShowTankModalPopup] = useState<boolean>(false);
  const [showHistoryModelPopup, setShowHistoryModelPopup] = useState(false);
  const [locationName, setLocationName] = useState("");
  const [startDate, setStartDate] = useState(dayjs());
  const [endDate, setEndDate] = useState(dayjs());

  //---Manual Update--
  const clickManualUpdate = (tankNo: number) => {
    Promise.resolve().then(() => {
      setManualTankNo(tankNo);
      setManualTankID(
        filterBandState.tanks.filter((row) => row.vT_TANK_NO === tankNo)[0]
          .vT_TANK_ID
      );
      setShowTankModalPopup(true);
    });
  };

  const onModalClose = (e: React.MouseEvent<HTMLButtonElement>) => {
    Promise.resolve().then(() => {
      setManualTankNo(0);
      setManualTankID(0);
      setShowTankModalPopup(false);
    });
  };
  //------------------
  //--Show Chart------
  const handleShowChart = (locationName: string, tankNo: number) => {
    Promise.resolve().then(() => {
      setManualTankID(
        filterBandState.tanks.filter((row) => row.vT_TANK_NO === tankNo)[0]
          .vT_TANK_ID
      );
      setShowChartModalPopup(true);
      setLocationName(locationName);
    });
  };

  const onChartModalClose = (e: React.MouseEvent<HTMLButtonElement>) => {
    Promise.resolve().then(() => {
      setShowChartModalPopup(false);
      setStartDate(dayjs());
      setEndDate(dayjs());
    });
  };
  //------------------
  //--Show Histroy----
  const handleShowHistory = (tankNo: number) => {
    Promise.resolve().then(() => {
      setManualTankID(
        filterBandState.tanks.filter((row) => row.vT_TANK_NO === tankNo)[0]
          .vT_TANK_ID
      );
      setShowHistoryModelPopup(true);
    });
  };

  const onHistoryPopupClose = () => {
    Promise.resolve().then(() => {
      setShowHistoryModelPopup(false);
      setStartDate(dayjs());
      setEndDate(dayjs());
    });
  };
  //------------------
  const noRec = (
    <CardContent>
      <Table>
        <TableBody>
          <StyledTableRow>
            <StyledTableCell>No records found</StyledTableCell>
          </StyledTableRow>
        </TableBody>
      </Table>
    </CardContent>
  );
  return (
    <React.Fragment>
      {
        <Card
          sx={{ width: "100hv", borderRadius: 3, margin: 2, mt: 0 }}
          elevation={6}
        >
          <CustomCardHeader
            title="Inventory Information"
            action={
              <>
                {role?.toString().toUpperCase() === "ADMIN" ? (
                  <Button
                    variant="text"
                    sx={{ fontSize: "0.90rem" }}
                    onClick={(e) => clickManualUpdate(tankInfo.vT_TANK_NO)}
                    hidden={
                      role?.toString().toUpperCase() === "ADMIN" ? true : false
                    }
                  >
                    <u>Manual Update</u>
                  </Button>
                ) : (
                  <></>
                )}

                <Button
                  variant="text"
                  sx={{ fontSize: "0.90rem" }}
                  onClick={(e) =>
                    handleShowChart(
                      tankInfo.vL_LOCATION_NAME,
                      tankInfo.vT_TANK_NO
                    )
                  }
                >
                  <u>Show Chart</u>
                </Button>
                <Button
                  variant="text"
                  sx={{ fontSize: "0.90rem" }}
                  onClick={(e) => handleShowHistory(tankInfo.vT_TANK_NO)}
                >
                  <u>Show History</u>
                </Button>
              </>
            }
          ></CustomCardHeader>
          {tankInfo.vT_TANK_NAME != null && tankInfo.vT_TANK_NAME !== "" ? (
            <CardContent>
              <Table>
                <TableBody>
                  <StyledTableRow>
                    <StyledTableCell style={{ fontWeight: "bold" }}>
                      Tank Number:
                    </StyledTableCell>
                    <StyledTableCell>{tankInfo.vT_TANK_NO}</StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow>
                    <StyledTableCell style={{ fontWeight: "bold" }}>
                      Tank Name:
                    </StyledTableCell>
                    <StyledTableCell>{tankInfo.vT_TANK_NAME}</StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow>
                    <StyledTableCell style={{ fontWeight: "bold" }}>
                      Site:
                    </StyledTableCell>
                    <StyledTableCell>
                      {tankInfo.vL_LOCATION_NAME}
                    </StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow>
                    <StyledTableCell style={{ fontWeight: "bold" }}>
                      Product:
                    </StyledTableCell>
                    <StyledTableCell>
                      {tankInfo.vP_PRODUCT_DESC}
                    </StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow>
                    <StyledTableCell style={{ fontWeight: "bold" }}>
                      Last Level Update:
                    </StyledTableCell>
                    <StyledTableCell>
                      {tankInfo.lastLevelUpdate !== null &&
                      tankInfo.lastLevelUpdate !== ""
                        ? moment(tankInfo.lastLevelUpdate).format(
                            "MM/DD/YYYY hh:mm:ss A"
                          )
                        : ""}
                    </StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow>
                    <StyledTableCell style={{ fontWeight: "bold" }}>
                      Begin Level(%):
                    </StyledTableCell>
                    <StyledTableCell>{tankInfo.beginLevel}</StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow>
                    <StyledTableCell style={{ fontWeight: "bold" }}>
                      Current Level(%):
                    </StyledTableCell>
                    <StyledTableCell>{tankInfo.currentLevel}</StyledTableCell>
                  </StyledTableRow>
                </TableBody>
              </Table>
            </CardContent>
          ) : (
            noRec
          )}
        </Card>
      }
      <ModalPopup
        open={showTankModalPopup}
        title="Tank Information - Insert Tank Reading"
        onPopupClose={onModalClose}
      >
        <ManualTankDetails
          setShowTankModalPopup={setShowTankModalPopup}
          setRefreshList={setRefreshList}
          manualTankNo={manualTankNo}
          manualTankID={manualTankID}
        ></ManualTankDetails>
      </ModalPopup>
      <ModalPopup
        open={showHistoryModelPopup}
        title={"Show History"}
        onPopupClose={onHistoryPopupClose}
        width="700px"
      >
        <ShowHistory
          tankId={manualTankID}
          locationName={tankInfo.vL_LOCATION_NAME}
          startDate={startDate}
          endDate={endDate}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
          setShowHistoryModelPopup={setShowHistoryModelPopup}
          setShowChartModalPopup={setShowChartModalPopup}
        ></ShowHistory>
      </ModalPopup>

      <ModalPopup
        open={showChartModalPopup}
        title={"Show Chart"}
        onPopupClose={onChartModalClose}
        width="700px"
      >
        <ShowChart
          startDate={startDate}
          endDate={endDate}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
          setShowChartModalPopup={setShowChartModalPopup}
          setShowHistoryModelPopup={setShowHistoryModelPopup}
          locationName={locationName}
          tankId={manualTankID}
        />
      </ModalPopup>
    </React.Fragment>
  );
}

export default InventoryInformation;
