import React, { useEffect, useState } from "react";
import Snackbar from "@mui/material/Snackbar";
import { uiActions } from "../../store/ui-slice";
import { useAppDispatch } from "../../store/store";
import MuiAlert, { AlertProps } from "@mui/material/Alert";

interface NotificationHandlerProps {
  error?: Error;
  message: string;
  severity: "success" | "error" | "warning" | "info";
  showNotification: boolean;
}

function NotificationHandler(props: NotificationHandlerProps) {
  const dispatch = useAppDispatch();
  const [msgData, setMsgData] = useState<string[]>([]);

  const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
    props,
    ref
  ) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const handleClose = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    if (props.severity === "error") {
      dispatch(uiActions.stopProgressBar());
    }
    console.log("Inside handleClose" + props.error);
    if (props.error) {
      logError(props.error);
    }

    dispatch(uiActions.clearNotification());
  };

  useEffect(() => {
    if (props.message.indexOf("\n") !== -1) {
      let splitMsg = [];
      splitMsg = props.message.split("\n");
      setMsgData(splitMsg);
    } else {
      let arrayMsg = [];
      arrayMsg.push(props.message);
      setMsgData(arrayMsg);
    }
  }, [props.message]);

  const logError = (error: Error) => {
    //TODO : Call API for AppInsights
    console.log("Inside logError");
  };

  return (
    <Snackbar
      open={props.showNotification}
      autoHideDuration={6000}
      onClose={handleClose}
    >
      <Alert
        onClose={handleClose}
        severity={props.severity}
        sx={{ width: "100%" }}
      >
        {msgData.map((row, index) => {
          return (
            <span>
              {row}
              <br />
            </span>
          );
        })}
      </Alert>
    </Snackbar>
  );
}

export default NotificationHandler;
