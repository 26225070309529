import React from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogProps, DialogTitle, styled } from "@mui/material";
import WarningSharpIcon from '@mui/icons-material/WarningSharp';
import CheckSharpIcon from '@mui/icons-material/CheckSharp';
import CloseSharpIcon from '@mui/icons-material/CloseSharp';

export interface ConfirmationMessageProps extends DialogProps {
    open: boolean,
    title?:string,
    titleElement?:JSX.Element,
    message?: string
    onYesClick: React.MouseEventHandler,
    onNoClick: React.MouseEventHandler,        
    yesButtonText?: string,
    noButtonText?: string,    
}

export interface DialogTitleProps {
    id: string;
    children?: React.ReactNode;
}

function ConfirmationMessage(props: React.PropsWithChildren<ConfirmationMessageProps>) {

    const BootstrapDialog = styled(Dialog)(({ theme }) => ({
        '& .MuiDialogContent-root': {
            padding: theme.spacing(2),
        },
        '& .MuiDialogActions-root': {
            padding: theme.spacing(1),
        },
    }));

    const BootstrapDialogTitle = (props: DialogTitleProps) => {
        const { children, ...other } = props;

        return (
            <DialogTitle sx={{ m: 0, pl: 2, pr: 2, pt: 1, pb: 1 }} {...other}>
                {children}
            </DialogTitle>
        );
    }

    return (
        <BootstrapDialog open={props.open} >
            <BootstrapDialogTitle id="customized-dialog-title">
               <WarningSharpIcon sx={{marginRight:1, paddingTop:0.1}}></WarningSharpIcon> 
               {(props.title === "" || props.title === undefined) && props.titleElement === undefined ? 'Confirm':""}
               {props.title}
               {props.titleElement}
            </BootstrapDialogTitle>
            <DialogContent dividers>
                {props.children} {props.message} 
            </DialogContent>
            <DialogActions>
                <Button autoFocus onClick={props.onYesClick} startIcon={<CheckSharpIcon></CheckSharpIcon>}>
                    {props.yesButtonText === "" || props.yesButtonText === undefined ? 'Yes' : props.yesButtonText}
                </Button>
                <Button autoFocus onClick={props.onNoClick}  startIcon={<CloseSharpIcon></CloseSharpIcon>}>
                    {props.noButtonText === "" || props.noButtonText === undefined ? 'No' : props.noButtonText}
                </Button>
            </DialogActions>
        </BootstrapDialog>
    )
}

export default ConfirmationMessage;