import React, { useEffect, useState } from "react";
import PageHeader from "../../shared/PageHeader";
import { AdditionalTableHeaderCell } from "../../../models/common";
import {
    Grid,
    TextField,
    Button,
    Container,
    Card,
    CardContent,
    MenuItem,
} from "@mui/material";
import TableBody from "@mui/material/TableBody";
import { StyledTableCell, StyledTableRow } from "../../controls/tablecontrols";
import { filterBandSelector, useAppDispatch } from "../../../store/store";
import { uiActions } from "../../../store/ui-slice";
import CustomCardHeader from "../../shared/CustomCardHeader";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { TableHeaderCell } from "../../../models/common";
import useTable from "../../../hooks/useTable";
import { IFilterBandState } from "../../../interfaces/storeInterfaces";
import { useSelector } from "react-redux";
import ShipmentDataService from "../../../services/shipmentService";
import {
    ProductCode,
    RetrieveSummaryReportModal,
} from "../../../models/shipment";
import { useFormik } from "formik";
import * as yup from "yup";
import { FormatInvResult } from "../../shared/CommonFunctions";
import moment from "moment";

type DataItem = {
    uom: boolean;
    net_usage: number;
    b_inv: number;
    e_inv: number;
    specific_Gravity: number;
};

const UsageReport = () => {
    const dispatch = useAppDispatch();
    const [usageList, setUsageList] = useState<RetrieveSummaryReportModal[]>([]);
    const [productCode, setProductCode] = useState<ProductCode[]>([]);
    const [dateThru, setDateThru] = useState<string>("");
    const [typeReport, setTypeReport] = useState<string>("");
    const [productId, setProductId] = useState<number>(0);
    const [isloaded, setIsloaded] = useState<boolean>(false);
    const filterBandState: IFilterBandState = useSelector(filterBandSelector);
    const [gridDisplay, setGridDisplay] = useState<boolean>(false);
    const { fetchData: fetchProductData } =
        ShipmentDataService.RetrieveProductsByLocation(
            filterBandState.selectedCustomerNo,
            filterBandState.selectedLocationNo
        );
    const { fetchData: fetchSummaryReport } =
        ShipmentDataService.RetrieveSummaryReport(
            filterBandState.selectedCustomerNo,
            filterBandState.selectedLocationNo,
            productId,
            dateThru,
            typeReport
        );

    const [showMultipleRow, setShowMultipleRow] = useState<{
        showMT: boolean;
        showBu: boolean;
        showlbs: boolean;
    }>({
        showMT: false,
        showBu: false,
        showlbs: false,
    });
    const [total, setTotal] = useState<DataItem>({
        uom: false,
        net_usage: 0,
        b_inv: 0,
        e_inv: 0,
        specific_Gravity: 0,
    });

    //this array is used to bind Report type dropdown list
    let reportTypeArray = [
        { value: "0", type: "Select Type" },
        { value: "Y", type: "Yearly" },
        { value: "M", type: "Monthly" },
        { value: "W", type: "Weekly" },
        { value: "D", type: "Daily" },
    ];

    //Used to calculate total
    useEffect(() => {
        setTotal({
            uom: false,
            net_usage: 0,
            b_inv: 0,
            e_inv: 0,
            specific_Gravity: 0,
        });
        usageList.forEach((item) => {
            setTotal((prev) => ({
                ...prev,
                net_usage: prev.net_usage + item.net_usage,
                b_inv: prev.b_inv + item.b_inv,
                e_inv: prev.e_inv + item.e_inv,
                specific_Gravity: item.specific_Gravity,
                uom: true,
            }));
        });
    }, [usageList]);

    //Used to check Multiple row
    useEffect(() => {
        setShowMultipleRow({
            showMT: false,
            showBu: false,
            showlbs: false,
        });
        usageList.forEach((item) => {
            if (item.uom === "MT") {
                setShowMultipleRow((prev) => ({
                    ...prev,
                    showMT: true,
                }));
            } else if (item.uom === "BU") {
                setShowMultipleRow((prev) => ({
                    ...prev,
                    showBu: true,
                }));
            } else {
                setShowMultipleRow((prev) => ({
                    ...prev,
                    showlbs: true,
                }));
            }
        });
    }, [usageList]);

    //Used to bind Product Code dropdown list
    useEffect(() => {
        if (
            filterBandState.selectedCustomerNo !== "" &&
            filterBandState.selectedCustomerNo !== undefined
        ) {
            dispatch(uiActions.startProgressBar());
            fetchProductData().then((res) => {
                setProductCode(res.responseData);
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterBandState.selectedCustomerNo]);

    //Used to bind the table data
    useEffect(() => {
        if (isloaded) {
            dispatch(uiActions.startProgressBar());
            fetchSummaryReport().then((res) => {
                if (res.isSuccess === true) {
                    setUsageList(res.responseData);
                    setIsloaded(false);
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isloaded]);

    //Validation
    const validationSchema = yup.object({
        vP_PRODUCT_ID: yup
            .number()
            .required()
            .moreThan(0, "Product Code is required"),
        reportType: yup
            .string()
            .oneOf(["Y", "M", "W", "D"], "Report Type is required")
            .required("Required"),
    });

    //If Report type is Daily than Net Usage is disable from the table
    const tableHeaderCellDetails: TableHeaderCell[] =
        typeReport !== "D"
            ? [
                {
                    id: "location_name",
                    label: "Location Name",
                    align: "left",
                    disableSorting: true,
                },
                {
                    id: "site_no",
                    label: "Site #",
                    align: "left",
                    disableSorting: true,
                },
                {
                    id: "b_inv",
                    label: "Beg. Inv.",
                    align: "left",
                    disableSorting: true,
                },
                { id: "0", label: "", align: "left", disableSorting: true },
                {
                    id: "e_inv_inches",
                    label: "Inches",
                    align: "left",
                    disableSorting: true,
                },
                {
                    id: "net_usage",
                    label: "Net Usage",
                    align: "left",
                    disableSorting: true,
                },
                {
                    id: "inv_time",
                    label: "Inv. Time",
                    align: "left",
                    disableSorting: true,
                },
            ]
            : [
                {
                    id: "location_name",
                    label: "Location Name",
                    align: "left",
                    disableSorting: true,
                },
                {
                    id: "site_no",
                    label: "Site #",
                    align: "left",
                    disableSorting: true,
                },
                {
                    id: "b_inv",
                    label: "Beg. Inv.",
                    align: "left",
                    disableSorting: true,
                },
                { id: "0", label: "", align: "left", disableSorting: true },
                {
                    id: "e_inv_inches",
                    label: "Inches",
                    align: "left",
                    disableSorting: true,
                },
                {
                    id: "inv_time",
                    label: "Inv. Time",
                    align: "left",
                    disableSorting: true,
                },
            ];

    const additionalHeaderDetails: AdditionalTableHeaderCell[] =
        typeReport !== "D"
            ? [
                { id: "0", label: "", align: "left" },
                { id: "0", label: "", align: "left" },
                { id: "0", label: "", align: "left" },
                { id: "e_inv", label: "Ending Inv", colSpan: 2, align: "center" },
                { id: "0", label: "", align: "left" },
                { id: "0", label: "", align: "left" },
            ]
            : [
                { id: "0", label: "", align: "left" },
                { id: "0", label: "", align: "left" },
                { id: "0", label: "", align: "left" },
                { id: "e_inv", label: "Ending Inv", colSpan: 2, align: "center" },
                { id: "0", label: "", align: "left" },
            ];

    //Table Header
    const { Tbl } = useTable<RetrieveSummaryReportModal>(
        usageList,
        tableHeaderCellDetails,
        false,
        10,
        additionalHeaderDetails
    );

    //Form Control using formik
    const formik = useFormik({
        initialValues: { thruDate: new Date(), vP_PRODUCT_ID: 0, reportType: "0" },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            const dateString = moment(values.thruDate.toISOString()).format(
                "YYYY-MM-DD"
            );
            setDateThru(dateString);
            setTypeReport(values.reportType);
            setProductId(values.vP_PRODUCT_ID);
            setIsloaded(true);
            setGridDisplay(true);
        },
    });

    //JSX code
    return (
        <>
            <PageHeader
                title="Usage Report"
                subtitle="To view the usage report, please select the Report Date, Report Type and Product and click on Show Summary button."
            ></PageHeader>
            <Card
                sx={{ width: "100hv", borderRadius: 3, margin: 2, mt: 0 }}
                elevation={6}
            >
                <CardContent sx={{ pb: 0 }}>
                    <React.Fragment>
                        <Container>
                            <form onSubmit={formik.handleSubmit}>
                                <Grid container spacing={10} sx={{ paddingTop: 1 }}>
                                    <Grid item xs={12} sm={6} md={4} lg={2}>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DatePicker
                                                label="Select Report Date"
                                                defaultValue={dayjs(new Date())}
                                                value={dayjs(formik.values.thruDate)}
                                                onChange={(value) =>
                                                    formik.setFieldValue("thruDate", value)
                                                }
                                            />
                                        </LocalizationProvider>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={4} lg={2}>
                                        <TextField
                                            id=""
                                            name="reportType"
                                            select
                                            label="Report Type"
                                            defaultValue="0"
                                            fullWidth
                                            value={formik.values.reportType}
                                            onBlur={formik.handleBlur}
                                            onChange={formik.handleChange}
                                            error={
                                                formik.touched.reportType &&
                                                Boolean(formik.errors.reportType)
                                            }
                                            helperText={
                                                formik.touched.reportType && formik.errors.reportType
                                            }
                                        >
                                            {reportTypeArray.map((row, index) => {
                                                return (
                                                    <MenuItem value={row.value}>{row.type}</MenuItem>
                                                );
                                            })}
                                        </TextField>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={4} lg={3}>
                                        <TextField
                                            id="Product ID"
                                            name="vP_PRODUCT_ID"
                                            select
                                            label="Product Code"
                                            defaultValue="0"
                                            fullWidth
                                            value={formik.values.vP_PRODUCT_ID}
                                            onBlur={formik.handleBlur}
                                            onChange={formik.handleChange}
                                            error={
                                                formik.touched.vP_PRODUCT_ID &&
                                                Boolean(formik.errors.vP_PRODUCT_ID)
                                            }
                                            helperText={
                                                formik.touched.vP_PRODUCT_ID &&
                                                formik.errors.vP_PRODUCT_ID
                                            }
                                        >
                                            <MenuItem value="0">
                                                {productCode.length > 0 ? "Select Product" : "None"}
                                            </MenuItem>
                                            {productCode.map((row, index) => {
                                                return (
                                                    <MenuItem
                                                        key={row.vP_PRODUCT_ID + "$" + index}
                                                        value={String(row.vP_PRODUCT_ID)}
                                                    >
                                                        {row.vP_PRODUCT_DESC +
                                                            "(" +
                                                            row.vP_PRODUCT_CODE +
                                                            ") "}
                                                    </MenuItem>
                                                );
                                            })}
                                        </TextField>
                                    </Grid>
                                    <Grid item xs={4} sm={4} md={3} lg={3}>
                                        <Button size="small" type="submit">
                                            Show Summary
                                        </Button>
                                    </Grid>
                                </Grid>
                            </form>
                        </Container>
                    </React.Fragment>
                </CardContent>
            </Card>
            {gridDisplay && (
                <Card
                    sx={{ width: "100hv", borderRadius: 3, margin: 2, mt: 0 }}
                    elevation={6}
                >
                    <CustomCardHeader title="Usage Report Details"></CustomCardHeader>
                    <CardContent sx={{ pb: 0 }}>
                        <Tbl>
                            <TableBody>
                                {usageList.map((row, index) => (
                                    <>
                                        <StyledTableRow key={index}>
                                            <StyledTableCell scope="row">
                                                {row.location_name}
                                            </StyledTableCell>
                                            <StyledTableCell scope="row">
                                                {row.site_no}
                                            </StyledTableCell>
                                            {row.uom === "MT" ? (
                                                <>
                                                    <StyledTableCell scope="row">
                                                        {row.b_inv === 0
                                                            ? FormatInvResult(
                                                                Number(Number(row.b_inv) / 2204),
                                                                row.uom,
                                                                0
                                                            )
                                                            : FormatInvResult(
                                                                Number(Number(row.b_inv) / 2204),
                                                                row.uom,
                                                                1
                                                            )}
                                                    </StyledTableCell>
                                                    <StyledTableCell scope="row">
                                                        {row.e_inv === 0
                                                            ? FormatInvResult(
                                                                Number(Number(row.e_inv) / 2204),
                                                                row.uom,
                                                                0
                                                            )
                                                            : FormatInvResult(
                                                                Number(Number(row.e_inv) / 2204),
                                                                row.uom,
                                                                1
                                                            )}
                                                    </StyledTableCell>
                                                    <StyledTableCell scope="row">
                                                        {row.e_inv_inches === 0
                                                            ? FormatInvResult(Number(row.e_inv_inches), "", 0)
                                                            : FormatInvResult(
                                                                Number(row.e_inv_inches),
                                                                "",
                                                                1
                                                            )}
                                                    </StyledTableCell>
                                                    {(typeReport !== "D" ? true : false) && (
                                                        <StyledTableCell scope="row">
                                                            {row.net_usage === 0
                                                                ? FormatInvResult(
                                                                    Number(Number(row.net_usage) / 2204),
                                                                    row.uom,
                                                                    0
                                                                )
                                                                : FormatInvResult(
                                                                    Number(Number(row.net_usage) / 2204),
                                                                    row.uom,
                                                                    1
                                                                )}
                                                        </StyledTableCell>
                                                    )}
                                                </>
                                            ) : row.uom === "BU" ? (
                                                <>
                                                    <StyledTableCell scope="row">
                                                        {row.b_inv === 0
                                                            ? FormatInvResult(
                                                                Number(
                                                                    Number(row.b_inv) /
                                                                    Number(row.specific_Gravity)
                                                                ),
                                                                row.uom,
                                                                0
                                                            )
                                                            : FormatInvResult(
                                                                Number(
                                                                    Number(row.b_inv) /
                                                                    Number(row.specific_Gravity)
                                                                ),
                                                                row.uom,
                                                                1
                                                            )}
                                                    </StyledTableCell>
                                                    <StyledTableCell scope="row">
                                                        {row.e_inv === 0
                                                            ? FormatInvResult(
                                                                Number(
                                                                    Number(row.e_inv) /
                                                                    Number(row.specific_Gravity)
                                                                ),
                                                                row.uom,
                                                                0
                                                            )
                                                            : FormatInvResult(
                                                                Number(
                                                                    Number(row.e_inv) /
                                                                    Number(row.specific_Gravity)
                                                                ),
                                                                row.uom,
                                                                1
                                                            )}
                                                    </StyledTableCell>
                                                    <StyledTableCell scope="row">
                                                        {row.e_inv_inches === 0
                                                            ? FormatInvResult(Number(row.e_inv_inches), "", 0)
                                                            : FormatInvResult(
                                                                Number(row.e_inv_inches),
                                                                "",
                                                                1
                                                            )}
                                                    </StyledTableCell>
                                                    {(typeReport !== "D" ? true : false) && (
                                                        <StyledTableCell scope="row">
                                                            {row.net_usage === 0
                                                                ? FormatInvResult(
                                                                    Number(
                                                                        Number(row.net_usage) /
                                                                        Number(row.specific_Gravity)
                                                                    ),
                                                                    row.uom,
                                                                    0
                                                                )
                                                                : FormatInvResult(
                                                                    Number(
                                                                        Number(row.net_usage) /
                                                                        Number(row.specific_Gravity)
                                                                    ),
                                                                    row.uom,
                                                                    1
                                                                )}
                                                        </StyledTableCell>
                                                    )}
                                                </>
                                            ) : (
                                                <>
                                                    <StyledTableCell scope="row">
                                                        {FormatInvResult(Number(row.b_inv), "lbs", 0)}
                                                    </StyledTableCell>
                                                    <StyledTableCell scope="row">
                                                        {FormatInvResult(Number(row.e_inv), "lbs", 0)}
                                                    </StyledTableCell>
                                                    <StyledTableCell scope="row">
                                                        {FormatInvResult(Number(row.e_inv_inches), "", 0)}
                                                    </StyledTableCell>
                                                    {(typeReport !== "D" ? true : false) && (
                                                        <StyledTableCell scope="row">
                                                            {FormatInvResult(Number(row.net_usage), "lbs", 0)}
                                                        </StyledTableCell>
                                                    )}
                                                </>
                                            )}
                                            <StyledTableCell scope="row">
                                                {row.inv_time}
                                            </StyledTableCell>
                                        </StyledTableRow>
                                    </>
                                ))}

                                {/* lbs */}
                                {showMultipleRow.showlbs && (
                                    <>
                                        <StyledTableRow style={{ backgroundColor: "yellow" }}>
                                            <StyledTableCell scope="row">{""}</StyledTableCell>
                                            <StyledTableCell scope="row">{"Total"}</StyledTableCell>
                                            <StyledTableCell scope="row">
                                                {FormatInvResult(Number(total.b_inv), "lbs", 0)}
                                            </StyledTableCell>
                                            <StyledTableCell scope="row">
                                                {FormatInvResult(Number(total.e_inv), "lbs", 0)}
                                            </StyledTableCell>
                                            <StyledTableCell scope="row">{""}</StyledTableCell>
                                            {(typeReport !== "D" ? true : false) && (
                                                <StyledTableCell scope="row">
                                                    {FormatInvResult(Number(total.net_usage), "lbs", 0)}
                                                </StyledTableCell>
                                            )}
                                            <StyledTableCell scope="row">{""}</StyledTableCell>
                                        </StyledTableRow>
                                    </>
                                )}

                                {/* MT */}
                                {showMultipleRow.showMT && (
                                    <>
                                        <StyledTableRow style={{ backgroundColor: "yellow" }}>
                                            <StyledTableCell scope="row">{""}</StyledTableCell>
                                            <StyledTableCell scope="row">
                                                {(showMultipleRow.showBu === false &&
                                                    showMultipleRow.showlbs === false &&
                                                    showMultipleRow.showMT === true) ||
                                                    (showMultipleRow.showMT === true &&
                                                        showMultipleRow.showBu === true &&
                                                        showMultipleRow.showlbs === false)
                                                    ? "Total"
                                                    : ""}
                                            </StyledTableCell>
                                            <StyledTableCell scope="row">
                                                {total.b_inv === 0
                                                    ? FormatInvResult(
                                                        Number(Number(total.b_inv) / 2204),
                                                        "MT",
                                                        0
                                                    )
                                                    : FormatInvResult(
                                                        Number(Number(total.b_inv) / 2204),
                                                        "MT",
                                                        1
                                                    )}
                                            </StyledTableCell>
                                            <StyledTableCell scope="row">
                                                {total.e_inv === 0
                                                    ? FormatInvResult(
                                                        Number(Number(total.e_inv) / 2204),
                                                        "MT",
                                                        0
                                                    )
                                                    : FormatInvResult(
                                                        Number(Number(total.e_inv) / 2204),
                                                        "MT",
                                                        1
                                                    )}
                                            </StyledTableCell>
                                            <StyledTableCell scope="row">{""}</StyledTableCell>
                                            {(typeReport !== "D" ? true : false) && (
                                                <StyledTableCell scope="row">
                                                    {total.net_usage === 0
                                                        ? FormatInvResult(
                                                            Number(Number(total.net_usage) / 2204),
                                                            "MT",
                                                            0
                                                        )
                                                        : FormatInvResult(
                                                            Number(Number(total.net_usage) / 2204),
                                                            "MT",
                                                            1
                                                        )}
                                                </StyledTableCell>
                                            )}
                                            <StyledTableCell scope="row">{""}</StyledTableCell>
                                        </StyledTableRow>
                                    </>
                                )}

                                {/* BU */}
                                {showMultipleRow.showBu && (
                                    <>
                                        <StyledTableRow style={{ backgroundColor: "yellow" }}>
                                            <StyledTableCell scope="row">{""}</StyledTableCell>
                                            <StyledTableCell scope="row">
                                                {showMultipleRow.showMT === false &&
                                                    showMultipleRow.showlbs === false &&
                                                    showMultipleRow.showBu === true
                                                    ? "Total"
                                                    : ""}
                                            </StyledTableCell>
                                            <StyledTableCell scope="row">
                                                {total.b_inv === 0
                                                    ? FormatInvResult(
                                                        Number(
                                                            Number(total.b_inv) /
                                                            Number(total.specific_Gravity)
                                                        ),
                                                        "Bu",
                                                        0
                                                    )
                                                    : FormatInvResult(
                                                        Number(
                                                            Number(total.b_inv) /
                                                            Number(total.specific_Gravity)
                                                        ),
                                                        "Bu",
                                                        1
                                                    )}
                                            </StyledTableCell>
                                            <StyledTableCell scope="row">
                                                {total.e_inv === 0
                                                    ? FormatInvResult(
                                                        Number(
                                                            Number(total.e_inv) /
                                                            Number(total.specific_Gravity)
                                                        ),
                                                        "Bu",
                                                        0
                                                    )
                                                    : FormatInvResult(
                                                        Number(
                                                            Number(total.e_inv) /
                                                            Number(total.specific_Gravity)
                                                        ),
                                                        "Bu",
                                                        1
                                                    )}
                                            </StyledTableCell>
                                            <StyledTableCell scope="row">{""}</StyledTableCell>
                                            {(typeReport !== "D" ? true : false) && (
                                                <StyledTableCell scope="row">
                                                    {total.net_usage === 0
                                                        ? FormatInvResult(
                                                            Number(
                                                                Number(total.net_usage) /
                                                                Number(total.specific_Gravity)
                                                            ),
                                                            "Bu",
                                                            0
                                                        )
                                                        : FormatInvResult(
                                                            Number(
                                                                Number(total.net_usage) /
                                                                Number(total.specific_Gravity)
                                                            ),
                                                            "Bu",
                                                            1
                                                        )}
                                                </StyledTableCell>
                                            )}
                                            <StyledTableCell scope="row">{""}</StyledTableCell>
                                        </StyledTableRow>
                                    </>
                                )}
                            </TableBody>
                        </Tbl>
                    </CardContent>
                </Card>
            )}
        </>
    );
};
export default UsageReport;
