/* eslint-disable react-hooks/exhaustive-deps */
import TableBody from "@mui/material/TableBody";
import { Card, CardContent } from "@mui/material";
import { ProductInventory, ProductInventories } from "../../../models/product";
import { StyledTableCell, StyledTableRow } from "../../controls/tablecontrols";
import { useEffect, useState } from "react";
import CustomerDataService from "../../../services/customerService";
import useTable from "../../../hooks/useTable";
import { TableHeaderCell } from "../../../models/common";
import CustomCardHeader from "../../shared/CustomCardHeader";
import { FormatInvResult } from "../../shared/CommonFunctions";

interface Props {
  customerNo: string;
  locationNo?: string;
}

function CustomerProductInventories({ customerNo }: Props) {
  const [currentInventories, setCurrentInventories] = useState<
    ProductInventories[]
  >([]);

  const [inTransitInventories, setInTransitInventories] = useState<
    ProductInventory[]
  >([]);

  const headerCellsCurrentInventory: TableHeaderCell[] = [
    { id: "product_desc", label: "Product Description" },
    { id: "product_code", label: "Product Code" },
    { id: "current_Inventories", label: "Current Inventory" },
  ];

  const headerCellsInTransitInventory: TableHeaderCell[] = [
    { id: "product_desc", label: "In-Transit Product" },
    { id: "product_code", label: "Product Code" },
    { id: "intransit_inv", label: "In-Transit Inventory" },
  ];

  const clearInventories = () => {
    setCurrentInventories([]);
    setInTransitInventories([]);
  };

  const { fetchData: fetchCurrentInventoriesData } =
    CustomerDataService.GetProductInventories(customerNo);

  const { fetchData: fetchInTransitInventoriesData } =
    CustomerDataService.GetSumIntransitInv(customerNo);

  useEffect(() => {
    if (customerNo) {
      CurrentInventory();
      InTransitInventory();
    } else {
      clearInventories();
    }
  }, [customerNo]);

  const CurrentInventory = () => {
    fetchCurrentInventoriesData().then((res) => {
      setCurrentInventories(res.responseData);
    });
  };

  const InTransitInventory = () => {
    fetchInTransitInventoriesData().then((res) => {
      setInTransitInventories(res.responseData);
    });
  };

  const {
    Tbl: TblCurrent,
    GetRowsAfterPagingAndSorting: GetRowsCurrentProductInventory,
  } = useTable<ProductInventories>(
    currentInventories,
    headerCellsCurrentInventory,
    false
  );

  const {
    Tbl: TblInTransit,
    GetRowsAfterPagingAndSorting: GetRowsInTransitProductInventory,
  } = useTable<ProductInventory>(
    inTransitInventories,
    headerCellsInTransitInventory,
    false
  );

  return (
    <Card
      sx={{ width: "100hv", borderRadius: 3, margin: 2, mt: 0 }}
      elevation={6}
    >
      <CustomCardHeader title="Product Inventories"></CustomCardHeader>
      <CardContent>
        <TblCurrent>
          {currentInventories.length > 0 ? (
            <TableBody>
              {GetRowsCurrentProductInventory().map((row, index) => (
                <StyledTableRow key={"Current" + index + row.product_code}>
                  <StyledTableCell>{row.product_desc}</StyledTableCell>
                  <StyledTableCell>{row.product_code}</StyledTableCell>
                  <StyledTableCell>
                    {FormatInvResult(
                      parseFloat(row.current_Inventories),
                      row.uom
                    )}
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          ) : (
            <TableBody></TableBody>
          )}
        </TblCurrent>
        <br></br>
        <TblInTransit>
          {inTransitInventories.length > 0 ? (
            <TableBody>
              {GetRowsInTransitProductInventory().map((row, index) => (
                <StyledTableRow key={"Intrasnit" + index + row.product_code}>
                  <StyledTableCell>{row.product_desc}</StyledTableCell>
                  <StyledTableCell>{row.product_code}</StyledTableCell>
                  <StyledTableCell>
                    {FormatInvResult(row.intransit_inv, row.uom)}
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          ) : (
            <TableBody></TableBody>
          )}
        </TblInTransit>
      </CardContent>
    </Card>
  );
}
export default CustomerProductInventories;
