import ShipmentEntry from "../components/app/Shipment/ShipmentEntry";
import SystemMessage from "./SystemMessage";

function ShipmentEntryPage() {
  const role = sessionStorage.getItem("roles");
  return role?.toString().toUpperCase() === "ADMIN" ? (
    <ShipmentEntry></ShipmentEntry>
  ) : (
    <SystemMessage msg="Access Denied." colorMsg="Red"></SystemMessage>
  );
}

export default ShipmentEntryPage;
