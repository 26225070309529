/* eslint-disable react-hooks/exhaustive-deps */
import { Card, Grid, Link, Table, TableBody, Button } from "@mui/material";
import { useState, useEffect } from "react";
import { ProductInventoryByLocation } from "../../../models/product";
import LocationService from "../../../services/locationService";
import { StyledCardContent } from "../../controls/commoncontrols";
import CustomCardHeader from "../../shared/CustomCardHeader";
import { StyledTableRow, StyledTableCell } from "../../controls/tablecontrols";
import { FormatInvResult } from "../../shared/CommonFunctions";
import moment from "moment";
import BatchMaintenance from "./BatchMaintenance";
import ModalPopup from "../../shared/ModalPopup";
import ShowDailyPage from "../Location/ShowDailyPage";
import EditProductLocation from "./EditProductLocation";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";

interface ProductInventoryProps {
  locationNo: string;
  customerNo: string;
}

function ProductInventory({ locationNo, customerNo }: ProductInventoryProps) {
  const role = sessionStorage.getItem("roles");

  const [productDetails, setProductInfo] = useState<
    ProductInventoryByLocation[]
  >([]);
  const [isRefreshProdInv, setIsRefreshProdInv] = useState<boolean>(false);
  const [showModalPopup, setShowModalPopup] = useState<boolean>(false);
  const [product_ID, setProduct_ID] = useState<number>(0);
  const [location_Name, setLocation_Name] = useState<string>("");
  const [location_ID, setLocation_ID] = useState<number>(0);
  const [showBatchModalPopup, setshowBatchModalPopup] =
    useState<boolean>(false);
  const [showProductModalPopup, setshowProductModalPopup] =
    useState<boolean>(false);

  const { fetchData: fetchProductInventory } =
    LocationService.GetProductInventoryByDate(customerNo, locationNo);

  //--Batch Maintenance--

  const onBatchModalClose = (e: React.MouseEvent<HTMLButtonElement>) => {
    Promise.resolve().then(() => {
      setProduct_ID(0);
      setshowBatchModalPopup(false);
    });
  };

  const handleEditBatch = (product_ID: number) => {
    Promise.resolve().then(() => {
      setProduct_ID(product_ID);
      setshowBatchModalPopup(true);
    });
  };
  //---------------------

  //--Show Daily---------
  const onModalClose = (e: React.MouseEvent<HTMLButtonElement>) => {
    Promise.resolve().then(() => {
      setProduct_ID(0);
      setLocation_Name("");
      setLocation_ID(0);
      setShowModalPopup(false);
    });
  };

  const onShowDailyClick = (
    product_ID: number,
    location_Name: string,
    location_ID: number
  ) => {
    Promise.resolve().then(() => {
      setProduct_ID(product_ID);
      setLocation_Name(location_Name);
      setLocation_ID(location_ID);
      setShowModalPopup(true);
    });
  };
  //---------------------

  //--Product Location---
  const editProdLocationClick = (
    product_ID: number,
    location_ID: number,
    locationName: string
  ) => {
    Promise.resolve().then(() => {
      setProduct_ID(product_ID);
      setLocation_ID(location_ID);
      setLocation_Name(locationName);
      setshowProductModalPopup(true);
    });
  };

  const onProdLocationModalClose = (e: React.MouseEvent<HTMLButtonElement>) => {
    Promise.resolve().then(() => {
      setProduct_ID(0);
      setLocation_ID(0);
      setshowProductModalPopup(false);
    });
  };
  //---------------------

  useEffect(() => {
    if (customerNo && locationNo) {
      fetchProductInventory().then((res) => {
        setProductInfo(res.responseData);
      });
    } else {
      setProductInfo([]);
    }
  }, [customerNo, locationNo]);

  useEffect(() => {
    if (isRefreshProdInv) {
      if (customerNo && locationNo) {
        fetchProductInventory().then((res) => {
          setProductInfo(res.responseData);
          setIsRefreshProdInv(false);
        });
      } else {
        setProductInfo([]);
        setIsRefreshProdInv(false);
      }
    }
  }, [customerNo, locationNo, isRefreshProdInv]);

  function renderTable() {
    if (productDetails.length > 0) {
      return productDetails.map((row, index) => {
        return (
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <Card
              sx={{ width: "100hv", borderRadius: 1, margin: 2 }}
              elevation={6}
            >
              <Table key={index + row.product_ID}>
                <TableBody>
                  <StyledTableRow style={{ backgroundColor: "#2C275D" }}>
                    <StyledTableCell
                      style={{ color: "white", fontWeight: "bold" }}
                    >
                      {row.product_Desc}
                    </StyledTableCell>
                    <StyledTableCell style={{ textAlign: "right" }}>
                      {role?.toString().toUpperCase() === "ADMIN" ? (
                        <Button
                          variant="text"
                          sx={{ fontSize: "0.80rem" }}
                          style={{ color: "white" }}
                          onClick={(e) =>
                            editProdLocationClick(
                              row.product_ID,
                              row.location_ID,
                              row.location_Name
                            )
                          }
                        >
                          <u>Edit Product Loc.</u>
                        </Button>
                      ) : (
                        ""
                      )}
                      <Button
                        variant="text"
                        sx={{ fontSize: "0.80rem" }}
                        style={{ color: "white" }}
                        onClick={(e) =>
                          onShowDailyClick(
                            row.product_ID,
                            row.location_Name,
                            row.location_ID
                          )
                        }
                      >
                        <u>Show Daily</u>
                      </Button>
                    </StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow>
                    <StyledTableCell>Tanks</StyledTableCell>
                    <StyledTableCell>{row.no_Of_Tanks}</StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow>
                    <StyledTableCell>Current Inventory</StyledTableCell>
                    <StyledTableCell>
                      {FormatInvResult(row.current_Inv, row.uom)}
                    </StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow>
                    <StyledTableCell>Delivery Dates</StyledTableCell>
                    <StyledTableCell>
                      {row.last_Delivery_Date} {row.next_Delivery_Date}
                    </StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow>
                    <StyledTableCell>Daily Product Usage</StyledTableCell>
                    <StyledTableCell>
                      {row.daily_Usage <= 0 && row.uom.toUpperCase() !== "MT"
                        ? "Delivery in progress"
                        : FormatInvResult(row.daily_Usage, row.uom)}
                    </StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow>
                    <StyledTableCell>Average Daily Usage</StyledTableCell>
                    <StyledTableCell>
                      {FormatInvResult(row.avg_Daily_Usage, row.uom)}
                    </StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow>
                    <StyledTableCell>Reorder Point</StyledTableCell>
                    <StyledTableCell>
                      {FormatInvResult(row.reorder_Point, row.uom)}
                    </StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow>
                    <StyledTableCell>Reorder Point Days</StyledTableCell>
                    <StyledTableCell>{row.reorder_Point_Days}</StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow>
                    <StyledTableCell>Intransit Inventory</StyledTableCell>
                    <StyledTableCell>
                      {FormatInvResult(row.intransit_Inv, row.uom)}
                    </StyledTableCell>
                  </StyledTableRow>
                  {row.vB_BATCH_NAME != null ? (
                    <>
                      <StyledTableRow>
                        <StyledTableCell>
                          Batch Name&nbsp;&nbsp;&nbsp;&nbsp;
                          {role?.toString().toUpperCase() === "ADMIN" ? (
                            <Link
                              component="button"
                              style={{ color: "Green", fontWeight: "bold" }}
                              onClick={() => handleEditBatch(row.product_ID)}
                            >
                              Edit Batch
                            </Link>
                          ) : (
                            ""
                          )}
                        </StyledTableCell>

                        {row.vB_URL !== "" ? (
                          <StyledTableCell>
                            <Link
                              href={"http://" + row.vB_URL}
                              target="_blank"
                              style={{ color: "Green", fontWeight: "bold" }}
                            >
                              {row.vB_BATCH_NAME}{" "}
                              <OpenInNewIcon fontSize="inherit" />
                            </Link>
                          </StyledTableCell>
                        ) : (
                          <StyledTableCell>
                            {row.vB_BATCH_NAME}{" "}
                          </StyledTableCell>
                        )}
                      </StyledTableRow>
                      <StyledTableRow>
                        <StyledTableCell>Calibration Date</StyledTableCell>
                        <StyledTableCell>
                          {moment(row.vB_CALIBRATION_DATE).format(
                            "MM/DD/YYYY"
                          ) === "01/01/1900"
                            ? " "
                            : moment(row.vB_CALIBRATION_DATE).format(
                                "MM/DD/YYYY"
                              )}
                        </StyledTableCell>
                      </StyledTableRow>
                    </>
                  ) : (
                    <>
                      <StyledTableRow>
                        <StyledTableCell>Batch Name</StyledTableCell>

                        <StyledTableCell>
                          {role?.toString().toUpperCase() === "ADMIN" ? (
                            <Link
                              component="button"
                              style={{ color: "Green", fontWeight: "bold" }}
                              onClick={() => handleEditBatch(row.product_ID)}
                            >
                              Add Batch
                            </Link>
                          ) : (
                            ""
                          )}
                        </StyledTableCell>
                      </StyledTableRow>
                    </>
                  )}
                </TableBody>
              </Table>
            </Card>
          </Grid>
        );
      });
    } else {
      return (
        <Grid item xs={12} sm={12} md={12} lg={12}>
          No records found
        </Grid>
      );
    }
  }

  return (
    <div>
      <Card
        sx={{ width: "100hv", borderRadius: 3, margin: 2, mt: 0 }}
        elevation={6}
      >
        <CustomCardHeader title="Product Inventory"></CustomCardHeader>
        <StyledCardContent>
          <Grid container>{renderTable()}</Grid>
        </StyledCardContent>
      </Card>

      <ModalPopup
        open={showModalPopup}
        title={"Show Daily Report"}
        onPopupClose={onModalClose}
        width="800px"
      >
        <ShowDailyPage
          product_ID={product_ID}
          location_Name={location_Name}
          location_ID={location_ID}
        ></ShowDailyPage>
      </ModalPopup>

      <ModalPopup
        open={showBatchModalPopup}
        title={"Batch Specifications - Setting Information"}
        onPopupClose={onBatchModalClose}
      >
        <BatchMaintenance
          setshowBatchModalPopup={setshowBatchModalPopup}
          locationNo={locationNo}
          productId={product_ID === null ? 0 : product_ID}
          customerNo={customerNo}
          setIsRefreshProdInv={setIsRefreshProdInv}
        />
      </ModalPopup>

      <ModalPopup
        open={showProductModalPopup}
        title={"Edit Product Location Information"}
        onPopupClose={onProdLocationModalClose}
        width="1200px"
      >
        <EditProductLocation
          setshowProductModalPopup={setshowProductModalPopup}
          productID={product_ID === null ? 0 : product_ID}
          locationID={location_ID === null ? 0 : location_ID}
          locationName={location_Name}
        />
      </ModalPopup>
    </div>
  );
}

export default ProductInventory;
