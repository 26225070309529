/* eslint-disable import/no-anonymous-default-export */
import useAxiosGet from "../hooks/useAxiosGet";
import { InboundUser, RailUser, RailcarTerminal } from "../models/common";

const isLocal = () => process.env.REACT_APP_ENVIR === "LOCAL";

const DetermineInboundOrOutbound = (customerNo: string) => {
  return useAxiosGet<InboundUser[]>(
    isLocal()
      ? `/Common/DetermineInboundOrOutbound/customers/${customerNo}/inboundcustomers`
      : `/customers/${customerNo}/inboundcustomers`,
    [],
    "Inbound Customer"
  );
};

const DetermineIfCustomerIsRailUser = (customerNo: string) => {
  return useAxiosGet<RailUser[]>(
    isLocal()
      ? `/Common/DetermineIfCustomerIsRailUser/customers/${customerNo}/railusers`
      : `/customers/${customerNo}/railusers`,
    [],
    "Rail User"
  );
};

const DetermineRailcarTerminal = (customerNo: string, locationNo: string) => {
  return useAxiosGet<RailcarTerminal[]>(
    isLocal()
      ? `/Common/DetermineRailcarTerminal/customers/${customerNo}/locations/${locationNo}/railcarterminal`
      : `/customers/${customerNo}/locations/${locationNo}/railcarterminal`,
    [],
    "Railcar Terminal"
  );
};

export default {
  DetermineInboundOrOutbound,
  DetermineIfCustomerIsRailUser,
  DetermineRailcarTerminal,
};
