import Avatar from '@mui/material/Avatar';

function stringToColor(string: string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

function stringAvatar(name: string) {    
  let shortName = ""; 

  let isEmptyChecked = isEmptyOrSpaces(name);

  if(!isEmptyChecked)
  {
    shortName = getShortName(name);
  }
  else
  {    
    shortName = "X";
  }   
  
  return {    
    sx: {
      height: 35, 
      width: 35,
      mr:1,
      bgcolor: stringToColor(name),    
    },
    children: `${shortName}`,
  };
}

function isEmptyOrSpaces(name: string){
  let isSpecialChar = containsSpecialChars(name);
  if (isSpecialChar)
  {
    let modifiedAccountName = name.replace(/ *\([^)]*\) */g, ""); 
    return modifiedAccountName === null || modifiedAccountName.match(/^ *$/) !== null;
  }
  else {
    return name === null || name.match(/^ *$/) !== null;
  }  
}

function containsSpecialChars(name: string) {
  // eslint-disable-next-line no-useless-escape
  const specialChars = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
  return specialChars.test(name);
}

function getShortName(name: string) {
  const firstLetters = name
    .split(' ')
    .map(word => word[0])
    .join('')
    .substring(0, 2);
  
  return firstLetters;
}

export default function ProfileNameAvatar(accountName:any) {   
    return (
        <Avatar {...stringAvatar(accountName.accountName)} />        
    )
}