import React, { useRef } from "react";
import { useReactToPrint } from "react-to-print";
import { OpenOrdersModel } from "../../../models/order";
import { TableHeaderCell } from "../../../models/common";
import { Button, Grid } from "@mui/material";
import PrintIcon from "@mui/icons-material/Print";

interface Props {
  openOrder: OpenOrdersModel[];
  tableHeader: TableHeaderCell[];
}

function OpenOrdersPrint(props: React.PropsWithChildren<Props>) {
  const componentRef = useRef(null);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const tableStyle = {
    div: {
      padding: "10px",
      paddingLeft: "30px",
      paddingRight: "30px",
    },
    table: {
      border: "1px solid black",
      "border-collapse": "collapse",
      width: "100%",
    },
    tdata: {
      border: "1px solid black",
      "border-collapse": "collapse",
      paddingLeft: "5px",
    },
  };

  function loadCurrentDate() {
    let datetime = new Date().toLocaleDateString() + "," + new Date().toLocaleTimeString();
    return datetime;
  }

  return (
    <React.Fragment>
      <div>
        <Button onClick={handlePrint} startIcon={<PrintIcon />} sx={{ mr: 2 }}>
          Print
        </Button>
      </div>
      <div id="printTable" ref={componentRef} style={tableStyle.div}>
      <Grid container spacing={3} sx={{ paddingTop: 1 }}>
          <Grid item xs={5} sm={5} md={5} lg={5}>{loadCurrentDate()}</Grid>
          <Grid item xs={7} sm={7} md={7} lg={7} style={{ textAlign: "left", marginBottom: "10px", fontWeight: "bold", fontSize: "18px" }}><span >Open Orders</span></Grid>
        </Grid>
        <table style={tableStyle.table}>
          <thead>
            <tr>
              {props.tableHeader.map((header, index) => (
                <th style={tableStyle.tdata}>{header.label}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {props.openOrder.map((row, index) => (
              <tr key={index}>
                <td style={tableStyle.tdata}>{row.loC_NAME}</td>
                <td style={tableStyle.tdata}>{row.loC_SHIPTO}</td>
                <td style={tableStyle.tdata}>{row.proD_CODE}</td>
                <td style={tableStyle.tdata}>{row.formattedCapacity}</td>
                <td style={tableStyle.tdata}>{row.formattedActuaL_INV}</td>
                <td style={tableStyle.tdata}>{row.formattedReordeR_PNT}</td>
                <td style={tableStyle.tdata}>{row.formattedReordeR_QTY}</td>
                <td style={tableStyle.tdata}>{row.ordeR_DATE}</td>
                <td style={tableStyle.tdata}>{row.arrivaL_DATE}</td>
                <td style={tableStyle.tdata}>{row.ordeR_NO}</td>
                <td style={tableStyle.tdata}>{row.orderState}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </React.Fragment>
  );
}

export default OpenOrdersPrint;
