import React, { useEffect, useState } from "react";
// import { Link as RouterLink } from "react-router-dom";

// Shared Component
import PageHeader from "../../shared/PageHeader";
import {
  Button,
  Card,
  CardContent,
  Grid,
  MenuItem,
  TableBody,
  TextField,
} from "@mui/material";
import PrintIcon from "@mui/icons-material/Print";

// Maintain States
import { IFilterBandState } from "../../../interfaces/storeInterfaces";
import { useSelector } from "react-redux";
import { filterBandSelector, useAppDispatch } from "../../../store/store";

// Models
import { ProductCode } from "../../../models/shipment";

// Services
import ShipmentDataService from "../../../services/shipmentService";
import ProductDataService from "../../../services/productService";
import useTable from "../../../hooks/useTable";
import { TableHeaderCell } from "../../../models/common";
import { StyledTableCell, StyledTableRow } from "../../controls/tablecontrols";
import PrintInventory from "./PrintInventory";
import ModalPopup from "../../shared/ModalPopup";
import ProductInventoriesTable from "./ProductInventories";
import { filterBandActions } from "../../../store/filterband-slice";

function IntransitProductInventory() {
  const dispatch = useAppDispatch();
  const filterBandState: IFilterBandState = useSelector(filterBandSelector);
  const [productId, setProductId] = useState<string>("");
  const [grandTotal, setGrandTotal] = useState<number>(0);
  const [productList, setProductList] = useState<ProductCode[]>([]);
  const [shipmentCount, setShipmentCount] = useState<any>([]);
  const [shipmentCountEmpty, setShipmentCountEmpty] = useState<any>([]);
  const [shipmentCountForAll, setShipmentCountForAll] = useState<any>([]);
  const [showModalPopup, setShowModalPopup] = useState<boolean>(false);
  const [locationName, setLocationName] = useState<string>("");
  const [productName, setProductName] = useState<string>("");
  const [showPrintInventory, setshowPrintInventory] = useState<boolean>(false);
  // Function creating table row
  function createData(IsRow: boolean, State: string, CountTotal: number) {
    return { IsRow, State, CountTotal };
  }
  const tableHeaderCellDetails: TableHeaderCell[] = [
    { id: "rS_CURRENT_CLM_STATE", label: "Loaded" },
    { id: "countTotal", label: "" },
  ];
  const { Tbl } = useTable<any>(shipmentCount, tableHeaderCellDetails, false);

  // Function Creating table row
  function createDataForEmpty(
    IsRow: boolean,
    State: string,
    CountTotal: number
  ) {
    return { IsRow, State, CountTotal };
  }
  const tableHeaderCellDetailsForEmpty: TableHeaderCell[] = [
    { id: "state", label: "Empties" },
    { id: "countTotal", label: "" },
  ];
  const { Tbl: TblEmpty } = useTable<any>(
    shipmentCountEmpty,
    tableHeaderCellDetailsForEmpty,
    false
  );

  // Function Creating table row
  function createDataForAll(IsRow: boolean, State: string, CountTotal: number) {
    return { IsRow, State, CountTotal };
  }
  const tableHeaderCellDetailsForAll: TableHeaderCell[] = [
    { id: "state", label: "Loaded For" },
    { id: "countTotal", label: "" },
  ];
  const { Tbl: TblForAll } = useTable<any>(
    shipmentCountForAll,
    tableHeaderCellDetailsForAll,
    false
  );

  const { fetchData: fetchProductData } =
    ShipmentDataService.RetrieveProductsByLocation(
      filterBandState.selectedCustomerNo,
      filterBandState.selectedLocationNo
    );

  const { fetchData: fetchShipmentCountNonUs } =
    ProductDataService.RetrieveShipmentCountNonUs(
      filterBandState.selectedLocationNo
    );

  const { fetchData: fetchShipmentCountLoadedNonUs } =
    ProductDataService.RetrieveShipmentCountLoadedNonUs(
      filterBandState.selectedCustomerNo
    );

  const { fetchData: fetchShipmentCountLoaded } =
    ProductDataService.RetrieveShipmentCountLoaded(
      filterBandState.selectedLocationNo,
      productId
    );

  const { fetchData: fetchShipmentDecaturCountLoaded } =
    ProductDataService.RetrieveShipmentCountLoadedDecatur(
      filterBandState.selectedCustomerNo,
      productId
    );

  const { fetchData: fetchShipmentCountLoadedCarrier } =
    ProductDataService.RetrieveShipmentCountLoadedCarrier(
      filterBandState.selectedLocationNo === ""
        ? filterBandState.selectedCustomerNo
        : filterBandState.selectedLocationNo,
      productId
    );

  const { fetchData: fetchShipmentCountLoadedAll } =
    ProductDataService.RetrieveShipmentCountLoadedAll(
      filterBandState.selectedCustomerNo,
      productId
    );

  const { fetchData: fetchShipmentCountEmpty } =
    ProductDataService.RetrieveShipmentCountEmpty(productId);

  const { fetchData: fetchShipmentCountDecaturEmpty } =
    ProductDataService.RetrieveShipmentCountEmptyDecatur(productId);

  const { fetchData: fetchShipmentCountEmptyAtShop } =
    ProductDataService.RetrieveShipmentCountEmptyAtShop();

  const { fetchData: fetchShipmentCountLoadedForAll } =
    ProductDataService.RetrieveShipmentCountLoadedForAll(
      filterBandState.selectedLocationNo,
      productId
    );

  const { fetchData: fetchShipmentCountLoadedForAllCustomer } =
    ProductDataService.RetrieveShipmentCountLoadedForAllCustomer(
      filterBandState.selectedCustomerNo,
      productId
    );

  const { fetchData: fetchShipmentCountLoadedForAllOL } =
    ProductDataService.RetrieveShipmentCountLoadedForAllOL();

  useEffect(() => {
    if (
      filterBandState.selectedCustomerNo !== "" &&
      filterBandState.selectedCustomerNo !== undefined
    ) {
      if (filterBandState.selectedLocationNo !== "") {
        let locationName = filterBandState.locations.filter(
          (x) => x.locationNo === filterBandState.selectedLocationNo
        );
        setLocationName(locationName[0].locationName);
      }
      loadProduct();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterBandState.selectedCustomerNo, filterBandState.selectedLocationNo]);

  useEffect(() => {
    if (filterBandState.selectedLocationNo !== "" || productId !== "") {
      loadDataSet();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterBandState.selectedLocationNo, productId]);

  // Functions
  const loadProduct = () => {
    fetchProductData().then((res) => {
      setProductList(res.responseData);
      setProductName(res.responseData[0].vP_PRODUCT_DESC);
    });
  };

  const loadDataSet = async () => {
    let chkNon = false;
    const row: any = [];
    const rowEmpty: any = [];
    const rowAll: any = [];
    let countTotal = 0;
    let countEmptyTotal = 0;
    let countAllTotal = 0;
    let grandTotal = 0;

    if (filterBandState.selectedLocationNo !== "") {
      let NonUsData = await fetchShipmentCountNonUs();
      if (NonUsData.isSuccess === true) {
        chkNon = NonUsData.responseData.vL_LOC_AS_SOLDTO;
      }
    }

    if (filterBandState.selectedLocationNo !== "") {
      if (productId === "") {
        if (chkNon === true) {
          let shipmentCountLoadedNonUs = await fetchShipmentCountLoadedNonUs();
          if (
            shipmentCountLoadedNonUs.isSuccess &&
            shipmentCountLoadedNonUs.responseData.length > 0
          ) {
            for (
              let i = 0;
              i < shipmentCountLoadedNonUs.responseData.length;
              i++
            ) {
              row.push(
                createData(
                  true,
                  "Loaded in " +
                    shipmentCountLoadedNonUs.responseData[i]
                      .rS_CURRENT_CLM_STATE,
                  shipmentCountLoadedNonUs.responseData[i].countTotal
                )
              );
              countTotal =
                countTotal +
                shipmentCountLoadedNonUs.responseData[i].countTotal;
            }
          }
        } else {
          let ShipmentCountLoaded = await fetchShipmentCountLoaded();
          if (
            ShipmentCountLoaded.isSuccess &&
            ShipmentCountLoaded.responseData.length > 0
          ) {
            for (let i = 0; i < ShipmentCountLoaded.responseData.length; i++) {
              row.push(
                createData(
                  true,
                  "Loaded in " +
                    ShipmentCountLoaded.responseData[i].rS_CURRENT_CLM_STATE,
                  ShipmentCountLoaded.responseData[i].countTotal
                )
              );
              countTotal =
                countTotal + ShipmentCountLoaded.responseData[i].countTotal;
            }
          }
        }

        let shipmentDecaturCountLoaded =
          await fetchShipmentDecaturCountLoaded();
        if (
          shipmentDecaturCountLoaded.isSuccess &&
          shipmentDecaturCountLoaded.responseData.length > 0
        ) {
          for (
            let i = 0;
            i < shipmentDecaturCountLoaded.responseData.length;
            i++
          ) {
            row.push(
              createData(
                true,
                "Loaded in " +
                  shipmentDecaturCountLoaded.responseData[i]
                    .rS_CURRENT_CLM_CITY,
                shipmentDecaturCountLoaded.responseData[i].countTotal
              )
            );
            countTotal =
              countTotal +
              shipmentDecaturCountLoaded.responseData[i].countTotal;
          }
        }

        let shipmentCountLoadedCarrier =
          await fetchShipmentCountLoadedCarrier();
        if (
          shipmentCountLoadedCarrier.isSuccess &&
          shipmentCountLoadedCarrier.responseData.length > 0
        ) {
          for (
            let i = 0;
            i < shipmentCountLoadedCarrier.responseData.length;
            i++
          ) {
            row.push(
              createData(
                true,
                "Loaded at " +
                  shipmentCountLoadedCarrier.responseData[i].locationCity +
                  " (" +
                  shipmentCountLoadedCarrier.responseData[i]
                    .rS_CURRENT_CLM_CARRIER +
                  ") ",
                shipmentCountLoadedCarrier.responseData[i].countTotal
              )
            );
            countTotal =
              countTotal +
              shipmentCountLoadedCarrier.responseData[i].countTotal;
          }
        }
        grandTotal = grandTotal + countTotal;
        setGrandTotal(grandTotal);
        row.push(createData(false, "Total Loaded For CLINTON :", countTotal));
        setShipmentCount(row);
      } else {
        if (chkNon === true) {
          let shipmentCountLoadedNonUs = await fetchShipmentCountLoadedNonUs();
          if (
            shipmentCountLoadedNonUs.isSuccess &&
            shipmentCountLoadedNonUs.responseData.length > 0
          ) {
            for (
              let i = 0;
              i < shipmentCountLoadedNonUs.responseData.length;
              i++
            ) {
              row.push(
                createData(
                  true,
                  "Loaded in " +
                    shipmentCountLoadedNonUs.responseData[i]
                      .rS_CURRENT_CLM_STATE,
                  shipmentCountLoadedNonUs.responseData[i].countTotal
                )
              );
              countTotal =
                countTotal +
                shipmentCountLoadedNonUs.responseData[i].countTotal;
            }
          }
        } else {
          let ShipmentCountLoaded = await fetchShipmentCountLoaded();
          if (
            ShipmentCountLoaded.isSuccess &&
            ShipmentCountLoaded.responseData.length > 0
          ) {
            for (let i = 0; i < ShipmentCountLoaded.responseData.length; i++) {
              row.push(
                createData(
                  true,
                  "Loaded in " +
                    ShipmentCountLoaded.responseData[i].rS_CURRENT_CLM_STATE,
                  ShipmentCountLoaded.responseData[i].countTotal
                )
              );
              countTotal =
                countTotal + ShipmentCountLoaded.responseData[i].countTotal;
            }
          }
        }

        let shipmentDecaturCountLoaded =
          await fetchShipmentDecaturCountLoaded();
        if (
          shipmentDecaturCountLoaded.isSuccess &&
          shipmentDecaturCountLoaded.responseData.length > 0
        ) {
          if (
            shipmentDecaturCountLoaded.isSuccess &&
            shipmentDecaturCountLoaded.responseData.length > 0
          ) {
            for (
              let i = 0;
              i < shipmentDecaturCountLoaded.responseData.length;
              i++
            ) {
              row.push(
                createData(
                  true,
                  "Loaded in " +
                    shipmentDecaturCountLoaded.responseData[i]
                      .rS_CURRENT_CLM_CITY,
                  shipmentDecaturCountLoaded.responseData[i].countTotal
                )
              );
              countTotal =
                countTotal +
                shipmentDecaturCountLoaded.responseData[i].countTotal;
            }
          }
        }

        let shipmentCountLoadedCarrier =
          await fetchShipmentCountLoadedCarrier();
        if (
          shipmentCountLoadedCarrier.isSuccess &&
          shipmentCountLoadedCarrier.responseData.length > 0
        ) {
          for (
            let i = 0;
            i < shipmentCountLoadedCarrier.responseData.length;
            i++
          ) {
            row.push(
              createData(
                true,
                "Loaded at " +
                  shipmentCountLoadedCarrier.responseData[i].locationCity +
                  " (" +
                  shipmentCountLoadedCarrier.responseData[i]
                    .rS_CURRENT_CLM_CARRIER +
                  ") ",
                shipmentCountLoadedCarrier.responseData[i].countTotal
              )
            );
            countTotal =
              countTotal +
              shipmentCountLoadedCarrier.responseData[i].countTotal;
          }
          grandTotal = grandTotal + countTotal;
          setGrandTotal(grandTotal);
          row.push(createData(false, "Total Loaded For CLINTON :", countTotal));
        }
        setShipmentCount(row);
      }
    } else {
      if (productId === "") {
        if (chkNon === true) {
          let shipmentCountLoadedNonUs = await fetchShipmentCountLoadedNonUs();
          if (
            shipmentCountLoadedNonUs.isSuccess &&
            shipmentCountLoadedNonUs.responseData.length > 0
          ) {
            for (
              let i = 0;
              i < shipmentCountLoadedNonUs.responseData.length;
              i++
            ) {
              row.push(
                createData(
                  true,
                  "Loaded in " +
                    shipmentCountLoadedNonUs.responseData[i]
                      .rS_CURRENT_CLM_STATE,
                  shipmentCountLoadedNonUs.responseData[i].countTotal
                )
              );
              countTotal =
                countTotal +
                shipmentCountLoadedNonUs.responseData[i].countTotal;
            }
          }
        } else {
          let shipmentCountLoadedAll = await fetchShipmentCountLoadedAll();
          if (
            shipmentCountLoadedAll.isSuccess &&
            shipmentCountLoadedAll.responseData.length > 0
          ) {
            for (
              let i = 0;
              i < shipmentCountLoadedAll.responseData.length;
              i++
            ) {
              row.push(
                createData(
                  true,
                  "Loaded in " +
                    shipmentCountLoadedAll.responseData[i].rS_CURRENT_CLM_STATE,
                  shipmentCountLoadedAll.responseData[i].countTotal
                )
              );
              countTotal =
                countTotal + shipmentCountLoadedAll.responseData[i].countTotal;
            }
          }
        }

        let shipmentDecaturCountLoaded =
          await fetchShipmentDecaturCountLoaded();
        if (
          shipmentDecaturCountLoaded.isSuccess &&
          shipmentDecaturCountLoaded.responseData.length > 0
        ) {
          if (
            shipmentDecaturCountLoaded.isSuccess &&
            shipmentDecaturCountLoaded.responseData.length > 0
          ) {
            for (
              let i = 0;
              i < shipmentDecaturCountLoaded.responseData.length;
              i++
            ) {
              row.push(
                createData(
                  true,
                  "Loaded in " +
                    shipmentDecaturCountLoaded.responseData[i]
                      .rS_CURRENT_CLM_CITY,
                  shipmentDecaturCountLoaded.responseData[i].countTotal
                )
              );
              countTotal =
                countTotal +
                shipmentDecaturCountLoaded.responseData[i].countTotal;
            }
          }
        }

        let shipmentCountLoadedCarrier =
          await fetchShipmentCountLoadedCarrier();
        if (
          shipmentCountLoadedCarrier.isSuccess &&
          shipmentCountLoadedCarrier.responseData.length > 0
        ) {
          for (
            let i = 0;
            i < shipmentCountLoadedCarrier.responseData.length;
            i++
          ) {
            row.push(
              createData(
                true,
                "Loaded at " +
                  shipmentCountLoadedCarrier.responseData[i].locationCity +
                  " (" +
                  shipmentCountLoadedCarrier.responseData[i]
                    .rS_CURRENT_CLM_CARRIER +
                  ") ",
                shipmentCountLoadedCarrier.responseData[i].countTotal
              )
            );
            countTotal =
              countTotal +
              shipmentCountLoadedCarrier.responseData[i].countTotal;
          }
          grandTotal = grandTotal + countTotal;
          setGrandTotal(grandTotal);
          row.push(createData(false, "Total Loaded For CLINTON :", countTotal));
        }
        setShipmentCount(row);
      } else {
        if (chkNon === true) {
          let shipmentCountLoadedNonUs = await fetchShipmentCountLoadedNonUs();
          if (
            shipmentCountLoadedNonUs.isSuccess &&
            shipmentCountLoadedNonUs.responseData.length > 0
          ) {
            for (
              let i = 0;
              i < shipmentCountLoadedNonUs.responseData.length;
              i++
            ) {
              row.push(
                createData(
                  true,
                  "Loaded in " +
                    shipmentCountLoadedNonUs.responseData[i]
                      .rS_CURRENT_CLM_STATE,
                  shipmentCountLoadedNonUs.responseData[i].countTotal
                )
              );
              countTotal =
                countTotal +
                shipmentCountLoadedNonUs.responseData[i].countTotal;
            }
          }
        } else {
          let shipmentCountLoadedAll = await fetchShipmentCountLoadedAll();
          if (
            shipmentCountLoadedAll.isSuccess &&
            shipmentCountLoadedAll.responseData.length > 0
          ) {
            for (
              let i = 0;
              i < shipmentCountLoadedAll.responseData.length;
              i++
            ) {
              row.push(
                createData(
                  true,
                  "Loaded in " +
                    shipmentCountLoadedAll.responseData[i].rS_CURRENT_CLM_STATE,
                  shipmentCountLoadedAll.responseData[i].countTotal
                )
              );
              countTotal =
                countTotal + shipmentCountLoadedAll.responseData[i].countTotal;
            }
          }
        }

        let shipmentDecaturCountLoaded =
          await fetchShipmentDecaturCountLoaded();
        if (
          shipmentDecaturCountLoaded.isSuccess &&
          shipmentDecaturCountLoaded.responseData.length > 0
        ) {
          if (
            shipmentDecaturCountLoaded.isSuccess &&
            shipmentDecaturCountLoaded.responseData.length > 0
          ) {
            for (
              let i = 0;
              i < shipmentDecaturCountLoaded.responseData.length;
              i++
            ) {
              row.push(
                createData(
                  true,
                  "Loaded in " +
                    shipmentDecaturCountLoaded.responseData[i]
                      .rS_CURRENT_CLM_CITY,
                  shipmentDecaturCountLoaded.responseData[i].countTotal
                )
              );
              countTotal =
                countTotal +
                shipmentDecaturCountLoaded.responseData[i].countTotal;
            }
          }
        }

        let shipmentCountLoadedCarrier =
          await fetchShipmentCountLoadedCarrier();
        if (
          shipmentCountLoadedCarrier.isSuccess &&
          shipmentCountLoadedCarrier.responseData.length > 0
        ) {
          for (
            let i = 0;
            i < shipmentCountLoadedCarrier.responseData.length;
            i++
          ) {
            row.push(
              createData(
                true,
                "Loaded at " +
                  shipmentCountLoadedCarrier.responseData[i].locationCity +
                  " (" +
                  shipmentCountLoadedCarrier.responseData[i]
                    .rS_CURRENT_CLM_CARRIER +
                  ") ",
                shipmentCountLoadedCarrier.responseData[i].countTotal
              )
            );
            countTotal =
              countTotal +
              shipmentCountLoadedCarrier.responseData[i].countTotal;
          }
          grandTotal = grandTotal + countTotal;
          setGrandTotal(grandTotal);
          row.push(createData(false, "Total Loaded For CLINTON :", countTotal));
        }
        setShipmentCount(row);
      }
    }

    if (filterBandState.selectedLocationNo !== "") {
      if (productId === "") {
        let shipmentCountEmpty = await fetchShipmentCountEmpty();
        if (
          shipmentCountEmpty.isSuccess &&
          shipmentCountEmpty.responseData.length > 0
        ) {
          for (let i = 0; i < shipmentCountEmpty.responseData.length; i++) {
            rowEmpty.push(
              createDataForEmpty(
                true,
                "Empties in " + shipmentCountEmpty.responseData[i].state,
                shipmentCountEmpty.responseData[i].countTotal
              )
            );
            countEmptyTotal =
              countEmptyTotal + shipmentCountEmpty.responseData[i].countTotal;
          }
        }

        if (shipmentCountEmpty.responseData.length > 0) {
          let shipmentCountDecaturEmpty =
            await fetchShipmentCountDecaturEmpty();
          if (
            shipmentCountDecaturEmpty.isSuccess &&
            shipmentCountDecaturEmpty.responseData.length > 0
          ) {
            for (
              let i = 0;
              i < shipmentCountDecaturEmpty.responseData.length;
              i++
            ) {
              rowEmpty.push(
                createDataForEmpty(
                  true,
                  "Empties in " +
                    shipmentCountDecaturEmpty.responseData[i].city,
                  shipmentCountDecaturEmpty.responseData[i].countTotal
                )
              );
              countEmptyTotal =
                countEmptyTotal +
                shipmentCountDecaturEmpty.responseData[i].countTotal;
            }
          }

          let shipmentCountEmptyAtShop = await fetchShipmentCountEmptyAtShop();
          if (
            shipmentCountEmptyAtShop.isSuccess &&
            shipmentCountEmptyAtShop.responseData.length > 0
          ) {
            rowEmpty.push(
              createDataForEmpty(
                true,
                "At Shop in " + shipmentCountEmptyAtShop.responseData[0].city,
                shipmentCountEmptyAtShop.responseData[0].countTotal
              )
            );
            countEmptyTotal =
              countEmptyTotal +
              shipmentCountEmptyAtShop.responseData[0].countTotal;
          }
          if (productId !== "") {
            grandTotal = grandTotal + countEmptyTotal;
            setGrandTotal(grandTotal);
          }
          rowEmpty.push(
            createDataForEmpty(false, "Empty Total :", countEmptyTotal)
          );
        }
        setShipmentCountEmpty(rowEmpty);
      } else {
        let shipmentCountEmpty = await fetchShipmentCountEmpty();
        if (
          shipmentCountEmpty.isSuccess &&
          shipmentCountEmpty.responseData.length > 0
        ) {
          for (let i = 0; i < shipmentCountEmpty.responseData.length; i++) {
            rowEmpty.push(
              createDataForEmpty(
                true,
                "Empties in " + shipmentCountEmpty.responseData[i].state,
                shipmentCountEmpty.responseData[i].countTotal
              )
            );
            countEmptyTotal =
              countEmptyTotal + shipmentCountEmpty.responseData[i].countTotal;
          }
        }

        if (shipmentCountEmpty.responseData.length > 0) {
          let shipmentCountDecaturEmpty =
            await fetchShipmentCountDecaturEmpty();
          if (
            shipmentCountDecaturEmpty.isSuccess &&
            shipmentCountDecaturEmpty.responseData.length > 0
          ) {
            for (
              let i = 0;
              i < shipmentCountDecaturEmpty.responseData.length;
              i++
            ) {
              rowEmpty.push(
                createDataForEmpty(
                  true,
                  "Empties in " +
                    shipmentCountDecaturEmpty.responseData[i].city,
                  shipmentCountDecaturEmpty.responseData[i].countTotal
                )
              );
              countEmptyTotal =
                countEmptyTotal +
                shipmentCountDecaturEmpty.responseData[i].countTotal;
            }
          }

          let shipmentCountEmptyAtShop = await fetchShipmentCountEmptyAtShop();
          if (
            shipmentCountEmptyAtShop.isSuccess &&
            shipmentCountEmptyAtShop.responseData.length > 0
          ) {
            // for (let i = 0; i < shipmentCountEmptyAtShop.responseData.length; i++) {
            rowEmpty.push(
              createDataForEmpty(
                true,
                "At Shop in " + shipmentCountEmptyAtShop.responseData[0].city,
                shipmentCountEmptyAtShop.responseData[0].countTotal
              )
            );
            countEmptyTotal =
              countEmptyTotal +
              shipmentCountEmptyAtShop.responseData[0].countTotal;
            // }
          }

          if (productId !== "") {
            grandTotal = grandTotal + countEmptyTotal;
            setGrandTotal(grandTotal);
          }
          rowEmpty.push(
            createDataForEmpty(false, "Empty Total :", countEmptyTotal)
          );
        }
        setShipmentCountEmpty(rowEmpty);
      }
    } else {
      if (productId === "") {
        let shipmentCountEmpty = await fetchShipmentCountEmpty();
        if (
          shipmentCountEmpty.isSuccess &&
          shipmentCountEmpty.responseData.length > 0
        ) {
          for (let i = 0; i < shipmentCountEmpty.responseData.length; i++) {
            rowEmpty.push(
              createDataForEmpty(
                true,
                "Empties in " + shipmentCountEmpty.responseData[i].state,
                shipmentCountEmpty.responseData[i].countTotal
              )
            );
            countEmptyTotal =
              countEmptyTotal + shipmentCountEmpty.responseData[i].countTotal;
          }
        }

        let shipmentCountDecaturEmpty = await fetchShipmentCountDecaturEmpty();
        if (
          shipmentCountDecaturEmpty.isSuccess &&
          shipmentCountDecaturEmpty.responseData.length > 0
        ) {
          for (
            let i = 0;
            i < shipmentCountDecaturEmpty.responseData.length;
            i++
          ) {
            rowEmpty.push(
              createDataForEmpty(
                true,
                "Empties in " + shipmentCountDecaturEmpty.responseData[i].city,
                shipmentCountDecaturEmpty.responseData[i].countTotal
              )
            );
            countEmptyTotal =
              countEmptyTotal +
              shipmentCountDecaturEmpty.responseData[i].countTotal;
          }
        }

        let shipmentCountEmptyAtShop = await fetchShipmentCountEmptyAtShop();
        if (
          shipmentCountEmptyAtShop.isSuccess &&
          shipmentCountEmptyAtShop.responseData.length > 0
        ) {
          rowEmpty.push(
            createDataForEmpty(
              true,
              "At Shop in " + shipmentCountEmptyAtShop.responseData[0].city,
              shipmentCountEmptyAtShop.responseData[0].countTotal
            )
          );
          countEmptyTotal =
            countEmptyTotal +
            shipmentCountEmptyAtShop.responseData[0].countTotal;
        }
        if (productId !== "") {
          grandTotal = grandTotal + countEmptyTotal;
          setGrandTotal(grandTotal);
        }
        rowEmpty.push(
          createDataForEmpty(false, "Empty Total :", countEmptyTotal)
        );
        setShipmentCountEmpty(rowEmpty);
      } else {
        let shipmentCountEmpty = await fetchShipmentCountEmpty();
        if (
          shipmentCountEmpty.isSuccess &&
          shipmentCountEmpty.responseData.length > 0
        ) {
          for (let i = 0; i < shipmentCountEmpty.responseData.length; i++) {
            rowEmpty.push(
              createDataForEmpty(
                true,
                "Empties in " + shipmentCountEmpty.responseData[i].state,
                shipmentCountEmpty.responseData[i].countTotal
              )
            );
            countEmptyTotal =
              countEmptyTotal + shipmentCountEmpty.responseData[i].countTotal;
          }
        }

        let shipmentCountDecaturEmpty = await fetchShipmentCountDecaturEmpty();
        if (
          shipmentCountDecaturEmpty.isSuccess &&
          shipmentCountDecaturEmpty.responseData.length > 0
        ) {
          for (
            let i = 0;
            i < shipmentCountDecaturEmpty.responseData.length;
            i++
          ) {
            rowEmpty.push(
              createDataForEmpty(
                true,
                "Empties in " + shipmentCountDecaturEmpty.responseData[i].city,
                shipmentCountDecaturEmpty.responseData[i].countTotal
              )
            );
            countEmptyTotal =
              countEmptyTotal +
              shipmentCountDecaturEmpty.responseData[i].countTotal;
          }
        }

        let shipmentCountEmptyAtShop = await fetchShipmentCountEmptyAtShop();
        if (
          shipmentCountEmptyAtShop.isSuccess &&
          shipmentCountEmptyAtShop.responseData.length > 0
        ) {
          // for (let i = 0; i < shipmentCountEmptyAtShop.responseData.length; i++) {
          rowEmpty.push(
            createDataForEmpty(
              true,
              "At Shop in " + shipmentCountEmptyAtShop.responseData[0].city,
              shipmentCountEmptyAtShop.responseData[0].countTotal
            )
          );
          countEmptyTotal =
            countEmptyTotal +
            shipmentCountEmptyAtShop.responseData[0].countTotal;
          // }
        }

        if (productId !== "") {
          grandTotal = grandTotal + countEmptyTotal;
          setGrandTotal(grandTotal);
        }
        rowEmpty.push(
          createDataForEmpty(false, "Empty Total :", countEmptyTotal)
        );
        setShipmentCountEmpty(rowEmpty);
      }
    }

    if (filterBandState.selectedLocationNo !== "") {
      let shipmentCountLoadedForAll = await fetchShipmentCountLoadedForAll();
      if (
        shipmentCountLoadedForAll.isSuccess &&
        shipmentCountLoadedForAll.responseData.length > 0
      ) {
        for (
          let i = 0;
          i < shipmentCountLoadedForAll.responseData.length;
          i++
        ) {
          rowAll.push(
            createDataForAll(
              true,
              "Loaded For " + shipmentCountLoadedForAll.responseData[i].city,
              shipmentCountLoadedForAll.responseData[i].countTotal
            )
          );
          countAllTotal =
            countAllTotal +
            shipmentCountLoadedForAll.responseData[i].countTotal;
        }
      }

      if (filterBandState.selectedCustomerNo === "Z00AD5") {
        let shipmentCountLoadedForAllOL =
          await fetchShipmentCountLoadedForAllOL();
        if (
          shipmentCountLoadedForAllOL.isSuccess &&
          shipmentCountLoadedForAllOL.responseData.length > 0
        ) {
          for (
            let i = 0;
            i < shipmentCountLoadedForAllOL.responseData.length;
            i++
          ) {
            rowAll.push(
              createDataForAll(
                true,
                "Loaded For " +
                  shipmentCountLoadedForAllOL.responseData[i]
                    .rS_LOAD_EMPTY_STATUS,
                shipmentCountLoadedForAllOL.responseData[i].countTotal
              )
            );
            countAllTotal =
              countAllTotal +
              shipmentCountLoadedForAllOL.responseData[i].countTotal;
          }
        }
      }
      grandTotal = grandTotal + countAllTotal;
      setGrandTotal(grandTotal);
      if (countAllTotal > 0) {
        rowAll.push(
          createDataForAll(false, "Loaded for Total :", countAllTotal)
        );
      }
      setShipmentCountForAll(rowAll);
    } else {
      let shipmentCountLoadedForAllCustomer =
        await fetchShipmentCountLoadedForAllCustomer();
      if (
        shipmentCountLoadedForAllCustomer.isSuccess &&
        shipmentCountLoadedForAllCustomer.responseData.length > 0
      ) {
        for (
          let i = 0;
          i < shipmentCountLoadedForAllCustomer.responseData.length;
          i++
        ) {
          rowAll.push(
            createDataForAll(
              true,
              "Loaded For " +
                shipmentCountLoadedForAllCustomer.responseData[i].city,
              shipmentCountLoadedForAllCustomer.responseData[i].countTotal
            )
          );
          countAllTotal =
            countAllTotal +
            shipmentCountLoadedForAllCustomer.responseData[i].countTotal;
        }
      }

      if (filterBandState.selectedCustomerNo === "Z00AD5") {
        let shipmentCountLoadedForAllOL =
          await fetchShipmentCountLoadedForAllOL();
        if (
          shipmentCountLoadedForAllOL.isSuccess &&
          shipmentCountLoadedForAllOL.responseData.length > 0
        ) {
          for (
            let i = 0;
            i < shipmentCountLoadedForAllOL.responseData.length;
            i++
          ) {
            rowAll.push(
              createDataForAll(
                true,
                "Loaded For " +
                  shipmentCountLoadedForAllOL.responseData[i]
                    .rS_LOAD_EMPTY_STATUS,
                shipmentCountLoadedForAllOL.responseData[i].countTotal
              )
            );
            countAllTotal =
              countAllTotal +
              shipmentCountLoadedForAllOL.responseData[i].countTotal;
          }
        }
      }
      grandTotal = grandTotal + countAllTotal;
      setGrandTotal(grandTotal);
      if (countAllTotal > 0) {
        rowAll.push(
          createDataForAll(false, "Loaded for Total :", countAllTotal)
        );
      }
      setShipmentCountForAll(rowAll);
    }
  };

  // props
  const ITEM_HEIGHT = 46;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5,
      },
    },
  };

  // Events
  const handleProductChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const productCode = event.target.value === "All" ? "" : event.target.value;
    setProductId(productCode);
    dispatch(
      filterBandActions.setSelectedProduct({
        productId: productCode,
      })
    );
  };

  const onExcelClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    Promise.resolve().then(() => {
      setShowModalPopup(true);
      setshowPrintInventory(true);
    });
  };

  const onModalClose = (e: React.MouseEvent<HTMLButtonElement>) => {
    Promise.resolve().then(() => {
      setShowModalPopup(false);
    });
  };

  return (
    <React.Fragment>
      <PageHeader
        title="In-Transit Product Summary"
        subtitle="Select the Location Name to view the location summary. Product Inventories shows the current inventory for all the locations summation by product."
      ></PageHeader>
      {
        <Card
          sx={{ width: "100hv", borderRadius: 3, margin: 2, mt: 0 }}
          elevation={6}
        >
          <CardContent sx={{ pb: 0 }}>
            <Grid container columnSpacing={{ xs: 1, sm: 1, md: 4, lg: 4 }}>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Grid container>
                  <Grid item xs={2} sm={2} md={4} lg={4}></Grid>
                  <Grid item xs={8} sm={8} md={4} lg={4}>
                    <TextField
                      id="Product"
                      name="Product"
                      select
                      label="Product"
                      onChange={handleProductChange}
                      fullWidth
                      inputProps={{ MenuProps }}
                      value={productId !== "" ? productId : "All"}
                    >
                      <MenuItem value="All">Please Select a Product</MenuItem>
                      {productList.map((row, index) => {
                        return (
                          row.vpL_RAIL_USER === true && (
                            <MenuItem
                              key={index + row.vP_PRODUCT_ID}
                              value={row.vP_PRODUCT_CODE}
                            >
                              {row.vP_PRODUCT_DESC}
                            </MenuItem>
                          )
                        );
                      })}
                    </TextField>
                  </Grid>
                  <Grid item xs={2} sm={2} md={4} lg={4}></Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <ProductInventoriesTable />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Card
                  sx={{ width: "100hv", borderRadius: 3, margin: 1 }}
                  elevation={6}
                >
                  <Grid
                    container
                    columnSpacing={{ xs: 1, sm: 1, md: 4, lg: 4 }}
                    sx={{ mt: 1 }}
                  >
                    <Grid item xs={4} sm={4} md={4} lg={4}></Grid>
                    <Grid
                      item
                      xs={4}
                      sm={4}
                      md={4}
                      lg={4}
                      sx={{ textAlign: "center" }}
                    >
                      <Grid>
                        <Button
                          onClick={onExcelClick}
                          startIcon={<PrintIcon />}
                        >
                          Print
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                  <CardContent sx={{ pb: 0 }}>
                    {filterBandState.selectedLocationNo !== "" ? (
                      <Tbl>
                        <TableBody>
                          {shipmentCount &&
                            shipmentCount.map((row: any, index: any) =>
                              row.IsRow === true ? (
                                <StyledTableRow key={row.countTotal}>
                                  <StyledTableCell
                                    style={{ width: "500px" }}
                                    component="th"
                                    scope="row"
                                  >
                                    {row.State}
                                  </StyledTableCell>
                                  <StyledTableCell component="th" scope="row">
                                    {row.CountTotal}
                                  </StyledTableCell>
                                </StyledTableRow>
                              ) : (
                                <StyledTableRow
                                  style={{ backgroundColor: "#5c555557" }}
                                  key={row.countTotal}
                                >
                                  <StyledTableCell
                                    style={{ width: "500px" }}
                                    component="th"
                                    scope="row"
                                  >
                                    {row.State}
                                  </StyledTableCell>
                                  <StyledTableCell component="th" scope="row">
                                    {row.CountTotal}
                                  </StyledTableCell>
                                </StyledTableRow>
                              )
                            )}
                        </TableBody>
                      </Tbl>
                    ) : (
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Card
                          sx={{ width: "100hv", margin: 0.5 }}
                          elevation={6}
                        >
                          <CardContent>
                            <Grid
                              container
                              columnSpacing={{ xs: 1, sm: 1, md: 4, lg: 4 }}
                            >
                              <Grid item xs={12} sm={12} md={6} lg={6}>
                                PLEASE SELECT A LOCATION
                              </Grid>
                            </Grid>
                          </CardContent>
                        </Card>
                      </Grid>
                    )}
                    {productId !== "" ? (
                      <TblEmpty>
                        <TableBody>
                          {shipmentCountEmpty &&
                            shipmentCountEmpty.map((row: any, index: any) =>
                              row.IsRow === true ? (
                                <StyledTableRow key={row.countTotal}>
                                  <StyledTableCell
                                    style={{ width: "500px" }}
                                    component="th"
                                    scope="row"
                                  >
                                    {row.State}
                                  </StyledTableCell>
                                  <StyledTableCell component="th" scope="row">
                                    {row.CountTotal}
                                  </StyledTableCell>
                                </StyledTableRow>
                              ) : (
                                <StyledTableRow
                                  style={{ backgroundColor: "#5c555557" }}
                                  key={row.countTotal}
                                >
                                  <StyledTableCell
                                    style={{ width: "500px" }}
                                    component="th"
                                    scope="row"
                                  >
                                    {row.State}
                                  </StyledTableCell>
                                  <StyledTableCell component="th" scope="row">
                                    {row.CountTotal}
                                  </StyledTableCell>
                                </StyledTableRow>
                              )
                            )}
                        </TableBody>
                      </TblEmpty>
                    ) : (
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Card
                          sx={{ width: "100hv", margin: 0.5 }}
                          elevation={6}
                        >
                          <CardContent>
                            <Grid
                              container
                              columnSpacing={{ xs: 1, sm: 1, md: 4, lg: 4 }}
                            >
                              <Grid item xs={12} sm={12} md={6} lg={6}>
                                PLEASE SELECT A PRODUCT
                              </Grid>
                            </Grid>
                          </CardContent>
                        </Card>
                      </Grid>
                    )}
                    {shipmentCountForAll.length > 0 ? (
                      <TblForAll>
                        <TableBody>
                          {shipmentCountForAll &&
                            shipmentCountForAll.map((row: any, index: any) =>
                              row.IsRow === true ? (
                                <StyledTableRow key={row.countTotal}>
                                  <StyledTableCell
                                    style={{ width: "500px" }}
                                    component="th"
                                    scope="row"
                                  >
                                    {row.State}
                                  </StyledTableCell>
                                  <StyledTableCell component="th" scope="row">
                                    {row.CountTotal}
                                  </StyledTableCell>
                                </StyledTableRow>
                              ) : (
                                <StyledTableRow
                                  style={{ backgroundColor: "#5c555557" }}
                                  key={row.countTotal}
                                >
                                  <StyledTableCell
                                    style={{ width: "500px" }}
                                    component="th"
                                    scope="row"
                                  >
                                    {row.State}
                                  </StyledTableCell>
                                  <StyledTableCell component="th" scope="row">
                                    {row.CountTotal}
                                  </StyledTableCell>
                                </StyledTableRow>
                              )
                            )}
                        </TableBody>
                      </TblForAll>
                    ) : (
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Card
                          sx={{ width: "100hv", margin: 0.5 }}
                          elevation={6}
                        >
                          <CardContent>
                            <Grid
                              container
                              columnSpacing={{ xs: 1, sm: 1, md: 4, lg: 4 }}
                            >
                              <Grid item xs={12} sm={12} md={6} lg={6}>
                                NO DATA FOUND
                              </Grid>
                            </Grid>
                          </CardContent>
                        </Card>
                      </Grid>
                    )}
                    <Grid item xs={12} sm={12} md={12}>
                      <Card
                        sx={{
                          backgroundColor: "yellow",
                          width: "100hv",
                          margin: 0.5,
                        }}
                        elevation={6}
                      >
                        <CardContent>
                          <Grid
                            container
                            columnSpacing={{ xs: 1, sm: 1, md: 4, lg: 4 }}
                          >
                            <Grid item xs={6} sm={6} md={6} lg={6}>
                              GRAND TOTAL # OF CARS: &nbsp; {grandTotal}
                            </Grid>
                          </Grid>
                        </CardContent>
                      </Card>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      }
      <ModalPopup
        open={showModalPopup}
        title={locationName + " " + productName + " Inventory"}
        onPopupClose={onModalClose}
        width="1200px"
      >
        <PrintInventory
          productId={productId}
          setShowModalPopup={setShowModalPopup}
          showPrintInventory={showPrintInventory}
        />
      </ModalPopup>
    </React.Fragment>
  );
}

export default IntransitProductInventory;
