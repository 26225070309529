import { environment } from "./env";
import { MsalAuthProvider, LoginType } from "react-aad-msal";

const configAuth = {
  auth: {
    authority: environment.REACT_APP_OIS_AUTH_AUTHORITY,
    clientId: environment.REACT_APP_B2C_CLIENT_ID,
    redirectUri: environment.REACT_APP_B2C_REDIRECT_URI,
    validateAuthority: false,
    postLogoutRedirectUri: environment.REACT_APP_B2C_REDIRECT_URI,
  },
  cache: {
    CacheStorage: 'sessionStorage',
    storeAuthStateInCookie: false
  }
};

export const loginRequest = {
  scopes: [environment.REACT_APP_B2C_SCOPE],
  forceRefresh: false
};

const options = {
  loginType: LoginType.Redirect,
  tokenRefreshUri: window.location.origin
}

function authErrorHandler(error:any) {
  if (error && error?.errorMessage?.indexOf('AADB2C90118') !== -1) {
      authProvider.setAuthenticationParameters({
          authority: process.env.REACT_APP_OIS_RESET_AUTHORITY,
      })
      authProvider.login();
    }
  
    if (error && error?.errorMessage?.indexOf('AADB2C99002') !== -1) {
      window.sessionStorage.clear();
      authProvider.setAuthenticationParameters({
          authority: process.env.REACT_APP_OIS_AUTH_AUTHORITY,
      })
      authProvider.login();
    }
}

export const authProvider = new MsalAuthProvider(configAuth, loginRequest, options)
authProvider.registerErrorHandler(authErrorHandler)