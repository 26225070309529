/* eslint-disable react-hooks/exhaustive-deps */
import { Grid } from "@mui/material";
import CustomerLocationList from "../Location/CustomerLocationList";
import CustomerProductInventories from "../Product/CustomerProductInventories";
import CustomerInfo from "./CustomerInfo";
import { IFilterBandState } from "../../../interfaces/storeInterfaces";
import { filterBandSelector } from "../../../store/store";
import { useSelector } from "react-redux";
import { uiActions } from "../../../store/ui-slice";
import { useEffect } from "react";
import { useAppDispatch } from "../../../store/store";
import PageHeader from "../../shared/PageHeader";
import { filterBandActions } from "../../../store/filterband-slice";

function CustomerInfoSummary() {
  const filterBandState: IFilterBandState = useSelector(filterBandSelector);
  const dispatch = useAppDispatch();

  dispatch(filterBandActions.removeSelectedLocation());

  useEffect(() => {
    if (filterBandState.selectedCustomerNo)
      dispatch(uiActions.startProgressBarWithTotalStepCount(2));
  }, [filterBandState.selectedCustomerNo]);

  return (
    <div>
      <PageHeader
        title="Customer Summary"
        subtitle="Click on the Location Name to view the location summary. Product Inventories shows the current inventory for all the locations summation by product."
      ></PageHeader>
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 1, md: 1 }}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <CustomerInfo
            customerNo={filterBandState.selectedCustomerNo}
          ></CustomerInfo>
        </Grid>
        <Grid item xs={12} sm={12} md={7} lg={7}>
          <CustomerProductInventories
            customerNo={filterBandState.selectedCustomerNo}
          ></CustomerProductInventories>
        </Grid>
        <Grid item xs={12} sm={12} md={5} lg={5}>
          <CustomerLocationList
            customerNo={filterBandState.selectedCustomerNo}
          ></CustomerLocationList>
        </Grid>
      </Grid>
    </div>
  );
}
export default CustomerInfoSummary;
