
import CustomerInfoSummary from "../components/app/Customer/CustomerInfoSummary";

const CustomerSummaryPage = () => {

    return (
        <CustomerInfoSummary></CustomerInfoSummary>
    );
};
  
  export default CustomerSummaryPage;
  