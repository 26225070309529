import Axios from "axios";
import { environment } from "./env";

const apiURL = environment.apiBaseUrl + "/" + environment.apiVersion;
export const axios = Axios.create({
  baseURL: `${apiURL}`,
  headers: {
    'Access-Control-Allow-Origin': environment.REACT_APP_B2C_REDIRECT_URI,
    'Accept': 'application/json, text/plain, */*',
    'Content-Type': 'application/json',
    'Ocp-Apim-Subscription-Key': environment.REACT_APP_APIM_SUB_KEY
  },
});
