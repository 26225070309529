import React, { useRef } from "react";
import { useReactToPrint } from "react-to-print";
import { TableHeaderCell } from "../../../models/common";
import { Button, Grid } from "@mui/material";
import PrintIcon from "@mui/icons-material/Print";
import { IntransitCars } from "../../../models/shipment";
import moment from "moment";

interface Props {
  intransitCars: IntransitCars[];
  tableHeader: TableHeaderCell[];
}

function IntransitProductSummaryPrint(props: React.PropsWithChildren<Props>) {
  const componentRef = useRef(null);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  function loadCurrentDate() {
    let datetime = new Date().toLocaleDateString() + "," + new Date().toLocaleTimeString();
    return datetime;
  }

  const tableStyle = {
    div: {
      padding: "10px",
      paddingLeft: "30px",
      paddingRight: "30px",
    },
    table: {
      border: "1px solid black",
      "border-collapse": "collapse",
      width: "100%",
    },
    tdata: {
      border: "1px solid black",
      "border-collapse": "collapse",
      paddingLeft: "5px",
    },
  };

  return (
    <React.Fragment>
      <div>
        <Button onClick={handlePrint} startIcon={<PrintIcon />} sx={{ mr: 2 }}>
          Print
        </Button>
      </div>
      <div id="printTable" ref={componentRef} style={tableStyle.div}>
        <Grid container spacing={3} sx={{ paddingTop: 1 }}>
          <Grid item xs={5} sm={5} md={5} lg={5}>{loadCurrentDate()}</Grid>
          <Grid item xs={7} sm={7} md={7} lg={7} style={{ textAlign: "left", marginBottom: "10px", fontWeight: "bold", fontSize: "18px" }}><span >In-Transit Schedule</span></Grid>
        </Grid>
        <table style={tableStyle.table}>
          <thead>
            <tr>
              {props.tableHeader.map((header, index) => (
                <th style={tableStyle.tdata}>{header.label}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {props.intransitCars.map((row, index) => (
              <tr key={index}>
                <td style={tableStyle.tdata}>{row.rS_CAR_NUMBER}</td>
                <td style={tableStyle.tdata}>
                  {row.formatted_rS_SHIPPED_DATE}
                </td>
                <td style={tableStyle.tdata}>{row.rS_LOAD_EMPTY_STATUS}</td>
                <td style={tableStyle.tdata}>{row.origin}</td>
                <td style={tableStyle.tdata}>{row.destination}</td>
                <td style={tableStyle.tdata}></td>
                <td style={tableStyle.tdata}></td>
                <td style={tableStyle.tdata}>{row.clm}</td>
                <td style={tableStyle.tdata}>
                  {row.formatted_CURRENT_CLM_DATE !== null
                    ? moment(row.formatted_CURRENT_CLM_DATE).format(
                      "MMM DD YYYY h:mm A"
                    )
                    : row.formatted_CURRENT_CLM_DATE}
                </td>
                <td style={tableStyle.tdata}></td>
                <td style={tableStyle.tdata}>{row.idlE_DAYS}</td>
                <td style={tableStyle.tdata}>{row.formatted_NET_WEIGHT}</td>
                <td style={tableStyle.tdata}>{row.rS_PRODUCT_CODE}</td>
                <td style={tableStyle.tdata}>{row.rS_PERSONNEL_CODE}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </React.Fragment>
  );
}

export default IntransitProductSummaryPrint;
