import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardContent,
  Container,
  Grid,
  Table,
  TableBody,
  TableHead,
} from "@mui/material";
import { uiActions } from "../../../store/ui-slice";
import ShipmentService from "../../../services/shipmentService";
import OrderService from "../../../services/orderService";
import { filterBandSelector, useAppDispatch } from "../../../store/store";
import { IFilterBandState } from "../../../interfaces/storeInterfaces";
import { useSelector } from "react-redux";
import { StyledTableCell, StyledTableRow } from "../../controls/tablecontrols";
import PageHeader from "../../shared/PageHeader";
import { RailcarInventories } from "../../../models/shipment";
import CustomCardHeader from "../../shared/CustomCardHeader";
import { FormatNumber } from "../../shared/CommonFunctions";
import moment from "moment";
import { RailcarOrders } from "../../../models/order";
import ModalPopup from "../../shared/ModalPopup";
import RailcarInventoryHistory from "./RailcarInventoryHistory";

function RailcarInventory() {
  const dispatch = useAppDispatch();
  const [railCarInventory, setRailCarInventory] = useState<
    RailcarInventories[]
  >([]);
  const [showRailcarHistoryModalPopup, setShowRailcarHistoryModalPopup] =
    useState<boolean>(false);

  const filterBandState: IFilterBandState = useSelector(filterBandSelector);
  const { fetchData: fetchRailcarInventory } =
    ShipmentService.RetrieveRailCarInventory(
      filterBandState.selectedLocationNo
    );

  //---Railcar History--
  const clickRailcarHistory = () => {
    Promise.resolve().then(() => {
      setShowRailcarHistoryModalPopup(true);
    });
  };
  const onRailcarModalClose = (e: React.MouseEvent<HTMLButtonElement>) => {
    Promise.resolve().then(() => {
      setShowRailcarHistoryModalPopup(false);
    });
  };
  useEffect(() => {
    if (
      filterBandState.selectedCustomerNo !== "" &&
      filterBandState.selectedCustomerNo !== undefined
    ) {
      loadBatchDetails();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadBatchDetails = () => {
    dispatch(uiActions.startProgressBar());
    fetchRailcarInventory().then((res) => {
      setRailCarInventory(res.responseData);
    });
  };

  return (
    <>
      <PageHeader
        title="Railcar Inventory"
        subtitle="This report only lists railcars that are currently in RL status."
      ></PageHeader>
      <Card
        sx={{ width: "100hv", borderRadius: 3, margin: 2, mt: 0 }}
        elevation={6}
      >
        <CustomCardHeader
          title="Inventory Details"
          action={
            <>
              <Button
                variant="text"
                sx={{ fontSize: "0.90rem" }}
                onClick={(e) => clickRailcarHistory()}
              >
                <u>Show History</u>
              </Button>
            </>
          }
        ></CustomCardHeader>
        <CardContent sx={{ pb: 0 }}>
          <React.Fragment>
            <Container>
              <Grid container>
                {/* <Grid item xs={12} sm={12} md={12} lg={12}> */}
                {railCarInventory.length > 0 ? (
                  railCarInventory.map((row, index) => (
                    <>
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Card
                          sx={{
                            width: "100%",
                            borderRadius: 3,
                            margin: 2,
                            mt: 0,
                          }}
                          elevation={6}
                        >
                          <CardContent>
                            <Table>
                              <TableHead>
                                <StyledTableRow>
                                  <StyledTableCell>
                                    {"Car Number:"} {row.rS_CAR_NUMBER}
                                  </StyledTableCell>
                                  <StyledTableCell>
                                    {"Product Desc:"}{" "}
                                    {row.rS_PRODUCT_DESCRIPTION}
                                  </StyledTableCell>
                                  <StyledTableCell>
                                    {"Received Loaded Weight: "}
                                    {FormatNumber(row.rS_NET_WEIGHT, 0)}
                                  </StyledTableCell>
                                </StyledTableRow>
                              </TableHead>
                              <TableBody>
                                <StyledTableRow>
                                  <StyledTableCell
                                    style={{ fontWeight: "bold" }}
                                  >
                                    {"Load Date: "}
                                    {moment(row.rS_LOAD_DATE).format(
                                      "MM/DD/YYYY"
                                    )}
                                  </StyledTableCell>
                                  <StyledTableCell
                                    style={{ fontWeight: "bold" }}
                                  >
                                    {"Shipped Date: "}
                                    {moment(row.rS_SHIPPED_DATE).format(
                                      "MM/DD/YYYY"
                                    )}
                                  </StyledTableCell>
                                  <StyledTableCell
                                    style={{ fontWeight: "bold" }}
                                  >
                                    {"Received Date: "}
                                    {moment(row.rS_RECEIVED_DATE).format(
                                      "MM/DD/YYYY hh:mm:ss A"
                                    )}
                                  </StyledTableCell>
                                </StyledTableRow>
                              </TableBody>
                            </Table>
                            <RenderRailCarOrders
                              carNumber={row.rS_CAR_NUMBER}
                              receivedDate={row.rS_RECEIVED_DATE}
                              locationNo={filterBandState.selectedLocationNo}
                              nLoadedWeight={Number(row.rS_NET_WEIGHT)}
                            />
                          </CardContent>
                        </Card>
                      </Grid>
                    </>
                  ))
                ) : (
                  <Grid item xs={12} sm={12} md={12} lg={12} sx={{textAlign:"center"}}>
                    <span>
                      There are no railcar inventory available for the location
                      you selected.
                    </span>
                  </Grid>
                )}
              </Grid>
            </Container>
          </React.Fragment>
        </CardContent>
      </Card>

      <ModalPopup
        open={showRailcarHistoryModalPopup}
        title={"Railcar Inventory History"}
        onPopupClose={onRailcarModalClose}
        width="1200px"
      >
        <RailcarInventoryHistory></RailcarInventoryHistory>
      </ModalPopup>
    </>
  );
}

interface renderRailCarOrderProps {
  carNumber: string;
  receivedDate: string;
  locationNo: string;
  nLoadedWeight: number;
}

function RenderRailCarOrders(props: renderRailCarOrderProps) {
  let nTotalOrderWeight = 0;
  const [ordersForRailcar, setOrdersForRailcar] = useState<RailcarOrders[]>([]);
  const { fetchData: fetchOrdersForRailcar } =
    OrderService.RetrieveOrderAgainstRailCar(
      props.carNumber,
      props.receivedDate,
      props.locationNo
    );

  useEffect(() => {
    fetchOrdersForRailcar().then((res) => {
      setOrdersForRailcar(res.responseData);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (ordersForRailcar.length > 0) {
    return (
      <Table>
        <TableBody>
          <StyledTableRow style={{ backgroundColor: "rgba(224, 224, 224, 1)" }}>
            <StyledTableCell style={{ fontWeight: "bold" }}>
              {"Order Date"}
            </StyledTableCell>
            <StyledTableCell style={{ fontWeight: "bold" }}>
              {"Order Number"}
            </StyledTableCell>
            <StyledTableCell style={{ fontWeight: "bold" }}>
              {"Invoice Number"}
            </StyledTableCell>
            <StyledTableCell style={{ fontWeight: "bold" }}>
              {"Order Weight"}
            </StyledTableCell>
          </StyledTableRow>

          {ordersForRailcar.map((row, index) => (
            <StyledTableRow>
              <StyledTableCell>
                {moment(row.vS_SHIPPED_DATE).format("MM/DD/YYYY HH:mm:ss A")}
              </StyledTableCell>
              <StyledTableCell>{row.vO_ADM_ORDER_NO}</StyledTableCell>
              <StyledTableCell>{row.vO_INVOICE_NO}</StyledTableCell>
              {row.vO_SPLIT_ORDER ? (
                <StyledTableCell>
                  <sup>{"split"}</sup>
                  {FormatNumber(Number(row.vS_SHIPMENT_AMOUNT), 0)}
                </StyledTableCell>
              ) : (
                <StyledTableCell>
                  {FormatNumber(Number(row.vS_SHIPMENT_AMOUNT), 0)}
                </StyledTableCell>
              )}
              <div style={{ display: "none" }}>
                {
                  (nTotalOrderWeight =
                    nTotalOrderWeight + Number(row.vS_SHIPMENT_AMOUNT))
                }
              </div>
            </StyledTableRow>
          ))}

          <StyledTableRow>
            <StyledTableCell
              colSpan={4}
              style={{ fontWeight: "bold" }}
              align={"right"}
            >
              {"Total Ordered Weight:"}
            </StyledTableCell>
          </StyledTableRow>
          <StyledTableRow>
            <StyledTableCell
              colSpan={4}
              style={{ fontWeight: "bold" }}
              align={"right"}
            >
              {FormatNumber(nTotalOrderWeight, 0)}
            </StyledTableCell>
          </StyledTableRow>
          <StyledTableRow>
            <StyledTableCell
              colSpan={4}
              style={{ fontWeight: "bold" }}
              align={"right"}
            >
              {"Estimated Weight Remaining:"}
            </StyledTableCell>
          </StyledTableRow>
          <StyledTableRow>
            {Number(props.nLoadedWeight) - Number(nTotalOrderWeight) < 0 ? (
              <StyledTableCell
                colSpan={4}
                style={{ fontWeight: "bold", color: "red" }}
                align={"right"}
              >
                {FormatNumber(
                  Number(props.nLoadedWeight) - Number(nTotalOrderWeight),
                  0
                )}
              </StyledTableCell>
            ) : (
              <StyledTableCell
                colSpan={4}
                style={{ fontWeight: "bold" }}
                align={"right"}
              >
                {FormatNumber(
                  Number(props.nLoadedWeight) - Number(nTotalOrderWeight),
                  0
                )}
              </StyledTableCell>
            )}
          </StyledTableRow>
        </TableBody>
      </Table>
    );
  } else {
    return (
      <>
        <Table>
          <TableBody>
            <StyledTableRow>
              <StyledTableCell colSpan={4}>
                No order has been placed against this railcar.
              </StyledTableCell>
            </StyledTableRow>
          </TableBody>
        </Table>
      </>
    );
  }
}

export default RailcarInventory;
