import React, { useEffect, useState } from "react";
import { Grid, TextField, Button, Container, Divider } from "@mui/material";
import { useFormik } from "formik";
import * as yup from "yup";
import { useAppDispatch } from "../../../store/store";
import { uiActions } from "../../../store/ui-slice";
import LocationService from "../../../services/locationService";
import { BatchSpecification } from "../../../models/location";
import ConfirmationMessage from "../../shared/ConfirmationMessage";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

interface BatchMaintenanceProps {
  setshowBatchModalPopup: React.Dispatch<React.SetStateAction<boolean>>;
  locationNo: string;
  productId: number;
  customerNo: string;
  setIsRefreshProdInv: React.Dispatch<React.SetStateAction<boolean>>;
}

function BatchMaintenance(props: BatchMaintenanceProps) {
  const dispatch = useAppDispatch();
  const [location_No, setLocationId] = useState("");
  const [productId, setProductId] = useState(0);
  const [batchspecs, setBatchSpecification] = useState<BatchSpecification>(
    LocationService.GetDefaultBatchSpecification()
  );
  const [isConfirmDelete, setIsConfirmDelete] = useState<boolean>(false);
  const [openConfirmation, setOpenConfirmation] =
    React.useState<boolean>(false);
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const [mstrKey, setMstrKey] = useState<number>(0);
  const { fetchData: fetchBatchDetails } =
    LocationService.RetrieveBatchAllSpecification(
      props.locationNo,
      props.productId,
      props.customerNo
    );

  const validationSchema = yup.object({
    vB_BATCH_NAME: yup
      .string()
      .required(
        "Validation Errors !!! \nPlease make sure you have entered all the required fields(*)  \nPlease enter a  Batch Name"
      )
      .min(1, "Please enter a valid Batch Name.")
      .max(100, "Please enter a valid Batch Name."),

    vB_CALIBRATION_DATE: yup
      .string()
      .nullable()
      .test(
        "Valid Date",
        "Please enter a valid Calibration Date",
        function (value) {
          if (value !== null) {
            const isValidDate =
              dayjs(value).isValid() &&
              1900 <= dayjs(value).year() &&
              dayjs(value).year() < 2100;
            return isValidDate;
          } else {
            return true;
          }
        }
      ),
  });

  const { putData: updateBatchSpecification } =
    LocationService.UpdateBatchSpecification(batchspecs);
  const { postData: addBatchSpecification } =
    LocationService.CreateBatchSpecification(batchspecs);
  const { deleteData: deleteBatchSpecification } =
    LocationService.DeleteBatchSpecification(mstrKey);

  useEffect(() => {
    loadBatchDetails(props.locationNo, props.productId, props.customerNo);
    setLocationId(props.locationNo);
    setProductId(props.productId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.locationNo, props.productId, props.customerNo]);

  useEffect(() => {
    if (isLoaded) {
      if (mstrKey !== 0) {
        dispatch(uiActions.startProgressBar());
        updateBatchSpecification().then((res) => {
          if (res.isSuccess) {
            dispatch(
              uiActions.displayNotification({
                message: "Batch specification updated successfully!",
                severity: "success",
              })
            );
            props.setshowBatchModalPopup(false);
            props.setIsRefreshProdInv(true);
          }
        });
      } else {
        dispatch(uiActions.startProgressBar());
        addBatchSpecification().then((res) => {
          if (res.isSuccess === true) {
            if (res.responseData.success === "Yes") {
              dispatch(
                uiActions.displayNotification({
                  message: "Batch specification added successfully!",
                  severity: "success",
                })
              );
              props.setshowBatchModalPopup(false);
              props.setIsRefreshProdInv(true);
            } else if (res.responseData.success === "No") {
              dispatch(
                uiActions.logErrorNotification({
                  message: "Batch specification already added",
                  severity: "error",
                })
              );
            }
          }
        });
      }
    } else {
      setIsLoaded(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [batchspecs]);

  useEffect(() => {
    if (isConfirmDelete) {
      dispatch(uiActions.startProgressBar());
      deleteBatchSpecification().then((res) => {
        if (res.isSuccess) {
          dispatch(
            uiActions.displayNotification({
              message: "Batch deleted successfully!",
              severity: "success",
            })
          );
          props.setshowBatchModalPopup(false);
          props.setIsRefreshProdInv(true);
        }
      });

      Promise.resolve().then(() => {
        setIsConfirmDelete(false);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isConfirmDelete]);

  const formik = useFormik({
    initialValues: LocationService.GetDefaultBatchSpecification(),
    validationSchema: validationSchema,
    onSubmit: (values: BatchSpecification) => {
      values.VB_PRODUCT_ID = productId;
      if (values.vB_CALIBRATION_DATE == null) {
        values.vB_CALIBRATION_DATE = dayjs("1900-01-01T00:00:00.000Z").format(
          "MM/DD/YYYY"
        );
      } else {
        values.vB_CALIBRATION_DATE = dayjs(values.vB_CALIBRATION_DATE).format(
          "MM/DD/YYYY"
        );
      }
      setBatchSpecification(values);
    },
  });

  const handleClose = () => {
    Promise.resolve().then(() => {
      props.setshowBatchModalPopup(false);
    });
  };

  const handleOpenDeleteConfirmation = (mstrKey: number) => {
    Promise.resolve().then(() => {
      setOpenConfirmation(true);
    });
  };
  const handleConfirmDelete = (e: React.MouseEvent<HTMLButtonElement>) => {
    Promise.resolve().then(() => {
      setOpenConfirmation(false);
      setIsConfirmDelete(true);
    });
  };

  const handleCancelDelete = (e: React.MouseEvent<HTMLButtonElement>) => {
    Promise.resolve().then(() => {
      setOpenConfirmation(false);
    });
  };

  const loadBatchDetails = (
    locationId: string,
    productId: number,
    customerNo: string
  ) => {
    if (locationId !== "" && productId !== 0 && customerNo !== "") {
      dispatch(uiActions.startProgressBar());
      fetchBatchDetails().then((res) => {
        setMstrKey(res.responseData[0].vB_KEY);
        formik.setValues(res.responseData[0]);
        formik.setFieldValue(
          "vB_CALIBRATION_DATE",
          res.responseData[0].vB_CALIBRATION_DATE !== null
            ? dayjs(res.responseData[0].vB_CALIBRATION_DATE)
            : null
        );
      });
    }
  };

  const dateChanger = (newValue: dayjs.Dayjs | null) => {
    if (newValue !== null && dayjs(newValue).isValid()) {
      formik.setFieldValue(
        "vB_CALIBRATION_DATE",
        dayjs(newValue).format("MM/DD/YYYY")
      );
    } else if (newValue === null) {
      formik.setFieldValue("vB_CALIBRATION_DATE", null);
    } else {
      formik.setFieldValue("vB_CALIBRATION_DATE", newValue);
    }
  };

  return (
    <React.Fragment>
      <Container>
        <form onSubmit={formik.handleSubmit} id="form1" className="form">
          <Grid container spacing={3} sx={{ paddingTop: 1 }}>
            <Grid item xs={6} sm={6} md={6}>
              <TextField
                name="productId"
                label="Product"
                value={productId}
                fullWidth
                disabled={true}
              />
            </Grid>
            <Grid item xs={6} sm={6} md={6}>
              <TextField
                name="location_No"
                label="Location ID"
                value={location_No}
                fullWidth
                disabled={true}
              />
            </Grid>
            <Grid item xs={6} sm={6} md={6}>
              <TextField
                name="vB_BATCH_NAME"
                label="Batch Name *"
                value={formik.values.vB_BATCH_NAME}
                fullWidth
                onChange={formik.handleChange}
                error={
                  formik.touched.vB_BATCH_NAME &&
                  Boolean(formik.errors.vB_BATCH_NAME)
                }
                helperText={
                  formik.touched.vB_BATCH_NAME && formik.errors.vB_BATCH_NAME
                }
              />
            </Grid>
            <Grid item xs={6} sm={6} md={6}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="Calibration Date"
                  value={
                    formik.values.vB_CALIBRATION_DATE !== null
                      ? dayjs(formik.values.vB_CALIBRATION_DATE)
                      : null
                  }
                  onChange={(newValue) => {
                    dateChanger(newValue);
                  }}
                  slotProps={{
                    textField: {
                      variant: "standard",
                      error:
                        formik.touched.vB_CALIBRATION_DATE &&
                        Boolean(formik.errors.vB_CALIBRATION_DATE),
                      helperText:
                        formik.touched.vB_CALIBRATION_DATE &&
                        formik.errors.vB_CALIBRATION_DATE,
                    },
                  }}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <TextField
                name="vB_URL"
                label="URL"
                value={formik.values.vB_URL}
                onChange={formik.handleChange}
                fullWidth
              />
            </Grid>

            <Grid item xs={6} sm={6} md={6}>
              <TextField
                name="vB_SETPOINT"
                label="Setpoint"
                value={formik.values.vB_SETPOINT}
                onChange={formik.handleChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={10} sm={10} md={10}>
              <TextField
                name="vB_COMMENTS"
                label="Comments"
                value={formik.values.vB_COMMENTS}
                onChange={formik.handleChange}
                fullWidth
              />
            </Grid>
          </Grid>
        </form>
      </Container>
      <Divider sx={{ mt: 4, mb: 2 }}></Divider>
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        sx={{
          justifyContent: "flex-end",
          alignItems: "flex-end",
          display: "flex",
        }}
      >
        <Button
          form="form1"
          type="submit"
          sx={{ mr: 1 }}
          onSubmit={(e) => formik.handleSubmit}
        >
          {" "}
          {mstrKey !== 0 ? "Update" : "Submit"}
        </Button>
        {formik.values.vB_KEY !== 0 && (
          <Button
            onClick={(e) => handleOpenDeleteConfirmation(mstrKey)}
            type="button"
            sx={{ mr: 1 }}
          >
            {" "}
            {"Delete"}
          </Button>
        )}

        <Button type="button" onClick={handleClose}>
          {"Close"}
        </Button>
      </Grid>
      <ConfirmationMessage
        open={openConfirmation}
        message={
          "Are you sure you want to delete this batch" +
          " ? This process cannot be undone."
        }
        onYesClick={handleConfirmDelete}
        onNoClick={handleCancelDelete}
      ></ConfirmationMessage>
    </React.Fragment>
  );
}

export default BatchMaintenance;
