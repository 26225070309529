/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import useTable from "../../../hooks/useTable";
import { Link as RouterLink } from "react-router-dom";

//Date Packages
import dayjs from "dayjs";
import { LocalizationProvider } from '@mui/x-date-pickers';
import { DatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

// Models
import { ShipmentEntryDetails } from "../../../models/shipment";
import { StyledTableCell, StyledTableRow } from "../../controls/tablecontrols";
import { TableHeaderCell } from "../../../models/common";

// Matarial
import TableBody from '@mui/material/TableBody';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import { Button, Box, Card, CardContent, FormControlLabel } from "@mui/material";
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

// Child Page
import PageHeader from "../../shared/PageHeader";
import ModalPopup from "../../shared/ModalPopup";
import ErrorPopup from "./ErrorPopup";

// Services
import ShipmentDataService from "../../../services/shipmentService";
import CustomerDataService from "../../../services/customerService";

// States
import { IFilterBandState } from "../../../interfaces/storeInterfaces";
import { useSelector } from "react-redux";
import { filterBandSelector, useAppDispatch } from "../../../store/store";
import { filterBandActions } from "../../../store/filterband-slice";
import { uiActions } from "../../../store/ui-slice";

const ShipmentEntry = () => {
  const dispatch = useAppDispatch();
  const filterBandState: IFilterBandState = useSelector(filterBandSelector);
  const [shipmentEntry, setShipmentEntry] = useState<any>([]);
  const [saveData, setSaveData] = useState<boolean>(false);
  const [showModalPopup, setShowModalPopup] = useState<boolean>(false);
  const [orderNos, setOrderNos] = useState("");
  const [isRefreshList, setRefreshList] = React.useState<boolean>(false);

  // API's
  const [shipmentInfo, setShipmentInfo] = useState<ShipmentEntryDetails>(ShipmentDataService.GetDefaultShipmentEntryDetails());
  const { postData: addShipmentData } = ShipmentDataService.AddShipmentEntry(shipmentInfo);
  const { fetchData: fetchLocationData } = CustomerDataService.GetCustomerLocations(filterBandState.selectedCustomerNo, filterBandState.selectedLocationNo);

  // Function creating table row
  function createData(
    site: number | null,
    locationName: string,
    ship: number | string | null,
    orderNo: number | null,
    deliveryDate: dayjs.Dayjs | null,
    truckWeight: number | null,
    product: number | null,
    comments: string | null,
    loadOut: string | null,
    selectedCustomerNo: string,
    locationID: number,
  ) {
    return { site, locationName, ship, orderNo, deliveryDate, truckWeight, product, comments, loadOut, selectedCustomerNo, locationID };
  }

  const tableHeaderCellDetails: TableHeaderCell[] = [
    { id: "vT_Site", label: "Site #" },
    { id: "vT_LocationName", label: "Location Name" },
    { id: "vT_ShipTo", label: "ShipTo" },
    { id: "OrderNo", label: "Order No." },
    { id: "DeliveryDate", label: "Delivery Date" },
    { id: "Weight", label: "Truck Weight (lbs)" },
    { id: "vP_PRODUCT_ID", label: "Product" },
    { id: "Comments", label: "Comments" },
    { id: "LoadOut", label: "Load Out" },
  ];
  const { Tbl } = useTable<any>(
    shipmentEntry,
    tableHeaderCellDetails,
    false,
    10
  );

  // Making the table row
  useEffect(() => {
    if (filterBandState.selectedCustomerNo !== null &&
      filterBandState.selectedCustomerNo !== undefined &&
      filterBandState.selectedCustomerNo !== '') {
      dispatch(uiActions.startProgressBarWithTotalStepCount(1));
      locationDataLoaded();
    }
  }, [filterBandState.locations])

  useEffect(() => {
    if (saveData === true) {
      dispatch(uiActions.startProgressBar());
      addShipmentData().then((res) => {
        if (res.isSuccess === true) {
          if (res.responseData.success === "No") {
            setShowModalPopup(true);
            setOrderNos(res.responseData["orderNumbers"]);
          }
          if (res.isSuccess && res.responseData.success === "Yes") {
            dispatch(
              uiActions.displayNotification({
                message: "The orders have been saved successfully",
                severity: "success",
              })
            );
            locationDataLoaded();
          }
        }
      });
    }
  }, [shipmentInfo]);

  useEffect(() => {
    setOrderNos("");
    setShowModalPopup(false);
    locationDataLoaded();
  }, [isRefreshList]);

  const locationDataLoaded = () => {
    fetchLocationData().then((res) => {
      const rows = [];
      for (let i = 0; i < res.responseData.length; i++) {
        rows.push(
          createData(res.responseData[i].siteNumber,
            res.responseData[i].locationName,
            res.responseData[i].locationNo,
            null,
            null,
            null,
            null,
            null,
            null,
            filterBandState.selectedCustomerNo,
            res.responseData[i].locationID
          ));
      }
      setShipmentEntry(rows);
    });
  }

  // Events
  const loadoutHandleChange = (event: any, rowIndex: any) => {
    shipmentEntry[rowIndex].loadOut = event.target.checked;
    setShipmentEntry(shipmentEntry);
  };

  // For Select drop down 
  const handleDataFromChildSelect = (data: any, rowIndex: any) => {
    shipmentEntry[rowIndex].product = data;
    setShipmentEntry(shipmentEntry);
  }

  // Input handler function
  const handleInputChange = (event: any, rowIndex: any, field: any) => {
    shipmentEntry[rowIndex][field] = event.target.value;
    setShipmentEntry(shipmentEntry);
  }

  // Handle Date Change
  const handleDateChangedelivery = (date: dayjs.Dayjs, rowIndex: any) => {
    shipmentEntry[rowIndex].deliveryDate = date;
    setShipmentEntry(shipmentEntry);
  }

  // Check the input is number
  function isNumber(value: any): boolean {
    return !isNaN(Number(value));
  }

  function IsNumeric(weight: any): boolean {
    if (isNumber(weight)) {
      return true;
    } else {
      return false;
    }
  }

  // HandleSaveClick
  const handleSaveClick = () => {
    let DisplayMsg: string = '';
    let strAtleastOne: number = 0;

    // Validation
    for (let i = 0; i < shipmentEntry.length; i++) {
      if (shipmentEntry[i].orderNo !== null ||
        (shipmentEntry[i].deliveryDate !== null && shipmentEntry[i].deliveryDate !== 'None') ||
        shipmentEntry[i].truckWeight !== null ||
        (shipmentEntry[i].product !== null && shipmentEntry[i].product !== '') ||
        shipmentEntry[i].comments !== null) {
        if (shipmentEntry[i].orderNo === null ||
          shipmentEntry[i].deliveryDate === null ||
          (shipmentEntry[i].deliveryDate === null && shipmentEntry[i].deliveryDate === 'None') ||
          shipmentEntry[i].truckWeight === null ||
          (shipmentEntry[i].product === null || shipmentEntry[i].product === '')) {
          DisplayMsg = DisplayMsg + "Enter all the required fields for the location " + shipmentEntry[i].locationName + "\n";
        }
        if (shipmentEntry[i].truckWeight !== '') {
          if (IsNumeric(shipmentEntry[i].truckWeight) === false) {
            DisplayMsg = DisplayMsg + "Truck Weight should be a number for location " + shipmentEntry[i].locationName + ".\n"
          }
        }
        strAtleastOne = 1;
      }
    }

    if (strAtleastOne === 0) {
      DisplayMsg = DisplayMsg + "Please enter at least one order. \n";
    }

    if (DisplayMsg.trim() !== '') {
      dispatch(
        uiActions.displayNotification({
          message: DisplayMsg,
          severity: "error",
        })
      );
      return false;
    } else {
      // eslint-disable-next-line array-callback-return
      const objShipment = shipmentEntry.map((obj: any) => {
        if (obj.orderNo !== null &&
          (obj.deliveryDate !== null || obj.deliveryDate !== 'None') &&
          obj.truckWeight !== null &&
          (obj.product !== null || obj.product !== '')) {
          return {
            orderNo: obj.orderNo,
            deliveryDate: new Date(obj.deliveryDate),
            weight: obj.loadOut === true ? '-' + obj.truckWeight : obj.truckWeight,
            productID: obj.product,
            comments: obj.comments === null ? "" : obj.comments,
            locationID: obj.locationID
          }
        }
      })
      const objData: ShipmentEntryDetails = objShipment.filter((item: any): item is any => item !== undefined);
      setSaveData(true);
      setShipmentInfo(objData);
    }
  }

  const handleLocationShipment = (shipTo: number) => {
    if (shipTo !== null && shipTo !== undefined) {
      dispatch(
        filterBandActions.setSelectedLocation({
          locationNo: shipTo,
        })
      );
    }
  };

  const onModalClose = (e: React.MouseEvent<HTMLButtonElement>) => {
    Promise.resolve().then(() => {
      setOrderNos("");
      setShowModalPopup(false);
      locationDataLoaded();
    });
  };

  return (
    <>
      <PageHeader title="Add Shipments" subtitle="To add bulk shipments, please do not select a location in the location dropdown box at the top."></PageHeader>
      {
        <Card sx={{ width: "100hv", borderRadius: 3, margin: 2, mt: 0 }} elevation={6}>
          <CardContent sx={{ pb: 0 }}>
            <Tbl>
              <TableBody>
                {shipmentEntry && (shipmentEntry.map((row: any, index: any) => (
                  <StyledTableRow key={row.site}>
                    <StyledTableCell component="th" scope="row">
                      {row.site}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      <RouterLink
                        onClick={(e) =>
                          handleLocationShipment(row.ship)
                        }
                        to={`/locationShipmentSummary`}
                        style={{
                          color: 'green'
                        }}
                      >
                        {row.locationName}
                      </RouterLink>
                    </StyledTableCell>
                    <StyledTableCell align="left">{row.ship}</StyledTableCell>
                    <StyledTableCell align="left">
                      <TextField
                        id={"orderNo_" + index}
                        variant="outlined"
                        type="text"
                        value={row.orderNo}
                        onChange={(e) => handleInputChange(e, index, "orderNo")}
                        inputProps={{ maxLength: 6 }}
                        size="small"
                      />
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      <DatePickerForDelivery
                        onDateSelected={handleDateChangedelivery}
                        index={index}
                        deliveryValue={row.deliveryDate}
                      />
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      <TextField
                        id={"Weight" + index}
                        value={row.truckWeight}
                        variant="outlined"
                        onChange={(e) => { handleInputChange(e, index, "truckWeight") }}
                        inputProps={{ maxLength: 8 }}
                      />
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                        {
                          <SelectDropdown
                            payloadP={{
                              index: index,
                              selectedCustomerNo: row.selectedCustomerNo,
                              locationNo: row.ship,
                              product: row.product
                            }}
                            index={index}
                            onDataSelected={handleDataFromChildSelect}
                          />
                        }
                      </FormControl>
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      <TextField
                        id={"comments_" + index}
                        variant="outlined"
                        type="text"
                        value={row.comments}
                        onChange={(e) => handleInputChange(e, index, "comments")}
                      />
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      <FormControlLabel
                        value="start"
                        control={
                          <Checkbox
                            checked={row.loadout}
                            onChange={(e) => loadoutHandleChange(e, index)}
                          />
                        }
                        label=""
                        labelPlacement="start"
                      />
                    </StyledTableCell>
                  </StyledTableRow>
                )))}
              </TableBody>
            </Tbl>
            <Box textAlign='center'>
              <Button variant="contained" onClick={handleSaveClick}>Save</Button>
            </Box>
          </CardContent>
        </Card>
      }
      <ModalPopup
        open={showModalPopup}
        title="Error"
        onPopupClose={onModalClose}
        width="600px"
      >
        <ErrorPopup
          setShowModalPopup={setShowModalPopup}
          orderNos={orderNos}
          setRefreshList={setRefreshList}
        />
      </ModalPopup>
    </>
  )
}
// component for product dropdown
function SelectDropdown(props: any) {
  const [changedValue, setValue] = useState<any>([]);
  const [data, setData] = useState<any>([]);
  const { fetchData: fetchProductList } = ShipmentDataService.RetrieveProductsByLocation(
    props.payloadP.selectedCustomerNo,
    props.payloadP.locationNo);
  useEffect(() => {
    fetchProductList().then((resp) => {
      setData(resp.responseData)
    })
  }, [])


  const handleProductSelectChange = (event: any) => {
    const selectedData = event.target.value;
    props.onDataSelected(selectedData, props.index);
    setValue(selectedData);
  };

  return (
    <Select
      labelId={"product_" + props.index}
      id={"product_" + props.index}
      onChange={handleProductSelectChange}
      value={changedValue}
      label="Product"
    >
      {data.length === 0 &&
        <MenuItem value="">
          <em>None</em>
        </MenuItem>
      }
      {data.length > 0 &&
        <MenuItem value="">
          &nbsp;
        </MenuItem>
      }
      {data && data.map((item: any) => (
        <MenuItem value={item.vP_PRODUCT_ID} >
          {item.vP_PRODUCT_CODE.trim()}
        </MenuItem>
      ))}
    </Select>
  )
}

export default ShipmentEntry;

// Component for DatePicker
export function DatePickerForDelivery(props: any) {
  const handleDateChange = (date: dayjs.Dayjs | null) => {
    props.onDateSelected(date ? date.format("MM/DD/YYYY") : "None", props.index);
  }
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} >
      <DatePicker
        onChange={handleDateChange}
        value={props.deliveryValue !== null ? dayjs(props.deliveryValue) : null}
      />
    </LocalizationProvider>
  )
}
