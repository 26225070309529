function FormatContactNumber(contactNo: string) {
  let formattedNo;
  if (contactNo != null && contactNo !== undefined && contactNo !== "") {
    contactNo = contactNo.trim();
    if (contactNo.length === 7) {
      formattedNo = contactNo.substr(0, 3) + "." + contactNo.substr(3, 4);
    } else if (contactNo.length === 10) {
      formattedNo =
        contactNo.substr(0, 3) +
        "." +
        contactNo.substr(3, 3) +
        "." +
        contactNo.substr(6, 4);
    } else {
      formattedNo = contactNo;
    }
  } else {
    formattedNo = "";
  }
  return formattedNo;
}

function FormatZipCode(zipCode: string) {
  let formattedNo;
  if (zipCode !== null && zipCode !== undefined && zipCode !== "") {
    zipCode = zipCode.trim();
    if (zipCode.length === 9) {
      formattedNo = zipCode.substr(0, 5) + "-" + zipCode.substr(5, 4);
    } else {
      formattedNo = zipCode;
    }
  } else {
    formattedNo = "";
  }
  return formattedNo;
}

function FormatInvResult(
  value: number | bigint,
  unit: string,
  fraction: number = 2
) {
  let formattedNo: string;
  if (value !== null && value !== undefined) {
    formattedNo = FormatNumber(value, fraction);
    formattedNo += " " + FormatUnit(unit.trim());
  } else {
    formattedNo = "";
  }
  return formattedNo;
}

function FormatUnit(unit: string) {
  let formattedUnit: string;
  unit = unit.trim();
  if (unit !== "" && unit !== undefined && unit !== null) {
    if (unit.toUpperCase() === "LBS") {
      formattedUnit = "lbs";
    } else if (unit.toUpperCase() === "BU") {
      formattedUnit = "Bu";
    } else if (unit.toUpperCase() === "MT") {
      formattedUnit = "MT";
    } else {
      formattedUnit = unit;
    }
  } else {
    formattedUnit = "";
  }
  return formattedUnit;
}

function FormatNumber(value: number | bigint, fraction: number = 2) {
  let formattedNo: string;
  if (value !== null && value !== undefined) {
    if (value.toString().indexOf(",") === -1) {
      formattedNo = new Intl.NumberFormat("en-US", {
        minimumFractionDigits: fraction,
        maximumFractionDigits: fraction,
      }).format(value);
    } else {
      formattedNo = value.toString();
    }
  } else {
    formattedNo = "";
  }
  return formattedNo;
}

function isNumeric(n: any) {
  return !isNaN(parseFloat(n)) && isFinite(n);
}

function base64ToArrayBuffer(base64: string) {
  var binaryString = window.atob(base64);
  var binaryLen = binaryString.length;
  var bytes = new Uint8Array(binaryLen);
  for (var i = 0; i < binaryLen; i++) {
    var ascii = binaryString.charCodeAt(i);
    bytes[i] = ascii;
  }
  return bytes;
}

function CheckError(data: any) {
  const specialChars = /[`'"-]/;
  let msg: boolean = false;
  if (data.length !== undefined && data.length > 1) {
    for (let j = 0; j < data.length; j++) {
      const keys = Object.keys(data[j]);
      for (let i = 0; i < keys.length; i++) {
        const value = data[j][keys[i]];
        if (specialChars.test(value) === true) {
          msg = true;
          return msg;
        }
      }
    }
  } else {
    const keys = Object.keys(data);
    for (let i = 0; i < keys.length; i++) {
      const value = data[keys[i]];
      if (specialChars.test(value) === true) {
        msg = true;
        return msg;
      }
    }
  }
  return msg;
}

function UnderDevelopment() {
  alert("Page is under development");
}

export {
  FormatContactNumber,
  FormatInvResult,
  FormatZipCode,
  FormatNumber,
  FormatUnit,
  isNumeric,
  base64ToArrayBuffer,
  CheckError,
  UnderDevelopment,
};
