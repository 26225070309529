import CustomerSummaryPage from "./CustomerSummaryPage";
import LocationInformationPage from "./LocationInformationPage";
import TankDetailsPage from "./TankDetailsPage";
import { IFilterBandState } from "../interfaces/storeInterfaces";
import { useSelector } from "react-redux";
import { filterBandSelector } from "../store/store";

const LoadSummaryPage = () => {
  const filterBandState: IFilterBandState = useSelector(filterBandSelector);

  return (
    <>
      {filterBandState.selectedTankNo !== "" &&
      filterBandState.selectedTankNo != null ? (
        <TankDetailsPage></TankDetailsPage>
      ) : filterBandState.selectedLocationNo !== "" &&
        filterBandState.selectedLocationNo != null ? (
        <LocationInformationPage></LocationInformationPage>
      ) : (
        <CustomerSummaryPage></CustomerSummaryPage>
      )}
    </>
  );
};

export default LoadSummaryPage;
