/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Card, CardContent, IconButton } from "@mui/material";
import React, { useEffect, useState } from "react";
import TableBody from "@mui/material/TableBody";
import ModeEditSharpIcon from "@mui/icons-material/ModeEditSharp";
import DeleteSharpIcon from "@mui/icons-material/DeleteSharp";
import AddSharpIcon from "@mui/icons-material/AddSharp";
import { StyledTableCell, StyledTableRow } from "../../controls/tablecontrols";
import { TableHeaderCell } from "../../../models/common";
import useTable from "../../../hooks/useTable";
import UserNoticeService from "../../../services/userNoticeService";
import { uiActions } from "../../../store/ui-slice";
import { filterBandSelector, useAppDispatch } from "../../../store/store";
import PageHeader from "../../shared/PageHeader";
import CustomCardHeader from "../../shared/CustomCardHeader";
import { IFilterBandState } from "../../../interfaces/storeInterfaces";
import { useSelector } from "react-redux";
import { LevelUsers, Users } from "../../../models/userNotice";
import AddEditNoticeRequest from "./AddEditNoticeRequest";
import ModalPopup from "../../shared/ModalPopup";
import ConfirmationMessage from "../../shared/ConfirmationMessage";
import { filterBandActions } from "../../../store/filterband-slice";

function NoticeRequest() {
  const dispatch = useAppDispatch();
  const filterBandState: IFilterBandState = useSelector(filterBandSelector);
  const [isRefreshList, setRefreshList] = React.useState<boolean>(false);
  const [userList, setUserList] = useState<Users[]>([]);
  const [levelUserList, setLevelUserList] = useState<LevelUsers[]>([]);
  const [locationName, setLocationName] = useState<string>("");
  const [openNoticeConfirmation, setOpenNoticeConfirmation] =
    React.useState<boolean>(false);
  const [openLevelNoticeConfirmation, setOpenLevelNoticeConfirmation] =
    React.useState<boolean>(false);
  const [showModalPopup, setShowModalPopup] = useState<boolean>(false);
  const [deleteNoticeUserId, setDeleteNoticeUserId] = useState<string>("");
  const [deleteNoticeId, setDeleteNoticeId] = useState<string>("");
  const [deleteLevelUserId, setDeletelevelUserId] = useState<string>("");
  const [deleteLevelNoticeId, setDeleteLevelNoticeId] = useState<string>("");

  const [isNoticeConfirmDelete, setIsNoticeConfirmDelete] =
    useState<boolean>(false);
  const [isLevelNoticeConfirmDelete, setIsLevelNoticeConfirmDelete] =
    useState<boolean>(false);
  const [deleteLocationId, setDeleteLocationId] = useState<string>("");
  const [noticeId, setNoticeId] = useState<string>("");
  const [userId, setUserId] = useState<string>("");
  const [locationNo, setLocationNo] = useState<string>("");
  const [locationId, setLocationId] = useState<string>("");
  const [isEditLevelNotice, setIsEditLevelNotice] = useState<boolean>(false);
  const [isEditNotice, setIsEditNotice] = useState<boolean>(false);
  const [noticeDesc, setNoticeDesc] = useState<string>("");
  const [levelNoticeDesc, setLevelNoticeDesc] = useState<string>("");
  const tableHeaderUserList: TableHeaderCell[] = [
    { id: "userName", label: "User Name" },
    { id: "email", label: "Email Address" },
    { id: "noticeDesc", label: "Notice Type" },
    { id: "edit", label: "Edit", disableSorting: true },
    { id: "delete", label: "Delete", disableSorting: true },
  ];

  const { Tbl: TblUserLIst, GetRowsAfterPagingAndSorting: GetRowsUserList } =
    useTable<Users>(userList, tableHeaderUserList, true, 10);

  const tableHeaderLevelUserList: TableHeaderCell[] = [
    { id: "userName", label: "User Name" },
    { id: "locationNo", label: "Loc No" },
    { id: "email", label: "Email Address" },
    { id: "noticeDesc", label: "Notice Type" },
    { id: "edit", label: "Edit", disableSorting: true },
    { id: "delete", label: "Delete", disableSorting: true },
  ];

  const {
    Tbl: TblLevelUserList,
    GetRowsAfterPagingAndSorting: GetRowsLevelUserList,
  } = useTable<LevelUsers>(levelUserList, tableHeaderLevelUserList, true, 10);

  const { fetchData: fetchUserList } = UserNoticeService.RetrieveUserList(
    filterBandState.selectedCustomerNo
  );
  const { fetchData: fetchLevelUserList } =
    UserNoticeService.RetrieveLevelUserList(
      filterBandState.selectedCustomerNo,
      filterBandState.selectedLocationNo
        ? filterBandState.locations
            .filter(
              (x) => x.locationNo === filterBandState.selectedLocationNo
            )[0]
            .locationID.toString()
        : ""
    );
  const { deleteData: deleteNotice } = UserNoticeService.DeleteNotice(
    deleteNoticeUserId,
    deleteNoticeId
  );
  const { deleteData: deleteLevelNotice } = UserNoticeService.DeleteLevelNotice(
    deleteLevelUserId,
    deleteLocationId,
    deleteLevelNoticeId
  );

  useEffect(() => {
    if (
      filterBandState.selectedCustomerNo !== "" &&
      filterBandState.selectedCustomerNo !== undefined
    ) {
      getUserList();
      getLevelUserList();
    }
  }, [filterBandState.selectedCustomerNo]);

  useEffect(() => {
    if (isRefreshList) {
      getUserList();
      getLevelUserList();
      setRefreshList(false);
      setNoticeId("");
      setUserId("");
      setLocationNo("");
      setLocationId("");
      setIsEditLevelNotice(false);
      setIsEditNotice(false);
    } else {
      setUserList([]);
      setLevelUserList([]);
      setRefreshList(false);
      setNoticeId("");
      setUserId("");
      setLocationNo("");
      setLocationId("");
      setIsEditLevelNotice(false);
      setIsEditNotice(false);
    }
  }, [isRefreshList]);

  useEffect(() => {
    if (isNoticeConfirmDelete) {
      dispatch(uiActions.startProgressBar());
      deleteNotice().then((res) => {
        if (res.isSuccess) {
          dispatch(
            uiActions.displayNotification({
              message: "Notice information deleted successfully!",
              severity: "success",
            })
          );
          setRefreshList(true);
        }
      });

      Promise.resolve().then(() => {
        setIsNoticeConfirmDelete(false);
      });
    } else if (isLevelNoticeConfirmDelete) {
      dispatch(uiActions.startProgressBar());
      deleteLevelNotice().then((res) => {
        if (res.isSuccess) {
          dispatch(
            uiActions.displayNotification({
              message: "Notice Level request deleted successfully!",
              severity: "success",
            })
          );
          setRefreshList(true);
        }
      });

      Promise.resolve().then(() => {
        setIsLevelNoticeConfirmDelete(false);
      });
    }
  }, [isNoticeConfirmDelete, isLevelNoticeConfirmDelete]);

  const getUserList = () => {
    dispatch(uiActions.startProgressBar());
    fetchUserList().then((res) => {
      if (res.responseData.length > 0) {
        setUserList(res.responseData);
      }
    });
  };

  const getLevelUserList = () => {
    dispatch(uiActions.startProgressBar());
    fetchLevelUserList().then((res) => {
      if (res.responseData.length > 0) {
        setLevelUserList(res.responseData);
        if (filterBandState.selectedLocationNo) {
          setLocationName(res.responseData[0].locationName);
        }
      } else {
        if (filterBandState.selectedLocationNo) {
          setLocationName(
            filterBandState.locations
              .filter(
                (x) => x.locationNo === filterBandState.selectedLocationNo
              )[0]
              .locationName.toString()
          );
        }
      }
    });
  };
  const onAddNewClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    Promise.resolve().then(() => {
      setShowModalPopup(true);
    });
  };

  const onModalClose = (e: React.MouseEvent<HTMLButtonElement>) => {
    Promise.resolve().then(() => {
      setShowModalPopup(false);
      setNoticeId("");
      setUserId("");
      setLocationNo("");
      setLocationId("");
      setIsEditLevelNotice(false);
      setIsEditNotice(false);
    });
  };

  const handleNoticeOpenDeleteConfirmation = (
    userId: string,
    noticeId: string,
    noticeDesc: string
  ) => {
    Promise.resolve().then(() => {
      setOpenNoticeConfirmation(true);
      setDeleteNoticeUserId(userId);
      setDeleteNoticeId(noticeId);
      setNoticeDesc(noticeDesc);
    });
  };

  const handleNoticeConfirmDelete = (
    e: React.MouseEvent<HTMLButtonElement>
  ) => {
    Promise.resolve().then(() => {
      setOpenNoticeConfirmation(false);
      setIsNoticeConfirmDelete(true);
    });
  };

  const handleNoticeCancelDelete = (e: React.MouseEvent<HTMLButtonElement>) => {
    Promise.resolve().then(() => {
      setOpenNoticeConfirmation(false);
      setDeleteNoticeUserId("");
      setDeleteNoticeId("");
    });
  };

  const handleLevelNoticeOpenDeleteConfirmation = (
    userId: string,
    noticeId: string,
    locationId: string,
    levelNoticeDesc: string
  ) => {
    Promise.resolve().then(() => {
      setOpenLevelNoticeConfirmation(true);
      setDeletelevelUserId(userId);
      setDeleteLevelNoticeId(noticeId);
      setDeleteLocationId(locationId);
      setLevelNoticeDesc(levelNoticeDesc);
    });
  };

  const handleLevelNoticeConfirmDelete = (
    e: React.MouseEvent<HTMLButtonElement>
  ) => {
    Promise.resolve().then(() => {
      setOpenLevelNoticeConfirmation(false);
      setIsLevelNoticeConfirmDelete(true);
    });
  };

  const handleLevelNoticeCancelDelete = (
    e: React.MouseEvent<HTMLButtonElement>
  ) => {
    Promise.resolve().then(() => {
      setOpenLevelNoticeConfirmation(false);
      setDeletelevelUserId("");
      setDeleteLevelNoticeId("");
      setDeleteLocationId("");
    });
  };

  const handleEditNotice = (userId: number, noticeId: number) => {
    Promise.resolve().then(() => {
      setShowModalPopup(true);
      setNoticeId(noticeId.toString());
      setUserId(userId.toString());
      setIsEditNotice(true);
    });
  };

  const handleEditLevelNotice = (
    userId: number,
    noticeId: number,
    locationNo: string,
    locationId: number
  ) => {
    Promise.resolve().then(() => {
      setShowModalPopup(true);
      setNoticeId(noticeId.toString());
      setUserId(userId.toString());
      setLocationNo(locationNo);
      setLocationId(locationId.toString());
      setIsEditLevelNotice(true);
      if (
        locationNo !== null &&
        locationNo !== undefined &&
        locationNo !== ""
      ) {
        dispatch(
          filterBandActions.setSelectedLocation({
            locationNo: locationNo,
          })
        );
      }
    });
  };

  return (
    <div>
      <PageHeader
        title="Notice Request"
        subtitle="To sort the list by location, Please select a location from the location dropdown at the top."
      ></PageHeader>
      {
        <Card
          sx={{ width: "100hv", borderRadius: 3, margin: 2, mt: 0 }}
          elevation={6}
        >
          <CustomCardHeader
            title="User Notices for all OIS customers"
            action={
              <Button onClick={onAddNewClick} startIcon={<AddSharpIcon />}>
                Add New
              </Button>
            }
          ></CustomCardHeader>
          <CardContent sx={{ pb: 0 }}>
            <TblUserLIst>
              <TableBody>
                {GetRowsUserList().map((row, index) => (
                  <StyledTableRow key={index}>
                    <StyledTableCell scope="row">
                      {row.userName}
                    </StyledTableCell>
                    <StyledTableCell scope="row">{row.email}</StyledTableCell>
                    <StyledTableCell scope="row">
                      {row.noticeDesc}{" "}
                      {row.idleDays !== null && row.idleDays > 0
                        ? row.idleDays + " days"
                        : ""}
                    </StyledTableCell>
                    <StyledTableCell scope="row">
                      <IconButton
                        aria-label="Edit"
                        size="small"
                        sx={{ paddingLeft: 1, paddingRight: 1 }}
                        onClick={(e) =>
                          handleEditNotice(row.userID, row.noticeID)
                        }
                      >
                        <ModeEditSharpIcon fontSize="small" />
                      </IconButton>
                    </StyledTableCell>
                    <StyledTableCell scope="row">
                      <IconButton
                        aria-label="Delete"
                        size="small"
                        sx={{ paddingLeft: 1, paddingRight: 1 }}
                        onClick={(e) =>
                          handleNoticeOpenDeleteConfirmation(
                            row.userID.toString(),
                            row.noticeID.toString(),
                            row.noticeDesc
                          )
                        }
                      >
                        <DeleteSharpIcon fontSize="small" />
                      </IconButton>
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </TblUserLIst>
          </CardContent>
        </Card>
      }
      <Card
        sx={{ width: "100hv", borderRadius: 3, margin: 2, mt: 0 }}
        elevation={6}
      >
        <CustomCardHeader
          title={
            locationName !== ""
              ? "Notices for Users for location " + locationName
              : "Notices for Users for all locations:"
          }
        ></CustomCardHeader>
        <CardContent sx={{ pb: 0 }}>
          <TblLevelUserList>
            <TableBody>
              {GetRowsLevelUserList().map((row, index) => (
                <StyledTableRow key={index}>
                  <StyledTableCell scope="row">{row.userName}</StyledTableCell>
                  <StyledTableCell scope="row">
                    {row.locationNo}
                  </StyledTableCell>
                  <StyledTableCell scope="row">{row.email}</StyledTableCell>
                  <StyledTableCell scope="row">
                    {row.noticeDesc.trim() !== "Shipment Arrival Date" &&
                    row.noticeDesc.trim() !== "COA Missing @Destination" &&
                    row.noticeDesc.trim() !==
                      "High Inventory Arrival Warning" &&
                    row.noticeDesc.trim() !== "ADU Range Warning" &&
                    row.noticeDesc.trim().indexOf("Usage") === -1 ? (
                      <div>
                        {row.noticeDesc}
                        {row.equalitySymbol + " " + row.threshold}{" "}
                        {row.daily === "Y" ? " - Limit one email daily" : ""}
                      </div>
                    ) : (
                      <>{row.noticeDesc + " " + row.threshold}</>
                    )}
                  </StyledTableCell>

                  <StyledTableCell scope="row">
                    <IconButton
                      aria-label="Edit"
                      size="small"
                      sx={{ paddingLeft: 1, paddingRight: 1 }}
                      onClick={(e) =>
                        handleEditLevelNotice(
                          row.userID,
                          row.noticeID,
                          row.locationNo,
                          row.locationID
                        )
                      }
                    >
                      <ModeEditSharpIcon fontSize="small" />
                    </IconButton>
                  </StyledTableCell>
                  <StyledTableCell scope="row">
                    <IconButton
                      aria-label="Delete"
                      size="small"
                      sx={{ paddingLeft: 1, paddingRight: 1 }}
                      onClick={(e) =>
                        handleLevelNoticeOpenDeleteConfirmation(
                          row.userID.toString(),
                          row.noticeID.toString(),
                          row.locationID.toString(),
                          row.noticeDesc
                        )
                      }
                    >
                      <DeleteSharpIcon fontSize="small" />
                    </IconButton>
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </TblLevelUserList>
        </CardContent>
      </Card>
      <ModalPopup
        open={showModalPopup}
        title={
          isEditLevelNotice || isEditNotice
            ? "Update Notice Request"
            : "Add Notice Request"
        }
        onPopupClose={onModalClose}
        width="720px"
      >
        <AddEditNoticeRequest
          setShowModalPopup={setShowModalPopup}
          userId={userId}
          noticeId={noticeId}
          locationNo={locationNo}
          locationId={locationId}
          isEditLevelNotice={isEditLevelNotice}
          isEditNotice={isEditNotice}
          setRefreshList={setRefreshList}
        />
      </ModalPopup>
      <ConfirmationMessage
        open={openNoticeConfirmation}
        message={`Do you really want to delete "${noticeDesc.trim()}" notice request? This process cannot be undone.`}
        onYesClick={handleNoticeConfirmDelete}
        onNoClick={handleNoticeCancelDelete}
      ></ConfirmationMessage>
      <ConfirmationMessage
        open={openLevelNoticeConfirmation}
        message={`Do you really want to delete "${levelNoticeDesc.trim()}" notice request? This process cannot be undone.`}
        onYesClick={handleLevelNoticeConfirmDelete}
        onNoClick={handleLevelNoticeCancelDelete}
      ></ConfirmationMessage>
    </div>
  );
}

export default NoticeRequest;
