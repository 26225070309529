/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from "react";
import {
  Grid,
  TextField,
  Button,
  Container,
  Divider,
  CardContent,
  Card,
  MenuItem,
  FormControl,
  InputLabel,
  Checkbox,
  RadioGroup,
  FormControlLabel,
  Radio,
  Link,
} from "@mui/material";
import { useFormik } from "formik";
import * as yup from "yup";
import TankService from "../../../services/tankService";
import ProductService from "../../../services/productService";
import { filterBandSelector, useAppDispatch } from "../../../store/store";
import { uiActions } from "../../../store/ui-slice";
import { AddTank, TankInfo } from "../../../models/tank";
import { IFilterBandState } from "../../../interfaces/storeInterfaces";
import { useSelector } from "react-redux";
import CustomCardHeader from "../../shared/CustomCardHeader";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { Product } from "../../../models/product";
import { UnitInfo } from "../../../models/location";
import { isNumeric } from "../../shared/CommonFunctions";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";

interface AddEditTankProps {
  setShowModalPopup: React.Dispatch<React.SetStateAction<boolean>>;
  tankID: number;
  locationNo: string;
  setRefreshList: React.Dispatch<React.SetStateAction<boolean>>;
  setProductName: React.Dispatch<React.SetStateAction<string>>;
}

function AddEditTank(props: AddEditTankProps) {
  const filterBandState: IFilterBandState = useSelector(filterBandSelector);
  const dispatch = useAppDispatch();
  const [productList, setProductList] = useState<Product[]>([]);
  const [unitList, setUnitList] = useState<UnitInfo[]>([]);
  const [tankID, setTankID] = useState<number>(0);
  const [tankInfoAPI, setTankInfoAPI] = useState<AddTank>(
    TankService.GetDefaultTankInfo()
  );
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const [TankPSIFlag, setTankPSIFlag] = useState<boolean>(false);
  const [TankMAFlag, setTankMAFlag] = useState<boolean>(false);
  const [disablePSI, setDisablePSI] = useState<boolean>(false);
  const [initialLoad, setInitialLoad] = useState<boolean>(false);

  //---------------------formik
  const validationSchema = yup.object({
    vT_TANK_NAME: yup.string().required("Required"),
    vT_TANK_NO: yup
      .number()
      .integer("Tank Number must be an integer")
      .required("Required"),
    vT_PRODUCT_ID: yup.number().required("Required"),
    vT_TANK_HEIGHT: yup
      .number()
      .typeError("Tank Height must be a number")
      .required("Required"),
    vT_TANK_DIAMETER: yup
      .number()
      .typeError("Tank Diameter must be a number")
      .required("Required"),
    vT_TANK_LENGTH: yup
      .number()
      .typeError("Tank Length must be a number")
      .when("vT_TANK_ORIENTATION", {
        is: "H",
        then: yup
          .number()
          .typeError("Tank Length must be a number")
          .required("Required"),
      }),
    vT_TANK_PSI_MAX: yup
      .number()
      .typeError("Tank Max PSI must be a number")
      .when("radioPSIValue", {
        is: false,
        then: yup
          .number()
          .typeError("Tank Max PSI must be a number")
          .required("Required"),
      }),
    vT_PROBE_PSI_MAX: yup
      .number()
      .typeError("Probe Max PSI must be a number")
      .when("radioPSIValue", {
        is: false,
        then: yup
          .number()
          .typeError("Probe Max PSI must be a number")
          .required("Required"),
      }),
    vT_TANK_SCALE: yup
      .number()
      .typeError("Tank Scale Max must be a number")
      .required("Required"),
    vT_TANK_CAPACITY_GAL: yup
      .number()
      .typeError("Tank Capacity(gal) must be a number")
      .required("Add valid dimensions of Tank to calculate"),
    vT_TANK_CAPACITY_CUB_FT: yup
      .number()
      .typeError("Tank Capacity(Cubic ft) must be a number"),
    vT_TANK_TEMP_F: yup
      .number()
      .nullable(true)
      .typeError("Tank Temperature must be a number"),
    vT_PROBE_4MA_SET: yup
      .number()
      .nullable(true)
      .typeError("Probe 4ma Setting must be a number"),
    vT_PROBE_20MA_SET: yup
      .number()
      .nullable(true)
      .typeError("Probe 20ma Setting must be a number"),
    vT_PROBE_IND_SPAN_LOW: yup
      .number()
      .nullable(true)
      .typeError("Probe Indicator Span Low must be a number"),
    vT_PROBE_IND_SPAN_HIGH: yup
      .number()
      .nullable(true)
      .typeError("Probe Indicator Span High must be a number"),
    vT_TEMP_RANGE_MIN: yup
      .number()
      .nullable(true)
      .typeError("Minimum Temperature must be a number")
      .when("vT_TEMP_READER", {
        is: true,
        then: yup
          .number()
          .nullable(true)
          .required("Required")
          .typeError("Minimum Temperature must be a number"),
      }),
    vT_TEMP_RANGE_MAX: yup
      .number()
      .nullable(true)
      .typeError("Maximum Temperature must be a number")
      .when("vT_TEMP_READER", {
        is: true,
        then: yup
          .number()
          .nullable(true)
          .required("Required")
          .typeError("Maximum Temperature must be a number"),
      }),
    vT_PROBE_CAL_DATE: yup
      .date()
      .nullable(true)
      .typeError("Please enter a valid Date"),
    vT_PROBE_INST_DATE: yup
      .date()
      .nullable(true)
      .typeError("Please enter a valid Date"),
  });

  const formik = useFormik({
    initialValues: TankService.GetDefaultTankInfo(),
    validationSchema: validationSchema,
    enableReinitialize: true,

    onReset: (Event) => {
      formik.values = TankService.GetDefaultTankInfo();
      loadMaxNumber();
    },

    onSubmit: (values: TankInfo) => {
      assignValues();
    },
  });

  const setInputValues = useCallback(
    (key, value) =>
      formik.setValues({
        ...formik.values,
        [key]: value,
      }),
    [formik]
  );

  //---------------------useEffect

  //for page load
  useEffect(() => {
    if (
      props.locationNo !== "" &&
      props.locationNo !== null &&
      props.locationNo !== undefined
    ) {
      loadProduct();
      loadunit();
      if (
        props.tankID !== 0 &&
        props.tankID !== null &&
        props.tankID !== undefined
      ) {
        loadTankbyID(props.tankID);
        setTankID(props.tankID);
      } else {
        loadMaxNumber();
        props.setProductName("");
      }
    }
  }, [props.tankID]);

  //for add update api response
  useEffect(() => {
    if (isLoaded) {
      if (tankID !== 0 && tankID !== null) {
        dispatch(uiActions.startProgressBar());
        updateTankData().then((res) => {
          if (res.isSuccess) {
            dispatch(
              uiActions.displayNotification({
                message: "Tank updated successfully!",
                severity: "success",
              })
            );
            props.setShowModalPopup(false);
            props.setRefreshList(true);
          }
        });
      } else {
        dispatch(uiActions.startProgressBar());
        addTankData().then((res) => {
          if (res.isSuccess === true) {
            if (res.responseData.success === "Yes") {
              dispatch(
                uiActions.displayNotification({
                  message: "Tank added successfully!",
                  severity: "success",
                })
              );
              props.setShowModalPopup(false);
              props.setRefreshList(true);
            } else if (res.responseData.success === "No") {
              dispatch(
                uiActions.logErrorNotification({
                  message: "There is already a tank with this number.",
                  severity: "error",
                })
              );
            }
          }
        });
      }
    } else {
      setIsLoaded(true);
    }
  }, [tankInfoAPI]);

  //for calcualting tank PSI after change event
  useEffect(() => {
    if (TankPSIFlag === true) {
      if (formik.values.radioPSIValue === false) {
        setTankPSI();
      }
      setTankPSIFlag(false);
    }
  }, [formik.values.vT_PROBE_TYPE, formik.values.vT_TANK_HEIGHT, TankPSIFlag]);

  //for calculating tank MA after change event
  useEffect(() => {
    if (
      TankMAFlag === true &&
      formik.values.vT_TANK_PSI_MAX !== null &&
      formik.values.vT_TANK_PSI_MAX !== undefined
    ) {
      setTankMA();
      setTankMAFlag(false);
    }
  }, [formik.values.vT_TANK_PSI_MAX, TankMAFlag]);

  //for cheking radio button on load
  useEffect(() => {
    if (initialLoad === true) {
      if (
        formik.values.vT_TANK_PSI_MAX === null ||
        formik.values.vT_TANK_PSI_MAX === undefined
      ) {
        setInputValues("radioPSIValue", true);
      } else {
        setInputValues("radioPSIValue", false);
      }
      getTankCapacity();
      setInitialLoad(false);
    }
  }, [initialLoad]);

  //for radio button change event
  useEffect(() => {
    checkPSI();
  }, [formik.values.radioPSIValue]);

  useEffect(() => {
    getTankCapacity();
  }, [
    formik.values.vT_TANK_HEIGHT,
    formik.values.vT_TANK_DIAMETER,
    formik.values.vT_TANK_LENGTH,
    formik.values.vT_TANK_ORIENTATION,
  ]);

  //---------------------API
  const { fetchData: fetchTankData } = TankService.RetrieveTankInfo(
    filterBandState.selectedCustomerNo,
    props.locationNo,
    props.tankID
  );

  const { fetchData: fetchMaxTank } = TankService.RetrieveMaxTankNumber(
    filterBandState.selectedCustomerNo,
    props.locationNo
  );

  const { fetchData: fetchProductData } = ProductService.RetrieveProducts();

  const { fetchData: fetchUnitData } = TankService.RetrieveUnitByLocation(
    filterBandState.selectedCustomerNo,
    props.locationNo
  );

  const { putData: updateTankData } = TankService.UpdateTank(
    filterBandState.selectedCustomerNo,
    props.locationNo,
    tankInfoAPI
  );

  const { postData: addTankData } = TankService.CreateTank(
    filterBandState.selectedCustomerNo,
    props.locationNo,
    tankInfoAPI
  );

  //---------------------events
  const productChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const productID = event.target.value;
    formik.setFieldValue("vT_PRODUCT_ID", parseInt(productID));

    const product = productList.find(
      (row) => row.vP_PRODUCT_ID === parseInt(productID)
    ) || { vP_PRODUCT_GROUP: null, vP_SPECIFIC_GRAVITY: null };
    formik.setFieldValue("vP_PRODUCT_GROUP", product.vP_PRODUCT_GROUP);
    formik.setFieldValue("vP_SPECIFIC_GRAVITY", product.vP_SPECIFIC_GRAVITY);
  };

  const isActiveHandleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValues("vT_TANK_ACTIVE", event.target.checked === true ? "Y" : "N");
  };

  const RadioTemperature = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValues(
      "vT_TEMP_READER",
      (event.target as HTMLInputElement).value === "true" ? true : false
    );
  };

  const RadioPSI = (event: React.ChangeEvent<HTMLInputElement>) => {
    let value =
      (event.target as HTMLInputElement).value === "true" ? true : false;
    setInputValues("radioPSIValue", value);
  };

  const SensorMaxChange = (event: React.FocusEvent<HTMLInputElement>) => {
    checkSensorMax(event.target.value);
    setTankMAFlag(true);
  };

  const TankMaxChange = (event: React.FocusEvent<HTMLInputElement>) => {
    const formValues = formik.values;
    checkSensorMax(formValues.vT_PROBE_PSI_MAX);
    setTankMAFlag(true);
  };

  const onProbeTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const probeValue = event.target.value;
    setInputValues("vT_PROBE_TYPE", probeValue);
    setTankPSIFlag(true);
    setTankMAFlag(true);
  };

  const onheightChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const height = event.target.value;
    setInputValues("vT_TANK_HEIGHT", height);
    setTankPSIFlag(true);
    setTankMAFlag(true);
  };

  //---------------------functions
  const loadProduct = async () => {
    dispatch(uiActions.startProgressBar());
    await fetchProductData().then((res) => {
      setProductList(res.responseData);
    });
  };

  const loadMaxNumber = async () => {
    dispatch(uiActions.startProgressBar());
    await fetchMaxTank().then((res) => {
      formik.setFieldValue("vT_TANK_NO", res.responseData.tankNumber);
      formik.setFieldValue("vT_TANK_ORIENTATION", "H");
      formik.setFieldValue("vT_PROBE_TYPE", "P");
      formik.setFieldValue("vT_PROBE_COMM_TYPE", "A");
      formik.setFieldValue("vT_TANK_ACTIVE", "N");
      formik.setFieldValue("vT_TEMP_READER", false);
      formik.setFieldValue("radioPSIValue", true);
      let location = filterBandState.locations.find(
        (row) => row.locationNo === props.locationNo
      );
      formik.setFieldValue("vT_LOCATION_ID", location?.locationID);
    });
  };

  const loadunit = async () => {
    dispatch(uiActions.startProgressBar());
    await fetchUnitData().then((res) => {
      setUnitList(res.responseData);
    });
  };

  const loadTankbyID = (tankID: number) => {
    if (tankID !== 0) {
      dispatch(uiActions.startProgressBar());
      fetchTankData().then((res) => {
        formik.setValues(res.responseData);
        props.setProductName(" - " + res.responseData.vP_PRODUCT_DESC);
        setInitialLoad(true);
      });
    }
  };

  const assignValues = () => {
    let formValues = formik.values;
    let apiValues: AddTank = {
      vT_TANK_ID: checkNull(formValues.vT_TANK_ID),
      vT_TANK_NAME: checkNull(formValues.vT_TANK_NAME),
      vT_TANK_NO: checkNull(formValues.vT_TANK_NO),
      vT_LOCATION_ID: checkNull(formValues.vT_LOCATION_ID),
      vT_UNIT_ID:
        String(formValues.vT_UNIT_ID) === "NA"
          ? null
          : checkNull(formValues.vT_UNIT_ID),
      vT_PRODUCT_ID: checkNull(formValues.vT_PRODUCT_ID),
      vT_TANK_HEIGHT: checkNull(formValues.vT_TANK_HEIGHT),
      vT_TANK_DIAMETER: checkNull(formValues.vT_TANK_DIAMETER),
      vT_TANK_TEMP_F: checkNull(formValues.vT_TANK_TEMP_F),
      vT_TANK_CAPACITY_GAL: checkNull(formValues.vT_TANK_CAPACITY_GAL),
      vT_TANK_CAPACITY_CUB_FT: checkNull(formValues.vT_TANK_CAPACITY_CUB_FT),
      vT_TANK_PSI_MAX: checkNull(formValues.vT_TANK_PSI_MAX),
      vT_TANK_ACTIVE: checkNull(formValues.vT_TANK_ACTIVE),
      vT_TANK_AUTO_CALC: checkNull(formValues.vT_TANK_AUTO_CALC),
      vT_PROBE_COMM_TYPE: checkNull(formValues.vT_PROBE_COMM_TYPE),
      vT_PROBE_TYPE: checkNull(formValues.vT_PROBE_TYPE),
      vT_PROBE_MODEL: checkNull(formValues.vT_PROBE_MODEL),
      vT_PROBE_MFG: checkNull(formValues.vT_PROBE_MFG),
      vT_PROBE_4MA_SET: checkNull(formValues.vT_PROBE_4MA_SET),
      vT_PROBE_20MA_SET: checkNull(formValues.vT_PROBE_20MA_SET),
      vT_PROBE_IND_SPAN_LOW: checkNull(formValues.vT_PROBE_IND_SPAN_LOW),
      vT_PROBE_IND_SPAN_HIGH: checkNull(formValues.vT_PROBE_IND_SPAN_HIGH),
      vT_PROBE_CAL_DATE: checkNull(formValues.vT_PROBE_CAL_DATE),
      vT_PROBE_INST_DATE: checkNull(formValues.vT_PROBE_INST_DATE),
      vT_PROBE_BLANKING: checkNull(formValues.vT_PROBE_BLANKING),
      vT_PROBE_PSI_MAX: checkNull(formValues.vT_PROBE_PSI_MAX),
      vT_SEND_WRN_EMAIL: checkNull(formValues.vT_SEND_WRN_EMAIL),
      vT_TANK_ORIENTATION: checkNull(formValues.vT_TANK_ORIENTATION),
      vT_TANK_LENGTH:
        String(formValues.vT_TANK_LENGTH) === "" ||
          formValues.vT_TANK_LENGTH === null
          ? 0
          : checkNull(formValues.vT_TANK_LENGTH),
      vT_TANK_SCALE: checkNull(formValues.vT_TANK_SCALE),
      vT_TANK_MA_MAX: checkNull(formValues.vT_TANK_MA_MAX),
      vT_SOURCE_TYPE:
        formValues.vT_SOURCE_TYPE === "NA"
          ? null
          : checkNull(formValues.vT_SOURCE_TYPE),
      vT_SOURCE_TAG: checkNull(formValues.vT_SOURCE_TAG),
      vT_SOURCE_NODE: checkNull(formValues.vT_SOURCE_NODE),
      vT_TEMP_READER: checkNull(formValues.vT_TEMP_READER),
      vT_TEMP_RANGE_MIN: checkNull(formValues.vT_TEMP_RANGE_MIN),
      vT_TEMP_RANGE_MAX: checkNull(formValues.vT_TEMP_RANGE_MAX),
    };
    setTankInfoAPI(apiValues);
  };

  const checkNull = (value: any) => {
    if (value !== null && String(value) !== "") {
      return value;
    } else {
      return null;
    }
  };

  const setTankPSI = () => {
    const formValues = formik.values;
    if (formValues.vT_PROBE_TYPE === "P") {
      let value =
        Math.round(
          (formValues.vT_TANK_HEIGHT / 2.307) *
          formValues.vP_SPECIFIC_GRAVITY *
          10000
        ) / 10000;
      formik.setFieldValue("vT_TANK_PSI_MAX", value);
    }
  };

  const setTankMA = () => {
    const formValues = formik.values;
    if (
      formValues.vT_PROBE_PSI_MAX === 0 ||
      formValues.vT_PROBE_PSI_MAX === null ||
      formValues.vT_PROBE_PSI_MAX === undefined
    ) {
      formik.setFieldValue("vT_TANK_MA_MAX", "");
    } else if (
      formValues.vT_PROBE_TYPE === "P" &&
      String(formValues.vT_TANK_PSI_MAX) !== "" &&
      String(formValues.vT_PROBE_PSI_MAX) !== ""
    ) {
      let value =
        Math.round(
          (((formValues.vT_TANK_PSI_MAX || 0) / formValues.vT_PROBE_PSI_MAX) *
            16 +
            4) *
          10000
        ) / 10000;
      formik.setFieldValue("vT_TANK_MA_MAX", value);
    } else {
      formik.setFieldValue("vT_TANK_MA_MAX", "");
    }
  };

  const checkSensorMax = (sensorMax: string | Number | null | undefined) => {
    if (sensorMax !== undefined && sensorMax !== null && sensorMax !== "") {
      if (isNumeric(sensorMax))
        if (Number(sensorMax) === 0) {
          dispatch(
            uiActions.logErrorNotification({
              message: "Validation Error: Sensor Max(PSI) cannot be 0",
              severity: "error",
            })
          );
        }
    } else {
      dispatch(
        uiActions.logErrorNotification({
          message: "Validation Error:  Sensor Max(PSI) cannot be blank",
          severity: "error",
        })
      );
    }
  };

  const checkPSI = () => {
    const formValues = formik.values;
    if (formValues.radioPSIValue === true) {
      formik.setFieldValue("vT_TANK_PSI_MAX", "");
      formik.setFieldValue("vT_PROBE_PSI_MAX", "");
      formik.setFieldValue("vT_TANK_MA_MAX", "");
      setDisablePSI(true);
    }
    if (formValues.radioPSIValue === false) {
      setDisablePSI(false);
      setTankPSIFlag(true);
    }
  };

  const getTankCapacity = () => {
    const formValues = formik.values;
    let capacityCuFt, capacityGal;

    if (formValues.vT_TANK_ORIENTATION === "V") {
      if (
        String(formValues.vT_TANK_HEIGHT) !== "" &&
        String(formValues.vT_TANK_DIAMETER) !== ""
      ) {
        const r = formValues.vT_TANK_DIAMETER / 2;
        const h = formValues.vT_TANK_HEIGHT;

        const volCuFt = Math.PI * r * r * h;

        capacityCuFt = Math.round(volCuFt * 100) / 100;
        capacityGal = Math.round(volCuFt * 7.48051945 * 100) / 100;
      }
    } else {
      if (
        String(formValues.vT_TANK_LENGTH) !== "" &&
        String(formValues.vT_TANK_DIAMETER) !== "" &&
        String(formValues.vT_TANK_HEIGHT) !== ""
      ) {
        const r = (formValues.vT_TANK_DIAMETER / 2) * 12; //express radius in inches
        const h = formValues.vT_TANK_HEIGHT * 12; //express height in inches
        const l = Number(formValues.vT_TANK_LENGTH) * 12; //express length in inches

        const volGal =
          l *
          (r * r * Math.acos((r - h) / r) -
            Math.sqrt(2 * r * h - h * h) * (r - h));

        capacityCuFt = (volGal / 231) * 0.133680556;
        capacityGal = volGal / 231;
      }
    }
    formik.setFieldValue(
      "vT_TANK_CAPACITY_CUB_FT",
      capacityCuFt !== undefined && !Number.isNaN(capacityCuFt)
        ? capacityCuFt
        : ""
    );
    formik.setFieldValue(
      "vT_TANK_CAPACITY_GAL",
      capacityGal !== undefined && !Number.isNaN(capacityGal) ? capacityGal : ""
    );
  };

  //---------------------props
  const ITEM_HEIGHT = 46;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5,
      },
    },
  };

  return (
    <React.Fragment>
      <Container>
        <form onSubmit={formik.handleSubmit} id="form1" className="form">
          <Grid container columnSpacing={{ xs: 1, sm: 1, md: 4, lg: 4 }}>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Card sx={{ borderRadius: 1 }} elevation={3}>
                <CustomCardHeader title="Tank Setting Information"></CustomCardHeader>
                <CardContent>
                  <Grid
                    container
                    columnSpacing={{ xs: 1, sm: 1, md: 4, lg: 4 }}
                  // rowSpacing={0.7}
                  >
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <TextField
                        name="vT_TANK_NAME"
                        label="Tank Name"
                        fullWidth
                        value={formik.values.vT_TANK_NAME}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.vT_TANK_NAME &&
                          Boolean(formik.errors.vT_TANK_NAME)
                        }
                        helperText={
                          formik.touched.vT_TANK_NAME &&
                          formik.errors.vT_TANK_NAME
                        }
                        inputProps={{ maxLength: 50 }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <TextField
                        name="vT_TANK_NO"
                        label="Tank Number"
                        fullWidth
                        value={formik.values.vT_TANK_NO}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.vT_TANK_NO &&
                          Boolean(formik.errors.vT_TANK_NO)
                        }
                        helperText={
                          formik.touched.vT_TANK_NO && formik.errors.vT_TANK_NO
                        }
                        inputProps={{ maxLength: 5 }}
                        disabled={true}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <TextField
                        id="Product ID"
                        name="vT_PRODUCT_ID"
                        select
                        label="Product ID"
                        value={formik.values.vT_PRODUCT_ID}
                        onChange={productChange}
                        error={
                          formik.touched.vT_PRODUCT_ID &&
                          Boolean(formik.errors.vT_PRODUCT_ID)
                        }
                        helperText={
                          formik.touched.vT_PRODUCT_ID &&
                          formik.errors.vT_PRODUCT_ID
                        }
                        fullWidth
                        inputProps={{ MenuProps }}
                      >
                        {productList.map((row, index) => {
                          return (
                            <MenuItem
                              key={row.vP_PRODUCT_ID + "$" + index}
                              value={String(row.vP_PRODUCT_ID)}
                            >
                              {"(" +
                                row.vP_PRODUCT_CODE +
                                ") " +
                                row.vP_PRODUCT_DESC}
                            </MenuItem>
                          );
                        })}
                      </TextField>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <TextField
                        name="vP_PRODUCT_GROUP"
                        label="Product Group"
                        fullWidth
                        value={formik.values.vP_PRODUCT_GROUP}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.vP_PRODUCT_GROUP &&
                          Boolean(formik.errors.vP_PRODUCT_GROUP)
                        }
                        helperText={
                          formik.touched.vP_PRODUCT_GROUP &&
                          formik.errors.vP_PRODUCT_GROUP
                        }
                        inputProps={{ readOnly: true }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <TextField
                        name="vP_SPECIFIC_GRAVITY"
                        label="Specific Gravity"
                        fullWidth
                        value={formik.values.vP_SPECIFIC_GRAVITY}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.vP_SPECIFIC_GRAVITY &&
                          Boolean(formik.errors.vP_SPECIFIC_GRAVITY)
                        }
                        helperText={
                          formik.touched.vP_SPECIFIC_GRAVITY &&
                          formik.errors.vP_SPECIFIC_GRAVITY
                        }
                        inputProps={{ readOnly: true }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <TextField
                        name="vT_TANK_HEIGHT"
                        label="Tank Height(ft)"
                        fullWidth
                        value={formik.values.vT_TANK_HEIGHT}
                        onChange={onheightChange}
                        error={
                          formik.touched.vT_TANK_HEIGHT &&
                          Boolean(formik.errors.vT_TANK_HEIGHT)
                        }
                        helperText={
                          formik.touched.vT_TANK_HEIGHT &&
                          formik.errors.vT_TANK_HEIGHT
                        }
                        inputProps={{ maxLength: 8 }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <TextField
                        name="vT_TANK_CAPACITY_GAL"
                        label="Tank Capacity(gal)"
                        fullWidth
                        value={formik.values.vT_TANK_CAPACITY_GAL}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.vT_TANK_CAPACITY_GAL &&
                          Boolean(formik.errors.vT_TANK_CAPACITY_GAL)
                        }
                        helperText={
                          formik.touched.vT_TANK_CAPACITY_GAL &&
                          formik.errors.vT_TANK_CAPACITY_GAL
                        }
                        inputProps={{ readOnly: true, maxLength: 8 }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <TextField
                        name="vT_TANK_DIAMETER"
                        label="Tank Diameter(ft)"
                        fullWidth
                        value={formik.values.vT_TANK_DIAMETER}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.vT_TANK_DIAMETER &&
                          Boolean(formik.errors.vT_TANK_DIAMETER)
                        }
                        helperText={
                          formik.touched.vT_TANK_DIAMETER &&
                          formik.errors.vT_TANK_DIAMETER
                        }
                        inputProps={{ maxLength: 8 }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <TextField
                        name="vT_TANK_CAPACITY_CUB_FT"
                        label="Tank Capacity(cubic ft)"
                        fullWidth
                        value={formik.values.vT_TANK_CAPACITY_CUB_FT}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.vT_TANK_CAPACITY_CUB_FT &&
                          Boolean(formik.errors.vT_TANK_CAPACITY_CUB_FT)
                        }
                        helperText={
                          formik.touched.vT_TANK_CAPACITY_CUB_FT &&
                          formik.errors.vT_TANK_CAPACITY_CUB_FT
                        }
                        inputProps={{ readOnly: true, maxLength: 8 }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      {formik.values.vT_TANK_ORIENTATION === "H" && (
                        <TextField
                          name="vT_TANK_LENGTH"
                          label="Length(ft)"
                          fullWidth
                          value={formik.values.vT_TANK_LENGTH}
                          onChange={formik.handleChange}
                          error={
                            formik.touched.vT_TANK_LENGTH &&
                            Boolean(formik.errors.vT_TANK_LENGTH)
                          }
                          helperText={
                            formik.touched.vT_TANK_LENGTH &&
                            formik.errors.vT_TANK_LENGTH
                          }
                          inputProps={{ maxLength: 8 }}
                        />
                      )}
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <TextField
                        name="vT_TANK_TEMP_F"
                        label="Tank Temperature(f)"
                        fullWidth
                        value={formik.values.vT_TANK_TEMP_F}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.vT_TANK_TEMP_F &&
                          Boolean(formik.errors.vT_TANK_TEMP_F)
                        }
                        helperText={
                          formik.touched.vT_TANK_TEMP_F &&
                          formik.errors.vT_TANK_TEMP_F
                        }
                        inputProps={{ maxLength: 8 }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <TextField
                        id="Tank Orientation"
                        name="vT_TANK_ORIENTATION"
                        select
                        label="Tank Orientation"
                        value={formik.values.vT_TANK_ORIENTATION}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.vT_TANK_ORIENTATION &&
                          Boolean(formik.errors.vT_TANK_ORIENTATION)
                        }
                        helperText={
                          formik.touched.vT_TANK_ORIENTATION &&
                          formik.errors.vT_TANK_ORIENTATION
                        }
                        fullWidth
                        inputProps={{ MenuProps }}
                      >
                        <MenuItem value="V">Vertical</MenuItem>
                        <MenuItem value="H">Horizontal</MenuItem>
                      </TextField>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} sx={{ mt: 0.4 }}>
                      <TextField
                        name="vT_TANK_SCALE"
                        label="Tank Scale Max(Tons)"
                        fullWidth
                        value={formik.values.vT_TANK_SCALE}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.vT_TANK_SCALE &&
                          Boolean(formik.errors.vT_TANK_SCALE)
                        }
                        helperText={
                          formik.touched.vT_TANK_SCALE &&
                          formik.errors.vT_TANK_SCALE
                        }
                        inputProps={{ maxLength: 8 }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      {/* pending */}
                      <Grid container>
                        <Grid item xs={6} sm={6} md={6} lg={6}>
                          <InputLabel sx={{ mt: 1 }}>Tank Active</InputLabel>
                        </Grid>
                        <Grid item xs={6} sm={6} md={6} lg={6}>
                          <Checkbox
                            id="vT_TANK_ACTIVE"
                            name="vT_TANK_ACTIVE"
                            value={formik.values.vT_TANK_ACTIVE}
                            checked={
                              formik.values.vT_TANK_ACTIVE === "Y"
                                ? true
                                : false
                            }
                            onChange={isActiveHandleChange}
                            inputProps={{ "aria-label": "controlled" }}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <TextField
                        id="Unit Device ID"
                        name="vT_UNIT_ID"
                        select
                        label="Unit Device ID"
                        value={
                          formik.values.vT_UNIT_ID === null ||
                            String(formik.values.vT_UNIT_ID) === "" ||
                            String(formik.values.vT_UNIT_ID) === "NA"
                            ? "NA"
                            : formik.values.vT_UNIT_ID
                        }
                        onChange={formik.handleChange}
                        error={
                          formik.touched.vT_UNIT_ID &&
                          Boolean(formik.errors.vT_UNIT_ID)
                        }
                        helperText={
                          formik.touched.vT_UNIT_ID && formik.errors.vT_UNIT_ID
                        }
                        fullWidth
                        inputProps={{ MenuProps }}
                      >
                        <MenuItem value="NA">Select Unit</MenuItem>
                        {unitList.map((row, index) => {
                          return (
                            <MenuItem
                              key={index + row.vunT_UNIT_ID}
                              value={row.vunT_UNIT_ID}
                            >
                              {row.vunT_UNIT_NO}
                            </MenuItem>
                          );
                        })}
                      </TextField>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <TextField
                        id="Data Source"
                        name="vT_SOURCE_TYPE"
                        select
                        label="Data Source"
                        value={
                          formik.values.vT_SOURCE_TYPE === null ||
                            formik.values.vT_SOURCE_TYPE === "" ||
                            formik.values.vT_SOURCE_TYPE === "NA"
                            ? "NA"
                            : formik.values.vT_SOURCE_TYPE
                        }
                        onChange={formik.handleChange}
                        error={
                          formik.touched.vT_SOURCE_TYPE &&
                          Boolean(formik.errors.vT_SOURCE_TYPE)
                        }
                        helperText={
                          formik.touched.vT_SOURCE_TYPE &&
                          formik.errors.vT_SOURCE_TYPE
                        }
                        fullWidth
                        inputProps={{ MenuProps }}
                      >
                        <MenuItem value={"NA"}>- - - Select - - -</MenuItem>
                        <MenuItem value={"D3"}>D3 Plant Tag</MenuItem>
                        <MenuItem value={"LIMDA"}>LIMDA Server Tag</MenuItem>
                        <MenuItem value={"Email"}>Email Input Source</MenuItem>
                        <MenuItem value={"FTP"}>FTP Input Source</MenuItem>
                      </TextField>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <Grid
                        container
                        columnSpacing={{ xs: 1, sm: 1, md: 4, lg: 4 }}
                      >
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                          <TextField
                            name="vT_SOURCE_TAG"
                            label="Tag Number"
                            fullWidth
                            value={formik.values.vT_SOURCE_TAG}
                            onChange={formik.handleChange}
                            error={
                              formik.touched.vT_SOURCE_TAG &&
                              Boolean(formik.errors.vT_SOURCE_TAG)
                            }
                            helperText={
                              formik.touched.vT_SOURCE_TAG &&
                              formik.errors.vT_SOURCE_TAG
                            }
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                          <TextField
                            name="vT_SOURCE_NODE"
                            label="Server Name"
                            fullWidth
                            value={formik.values.vT_SOURCE_NODE}
                            onChange={formik.handleChange}
                            error={
                              formik.touched.vT_SOURCE_NODE &&
                              Boolean(formik.errors.vT_SOURCE_NODE)
                            }
                            helperText={
                              formik.touched.vT_SOURCE_NODE &&
                              formik.errors.vT_SOURCE_NODE
                            }
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <InputLabel sx={{ mt: 1 }}>Temperature Reader</InputLabel>
                      <FormControl>
                        <RadioGroup
                          row
                          value={formik.values.vT_TEMP_READER}
                          onChange={RadioTemperature}
                        >
                          <FormControlLabel
                            value="true"
                            control={<Radio />}
                            label="Yes"
                          />
                          <FormControlLabel
                            value="false"
                            control={<Radio />}
                            label="No"
                          />
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <Grid
                        container
                        columnSpacing={{ xs: 1, sm: 1, md: 4, lg: 4 }}
                      >
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                          <TextField
                            name="vT_TEMP_RANGE_MIN"
                            label="Min Temp"
                            fullWidth
                            value={formik.values.vT_TEMP_RANGE_MIN}
                            onChange={formik.handleChange}
                            error={
                              formik.touched.vT_TEMP_RANGE_MIN &&
                              Boolean(formik.errors.vT_TEMP_RANGE_MIN)
                            }
                            helperText={
                              formik.touched.vT_TEMP_RANGE_MIN &&
                              formik.errors.vT_TEMP_RANGE_MIN
                            }
                            inputProps={{ maxLength: 8 }}
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                          <TextField
                            name="vT_TEMP_RANGE_MAX"
                            label="Max Temp"
                            fullWidth
                            value={formik.values.vT_TEMP_RANGE_MAX}
                            onChange={formik.handleChange}
                            error={
                              formik.touched.vT_TEMP_RANGE_MAX &&
                              Boolean(formik.errors.vT_TEMP_RANGE_MAX)
                            }
                            helperText={
                              formik.touched.vT_TEMP_RANGE_MAX &&
                              formik.errors.vT_TEMP_RANGE_MAX
                            }
                            inputProps={{ maxLength: 8 }}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Card sx={{ borderRadius: 1, mt: 0 }} elevation={3}>
                <CustomCardHeader title="Sensor Setting Information"></CustomCardHeader>
                <CardContent>
                  <Grid
                    container
                    columnSpacing={{ xs: 1, sm: 1, md: 4, lg: 4 }}
                  // rowSpacing={0.7}
                  >
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <TextField
                        id="Probe Type"
                        name="vT_PROBE_TYPE"
                        select
                        label="Probe Type"
                        value={formik.values.vT_PROBE_TYPE}
                        onChange={onProbeTypeChange}
                        error={
                          formik.touched.vT_PROBE_TYPE &&
                          Boolean(formik.errors.vT_PROBE_TYPE)
                        }
                        helperText={
                          formik.touched.vT_PROBE_TYPE &&
                          formik.errors.vT_PROBE_TYPE
                        }
                        fullWidth
                        inputProps={{ MenuProps }}
                      >
                        <MenuItem value="U">Ultrasonic</MenuItem>
                        <MenuItem value="P">Pressure Sensor</MenuItem>
                      </TextField>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6}></Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <TextField
                        id="Probe Comm. Type"
                        name="vT_PROBE_COMM_TYPE"
                        select
                        label="Probe Comm. Type"
                        value={formik.values.vT_PROBE_COMM_TYPE}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.vT_PROBE_COMM_TYPE &&
                          Boolean(formik.errors.vT_PROBE_COMM_TYPE)
                        }
                        helperText={
                          formik.touched.vT_PROBE_COMM_TYPE &&
                          formik.errors.vT_PROBE_COMM_TYPE
                        }
                        fullWidth
                        inputProps={{ MenuProps }}
                      >
                        <MenuItem value="D">Digital</MenuItem>
                        <MenuItem value="A">Analog</MenuItem>
                      </TextField>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} sx={{ mt: 0.4 }}>
                      <TextField
                        name="vT_PROBE_MODEL"
                        label="Probe Model"
                        fullWidth
                        value={formik.values.vT_PROBE_MODEL}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.vT_PROBE_MODEL &&
                          Boolean(formik.errors.vT_PROBE_MODEL)
                        }
                        helperText={
                          formik.touched.vT_PROBE_MODEL &&
                          formik.errors.vT_PROBE_MODEL
                        }
                        inputProps={{ maxLength: 15 }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} sx={{ mt: 0.4 }}>
                      <TextField
                        name="vT_PROBE_MFG"
                        label="Probe Manufacture"
                        fullWidth
                        value={formik.values.vT_PROBE_MFG}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.vT_PROBE_MFG &&
                          Boolean(formik.errors.vT_PROBE_MFG)
                        }
                        helperText={
                          formik.touched.vT_PROBE_MFG &&
                          formik.errors.vT_PROBE_MFG
                        }
                        inputProps={{ maxLength: 15 }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} sx={{ mt: 0.4 }}>
                      <TextField
                        name="vT_PROBE_BLANKING"
                        label="Probe Blanking"
                        fullWidth
                        value={formik.values.vT_PROBE_BLANKING}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.vT_PROBE_BLANKING &&
                          Boolean(formik.errors.vT_PROBE_BLANKING)
                        }
                        helperText={
                          formik.touched.vT_PROBE_BLANKING &&
                          formik.errors.vT_PROBE_BLANKING
                        }
                        inputProps={{ maxLength: 15 }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} sx={{ mt: 0.4 }}>
                      <TextField
                        name="vT_PROBE_4MA_SET"
                        label="Probe 4ma Setting"
                        fullWidth
                        value={formik.values.vT_PROBE_4MA_SET}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.vT_PROBE_4MA_SET &&
                          Boolean(formik.errors.vT_PROBE_4MA_SET)
                        }
                        helperText={
                          formik.touched.vT_PROBE_4MA_SET &&
                          formik.errors.vT_PROBE_4MA_SET
                        }
                        inputProps={{ maxLength: 15 }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} sx={{ mt: 0.4 }}>
                      <TextField
                        name="vT_PROBE_20MA_SET"
                        label="Probe 20ma Setting"
                        fullWidth
                        value={formik.values.vT_PROBE_20MA_SET}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.vT_PROBE_20MA_SET &&
                          Boolean(formik.errors.vT_PROBE_20MA_SET)
                        }
                        helperText={
                          formik.touched.vT_PROBE_20MA_SET &&
                          formik.errors.vT_PROBE_20MA_SET
                        }
                        inputProps={{ maxLength: 15 }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} sx={{ mt: 0.4 }}>
                      <TextField
                        name="vT_PROBE_IND_SPAN_LOW"
                        label="Probe Indicator Span Low(ft):"
                        fullWidth
                        value={formik.values.vT_PROBE_IND_SPAN_LOW}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.vT_PROBE_IND_SPAN_LOW &&
                          Boolean(formik.errors.vT_PROBE_IND_SPAN_LOW)
                        }
                        helperText={
                          formik.touched.vT_PROBE_IND_SPAN_LOW &&
                          formik.errors.vT_PROBE_IND_SPAN_LOW
                        }
                        inputProps={{ maxLength: 15 }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} sx={{ mt: 0.4 }}>
                      <TextField
                        name="vT_PROBE_IND_SPAN_HIGH"
                        label="Probe Indicator Span High(ft)"
                        fullWidth
                        value={formik.values.vT_PROBE_IND_SPAN_HIGH}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.vT_PROBE_IND_SPAN_HIGH &&
                          Boolean(formik.errors.vT_PROBE_IND_SPAN_HIGH)
                        }
                        helperText={
                          formik.touched.vT_PROBE_IND_SPAN_HIGH &&
                          formik.errors.vT_PROBE_IND_SPAN_HIGH
                        }
                        inputProps={{ maxLength: 15 }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} sx={{ mt: 0.4 }}>
                      <FormControl>
                        <RadioGroup
                          row
                          value={formik.values.radioPSIValue}
                          onChange={RadioPSI}
                          name="radioPSIValue"
                        >
                          <FormControlLabel
                            value="true"
                            control={<Radio />}
                            label="Percentage"
                          />

                          <FormControlLabel
                            value="false"
                            control={<Radio />}
                            label="Ratio"
                          />
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <TextField
                        name="vT_PROBE_PSI_MAX"
                        label="Sensor Max(PSI)"
                        fullWidth
                        value={formik.values.vT_PROBE_PSI_MAX}
                        onChange={formik.handleChange}
                        onBlur={SensorMaxChange}
                        error={
                          formik.touched.vT_PROBE_PSI_MAX &&
                          Boolean(formik.errors.vT_PROBE_PSI_MAX)
                        }
                        helperText={
                          formik.touched.vT_PROBE_PSI_MAX &&
                          formik.errors.vT_PROBE_PSI_MAX
                        }
                        disabled={disablePSI}
                        inputProps={{ maxLength: 10 }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <TextField
                        name="vT_TANK_PSI_MAX"
                        label="Tank Max(PSI): "
                        fullWidth
                        value={formik.values.vT_TANK_PSI_MAX}
                        onChange={formik.handleChange}
                        onBlur={TankMaxChange}
                        error={
                          formik.touched.vT_TANK_PSI_MAX &&
                          Boolean(formik.errors.vT_TANK_PSI_MAX)
                        }
                        helperText={
                          formik.touched.vT_TANK_PSI_MAX &&
                          formik.errors.vT_TANK_PSI_MAX
                        }
                        disabled={disablePSI}
                        inputProps={{ maxLength: 10 }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <Link
                        href={"http://www.onlineconversion.com/pressure.htm"}
                        target="_blank"
                      >
                        Conversion Tool <OpenInNewIcon fontSize="inherit" />
                      </Link>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <TextField
                        name="vT_TANK_MA_MAX"
                        label="Tank Max(mA): "
                        fullWidth
                        value={formik.values.vT_TANK_MA_MAX}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.vT_TANK_MA_MAX &&
                          Boolean(formik.errors.vT_TANK_MA_MAX)
                        }
                        helperText={
                          formik.touched.vT_TANK_MA_MAX &&
                          formik.errors.vT_TANK_MA_MAX
                        }
                        // disabled={disablePSI}
                        inputProps={{ maxLength: 10 }}
                      />
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} lg={6}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          label="Probe Calibration Date"
                          value={
                            dayjs(formik.values.vT_PROBE_CAL_DATE).isValid()
                              ? dayjs(formik.values.vT_PROBE_CAL_DATE)
                              : null
                          }
                          onChange={(newValue) => {
                            if (newValue !== null && newValue.isValid()) {
                              formik.setFieldValue(
                                "vT_PROBE_CAL_DATE",
                                dayjs(newValue).format("MM/DD/YYYY")
                              );
                            } else {
                              formik.setFieldValue("vT_PROBE_CAL_DATE", null);
                            }
                          }}
                          slotProps={{
                            textField: {
                              variant: "standard",
                              error:
                                formik.touched.vT_PROBE_CAL_DATE &&
                                Boolean(formik.errors.vT_PROBE_CAL_DATE),
                              helperText:
                                formik.touched.vT_PROBE_CAL_DATE &&
                                formik.errors.vT_PROBE_CAL_DATE,
                            },
                          }}
                        />
                      </LocalizationProvider>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} lg={6}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          label="Probe Install Date"
                          value={
                            dayjs(formik.values.vT_PROBE_INST_DATE).isValid()
                              ? dayjs(formik.values.vT_PROBE_INST_DATE)
                              : null
                          }
                          onChange={(newValue) => {
                            if (newValue !== null && newValue.isValid()) {
                              formik.setFieldValue(
                                "vT_PROBE_INST_DATE",
                                dayjs(newValue).format("MM/DD/YYYY")
                              );
                            } else {
                              formik.setFieldValue("vT_PROBE_INST_DATE", null);
                            }
                          }}
                          slotProps={{
                            textField: {
                              variant: "standard",
                              error:
                                formik.touched.vT_PROBE_INST_DATE &&
                                Boolean(formik.errors.vT_PROBE_INST_DATE),
                              helperText:
                                formik.touched.vT_PROBE_INST_DATE &&
                                formik.errors.vT_PROBE_INST_DATE,
                            },
                          }}
                        />
                      </LocalizationProvider>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </form>
      </Container>
      <Divider sx={{ mt: 4, mb: 2 }}></Divider>
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        sx={{
          justifyContent: "flex-end",
          alignItems: "flex-end",
          display: "flex",
        }}
      >
        {tankID === 0 && (
          <Button onClick={formik.handleReset} sx={{ mr: 1 }}>
            Reset
          </Button>
        )}
        <Button
          form="form1"
          type="submit"
          onSubmit={(e) => formik.handleSubmit}
        >
          {tankID !== 0 ? "Update" : "Submit"}
        </Button>
      </Grid>
    </React.Fragment>
  );
}

export default AddEditTank;
