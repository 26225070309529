import SystemMessage from "./SystemMessage";
import OrderSchedule from "../components/app/Order/OrderSchedule";

function OrderSchedulePage() {
  const role = sessionStorage.getItem("roles");
  return (
    role?.toString().toUpperCase() === "ADMIN" ?
      <OrderSchedule></OrderSchedule> : <SystemMessage msg="Access Denied." colorMsg="Red"></SystemMessage>
  );
}

export default OrderSchedulePage