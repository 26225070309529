//import { AuthenticationActions, AuthenticationState } from "react-aad-msal";
import { createSlice } from '@reduxjs/toolkit';
import { IADAuthState } from "../interfaces/storeInterfaces";

//let defaultAdAuthList:AdAuth;
const initialState = {  
  state: 'Unauthenticated',  
  account: {
    accountIdentifier:'',
    environment:'', 
    name:'',
    userName:'',
    jwtIdToken:''
  }
} as IADAuthState;

const adAuthSlice = createSlice({
  name: 'adAuth',
  initialState,
  reducers: { 
    setAccount(state, action) {
      state.account = action.payload
    }, 
    setAuthenticatedState(state, action) {
      state.state = action.payload
    }    
  }
});

export const adAuthActions = adAuthSlice.actions;

export default adAuthSlice;
