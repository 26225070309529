/* eslint-disable import/no-anonymous-default-export */
import useAxiosGet from "../hooks/useAxiosGet";
import useAxiosPost from "../hooks/useAxiosPost";
import useAxiosDelete from "../hooks/useAxiosDelete";
import { Customer, CustomerList } from "../models/customer";
import { LocationInfo } from "../models/location";
import { ProductInventory } from "../models/product";

const isLocal = () => (process.env.REACT_APP_ENVIR === 'LOCAL');
const userId = () => sessionStorage.getItem("userMailID");
const date=new Date().toJSON().slice(0,10);

const GetDefaultCustomer = (): Customer => {
  const defaultCustomer = {
    customerID: 0,
    customerNo: "",
    customerName: "",
    vC_CUSTOMER_CONTACT: "",
    vC_CUSTOMER_PHONE: "",
    vC_CUSTOMER_EMAIL: "",
    vC_COA_REQUEST_GROUP: "",
  } as Customer;

  return defaultCustomer;
};

const GetCustomers = () => {
  return useAxiosGet<Customer[]>( isLocal() ?`/Customer/GetCustomers/users/${userId()}/customers` :`/users/${userId()}/customers`, [], "Customer list");
};

const GetCustomerByNumber = (customerNo:string)=> {
  return useAxiosGet<Customer>(isLocal() ?`/Customer/GetCustomerByNumber/users/${userId()}/customers/${customerNo}`:`/users/${userId()}/customers/${customerNo}`, GetDefaultCustomer() , "Customer Information");
};

const CreateCustomer = (data:Customer) => {
  return useAxiosPost(isLocal() ?`/Customer/CreateCustomer/users/${userId()}/customers`:`/users/${userId()}/customers`, data, "Customer Maintainance - Add Customer");
};

const UpdateCustomerByNumber = (data:Customer) => {
  return useAxiosPost(isLocal() ?`/Customer/UpdateCustomerByNumber/users/${userId()}/customer/${data.customerNo}`:`/users/${userId()}/customer/${data.customerNo}`, data, "Customer Maintainance - Update Customer");
};

const DeleteCustomerByNumber = (customerNo:string) => {
  return useAxiosDelete(isLocal() ?`/Customer/DeleteCustomerByNumber/users/${userId()}/customers/${customerNo}`:`/users/${userId()}/customers/${customerNo}`, customerNo, "Customer Maintainance - Delete Customer");
};

const GetCustomerLocations = (customerNo: string, locationNo?: string) => {
  let optionalParameters="";
  if(locationNo!=="" &&locationNo!==null && locationNo!==undefined){
    optionalParameters=`?locationNo=${locationNo}`
  }
  return useAxiosGet<LocationInfo[]>(isLocal()?`/Customer/GetCustomerLocations/users/${userId()}/customers/${customerNo}/locations${optionalParameters}`:`/users/${userId()}/customers/${customerNo}/locations${optionalParameters}`, [], "Location list");
};

const GetProductInventories = (customerNo:string) => {
  return useAxiosGet<ProductInventory[]>(isLocal()?`/Customer/GetProductInventories/users/${userId()}/customers/${customerNo}/inventories`:`/users/${userId()}/customers/${customerNo}/inventories`, [], "Current Inventories");
};

const GetSumIntransitInv = (customerNo: string, locationNo?: string, productCode?: string) => {
  let optionalParameters="";
  if (locationNo !== "" && locationNo !== null && locationNo !== undefined) {
    optionalParameters = `locationNo=${locationNo}&`;
  }
  if (productCode !== "" && productCode !== null && productCode !== undefined) {
    optionalParameters += `productCode=${productCode}`;
  }
  return useAxiosGet<ProductInventory[]>(isLocal()?`/Customer/GetSumIntransitInv/users/${userId()}/customers/${customerNo}/products?${optionalParameters}`:`/users/${userId()}/customers/${customerNo}/products?${optionalParameters}`,[], "In-Transit Inventories");
};

const RetrieveSumIntransitInvByLocation = (customerNo: string, locationNo: string) => {
  return useAxiosGet<ProductInventory[]>(isLocal()?`/Customer/RetrieveSumIntransitInvByLocation/users/${userId()}/customers/${customerNo}/locationNo/${locationNo}/locationproducts?date=${date}`:`/users/${userId()}/customers/${customerNo}/locationNo/${locationNo}/locationproducts`,[], "In-Transit Inventories");
};

const RetrieveAllCustomers = () => {
  return useAxiosGet<CustomerList[]>(isLocal() ? `/Customer/RetrieveAllCustomers/customers` : `/customers`, [], "All Customers");
};

const RetrieveUserCustomers = (userEmailID: string) => {
  return useAxiosGet<Customer[]>(isLocal() ? `/Customer/RetrieveUserCustomers/users/${userEmailID}/usercustomers` : `/users/${userEmailID}/usercustomers`, [], "Customers Assigned to User");
};


export default { 
  GetCustomerByNumber,
  GetDefaultCustomer,
  GetCustomers,
  CreateCustomer,
  UpdateCustomerByNumber,
  DeleteCustomerByNumber,
  GetCustomerLocations,
  GetProductInventories,
  GetSumIntransitInv,
  RetrieveSumIntransitInvByLocation,
  RetrieveAllCustomers,
  RetrieveUserCustomers
};
