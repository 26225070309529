import React from "react";
import PageHeader from "../../shared/PageHeader";
import { Button, Card, CardContent, IconButton } from "@mui/material";
import CustomCardHeader from "../../shared/CustomCardHeader";
import AddSharpIcon from "@mui/icons-material/AddSharp";
import TableBody from "@mui/material/TableBody";
import useTable from "../../../hooks/useTable";
import { TableHeaderCell } from "../../../models/common";
import ProductService from "../../../services/productService";
import { Product } from "../../../models/product";
import { StyledTableCell, StyledTableRow } from "../../controls/tablecontrols";
import ModeEditSharpIcon from "@mui/icons-material/ModeEditSharp";
import ModalPopup from "../../shared/ModalPopup";
import AddEditProduct from "./AddEditProduct"
import { useAppDispatch } from "../../../store/store";
import { uiActions } from "../../../store/ui-slice";

const ProductMaintenance = () => {

  const dispatch = useAppDispatch();
  const { fetchData: fetchProductData } = ProductService.RetrieveProducts();
  const [productlist, setProductlist] = React.useState<Product[]>([]);
  const [showModalPopup, setShowModalPopup] = React.useState<boolean>(false);
  const [isRefreshList, setRefreshList] = React.useState<boolean>(true);
  const [dataAsProp, setDataAsDrop] = React.useState<Product>()

  React.useEffect(() => {
    if (isRefreshList) {
      dispatch(uiActions.startProgressBar());
      fetchProductData().then((res) => {
        setProductlist(res.responseData)
        setRefreshList(false);
      })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRefreshList])

  const onModalClose = (e: React.MouseEvent<HTMLButtonElement>) => {
    Promise.resolve().then(() => {
      setDataAsDrop(ProductService.GetDefaultProduct());
      setShowModalPopup(false);
    });
  };

  const onAddNewClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    Promise.resolve().then(() => {
      setDataAsDrop(ProductService.GetDefaultProduct());
      setShowModalPopup(true);
    });
  };

  const handleEdit = (productData: Product) => {
    Promise.resolve().then(() => {
      setDataAsDrop(productData);
      setShowModalPopup(true);
    });
  };

  const tableHeaderCellDetails: TableHeaderCell[] = [
    { id: "vP_PRODUCT_DESC", label: "Product" },
    { id: "vP_PRODUCT_CODE", label: "Code" },
    { id: "edit", label: "Edit", disableSorting: true },
  ];

  const { Tbl, GetRowsAfterPagingAndSorting } = useTable<Product>(
    productlist,
    tableHeaderCellDetails,
    true,
    10
  );

  return (
    <>
      <PageHeader
        title="Product Maintenance"
        subtitle="Manage products"
      ></PageHeader>
      {
        <Card
          sx={{ width: "100hv", borderRadius: 3, margin: 2, mt: 0 }}
          elevation={6}
        >
          <CustomCardHeader
            title="Product List"
            action={
              <Button onClick={onAddNewClick} startIcon={<AddSharpIcon />}>
                Add New
              </Button>
            }
          ></CustomCardHeader>
          <CardContent sx={{ pb: 0 }}>
            <Tbl>
              <TableBody>
                {productlist &&
                  GetRowsAfterPagingAndSorting().map((row, index) => (
                    <StyledTableRow key={index}>
                      <StyledTableCell scope="row">
                        {row.vP_PRODUCT_DESC}
                      </StyledTableCell>
                      <StyledTableCell scope="row">
                        {row.vP_PRODUCT_CODE}
                      </StyledTableCell>
                      <StyledTableCell scope="row">
                        {/* <Tooltip title="Edit"> */}
                        <IconButton
                          aria-label="Edit"
                          size="small"
                          sx={{ paddingLeft: 1, paddingRight: 1 }}
                          onClick={(e) => handleEdit(row)}
                        >
                          <ModeEditSharpIcon fontSize="small" />
                        </IconButton>
                        {/* </Tooltip> */}
                      </StyledTableCell>
                    </StyledTableRow>
                  ))
                }
              </TableBody>
            </Tbl>
          </CardContent>
        </Card>
      }
      <ModalPopup
        open={showModalPopup}
        title={dataAsProp?.vP_PRODUCT_ID !== 0 ? "Edit Product" : "Add Product"}
        onPopupClose={onModalClose}
      >{dataAsProp && <AddEditProduct
        setShowModalPopup={setShowModalPopup}
        productData={dataAsProp}
        setRefreshList={setRefreshList}
      />}
      </ModalPopup>
    </>
  )
}
export default ProductMaintenance;