/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Card, CardContent, Divider, Grid, TableBody } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useReactToPrint } from "react-to-print";
import PrintIcon from "@mui/icons-material/Print";

// Maintain States
import { IFilterBandState } from "../../../interfaces/storeInterfaces";
import { useSelector } from "react-redux";
import { filterBandSelector } from "../../../store/store";

// Services
import ShipmentDataService from "../../../services/shipmentService";
import ProductDataService from "../../../services/productService";

import moment from "moment";
import ProductInventoriesTable from "./ProductInventories";
import { TableHeaderCell } from "../../../models/common";
import useTable from "../../../hooks/useTable";
import { StyledTableCell, StyledTableRow } from "../../controls/tablecontrols";

interface PrintInventoryProps {
    productId: string;
    setShowModalPopup: React.Dispatch<React.SetStateAction<boolean>>;
    showPrintInventory: boolean;
}

function PrintInventory(props: PrintInventoryProps) {
    const componentRef = useRef(null);
    const filterBandState: IFilterBandState = useSelector(filterBandSelector);
    const [screenTitle, setScreenTitle] = useState<string>('');
    const [dateTime, setDateTime] = useState<string>('');
    const [shipmentCount, setShipmentCount] = useState<any>([]);
    const [shipmentCountEmpty, setShipmentCountEmpty] = useState<any>([]);
    const [grandTotal, setGrandTotal] = useState<number>(0);
    const [shipmentCountForAll, setShipmentCountForAll] = useState<any>([]);

    // Function creating table row
    function createData(
        IsRow: boolean,
        State: string,
        CountTotal: string
    ) {
        return { IsRow, State, CountTotal };
    }
    const tableHeaderCellDetails: TableHeaderCell[] = [
        { id: "rS_CURRENT_CLM_STATE", label: "Loaded" },
        { id: "countTotal", label: "" },
    ];
    const { Tbl } = useTable<any>(
        shipmentCount,
        tableHeaderCellDetails,
        false
    );

    // Function Creating table row
    function createDataForEmpty(
        IsRow: boolean,
        State: string,
        CountTotal: number
    ) {
        return { IsRow, State, CountTotal };
    }
    const tableHeaderCellDetailsForEmpty: TableHeaderCell[] = [
        { id: "state", label: "Empties" },
        { id: "countTotal", label: "" }
    ];
    const { Tbl: TblEmpty } = useTable<any>(
        shipmentCountEmpty,
        tableHeaderCellDetailsForEmpty,
        false
    );

    // Function Creating table row
    function createDataForAll(
        IsRow: boolean,
        State: string,
        CountTotal: number
    ) {
        return { IsRow, State, CountTotal };
    }
    const tableHeaderCellDetailsForAll: TableHeaderCell[] = [
        { id: "state", label: "Loaded For" },
        { id: "countTotal", label: "" }
    ];
    const { Tbl: TblForAll } = useTable<any>(
        shipmentCountForAll,
        tableHeaderCellDetailsForAll,
        false
    );

    const { fetchData: fetchProductData } = ShipmentDataService.RetrieveProductsByLocation(
        filterBandState.selectedCustomerNo,
        filterBandState.selectedLocationNo
    );

    const { fetchData: fetchShipmentCountNonUs } = ProductDataService.RetrieveShipmentCountNonUs(
        filterBandState.selectedLocationNo
    );

    const { fetchData: fetchShipmentCountLoadedNonUs } = ProductDataService.RetrieveShipmentCountLoadedNonUs(
        filterBandState.selectedCustomerNo
    );

    const { fetchData: fetchShipmentCountLoaded } = ProductDataService.RetrieveShipmentCountLoaded(
        filterBandState.selectedLocationNo,
        filterBandState.selectedProductID
    );

    const { fetchData: fetchShipmentDecaturCountLoaded } = ProductDataService.RetrieveShipmentCountLoadedDecatur(
        filterBandState.selectedCustomerNo,
        filterBandState.selectedProductID
    );

    const { fetchData: fetchShipmentCountLoadedCarrier } = ProductDataService.RetrieveShipmentCountLoadedCarrier(
        filterBandState.selectedLocationNo === '' ? filterBandState.selectedCustomerNo : filterBandState.selectedLocationNo,
        filterBandState.selectedProductID
    );

    const { fetchData: fetchShipmentCountLoadedAll } = ProductDataService.RetrieveShipmentCountLoadedAll(
        filterBandState.selectedCustomerNo,
        filterBandState.selectedProductID
    );

    const { fetchData: fetchShipmentCountEmpty } = ProductDataService.RetrieveShipmentCountEmpty(
        props.productId
    );

    const { fetchData: fetchShipmentCountDecaturEmpty } = ProductDataService.RetrieveShipmentCountEmptyDecatur(
        props.productId
    );

    const { fetchData: fetchShipmentCountEmptyAtShop } = ProductDataService.RetrieveShipmentCountEmptyAtShop();

    const { fetchData: fetchShipmentCountLoadedForAll } = ProductDataService.RetrieveShipmentCountLoadedForAll(
        filterBandState.selectedLocationNo,
        props.productId
    );

    const { fetchData: fetchShipmentCountLoadedForAllCustomer } = ProductDataService.RetrieveShipmentCountLoadedForAllCustomer(
        filterBandState.selectedCustomerNo,
        props.productId
    );

    const { fetchData: fetchShipmentCountLoadedForAllOL } = ProductDataService.RetrieveShipmentCountLoadedForAllOL();

    const screenStyle = {
        heading: {
            'text-align': 'center',
            color: 'darkblue',
            'font-weight': 'bold'
        },
        headingMessage: {
            'text-align': 'center',
            color: 'darkblue'
        },
    };

    useEffect(() => {
        if (props.showPrintInventory === true) {
            loadProduct();
        }
    }, [props.showPrintInventory]);


    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    const loadProduct = () => {
        fetchProductData().then((res) => {
            if (filterBandState.selectedLocationNo !== '') {
                let locationName = filterBandState.locations.filter(x => x.locationNo === filterBandState.selectedLocationNo);
                setScreenTitle(locationName[0].locationName + ' ' + res.responseData[0].vP_PRODUCT_DESC + ' Inventory');
            } else {
                if (filterBandState.selectedCustomerNo !== '') {
                    let customerName = filterBandState.customers.filter(x => x.customerNo === filterBandState.selectedCustomerNo);
                    setScreenTitle(customerName[0].customerName + ' ' + res.responseData[0].vP_PRODUCT_DESC + ' Inventory');
                }
            }
            setDateTime(moment(new Date()).format("MM/DD/YYYY hh:mm:ss A"));
            loadDataSet();
        });
    };

    const loadDataSet = async () => {
        let chkNon = false;
        const row: any = [];
        const rowEmpty: any = [];
        const rowAll: any = [];
        let countTotal = 0;
        let countEmptyTotal = 0;
        let countAllTotal = 0;
        let grandTotal = 0;

        if (filterBandState.selectedLocationNo !== '') {
            let NonUsData = await fetchShipmentCountNonUs();
            if (NonUsData.isSuccess === true) {
                chkNon = NonUsData.responseData.vL_LOC_AS_SOLDTO;
            }
        }

        if (filterBandState.selectedLocationNo !== '') {
            if (filterBandState.selectedProductID === '') {
                if (chkNon === true) {
                    let shipmentCountLoadedNonUs = await fetchShipmentCountLoadedNonUs();
                    if (shipmentCountLoadedNonUs.isSuccess && shipmentCountLoadedNonUs.responseData.length > 0) {
                        for (let i = 0; i < shipmentCountLoadedNonUs.responseData.length; i++) {
                            row.push(
                                createData(
                                    true,
                                    'Loaded in ' + shipmentCountLoadedNonUs.responseData[i].rS_CURRENT_CLM_STATE,
                                    shipmentCountLoadedNonUs.responseData[i].countTotal
                                ));
                            countTotal = countTotal + shipmentCountLoadedNonUs.responseData[i].countTotal;
                        }
                    }
                } else {
                    let ShipmentCountLoaded = await fetchShipmentCountLoaded();
                    if (ShipmentCountLoaded.isSuccess && ShipmentCountLoaded.responseData.length > 0) {
                        for (let i = 0; i < ShipmentCountLoaded.responseData.length; i++) {
                            row.push(
                                createData(
                                    true,
                                    'Loaded in ' + ShipmentCountLoaded.responseData[i].rS_CURRENT_CLM_STATE,
                                    ShipmentCountLoaded.responseData[i].countTotal
                                ));
                            countTotal = countTotal + ShipmentCountLoaded.responseData[i].countTotal;
                        }
                    }
                }

                let shipmentDecaturCountLoaded = await fetchShipmentDecaturCountLoaded();
                if (shipmentDecaturCountLoaded.isSuccess && shipmentDecaturCountLoaded.responseData.length > 0) {
                    for (let i = 0; i < shipmentDecaturCountLoaded.responseData.length; i++) {
                        row.push(
                            createData(
                                true,
                                'Loaded in ' + shipmentDecaturCountLoaded.responseData[i].rS_CURRENT_CLM_CITY,
                                shipmentDecaturCountLoaded.responseData[i].countTotal
                            ));
                        countTotal = countTotal + shipmentDecaturCountLoaded.responseData[i].countTotal;
                    }
                }

                let shipmentCountLoadedCarrier = await fetchShipmentCountLoadedCarrier();
                if (shipmentCountLoadedCarrier.isSuccess && shipmentCountLoadedCarrier.responseData.length > 0) {
                    for (let i = 0; i < shipmentCountLoadedCarrier.responseData.length; i++) {
                        row.push(
                            createData(
                                true,
                                'Loaded in ' + shipmentCountLoadedCarrier.responseData[i].locationCity,
                                shipmentCountLoadedCarrier.responseData[i].countTotal + ' ' + shipmentCountLoadedCarrier.responseData[i].rS_CURRENT_CLM_CARRIER
                            ));
                        countTotal = countTotal + shipmentCountLoadedCarrier.responseData[i].countTotal;
                    }
                }
                grandTotal = grandTotal + countTotal;
                // setGrandTotal(grandTotal);
                row.push(
                    createData(
                        false,
                        'Total Loaded For CLINTON :',
                        countTotal.toString()
                    ));
                setShipmentCount(row);

            } else {
                if (chkNon === true) {
                    let shipmentCountLoadedNonUs = await fetchShipmentCountLoadedNonUs();
                    if (shipmentCountLoadedNonUs.isSuccess && shipmentCountLoadedNonUs.responseData.length > 0) {
                        for (let i = 0; i < shipmentCountLoadedNonUs.responseData.length; i++) {
                            row.push(
                                createData(
                                    true,
                                    'Loaded in ' + shipmentCountLoadedNonUs.responseData[i].rS_CURRENT_CLM_STATE,
                                    shipmentCountLoadedNonUs.responseData[i].countTotal
                                ));
                            countTotal = countTotal + shipmentCountLoadedNonUs.responseData[i].countTotal;
                        }
                    }
                } else {
                    let ShipmentCountLoaded = await fetchShipmentCountLoaded();
                    if (ShipmentCountLoaded.isSuccess && ShipmentCountLoaded.responseData.length > 0) {
                        for (let i = 0; i < ShipmentCountLoaded.responseData.length; i++) {
                            row.push(
                                createData(
                                    true,
                                    'Loaded in ' + ShipmentCountLoaded.responseData[i].rS_CURRENT_CLM_STATE,
                                    ShipmentCountLoaded.responseData[i].countTotal
                                ));
                            countTotal = countTotal + ShipmentCountLoaded.responseData[i].countTotal;
                        }
                    }
                }

                let shipmentDecaturCountLoaded = await fetchShipmentDecaturCountLoaded();
                if (shipmentDecaturCountLoaded.isSuccess && shipmentDecaturCountLoaded.responseData.length > 0) {
                    if (shipmentDecaturCountLoaded.isSuccess && shipmentDecaturCountLoaded.responseData.length > 0) {
                        for (let i = 0; i < shipmentDecaturCountLoaded.responseData.length; i++) {
                            row.push(
                                createData(
                                    true,
                                    'Loaded in ' + shipmentDecaturCountLoaded.responseData[i].rS_CURRENT_CLM_CITY,
                                    shipmentDecaturCountLoaded.responseData[i].countTotal
                                ));
                            countTotal = countTotal + shipmentDecaturCountLoaded.responseData[i].countTotal;
                        }
                    }
                }

                let shipmentCountLoadedCarrier = await fetchShipmentCountLoadedCarrier();
                if (shipmentCountLoadedCarrier.isSuccess && shipmentCountLoadedCarrier.responseData.length > 0) {
                    for (let i = 0; i < shipmentCountLoadedCarrier.responseData.length; i++) {
                        row.push(
                            createData(
                                true,
                                'Loaded in ' + shipmentCountLoadedCarrier.responseData[i].locationCity,
                                shipmentCountLoadedCarrier.responseData[i].countTotal + ' ' + shipmentCountLoadedCarrier.responseData[i].rS_CURRENT_CLM_CARRIER
                            ));
                        countTotal = countTotal + shipmentCountLoadedCarrier.responseData[i].countTotal;
                    }
                    grandTotal = grandTotal + countTotal;
                    // setGrandTotal(grandTotal);
                    row.push(
                        createData(
                            false,
                            'Total Loaded For CLINTON :',
                            countTotal.toString()
                        ));
                }
                setShipmentCount(row);
            }
        } else {
            if (filterBandState.selectedProductID === '') {
                if (chkNon === true) {
                    let shipmentCountLoadedNonUs = await fetchShipmentCountLoadedNonUs();
                    if (shipmentCountLoadedNonUs.isSuccess && shipmentCountLoadedNonUs.responseData.length > 0) {
                        for (let i = 0; i < shipmentCountLoadedNonUs.responseData.length; i++) {
                            row.push(
                                createData(
                                    true,
                                    'Loaded in ' + shipmentCountLoadedNonUs.responseData[i].rS_CURRENT_CLM_STATE,
                                    shipmentCountLoadedNonUs.responseData[i].countTotal
                                ));
                            countTotal = countTotal + shipmentCountLoadedNonUs.responseData[i].countTotal;
                        }
                    }
                } else {
                    let shipmentCountLoadedAll = await fetchShipmentCountLoadedAll();
                    if (shipmentCountLoadedAll.isSuccess && shipmentCountLoadedAll.responseData.length > 0) {
                        for (let i = 0; i < shipmentCountLoadedAll.responseData.length; i++) {
                            row.push(
                                createData(
                                    true,
                                    'Loaded in ' + shipmentCountLoadedAll.responseData[i].rS_CURRENT_CLM_STATE,
                                    shipmentCountLoadedAll.responseData[i].countTotal
                                ));
                            countTotal = countTotal + shipmentCountLoadedAll.responseData[i].countTotal;
                        }
                    }
                }

                let shipmentDecaturCountLoaded = await fetchShipmentDecaturCountLoaded();
                if (shipmentDecaturCountLoaded.isSuccess && shipmentDecaturCountLoaded.responseData.length > 0) {
                    if (shipmentDecaturCountLoaded.isSuccess && shipmentDecaturCountLoaded.responseData.length > 0) {
                        for (let i = 0; i < shipmentDecaturCountLoaded.responseData.length; i++) {
                            row.push(
                                createData(
                                    true,
                                    'Loaded in ' + shipmentDecaturCountLoaded.responseData[i].rS_CURRENT_CLM_CITY,
                                    shipmentDecaturCountLoaded.responseData[i].countTotal
                                ));
                            countTotal = countTotal + shipmentDecaturCountLoaded.responseData[i].countTotal;
                        }
                    }
                }

                let shipmentCountLoadedCarrier = await fetchShipmentCountLoadedCarrier();
                if (shipmentCountLoadedCarrier.isSuccess && shipmentCountLoadedCarrier.responseData.length > 0) {
                    for (let i = 0; i < shipmentCountLoadedCarrier.responseData.length; i++) {
                        row.push(
                            createData(
                                true,
                                'Loaded in ' + shipmentCountLoadedCarrier.responseData[i].locationCity,
                                shipmentCountLoadedCarrier.responseData[i].countTotal + ' ' + shipmentCountLoadedCarrier.responseData[i].rS_CURRENT_CLM_CARRIER
                            ));
                        countTotal = countTotal + shipmentCountLoadedCarrier.responseData[i].countTotal;
                    }
                    grandTotal = grandTotal + countTotal;
                    // setGrandTotal(grandTotal);
                    row.push(
                        createData(
                            false,
                            'Total Loaded For CLINTON :',
                            countTotal.toString()
                        ));
                }
                setShipmentCount(row);

            } else {
                if (chkNon === true) {
                    let shipmentCountLoadedNonUs = await fetchShipmentCountLoadedNonUs();
                    if (shipmentCountLoadedNonUs.isSuccess && shipmentCountLoadedNonUs.responseData.length > 0) {
                        for (let i = 0; i < shipmentCountLoadedNonUs.responseData.length; i++) {
                            row.push(
                                createData(
                                    true,
                                    'Loaded in ' + shipmentCountLoadedNonUs.responseData[i].rS_CURRENT_CLM_STATE,
                                    shipmentCountLoadedNonUs.responseData[i].countTotal
                                ));
                            countTotal = countTotal + shipmentCountLoadedNonUs.responseData[i].countTotal;
                        }
                    }
                } else {
                    let shipmentCountLoadedAll = await fetchShipmentCountLoadedAll();
                    if (shipmentCountLoadedAll.isSuccess && shipmentCountLoadedAll.responseData.length > 0) {
                        for (let i = 0; i < shipmentCountLoadedAll.responseData.length; i++) {
                            row.push(
                                createData(
                                    true,
                                    'Loaded in ' + shipmentCountLoadedAll.responseData[i].rS_CURRENT_CLM_STATE,
                                    shipmentCountLoadedAll.responseData[i].countTotal
                                ));
                            countTotal = countTotal + shipmentCountLoadedAll.responseData[i].countTotal;
                        }
                    }
                }

                let shipmentDecaturCountLoaded = await fetchShipmentDecaturCountLoaded();
                if (shipmentDecaturCountLoaded.isSuccess && shipmentDecaturCountLoaded.responseData.length > 0) {
                    if (shipmentDecaturCountLoaded.isSuccess && shipmentDecaturCountLoaded.responseData.length > 0) {
                        for (let i = 0; i < shipmentDecaturCountLoaded.responseData.length; i++) {
                            row.push(
                                createData(
                                    true,
                                    'Loaded in ' + shipmentDecaturCountLoaded.responseData[i].rS_CURRENT_CLM_CITY,
                                    shipmentDecaturCountLoaded.responseData[i].countTotal
                                ));
                            countTotal = countTotal + shipmentDecaturCountLoaded.responseData[i].countTotal;
                        }
                    }
                }

                let shipmentCountLoadedCarrier = await fetchShipmentCountLoadedCarrier();
                if (shipmentCountLoadedCarrier.isSuccess && shipmentCountLoadedCarrier.responseData.length > 0) {
                    for (let i = 0; i < shipmentCountLoadedCarrier.responseData.length; i++) {
                        row.push(
                            createData(
                                true,
                                'Loaded in ' + shipmentCountLoadedCarrier.responseData[i].locationCity,
                                shipmentCountLoadedCarrier.responseData[i].countTotal + ' ' + shipmentCountLoadedCarrier.responseData[i].rS_CURRENT_CLM_CARRIER
                            ));
                        countTotal = countTotal + shipmentCountLoadedCarrier.responseData[i].countTotal;
                    }
                    grandTotal = grandTotal + countTotal;
                    // setGrandTotal(grandTotal);
                    row.push(
                        createData(
                            false,
                            'Total Loaded For CLINTON :',
                            countTotal.toString()
                        ));
                }
                setShipmentCount(row);
            }
        }

        if (filterBandState.selectedLocationNo !== '') {
            if (props.productId === '') {
                let shipmentCountEmpty = await fetchShipmentCountEmpty();
                if (shipmentCountEmpty.isSuccess && shipmentCountEmpty.responseData.length > 0) {
                    for (let i = 0; i < shipmentCountEmpty.responseData.length; i++) {
                        rowEmpty.push(
                            createDataForEmpty(
                                true,
                                'Empties in ' + shipmentCountEmpty.responseData[i].state,
                                shipmentCountEmpty.responseData[i].countTotal
                            ));
                        countEmptyTotal = countEmptyTotal + shipmentCountEmpty.responseData[i].countTotal;
                    }
                }

                if (shipmentCountEmpty.responseData.length > 0) {
                    let shipmentCountDecaturEmpty = await fetchShipmentCountDecaturEmpty();
                    if (shipmentCountDecaturEmpty.isSuccess && shipmentCountDecaturEmpty.responseData.length > 0) {
                        for (let i = 0; i < shipmentCountDecaturEmpty.responseData.length; i++) {
                            rowEmpty.push(
                                createDataForEmpty(
                                    true,
                                    'Empties in ' + shipmentCountDecaturEmpty.responseData[i].city,
                                    shipmentCountDecaturEmpty.responseData[i].countTotal
                                ));
                            countEmptyTotal = countEmptyTotal + shipmentCountDecaturEmpty.responseData[i].countTotal;
                        }
                    }

                    let shipmentCountEmptyAtShop = await fetchShipmentCountEmptyAtShop();
                    if (shipmentCountEmptyAtShop.isSuccess && shipmentCountEmptyAtShop.responseData.length > 0) {
                        for (let i = 0; i < shipmentCountEmptyAtShop.responseData.length; i++) {
                            rowEmpty.push(
                                createDataForEmpty(
                                    true,
                                    'At Shop in ' + shipmentCountEmptyAtShop.responseData[i].city,
                                    shipmentCountEmptyAtShop.responseData[i].countTotal
                                ));
                            countEmptyTotal = countEmptyTotal + shipmentCountEmptyAtShop.responseData[i].countTotal;
                        }
                    }
                    if (props.productId !== '') {
                        grandTotal = grandTotal + countEmptyTotal;
                        setGrandTotal(grandTotal);
                    }
                    rowEmpty.push(
                        createDataForEmpty(
                            false,
                            'Empty Total :',
                            countEmptyTotal
                        ));
                }
                setShipmentCountEmpty(rowEmpty);
            } else {
                let shipmentCountEmpty = await fetchShipmentCountEmpty();
                if (shipmentCountEmpty.isSuccess && shipmentCountEmpty.responseData.length > 0) {
                    for (let i = 0; i < shipmentCountEmpty.responseData.length; i++) {
                        rowEmpty.push(
                            createDataForEmpty(
                                true,
                                'Empties in ' + shipmentCountEmpty.responseData[i].state,
                                shipmentCountEmpty.responseData[i].countTotal
                            ));
                        countEmptyTotal = countEmptyTotal + shipmentCountEmpty.responseData[i].countTotal;
                    }

                }

                if (shipmentCountEmpty.responseData.length > 0) {
                    let shipmentCountDecaturEmpty = await fetchShipmentCountDecaturEmpty();
                    if (shipmentCountDecaturEmpty.isSuccess && shipmentCountDecaturEmpty.responseData.length > 0) {
                        for (let i = 0; i < shipmentCountDecaturEmpty.responseData.length; i++) {
                            rowEmpty.push(
                                createDataForEmpty(
                                    true,
                                    'Empties in ' + shipmentCountDecaturEmpty.responseData[i].city,
                                    shipmentCountDecaturEmpty.responseData[i].countTotal
                                ));
                            countEmptyTotal = countEmptyTotal + shipmentCountDecaturEmpty.responseData[i].countTotal;
                        }
                    }

                    let shipmentCountEmptyAtShop = await fetchShipmentCountEmptyAtShop();
                    if (shipmentCountEmptyAtShop.isSuccess && shipmentCountEmptyAtShop.responseData.length > 0) {
                        for (let i = 0; i < shipmentCountEmptyAtShop.responseData.length; i++) {
                            rowEmpty.push(
                                createDataForEmpty(
                                    true,
                                    'At Shop in ' + shipmentCountEmptyAtShop.responseData[i].city,
                                    shipmentCountEmptyAtShop.responseData[i].countTotal
                                ));
                            countEmptyTotal = countEmptyTotal + shipmentCountEmptyAtShop.responseData[i].countTotal;
                        }
                    }


                    if (props.productId !== '') {
                        grandTotal = grandTotal + countEmptyTotal;
                        setGrandTotal(grandTotal);
                    }
                    rowEmpty.push(
                        createDataForEmpty(
                            false,
                            'Empty Total :',
                            countEmptyTotal
                        ));
                }
                setShipmentCountEmpty(rowEmpty);
            }
        } else {
            if (props.productId === '') {
                let shipmentCountEmpty = await fetchShipmentCountEmpty();
                if (shipmentCountEmpty.isSuccess && shipmentCountEmpty.responseData.length > 0) {
                    for (let i = 0; i < shipmentCountEmpty.responseData.length; i++) {
                        rowEmpty.push(
                            createDataForEmpty(
                                true,
                                'Empties in ' + shipmentCountEmpty.responseData[i].state,
                                shipmentCountEmpty.responseData[i].countTotal
                            ));
                        countEmptyTotal = countEmptyTotal + shipmentCountEmpty.responseData[i].countTotal;
                    }
                }

                let shipmentCountDecaturEmpty = await fetchShipmentCountDecaturEmpty();
                if (shipmentCountDecaturEmpty.isSuccess && shipmentCountDecaturEmpty.responseData.length > 0) {
                    for (let i = 0; i < shipmentCountDecaturEmpty.responseData.length; i++) {
                        rowEmpty.push(
                            createDataForEmpty(
                                true,
                                'Empties in ' + shipmentCountDecaturEmpty.responseData[i].city,
                                shipmentCountDecaturEmpty.responseData[i].countTotal
                            ));
                        countEmptyTotal = countEmptyTotal + shipmentCountDecaturEmpty.responseData[i].countTotal;
                    }
                }

                let shipmentCountEmptyAtShop = await fetchShipmentCountEmptyAtShop();
                if (shipmentCountEmptyAtShop.isSuccess && shipmentCountEmptyAtShop.responseData.length > 0) {
                    for (let i = 0; i < shipmentCountEmptyAtShop.responseData.length; i++) {
                        rowEmpty.push(
                            createDataForEmpty(
                                true,
                                'At Shop in ' + shipmentCountEmptyAtShop.responseData[i].city,
                                shipmentCountEmptyAtShop.responseData[i].countTotal
                            ));
                        countEmptyTotal = countEmptyTotal + shipmentCountEmptyAtShop.responseData[i].countTotal;
                    }
                }
                if (props.productId !== '') {
                    grandTotal = grandTotal + countEmptyTotal;
                    setGrandTotal(grandTotal);
                }
                rowEmpty.push(
                    createDataForEmpty(
                        false,
                        'Empty Total :',
                        countEmptyTotal
                    ));
                setShipmentCountEmpty(rowEmpty);
            } else {
                let shipmentCountEmpty = await fetchShipmentCountEmpty();
                if (shipmentCountEmpty.isSuccess && shipmentCountEmpty.responseData.length > 0) {
                    for (let i = 0; i < shipmentCountEmpty.responseData.length; i++) {
                        rowEmpty.push(
                            createDataForEmpty(
                                true,
                                'Empties in ' + shipmentCountEmpty.responseData[i].state,
                                shipmentCountEmpty.responseData[i].countTotal
                            ));
                        countEmptyTotal = countEmptyTotal + shipmentCountEmpty.responseData[i].countTotal;
                    }

                }

                let shipmentCountDecaturEmpty = await fetchShipmentCountDecaturEmpty();
                if (shipmentCountDecaturEmpty.isSuccess && shipmentCountDecaturEmpty.responseData.length > 0) {
                    for (let i = 0; i < shipmentCountDecaturEmpty.responseData.length; i++) {
                        rowEmpty.push(
                            createDataForEmpty(
                                true,
                                'Empties in ' + shipmentCountDecaturEmpty.responseData[i].city,
                                shipmentCountDecaturEmpty.responseData[i].countTotal
                            ));
                        countEmptyTotal = countEmptyTotal + shipmentCountDecaturEmpty.responseData[i].countTotal;
                    }
                }

                let shipmentCountEmptyAtShop = await fetchShipmentCountEmptyAtShop();
                if (shipmentCountEmptyAtShop.isSuccess && shipmentCountEmptyAtShop.responseData.length > 0) {
                    for (let i = 0; i < shipmentCountEmptyAtShop.responseData.length; i++) {
                        rowEmpty.push(
                            createDataForEmpty(
                                true,
                                'At Shop in ' + shipmentCountEmptyAtShop.responseData[i].city,
                                shipmentCountEmptyAtShop.responseData[i].countTotal
                            ));
                        countEmptyTotal = countEmptyTotal + shipmentCountEmptyAtShop.responseData[i].countTotal;
                    }
                }

                if (props.productId !== '') {
                    grandTotal = grandTotal + countEmptyTotal;
                    setGrandTotal(grandTotal);
                }
                rowEmpty.push(
                    createDataForEmpty(
                        false,
                        'Empty Total :',
                        countEmptyTotal
                    ));
                setShipmentCountEmpty(rowEmpty);
            }
        }

        if (filterBandState.selectedLocationNo !== '') {
            let shipmentCountLoadedForAll = await fetchShipmentCountLoadedForAll();
            if (shipmentCountLoadedForAll.isSuccess && shipmentCountLoadedForAll.responseData.length > 0) {
                for (let i = 0; i < shipmentCountLoadedForAll.responseData.length; i++) {
                    rowAll.push(
                        createDataForAll(
                            true,
                            'Loaded For ' + shipmentCountLoadedForAll.responseData[i].city,
                            shipmentCountLoadedForAll.responseData[i].countTotal
                        ));
                    countAllTotal = countAllTotal + shipmentCountLoadedForAll.responseData[i].countTotal;
                }
            }

            if (filterBandState.selectedCustomerNo === 'Z00AD5') {
                let shipmentCountLoadedForAllOL = await fetchShipmentCountLoadedForAllOL();
                if (shipmentCountLoadedForAllOL.isSuccess && shipmentCountLoadedForAllOL.responseData.length > 0) {
                    for (let i = 0; i < shipmentCountLoadedForAllOL.responseData.length; i++) {
                        rowAll.push(
                            createDataForAll(
                                true,
                                'Loaded For ' + shipmentCountLoadedForAllOL.responseData[i].rS_LOAD_EMPTY_STATUS,
                                shipmentCountLoadedForAllOL.responseData[i].countTotal
                            ));
                        countAllTotal = countAllTotal + shipmentCountLoadedForAllOL.responseData[i].countTotal;
                    }
                }
            }
            grandTotal = grandTotal + countAllTotal;
            setGrandTotal(grandTotal);
            if (countAllTotal > 0) {
                rowAll.push(
                    createDataForAll(
                        false,
                        'Loaded for Total :',
                        countAllTotal
                    ));
            }
            setShipmentCountForAll(rowAll);
        } else {
            let shipmentCountLoadedForAllCustomer = await fetchShipmentCountLoadedForAllCustomer();
            if (shipmentCountLoadedForAllCustomer.isSuccess && shipmentCountLoadedForAllCustomer.responseData.length > 0) {
                for (let i = 0; i < shipmentCountLoadedForAllCustomer.responseData.length; i++) {
                    rowAll.push(
                        createDataForAll(
                            true,
                            'Loaded For ' + shipmentCountLoadedForAllCustomer.responseData[i].city,
                            shipmentCountLoadedForAllCustomer.responseData[i].countTotal
                        ));
                    countAllTotal = countAllTotal + shipmentCountLoadedForAllCustomer.responseData[i].countTotal;
                }
            }

            if (filterBandState.selectedCustomerNo === 'Z00AD5') {
                let shipmentCountLoadedForAllOL = await fetchShipmentCountLoadedForAllOL();
                if (shipmentCountLoadedForAllOL.isSuccess && shipmentCountLoadedForAllOL.responseData.length > 0) {
                    for (let i = 0; i < shipmentCountLoadedForAllOL.responseData.length; i++) {
                        rowAll.push(
                            createDataForAll(
                                true,
                                'Loaded For ' + shipmentCountLoadedForAllOL.responseData[i].rS_LOAD_EMPTY_STATUS,
                                shipmentCountLoadedForAllOL.responseData[i].countTotal
                            ));
                        countAllTotal = countAllTotal + shipmentCountLoadedForAllOL.responseData[i].countTotal;
                    }
                }
            }
            grandTotal = grandTotal + countAllTotal;
            setGrandTotal(grandTotal);
            if (countAllTotal > 0) {
                rowAll.push(
                    createDataForAll(
                        false,
                        'Loaded for Total :',
                        countAllTotal
                    ));
            }
            setShipmentCountForAll(rowAll);
        }
    };

    return (
        <div>
            <Button onClick={handlePrint} startIcon={<PrintIcon />}>Print</Button>
            <Divider sx={{ mt: 4, mb: 2 }}></Divider>
            <div id="printTable" ref={componentRef} >
                <div style={screenStyle.heading}>
                    {screenTitle}
                </div>
                <div style={screenStyle.headingMessage}>
                    Report Generated: {dateTime}
                </div>
                <div>
                    <ProductInventoriesTable />
                </div>
                <div>
                    <Card
                        sx={{ width: "100hv", borderRadius: 3, margin: 1 }}
                        elevation={6}
                    >
                        <CardContent sx={{ pb: 0 }}>
                            {filterBandState.selectedLocationNo !== '' ? (
                                <Tbl>
                                    <TableBody>
                                        {shipmentCount && (shipmentCount.map((row: any, index: any) => (
                                            row.IsRow === true ?
                                                <StyledTableRow key={row.countTotal}>
                                                    <StyledTableCell style={{ width: '500px' }} component="th" scope="row">
                                                        {row.State}
                                                    </StyledTableCell>
                                                    <StyledTableCell component="th" scope="row">
                                                        {row.CountTotal}
                                                    </StyledTableCell>
                                                </StyledTableRow>
                                                : <StyledTableRow style={{ backgroundColor: "#5c555557" }} key={row.countTotal}>
                                                    <StyledTableCell style={{ width: '500px' }} component="th" scope="row">
                                                        {row.State}
                                                    </StyledTableCell>
                                                    <StyledTableCell component="th" scope="row">
                                                        {row.CountTotal}
                                                    </StyledTableCell>
                                                </StyledTableRow>
                                        )))}
                                    </TableBody>
                                </Tbl>
                            ) : (
                                <Grid item xs={12} sm={12} md={12} lg={12}>
                                    <Card
                                        sx={{ width: "100hv", margin: 0.5 }}
                                        elevation={6}
                                    >
                                        <CardContent>
                                            <Grid
                                                container
                                                columnSpacing={{ xs: 1, sm: 1, md: 4, lg: 4 }}
                                            >
                                                <Grid item xs={12} sm={12} md={6} lg={6}>
                                                    No Data Found
                                                </Grid>
                                            </Grid>
                                        </CardContent>
                                    </Card>
                                </Grid>
                            )}
                            {props.productId !== '' ? (
                                <TblEmpty>
                                    <TableBody>
                                        {shipmentCountEmpty && (shipmentCountEmpty.map((row: any, index: any) => (
                                            row.IsRow === true ?
                                                <StyledTableRow key={row.countTotal}>
                                                    <StyledTableCell style={{ width: '500px' }} component="th" scope="row">
                                                        {row.State}
                                                    </StyledTableCell>
                                                    <StyledTableCell component="th" scope="row">
                                                        {row.CountTotal}
                                                    </StyledTableCell>
                                                </StyledTableRow>
                                                : <StyledTableRow style={{ backgroundColor: "#5c555557" }} key={row.countTotal}>
                                                    <StyledTableCell style={{ width: '500px' }} component="th" scope="row">
                                                        {row.State}
                                                    </StyledTableCell>
                                                    <StyledTableCell component="th" scope="row">
                                                        {row.CountTotal}
                                                    </StyledTableCell>
                                                </StyledTableRow>
                                        )))}
                                    </TableBody>
                                </TblEmpty>
                            ) : (
                                <Grid item xs={12} sm={12} md={12} lg={12}>
                                    <Card
                                        sx={{ width: "100hv", margin: 0.5 }}
                                        elevation={6}
                                    >
                                        <CardContent>
                                            <Grid
                                                container
                                                columnSpacing={{ xs: 1, sm: 1, md: 4, lg: 4 }}
                                            >
                                                <Grid item xs={12} sm={12} md={6} lg={6}>
                                                    No Data Found
                                                </Grid>
                                            </Grid>
                                        </CardContent>
                                    </Card>
                                </Grid>
                            )}
                            {shipmentCountForAll.length > 0 ? (
                                <TblForAll>
                                    <TableBody>
                                        {shipmentCountForAll && (shipmentCountForAll.map((row: any, index: any) => (
                                            row.IsRow === true ?
                                                <StyledTableRow key={row.countTotal}>
                                                    <StyledTableCell style={{ width: '500px' }} component="th" scope="row">
                                                        {row.State}
                                                    </StyledTableCell>
                                                    <StyledTableCell component="th" scope="row">
                                                        {row.CountTotal}
                                                    </StyledTableCell>
                                                </StyledTableRow>
                                                : <StyledTableRow style={{ backgroundColor: "#5c555557" }} key={row.countTotal}>
                                                    <StyledTableCell style={{ width: '500px' }} component="th" scope="row">
                                                        {row.State}
                                                    </StyledTableCell>
                                                    <StyledTableCell component="th" scope="row">
                                                        {row.CountTotal}
                                                    </StyledTableCell>
                                                </StyledTableRow>
                                        )))}
                                    </TableBody>
                                </TblForAll>
                            ) : (
                                <Grid item xs={12} sm={12} md={12} lg={12}>
                                    <Card
                                        sx={{ width: "100hv", margin: 0.5 }}
                                        elevation={6}
                                    >
                                        <CardContent>
                                            <Grid
                                                container
                                                columnSpacing={{ xs: 1, sm: 1, md: 4, lg: 4 }}
                                            >
                                                <Grid item xs={12} sm={12} md={6} lg={6}>
                                                    NO DATA FOUND
                                                </Grid>
                                            </Grid>
                                        </CardContent>
                                    </Card>
                                </Grid>
                            )}
                            <Grid item xs={12} sm={12} md={12}>
                                <Card sx={{ backgroundColor: "yellow", width: "100hv", margin: 0.5 }} elevation={6}>
                                    <CardContent>
                                        <Grid container columnSpacing={{ xs: 1, sm: 1, md: 4, lg: 4 }}>
                                            <Grid item xs={6} sm={6} md={6} lg={6}>
                                                GRAND TOTAL # OF CARS: &nbsp; {grandTotal}
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                </Card>
                            </Grid>
                        </CardContent>
                    </Card>
                </div>
            </div>
        </div>
    );
}

export default PrintInventory;