/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from "react";
import {
  Grid,
  TextField,
  Button,
  Container,
  Divider,
  Checkbox,
  FormControlLabel,
  RadioGroup,
  Radio,
  FormLabel,
} from "@mui/material";
import SearchSharpIcon from "@mui/icons-material/SearchSharp";
import { useFormik } from "formik";
import * as yup from "yup";

// Store/Redux
import { useSelector } from "react-redux";
import { uiActions } from "../../../store/ui-slice";
import { IFilterBandState } from "../../../interfaces/storeInterfaces";
import { filterBandSelector, useAppDispatch } from "../../../store/store";

// Model
import { LocationDetails } from "../../../models/location";

// Services
import LocationDataService from "../../../services/locationService";
import DB2LookUpDataService from "../../../services/db2LookUpService";

interface LocationMaintenanceProps {
  setShowModalPopup: React.Dispatch<React.SetStateAction<boolean>>;
  locationNo: string;
  setRefreshList: React.Dispatch<React.SetStateAction<boolean>>;
}

function LocationMaintenance(props: LocationMaintenanceProps) {
  const dispatch = useAppDispatch();
  const filterBandState: IFilterBandState = useSelector(filterBandSelector);
  const [locationNo, setLocationNo] = useState("");
  const [lookupID, setLookUpID] = useState<string>("");
  const [isLoaded, setIsLoaded] = useState<boolean>(false);

  const [locationInfo, setLocationInformation] = useState<LocationDetails>(
    LocationDataService.GetDefaultLocationInfo()
  );
  const { fetchData: fetchLocationData } = LocationDataService.GetLocationByLocNumber(
    filterBandState.selectedCustomerNo,
    props.locationNo
  );
  const { fetchData: fetchLocationLookUpData } =
    DB2LookUpDataService.RetrieveTransportLocation(lookupID);
  const { putData: updateLocationData } =
    LocationDataService.UpdateLocation(locationInfo);
  const { postData: addLocationData } =
    LocationDataService.CreateLocation(locationInfo);

  useEffect(() => {
    loadLocationByID(props.locationNo);
    setLocationNo(props.locationNo);
  }, [props.locationNo]);

  useEffect(() => {
    if (isLoaded) {
      if (
        locationNo !== "" &&
        locationNo !== null &&
        locationNo !== undefined
      ) {
        dispatch(uiActions.startProgressBar());
        updateLocationData().then((res) => {
          if (res.isSuccess) {
            dispatch(
              uiActions.displayNotification({
                message: "Location updated successfully!",
                severity: "success",
              })
            );
            props.setShowModalPopup(false);
            props.setRefreshList(true);
          }
        });
      } else {
        dispatch(uiActions.startProgressBar());
        addLocationData().then((res) => {
          if (res.isSuccess === true) {
            if (res.responseData.success === "Yes") {
              dispatch(
                uiActions.displayNotification({
                  message: "Location added successfully!",
                  severity: "success",
                })
              );
              props.setShowModalPopup(false);
              props.setRefreshList(true);
            } else if (res.responseData.success === "No") {
              dispatch(
                uiActions.logErrorNotification({
                  message:
                    "There is already a location with this 'Ship to' number.",
                  severity: "error",
                })
              );
            }
          }
        });
      }
    } else {
      setIsLoaded(true);
    }
  }, [locationInfo]);

  useEffect(() => {
    LookUpLocation(lookupID);
    setLookUpID(lookupID);
  }, [lookupID]);

  // Form Configuration
  const validationSchema = yup.object({
    vL_CUSTOMER_SHIPTO: yup
      .string()
      .required("Required")
      .min(6, "Ship To# No. should be of 6 characters")
      .max(6, "Ship To# No. should be of 6 characters"),

    vL_LOCATION_NAME: yup.string().required("Required"),

    vL_ADDRESS_1: yup.string().nullable().required("Required"),

    vL_CITY: yup.string().nullable().required("Required"),

    vL_STATE: yup
      .string()
      .required("Required")
      .nullable()
      .max(2, "State should be of 2 characters"),

    vL_ZIP: yup.string().nullable().required("Required"),

    vL_FAX: yup.string().nullable().max(15, "Fax should be of 15 characters"),

    vL_SITE_NUMBER: yup
      .string()
      .nullable()
      .matches(/^[.0-9]*$/, "Site Number should be a number"),
  });

  const formik = useFormik({
    initialValues: LocationDataService.GetDefaultLocationInfo(),
    validationSchema: validationSchema,

    onSubmit: (values: LocationDetails) => {
      if (locationNo === "") {
        let customerDetail = filterBandState.customers.filter(
          (row) => row.customerNo === filterBandState.selectedCustomerNo
        );
        if (customerDetail.length > 0) {
          values.vL_CUSTOMER_ID = customerDetail[0].customerID;
          values.vL_SITE_NUMBER =
            values.vL_SITE_NUMBER === null
              ? ""
              : values.vL_SITE_NUMBER.toString();
          setLocationInformation(values);
        }
      } else {
        values.vL_SITE_NUMBER =
          values.vL_SITE_NUMBER == null ? "" : values.vL_SITE_NUMBER.toString();
        setLocationInformation(values);
      }
    },
  });

  // Events
  const useLocHandleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValues("vL_LOC_AS_SOLDTO", event.target.checked);
  };

  const ADMLocationhandleChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setInputValues(
      "vL_ADM_LOCATION",
      (event.target as HTMLInputElement).value === "true" ? true : false
    );
  };

  const DistributionhandleChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setInputValues(
      "vL_RAILCAR_TERMINAL",
      (event.target as HTMLInputElement).value === "true" ? true : false
    );
  };

  const ROAhandleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValues(
      "vL_COA_REQUEST_LOCATION",
      (event.target as HTMLInputElement).value === "true" ? true : false
    );
  };

  const handleLookUp = (e: React.MouseEvent<HTMLButtonElement>) => {
    formik.handleReset(Event);
    if (
      formik.values.vL_CUSTOMER_SHIPTO === "" ||
      formik.values.vL_CUSTOMER_SHIPTO === undefined ||
      formik.values.vL_CUSTOMER_SHIPTO === null
    ) {
      dispatch(
        uiActions.displayNotification({
          message: "Please provide a Ship To #",
          severity: "error",
        })
      );
      return;
    }
    setLookUpID(formik.values.vL_CUSTOMER_SHIPTO);
  };

  const LookUpLocation = (locationID: string) => {
    if (locationID !== "") {
      dispatch(uiActions.startProgressBar());
      fetchLocationLookUpData().then((res) => {
        if (
          res.responseData.vL_CUSTOMER_SHIPTO !== null &&
          res.responseData.vL_CUSTOMER_SHIPTO !== ""
        ) {
          formik.setValues(res.responseData);
          setLookUpID("");
        } else {
          dispatch(
            uiActions.displayNotification({
              message:
                "No location information is found for the Ship To # you have provided.",
              severity: "error",
            })
          );
        }
      });
    }
  };

  // Methods
  const loadLocationByID = (locationNo: string) => {
    if (locationNo !== "" && locationNo !== undefined && locationNo !== null) {
      fetchLocationData().then((res) => {
        formik.setValues(res.responseData);
      });
    }
  };

  const setInputValues = useCallback(
    (key, value) =>
      formik.setValues({
        ...formik.values,
        [key]: value,
      }),
    [formik]
  );

  return (
    <React.Fragment>
      <Container>
        <form
          onSubmit={formik.handleSubmit}
          id="frmLocationMaintenance"
          className="form"
        >
          <Grid container spacing={3} sx={{ paddingTop: 1 }}>
            {(locationNo === "" ||
              locationNo === undefined ||
              locationNo === null) && (
              <Grid item xs={12} sm={12} md={12}>
                <span>
                  Note: To add a new location, please provide the location
                  information and click on the submit button.
                </span>
              </Grid>
            )}
            <Grid item xs={9} sm={9} md={9}>
              <TextField
                name="vL_CUSTOMER_SHIPTO"
                label="Ship To # *"
                value={formik.values.vL_CUSTOMER_SHIPTO}
                disabled={
                  locationNo !== "" &&
                  locationNo !== undefined &&
                  locationNo !== null
                    ? true
                    : false
                }
                fullWidth
                onChange={formik.handleChange}
                error={
                  formik.touched.vL_CUSTOMER_SHIPTO &&
                  Boolean(formik.errors.vL_CUSTOMER_SHIPTO)
                }
                helperText={
                  formik.touched.vL_CUSTOMER_SHIPTO &&
                  formik.errors.vL_CUSTOMER_SHIPTO
                }
                inputProps={{ maxLength: 6 }}
              />
            </Grid>
            {/* {locationNo !== "" && locationNo !== undefined && locationNo !== null && ( */}
            <Grid item xs={3} sm={3} md={3}>
              <Button
                startIcon={<SearchSharpIcon></SearchSharpIcon>}
                onClick={(e) => handleLookUp(e)}
              >
                Look Up
              </Button>
            </Grid>
            {/* )} */}
            <Grid item xs={6} sm={6} md={6}>
              <TextField
                name="vL_LOCATION_NAME"
                label="Location Name *"
                value={formik.values.vL_LOCATION_NAME}
                fullWidth
                onChange={formik.handleChange}
                error={
                  formik.touched.vL_LOCATION_NAME &&
                  Boolean(formik.errors.vL_LOCATION_NAME)
                }
                helperText={
                  formik.touched.vL_LOCATION_NAME &&
                  formik.errors.vL_LOCATION_NAME
                }
              />
            </Grid>
            {
              <Grid item xs={6} sm={6} md={6}>
                <FormControlLabel
                  value="start"
                  control={
                    <Checkbox
                      checked={formik.values.vL_LOC_AS_SOLDTO}
                      onChange={useLocHandleChange}
                    />
                  }
                  label="Use Loc# as SoldTo#"
                  labelPlacement="start"
                />
              </Grid>
            }
            <Grid item xs={9} sm={9} md={9}>
              <TextField
                name="vL_ADDRESS_1"
                label="Address 1 *"
                value={formik.values.vL_ADDRESS_1}
                fullWidth
                onChange={formik.handleChange}
                error={
                  formik.touched.vL_ADDRESS_1 &&
                  Boolean(formik.errors.vL_ADDRESS_1)
                }
                helperText={
                  formik.touched.vL_ADDRESS_1 && formik.errors.vL_ADDRESS_1
                }
              />
            </Grid>
            <Grid item xs={9} sm={9} md={9}>
              <TextField
                name="vL_ADDRESS_2"
                label="Address 2"
                value={formik.values.vL_ADDRESS_2}
                fullWidth
                onChange={formik.handleChange}
                error={
                  formik.touched.vL_ADDRESS_2 &&
                  Boolean(formik.errors.vL_ADDRESS_2)
                }
                helperText={
                  formik.touched.vL_ADDRESS_2 && formik.errors.vL_ADDRESS_2
                }
              />
            </Grid>
            <Grid item xs={4} sm={4} md={4}>
              <TextField
                name="vL_CITY"
                label="City *"
                value={formik.values.vL_CITY}
                fullWidth
                onChange={formik.handleChange}
                error={formik.touched.vL_CITY && Boolean(formik.errors.vL_CITY)}
                helperText={formik.touched.vL_CITY && formik.errors.vL_CITY}
              />
            </Grid>
            <Grid item xs={4} sm={4} md={4}>
              <TextField
                name="vL_STATE"
                label="State *"
                value={formik.values.vL_STATE}
                fullWidth
                onChange={formik.handleChange}
                error={
                  formik.touched.vL_STATE && Boolean(formik.errors.vL_STATE)
                }
                helperText={formik.touched.vL_STATE && formik.errors.vL_STATE}
                inputProps={{ maxLength: 2 }}
              />
            </Grid>
            <Grid item xs={4} sm={4} md={4}>
              <TextField
                name="vL_ZIP"
                label="Zip *"
                value={formik.values.vL_ZIP}
                fullWidth
                onChange={formik.handleChange}
                error={formik.touched.vL_ZIP && Boolean(formik.errors.vL_ZIP)}
                helperText={formik.touched.vL_ZIP && formik.errors.vL_ZIP}
              />
            </Grid>
            <Grid item xs={6} sm={6} md={6}>
              <TextField
                name="vL_PHONE"
                label="Phone"
                value={formik.values.vL_PHONE}
                fullWidth
                onChange={formik.handleChange}
                error={
                  formik.touched.vL_PHONE && Boolean(formik.errors.vL_PHONE)
                }
                helperText={formik.touched.vL_PHONE && formik.errors.vL_PHONE}
              />
            </Grid>
            <Grid item xs={6} sm={6} md={6}>
              <TextField
                name="vL_FAX"
                label="Fax"
                value={formik.values.vL_FAX}
                fullWidth
                onChange={formik.handleChange}
                error={formik.touched.vL_FAX && Boolean(formik.errors.vL_FAX)}
                helperText={formik.touched.vL_FAX && formik.errors.vL_FAX}
                inputProps={{ maxLength: 15 }}
              />
            </Grid>
            <Grid item xs={6} sm={6} md={6}>
              <TextField
                name="vL_CONTACT_NAME"
                label="Contact Name"
                value={formik.values.vL_CONTACT_NAME}
                fullWidth
                onChange={formik.handleChange}
                error={
                  formik.touched.vL_CONTACT_NAME &&
                  Boolean(formik.errors.vL_CONTACT_NAME)
                }
                helperText={
                  formik.touched.vL_CONTACT_NAME &&
                  formik.errors.vL_CONTACT_NAME
                }
              />
            </Grid>
            <Grid item xs={6} sm={6} md={6}>
              <TextField
                name="vL_CONTACT_EMAIL"
                label="Email"
                value={formik.values.vL_CONTACT_EMAIL}
                fullWidth
                onChange={formik.handleChange}
                error={
                  formik.touched.vL_CONTACT_EMAIL &&
                  Boolean(formik.errors.vL_CONTACT_EMAIL)
                }
                helperText={
                  formik.touched.vL_CONTACT_EMAIL &&
                  formik.errors.vL_CONTACT_EMAIL
                }
              />
            </Grid>
            <Grid item xs={6} sm={6} md={6} lg={6}>
              <FormLabel>ADM Location</FormLabel>
              <RadioGroup
                row
                aria-labelledby="vL_ADM_LOCATION"
                name="vL_ADM_LOCATION"
                value={formik.values.vL_ADM_LOCATION === true ? true : false}
                onChange={ADMLocationhandleChange}
              >
                <FormControlLabel
                  value="true"
                  control={<Radio size="small" />}
                  label="Yes"
                />
                <FormControlLabel
                  value="false"
                  control={<Radio size="small" />}
                  label="No"
                />
              </RadioGroup>
            </Grid>
            <Grid item xs={6} sm={6} md={6} lg={6}>
              <FormLabel>Distribution Terminal</FormLabel>
              <RadioGroup
                row
                aria-labelledby="vL_RAILCAR_TERMINAL"
                name="vL_RAILCAR_TERMINAL"
                value={
                  formik.values.vL_RAILCAR_TERMINAL === true ? true : false
                }
                onChange={DistributionhandleChange}
              >
                <FormControlLabel
                  value="true"
                  control={<Radio size="small" />}
                  label="Yes"
                />
                <FormControlLabel
                  value="false"
                  control={<Radio size="small" />}
                  label="No"
                />
              </RadioGroup>
            </Grid>
            <Grid item xs={6} sm={6} md={6} lg={6}>
              <FormLabel>COA Request Location</FormLabel>
              <RadioGroup
                row
                aria-labelledby="vL_COA_REQUEST_LOCATION"
                name="vL_COA_REQUEST_LOCATION"
                value={
                  formik.values.vL_COA_REQUEST_LOCATION === true ? true : false
                }
                onChange={ROAhandleChange}
              >
                <FormControlLabel
                  value="true"
                  control={<Radio size="small" />}
                  label="Yes"
                />
                <FormControlLabel
                  value="false"
                  control={<Radio size="small" />}
                  label="No"
                />
              </RadioGroup>
            </Grid>
            <Grid item xs={6} sm={6} md={6} lg={6}>
              <TextField
                name="vL_SITE_NUMBER"
                label="Site Number"
                // inputProps={{inputMod: 'numeric', pattern: '[0-9.]*'}}
                value={formik.values.vL_SITE_NUMBER}
                fullWidth
                onChange={formik.handleChange}
                error={
                  formik.touched.vL_SITE_NUMBER &&
                  Boolean(formik.errors.vL_SITE_NUMBER)
                }
                // helperText={'Site Number should be a number'}
                helperText={
                  formik.touched.vL_SITE_NUMBER && formik.errors.vL_SITE_NUMBER
                }
              />
            </Grid>
          </Grid>
        </form>
      </Container>
      <Divider sx={{ mt: 4, mb: 2 }}></Divider>
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        sx={{
          justifyContent: "flex-end",
          alignItems: "flex-end",
          display: "flex",
        }}
      >
        {(locationNo === "" ||
          locationNo === undefined ||
          locationNo === null) && (
          <Button onClick={formik.handleReset} sx={{ mr: 1 }}>
            Reset
          </Button>
        )}
        <Button
          form="frmLocationMaintenance"
          type="submit"
          onSubmit={(e) => formik.handleSubmit}
        >
          {locationNo !== "" && locationNo !== undefined && locationNo !== null
            ? "Update"
            : "Submit"}
        </Button>
      </Grid>
    </React.Fragment>
  );
}

export default LocationMaintenance;
