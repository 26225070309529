// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.adm-img-line {
    height: 15px;
    overflow-y: hidden;
    border-radius: 0px 0px 14px 14px;
}
.adm-home-img-line {
    height: 10px;
    overflow-y: hidden;
}
.form > .MuiGrid-root > .MuiGrid-item{
    padding-top: 12px!important
}

@media only screen and (max-width: 900px) {
    /* For mobile phones: */
    .adm-img-line {
        height: 30px;
        overflow-y: hidden;
        border-radius: 0px 0px 20px 20px;        
        margin-top: -10px;
    }

    .adm-home-img-line {
        height: 20px;
        overflow-y: hidden;
        margin-top: -10px;
    }


  }`, "",{"version":3,"sources":["webpack://./src/utils/css/site.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,kBAAkB;IAClB,gCAAgC;AACpC;AACA;IACI,YAAY;IACZ,kBAAkB;AACtB;AACA;IACI;AACJ;;AAEA;IACI,uBAAuB;IACvB;QACI,YAAY;QACZ,kBAAkB;QAClB,gCAAgC;QAChC,iBAAiB;IACrB;;IAEA;QACI,YAAY;QACZ,kBAAkB;QAClB,iBAAiB;IACrB;;;EAGF","sourcesContent":[".adm-img-line {\n    height: 15px;\n    overflow-y: hidden;\n    border-radius: 0px 0px 14px 14px;\n}\n.adm-home-img-line {\n    height: 10px;\n    overflow-y: hidden;\n}\n.form > .MuiGrid-root > .MuiGrid-item{\n    padding-top: 12px!important\n}\n\n@media only screen and (max-width: 900px) {\n    /* For mobile phones: */\n    .adm-img-line {\n        height: 30px;\n        overflow-y: hidden;\n        border-radius: 0px 0px 20px 20px;        \n        margin-top: -10px;\n    }\n\n    .adm-home-img-line {\n        height: 20px;\n        overflow-y: hidden;\n        margin-top: -10px;\n    }\n\n\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
