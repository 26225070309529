/* eslint-disable import/no-anonymous-default-export */
import useAxiosDelete from "../hooks/useAxiosDelete";
import useAxiosPost from "../hooks/useAxiosPost";
import useAxiosPut from "../hooks/useAxiosPut";
import { UnitInfo } from "../models/location";

const isLocal = () => process.env.REACT_APP_ENVIR === "LOCAL";
const userId = () => sessionStorage.getItem("userMailID");

const UpdateUnitInformationByUnitID = (customerNo:string,locationNo:string,data:UnitInfo) => {
    return useAxiosPut(isLocal() ?`/Unit/UpdateUnitInformationByUnitID/users/${userId()}/customers/${customerNo}/locations/${locationNo}/unitinformations`:`/users/${userId()}/customers/${customerNo}/locations/${locationNo}/unitinformations`, data, "Unit Maintainance - Update Unit");
  };
  
  const CreateUnitInformation = (customerNo:string,locationNo:string,data:UnitInfo) => {
    return useAxiosPost(isLocal() ?`/Unit/CreateUnitInformation/users/${userId()}/customers/${customerNo}/locations/${locationNo}/unitinformations`:`/users/${userId()}/customers/${customerNo}/locations/${locationNo}/unitinformations`, data, "Unit Maintainance - Add Unit");
  };
  
  const DeleteUnit = (customerNo:string,unitId:string) => {
    return useAxiosDelete(isLocal() ?`/Unit/DeleteUnit/users/${userId()}/customers/${customerNo}/units/${unitId}`:`users/${userId()}/customers/${customerNo}/units/${unitId}`,"Unit Maintainance - Delete Unit");
  };

export default {
    UpdateUnitInformationByUnitID,
    CreateUnitInformation,
    DeleteUnit
  };
  