import RailcarHistory from "../components/app/Shipment/RailcarHistory";
import { filterBandSelector } from "../store/store";
import { useSelector } from "react-redux";
import { IFilterBandState } from "../interfaces/storeInterfaces";
import SystemMessage from "./SystemMessage";

function RailcarHistoryPage() {
  const filterBandState: IFilterBandState = useSelector(filterBandSelector);

  return filterBandState.inboundUser === true ? (
    <RailcarHistory></RailcarHistory>
  ) : filterBandState.railcarUser === true ? (
    <RailcarHistory></RailcarHistory>
  ) : (
    <SystemMessage msg="Access Denied." colorMsg="Red"></SystemMessage>
  );
}

export default RailcarHistoryPage;
