/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Card, CardContent, Grid, IconButton } from "@mui/material";
import React, { useEffect, useState } from "react";
import TableBody from "@mui/material/TableBody";
import ModeEditSharpIcon from "@mui/icons-material/ModeEditSharp";
import DeleteSharpIcon from "@mui/icons-material/DeleteSharp";
import AddSharpIcon from "@mui/icons-material/AddSharp";
import ReplyIcon from "@mui/icons-material/Reply";
import { StyledTableCell, StyledTableRow } from "../../controls/tablecontrols";
import { TableHeaderCell } from "../../../models/common";
import useTable from "../../../hooks/useTable";
import TankService from "../../../services/tankService";
import ModalPopup from "../../shared/ModalPopup";
import { filterBandSelector, useAppDispatch } from "../../../store/store";
import PageHeader from "../../shared/PageHeader";
import CustomCardHeader from "../../shared/CustomCardHeader";
import ConfirmationMessage from "../../shared/ConfirmationMessage";
import { IFilterBandState } from "../../../interfaces/storeInterfaces";
import { useSelector } from "react-redux";
import { filterBandActions } from "../../../store/filterband-slice";
import { Link, useLocation } from "react-router-dom";
import { TankByLocation } from "../../../models/tank";
import AddEditTank from "./AddEditTank";
import { uiActions } from "../../../store/ui-slice";

function TankList() {
  const filterBandState: IFilterBandState = useSelector(filterBandSelector);
  const [tankList, setTankList] = useState<TankByLocation[]>([]);
  const [tankID, setTankID] = useState<number>(0);
  const [deleteTankNo, setDeleteTankNo] = useState<number>(0);
  const [deleteTankID, setDeleteTankID] = useState<number>(0);
  const [isConfirmDelete, setIsConfirmDelete] = useState<boolean>(false);
  const [openConfirmation, setOpenConfirmation] = useState<boolean>(false);
  const [isRefreshList, setRefreshList] = useState<boolean>(false);
  const [showModalPopup, setShowModalPopup] = useState<boolean>(false);
  const [deleteTankName, setDeleteTankName] = useState<string>("");
  const [productName, setProductName] = useState<string>("");
  const dispatch = useAppDispatch();
  const tank = useLocation();

  const tableHeaderCellDetails: TableHeaderCell[] = [
    { id: "vT_TANK_NO", label: "Tank No" },
    { id: "vT_TANK_NAME", label: "Tank Name" },
    { id: "edit", label: "Edit", disableSorting: true },
    { id: "delete", label: "Delete", disableSorting: true },
  ];

  const { Tbl, GetRowsAfterPagingAndSorting } = useTable<TankByLocation>(
    tankList,
    tableHeaderCellDetails,
    true,
    10
  );
  const { fetchData: fetchTankData } = TankService.RetrieveTanksBylocation(
    filterBandState.selectedCustomerNo,
    filterBandState.selectedLocationNo
  );
  const { deleteData: deleteTank } = TankService.DeleteTank(
    filterBandState.selectedCustomerNo,
    filterBandState.selectedLocationNo,
    deleteTankID
  );

  useEffect(() => {
    getTanks();
    setProductName("");
  }, [isRefreshList, filterBandState.tanks]);

  useEffect(() => {
    if (tank.state !== null && tank.state !== undefined) {
      if (tank.state.editModal === true && tank.state.editTankID !== "") {
        setTankID(tank.state.editTankID);
        setShowModalPopup(true);
      } else if (
        tank.state.editModal === true &&
        tank.state.editTankID === ""
      ) {
        setTankID(0);
        setShowModalPopup(true);
      }
      window.history.replaceState({}, document.title);
    }
  }, [tank]);

  const getTanks = () => {
    if (isRefreshList) {
      fetchTankData().then((res) => {
        dispatch(filterBandActions.setTankList(res.responseData));
        setRefreshList(false);
      });
    }
    setTankList(filterBandState.tanks);
  };

  useEffect(() => {
    if (isConfirmDelete && deleteTankID !== 0) {
      dispatch(uiActions.startProgressBar());
      deleteTank().then((res) => {
        if (res.isSuccess) {
          dispatch(
            uiActions.displayNotification({
              message: "Tank deleted successfully!",
              severity: "success",
            })
          );
          if (filterBandState.selectedTankNo === String(deleteTankNo))
            dispatch(filterBandActions.removeSelectedTank());
          setRefreshList(true);
        }
      });

      Promise.resolve().then(() => {
        setIsConfirmDelete(false);
        setDeleteTankNo(0);
        setDeleteTankID(0);
        setDeleteTankName("");
      });
    }
  }, [isConfirmDelete]);

  const onAddNewClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    Promise.resolve().then(() => {
      setTankID(0);
      setShowModalPopup(true);
    });
  };

  const onModalClose = (e: React.MouseEvent<HTMLButtonElement>) => {
    Promise.resolve().then(() => {
      setTankID(0);
      setShowModalPopup(false);
    });
  };

  const handleEdit = (tankID: number) => {
    Promise.resolve().then(() => {
      setTankID(tankID);
      setShowModalPopup(true);
    });
  };

  const handleOpenDeleteConfirmation = (
    tankNo: number,
    tankID: number,
    tankName: string
  ) => {
    Promise.resolve().then(() => {
      setOpenConfirmation(true);
      setDeleteTankNo(tankNo);
      setDeleteTankID(tankID);
      setDeleteTankName(tankName);
    });
  };

  const handleConfirmDelete = (e: React.MouseEvent<HTMLButtonElement>) => {
    Promise.resolve().then(() => {
      setOpenConfirmation(false);
      setIsConfirmDelete(true);
    });
  };

  const handleCancelDelete = (e: React.MouseEvent<HTMLButtonElement>) => {
    Promise.resolve().then(() => {
      setOpenConfirmation(false);
      setDeleteTankNo(0);
      setDeleteTankID(0);
      setDeleteTankName("");
    });
  };

  return (
    <div>
      <PageHeader title="Tank Maintenance" subtitle="Manage Tanks"></PageHeader>

      {
        <Card
          sx={{ width: "100hv", borderRadius: 3, margin: 2, mt: 0 }}
          elevation={6}
        >
          <CustomCardHeader
            title="Tank List"
            action={
              filterBandState.selectedCustomerNo !== "" &&
              filterBandState.selectedLocationNo !== "" && (
                <Grid container columnSpacing={{ xs: 1, sm: 1, md: 4, lg: 4 }}>
                  <Grid item>
                    <Link to={"/"}>
                      <Button startIcon={<ReplyIcon />}>Back To Main</Button>
                    </Link>
                  </Grid>
                  <Grid item>
                    <Button
                      onClick={onAddNewClick}
                      startIcon={<AddSharpIcon />}
                    >
                      Add New
                    </Button>
                  </Grid>
                </Grid>
              )
            }
          ></CustomCardHeader>
          <CardContent sx={{ pb: 0 }}>
            <Tbl>
              <TableBody>
                {GetRowsAfterPagingAndSorting().map((row, index) => (
                  <StyledTableRow key={row.vT_TANK_NO}>
                    <StyledTableCell scope="row">
                      {row.vT_TANK_NO}
                    </StyledTableCell>
                    <StyledTableCell scope="row">
                      {row.vT_TANK_NAME}
                    </StyledTableCell>
                    <StyledTableCell scope="row">
                      {/* <Tooltip title="Edit"> */}
                      <IconButton
                        aria-label="Edit"
                        size="small"
                        sx={{ paddingLeft: 1, paddingRight: 1 }}
                        onClick={(e) => handleEdit(row.vT_TANK_ID)}
                      >
                        <ModeEditSharpIcon fontSize="small" />
                      </IconButton>
                      {/* </Tooltip> */}
                    </StyledTableCell>
                    <StyledTableCell scope="row">
                      {/* <Tooltip title="Delete"> */}
                      <IconButton
                        aria-label="Delete"
                        size="small"
                        sx={{ paddingLeft: 1, paddingRight: 1 }}
                        onClick={(e) =>
                          handleOpenDeleteConfirmation(
                            row.vT_TANK_NO,
                            row.vT_TANK_ID,
                            row.vT_TANK_NAME
                          )
                        }
                      >
                        <DeleteSharpIcon fontSize="small" />
                      </IconButton>
                      {/* </Tooltip> */}
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Tbl>
          </CardContent>
        </Card>
      }
      <ModalPopup
        open={showModalPopup}
        title={"Tank Specifications" + productName}
        onPopupClose={onModalClose}
        width="1200px"
      >
        <AddEditTank
          setShowModalPopup={setShowModalPopup}
          locationNo={filterBandState.selectedLocationNo}
          tankID={tankID}
          setRefreshList={setRefreshList}
          setProductName={setProductName}
        />
      </ModalPopup>
      <ConfirmationMessage
        open={openConfirmation}
        message={
          "Do you really want to delete the Tank- " +
          '"' +
          deleteTankName +
          '"' +
          " ? This process cannot be undone."
        }
        onYesClick={handleConfirmDelete}
        onNoClick={handleCancelDelete}
      ></ConfirmationMessage>
    </div>
  );
}

export default TankList;
