import { axios } from "../config/axios";
import { APIResponse } from "../models/api";
import { useAppDispatch } from "../store/store";
import { uiActions } from "../store/ui-slice";


// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default function useAxiosDelete<T>(url: string, requestData:any, title: string="") {
    const dispatch = useAppDispatch();

    const deleteData = async  (): Promise<APIResponse> => {
        let apiResObj:APIResponse={responseData: null, isSuccess:false,errorString:''};

      await axios.delete(url, {headers:{'Authorization': `Bearer ${sessionStorage.getItem("jwtToken")}`}}).then((response) => {
            //console.log(url);
            dispatch(uiActions.incrementCurrentProgress());
            apiResObj = {responseData: response.data, isSuccess:true,errorString:''};
        }).catch((e) => {    
            dispatch(uiActions.stopProgressBar());        
            dispatch(uiActions.logErrorNotification( { message: title==="" ? 'Error occurred' : 'Error occurred in ' + title, 
                                                        error: JSON.stringify(e) }));
            apiResObj = {responseData: null, isSuccess:false,errorString:JSON.stringify(e)};                                           
        });

        return apiResObj;
    }


    return {
        deleteData
    }
}