import { CircularProgress } from "@mui/material";
import Overlay from "./Overlay";

function ProgressBar() {
    return (
        <Overlay>
            <div className="divOverlayCss">
                <CircularProgress size={100} thickness={7}/>
            </div>
        </Overlay>
    )
}
export default ProgressBar;