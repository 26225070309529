import RailcarInventory from "../components/app/Shipment/RailcarInventory";
import { filterBandSelector } from "../store/store";
import { useSelector } from "react-redux";
import { IFilterBandState } from "../interfaces/storeInterfaces";
import SystemMessage from "./SystemMessage";

function RailcarInventoryPage() {
  const role = sessionStorage.getItem("roles");
  const filterBandState: IFilterBandState = useSelector(filterBandSelector);
  return role?.toString().toUpperCase() === "ADMIN" &&
    filterBandState.railcarTerminal === true ? (
    <RailcarInventory></RailcarInventory>
  ) : (
    <SystemMessage msg="Access Denied." colorMsg="Red"></SystemMessage>
  );
}
export default RailcarInventoryPage;
