import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IUIState } from '../interfaces/storeInterfaces';

const initialState = {
    showProgressBar: false,
    totalStepCount:0,
    completedStepCount:0,
    message:"",
    severity:"success" ,
    error:undefined,
    showNotification:false,
} as IUIState

const uiSlice = createSlice({
    name: 'ui',
    initialState,
    reducers:{
        startProgressBar(state) {
            state.showProgressBar = true;
            state.totalStepCount=0;
            state.completedStepCount=0;
        },
        stopProgressBar(state) {
            state.showProgressBar = false;
            state.totalStepCount=0;
            state.completedStepCount=0;
        },
        startProgressBarWithTotalStepCount(state, action: PayloadAction<number>) {
            state.showProgressBar = true;
            state.totalStepCount = action.payload;
            state.completedStepCount = 0;
        },
        incrementCurrentProgress(state) {
            state.completedStepCount=state.completedStepCount+1;
            if(state.completedStepCount >= state.totalStepCount)
            {
                state.showProgressBar = false;   
                state.totalStepCount=0;
                state.completedStepCount=0; 
            }
        },
        displayNotification(state, action)
        {
            state.message=action.payload.message;
            state.severity=action.payload.severity;
            state.showNotification = true;
        },
        logErrorNotification(state, action)
        {
            state.message=action.payload.message;
            state.error = action.payload.error;        
            state.severity="error";
            state.showNotification = true;
        },
        clearNotification(state)
        {
            state.message="";
            state.severity="success";
            state.showNotification = false;
            state.error=undefined;
        },
    }  
  });

export const uiActions = uiSlice.actions;

export default uiSlice;