import jwtDecode from "jwt-decode";

function SessionCheck() {
  interface MyToken {
    name: string;
    exp: number;
    email: string;
    roles: [];
  }

  let validTime, calculated: number;

  const token =
    sessionStorage.getItem("jwtToken") === undefined
      ? ""
      : sessionStorage.getItem("jwtToken");
  const decodedToken = jwtDecode<MyToken>(token || "");
  const isValid: boolean = token ? decodedToken.exp * 1000 > Date.now() : false;

  if (isValid) {
    // console.log(-((Date.now()/1000)-decodedToken.exp)*1000);
    // validTime = 100000;

    calculated = (-(Date.now() / 1000 - decodedToken.exp) * 1000) - 60000;
    if (calculated < 0) {
      validTime = 0;
    } else {
      validTime = calculated;
    }
  } else {
    validTime = 0;
  }

  return validTime;
}

export default SessionCheck;
