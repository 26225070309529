import { axios } from "../config/axios";
import { APIResponse } from "../models/api";
import { useAppDispatch } from "../store/store";
import { uiActions } from "../store/ui-slice";


export default function useAxiosGet<T>(url: string, defaultResponseDataValue:T, title: string="") {
    const dispatch = useAppDispatch();

    const fetchData = async  (): Promise<APIResponse> => {
        let apiResObj:APIResponse={responseData: defaultResponseDataValue, isSuccess:false,errorString:''};

      await axios.get(url,{headers:{'Authorization': `Bearer ${sessionStorage.getItem("jwtToken")}`}}).then((response) => {
            //console.log(url);
            dispatch(uiActions.incrementCurrentProgress());
            apiResObj = {responseData: response.data, isSuccess:true,errorString:''};
        }).catch((e) => {    
            dispatch(uiActions.stopProgressBar());        
            dispatch(uiActions.logErrorNotification( { message: title==="" ? 'Error occurred' : 'Error occurred in ' + title, 
                                                        error: JSON.stringify(e) }));
            apiResObj = {responseData: defaultResponseDataValue, isSuccess:false,errorString:JSON.stringify(e)};                                           
        });

        return apiResObj;
    }


    return {
        fetchData
    }
}