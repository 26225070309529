//Background color
export const BgColorSiteContent : string = '#E7EBF0'// string='#E7EBF0';//'#F4F5FA';
export const BgColorAvatar : string= '#F4BB44' 
export const BgColorFilterBand : string= '#DEE4E7' 
export const BgColorTableHeader : string= '#2c275d' 

//FontColor
export const FontColorLabelItem: string= '#344767'
export const FontColorDetailItem: string= '#506176'
export const FontColorCardHeader: string= '#3A3541'

//FontSize
export const FontSizeLabelItem: string= '0.95rem'
export const FontSizeDetailItem: string= '0.85rem'

//Font Family
export const CommonFontFamily : string= 'Inter, sans-serif' 

//Theme
export const BgThemeColorADMBlue : string='#2c275d';
export const BgThemeColorPrimaryLight : string= '#03a9f4' 
export const BgThemeColorSecondaryMain : string= '#008000' 
export const CommonThemeFontSize = '0.9rem';
export const CommonThemeAutoCompleteFontSize = '0.8rem';