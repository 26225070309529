import React from 'react';
import admLine from "../../assets/adm.svg";
function ADMLine() {
  return (
    <div className="adm-home-img-line">
         <img src={admLine} alt="ADM colors line"></img>
    </div>
  )

}
export default ADMLine;