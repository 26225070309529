/* eslint-disable import/no-anonymous-default-export */
import useAxiosGet from "../hooks/useAxiosGet";
import useAxiosPost from "../hooks/useAxiosPost";
import useAxiosPut from "../hooks/useAxiosPut";
import useAxiosDelete from "../hooks/useAxiosDelete";
import { UnitInfo, LocationDetails, TanksForLocation, ShowDaily, ProductLocation, EmailContacts, SaveContacts, UpdateProductLocationData, BatchSpecification, CustomerLocations, AssignedLocations} from "../models/location";
import { ProductInventoryByLocation } from "../models/product";

const isLocal = () => (process.env.REACT_APP_ENVIR === 'LOCAL');
const userId = () => sessionStorage.getItem("userMailID");
const date=new Date().toJSON().slice(0,10);

const GetDefaultLocationInfo = (): LocationDetails => {
  const defaultLocationInfo = {
    vL_LOCATION_ID: 0,
    vL_CUSTOMER_ID: 0,
    vL_CUSTOMER_SHIPTO: "",
    vL_LOCATION_NAME: "",
    vL_SITE_NUMBER: "",
    vL_ADM_LOCATION: false,
    vL_CONTACT_NAME: "",
    vL_CONTACT_EMAIL: "",
    vL_CONTACT_EMAIL2: "",
    vL_CONTACT_EMAIL3: "",
    vL_ADDRESS_1: "",
    vL_ADDRESS_2: "",
    vL_CITY: "",
    vL_STATE: "",
    vL_ZIP: "",
    vL_PHONE: "",
    vL_FAX: "",
    vL_INBOUND_RAIL: false,
    vL_AUTO_SENSE: "",
    vL_LOC_AS_SOLDTO: false,
    vL_RAILCAR_TERMINAL: false,
    vL_COA_REQUEST_LOCATION: false,
  } as LocationDetails;

  return defaultLocationInfo;
};


const GetDefaultUnitInfo = (): UnitInfo => {
  const detDefaultUnitInfo = {
    vunT_UNIT_NO: "",
    vunT_LOCATION_ID: 0,
    vunT_UNIT_IP: "",
    vunT_UNIT_SUBNET: "",
    vunT_UNIT_ID: "",
    vunT_UNIT_SMTP: "",
    vunT_TEMP_SOLDTO: "",
    vunT_TEMP_SHIPTO: "",
    vunT_UNIT_GATEWAY: "",
    vunT_UNIT_IMEI: "",
    vunT_UNIT_APN: "",
    vunT_UNIT_USERNAME: "",
    vunT_UNIT_PASSWORD: "",
    vunT_UNIT_MODEL_NUMBER: "",
    vunT_UNIT_SERIAL_NUMBER: ""
  } as UnitInfo;

  return detDefaultUnitInfo;
};

const GetDefaultProductLocation = (): any => {
  const GetDefaultProductLocation = {
    vpL_PRODUCT_ID: "",
    vpL_LOCATION_ID: "",
    vpL_AVG_DAILY_USAGE: "",
    vpL_AVG_DAILY_USAGE_OVERRIDE: "",
    vpL_REPORT_TIME: "",
    vpL_REPORT_TIME_WEEKLY: "",
    vpL_WKND_FLG: "",
    vpL_ORDER_POINT_PERCENT: "",
    vpL_ORDER_POINT_DAYS: "",
    vpL_ORDER_REORDER_QTY: "",
    vpL_ORDER_MAX_SHIP_QTY: "",
    vpL_ORDER_MIN_SHIP_PCT: "",
    vpL_ORDER_COPC: "",
    vpL_ORDER_MSTR_CUST_NO: "",
    vpL_ORDER_MSTR_SHIP_CODE: "",
    vpL_ORDER_CONTAINER_CODE: "",
    vpL_ORDER_LEAD_DAYS: "",
    vpL_ORDER_TRAVEL_DAYS: "",
    vpL_ORDER_ESTIMATION: "",
    vpL_ORDER_GENERATION: "",
    vP_PRODUCT_CODE: "",
    vP_PRODUCT_DESC: "",
    vpL_RAIL_USER: "",
    vpl_acpt_overflow: "",
    vpL_PROD_SCHEDULE_USER: "",
    capacity: "",
    vpL_UOM: "",
    vpL_NUMBER_OF_CARS: "",
    vpL_IBM_SYNC: "",
    vpL_ARRIVAL_DT_FLG: "",
    vpL_PR_CODE: "",
    vpL_MOBILE_PUBLIC: "",
    vpL_MOBILE_PRIVATE: "",
    vpL_ARRIVAL_DATE_ADJUSTMENT: "",
    vpL_HIGH_INVENTORY_ARRIVAL_WARNING: "",
    vpL_ADU_RANGE_WARNING: "",
    vpL_ADU_RANGE_MIN: "",
    vpL_ADU_RANGE_MAX: "",
    vpT_PRODUCT_TYPE: "",
    vpL_WEEK_AVG_DISPLAY: "",
    vpL_OPERATING_DAYS: "",
    averageDailyUsage: ""
  } as any;
  return GetDefaultProductLocation;
};

const GetDefaultContactInfo = (): SaveContacts => {
  const GetDefaultContactInfo = {
    vpL_PRODUCT_ID: 0,
    vpL_LOCATION_ID: 0,
    orderContactIDs: "",
  } as SaveContacts;
  return GetDefaultContactInfo;
};

const GetDefaultBatchSpecification = () : BatchSpecification =>
{
   const defaultBatchSpecification = {
     vB_KEY: 0,
     vB_LOCATION_ID: '',
     VB_PRODUCT_ID: 0,
     vB_BATCH_NAME: '',
     vB_URL: '',
     vB_CALIBRATION_DATE: null,
     vB_SETPOINT: '',
     vB_COMMENTS: ''
   } as unknown as BatchSpecification;
  return defaultBatchSpecification;
}

const GetLocationByLocNumber = (customerNo: string,locationNo: string) => {
  return useAxiosGet<LocationDetails>(isLocal()?`/Location/GetLocationByLocNumber/users/${userId()}/customers/${customerNo}/locations/${locationNo}/locationdetails`:`/users/${userId()}/customers/${customerNo}/locations/${locationNo}/locationdetails`,GetDefaultLocationInfo(),"Location Information");
};

const GetUnitByID = (  customerNo: string,  locationNo: string,  unitId: string) => {
  return useAxiosGet<UnitInfo[]>(isLocal()?`/Location/GetUnitByID/users/${userId()}/customers/${customerNo}/locations/${locationNo}/units?unitID=${unitId}`:`/users/${userId()}/customers/${customerNo}/locations/${locationNo}/units?unitID=${unitId}`,[],"Unit Information");
};

const GetTanksForLocation = (customerNo:string, locationNo:string) => {
  return useAxiosGet<TanksForLocation[]>(isLocal()?`/Location/GetTanksForLocation/users/${userId()}/customers/${customerNo}/locations/${locationNo}/tanks`:`/users/${userId()}/customers/${customerNo}/locations/${locationNo}/locationtanks`, [], "Tank list");
};

const UpdateLocation = (data:LocationDetails) => {
  return useAxiosPut(isLocal() ?`/Location/UpdateLocation/users/${userId()}/locations`:`/users/${userId()}/locations`, data, "Location Maintainance - Update Location");
};

const CreateLocation = (data:LocationDetails) => {
  return useAxiosPost(isLocal() ?`/Location/CreateLocation/users/${userId()}/locations`:`/users/${userId()}/locations`, data, "Location Maintainance - Add Location");
};

const DeleteLocation = (customerNo: string, locationNo:string) => {
  return useAxiosDelete(isLocal() ? 
  `/Location/DeleteLocation/users/${userId()}/customers/${customerNo}/locations/${locationNo}`:
  `/users/${userId()}/customers/${customerNo}/locations/${locationNo}`, 
  locationNo, 
  "Location Maintainance - Delete Location");
};

const RetrieveShowDailyData = (locationId: number, productId: number, startDate: string, endDate: string) => {
  return useAxiosGet<ShowDaily[]>(
    isLocal() ?
      `/Location/RetrieveShowDailyData/location/${locationId}/product/${productId}/startDate/${startDate}/endDate/${endDate}/showdailyinventories`
      : `/location/${locationId}/product/${productId}/startDate/${startDate}/endDate/${endDate}/showdailyinventories`,
    [],
    "Show Daily Data");
};

const RetrieveProductLocationInfo = (productID:number,locationID:number) =>{
  return useAxiosGet<ProductLocation[]>(isLocal()?`/Location/RetrieveProductLocationInfo/locations/${locationID}/products/${productID}/productlocations`:`/locations/${locationID}/products/${productID}/productlocations`, [], "Product Location Information");
}

const RetrieveContactEmails =(productID:number,locationID:number)=>{
  return useAxiosGet<EmailContacts[]>(isLocal()?`/Location/RetrieveContactEmails/locations/${locationID}/products/${productID}/contactdetails`:`/locations/${locationID}/products/${productID}/contactdetails`, [], "Email Contacts Details");
}

const UpdateProductLocationContacts=(productID:number,locationID:number,data:SaveContacts)=>{
  return useAxiosPut(isLocal() ?`/Location/UpdateProductLocationContacts/users/${userId()}/productlocationcontacts`:`/users/${userId()}/productlocationcontacts`, data, "Product Location - Update Contacts");
}

const UpdateProductLocation=(productID:number,locationID:number,data:UpdateProductLocationData)=>{
  return useAxiosPut(isLocal() ?`/Location/UpdateProductLocation/users/${userId()}/productlocations`:`/users/${userId()}/productlocations`, data, "Product Location - Update Information");
}

const RetrieveBatchAllSpecification = (locationNo:string, productId:number,customerNo:string) => {
  
  return useAxiosGet<BatchSpecification>(isLocal()?`/Location/RetrieveBatchAllSpecification/locations/${locationNo}/products/${productId}/batchspecifications`:`/locations/${locationNo}/products/${productId}/batchspecifications`,GetDefaultBatchSpecification(), "Batch Specification");
};


const UpdateBatchSpecification = (data:BatchSpecification) => {
  return useAxiosPut(isLocal() ?`/Location/UpdateBatchSpecification/batchSpecifications`:`/batchSpecifications`, data, "Batch Specification-Update Specification");
};

const CreateBatchSpecification = (data:BatchSpecification) => {
  return useAxiosPost(isLocal() ?`/Location/CreateBatchSpecification/batchSpecifications`:`/batchSpecifications`, data, "Batch Specification-Add Specification");
};

const DeleteBatchSpecification = (key:number) => {
  return useAxiosDelete(isLocal() ?`/Location/DeleteBatchSpecification/keys/${key}`:`/keys/${key}`,"Batch Specification-Delete Specification");
};

const GetProductInventoryByDate = (customerNo:string, locationNo:string) => {
  return useAxiosGet<ProductInventoryByLocation[]>(isLocal()?`/Location/GetProductInventoryByDate/users/${userId()}/customers/${customerNo}/locations/${locationNo}/inventories?receiveddate=${date}`:`/users/${userId()}/customers/${customerNo}/locations/${locationNo}/inventories?receiveddate=${date}`,[], "Product Inventory");
};

const RetrieveAllLocations = () => {
  return useAxiosGet<CustomerLocations[]>(isLocal() ? `/Location/RetrieveAllLocations/locations` : `/locations`, [], "Customer Locations");
};

const RetrieveUserLocations = (userEmailID: string) => {
  return useAxiosGet<AssignedLocations[]>(isLocal() ? `/Location/RetrieveUserLocations/users/${userEmailID}/userlocations` : `/users/${userEmailID}/userlocations`, [], "Locations Assigned to User");
};

export default {
  GetDefaultLocationInfo,
  GetLocationByLocNumber,
  GetUnitByID,
  GetTanksForLocation,
  UpdateLocation,
  CreateLocation,
  DeleteLocation,
  RetrieveShowDailyData,
  GetDefaultUnitInfo,
  RetrieveProductLocationInfo,
  GetDefaultProductLocation,
  RetrieveContactEmails,
  UpdateProductLocationContacts,
  GetDefaultContactInfo,
  UpdateProductLocation,
  RetrieveBatchAllSpecification,
  CreateBatchSpecification,
  UpdateBatchSpecification,
  DeleteBatchSpecification,
  GetDefaultBatchSpecification,
  GetProductInventoryByDate,
  RetrieveAllLocations,
  RetrieveUserLocations
};
