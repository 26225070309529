/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import DrawerList from "../shared/DrawerList";
import {
  DrawerMenuItemArray,
  InboundDrawerMenuItemArray,
} from "../../utils/data/routedata";
import { filterBandSelector } from "../../store/store";
import { useSelector } from "react-redux";
import { IFilterBandState } from "../../interfaces/storeInterfaces";

function LeftNav() {
  const filterBandState: IFilterBandState = useSelector(filterBandSelector);

  return (
    <React.Fragment>
      {filterBandState.inboundUser === true ? (
        <DrawerList menus={InboundDrawerMenuItemArray}></DrawerList>
      ) : (
        <DrawerList menus={DrawerMenuItemArray}></DrawerList>
      )}
    </React.Fragment>
  );
}
export default LeftNav;
