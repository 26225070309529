/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Button, Card, CardContent, Divider, Grid } from "@mui/material";
import { StyledTableRow, StyledTableCell } from "../../controls/tablecontrols";
import { uiActions } from "../../../store/ui-slice";
import { useAppDispatch } from "../../../store/store";
import { ShowDaily } from "../../../models/location";
import locationService from "../../../services/locationService";
import { LabelItem } from "../../controls/commoncontrols";
import useTable from "../../../hooks/useTable";
import { TableHeaderCell } from "../../../models/common";
import CustomCardHeader from "../../shared/CustomCardHeader";
import moment from "moment";
import { FormatUnit } from "../../shared/CommonFunctions";

//for DatePicker
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { DatePicker } from '@mui/x-date-pickers';
import dayjs, { Dayjs } from 'dayjs';


interface ShowDailyProps {
  product_ID: number;
  location_Name: string;
  location_ID: number;
}

function ShowDailyPage({ product_ID, location_Name, location_ID }: ShowDailyProps) {

  const dispatch = useAppDispatch();

  const [showDailyData, SetShowDailyData] = useState<ShowDaily[]>([]);

  const [startDate, setStartDate] = useState<Dayjs>(dayjs());

  const [endDate, setEndDate] = useState<Dayjs>(dayjs());

  const [invalidDate, setInvalidDate] = useState(false)

  const headerCellsUnitInfo: TableHeaderCell[] = [
    { id: "inv_Date", label: "Date" },
    { id: "b_Inv_Percent", label: "Beginning Level" },
    { id: "e_Inv_Percent", label: "Ending Level" },
    { id: "Total", label: "Total Usage", disableSorting: true }
  ];

  const {
    Tbl: TblUnit,
    GetRowsAfterPagingAndSorting: GetRowsInfo,
  } = useTable<ShowDaily>(showDailyData, headerCellsUnitInfo, false);


  const { fetchData: fetchShowDailyData } = locationService.RetrieveShowDailyData(location_ID, product_ID, startDate.format('MM-DD-YYYY'), endDate.format('MM-DD-YYYY'));
  const { fetchData: resetShowDailyData } = locationService.RetrieveShowDailyData(location_ID, product_ID, dayjs().format('MM-DD-YYYY'), dayjs().format('MM-DD-YYYY'));

  useEffect(() => {
    if (location_ID !== 0 && product_ID !== 0) {
      fetchShowDailyData().then((res) => {
        if (res.isSuccess === true) {
          SetShowDailyData(res.responseData);
        }
      });
    };
  }, [])

  function checkRequiredFields() {

    if (invalidDate) {
      dispatch(
        uiActions.displayNotification({
          message: "Please enter a valid Date",
          severity: "error",
        })
      );
      return;
    }

    if (startDate > endDate) {
      dispatch(
        uiActions.displayNotification({
          message: "Through Date should be after the Begin Date.",
          severity: "error",
        })
      );
      return;
    }

    if ((dayjs(endDate).toDate().getTime() - dayjs(startDate).toDate().getTime()) / (1000 * 3600 * 24) > 30) {
      dispatch(
        uiActions.displayNotification({
          message: "Select a date range not more than 30 days apart.",
          severity: "error",
        })
      );
      return;
    }

    fetchShowDailyData().then((res) => {
      if (res.isSuccess === true) {
        SetShowDailyData(res.responseData);
      }
    });
  }

  const onResetClick = () => {
    setStartDate(dayjs())
    setEndDate(dayjs())
    resetShowDailyData().then((res) => {
      if (res.isSuccess === true) {
        SetShowDailyData(res.responseData);
      }
    });
  }

  return (
    <React.Fragment>

      {/* for calenders */}
      <Grid container>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            sx={{ minWidth: '340px', margin: '15px' }}
            label="Search Begin Date"
            value={startDate}
            onChange={(newValue) => {
              if (newValue !== null && newValue.isValid()) {
                setStartDate(newValue)
                setInvalidDate(false)
              } else {
                setInvalidDate(true)
              }
            }}
          />
          <DatePicker
            sx={{ minWidth: '340px', margin: '15px' }}
            label="Search Through Date"
            value={endDate}
            onChange={(newValue) => {
              if (newValue !== null && newValue.isValid()) {
                setEndDate(newValue)
                setInvalidDate(false)
              } else {
                setInvalidDate(true)
              }
            }}
          />
        </LocalizationProvider>

      </Grid>

      <Grid container spacing={3}>
      <Grid item
        sx={{ alignItems: 'right', justifyContent: 'right', display: 'flex' }}
        xs={9}
        sm={6}
        md={6}
      >
        <Button type="submit" size="medium"  onClick={onResetClick} >{"Reset"}</Button>
      </Grid>
      <Grid item
        //sx={{ alignItems: 'center', justifyContent: 'center', display: 'flex' }}
        xs={6}
      >
        <Button type="submit" size="medium" onClick={checkRequiredFields}>{"Show Summary"}</Button>
      </Grid>
      </Grid>

      <div>
        <Divider sx={{ mt: 4, mb: 2 }}></Divider>
        <LabelItem style={{ whiteSpace: "nowrap" }}>
          {"Daily Report from " + dayjs(startDate).format("MM/DD/YYYY") + " to " + dayjs(endDate).format("MM/DD/YYYY")}
        </LabelItem>
        <Card>
          <CustomCardHeader title={location_Name}></CustomCardHeader>
          <CardContent style={{ whiteSpace: 'nowrap' }} >
            <TblUnit>
              {GetRowsInfo().map((row, index) => (
                <StyledTableRow key={index}>
                  <StyledTableCell>{moment(row.inv_Date).format("MM/DD/YYYY")}</StyledTableCell>
                  {(row.uom === "MT") ?
                    (<StyledTableCell>{row.b_Inv_Percent + "% / " + (row.b_Inv_Pounds / 2204).toFixed(1) + " " + FormatUnit(row.uom)}</StyledTableCell>)
                    :
                    (row.uom === "BT" ? <StyledTableCell>{row.b_Inv_Percent + "% / " + (row.b_Inv_Pounds / row.specificGravity).toFixed(1) + " " + FormatUnit(row.uom)}</StyledTableCell>
                      : <StyledTableCell>{row.b_Inv_Percent + "% / " + (row.b_Inv_Pounds).toFixed(0) + " " + FormatUnit(row.uom || '')}</StyledTableCell>)
                  }

                  {(row.uom === "MT") ?
                    (<StyledTableCell>{row.e_Inv_Percent + "% / " + (row.e_Inv_Pounds / 2204).toFixed(1) + " " + FormatUnit(row.uom)}</StyledTableCell>)
                    :
                    (row.uom === "BT" ? <StyledTableCell>{row.e_Inv_Percent + "% / " + (row.e_Inv_Pounds / row.specificGravity).toFixed(1) + " " + FormatUnit(row.uom)}</StyledTableCell>
                      : <StyledTableCell>{row.e_Inv_Percent + "% / " + (row.e_Inv_Pounds).toFixed(0) + " " + FormatUnit(row.uom || '')}</StyledTableCell>)
                  }

                  {(row.uom === "MT") ?
                    (<StyledTableCell>{((row.b_Inv_Pounds / 2204) - (row.e_Inv_Pounds / 2204) + (row.shipment_Weight / 2204)).toFixed(1) + " " + FormatUnit(row.uom)}</StyledTableCell>)
                    :
                    (row.uom === "BT" ? <StyledTableCell>{((row.b_Inv_Pounds - row.e_Inv_Pounds + row.shipment_Weight) / row.specificGravity).toFixed(1) + " " + FormatUnit(row.uom)}</StyledTableCell>
                      : <StyledTableCell>{(row.b_Inv_Pounds - row.e_Inv_Pounds + row.shipment_Weight).toFixed(0) + " " + FormatUnit(row.uom || '')}</StyledTableCell>)
                  }

                </StyledTableRow>
              ))}
            </TblUnit>
          </CardContent>
        </Card></div>
    </React.Fragment>
  );
}
export default ShowDailyPage;